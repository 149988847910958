import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useModules from 'src/shared/hooks/useModules';
import usePaymentConfig from 'src/features/payment-management/hooks/data/usePaymentConfig';
import measureUserAccess from 'src/shared/utils/measureUserAccess';

import {
  createClientRoute,
  createServerRoute,
  designateRoutesByCountry,
  normalizeRoutes,
} from './utils';

const hasUserAccess = (module, user) => {
  return measureUserAccess(module.roles, user.userIs);
};

const useRoutes = () => {
  const user = useSelector(({ user }) => ({
    userIs: user.userIs,
    families: user.data?.families,
    country: user.data?.country,
    isWebView: user.isWebView,
    apiKey: user.apiKey,
  }));
  const userIsWebAndLoggedIn = Boolean(user.apiKey) && !user.isWebView;

  const { data: serverModules } = useModules();
  const { data: config } = usePaymentConfig({
    enabled:
      userIsWebAndLoggedIn && (user.userIs.admin || user.userIs.financialAdmin),
  });

  const processRoute = useCallback(
    (route) => {
      if (typeof route.id !== 'number' && !route.moduleId) {
        const clientRoute = createClientRoute({
          modules: serverModules,
          route,
          config,
        });

        if (!clientRoute.enabled || !hasUserAccess(route, user)) return null;

        return clientRoute;
      }

      const currentModule = serverModules.find(
        (userModule) =>
          userModule.id === route.id || userModule.id === route.moduleId,
      );

      if (!currentModule) return null;

      if (!hasUserAccess(currentModule, user)) return null;
      if (!hasUserAccess(route, user)) return null;

      const serverRoute = createServerRoute({
        currentModule,
        route,
        config,
      });

      if (!serverRoute.enabled) return null;

      return serverRoute;
    },
    [config, serverModules, user],
  );

  const createNestedRoutes = useCallback(
    (nestedRoutes) => {
      if (!nestedRoutes || !Array.isArray(nestedRoutes)) return [];

      const nestedResults = nestedRoutes.map((nestedRoute) => {
        const subRoutes = processRoute(nestedRoute);
        if (subRoutes && nestedRoute.items) {
          subRoutes.items = createNestedRoutes(nestedRoute.items);
        }
        return subRoutes;
      });

      return nestedResults.filter((subRoutes) => subRoutes !== null);
    },
    [processRoute],
  );

  const checkRouteAvailability = useCallback(
    (moduleName) =>
      serverModules.some(
        (server) => server.name === moduleName && server.enabled,
      ),
    [serverModules],
  );

  const createRoutes = useCallback(
    (rawRoutes) => {
      const routesByCountry = designateRoutesByCountry(rawRoutes, user);

      const routes = routesByCountry.reduce((acc, route) => {
        const subRoutes = processRoute(route);

        if (subRoutes && route.items)
          subRoutes.items = createNestedRoutes(route.items);
        if (subRoutes) acc.push(subRoutes);

        return acc;
      }, []);

      return normalizeRoutes(routes);
    },
    [user, processRoute, createNestedRoutes],
  );

  return { createRoutes, checkRouteAvailability };
};

export default useRoutes;
