import axios from 'axios';

import request from './request';
import injectInterceptor from './interceptor';

export const axiosInstance = axios.create();

const instance = injectInterceptor(axiosInstance);

const axiosService = {
  request(options) {
    return instance.request(options);
  },
};

const api = {
  get({ url, headers, getRaw, data = {}, cancelToken, ...rest }) {
    return request({
      service: axiosService,
      method: 'GET',
      url,
      data,
      headers,
      getRaw,
      cancelToken,
      ...rest,
    });
  },
  post({ url, headers, getRaw, data = {}, cancelToken, ...rest }) {
    return request({
      service: axiosService,
      method: 'POST',
      url,
      data,
      headers,
      getRaw,
      cancelToken,
      ...rest,
    });
  },
  put({ url, headers, getRaw, data = {}, cancelToken, ...rest }) {
    return request({
      service: axiosService,
      method: 'PUT',
      url,
      data,
      headers,
      getRaw,
      cancelToken,
      ...rest,
    });
  },
  patch({ url, headers, getRaw, data = {}, cancelToken, ...rest }) {
    return request({
      service: axiosService,
      method: 'PATCH',
      url,
      data,
      headers,
      getRaw,
      cancelToken,
      ...rest,
    });
  },
  delete({ url, headers, getRaw, data = {}, cancelToken, ...rest }) {
    return request({
      service: axiosService,
      method: 'DELETE',
      url,
      data,
      headers,
      getRaw,
      cancelToken,
      ...rest,
    });
  },
  upload({ url, headers, getRaw, data = {}, cancelToken, ...rest }) {
    return request({
      service: axiosService,
      method: 'UPLOAD',
      url,
      data,
      headers,
      getRaw,
      cancelToken,
      ...rest,
    });
  },
};

export default api;
