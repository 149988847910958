import { ROLE_LIST } from 'src/config/general';

const USER_PROFILE_VIEW_ROLES = [
  ROLE_LIST.ADMIN,
  ROLE_LIST.EXECUTIVE,
  ROLE_LIST.PRECEPTOR,
  ROLE_LIST.PARENT,
  ROLE_LIST.TEACHER,
  ROLE_LIST.STUDENT,
];

export default USER_PROFILE_VIEW_ROLES;
