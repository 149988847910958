import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { t } from '@lingui/macro';

import ConditionalWrapper from 'src/components/ConditionalWrapper';
import { BaseSubmitButton } from 'src/components/Form';

const SubmitButton = ({
  children = t`Enviar`,
  gridProps = { xs: 12 },
  boxProps = {},
  form,
  disabled,
  ...rest
}) => {
  const { touched, isValid, isSubmitting, dirty } = useFormikContext();
  const isDirty = Object.keys(touched).length > 0 || dirty;

  return (
    <Grid item {...gridProps}>
      <ConditionalWrapper
        condition={Object.keys(boxProps).length > 0}
        wrapper={(conditionalChildren) => (
          <Box {...boxProps}>{conditionalChildren}</Box>
        )}
      >
        <BaseSubmitButton
          form={form}
          disabled={!isDirty || !isValid || isSubmitting || disabled}
          {...rest}
        >
          {children}
        </BaseSubmitButton>
      </ConditionalWrapper>
    </Grid>
  );
};

SubmitButton.propTypes = {
  children: PropTypes.any,
  gridProps: PropTypes.object,
  boxProps: PropTypes.object,
  form: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SubmitButton;
