import { atom } from 'jotai';
import calendarTitleAtom from './calendarTitleAtom';
import calendarAtom from './calendarAtom';

const updateCalendarTitleAtom = atom(null, (get, set) => {
  const calendar = get(calendarAtom);
  const calendarTitle = calendar?.view?.title ?? '';

  set(calendarTitleAtom, calendarTitle);
});

export default updateCalendarTitleAtom;
