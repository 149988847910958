import { useQueryClient } from '@tanstack/react-query';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { paymentManagementUsers } from 'src/shared/services/url/UrlPaymentManagement';
import { useCreate } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const useAddMember = (rateId, students) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useCreate(paymentManagementUsers(rateId), {
    onError: ({ message: error }) => {
      const message = error ?? '';
      enqueueSnackbar(t`No se pudo vincular al estudiante. ${message}`, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.RATES],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.CONCEPT_SELECTED_MEMBERS],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.CONCEPT_SELECTED_MEMBERS_PAGINATED],
      });
    },
  });
};

export default useAddMember;
