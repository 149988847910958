import { useState } from 'react';
import linkifyHtml from 'linkifyjs/lib/linkify-html';

import { isValidUrl } from 'src/shared/helpers';
import { getOpenGraphUrl } from 'src/shared/services/url/UrlCommunication';
import { Api as api } from 'src/shared/services/api';

const getOpenGraph = (url) =>
  api.get({
    url: getOpenGraphUrl(),
    data: {
      url,
    },
  });

const usePublicationEditor = () => {
  const [isFetchingOpenGraph, setIsFetchingOpenGraph] = useState(false);

  const handlePasteContent = async (
    args,
    openGraph,
    setOpenGraph,
    buttonId,
  ) => {
    const regex = /href=["'](.*?)["']/;
    const match = regex.exec(args.content);
    const href = match ? match[1] : null;

    if (href && isValidUrl(href)) {
      try {
        setIsFetchingOpenGraph(true);
        if (!openGraph) {
          Object.assign(args, {
            content: linkifyHtml(args.content, {
              className: 'tinymce-link',
            }),
          });

          const { data: graphData } = await getOpenGraph(href);
          if (graphData) {
            setOpenGraph(graphData);
            window.tinymce.execCommand('editorChange', null, {
              editorId: buttonId,
              action: 'add',
            });
          }
        }
      } catch ({ message }) {
        // eslint-disable-next-line no-console
        console.log(message);
      } finally {
        setIsFetchingOpenGraph(false);
      }
    }
  };

  const handleLinkAdded = async (
    content,
    openGraph,
    setOpenGraph,
    buttonId,
  ) => {
    if (isValidUrl(content)) {
      await handlePasteContent({ content }, openGraph, setOpenGraph, buttonId);
    }
  };

  return { handlePasteContent, isFetchingOpenGraph, handleLinkAdded };
};

export default usePublicationEditor;
