import React from 'react';
import { Collapse, Grid } from '@mui/material';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { useSetAtom } from 'jotai';

import { useOpenDialog } from 'src/shared/hooks';
import { dialogs } from 'src/shared/constants';
import { selectedEditingUserAtom } from 'src/features/my-account/atoms';
import ContainerInfo from 'src/features/my-account/components/ContainerInfo';
import FieldInfo from 'src/features/my-account/components/FieldInfo';
import { toDateFormat } from 'src/shared/utils/dates';
import { GENDER_TYPES } from 'src/config/constants';
import useUserInfo from '../../../../../payment-management/hooks/useUserInfo';
import { checkRole } from '../../../../../../shared/helpers';
import { ROLE_LIST } from '../../../../../../config/general';

const PersonalInfo = ({
  user = {},
  containerProps,
  isEditable,
  isStudent = false,
  hideEmptyValue = false,
  isAdmin,
}) => {
  const { userIs, data: userLoggedData } = useUserInfo();
  const {
    email,
    document_number,
    birthdate,
    genre_id,
    alias,
    id: userId,
  } = user;
  const openEditUserInfo = useOpenDialog(dialogs.EDIT_USER_INFO_DIALOG);
  const setSelectedUserInfo = useSetAtom(selectedEditingUserAtom);
  const canSeeMyAlias = userId === userLoggedData.id && userIs.executive;
  const canSeeAlias =
    Boolean(alias) &&
    (userIs.admin ||
      canSeeMyAlias ||
      (userIs.executive && !checkRole([ROLE_LIST.EXECUTIVE], user)));

  const handleEdit = () => {
    openEditUserInfo(true);
    setSelectedUserInfo({ ...user, isStudent });
  };

  const userGender = GENDER_TYPES().find(({ id }) => id === genre_id) ?? {};

  const shouldShowContent = (value) => {
    return !hideEmptyValue ? true : !!value;
  };

  return (
    <Grid container direction={'column'}>
      <ContainerInfo
        onEdit={handleEdit}
        isEditable={isEditable || isAdmin}
        title={t`Información personal`}
        {...containerProps}
      >
        {canSeeAlias && <FieldInfo title={t`Alias`} value={alias} />}

        <Collapse in={shouldShowContent(email)}>
          <FieldInfo title={t`Correo electrónico`} value={email} />
        </Collapse>
        {!isAdmin && (
          <>
            <Collapse in={shouldShowContent(document_number)}>
              <FieldInfo title={t`DNI`} value={document_number} />
            </Collapse>

            <Collapse in={shouldShowContent(userGender.name)}>
              <FieldInfo title={t`Género`} value={userGender.name} />
            </Collapse>

            <Collapse in={shouldShowContent(birthdate)}>
              <FieldInfo
                title={t`Fecha de nacimiento`}
                {...(Boolean(birthdate) && {
                  value: toDateFormat(birthdate, t`DD/MM/YYYY`, true),
                })}
              />
            </Collapse>
          </>
        )}
      </ContainerInfo>
    </Grid>
  );
};

PersonalInfo.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    document_number: PropTypes.string,
    birthdate: PropTypes.string,
  }),
  containerProps: PropTypes.object,
  isEditable: PropTypes.bool,
  isStudent: PropTypes.bool,
  hideEmptyValue: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default PersonalInfo;
