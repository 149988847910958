import { t } from '@lingui/macro';
import dayjs from 'dayjs';
import { ROLE_LIST } from 'src/config/general';
import { uniqueByKey } from 'src/shared/utils/uniqueByKey';

const PAGE_DATA = {
  metadata: { title: t`Mensajes` },
};

const INBOX_HEADER_HEIGHT = 200;

const TRUNCATE_AT = 100;

const EMPTY_STATES_TYPES = {
  NO_MESSAGES: 'no-messages',
  SEARCH: 'search',
};

const getConversationUsers = (users, senderId) =>
  users ? users?.filter((user) => user.id !== senderId) : [];

const formatName = (user, shortFormat = false) =>
  user.alias
    ? user.alias
    : `${!shortFormat ? user.last_name : ''} ${user.name}`;

const getConversationUsersNames = (conversationUsers = [], maxLength = 1) => {
  const totalUsers = conversationUsers.map(formatName).join(', ');

  if (conversationUsers.length < maxLength) {
    return { totalUsers, summary: totalUsers };
  }

  const summaryUsers = conversationUsers
    .slice(0, 2)
    .map((user) => formatName(user, true))
    .join(', ');

  const remainingUsersCount =
    conversationUsers.length - maxLength > 1
      ? `, + ${conversationUsers.length - maxLength - 1}`
      : '';

  return { totalUsers, summary: `${summaryUsers} ${remainingUsersCount}` };
};

const getRelatedContact = (contact) => {
  const children = contact.children.map(({ children }) => ({
    ...children,
    role: ROLE_LIST.STUDENT,
    isFamily: true,
  }));

  const parents = contact.parent.map(({ parent }) => ({
    ...parent,
    role: ROLE_LIST.PARENT,
    isFamily: true,
  }));

  return uniqueByKey([...children, ...parents], 'id');
};

const truncatedMessage = (message) => {
  return message?.replace(/<[^>]*>/g, '').substring(0, TRUNCATE_AT);
};

const getHourFromStringTime = (time) => Number(time?.substring(0, 2));

const getMinutesFromStringTime = (time) => Number(time?.substring(3, 5));

const getHardestScheduleConfig = (configs) => {
  if (configs) {
    const openTimeAt = dayjs(
      Math.max(
        ...configs.map((config) =>
          dayjs()
            .hour(getHourFromStringTime(config.open_time_at))
            .minute(getMinutesFromStringTime(config.open_time_at))
            .second(0),
        ),
      ),
    ).format('HH:mm:ss');

    const closeTimeAt = dayjs(
      Math.min(
        ...configs
          .map((config) =>
            dayjs()
              .hour(getHourFromStringTime(config.close_time_at))
              .minute(getMinutesFromStringTime(config.close_time_at))
              .second(0),
          )
          .filter((hour) =>
            hour.isAfter(
              dayjs()
                .hour(getHourFromStringTime(openTimeAt))
                .minute(getMinutesFromStringTime(openTimeAt)),
            ),
          ),
      ),
    ).format('HH:mm:ss');

    const restrictWeekend = configs.reduce(
      (res, config) => res || config.restrict_weekend,
      false,
    );

    const restrictSchedule = configs.reduce(
      (res, config) => res || config.restrict_schedule,
      false,
    );

    return {
      ...configs[0],
      open_time_at: openTimeAt,
      close_time_at: closeTimeAt,
      restrict_weekend: restrictWeekend,
      restrict_schedule: restrictSchedule,
    };
  } else {
    return {};
  }
};

export {
  PAGE_DATA,
  INBOX_HEADER_HEIGHT,
  EMPTY_STATES_TYPES,
  getConversationUsers,
  getConversationUsersNames,
  getRelatedContact,
  truncatedMessage,
  getHourFromStringTime,
  getMinutesFromStringTime,
  getHardestScheduleConfig,
};
