/* eslint-disable no-useless-computed-key */
export default () => ({
  ['es-ar']: import('src/locales/es-ar/messages'),
  ['es-uy']: import('src/locales/es-uy/messages'),
  ['es-pe']: import('src/locales/es-pe/messages'),
  ['es-py']: import('src/locales/es-py/messages'),
  ['es-mx']: import('src/locales/es-mx/messages'),
  ['es-co']: import('src/locales/es-co/messages'),
  ['pt-br']: import('src/locales/pt-br/messages'),
  ['en']: import('src/locales/en-us/messages'),
  ['de-ar']: import('src/locales/de-de/messages'),
});
