import QUERY_KEY from 'src/shared/constants/queryKey';
import { useGetList } from 'src/shared/services/api/query';
import { getUserChildren } from 'src/shared/services/url/UrlUser';

const useUserSons = ({ userId, serializer, ...rest } = {}) => {
  const query = useGetList(
    [QUERY_KEY.USER_SONS, userId],
    getUserChildren(userId),
    { expand: 'custom_role,roles.division,courses' },
    true,
    false,
    { enabled: Boolean(userId), ...rest },
    serializer,
  );

  return {
    ...query,
    data: query?.data ?? [],
  };
};

export default useUserSons;
