import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { Dialog } from '@mui/material';

import { toDateFormat, toIsoDate, addHours } from 'src/shared/utils/dates';
import DialogTitle from 'src/features/payment-management/components/DialogTitle';
import dayjs from 'dayjs';
import useStyles from './styles';
import ScheduledElementForm from './ScheduledElementForm';

const ScheduledElementDialog = memo(
  ({
    isOpen,
    values = {},
    onClose,
    onConfirm,
    buttonId,
    title = t`Programar tarea`,
    confirmText = t`Programar tarea`,
    format = 'YYYY-MM-DD HH:mm:ss',
  }) => {
    const classes = useStyles();
    const today = addHours(1, new Date());
    const { start_datetime, id, due_at } = values;
    const parsedDueAt = due_at ? dayjs(due_at) : undefined;
    const startDateTime = toDateFormat(start_datetime ?? today, format);
    const [isLoading, setIsLoading] = useState(false);

    const hasDate = startDateTime != null;

    const initialValues = {
      start_date: dayjs(startDateTime).startOf('hour'),
    };

    const handleConfirm = async (values) => {
      setIsLoading(true);
      await onConfirm({ id, start_datetime: toIsoDate(values.start_date) });
      setIsLoading(false);
    };

    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isOpen}
        classes={{
          root: classes.dialogRoot,
        }}
      >
        <DialogTitle onClose={onClose} title={title} />

        <ScheduledElementForm
          initialTouched={{
            start_date: true,
          }}
          initialValues={initialValues}
          onSubmit={handleConfirm}
          disabled={isLoading || !hasDate}
          submitLabel={confirmText}
          minDate={today}
          maxDate={parsedDueAt}
          buttonId={buttonId}
        />
      </Dialog>
    );
  },
);

ScheduledElementDialog.displayName = 'ScheduledElementDialog';

ScheduledElementDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  values: PropTypes.object,
  format: PropTypes.string,
  title: PropTypes.string,
  confirmText: PropTypes.string,
  buttonId: PropTypes.string,
};

export default ScheduledElementDialog;
