import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  headerContent: {
    border: '1px solid rgba(0, 0, 0, 0.08)',
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  dialogContent: {
    margin: `${theme.spacing(3)} 0`,
  },
  dialogTitle: {
    padding: 0,
  },
  dialogSubtitle: {
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
