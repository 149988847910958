import React, { Suspense, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Box } from '@mui/material';

import MainLayout from 'src/shared/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import RoleGuard from 'src/components/RoleGuard';
import { BASE_URL, ROLE_LIST } from 'src/config/general';
import lazyWithErrorHandler from 'src/shared/utils/lazyWithErrorHandler';
import MyAccountLayout from './shared/layouts/MyAccountLayout';
import router from './shared/utils/router';
import useAnalytics from './shared/hooks/useAnalytics';

const SentryRoute = Sentry.withSentryRouting(Route);

const routesConfig = [
  {
    exact: true,
    path: [router.auth.login, router.auth.recoveryPassword, router.auth.logout],
    component: lazyWithErrorHandler(() => import('src/features/login')),
  },
  // TERMS AND CONDITIONS
  {
    exact: true,
    path: router.termsAndConditions.root,
    guard: RoleGuard,
    component: lazyWithErrorHandler(() =>
      import('src/features/terms-conditions'),
    ),
  },
  {
    exact: true,
    layout: MainLayout,
    path: router.collections.paymentOrder(':paymentOrderId'),
    component: lazyWithErrorHandler(() =>
      import(
        'src/features/payment-management/views/management/PaymentManagementOrderGeneratedView'
      ),
    ),
  },
  {
    exact: true,
    layout: MainLayout,
    path: router.collections.paymentOrderOnline(':orderId'),
    component: lazyWithErrorHandler(() =>
      import(
        'src/features/payment-management/views/management/PaymentOnlineView'
      ),
    ),
  },
  {
    exact: true,
    path: `${BASE_URL}/loader`,
    component: lazyWithErrorHandler(() => import('src/features/app-loader')),
  },
  {
    exact: true,
    path: router.service.suspendedService,
    component: lazyWithErrorHandler(() =>
      import('src/features/suspended-service'),
    ),
  },
  {
    exact: true,
    path: router.service.blockedUser,
    component: lazyWithErrorHandler(() => import('src/features/blocked-user')),
  },
  {
    path: router.service.redirectDownloadApp,
    component: lazyWithErrorHandler(() =>
      import('src/features/redirect-download-app'),
    ),
  },
  {
    exact: true,
    path: router.auth.firstLogin,
    guard: RoleGuard,
    component: lazyWithErrorHandler(() => import('src/features/first-login')),
  },
  {
    exact: true,
    layout: MyAccountLayout,
    path: [
      router.me.account,
      router.me.attendances,
      router.me.reportCard,
      router.me.grading,
      router.me.primaryReport,
      router.me.behavior,
      router.me.notifications,
      router.me.language,
      router.me.password,
      router.me.taxInformation,
      router.me.createTaxInformation(':familyId'),
      router.me.updateTaxInformation({
        familyId: ':familyId',
        taxId: ':taxInfoId',
      }),
    ],
    guard: RoleGuard,
    component: lazyWithErrorHandler(() => import('src/features/my-account')),
  },
  // CAMBIO DE PERIODO
  {
    path: router.institution.changePeriod(':periodId'),
    guard: RoleGuard,
    component: lazyWithErrorHandler(() =>
      import('src/features/institution/views/ChangePeriodView'),
    ),
  },
  // CAMBIO DE INSTITUTION
  {
    path: router.institution.changeInstitution(':institutionId'),
    component: lazyWithErrorHandler(() =>
      import('src/features/institution/views/ChangeInstitutionView'),
    ),
  },

  // LAYOUT PRINCIPAL
  {
    path: '/',
    layout: MainLayout,
    routes: [
      // SCHOOL DASHBOARD -----------
      {
        exact: true,
        path: [router.communication.dashboard.root],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/school-dashboard'),
        ),
      },

      // NEWSFEED -----------
      {
        exact: true,
        path: [
          router.communication.newsfeed.root,
          router.communication.newsfeed.myPosts,
          router.communication.newsfeed.savedPosts,
          router.communication.newsfeed.scheduledPosts,
          router.communication.newsfeed.readConfirmation,
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/newsfeed/views/NewsfeedView'),
        ),
      },
      // POST
      {
        exact: true,
        path: router.communication.newsfeed.post(':postId'),
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/newsfeed/views/PostView'),
        ),
      },
      // SUBJECTS ------------
      {
        exact: true,
        path: [
          router.communication.subjects.root,
          router.communication.subjects.extracurricular,
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/subjects/views/SubjectListView'),
        ),
      },
      {
        exact: true,
        path: [
          router.communication.subjects.subject(':subjectId'),
          router.communication.subjects.posts(':subjectId'),
          router.communication.subjects.tasks(':subjectId'),
          router.communication.subjects.members(':subjectId'),
          router.communication.subjects.savedPosts(':subjectId'),
          router.communication.subjects.links(':subjectId'),
          router.communication.subjects.scheduledPosts(':subjectId'),
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/subjects/views/SubjectDetailsView'),
        ),
      },
      // DIVISION
      {
        exact: true,
        path: router.communication.divisions.root,
        guard: RoleGuard,
        component: lazyWithErrorHandler(() => import('src/features/divisions')),
      },
      {
        path: [
          router.communication.divisions.division(':divisionId'),
          router.communication.divisions.posts(':divisionId'),
          router.communication.divisions.savedPosts(':divisionId'),
          router.communication.divisions.scheduledPosts(':divisionId'),
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/divisions/components/DivisionDetailsView'),
        ),
      },

      // TASKS -------------
      {
        exact: true,
        path: [
          router.integrations.virtualClassroom.root,
          router.integrations.virtualClassroom.questions,
          router.integrations.virtualClassroom.scheduled,
          router.integrations.virtualClassroom.students,
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/virtual-classroom/views/TaskView'),
        ),
      },
      {
        exact: true,
        path: router.integrations.virtualClassroom.task(':taskId'),
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/virtual-classroom/views/TasksDetailsView'),
        ),
      },
      // GROUPS ------------
      {
        exact: true,
        path: [
          router.communication.groups.root,
          router.communication.groups.members(':groupId'),
          router.communication.groups.posts(':groupId'),
          router.communication.groups.savedPosts(':groupId'),
          router.communication.groups.scheduledPosts(':groupId'),
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/groups/views/GroupsView'),
        ),
      },
      // CALENDAR -----------
      {
        exact: true,
        path: router.communication.calendar.root,
        guard: RoleGuard,
        component: lazyWithErrorHandler(() => import('src/features/calendar')),
      },
      // PICKUPS -----------
      {
        exact: true,
        path: [router.communication.pickUps.root],
        guard: RoleGuard,
        roles: [
          ROLE_LIST.ADMIN,
          ROLE_LIST.EXECUTIVE,
          ROLE_LIST.PRECEPTOR,
          ROLE_LIST.TEACHER,
        ],
        component: lazyWithErrorHandler(() => import('src/features/pickups')),
      },
      {
        exact: true,
        path: [router.configuration.pickups],
        guard: RoleGuard,
        roles: [ROLE_LIST.ADMIN],
        component: lazyWithErrorHandler(() =>
          import(
            'src/features/pickups/views/configuration/PickupConfigurationView'
          ),
        ),
      },
      {
        exact: true,
        path: [
          router.configuration.regularPickups,
          router.configuration.extraordinaryPickups,
          router.configuration.authorizedPickups,
        ],
        guard: RoleGuard,
        roles: [ROLE_LIST.ADMIN],
        component: lazyWithErrorHandler(() =>
          import(
            'src/features/pickups/views/configuration/PickupConfigurationTabView'
          ),
        ),
      },

      // Messages -----------
      {
        exact: true,
        path: [
          router.communication.inbox.root,
          router.communication.inbox.contacts,
          router.communication.inbox.newMessage,
          router.communication.inbox.newBroadcastMessage,
          router.communication.inbox.conversation(':conversationId'),
          router.communication.draft.root,
          router.communication.draft.conversation(':conversationId'),
          router.communication.scheduled.root,
          router.communication.scheduled.conversation(':conversationId'),
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() => import('src/features/messages')),
      },

      // CONTROL ----------------
      {
        exact: true,
        path: [
          router.integrations.documents.root,
          router.integrations.documents.module(':notebookModuleId'),
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/control/views/ControlView'),
        ),
      },

      // PENDING ---------------
      {
        exact: true,
        path: [
          router.communication.pending.root,
          router.communication.pending.pending(':notebookModuleId'),
          router.communication.pending.all(':notebookModuleId'),
          router.communication.pending.unconfirmed(':notebookModuleId'),
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/pending/views/PendingView'),
        ),
      },

      // ACTIVITY -----------
      {
        path: [
          router.communication.activity.root,
          router.communication.activity.activityDetail(':activityId'),
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() => import('src/features/activity')),
      },

      // PAYMENT MANAGEMENT ----------------
      {
        exact: true,
        path: [
          router.collections.root,
          router.collections.families,
          router.collections.generateInvoices,
          router.collections.generateMassiveInvoices,
          router.collections.generateMassiveInvoicesSent,
          router.collections.generateMassiveInvoicesConcepts,
          router.collections.generateMassiveInvoicesDiscounts,
          router.collections.generateExtraordinaryInvoices,
          router.collections.generateExternalInfo,
          router.collections.control,
          router.collections.generateBill,
          router.collections.onboarding,
          router.collections.onlineCollection,
          router.collections.billing,
          router.collections.billed,
          router.collections.emptyBilling,
          router.collections.generatingBilling(':batchId'),
          router.collections.familyTaxInformation(':familyId'),
          router.collections.createFamilyTaxInformation(':familyId'),
          router.collections.updateFamilyTaxInformation({
            familyId: ':familyId',
            taxInformationId: ':taxInfoId',
          }),
          router.collections.familyTaxInformationDetails({
            familyId: ':familyId',
            taxInformationId: ':taxInfoId',
          }),
          router.collections.family(':familyId'),
          router.collections.familyBalance(':familyId'),
          router.collections.familyConceptsAndDiscounts(':familyId'),
          router.collections.familyGroup(':familyId'),
          router.collections.createFamilyGroupResponsible(':familyId'),
          router.collections.updateFamilyGroupResponsible({
            familyId: ':familyId',
            responsibleId: ':responsibleId',
          }),
          router.collections.familyGroupResponsible({
            familyId: ':familyId',
            responsibleId: ':responsibleId',
          }),
          router.collections.familyExternalEntities(':familyId'),
          router.collections.familyOtherDiscounts(':familyId'),
          router.collections.familyTransactions(':familyId'),
          router.collections.cashFlow,
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/payment-management'),
        ),
      },
      {
        exact: true,
        path: [
          router.configuration.collection,
          router.configuration.paymentButton,
          router.configuration.paymentButtonAccountDetails,
          router.configuration.paymentButtonDocuments,
          router.configuration.invoices,
          router.configuration.billing,
          router.configuration.billingCertificates(':taxInformationId'),
          router.configuration.complementIEDU(':taxInformationId'),
          router.configuration.customData(':taxInformationId'),
          router.configuration.billingAdvanceConfig,
          router.configuration.billingConfig(':taxInformationId'),
          router.configuration.billingForm(':taxInformationId'),
          router.configuration.paymentMethods,
          router.configuration.paymentMethodExternalEntities,
          router.configuration.permissions,
          router.configuration.adminPermissions,
          router.configuration.executivePermissions,
          router.configuration.concepts,
          router.configuration.template,
          router.configuration.debtReminders,
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import(
            'src/features/payment-management/views/configuration/PaymentManagementConfigurationView'
          ),
        ),
      },
      {
        exact: true,
        path: router.configuration.taxInformation,
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import(
            'src/features/payment-management/views/FamilyTaxInformation/ProfileFamilyTaxInformationView'
          ),
        ),
      },

      // REPORTS -----------
      {
        exact: true,
        path: [router.configuration.reports],
        guard: RoleGuard,
        roles: [ROLE_LIST.ADMIN, ROLE_LIST.EXECUTIVE],
        component: lazyWithErrorHandler(() =>
          import('src/features/reports/views/configuration/SettingReportsView'),
        ),
      },
      {
        exact: true,
        path: [router.integrations.primaryReports.root],
        guard: RoleGuard,
        roles: [
          ROLE_LIST.ADMIN,
          ROLE_LIST.EXECUTIVE,
          ROLE_LIST.PRECEPTOR,
          ROLE_LIST.TEACHER,
        ],
        component: lazyWithErrorHandler(() => import('src/features/reports')),
      },
      {
        exact: true,
        path: [
          router.integrations.primaryReports.root,
          router.integrations.primaryReports.history,
        ],
        guard: RoleGuard,
        roles: [
          ROLE_LIST.ADMIN,
          ROLE_LIST.EXECUTIVE,
          ROLE_LIST.PRECEPTOR,
          ROLE_LIST.TEACHER,
        ],
        component: lazyWithErrorHandler(() => import('src/features/reports')),
      },

      // NOTIFICATIONS -----------
      {
        exact: true,
        path: router.notifications.root,
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/notifications'),
        ),
      },

      // ATTENDANCE -----------
      {
        exact: true,
        path: [
          router.integrations.attendance.root,
          router.integrations.attendance.subject,
          router.integrations.attendance.total,
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/attendance'),
        ),
      },
      {
        exact: true,
        path: [
          router.configuration.attendance,
          router.configuration.attendanceScales,
          router.configuration.attendanceScaleSubjects,
          router.configuration.attendanceScaleExtracurricular,
          router.configuration.attendanceAlerts,
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/attendance/views/AttendanceConfigurationView'),
        ),
      },
      {
        exact: true,
        path: [
          router.integrations.grading.root,
          router.integrations.grading.general,
          router.integrations.grading.add,
          router.integrations.grading.send,
          router.integrations.grading.reportCard,
          router.integrations.grading.reportCardAttendances,
          router.integrations.grading.reportCardSignatures,
          router.integrations.grading.reportCardComments,
          router.integrations.grading.reportCardPendingSubjects,
          router.integrations.grading.reportCardConcepts,
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/grading/views/grading/GradingView'),
        ),
      },
      {
        exact: true,
        path: [
          router.configuration.grading,
          router.configuration.gradingLevels,
          router.configuration.gradingSubjects,
          router.configuration.gradingLevel(':levelId'),
          router.configuration.gradingLevelContest(':levelId'),
          router.configuration.gradingLevelGrading(':levelId'),
          router.configuration.gradingLevelScales(':levelId'),
          router.configuration.gradingSubject(':levelId'),
          router.configuration.gradingSubjectContest(':levelId'),
          router.configuration.gradingSubjectGrading(':levelId'),
          router.configuration.gradingSubjectScales(':levelId'),
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import(
            'src/features/grading/views/configuration/GradingConfigurationView'
          ),
        ),
      },
      {
        exact: true,
        path: [
          router.billingStatement.root,
          router.billingStatement.account,
          router.billingStatement.invoices,
          router.billingStatement.taxInformation,
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/billing-statement'),
        ),
      },
      {
        path: [
          router.configuration.root,
          router.configuration.behavior,
          router.configuration.institutionProfile,
          router.configuration.schoolManagement,
          router.configuration.schoolManagementCourse(':courseId'),
          router.configuration.schoolManagementFamilies,
          router.configuration.schoolManagementFamilyDetails(':familyId'),
          router.configuration.schoolManagementCommunity,
          router.configuration.periods,
          router.configuration.schoolManagementSpecialSubjects,
          router.configuration.schoolManagementSpecialSubjectDetail(
            ':subjectId',
          ),
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/settings/views/SettingsView'),
        ),
      },
      {
        exact: true,
        path: [
          router.profile.profile(':userId'),
          router.profile.attendances(':userId'),
          router.profile.grading(':userId'),
          router.profile.reportCard(':userId'),
          router.profile.primaryReport(':userId'),
          router.profile.behavior(':userId'),
          router.profile.billing(':userId'),
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/my-account/views/ProfileView'),
        ),
      },
      {
        exact: true,
        path: [router.profile.authorized(':userId')],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/my-account/views/AuthorizedInfoView'),
        ),
      },
      {
        exact: true,
        path: [router.profile.pickupCategory(':userId')],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/my-account/views/AuthorizedInfoPickupCategory'),
        ),
      },

      // INSTITUTION PERIOD
      {
        exact: true,
        path: [
          router.institution.institutionPeriod,
          router.institution.institutionPeriodDashboard,
          router.institution.institutionPeriodComunity,
          router.institution.institutionPeriodManagement,
          router.institution.institutionPeriodManagementPeriods,
          router.institution.InstitutionExtracurricular,
          router.institution.InstitutionExtracurricularDetail(':subjectId'),
          router.institution.schoolManagementCourse(':courseId', ':divisionId'),
        ],
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/features/institution/views/InstitutionView'),
        ),
      },

      // AI ASSISTANT -----------
      {
        exact: true,
        path: [
          router.assistant.root,
          router.assistant.documents,
          router.assistant.faq,
          router.assistant.thread,
          router.assistant.threadById(':threadId'),
        ],
        guard: RoleGuard,
        roles: [ROLE_LIST.ADMIN],
        component: lazyWithErrorHandler(() =>
          import('src/features/ai-assistant'),
        ),
      },

      // SEGUIMIENTO/BEHAVIOUR
      {
        path: router.communication.behavior.root,
        guard: RoleGuard,
        component: lazyWithErrorHandler(() => import('src/features/behaviour')),
      },

      // ERRORES -----------------
      {
        path: router.errors[404],
        component: lazyWithErrorHandler(() => import('src/shared/views/404')),
      },

      // TODO: Eventualmente hay que reemplazar esto con la nueva pagina de 404/403
      // LEGACY ROUTES --------------
      {
        path: '*',
        guard: RoleGuard,
        component: lazyWithErrorHandler(() =>
          import('src/shared/views/LegacyRoutes'),
        ),
      },
    ],
  },
];

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen fullScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Box;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <SentryRoute
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard roles={route.roles}>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

const Routes = () => {
  // tiene que usarse en la misma altura de donde hacemos el Switch
  useAnalytics();
  return renderRoutes(routesConfig);
};

export default Routes;
