const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';
const SCRIPT_ID = 'googlePicker';

const loadGoogleScript = (callback) => {
  const existingScript = document.getElementById(SCRIPT_ID);

  if (!existingScript) {
    const script = document.createElement('script');

    script.src = GOOGLE_SDK_URL;
    script.id = SCRIPT_ID;

    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};
export default loadGoogleScript;
