import { useGetOne } from 'src/shared/services/api/query';
import { dLocalAccountInformation } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import oneElementArraySerializer from 'src/shared/services/api/query/serializer/oneElementArraySerializer';

const useGetDLocalAccountInformation = ({ enabled = true } = {}) => {
  const customSerializer = oneElementArraySerializer();

  return useGetOne(
    [QUERY_KEY.DLOCAL_ACCOUNT],
    dLocalAccountInformation(),
    undefined,
    false,
    false,
    { enabled },
    customSerializer,
  );
};

export default useGetDLocalAccountInformation;
