import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Checkbox,
  TableCell,
  TableRow,
  IconButton,
  TableHead,
  Typography,
  Box,
} from '@mui/material';
import { Filter as FilterIcon } from 'react-feather';

import useStyles from './styles';

const Head = ({
  selectedAllItems = false,
  selectedSomeItems = false,
  columns = [],
  onSelectAllItems,
  withActions,
  className,
  onSortChange,
  stickyFirstColumn,
  enableChecks,
  checkProps = {},
  selectedCount,
  totalCount,
  TotalSelectionComponent,
  actionsHeader,
  withDeselectionDefault,
  ...rest
}) => {
  const classes = useStyles();
  const { selectedAllDisabled, ...restOfCheckProps } = checkProps;
  const showTotalSelection =
    withDeselectionDefault || (selectedCount > 0 && selectedCount < totalCount);

  return (
    <TableHead className={clsx(classes.root, className)} {...rest}>
      {actionsHeader && (
        <TableRow sx={{ height: 'max-content' }}>
          <TableCell colSpan={columns.length + (enableChecks ? 1 : 0)}>
            <Box display={'flex'} flexDirection={'row'}>
              {enableChecks && (
                <Checkbox
                  data-testid="table-checkbox"
                  size="small"
                  checked={selectedAllItems}
                  indeterminate={selectedSomeItems}
                  onChange={onSelectAllItems}
                  {...restOfCheckProps}
                  disabled={selectedAllDisabled}
                />
              )}
              {actionsHeader}
            </Box>
          </TableCell>
        </TableRow>
      )}

      <TableRow className={classes.tableRow}>
        {enableChecks && (
          <TableCell padding="checkbox" style={{ top: actionsHeader && 53 }}>
            {!actionsHeader && (
              <Checkbox
                inputProps={{ 'data-testid': 'table-checkbox' }}
                size="small"
                checked={selectedAllItems}
                indeterminate={selectedSomeItems}
                onChange={onSelectAllItems}
                {...restOfCheckProps}
                disabled={selectedAllDisabled}
              />
            )}
          </TableCell>
        )}

        {columns.map((header, index) => {
          const isFirstColumn = index === 0;
          return (
            <TableCell
              align={header.align}
              key={header.key}
              className={clsx({
                [classes.stickyColumn]: stickyFirstColumn && isFirstColumn,
              })}
              style={{ top: actionsHeader && 53, ...header.style }}
              {...header.props}
            >
              {header.renderColumnCell ? (
                header.renderColumnCell(header)
              ) : (
                <Typography variant="h3">
                  {header.title}{' '}
                  {header.sort && (
                    <IconButton
                      onClick={() => onSortChange(header.key)}
                      size="small"
                    >
                      <FilterIcon size={15} />
                    </IconButton>
                  )}
                </Typography>
              )}
            </TableCell>
          );
        })}

        {withActions && <TableCell align="right" />}
      </TableRow>
      {showTotalSelection && TotalSelectionComponent}
    </TableHead>
  );
};

Head.propTypes = {
  onSelectAllItems: PropTypes.func,
  onSortChange: PropTypes.func,
  columns: PropTypes.array.isRequired,
  withActions: PropTypes.bool,
  selectedAllItems: PropTypes.bool.isRequired,
  selectedSomeItems: PropTypes.bool.isRequired,
  className: PropTypes.string,
  enableChecks: PropTypes.bool,
  checkProps: PropTypes.object,
  stickyFirstColumn: PropTypes.bool,
  selectedCount: PropTypes.number,
  totalCount: PropTypes.number,
  TotalSelectionComponent: PropTypes.node,
  actionsHeader: PropTypes.bool,
  withDeselectionDefault: PropTypes.bool,
};

export default Head;
