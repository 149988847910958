export const hasAlpha = (context, canvas) => {
  const { data } = context.getImageData(0, 0, canvas.width, canvas.height);
  let hasAlphaPixels = false;
  for (let i = 3, n = data.length; i < n; i += 4) {
    if (data[i] < 255) {
      hasAlphaPixels = true;
      break;
    }
  }
  return hasAlphaPixels;
};
