import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';

import stepFamily, { stepConfigFamily } from '../../atoms/step/stepFamily';

const useResetStep = (name) => {
  const stepConfigAtom = stepConfigFamily({ name });
  const setStepConfig = useSetAtom(stepConfigAtom);

  const stepAtom = stepFamily({ name });
  const resetStep = useResetAtom(stepAtom);

  return (stepConfig = undefined) => {
    if (stepConfig) {
      setStepConfig((prev) => {
        return { ...prev, ...stepConfig };
      });
    }
    resetStep();
  };
};

export default useResetStep;
