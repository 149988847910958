import camelToSnake from 'snakecase-keys';
import {
  removeEmptyObjectKey,
  handleCanUseSnakeCase,
} from 'src/shared/helpers';

/* eslint-disable no-param-reassign */
const request = ({
  service,
  method,
  url,
  data,
  getRaw,
  headers,
  cancelToken,
  allowNull = false,
  ...rest
}) => {
  const options = {
    method,
    url,
    responseType: 'json',
    cancelToken,
    ...rest,
  };

  if (data && method === 'GET') {
    // TODO: REVISAR ESTO A VER QUE HACEMOS
    const nonEmptyParams = removeEmptyObjectKey(data);
    const snakelizedParams = camelToSnake(nonEmptyParams);
    const canUseSnakeCase = handleCanUseSnakeCase(nonEmptyParams);
    const newParams = canUseSnakeCase ? snakelizedParams : nonEmptyParams;

    if (newParams.per_page) {
      // Replacing per_page param util backend accept per_page
      newParams['per-page'] = newParams.per_page;
      delete newParams.per_page;
    }

    options.params = newParams;
  } else if (data && method === 'UPLOAD') {
    options.method = 'POST';
    options.headers = {
      'Content-Type': 'multipart/form-data',
    };

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'files' && Array.isArray(value)) {
        value.forEach((file) => formData.append('files', file));
      } else {
        formData.append(key, value);
      }
    });
    options.data = formData;
  } else if (data) {
    const nonEmptyParams = removeEmptyObjectKey(data, allowNull);
    const snakelizedParams = camelToSnake(nonEmptyParams);
    const getParams = {};

    if (snakelizedParams.per_page) {
      getParams['per-page'] = snakelizedParams.per_page;
    }

    if (snakelizedParams.page) {
      getParams.page = snakelizedParams.page;
    }

    if (snakelizedParams.expand) {
      getParams.expand = snakelizedParams.expand;
      delete snakelizedParams.expand;
    }

    if (snakelizedParams.sort) {
      getParams.sort = snakelizedParams.sort;
      delete snakelizedParams.sort;
    }

    options.params = getParams;
    options.data = Array.isArray(data) ? data : JSON.stringify(data);
    options.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  if (headers) {
    options.headers = {
      ...options.headers,
      ...headers,
    };
  }

  return new Promise((resolve, reject) => {
    service
      .request(options)
      .then((response) => {
        resolve(getRaw ? response : response.data);
      })
      .catch((error) => {
        if (error.response) {
          const message = error?.response?.data?.data?.message ?? '';
          if (message) error.message = message;
        } else if (error.request) {
          // No server response
        } else {
          error.data = false;
        }

        reject(error);
      });
  });
};

export default request;
