import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  statusIcon: {
    color: theme.palette.primary.main,
  },
  statusDisabled: {
    color: theme.palette.grey['300'],
  },
  openButton: {
    position: 'absolute',
    marginTop: `-${theme.spacing(2)}`,
  },
}));

export default useStyles;
