import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAtom } from 'jotai';
import { useDebouncedCallback } from 'use-debounce';

import { dialogs } from 'src/shared/constants';
import {
  useDialogIsOpen,
  useOpenDialog,
  useUsersSearch,
} from 'src/shared/hooks';

import {
  useCreateFamilyInformation,
  useUnparentStudent,
  useUpdateFamilyInformation,
} from 'src/features/payment-management/hooks';
import { selectedNewFamilyUsersAtom } from 'src/features/payment-management/atoms';
import { ROLE_LIST } from 'src/config/general';
import isNewFamilyAtom from 'src/shared/atoms/family/isNewFamilyAtom';
import { STUDENT_ROLE_ID } from 'src/config/constants';
import { checkRole } from 'src/shared/helpers';
import { HIGH_ROLES } from 'src/features/school-dashboard/constants/helpers';

import queryClient from 'src/config/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const useSelectUnparentStudent = () => {
  const hasAccess = checkRole(HIGH_ROLES);

  const isDialogOpen = useDialogIsOpen(dialogs.SELECT_UNPARENT_STUDENTS_DIALOG);
  const openDialog = useOpenDialog(dialogs.SELECT_UNPARENT_STUDENTS_DIALOG);
  const openFamilyGroupDialog = useOpenDialog(dialogs.FAMILY_GROUP_DIALOG);
  const openFamilyInfoDialog = useOpenDialog(dialogs.FAMILY_INFO_DIALOG);
  const openNewFamilyConfirmDialog = useOpenDialog(
    dialogs.CONFIRM_NEW_FAMILY_GROUP_DIALOG,
  );
  const openAssignFamilyDialog = useOpenDialog(
    dialogs.ASSIGN_TO_FAMILY_GROUP_DIALOG,
  );
  const institutionId = useSelector(({ user }) => user.selectedInstitution.id);
  const createFamilyInformation = useCreateFamilyInformation();
  const { data: unparentStudents } = useUnparentStudent({ enabled: hasAccess });
  const [selectedNewFamilyUsers, setSelectedNewFamilyUsers] = useAtom(
    selectedNewFamilyUsersAtom,
  );

  const [selectedStudentName, setSelectedStudentName] = useState('');
  const [selectedFamily, setSelectedFamily] = useState('');
  const updateFamilyInformation = useUpdateFamilyInformation(selectedFamily.id);
  const [isNewFamily, setIsNewFamily] = useAtom(isNewFamilyAtom);

  const unparentStudentIds = useMemo(
    () => unparentStudents?.map(({ id }) => id),
    [unparentStudents],
  );

  const {
    users: searchUsers,
    loading,
    search,
    handleSearch,
    handleChangeParam,
  } = useUsersSearch({
    ids: unparentStudentIds,
    enabled: isDialogOpen,
    roles: [STUDENT_ROLE_ID],
  });

  const hasParentSelected = useMemo(
    () => selectedNewFamilyUsers.some((item) => item.role === ROLE_LIST.PARENT),
    [selectedNewFamilyUsers],
  );

  const handleCloseDialog = () => {
    setIsNewFamily(false);
    openDialog(false);
  };

  const handleSelectStudent = (student) => {
    setSelectedStudentName(`${student.name} ${student.last_name}`);
    setSelectedNewFamilyUsers([
      {
        role: 'child',
        institution_id: institutionId,
        isUnparent: true,
        user_id: student.id,
        ...student,
      },
    ]);
    openDialog(false);

    if (!isNewFamily) openNewFamilyConfirmDialog(true);
    else openFamilyGroupDialog(true);
  };

  const handleGroupNextButton = () => {
    openFamilyGroupDialog(false);
    openFamilyInfoDialog(true);
  };

  const handleGroupBackButton = () => {
    openFamilyGroupDialog(false);

    if (!isNewFamily) openNewFamilyConfirmDialog(true);
    else openDialog(true);
  };

  const handleCreateButton = (values, formikBag) => {
    const users = selectedNewFamilyUsers.map(({ user_id, role }) => ({
      user_id,
      role,
    }));

    createFamilyInformation.mutate(
      {
        ...values,
        users,
      },
      {
        onSuccess: () => {
          openFamilyInfoDialog(false);
          queryClient.invalidateQueries([QUERY_KEY.FAMILY]);
        },
      },
    );

    formikBag.resetForm();
  };

  const handleInfoBackButton = () => {
    openFamilyInfoDialog(false);
    openFamilyGroupDialog(true);
  };

  const handleNewFamilyNextButton = (values) => {
    if (values.assign_family === 'yes') {
      openNewFamilyConfirmDialog(false);
      openAssignFamilyDialog(true);
    } else {
      openNewFamilyConfirmDialog(false);
      openFamilyGroupDialog(true);
    }
  };

  const handleNewFamilyBackButton = () => {
    setIsNewFamily(true);
    openNewFamilyConfirmDialog(false);
    openDialog(true);
  };

  const handleupdateFamilyInformation = useDebouncedCallback((value) => {
    const { name, address, phone, email, institution_id, users } = value;

    const selectedUser = selectedNewFamilyUsers.map(({ user_id, role }) => ({
      user_id,
      role,
    }));

    updateFamilyInformation.mutate(
      {
        name,
        address,
        phone,
        email,
        institution_id,
        users: [...users, ...selectedUser],
      },
      {
        onSuccess: () => {
          openAssignFamilyDialog(false);
          queryClient.invalidateQueries([QUERY_KEY.FAMILY]);
        },
      },
    );
  }, 300);

  const handleAssignFamilyAssignButton = (values, formikBag) => {
    setSelectedFamily(values.selected_family);
    handleupdateFamilyInformation(values.selected_family);
    formikBag.resetForm();
  };

  const handleAssignFamilyBackButton = () => {
    openAssignFamilyDialog(false);
    openNewFamilyConfirmDialog(true);
  };

  const handleChangeFilters = ({ divisions }) => {
    handleChangeParam({ divisions });
  };

  useEffect(() => {
    return () => {
      setSelectedNewFamilyUsers([]);
    };
  }, [setSelectedNewFamilyUsers]);

  return {
    isDialogOpen,
    selectedStudentName,
    students: searchUsers,
    loading,
    search,
    hasParentSelected,
    unparentStudents,
    handleCloseDialog,
    handleSearch,
    handleChangeFilters,
    handleSelectStudent,
    handleGroupNextButton,
    handleGroupBackButton,
    handleCreateButton,
    handleInfoBackButton,
    handleNewFamilyNextButton,
    handleNewFamilyBackButton,
    handleAssignFamilyAssignButton,
    handleAssignFamilyBackButton,
  };
};

export default useSelectUnparentStudent;
