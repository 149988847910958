import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { useCreate } from 'src/shared/services/api/query';
import { getUploadToken } from 'src/shared/services/url/UrlCommunication';

const ERROR_MESSAGE = t`Hubo un error al intentar generar el token del archivo`;

const useGenerateFileToken = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useCreate(
    getUploadToken(),
    {
      onError: ({ message: error }) => {
        const message = error?.message ?? '';
        enqueueSnackbar(message ?? ERROR_MESSAGE, { variant: 'error' });
      },
    },
    false,
  );
};

export default useGenerateFileToken;
