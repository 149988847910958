import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import { Trans, t } from '@lingui/macro';
import { formatDivisionName } from 'src/shared/helpers';

import InfoTitle from '../InfoTitle';

const DIVISIONS_LIMIT = 6;
const TRUNCATE_AT = 3;

const DivisionInfo = ({ roles = [], textProps, loading }) => {
  const divisionName = formatDivisionName(roles);
  const divisionsArray = divisionName.split(',');
  const truncatedDivisions = divisionsArray.splice(0, TRUNCATE_AT).join(', ');
  const [shouldTruncate, setShowingDivisions] = useState(
    divisionsArray.length > DIVISIONS_LIMIT,
  );

  const handleToggleShowMore = () => {
    setShowingDivisions(false);
  };

  if (!loading && !divisionName.length) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <InfoTitle title={t`Nivel y división`}>
        <Box display={'flex'} flexDirection={'row'} mt={0}>
          {loading ? (
            <Skeleton width={80} />
          ) : (
            <Typography color={'text.secondary'} variant="body2" {...textProps}>
              {shouldTruncate ? (
                <>
                  {truncatedDivisions} y{' '}
                  <Button
                    variant="text"
                    sx={{ p: 0, ml: 1, minWidth: 'unset' }}
                    onClick={handleToggleShowMore}
                  >
                    <Trans>{divisionsArray.length} más</Trans>
                  </Button>
                </>
              ) : (
                divisionName
              )}
            </Typography>
          )}
        </Box>
      </InfoTitle>
    </Grid>
  );
};

DivisionInfo.propTypes = {
  textProps: PropTypes.object,
  roles: PropTypes.array,
  loading: PropTypes.bool,
};

export default DivisionInfo;
