import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { useGetList } from 'src/shared/services/api/query';
import { getUnparentStudent } from 'src/shared/services/url/UrlUser';

const useUnparentStudent = (queryParams) => {
  return useGetList(
    [QUERY_KEY.UNPARENT_STUDENTS],
    getUnparentStudent(),
    { 'per-page': 2000 },
    true,
    false,
    queryParams,
  );
};

export default useUnparentStudent;
