import { EVENT_COLORS, EVENT_TYPES } from 'src/config/general';

export const getColorByEventType = (typeId) => {
  switch (typeId) {
    case EVENT_TYPES.HOMEWORKS:
      return EVENT_COLORS.HOMEWORKS;
    case EVENT_TYPES.GRADINGS:
      return EVENT_COLORS.GRADINGS;
    default:
      return EVENT_COLORS.DEFAULT;
  }
};
