const DINING_HALL = {
  id: 1334,
  description: 'Comedor Escolar',
};

const hardCodeInitValuesArg = {
  productCode: '1',
  productDescription: 'Cuota escolar - Aviso de pago - Saldo deudor',
  productUnit: {
    id: 1,
    name: 'Uni',
  },
  neto: '-',
  iva: '-',
  switchReceiver: true,
  hasResponsibleBiller: false,
  limitedOfMaxAmount: false,
  receiptType: null,
  pointOfSale: null,
  serviceType: null,
};

export { hardCodeInitValuesArg, DINING_HALL };
