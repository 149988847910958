import dayjs from 'dayjs';
import { GUEST_TYPE } from '../constants';

const getLevelsFromDivisions = (divisions = []) => [
  ...new Set(divisions.map((division) => division.course?.level_id)),
];

const getGuestType = (event) => {
  if (event.group_id) return GUEST_TYPE.GROUP;

  return event.roles_divisions?.users?.length > 0
    ? GUEST_TYPE.INDIVIDUAL
    : GUEST_TYPE.GENERAL;
};

const parseEventToFormValues = (event) => {
  // check empty object
  if (Object.entries(event ?? {}).length === 0) {
    return undefined;
  }
  return {
    id: event.id,
    title: event.title,
    description: event.text,
    isAllDay: event.all_day,
    startDate: dayjs(event.start_datetime).format(),
    startDateHour: dayjs(event.start_datetime).format(),
    guestType: getGuestType(event),
    endDate: dayjs(event.end_datetime).format(),
    endDateHour: dayjs(event.end_datetime).format(),
    [GUEST_TYPE.GENERAL]: {
      divisions:
        event.roles_divisions?.divisions?.map((division) => division.id) ?? [],
      roles: event.roles_divisions?.roles ?? [],
      levels: getLevelsFromDivisions(event.roles_divisions?.divisions),
      subjects: [],
    },
    [GUEST_TYPE.INDIVIDUAL]: event.roles_divisions?.users ?? [],
    withAssistance: event.rvsp,
    withNotifications: event.enable_notification,
    [GUEST_TYPE.GROUP]: event.group_id,
  };
};

export default parseEventToFormValues;
