import { useSelector } from 'react-redux';

const useIsOnlySchoolStaff = () => {
  const { isTeacher, isPreceptor, isExecutive } = useSelector(({ user }) => ({
    isTeacher: user.userIs.teacher,
    isPreceptor: user.userIs.preceptor,
    isExecutive: user.userIs.executive,
  }));

  return isTeacher || isPreceptor || isExecutive;
};
export default useIsOnlySchoolStaff;
