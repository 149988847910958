// EMPTY STATES IMAGES
import { ReactComponent as NewsfeedEmptyImage } from 'src/assets/images/emptystates/newsfeed/newsfeed-emptystate.svg';
import { ReactComponent as MyPostsEmptyImage } from 'src/assets/images/emptystates/newsfeed/my-posts-emptystate.svg';
import { ReactComponent as SavedPostsEmptyImage } from 'src/assets/images/emptystates/newsfeed/saved-emptystate.svg';
import { ReactComponent as SearchEmptyImage } from 'src/assets/images/emptystates/search/search-emptystate.svg';
import { ReactComponent as GroupsEmptyImage } from 'src/assets/images/emptystates/groups/groups-emptystate.svg';
import { ReactComponent as SuccessEmptyImage } from 'src/assets/images/emptystates/success/success-emptystate.svg';
import { ReactComponent as ContacsIllustration } from 'src/assets/images/emptystates/empty-state-illustration.svg';
import { ReactComponent as RocketImage } from 'src/assets/images/emptystates/rocket/rocket-emptystate.svg';

export const getImageByType = (type) => {
  switch (type) {
    case 'newsfeed':
      return NewsfeedEmptyImage;
    case 'savedPosts':
      return SavedPostsEmptyImage;
    case 'myPosts':
      return MyPostsEmptyImage;
    case 'search':
      return SearchEmptyImage;
    case 'groups':
      return GroupsEmptyImage;
    case 'success':
      return SuccessEmptyImage;
    case 'illustration':
      return ContacsIllustration;
    case 'rocket':
      return RocketImage;

    default:
      return NewsfeedEmptyImage;
  }
};
