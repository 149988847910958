import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { updateFamilyExternalEntity } from 'src/shared/services/url/UrlPaymentManagement';
import { useUpdate } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const useUpdateFamilyExternalEntity = ({ id }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useUpdate(
    updateFamilyExternalEntity(id),
    {
      onSuccess: () => {
        enqueueSnackbar(t`Entidad externa actualizada con éxito.`, {
          variant: 'success',
        });
      },
      onError: ({ message }) => {
        enqueueSnackbar(
          message ?? t`Hubo un error al intentar actualizar la entidad externa`,
          {
            variant: 'error',
          },
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries([QUERY_KEY.FAMILY_EXTERNAL_ENTITY]);
      },
    },
    false,
  );
};

export default useUpdateFamilyExternalEntity;
