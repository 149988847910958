import React from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Box,
} from '@mui/material';
import { Plural, t, Trans } from '@lingui/macro';
import { X as CloseIcon } from 'react-feather';
import { useAtomValue, useSetAtom } from 'jotai';
import { useHistory } from 'react-router';

import { useDialogIsOpen, useOpenDialog } from 'src/shared/hooks';
import { dialogs } from 'src/shared/constants';

import { selectedUserInfoAtom } from 'src/features/my-account/atoms';
import PersonalInfo from 'src/features/my-account/views/ProfileView/ProfileInfo/PersonalInfo';
import { useUserInfoView } from 'src/features/my-account/hooks';
import ProfilePhoto from 'src/features/my-account/components/ProfilePhoto';
import FamilyInfo from 'src/features/my-account/components/FamilyInfo';
import NameInfo from 'src/features/my-account/components/NameInfo';
import DivisionInfo from 'src/features/my-account/components/DivisionInfo';
import ChildrenOrParentInfo from 'src/features/my-account/components/ChildrenOrParentInfo';
import ScrollBar from 'src/components/ScrollBar';
import { useSendMessageToUser } from 'src/features/messages/hooks';
import DialogActions from './DialogActions';
import SubjectInfo from 'src/features/my-account/components/SubjectInfo';
import AuthorizedInfo from 'src/features/my-account/components/AuthorizedInfo';
import router from 'src/shared/utils/router';

const UserInfoViewDialog = () => {
  const history = useHistory();
  const { sendMessageToUser } = useSendMessageToUser();
  const isOpen = useDialogIsOpen(dialogs.USER_INFO_DIALOG);
  const selectedUser = useAtomValue(selectedUserInfoAtom);
  const setSelectedUserInfo = useSetAtom(selectedUserInfoAtom);
  const openDialog = useOpenDialog(dialogs.USER_INFO_DIALOG);
  const userId = selectedUser?.user_id ?? selectedUser?.id;

  const onCloseDialog = () => {
    setSelectedUserInfo(null);
    openDialog(false);
  };

  const {
    userInfo,
    isLoading,
    isLoadingUserInfo,
    fullName,
    haveViewProfileAccess,
    userParent,
    userChildren,
    isAdmin,
    isSamePerson,
    isExecutive,
    hasMessagesAccess,
  } = useUserInfoView({
    userId,
    onErrorUserInformation: onCloseDialog,
  });

  const openProfile = () => {
    const targetRoute = isSamePerson
      ? router.me.account
      : router.profile.profile(userInfo?.id);

    history.push(targetRoute);

    onCloseDialog();
  };

  const sendMessage = () => {
    sendMessageToUser(userInfo);
    onCloseDialog();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onCloseDialog}
      sx={{
        '& .MuiDialogContent-root': {
          padding: '0px',
        },
      }}
    >
      {isLoading ? (
        <Box
          height={500}
          display={'flex'}
          justifyContent="center"
          alignItems={'center'}
        >
          <CircularProgress size={18} />
        </Box>
      ) : (
        <>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={onCloseDialog}>
              <CloseIcon size={16} />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ overflow: 'hidden' }}>
            <Grid container direction="row" pb={3}>
              <Grid item xs={4} px={3}>
                <Grid container direction="column" spacing={3}>
                  <ProfilePhoto user={userInfo} />
                  {haveViewProfileAccess && <FamilyInfo userInfo={userInfo} />}
                  <AuthorizedInfo userInfo={userInfo} />
                </Grid>
              </Grid>
              <Grid item xs={8} px={3}>
                <Grid container>
                  <NameInfo
                    loading={isLoadingUserInfo}
                    name={fullName}
                    userInfo={userInfo}
                    gridProps={{ pb: 1 }}
                  />
                  {!isAdmin && (
                    <ScrollBar style={{ maxHeight: 500, width: '100%' }}>
                      {!isExecutive && (
                        <SubjectInfo
                          textProps={{
                            color: 'black',
                            variant: 'body1',
                            fontSize: 14,
                            fontWeight: 500,
                          }}
                          roles={userInfo?.roles}
                        />
                      )}

                      <DivisionInfo
                        roles={userInfo?.roles}
                        textProps={{
                          color: 'black',
                          variant: 'body1',
                          fontSize: 14,
                          fontWeight: 500,
                        }}
                      />

                      {Boolean(userParent?.length) && (
                        <ChildrenOrParentInfo
                          title={t`Hijo/a de`}
                          family={userParent}
                        />
                      )}

                      {Boolean(userChildren?.length) && (
                        <ChildrenOrParentInfo
                          title={
                            <Plural
                              value={userChildren.length}
                              one={<Trans>Hijo/a</Trans>}
                              other={<Trans>Hijos/as</Trans>}
                            />
                          }
                          family={userChildren}
                        />
                      )}

                      {haveViewProfileAccess && (
                        <PersonalInfo
                          user={userInfo}
                          containerProps={{
                            sx: {
                              backgroundColor: 'transparent !important',
                              padding: 0,
                              mt: 2,
                            },
                          }}
                          hideEmptyValue
                        />
                      )}
                    </ScrollBar>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            hasMessagesAccess={hasMessagesAccess}
            haveViewProfileAccess={haveViewProfileAccess}
            openProfile={openProfile}
            sendMessage={sendMessage}
            isSamePerson={isSamePerson}
          />
        </>
      )}
    </Dialog>
  );
};

UserInfoViewDialog.propTypes = {};

export default UserInfoViewDialog;
