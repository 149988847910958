import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Tab } from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import { t } from '@lingui/macro';

import SearchSuggestions from './SearchSuggestions';
import searchFilters from './SearchSuggestions/filters';
import EventResult from './Events';
import UserResults from './Users';
import PostResults from './Posts';
import ResultLabel from './ResultLabel';
import SearchEmptyState from './SearchEmptyState';
import useStyles from './styles';

const SearchResults = ({
  onSelectFilter,
  activeFilter,
  results,
  searchQuery,
}) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState('1');

  const handleChangeTab = (event, newValue) => setActiveTab(newValue);

  useEffect(() => {
    setActiveTab(activeFilter?.id.toString() ?? '1');
  }, [activeFilter?.id]);

  return (
    <Card className={classes.root} raised>
      <CardContent>
        {results && searchQuery.length > 0 && (
          <>
            {activeFilter && <ResultLabel text={t`Resultados`} />}
            <TabContext value={activeTab}>
              {!activeFilter && (
                <TabList
                  onChange={handleChangeTab}
                  className={classes.tabHeader}
                >
                  <Tab
                    className={classes.tab}
                    label={t`Personas (${results.users.length ?? 0})`}
                    value="1"
                  />
                  <Tab
                    className={classes.tab}
                    label={t`Publicaciones (${results.posts.length ?? 0})`}
                    value="2"
                  />
                  <Tab
                    className={classes.tab}
                    label={t`Eventos (${results.events.length ?? 0})`}
                    value="3"
                  />
                </TabList>
              )}
              <TabPanel value="1" className={classes.tabContent}>
                <UserResults userRows={results.users} />
                {!results.users.length && <SearchEmptyState />}
              </TabPanel>
              <TabPanel value="2" className={classes.tabContent}>
                <PostResults postRows={results.posts} />
                {!results.posts?.length && <SearchEmptyState />}
              </TabPanel>
              <TabPanel value="3" className={classes.tabContent}>
                <EventResult eventRows={results.events} />
                {!results.events.length && <SearchEmptyState />}
              </TabPanel>
            </TabContext>
          </>
        )}

        {!searchQuery.length && !activeFilter && (
          <>
            <ResultLabel text={t`Estoy buscando`} />
            <SearchSuggestions
              filters={searchFilters}
              onSelectFilter={onSelectFilter}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

SearchResults.propTypes = {
  onSelectFilter: PropTypes.func,
  activeFilter: PropTypes.object,
  results: PropTypes.object,
  searchQuery: PropTypes.string,
};

export default SearchResults;
