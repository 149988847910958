import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography } from '@mui/material';
import { Edit3 as EditIcon } from 'react-feather';

const InfoTitle = ({ title, onEdit, children }) => {
  return (
    <Grid container direction={'row'} marginTop={2}>
      <Grid item xs={12}>
        <Grid
          container
          direction={'row'}
          justifyContent="space-between"
          alignItems={'center'}
        >
          <Typography
            color={'text.secondary'}
            variant="overline"
            fontSize={'12px'}
          >
            {title}
          </Typography>
          {onEdit && (
            <IconButton onClick={onEdit} color="primary">
              <EditIcon size={'16px'} />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

InfoTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  onEdit: PropTypes.func,
};

export default InfoTitle;
