import { atomWithReset } from 'jotai/utils';

const initialValues = {
  name: '',
  amountValue: '',
  percentValue: '',
  apply_type: '',
  monthsActivated: [],
  users: [],
  concept: null,
};

const draftCreateDiscountAtom = atomWithReset(initialValues);

export default draftCreateDiscountAtom;
