export const parseConfigFormValuesToApi = (values) => {
  const {
    withInterest,
    withDiscount: discount_before_due_date,
    dayToApplyDiscount: number_of_day_to_discount_before_due_date,
    dayToApplyInterest: number_of_day_to_interests_after_due_date,
    withExpiredImpute: expired_notice_payment_impute,
    number_of_day_to_daily_interest,
    selectedInterestType,
    disable_total_amount_change,
  } = values;

  return {
    ...values,

    interests_after_due_date:
      withInterest &&
      (selectedInterestType === 'due' || selectedInterestType === 'both'),
    daily_interest:
      withInterest &&
      (selectedInterestType === 'daily' || selectedInterestType === 'both'),
    currency: values.currency.id,
    discount_before_due_date,
    number_of_day_to_discount_before_due_date,
    number_of_day_to_interests_after_due_date,
    number_of_day_to_daily_interest,
    expired_notice_payment_impute,
    disable_total_amount_change: !disable_total_amount_change,
  };
};
