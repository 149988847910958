import { atom } from 'jotai';

const selectedEditingCustomFieldAtom = atom({
  isOpen: false,
  customFields: [],
  title: '',
});
selectedEditingCustomFieldAtom.debugLabel = 'selectedEditingCustomFieldAtom';

export default selectedEditingCustomFieldAtom;
