import { singleFamilyTaxInformationUrl } from 'src/shared/services/url/UrlPaymentManagement';
import { useGetPaginatedList } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const useFamilyTaxInformationById = (billingDataId) => {
  return useGetPaginatedList(
    [QUERY_KEY.FAMILY_TAX_INFORMATION, billingDataId],
    singleFamilyTaxInformationUrl(billingDataId),
    {
      expand:
        'person_type,payment_method,tax_regime,cfdi_use,municipality,department',
    },
  );
};

export default useFamilyTaxInformationById;
