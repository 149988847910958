import { atom } from 'jotai';

const paymentStatusMessageAtom = atom({
  isSuccess: false,
  message: '',
  isOpen: false,
});
paymentStatusMessageAtom.debugLabel = 'paymentStatusMessageAtom';

export default paymentStatusMessageAtom;
