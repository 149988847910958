/**
 * Sorts an array of items by their "sequence".
 * @param {Array} items - The array of items to sort.
 * @returns {Array} - The array sorted by sequence.
 */
export const sortBySequence = (items = []) => {
  const sortedItems = [...items];
  sortedItems.sort((a, b) => a.sequence - b.sequence);

  return sortedItems;
};
