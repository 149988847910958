const sanitizeText = (text) => {
  return (text ?? '')
    .replaceAll('<div', '<span')
    .replaceAll('</div>', '</span>')
    .replace(/<button.*?>.*?<\/button>/gi, '')
    .replace(/<script.*?>.*?<\/script>/gi, '')
    .replace(/class=".*?"/gi, '')
    .trim();
};

export default sanitizeText;
