import { useGetList } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';
import useUserInfo from 'src/features/payment-management/hooks/useUserInfo';
import { stringMatch } from 'src/shared/helpers';
import { getMessagesUserWhitelist } from 'src/shared/services/url/UrlCommunication';

const useLowRoleContacts = ({ enabled, searchTerm, ...params }) => {
  const { selectedInstitutionPeriodId } = useUserInfo();

  const query = useGetList(
    [QUERY_KEY.CONTACTS_LIST, params],
    getMessagesUserWhitelist(selectedInstitutionPeriodId),
    {
      expand:
        'alias,custom_role,courses,authAssignments,parent.parent,children.children',
      sort: 'last_name',
      ...params,
    },
    true,
    false,
    { enabled },
  );

  const { data } = query ?? {};

  return {
    ...query,
    data:
      data?.filter(
        ({ name, last_name, alias }) =>
          stringMatch(name, searchTerm) ||
          stringMatch(last_name, searchTerm) ||
          (alias && stringMatch(alias, searchTerm)),
      ) ?? [],
  };
};
export default useLowRoleContacts;
