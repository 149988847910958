import { hasAlpha } from './hasAlpha';

export const generateImagePreview = async (file) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const maxWidth = 80;
  const maxHeight = 80;

  const newImg = new Image();
  newImg.onload = () => {
    const iw = newImg.width;
    const ih = newImg.height;
    const scale = Math.min(maxWidth / iw, maxHeight / ih);
    const scaledWidth = iw * scale;
    const scaledHeight = ih * scale;
    canvas.width = scaledWidth;
    canvas.height = scaledHeight;
    ctx.drawImage(newImg, 0, 0, scaledWidth, scaledHeight);
    // eslint-disable-next-line no-param-reassign
    file.preview = canvas.toDataURL();
    // eslint-disable-next-line no-param-reassign
    file.hasAlpha = hasAlpha(ctx, canvas);
  };
  newImg.src = URL.createObjectURL(file);
};
