import { createTheme, alpha } from '@mui/material/styles';
import { Typography, typographyClasses } from '@mui/material';

import { RAIL_NAV_WIDTH } from 'src/config/constants';

export const createRailTheme = (theme) =>
  createTheme({
    ...theme,

    components: {
      ...theme.components,

      MuiListItemIcon: {
        ...theme.components.MuiListItemIcon,
        styleOverrides: {
          ...theme.components.MuiListItemIcon.styleOverrides,
          root: {
            ...theme.components.MuiListItemIcon.styleOverrides.root,
            color: theme.palette.text.primary,
          },
        },
      },

      MuiListItemButton: {
        styleOverrides: {
          root: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),

            '&:hover': {
              backgroundColor: theme.palette.grey[100],
              svg: {
                color: theme.palette.primary.main,
              },
            },

            '&.Mui-selected': {
              backgroundColor: theme.palette.grey[100],

              '&:hover': {
                backgroundColor: theme.palette.grey[100],
              },

              svg: {
                color: theme.palette.primary.main,
              },
            },
          },
        },
      },
    },
  });

export const createNavbarTheme = (theme) =>
  createTheme({
    ...theme,

    palette: {
      ...theme.palette,
      background: {
        default: theme.palette.grey[100],
        paper: theme.palette.grey[100],
      },
    },

    components: {
      ...theme.components,

      MuiDrawer: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.variant === 'temporary' && {
              position: 'static',
            }),
          }),

          paper: ({ ownerState, theme }) => ({
            ...(ownerState.variant === 'temporary' && {
              borderTopRightRadius: theme.shape.borderRadius * 4,
              borderBottomRightRadius: theme.shape.borderRadius * 4,

              [theme.breakpoints.up('md')]: {
                left: RAIL_NAV_WIDTH,
              },
            }),
          }),
        },
      },

      MuiListSubheader: {
        ...theme.components.MuiListSubheader,

        styleOverrides: {
          root: {
            fontSize: theme.typography.overline.fontSize,
            fontWeight: theme.typography.fontWeightBold,
            letterSpacing: theme.typography.overline.letterSpacing,
            textTransform: theme.typography.overline.textTransform,
          },
        },
        defaultProps: {
          component: Typography,
        },
      },

      MuiListItemButton: {
        styleOverrides: {
          root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            borderRadius: theme.spacing(5),
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            fontWeight: theme.typography.fontWeightLight,

            '&:hover': {
              backgroundColor: theme.palette.grey[200],
            },

            '&.Mui-selected': {
              backgroundColor: alpha(theme.palette.primary.light, 0.1),

              [`& .${typographyClasses.root}`]: {
                fontWeight: theme.typography.fontWeightMedium,
              },

              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.light, 0.15),
              },
            },
          },
        },
      },
    },
  });
