import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  MuiInputLabelFormControl: {
    position: 'relative',
    transform: 'none',
    fontWeight: 500,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
  },
  fileInput: {
    marginTop: 0,
  },
}));

export default useStyles;
