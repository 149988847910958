import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, InputLabel } from '@mui/material';
import { useFormikContext, useField } from 'formik';
import dayjs from 'dayjs';

import { DatePicker } from '@mui/x-date-pickers';

import ConditionalWrapper from 'src/components/ConditionalWrapper';

import useStyles from './styles';

const DateField = ({
  gridProps,
  name,
  disabled,
  label,
  inputFormat = 'DD/MM/YYYY',
  disableFormik = {
    error: false,
    helperText: false,
  },
  placeholder = '',
  helperText,
  onChange,
  withFloatingLabel = false,
  ...rest
}) => {
  const classes = useStyles();
  const form = useFormikContext();
  const [field, meta, helpers] = useField(name);

  const handleValueChange = (date, keyboard) => {
    if (onChange) onChange(date);
    if (!date) {
      helpers.setValue(null);
    } else if (dayjs(date).isValid()) {
      helpers.setValue(date);
    } else {
      helpers.setValue(dayjs(keyboard, inputFormat));
    }
  };
  const fieldValue = field.value ? dayjs(field.value) : null;

  return (
    <ConditionalWrapper
      condition={Boolean(gridProps)}
      wrapper={(children) => (
        <Grid item {...gridProps}>
          {children}
        </Grid>
      )}
    >
      <FormControl className={classes.formControl}>
        {!withFloatingLabel && label && (
          <InputLabel
            classes={{ formControl: classes.MuiInputLabelFormControl }}
          >
            {label}
          </InputLabel>
        )}
        <DatePicker
          fullWidth
          disabled={form.isSubmitting || disabled}
          variant="inline"
          value={fieldValue}
          format={inputFormat}
          onChange={handleValueChange}
          onOpen={() => {
            form.setFieldTouched(name, true);
          }}
          slotProps={{
            textField: {
              variant: 'outlined',
              onBlur: field.onBlur,
              className: classes.input,
              name: field.name,
              error: Boolean(
                !disableFormik?.error && meta.error && meta.touched,
              ),
              helperText:
                ((!disableFormik?.helperText && meta.touched && meta.error) ??
                  meta.error) ||
                helperText,
              inputProps: {
                placeholder,
              },
              ...(withFloatingLabel && {
                label,
                className: classes.floatingLabel,
              }),
            },
          }}
          {...rest}
        />
      </FormControl>
    </ConditionalWrapper>
  );
};

DateField.propTypes = {
  gridProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  inputFormat: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  disableFormik: PropTypes.shape({
    helperText: PropTypes.bool,
    error: PropTypes.bool,
  }),
  withFloatingLabel: PropTypes.bool,
};

export default memo(DateField);
