import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    cursor: 'pointer',
    marginTop: theme.spacing(2),
  },
  cardContent: {
    padding: 0,
    paddingBottom: '0 !important',
  },
  eventDetails: {
    padding: 0,
    margin: 0,
  },
  eventDetailsTitle: {
    fontSize: 14,
  },
  eventDetailsDesc: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    fontWeight: 300,
  },
  eventBox: {
    padding: '6px 2px',
  },
  eventDate: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'center',
    borderRight: `1px solid ${theme.palette.divider}`,
    marginRight: theme.spacing(2),
    padding: `0 ${theme.spacing(2)}`,
    paddingLeft: 0,
  },
}));

export default useStyles;
