import { useState } from 'react';

const useInboxEditorBox = () => {
  const [editorContent, setEditorContent] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [postFiles, setPostFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [openGraphData, setOpenGraphData] = useState(null);

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleFileDrop = ({ dataTransfer }) => {
    const { files } = dataTransfer;
    setIsDragging(false);
    if (files?.length) {
      setDroppedFiles(files);
    }
  };

  const handleLoadOpenGraph = (openGraph) => {
    setOpenGraphData(openGraph);
  };

  const handleOnDragOver = (e) => {
    if (!isDragging) {
      e.preventDefault();
      setTimeout(() => {
        setIsDragging(true);
      }, 0);
    }
  };

  const handleDragLeave = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };

  const setFileUploadProgress = (values) => {
    let uploadInProgress = false;
    values.forEach((value) => {
      if (value.progress !== 100) {
        uploadInProgress = true;
      }
    });
    setInProgress(uploadInProgress);
  };

  return {
    editorContent,
    setEditorContent,
    isDragging,
    setIsDragging,
    droppedFiles,
    setDroppedFiles,
    postFiles,
    setPostFiles,
    isUploading,
    setIsUploading,
    inProgress,
    setInProgress,
    openGraphData,
    setOpenGraphData,
    handleEditorChange,
    handleFileDrop,
    handleLoadOpenGraph,
    handleOnDragOver,
    handleDragLeave,
    setFileUploadProgress,
  };
};

export default useInboxEditorBox;
