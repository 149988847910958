import { useGetOne } from 'src/shared/services/api/query';
import { dLocalPaymentMethods } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const useDLocalPaymentMethods = () => {
  return useGetOne(
    [QUERY_KEY.DLOCAL_PAYMENT_METHODS],
    dLocalPaymentMethods(),
    { perPage: 100 },
    true,
  );
};

export default useDLocalPaymentMethods;
