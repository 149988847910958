import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';

import palette from 'src/theme/palette';
import { downloadFileWebView } from 'src/shared/utils/fileHelper';
import useUserInfo from 'src/features/payment-management/hooks/useUserInfo';

const FieldInfo = ({
  title,
  value,
  type,
  link,
  incompleteTextColor = palette.danger.main,
  colorText = 'black',
}) => {
  const { isWebView } = useUserInfo();

  const handleDownload = async () => {
    if (isWebView) {
      await downloadFileWebView(link, value);
      return;
    }

    window.open(link);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} mt={2}>
      <Typography color={'black'} variant="h3" fontWeight={600}>
        {title}
      </Typography>
      <Typography
        color={value ? colorText : incompleteTextColor}
        variant="body1"
        fontSize={'14px'}
        fontWeight={400}
        {...(link && {
          onClick: () => handleDownload(),
          sx: { cursor: 'pointer' },
        })}
      >
        {value || <Trans>Sin completar</Trans>}
      </Typography>
    </Box>
  );
};

FieldInfo.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.any,
  type: PropTypes.string,
  link: PropTypes.string,
  colorText: PropTypes.string,
  incompleteTextColor: PropTypes.string,
};

export default FieldInfo;
