import makeStyles from '@mui/styles/makeStyles';
import { mobile } from 'src/shared/utils/breakpoints';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'stretch',
    height: '100%',
  },
  paperScrollPaper: {
    minWidth: 485,
    [mobile]: {
      maxWidth: '100%',
    },
  },
}));

export default useStyles;
