import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router';

import { useCreate } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';
import { postScheduledConversation } from 'src/shared/services/url/UrlCommunication';
import router from 'src/shared/utils/router';

const useCreateScheduleConversation = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useCreate(
    postScheduledConversation(),
    {
      onSuccess: () => {
        enqueueSnackbar(t`Su mensaje ha sido programado`, {
          variant: 'success',
        });
        queryClient.refetchQueries({
          queryKey: [QUERY_KEY.SCHEDULED_CONVERSATIONS_LIST],
        });
        history.push(router.communication.scheduled.root);
      },
      onError: (error) => {
        enqueueSnackbar(
          error.message || t`Hubo un error al programar su mensaje.`,
          {
            variant: 'error',
          },
        );
      },
    },
    false,
  );
};

export default useCreateScheduleConversation;
