import { useGetOne } from 'src/shared/services/api/query';
import { pickupConfigUrl } from 'src/shared/services/url/UrlUser';
import { QUERY_KEY } from 'src/features/pickups/constants/queryKeys';
import pickupConfigSerializer from '../../helpers/pickupConfigSerializer';
import useIsSchoolManagement from 'src/shared/hooks/useIsSchoolManagement';
import useModules from 'src/shared/hooks/useModules';
import { BLENDED_MODULES } from 'src/config/constants';

const usePickupConfig = (params = {}) => {
  const isSchoolManagement = useIsSchoolManagement();
  const { data: serverModules } = useModules();

  const isAuthorizationEnabled = serverModules.some(
    ({ id, enabled }) => id === BLENDED_MODULES.PICKUPS.id && enabled,
  );

  const query = useGetOne(
    [QUERY_KEY.PICK_UP_CONFIG, params],
    pickupConfigUrl(),
    { ...params },
    false,
    false,
    { enabled: isSchoolManagement && isAuthorizationEnabled },
    pickupConfigSerializer,
  );

  return {
    ...query,
    data: query.isSuccess && params?.level_id ? query?.data[0] : query.data,
  };
};

export default usePickupConfig;
