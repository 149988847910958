import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useErrorMessage } from '../common/useErrorMessage';
import { abstractRequest } from '../common/abstractRequest';
import abstractSerializer from '../serializer/abstractSerializer';

export const useGetPaginatedList = (
  key,
  url,
  params = {},
  defaultError = true,
  queryParams = {},
  customSerializer = null,
  initialPage = 0,
  initialLimit = 2000,
  isPost = false,
) => {
  const { deserialize } = customSerializer || abstractSerializer();

  const withErrorMessage = useErrorMessage();
  const [page, setPage] = useState(initialPage);
  const [limit, setLimit] = useState(initialLimit);

  const pageParams = {
    'per-page': limit,
    page: page + 1,
  };

  const request = () =>
    abstractRequest({
      url,
      params: { ...pageParams, ...params },
      isPost,
      isRaw: true,
    });

  const queryFn = defaultError ? withErrorMessage(request) : request;

  const transform = (data) => {
    return {
      data: data.data,
      pagination: {
        page: data.page - 1,
        limit: data.limit,
        total: data.totalItems,
        hasNextPage: Number(data.page) < Number(data.totalPages),
        setPage,
        setLimit,
      },
    };
  };

  const query = useQuery([...key, pageParams], queryFn, {
    ...queryParams,
    select: (data) => transform(data),
    keepPreviousData: true,
  });

  if (query.isSuccess) {
    return {
      ...query,
      pagination: query?.data?.pagination ?? {},
      data: deserialize(query?.data ?? []),
    };
  }

  return query;
};
