import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Box, ListItemButton, Typography } from '@mui/material';

import measureUserAccess from 'src/shared/utils/measureUserAccess';

import useStyles from './styles';

const Tab = ({ tab }) => {
  const classes = useStyles();
  const history = useHistory();
  const { userIs } = useSelector(({ user }) => ({
    userIs: user.userIs,
  }));

  const locationArray = window.location.pathname;

  const { title, items } = tab;
  return (
    <>
      <Box paddingX={2} paddingY={1}>
        <Typography varian="h3" fontSize={'10px'}>
          {title}
        </Typography>
      </Box>
      {items.map((item) => {
        const { title: titleItem, href, selectedCondition, disablePush } = item;
        const selected = locationArray === selectedCondition;
        const canAccessModule = measureUserAccess(item.roles, userIs);

        const handlePush = () => {
          if (disablePush) {
            window.location.href = href;
            return;
          }
          history.push(href);
        };

        if (!canAccessModule) {
          return null;
        }

        return (
          <ListItemButton
            key={item.id}
            selected={selected}
            className={classes.listItem}
            onClick={handlePush}
            disableGutters
          >
            <Typography
              paddingLeft={2}
              variant="body1"
              color="text.secondary"
              {...(selected && { className: classes.active })}
            >
              {titleItem}
            </Typography>
          </ListItemButton>
        );
      })}
    </>
  );
};

Tab.propTypes = {
  tab: PropTypes.object,
};

export default Tab;
