import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const DEFAULT_ICON = {
  size: 16,
  padding: {
    small: 4,
    big: 8,
  },
};

const useStyles = makeStyles(() => ({
  root: {
    padding: ({ size }) => DEFAULT_ICON.padding[size],
  },
}));

const FeatherIconButton = memo(
  forwardRef(({ Icon, size = 'big', disabled, ...rest }, ref) => {
    const classes = useStyles({ size });

    return (
      <IconButton
        classes={{ root: classes.root }}
        ref={ref}
        size="large"
        disabled={disabled}
        {...rest}
      >
        <Icon size={DEFAULT_ICON.size} />
      </IconButton>
    );
  }),
);

FeatherIconButton.displayName = 'FeatherIconButton';

FeatherIconButton.propTypes = {
  Icon: PropTypes.elementType,
  size: PropTypes.oneOf(['small', 'big']),
};

export default FeatherIconButton;
