import React, { createContext, useState } from 'react';

export const FilesDropzoneContext = createContext({
  postFiles: [],
  setPostFiles: () => {},
  droppedFiles: [],
  setDroppedFiles: () => {},
  isUploading: false,
  setIsUploading: () => {},
});

export const FilesDropzoneContextProvider = ({ children, files = [] }) => {
  const [postFiles, setPostFiles] = useState(files);
  const [isUploading, setIsUploading] = useState(false);

  return (
    <FilesDropzoneContext.Provider
      value={{
        postFiles,
        setPostFiles,
        isUploading,
        setIsUploading,
      }}
    >
      {children}
    </FilesDropzoneContext.Provider>
  );
};
