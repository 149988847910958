import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  draftAtom,
  noInboxActiveConversationAtom,
  scheduledAtom,
} from 'src/features/messages/atoms';
import { DRAFT } from 'src/features/messages/constants';
import {
  useDeleteScheduledConversation,
  useGetScheduledConversationDetail,
  useInboxEditorBox,
} from 'src/features/messages/hooks';
import router from 'src/shared/utils/router';

const useScheduledConversationDetail = (conversationSelectedId) => {
  const { conversationId } = useParams();
  const history = useHistory();
  const [scheduledState, setScheduledState] = useAtom(scheduledAtom);
  const [draftState, setDraftState] = useAtom(draftAtom);
  const [activeConversation, setActiveConversation] = useAtom(
    noInboxActiveConversationAtom,
  );

  const { setDroppedFiles } = useInboxEditorBox();

  const {
    data: scheduledDetail,
    isLoading,
    isFetching,
    refetch: getNoInboxMessage,
  } = useGetScheduledConversationDetail(
    conversationId || conversationSelectedId,
  );

  useEffect(() => {
    if (scheduledDetail) setActiveConversation(scheduledDetail);
  }, [scheduledDetail, setActiveConversation]);

  const { mutateAsync: deleteScheduledConversation } =
    useDeleteScheduledConversation(
      scheduledDetail?.id || activeConversation?.id,
    );

  const handleGoBack = () => {
    if (scheduledDetail?.status === DRAFT) {
      history.push(router.communication.draft.root);
    } else {
      history.push(router.communication.scheduled.root);
    }
  };

  const handleGoEdit = (status, usersInfo) => {
    const mail = {
      ...scheduledDetail,
      recipients_id:
        scheduledDetail.recipients_id?.length === 0 ||
        scheduledDetail.recipients_id === null
          ? []
          : usersInfo,
    };
    if (status === DRAFT) {
      setDraftState(mail);
    } else {
      setScheduledState(mail);
    }
    history.push(router.communication.inbox.newMessage);
  };

  const resetScheduled = () => {
    setDraftState([]);
    setScheduledState([]);
    setDroppedFiles([]);
  };

  return {
    activeConversation,
    draftState,
    scheduledDetail,
    deleteScheduledConversation,
    getNoInboxMessage,
    handleGoBack,
    handleGoEdit,
    resetScheduled,
    isLoading,
    isFetching,
    scheduledState,
  };
};

export default useScheduledConversationDetail;
