import React, { memo } from 'react';
import PropTypes from 'prop-types';
import bytesToSize from 'src/shared/utils/bytesToSize';
import {
  getFileType,
  getFileStyles,
  DOCUMENT_FILE_TYPES,
  isGoogleDriveFile,
  checkForCustomLink,
  handleSubtitle,
  FILE_TYPES,
} from 'src/shared/utils/fileHelper';
import { ListItemIcon, ListItemText, Tooltip, Card, Grow } from '@mui/material';
import Icon from '@mdi/react';
import clsx from 'clsx';
import useStyles from './styles';
import FileItemActions from './FileItemActions';

const FileItem = memo(
  ({
    file,
    progressUpdate,
    onRemove,
    downloadable = false,
    showPreview,
    cardStyles,
    dense,
    onRemoveDisabled,
    loading,
  }) => {
    const classes = useStyles();
    const {
      id: fileId,
      name,
      mime_type: mimeType,
      link: fileLink,
      size: fileSize,
      preview: filePreview,
      original_file_name: originalFileName,
      type_id,
      file: fileUrl,
    } = file;
    const isCustomLink = checkForCustomLink(type_id);
    const fileExt = (originalFileName || name)?.split('.')?.pop();
    const fileType = isCustomLink
      ? 'external'
      : getFileType(mimeType || `.${fileExt}`);
    const fileStyles = getFileStyles(fileType);
    const isImage = fileType === 'picture';
    const documentPreview = DOCUMENT_FILE_TYPES.includes(fileType);
    const isDone =
      (progressUpdate &&
        progressUpdate[fileId] &&
        progressUpdate[fileId].progress === 100) ||
      (progressUpdate && ('link' in file || 'post_id' in file));
    const fileExist = progressUpdate === undefined;
    const isExternalFile = isGoogleDriveFile(type_id) || isCustomLink;
    const linkIsOneDrive = file.link?.includes('1drv.ms');
    const secondaryText = linkIsOneDrive
      ? handleSubtitle(FILE_TYPES.oneDrive)
      : handleSubtitle(type_id);

    return (
      <Grow in>
        <Card
          className={clsx({
            [classes.fileRow]: true,
            [classes.fileRowDense]: dense,
          })}
          disabled={!isDone && !fileExist}
          variant="outlined"
          {...cardStyles}
        >
          <div
            className={clsx(
              classes.fileRowContainer,
              classes[fileStyles.fileClass],
            )}
          >
            {!isExternalFile &&
              isImage &&
              ((fileUrl ?? fileLink) || filePreview) && (
                <div
                  className={classes.imagePreview}
                  style={{
                    backgroundImage: `url(${
                      fileUrl ?? fileLink ?? filePreview
                    })`,
                  }}
                />
              )}
            <ListItemIcon>
              <Icon
                className={classes.fileIcon}
                path={fileStyles?.icon}
                size="24px"
              />
            </ListItemIcon>

            {progressUpdate &&
              progressUpdate[fileId] &&
              progressUpdate[fileId].progress < 100 && (
                <div className={classes.fileLoader}>
                  {`${Math.round(progressUpdate[fileId].progress)}%`}
                </div>
              )}
          </div>

          {(originalFileName || name) && (
            <Tooltip title={originalFileName || name}>
              <ListItemText
                className={classes.fileInfoContainer}
                classes={{
                  primary: classes.fileName,
                  secondary: classes.fileSize,
                }}
                primary={originalFileName || name}
                primaryTypographyProps={{ variant: 'h5' }}
                secondary={fileSize ? bytesToSize(fileSize) : secondaryText}
              />
            </Tooltip>
          )}

          {/* {(isDone || fileExist) && ( */}
          <FileItemActions
            file={file}
            downloadable={downloadable}
            canPreview={(documentPreview || isImage) && !isExternalFile}
            onRemove={onRemove}
            onRemoveDisabled={onRemoveDisabled}
            onShowPreview={showPreview}
            isExternal={isExternalFile}
            loading={loading}
          />
          {/* )} */}
        </Card>
      </Grow>
    );
  },
);

FileItem.displayName = 'FileItem';

FileItem.propTypes = {
  file: PropTypes.object.isRequired,
  progressUpdate: PropTypes.object,
  onRemove: PropTypes.func,
  downloadable: PropTypes.bool,
  dense: PropTypes.bool,
  cardStyles: PropTypes.object,
  showPreview: PropTypes.func,
  onRemoveDisabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default FileItem;
