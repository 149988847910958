import createRouter from './createRouter';

const institutionRouter = createRouter('institution', {
  changePeriod: (periodId) => `/cambio/periodo/${periodId}`,
  changeInstitution: (institutionId) => `/cambio/institucion/${institutionId}`,
  institutionPeriod: `/institucion/ciclo-lectivo`,
  institutionPeriodDashboard: `/institucion/ciclo-lectivo/gestion-escolar`,
  institutionPeriodComunity: `/institucion/ciclo-lectivo/comunidad-escolar`,
  institutionPeriodManagement: `/institucion/ciclo-lectivo/gestion-red`,
  institutionPeriodManagementPeriods: `/institucion/ciclo-lectivo/periodos`,
  InstitutionExtracurricular: `/institucion/ciclo-lectivo/materias-especiales`,
  InstitutionExtracurricularDetail: (subjectId) =>
    `/institucion/ciclo-lectivo/materias-especiales/${subjectId}`,
  schoolManagementCourse: (courseId, divisionId) =>
    `/institucion/ciclo-lectivo/curso/${courseId}/${divisionId}`,
});

export default institutionRouter;
