import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, Typography, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import dayjs from 'dayjs';
import { X as CloseIcon } from 'react-feather';

import { useInstitutionCountryAccess } from 'src/features/payment-management/hooks';
import frontImg from 'src/assets/images/back-to-class/front-img-2023.png';
import { VC_ROUTES_MAP_COUNTRY_ACCESS } from 'src/config/general';

import useStyles from './styles';
import router from 'src/shared/utils/router';

const BackToClassDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const FINAL_SHOW_DATE = dayjs.utc('2023-02-28T00:00:00-03:00').local();
  const isActive = dayjs().isBefore(FINAL_SHOW_DATE);
  const {
    activeInstitutionPeriodId,
    selectedInstitution,
    userIs,
    yearPassDialog,
    setYearPassDialog,
  } = useSelector(({ user }) => ({
    activeInstitutionPeriodId: user.activeInstitutionPeriodId,
    selectedInstitution: user.selectedInstitution,
    userIs: user.userIs,
    yearPassDialog: user.yearPassDialog,
    setYearPassDialog: dispatch.user.setYearPassDialog,
  }));
  const { hasInstitutionCountryAccess } = useInstitutionCountryAccess();
  const hasCountryAccess = hasInstitutionCountryAccess(
    VC_ROUTES_MAP_COUNTRY_ACCESS.NEW_YEAR_DIALOG,
  );
  const activePeriod = selectedInstitution?.periods?.find(
    (period) => period.id === activeInstitutionPeriodId,
  );
  const OLD_YEAR = '2022';
  const isOldYear = activePeriod?.name?.includes(OLD_YEAR);
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const showDialog =
      userIs.admin && hasCountryAccess && isOldYear && !yearPassDialog;

    if (showDialog && isActive) {
      setIsOpen(true);
    }
  }, [hasCountryAccess, isActive, isOldYear, userIs, yearPassDialog]);

  const openInstructive = () => {
    setYearPassDialog(true);
    window.open(router.configuration.newYear);
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      classes={{
        paperScrollPaper: classes.paperScrollPaper,
      }}
    >
      <Box className={classes.root}>
        <Box
          width="100%"
          height={175}
          sx={{
            backgroundImage: `url(${frontImg})`,
            backgroundSize: 'cover',
            position: 'relative',
          }}
        >
          <Box className={classes.closeButton}>
            <IconButton onClick={handleClose}>
              <CloseIcon color="white" size={22} />
            </IconButton>
          </Box>
        </Box>

        <Box height={185} className={classes.infoContainer}>
          <Typography
            sx={{
              fontSize: 30,
              fontWeight: 700,
              lineHeight: '28px',
            }}
          >
            <Trans>Vuelta a clases 2023</Trans>
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              lineHeight: '16px',
              fontWeight: 400,
              textAlign: 'center',
            }}
          >
            <Trans>
              Recuerda realizar el <b>proceso de pase de año</b> para dar lugar
              al nuevo ciclo lectivo.
            </Trans>
          </Typography>
          <Button
            variant="contained"
            className={classes.button}
            onClick={openInstructive}
          >
            <Trans>Comenzar pase de año</Trans>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default BackToClassDialog;
