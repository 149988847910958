import { ROLE_LIST } from 'src/config/general';

const ATTENDANCE_ROLES = [
  ROLE_LIST.ADMIN,
  ROLE_LIST.EXECUTIVE,
  ROLE_LIST.PRECEPTOR,
  ROLE_LIST.TEACHER,
];

const GRADING_ROLES = [
  ROLE_LIST.ADMIN,
  ROLE_LIST.EXECUTIVE,
  ROLE_LIST.PRECEPTOR,
  ROLE_LIST.TEACHER,
];

const ATTENDANCE_STRUCTURE_ROLES = {
  LEVELS: ATTENDANCE_ROLES,
  DIVISIONS: ATTENDANCE_ROLES.filter((role) => role !== ROLE_LIST.TEACHER),
  SUBJECTS: ATTENDANCE_ROLES,
  EXTRACURRICULARS: ATTENDANCE_ROLES,
};

const GRADING_STRUCTURE_ROLES = {
  LEVELS: GRADING_ROLES,
  DIVISIONS: GRADING_ROLES,
  SUBJECTS: GRADING_ROLES,
  EXTRACURRICULARS: GRADING_ROLES,
};

const handleModulePermits = (modulePermits) => {
  switch (modulePermits) {
    case 'attendance':
      return ATTENDANCE_STRUCTURE_ROLES;
    case 'grading':
      return GRADING_STRUCTURE_ROLES;
    default:
      return ATTENDANCE_STRUCTURE_ROLES;
  }
};

const filterLevelsByPermits = (levelsToFilter, modulePermits) => {
  if (!modulePermits) return levelsToFilter;
  const PERMITS = handleModulePermits(modulePermits);
  return levelsToFilter.filter(
    (level) =>
      level.courses.filter((course) =>
        course.divisions.some(
          (division) =>
            division.roles &&
            division.roles.some((role) => PERMITS.LEVELS.includes(role)),
        ),
      ).length > 0,
  );
};

const filterDivisionsByPermits = (divisionsToFilter, modulePermits) => {
  if (!modulePermits) return divisionsToFilter;
  const PERMITS = handleModulePermits(modulePermits);
  return divisionsToFilter.filter(
    (division) =>
      division.roles &&
      division.roles.some((role) => PERMITS.DIVISIONS.includes(role)),
  );
};

const filterSubjectsByPermits = (subjectsToFilter, modulePermits) => {
  if (!modulePermits) return subjectsToFilter;
  const PERMITS = handleModulePermits(modulePermits);
  return subjectsToFilter.filter(
    (subject) =>
      subject.roles &&
      subject.roles.some((role) => PERMITS.SUBJECTS.includes(role)),
  );
};

export {
  ATTENDANCE_ROLES,
  ATTENDANCE_STRUCTURE_ROLES,
  GRADING_STRUCTURE_ROLES,
  filterLevelsByPermits,
  filterDivisionsByPermits,
  filterSubjectsByPermits,
};
