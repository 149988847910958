import { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import router from 'src/shared/utils/router';
import { checkRole } from 'src/shared/helpers';
import usePickupAccessRoles from 'src/features/pickups/hooks/usePickupAccessRoles';

const RoleGuard = ({ children, roles = [] }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isPickupRoute = useRouteMatch(router.communication.pickUps.root);
  const pickupAccessRoles = usePickupAccessRoles();

  const { userIs, setUserIs, isFirstLogin, isWebView, apiKey } = useSelector(
    ({ user }) => ({
      userIs: user.userIs,
      apiKey: user.apiKey,
      isFirstLogin: user.isFirstLogin,
      isWebView: user.isWebView,
      setUserIs: dispatch.user.setUserIs,
    }),
  );
  const isLoggedIn = useMemo(() => apiKey !== '', [apiKey]);
  const isInFirstLoginView = useMemo(
    () => location.pathname.indexOf('ingreso') >= 0,
    [location.pathname],
  );
  const hasRolePermission =
    isPickupRoute && pickupAccessRoles.length > 1
      ? checkRole(pickupAccessRoles)
      : checkRole(roles);

  useEffect(() => {
    if (!Object.keys(userIs).length) {
      setUserIs();
    }
  }, [setUserIs, userIs]);

  useEffect(() => {
    if (!isWebView) {
      if (!isLoggedIn) {
        window.location.href = router.auth.login;
      }

      if (isFirstLogin && !isInFirstLoginView) {
        history.push(router.auth.firstLogin);
      }

      if (isInFirstLoginView && !isFirstLogin) {
        history.push(router.communication.newsfeed.root);
      }
    }
  }, [history, isFirstLogin, isInFirstLoginView, isLoggedIn, isWebView]);

  useEffect(() => {
    if (roles.length && !hasRolePermission) {
      history.push(router.errors[404]);
    }
  }, [hasRolePermission, history, roles.length]);

  return children;
};

RoleGuard.displayName = 'RoleGuard';

RoleGuard.propTypes = {
  children: PropTypes.any,
  roles: PropTypes.array,
};

export default memo(RoleGuard);
