import { CircularProgress, Grid } from '@mui/material';
import React from 'react';

const LoadingComponent = () => (
  <Grid display="flex" justifyContent="center" my={2}>
    <CircularProgress size={32} />
  </Grid>
);

export default LoadingComponent;
