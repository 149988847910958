import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';
import { useDispatch } from 'react-redux';

import { Api as api } from 'src/shared/services/api';
import { updateUser } from 'src/shared/services/url/UrlUser';
import useUserInfo from 'src/features/payment-management/hooks/useUserInfo';
import { QUERY_KEY } from 'src/features/my-account/constants/queryKey';
import { USER_INFO_EXPAND } from 'src/features/my-account/constants/queryExpands';

const SUCCESS_MESSAGE = t`Información editada con éxito.`;
const ERROR_MESSAGE = t`Hubo un error al intentar editar la información.`;

const useUpdateProfileInfo = ({ isSilent } = {}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data: userData } = useUserInfo();

  const updatePersonalInfoMutation = ({ userId, data }) =>
    api.patch({
      url: `${updateUser(userId)}?expand=${USER_INFO_EXPAND}`,
      data,
    });

  const onSuccess = async ({ data }) => {
    if (data.id === userData.id) {
      await dispatch.user.setData(data);
    }
    await queryClient.invalidateQueries([QUERY_KEY.USER_INFO]);
    if (!isSilent) enqueueSnackbar(SUCCESS_MESSAGE, { variant: 'success' });
  };

  return useMutation({
    mutationFn: updatePersonalInfoMutation,
    onSuccess,
    onError: ({ message }) =>
      enqueueSnackbar(message ?? ERROR_MESSAGE, { variant: 'error' }),
  });
};

export default useUpdateProfileInfo;
