import React from 'react';
import { List } from '@mui/material';
import FileItem from 'src/components/FilesDropzone/FileItem';

const FileList = ({ files, onRemove }) =>
  files?.length > 0 && (
    <List>
      {files?.map((file, index) => (
        <FileItem
          key={`${file?.name}-${index}`}
          cardStyles={{
            sx: { width: 'auto !important', marginRight: '0 !important' },
          }}
          file={file}
          onRemove={() => onRemove(file)}
        />
      )) ?? null}
    </List>
  );

export default FileList;
