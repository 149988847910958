import axios from 'axios';

import { Api } from 'src/shared/services/api';
import { getUploadToken } from 'src/shared/services/url/UrlCommunication';
import { VIMEO_TOKEN } from 'src/config/general';
import { myVideosUrl } from './url/UrlVimeo';

const generateFileToken = async (extension, subFolder) => {
  const { data } = await Api.post({
    url: getUploadToken(),
    data: { extension, sub_folder_name: subFolder },
  });
  return data;
};

const uploadFileToAzure = async (
  tokenUrl,
  file,
  onUploadProgress,
  onSetCancelToken,
) => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  if (onSetCancelToken)
    onSetCancelToken((oldState) => ({ ...oldState, [file.id]: source }));
  const configs = {
    headers: {
      'x-ms-blob-type': 'BlockBlob',
      'Content-Type': file.type,
    },
    cancelToken: source.token,
    onUploadProgress,
  };
  return axios.put(tokenUrl, file, configs);
};

const createVimeoVideoFile = async (fileSize, fileName) => {
  const configs = {
    headers: {
      Authorization: `bearer ${VIMEO_TOKEN}`,
      Accept: 'application/vnd.vimeo.*+json;version=3.4',
      'Content-Type': 'application/json',
    },
  };
  const data = {
    upload: {
      approach: 'tus',
      size: fileSize,
    },
    name: fileName,
    privacy: { view: 'disable' },
  };

  return axios.post(myVideosUrl(), data, configs);
};

const uploadVimeoVideoFile = async (
  file,
  uploadUrl,
  onSetCancelToken,
  onUploadProgress,
) => {
  const { CancelToken } = axios;
  const source = CancelToken.source();

  if (onSetCancelToken) {
    onSetCancelToken((oldState) => ({ ...oldState, [file.id]: source }));
  }

  const configs = {
    headers: {
      'Tus-Resumable': '1.0.0',
      'Upload-Offset': 0,
      'Content-Type': 'application/offset+octet-stream',
    },
    cancelToken: source.token,
    onUploadProgress: (progressEvent) => {
      if (onUploadProgress) {
        onUploadProgress(file.id, progressEvent);
      }
    },
  };

  return axios.patch(uploadUrl, file, configs);
};

const verifyVimeoUpload = async (videoLink) => {
  const configs = {
    headers: {
      Accept: 'application/vnd.vimeo.*+json;version=3.4',
      'Tus-Resumable': '1.0.0',
    },
  };
  return axios.head(videoLink, configs);
};

const uploadFileToVimeo = async ({
  file,
  fileName,
  onProgress,
  onReject,
  onComplete,
}) => {
  try {
    const { data: creationData } = await createVimeoVideoFile(
      file.size,
      fileName,
    );
    const { upload, link } = creationData;
    const uploadLink = upload.upload_link;
    const { headers } = await uploadVimeoVideoFile(
      file,
      uploadLink,
      null,
      onProgress,
    );
    // Si el header "upload-offset" es igual al file.size es que se termino de subir.
    // Esto es util para cuando hagamos subidas en chunks, por ahora solo para verificar que se subio completo.
    if (Number(file.size) === Number(headers['upload-offset'])) {
      onComplete(link);
    }
  } catch (error) {
    onReject(error);
  }
};

export {
  generateFileToken,
  uploadFileToVimeo,
  uploadFileToAzure,
  createVimeoVideoFile,
  verifyVimeoUpload,
};
