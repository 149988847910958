/* eslint-disable no-param-reassign */
import Api from '../../api';

export const abstractRequest = async ({
  url,
  params = {},
  isPost = false,
  isRaw = false,
  pageParam,
  ...rest
}) => {
  if (pageParam) {
    params.page = pageParam;
  }

  const requestMethod = isPost ? Api.post : Api.get;
  const { data, headers } = await requestMethod({
    getRaw: isRaw,
    url,
    data: params,
    ...rest,
  });

  if (isRaw) {
    return {
      totalPages: parseInt(headers['x-pagination-page-count'], 10),
      totalItems: parseInt(headers['x-pagination-total-count'], 10),
      limit: parseInt(headers['x-pagination-per-page'], 10),
      page: parseInt(headers['x-pagination-current-page'], 10),
      data: data.data ?? data,
    };
  }

  return data;
};
