import { useRouteMatch } from 'react-router-dom';

import router from 'src/shared/utils/router';

const useIsNewCycle = () => {
  const isNewCycle = useRouteMatch(router.institution.institutionPeriod);

  return isNewCycle && !isNewCycle.isExact; // exclude the exact path
};

export default useIsNewCycle;
