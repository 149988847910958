import { atomWithReset } from 'jotai/utils';

const defaultValues = {
  active: null,
  divisions: [],
  levels: [],
  concepts: [],
  institution_taxs: [],
  months: [],
  billable: null,
  minAmount: '',
  maxAmount: '',
  name: '',
};

const filtersConceptAtom = atomWithReset(defaultValues);

export default filtersConceptAtom;
