import USER_PROFILE_VIEW_ROLES from './userProfileViewRoles';

const checkRoleRank = (
  userLoggedRoles,
  userInfoRoles,
  includeEqualRank = false,
) => {
  const userLoggedIndex = Math.min(
    ...userLoggedRoles.map((role) => USER_PROFILE_VIEW_ROLES.indexOf(role)),
  );
  const userInfoIndex = Math.min(
    ...userInfoRoles.map((role) => USER_PROFILE_VIEW_ROLES.indexOf(role)),
  );
  return includeEqualRank
    ? userLoggedIndex <= userInfoIndex
    : userLoggedIndex < userInfoIndex;
};

export default checkRoleRank;
