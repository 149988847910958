const isJSON = (str) => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

export const getLocalStorageKey = (storageKey) => {
  const storedValue = localStorage.getItem(storageKey);
  if (isJSON(storedValue)) {
    return JSON.parse(storedValue);
  }
  return storedValue;
};

export const setLocalStorageKey = (storageKey, storageValue = null) => {
  const value = JSON.stringify(storageValue);
  localStorage.setItem(storageKey, value);
};

export const removeLocalStorageKey = (storageKey) => {
  localStorage.removeItem(storageKey);
};
