import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';

import UserAvatar from 'src/components/UserAvatar';
import { formatUserRolesName } from 'src/shared/helpers';

import InfoTitle from '../InfoTitle';

const ChildrenOrParentInfo = ({ title, family = [], onEdit }) => {
  return (
    <Grid item xs={12}>
      <InfoTitle title={title} onEdit={onEdit}>
        <Grid
          container
          display={'flex'}
          flexDirection="column"
          spacing={1}
          mt={1}
        >
          {!family.length && (
            <Typography
              variant="body1"
              fontSize="14px"
              fontWeight={400}
              sx={{ ml: 1, color: 'danger' }}
            >
              <Trans>Sin completar</Trans>
            </Typography>
          )}
          {family.map((user, index) => {
            const { custom_role, name, last_name } = user;
            const fullName = `${last_name} ${name}`;
            const roles = user.roles?.map((role) => role.item_name);
            const rolesName = custom_role ?? formatUserRolesName(roles);
            return (
              <Grid item xs={12} key={index} mb={1}>
                <UserAvatar
                  size="large"
                  user={user}
                  display="flex"
                  alignItems="center"
                  isClickable
                >
                  <Box display="flex" flexDirection="column" ml={1}>
                    <Typography variant="body" lineHeight={'16px'}>
                      {fullName}
                    </Typography>
                    <Typography
                      variant="body"
                      lineHeight={'14px'}
                      fontSize={'12px'}
                      color={'text.secondary'}
                      mt={0.5}
                    >
                      {rolesName}
                    </Typography>
                  </Box>
                </UserAvatar>
              </Grid>
            );
          })}
        </Grid>
      </InfoTitle>
    </Grid>
  );
};

ChildrenOrParentInfo.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  family: PropTypes.array,
};

export default ChildrenOrParentInfo;
