import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Trans, t } from '@lingui/macro';
import { useAtom } from 'jotai';

import ExternalLinkForm from 'src/features/sidebar/components/ExternalLinkForm';
import { useOpenDialog } from 'src/shared/hooks';
import { dialogs } from 'src/shared/constants';

import {
  openExternalLinkDialogAtom,
  selectedExternalLinkAtom,
} from '../../atoms';
import useCreateExternalLink from '../../mutations/useCreateExternalLink';
import useUpdateExternalLink from '../../mutations/useUpdateExternalLink';

const ExternalLinksDialog = ({ ...props }) => {
  const setDeleteExternalLinkDialogIsOpen = useOpenDialog(
    dialogs.DELETE_EXTERNAL_LINK_DIALOG,
  );

  const [selectedExternalLink, setSelectedExternalLink] = useAtom(
    selectedExternalLinkAtom,
  );
  const [openExternalLinkDialog, setOpenExternalLinkDialog] = useAtom(
    openExternalLinkDialogAtom,
  );

  const { mutateAsync: updateExternalLink } = useUpdateExternalLink({
    onSuccess: () => handleCloseDialog(),
  });

  const { mutateAsync: createExternalLink } = useCreateExternalLink({
    onSuccess: () => handleCloseDialog(),
  });

  const handleSubmit = async (values) => {
    if (values.id) await updateExternalLink(values);
    else await createExternalLink(values);
  };

  const handleDelete = () => {
    setOpenExternalLinkDialog(false);
    setDeleteExternalLinkDialogIsOpen(true);
  };

  const handleCloseDialog = () => {
    setOpenExternalLinkDialog(false);
    setSelectedExternalLink(null);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={handleCloseDialog}
      open={openExternalLinkDialog}
      {...props}
    >
      <DialogTitle>
        {selectedExternalLink ? (
          <Trans>Editar acceso directo</Trans>
        ) : (
          <Trans>Crear acceso directo</Trans>
        )}
      </DialogTitle>

      <DialogContent>
        <ExternalLinkForm
          enableReinitialize
          onCancel={handleCloseDialog}
          onSubmit={handleSubmit}
          submitText={selectedExternalLink ? t`Guardar` : t`Crear acceso`}
          {...(selectedExternalLink && {
            selectedExternalLink: selectedExternalLink,
            onDelete: handleDelete,
          })}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ExternalLinksDialog;
