import { Trans } from '@lingui/macro';

import { BLENDED_MODULES } from 'src/config/constants';
import { HIGH_ROLE } from 'src/config/general';
import router from 'src/shared/utils/router';

export const COMMUNICATION_ITEMS = [
  {
    id: BLENDED_MODULES.NEWSFEED.id,
    title: <Trans>Noticias</Trans>,
    href: router.communication.newsfeed.root,
    enabled: true,
  },
  {
    id: BLENDED_MODULES.DIVISIONS.id,
    title: <Trans>Divisiones</Trans>,
    href: router.communication.divisions.root,
    enabled: true,
    roles: HIGH_ROLE,
  },
  {
    id: BLENDED_MODULES.SUBJECTS.id,
    title: <Trans>Materias</Trans>,
    href: router.communication.subjects.root,
    enabled: true,
  },
  {
    id: BLENDED_MODULES.GROUPS.id,
    title: <Trans>Grupos</Trans>,
    enabled: true,
    href: router.communication.groups.root,
  },
];
