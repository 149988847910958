import { useQueryClient } from '@tanstack/react-query';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { paymentManagementVariationUser } from 'src/shared/services/url/UrlPaymentManagement';
import { useCreate } from 'src/shared/services/api/query';
import { QUERY_KEY } from '../../../constants/queryKey';

const useAddMember = (discountId, conceptMembers = []) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const queryKey = {
    predicate: (query) => {
      return (
        query.queryKey[0] === QUERY_KEY.DISCOUNT_SELECTED_MEMBERS &&
        query.queryKey[1] === discountId
      );
    },
  };

  const getNewMembers = (newMembersIds) => [
    ...newMembersIds.map(({ user_id: userId }) =>
      conceptMembers.find((member) => member.id === userId),
    ),
  ];

  return useCreate(
    paymentManagementVariationUser(discountId),
    {
      onMutate: async (newMembersIds) => {
        await queryClient.cancelQueries();
        const prevValue = queryClient.getQueryData(queryKey);
        // optimistic update
        queryClient.setQueriesData(queryKey, (old) => {
          const oldMembers = [...old.data];
          return {
            data: [...getNewMembers(newMembersIds), ...oldMembers],
          };
        });
        return { prevValue };
      },
      onSuccess: () => {},
      onError: ({ message: error }, data, context) => {
        // roll-back
        queryClient.setQueryData(queryKey, context.prevValue);
        const message = error?.message ?? '';
        enqueueSnackbar(t`No se pudo vincular al estudiante. ${message}`, {
          variant: 'error',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.DISCOUNT_SELECTED_MEMBERS],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.DISCOUNTS],
        });
      },
    },
    false,
  );
};

export default useAddMember;
