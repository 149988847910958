import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const ResultLabel = ({ text = 'Estoy buscando', ...props }) => (
  <Typography
    style={{ fontSize: 14 }}
    color="textSecondary"
    gutterBottom
    {...props}
  >
    {text}
  </Typography>
);

ResultLabel.propTypes = {
  text: PropTypes.string,
};

export default ResultLabel;
