import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  menu: {
    width: 'auto',
    minWidth: 110,
    maxWidth: '100%',
  },
}));

export default useStyles;
