import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { MICROSOFT_CLIENT_ID } from 'src/config/general';
import useCloudStorage from '../useCloudStorage';
import { CLOUD_STORAGE_TYPES } from '../useCloudStorage/helpers';

const useOneDrive = (handleMenuClose, dropzoneId) => {
  const { enqueueSnackbar } = useSnackbar();
  const ERROR_MESSAGE = t`Ha ocurrido un error al intentar subir el archivo desde OneDrive`;
  const { handleSelectedCloudFiles } = useCloudStorage(
    dropzoneId,
    CLOUD_STORAGE_TYPES.ONE_DRIVE.id,
  );

  const handleError = useCallback(
    (error) => enqueueSnackbar(error ?? ERROR_MESSAGE),
    [ERROR_MESSAGE, enqueueSnackbar],
  );

  const showOneDrivePicker = useCallback(
    (action = 'share', multiSelect = true, advancedOptions = {}) => {
      const oneDriveOptions = {
        clientId: MICROSOFT_CLIENT_ID,
        action,
        multiSelect,
        openInNewWindow: true,
        advanced: advancedOptions,
        success: handleSelectedCloudFiles,
        error: handleError,
      };

      if (window.hasOwnProperty('OneDrive')) {
        window.OneDrive.open(oneDriveOptions);
      }
    },
    [handleError, handleSelectedCloudFiles],
  );

  return { showOneDrivePicker };
};

useOneDrive.propTypes = {};

export default useOneDrive;
