import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grow,
  ListItemAvatar,
  ListItemText,
  ListItem,
  Skeleton,
} from '@mui/material';

import { fillWithPlaceholder } from 'src/shared/helpers';
import randomWidth from 'src/shared/utils/randomWidth';

import useStyles from './styles';

const UserResultRowSkeleton = ({ count = 4, dense }) => {
  const classes = useStyles();
  const items = fillWithPlaceholder(count);
  return (
    <>
      {items.map((item, index) => {
        const itemKey = item + index;
        return (
          <Grow in timeout={600} key={itemKey}>
            <ListItem
              className={clsx({
                [classes.userRow]: true,
                [classes.userRowDense]: dense,
              })}
            >
              <ListItemAvatar>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={32}
                  height={32}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Skeleton
                    animation="wave"
                    width={randomWidth(80, 140)}
                    style={{ borderRadius: 4 }}
                  />
                }
                secondary={
                  <Skeleton
                    animation="wave"
                    width={60}
                    style={{ borderRadius: 4 }}
                  />
                }
              />
            </ListItem>
          </Grow>
        );
      })}
    </>
  );
};

UserResultRowSkeleton.propTypes = {
  count: PropTypes.number,
  dense: PropTypes.bool,
};

export default UserResultRowSkeleton;
