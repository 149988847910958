import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  SupervisorAccount as SupervisorAccountIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import { t, Trans } from '@lingui/macro';
import { Button, IconButton, ListItem, Tooltip } from '@mui/material';
import { useSetAtom } from 'jotai';

import abstractSerializer from 'src/shared/services/api/query/serializer/abstractSerializer';
import useUserSons from 'src/shared/hooks/useUserSons';
import router from 'src/shared/utils/router';
import useInstitutionConfig from 'src/shared/hooks/data/useInstitutionConfig';
import { useOpenDialog } from 'src/shared/hooks';
import { dialogs } from 'src/shared/constants';

import { openLinkedSonDialogAtom } from '../../atoms';

const AddChildrenButton = () => {
  const setOpenLinkedSons = useOpenDialog(dialogs.LINKED_SONS_DIALOG);

  return (
    <ListItem>
      <Button onClick={() => setOpenLinkedSons(true)}>
        <Trans>Añadir</Trans>
      </Button>
    </ListItem>
  );
};

const childrenSerializer = abstractSerializer(null, (children) =>
  children.map((child) => ({
    id: child.id,
    href: router.profile.profile(child.id),
    title: `${child.name} ${child.last_name}`,
  })),
);

const useChildrenConfig = () => {
  const { data, userIs } = useSelector((store) => store.user);
  const setOpenLinkedSons = useSetAtom(openLinkedSonDialogAtom);
  const { data: institutionConfig } = useInstitutionConfig();
  const [{ parent_manage_children }] = institutionConfig ?? [{}];

  const { data: sons } = useUserSons({
    userId: data.id,
    serializer: childrenSerializer,
    enabled: userIs.parent,
  });

  const handleClickRemoveSon = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      setOpenLinkedSons(true);
    },
    [setOpenLinkedSons],
  );

  const renderSonAction = useCallback(
    () => (
      <Tooltip title={t`Eliminar hijo/a`}>
        <IconButton size="small" onClick={handleClickRemoveSon}>
          <DeleteOutlineIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    ),
    [handleClickRemoveSon],
  );

  const enhanceSons = useCallback(
    (sons = []) => {
      const enhancedLinks = sons.map((son) => ({
        ...son,
        action: parent_manage_children ? renderSonAction(son) : undefined,
      }));

      if (!enhancedLinks.length) {
        enhancedLinks.push({
          id: 'empty',
          title: t`No tienes ningun hijo/a asociado.`,
          disabled: true,
        });
      }

      parent_manage_children &&
        enhancedLinks.push({
          id: 'new',
          title: t`Añadir`,
          Component: AddChildrenButton,
        });

      return enhancedLinks;
    },
    [parent_manage_children, renderSonAction],
  );

  const config = {
    id: 'children',
    subheader: <Trans>Hijos/As</Trans>,
    icon: <SupervisorAccountIcon size={18} />,
    items: enhanceSons(sons),
  };

  return config;
};

export default useChildrenConfig;
