import state from './state';
import reducers from './reducers';
import effects from './effects';

// Rematch model: https://rematchjs.org/docs/api-reference/models
export const subject = {
  state,
  reducers,
  effects,
};
