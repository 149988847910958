import makeStyles from '@mui/styles/makeStyles';
import { mobile } from 'src/shared/utils/breakpoints';

const useStyles = makeStyles((theme) => ({
  modal: {
    zIndex: `${theme.zIndex.appBar - 1} !important`,
  },
  drawer: {
    flexShrink: 0,
    zIndex: theme.zIndex.appBar - 1,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  subheader: {
    fontSize: 10,
    lineHeight: '38px',
  },
  institutionSelectorContainer: {
    marginTop: 0,
    [mobile]: {
      marginTop: theme.spacing(9),
    },
  },
  institutionSelectorContainerFixed: {
    marginTop: 64,
  },
  institutionSelectorBox: {
    padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
  },
  institutionSelectorBoxFixed: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    maxWidth: 215,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  buttonBack: {
    width: '100%',
    display: 'flex !important',
    justifyContent: 'left !important',
    alignItems: 'center',
  },
}));

export default useStyles;
