import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  CircularProgress,
} from '@mui/material';

import useStyles from './styles';

const SearchField = memo((props) => {
  const classes = useStyles();

  const {
    value,
    onClear,
    loading,
    label,
    InputProps,
    endAdornment,
    startAdornment,
    readOnly,
    inputProps,
    name,
    ...rest
  } = props;

  return (
    <FormControl className={classes.formControl}>
      {label && (
        <InputLabel classes={{ formControl: classes.MuiInputLabelFormControl }}>
          {label}
        </InputLabel>
      )}
      <TextField
        classes={{ root: classes.MuiInputRoot }}
        value={value}
        variant="outlined"
        InputProps={{
          ...InputProps,
          readOnly,
          endAdornment: loading ? (
            <InputAdornment position="end">
              <CircularProgress color="primary" size={12} />
            </InputAdornment>
          ) : (
            endAdornment ?? InputProps?.endAdornment
          ),
          startAdornment: startAdornment ?? InputProps?.startAdornment,
        }}
        inputProps={{
          ...inputProps,
          'data-testid': `input-search-${name}`,
        }}
        {...rest}
      />
    </FormControl>
  );
});

SearchField.displayName = 'SearchField';

SearchField.propTypes = {
  value: PropTypes.string,
  onClear: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  InputProps: PropTypes.object,
  label: PropTypes.string,
  endAdornment: PropTypes.node,
  startAdornment: PropTypes.node,
  readOnly: PropTypes.bool,
};

export default SearchField;
