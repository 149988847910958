import { QUERY_KEY } from 'src/features/my-account/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import { pickupCategoryUrl } from 'src/shared/services/url/UrlUser';

const useUserPickUpCategory = ({ enabled = true, userId }) => {
  return useGetOne(
    [QUERY_KEY.USER_PICKUP_CATEGORY, userId],
    pickupCategoryUrl(),
    {
      user_id: userId,
      paginate: false,
    },
    true,
    false,
    { enabled },
  );
};

export default useUserPickUpCategory;
