import palette from 'src/theme/palette';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SIS_URL = process.env.REACT_APP_SIS_URL;
export const VC_URL = process.env.REACT_APP_VC_BASE;
export const PMS_URL = process.env.REACT_APP_PMS_URL;
export const PMS_BILL_URL = process.env.REACT_APP_PMS_BILL_URL;
export const BILLING_BASE_URL = process.env.REACT_APP_BILLING_BASE_URL;
export const API_USER_BASE = process.env.REACT_APP_UIA_URL;
export const BOF_URL = process.env.REACT_APP_BILLING_BASE_URL;
export const API_VIRTUAL_CLASSROOM_BASE = process.env.REACT_APP_VC_URL;
export const API_COMMUNICATION_BASE = process.env.REACT_APP_CA_URL;
export const API_SIS_BASE = process.env.REACT_APP_SIS_API_URL;
export const TINYMCE_KEY = process.env.REACT_APP_TINYMCE_KEY;
export const VIMEO_TOKEN = process.env.REACT_APP_VIMEO_API_TOKEN;
export const GOOGLE_DRIVE_APP_ID = process.env.REACT_APP_GOOGLE_DRIVE_APP_ID;
export const GOOGLE_DRIVE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
export const GOOGLE_DRIVE_API_KEY = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;
export const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;
export const INTERCOM_KEY = process.env.REACT_APP_INTERCOM_KEY;
export const INTERCOM_API_BASE = process.env.REACT_APP_INTERCOM_API_BASE;
export const ACADEMY_URL = process.env.REACT_APP_ACADEMY_URL;
export const LLMS_API_KEY = process.env.REACT_APP_LLMS_API_KEY;
export const LLMS_API_SECRET = process.env.REACT_APP_LLMS_API_SECRET;
export const { PUBLIC_URL } = process.env;
export const FIREBASE_WEB_CLIENT_ID =
  process.env.REACT_APP_FIREBASE_WEB_CLIENT_ID;
export const AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID;
export const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
export const MICROSOFT_REDIRECT_URL =
  process.env.REACT_APP_MICROSOFT_REDIRECT_URL;
export const AI_URL = process.env.REACT_APP_AI_URL;

export const AVAILABLE_WEEKEND_INSTITUTIONS =
  process.env.REACT_APP_WEEKEND_ATTENDANCE_FEATURE_INSTITUTIONS?.split(',') ||
  [];

export const MESSAGES_EXCLUDED_INSTITUTIONS_IDS =
  process.env.REACT_APP_MESSAGES_EXCLUDED_INSTITUTIONS_IDS?.split(',') || [];

export const ROLE_TEACHER = 'teacher'; // TODO: REMOVE
export const ROLE_STUDENT = 'student'; // TODO: REMOVE
export const ROLE_PARENT = 'parent'; // TODO: REMOVE
export const ROLE_LIST = {
  ADMIN: 'admin',
  CHILD: 'child',
  FINANCIAL_ADMIN: 'admin-financial',
  FINANCIAL_EXECUTIVE: 'financial-executive',
  EXECUTIVE: 'executive',
  PRECEPTOR: 'preceptor',
  TEACHER: 'teacher',
  PARENT: 'parent',
  STUDENT: 'student',
};

export const HIGH_ROLE = [
  ROLE_LIST.ADMIN,
  ROLE_LIST.EXECUTIVE,
  ROLE_LIST.PRECEPTOR,
  ROLE_LIST.TEACHER,
  ROLE_LIST.FINANCIAL_ADMIN,
  ROLE_LIST.FINANCIAL_EXECUTIVE,
];
export const LOW_ROLE = [ROLE_LIST.STUDENT, ROLE_LIST.PARENT];
export const CAN_PUBLISH_ROLES = (relations = [], subjectId = null) => ({
  NEWSFEED: [ROLE_LIST.ADMIN, ROLE_LIST.EXECUTIVE, ROLE_LIST.PRECEPTOR],
  SUBJECT: relations.some((relation) => {
    const AUTHORIZED_ROLES = [
      ROLE_LIST.ADMIN,
      ROLE_LIST.TEACHER,
      ROLE_LIST.EXECUTIVE,
    ];

    return subjectId
      ? relation.role_subjects.some(
          (roleSubject) =>
            AUTHORIZED_ROLES.includes(roleSubject.role_name) &&
            roleSubject.subject_ids.includes(Number(subjectId)),
        )
      : relation.role_subjects.some((subject) =>
          AUTHORIZED_ROLES.includes(subject.role_name),
        ) ?? AUTHORIZED_ROLES.includes(relation);
  }),
  DIVISION: relations.some((relation) => {
    const AUTHORIZED_ROLES = [
      ROLE_LIST.ADMIN,
      ROLE_LIST.EXECUTIVE,
      ROLE_LIST.PRECEPTOR,
    ];
    return (
      relation.role_subjects.some((subject) =>
        AUTHORIZED_ROLES.includes(subject.role_name),
      ) ?? AUTHORIZED_ROLES.includes(relation)
    );
  }),
});

export const MAX_FILE_SIZE_DEFAULT = 500 * 1024 * 1024; // 150MB in bytes

export const EVENT_TYPES = {
  HOMEWORKS: 1,
  GRADINGS: 2,
  DEFAULT: 3,
};
export const EVENT_COLORS = {
  HOMEWORKS: palette.primary,
  // TODO: Get palette from theme?
  GRADINGS: '#0077E8',
  DEFAULT: '#F5BE3B',
};

export const PAYMENT_METHODS = {
  PAYPAL: 1,
  BANK: 2,
  EBANX: 3,
};

export const VC_ROUTES_MAP_COUNTRY_ACCESS = {
  PAYMENT_MANAGEMENT_BILLING: ['MX', 'AR', 'CO'],
  NEW_YEAR_DIALOG: ['AR', 'PE', 'GT', 'CR', 'EC', 'PY', 'UY'],
};

export default {};
