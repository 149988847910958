import React from 'react';
import EmptyState from 'src/components/EmptyState';
import { t } from '@lingui/macro';

const SearchEmptyState = () => (
  <EmptyState
    type="search"
    title={t`No se encontraron resultados.`}
    subtitle={t`Lo que buscaste desafortunadamente no se encontró, o no existe.`}
  />
);

export default SearchEmptyState;
