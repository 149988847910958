import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: 30,
    position: 'absolute',
    zIndex: 1,
  },
}));

export default useStyles;
