import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  MuiInputLabelFormControl: {
    position: 'relative',
    transform: 'none',
    fontWeight: 'bold',
    fontSize: theme.typography.body1,
  },
  MuiInputRoot: {
    '& > *': {
      paddingLeft: `${theme.spacing(0.5)} !important`,
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
  },
  icon: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: 14,
      height: 14,
    },
  },
}));

export default useStyles;
