import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  Box,
  InputLabel,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

import Editor from 'src/features/newsfeed/components/PublicationBox/PublicationEditor';

import useStyles from './styles';

// TODO: Find a way to add a dynamic loading animation like the other fields
const RichTextField = ({
  name,
  placeholder,
  disabled,
  loading,
  label,
  gridProps = { xs: 12 },
  initialValue = '',
  formatOptions,
  helperText,
}) => {
  const [field, , helpers] = useField(name);
  const [isInitializingRichText, setIsInitializingRichText] = useState(true);
  const { isSubmitting } = useFormikContext();
  const classes = useStyles();

  return (
    <Grid item {...gridProps}>
      <FormControl className={classes.formControl}>
        {label && (
          <InputLabel
            classes={{ formControl: classes.MuiInputLabelFormControl }}
          >
            {label}
          </InputLabel>
        )}

        <Box className={classes.container}>
          {isInitializingRichText && (
            <Box className={classes.loaderContainer}>
              <CircularProgress color="primary" size={15} />
            </Box>
          )}

          <Editor
            fontFormats
            className={classes.editor}
            initialValue={initialValue}
            placeholder={placeholder}
            onInit={() => setIsInitializingRichText(false)}
            value={field.value}
            onChange={helpers.setValue}
            disabled={isSubmitting || disabled || loading}
            formatOptions={formatOptions}
          />
        </Box>

        {helperText && (
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {helperText}
          </Typography>
        )}
      </FormControl>
    </Grid>
  );
};

RichTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  gridProps: PropTypes.object,
  initialValue: PropTypes.string,
  formatOptions: PropTypes.shape({
    bold: PropTypes.bool,
    italic: PropTypes.bool,
    underline: PropTypes.bool,
    bullist: PropTypes.bool,
  }),
  helperText: PropTypes.string,
};

export default RichTextField;
