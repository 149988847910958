import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';
import { Api as api } from 'src/shared/services/api';
import { searchParentUser, searchUser } from 'src/shared/services/url/UrlUser';

const ERROR_MESSAGE = t`Hubo un error al intentar encontrar el usuario.`;

const useUserSearchByDocument = (withoutDivisionPermissions = false) => {
  const { enqueueSnackbar } = useSnackbar();

  const url = withoutDivisionPermissions ? searchParentUser() : searchUser();

  const searchMutation = async ({ document_number }) =>
    api.get({
      url,
      data: { document_number },
    });

  return useMutation({
    mutationFn: searchMutation,
    onError: () => enqueueSnackbar(ERROR_MESSAGE, { variant: 'error' }),
  });
};

export default useUserSearchByDocument;
