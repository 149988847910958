import { useGetOne } from 'src/shared/services/api/query';
import { uiaSuspendedInstitutionsUrl } from 'src/shared/services/url/UrlUser';
import { QUERY_KEY } from 'src/features/suspended-service/constants/queryKey';

const useSuspendedInstitution = () => {
  return useGetOne(
    [QUERY_KEY.SUSPENDED_SERVICE],
    uiaSuspendedInstitutionsUrl(),
    false,
    false,
  );
};

export default useSuspendedInstitution;
