import store from 'src/shared/stores/configureStore';
import parseExternalId from './parseExternalId';

const satismeterKey = process.env.REACT_APP_SATISMETER_KEY;

const Satismeter = {
  load: () => {
    return new Promise((resolve, reject) => {
      try {
        (() => {
          window.satismeter =
            window.satismeter ||
            // eslint-disable-next-line func-names
            function () {
              // eslint-disable-next-line prefer-rest-params
              (window.satismeter.q = window.satismeter.q || []).push(arguments);
            };
          window.satismeter.l = 1 * new Date();
          const script = document.createElement('script');
          const parent = document.getElementsByTagName('script')[0].parentNode;
          script.async = 1;
          script.src = 'https://app.satismeter.com/js';
          script.onLoad = resolve();
          parent.appendChild(script);
        })();
      } catch (e) {
        reject(e);
      }
    });
  },
  setUser: () => {
    const { user } = store.getState();
    const name = `${user.data.name} ${user.data.last_name} - ${user.selectedInstitution.name}`;
    const payload = {
      writeKey: satismeterKey,
      userId: user.data.id,
      traits: {
        name,
        firstName: user.data.name,
        lastName: user.data.last_name,
        email: user.data.email,
        dni: user.data.document_number,
        country: user.data.country_name,
        externalId: parseExternalId(user.data.external_id),
        externalInstitutionPeriodId: parseExternalId(
          user.selectedInstitution.periods[0].external_id,
        ),
        institutionPeriodId: user.selectedInstitutionPeriodId,
        institutionName: user.selectedInstitution.name,
        isAdmin: user.userIs.admin,
        isExecutive: user.userIs.executive,
        isPreceptor: user.userIs.preceptor,
        isTeacher: user.userIs.teacher,
        isParent: user.userIs.parent,
        isStudent: user.userIs.student,
      },
    };

    window.satismeter(payload);
  },
  trackEvent: (event) => {
    const payload = {
      event,
    };
    window.satismeter('track', payload);
  },
};

export default Satismeter;
