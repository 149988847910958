import { API_USER_BASE, API_VIRTUAL_CLASSROOM_BASE } from 'src/config/general';

// TO-DO: Refactor in another issue
export const getAllLectures = () =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/lecture`;

export const getLecturesBySubjectId = (subjectId) =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/lecture/subject/${subjectId}`;

export const getAllTasks = () =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/task`;

export const getTaskDetails = (task_id) =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/task/${task_id}`;

// TO-DO: We don't use this func, remove it later
export const getTasksByStatusId = (status_id) =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/task?status_id=${status_id}`;

export const getTasksBySubjectId = (subjectId) =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/task/subject/${subjectId}`;

export const getTasksPaginated = () =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/task`;

export const getAnswersByQuestionId = ({ questionId }) =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/question/${questionId}/answer`;

// URL to edit the content of an answer
export const putAnswersByQuestionId = ({ questionId, answerId }) =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/question/${questionId}/answer/${answerId}`;

// URL to edit the content of a student question
export const putQuestionById = ({ questionId }) =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/question/${questionId}`;

// URL to create a student question
export const postQuestion = () =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/question`;

export const getMembersBySubjectId = (subjectId) =>
  `${API_USER_BASE}/api/subject/${subjectId}/members`;

export const getMembersSubjectIdAndRole = (subjectId) =>
  `${API_USER_BASE}/api/subject/${subjectId}/members`;

export const getStudentsBySubjectId = (subjectId) =>
  getMembersSubjectIdAndRole(subjectId);

export const getTeachersBySubjectId = (subjectId) =>
  getMembersSubjectIdAndRole(subjectId);

export const getUserHomeworks = () =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/task/user-homework`;

export const getUserHomeworksByTaskId = (task_id) =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/task/${task_id}/user-homework`;

export const getQuestions = () =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/question`;

export const turnInHomework = () =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/task/user-homework`;

export const postUserHomeworkReview = ({ user_homework_id }) =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/task/user-homework/${user_homework_id}`;

export const taskUser = () =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/task-user`;

export const markQuestionAsViewed = () =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/question/to-viewed`;

export const markAnswersAsViewed = () =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/answer/to-viewed`;

export const checkHomework = (id) =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/task/user-homework/${id}/check`;

export const redoHomework = (id) =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/task/user-homework/${id}/redo`;

export const changeHomeworkStatus = (taskUserId) =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/task-user/${taskUserId}/change-status`;

export const finishTask = (task_id) =>
  `${API_VIRTUAL_CLASSROOM_BASE}/api/classroom/task/${task_id}/close`;
