import createRouterSchema from './createRouterSchema';
import integrationsRouter from './integrations';
import communicationRouter from './communication';
import configurationRouter from './configuration';
import externalRouter from './external';
import collectionsRouter from './collections';
import notificationsRouter from './notifications';
import profileRouter from './profile';
import serviceRouter from './service';
import meRouter from './me';
import billingStatementRouter from './billingStatement';
import authRouter from './auth';
import errorsRouter from './errors';
import institutionRouter from './institution';
import termsAndConditionsRouter from './termsAndConditions';
import assistantRouter from './assistant';

const router = createRouterSchema({
  billingStatement: billingStatementRouter,
  communication: communicationRouter,
  configuration: configurationRouter,
  notifications: notificationsRouter,
  integrations: integrationsRouter,
  collections: collectionsRouter,
  external: externalRouter,
  profile: profileRouter,
  service: serviceRouter,
  me: meRouter,
  auth: authRouter,
  errors: errorsRouter,
  institution: institutionRouter,
  termsAndConditions: termsAndConditionsRouter,
  assistant: assistantRouter,
});

export default router;
