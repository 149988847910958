const QUERY_KEY = {
  DRAFT_CONVERSATIONS_LIST: 'DraftConversationsList',
  SCHEDULED_CONVERSATIONS_LIST: 'ScheduledConversationsList',
  SCHEDULED_CONFIG: 'ScheduledConfig',
  SCHEDULED_DETAIL: 'ScheduledDetail',
  CONVERSATIONS_LIST: 'ConversationsList',
  UNREAD_CONVERSATIONS_LIST: 'UnreadConversationsList',
  CONVERSATIONS_BY_ID: 'ConversationById',
  UNREAD_MESSAGES: 'UnreadMessages',
  MESSAGES_LIST: 'MessagesList',
  CONTACTS_LIST: 'ContactsList',
  UNREAD_CONVERSATIONS: 'UnreadConversations',
  USER_INFO: 'UserInfo',
  PRINT_CONVERSATION: 'PrintConversation',
};

export { QUERY_KEY };
