import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { useField, useFormikContext } from 'formik';

import InfiniteScrollTable from 'src/components/InfiniteScrollTable';
import addOrRemove from 'src/shared/utils/addOrRemove';
import { columns } from './helpers';
import useStyles from './styles';

const CheckSelector = ({
  name,
  title,
  items = [],
  checkPosition = 'end',
  fetchNextPage = () => {},
  hasNextPage,
  isLoading,
  maxHeight = 300,
  gridProps = { xs: 12 },
  onChange,
  checkProps = {},
  ...rest
}) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const onSelectItems = (items) => {
    setFieldValue(name, items);
    if (onChange) onChange(items);
  };

  const onClickItem = (item) => {
    const { disabled } = checkProps;
    const isDisabled =
      typeof disabled === 'function' ? disabled(item) : disabled;
    if (isDisabled) return;

    const newItems = addOrRemove(field.value, item);
    onSelectItems(newItems);
  };

  return (
    <Grid item p={1} {...gridProps}>
      {title && (
        <Typography variant="h3" mb={1}>
          {title}
        </Typography>
      )}
      <Box sx={{ maxHeight: maxHeight, overflow: 'auto' }}>
        <InfiniteScrollTable
          className={classes.table}
          elevation={0}
          enableChecks
          hasTableHead={false}
          items={items}
          columns={columns}
          checkPosition={checkPosition}
          onSelectItems={onSelectItems}
          onClickItem={onClickItem}
          onLoadMore={fetchNextPage}
          hasNextPage={hasNextPage}
          loading={isLoading}
          selectedItemsExternal={field.value}
          checkProps={checkProps}
          {...rest}
        />
      </Box>
    </Grid>
  );
};

CheckSelector.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  items: PropTypes.array,
  checkPosition: PropTypes.string,
  fetchNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  maxHeight: PropTypes.number,
  gridProps: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CheckSelector;
