import React from 'react';
import PropTypes from 'prop-types';
import PostResultRow from './PostResultRow';
import ResultLabel from '../ResultLabel';

const PostResults = ({ postRows, showLabel }) => (
  <>
    {showLabel && <ResultLabel text="Publicaciones" />}
    {postRows?.map((post) => (
      <PostResultRow key={post.id} post={post} />
    ))}
  </>
);

PostResults.propTypes = {
  postRows: PropTypes.array,
  showLabel: PropTypes.bool,
};

export default PostResults;
