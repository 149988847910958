import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { useCreate } from 'src/shared/services/api/query';
import { createExternalLinks as createExternalLinksUrl } from 'src/shared/services/url/UrlUser';

import { QUERY_KEY } from '../../constants';
import externalLinkSerializer from '../../serializers/externalLinkSerializer';

const useCreateExternalLink = ({ onSuccess, ...params } = {}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useCreate(
    createExternalLinksUrl(),
    {
      ...params,
      onSuccess: async () => {
        if (onSuccess) onSuccess();
        await queryClient.invalidateQueries([QUERY_KEY.EXTERNAL_LINKS]);
        enqueueSnackbar(t`Se ha creado el acceso directo con exito.`, {
          variant: 'success',
        });
      },
      onError: ({ message }) => {
        enqueueSnackbar(
          message ??
            t`Ocurrió un problema al intentar crear el acceso directo.`,
          {
            variant: 'error',
          },
        );
      },
    },
    false,
    externalLinkSerializer,
  );
};

export default useCreateExternalLink;
