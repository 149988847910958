import * as Yup from 'yup';
import { t } from '@lingui/macro';

const REQUIRED_TEXT = t`Ingrese una fecha`;

const ScheduledElementFormSchema = Yup.object({
  start_date: Yup.date().min(new Date()).nullable().required(REQUIRED_TEXT),
});

export default ScheduledElementFormSchema;
