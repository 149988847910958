import { useQueryClient } from '@tanstack/react-query';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { useUpdate } from 'src/shared/services/api/query';
import { paymentManagementUsersRemove } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from '../../../constants/queryKey';

const useRemoveMember = (rateId, queryParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const queryKey = {
    predicate: (query) => {
      return (
        query.queryKey[0] === QUERY_KEY.CONCEPT_SELECTED_MEMBERS &&
        query.queryKey[1] === rateId
      );
    },
  };

  return useUpdate(
    paymentManagementUsersRemove(rateId),
    {
      onError: ({ message }, data, context) => {
        queryClient.setQueryData(queryKey, context.prevValue);
        enqueueSnackbar(
          message ?? t`Hubo un error intentando desvincular al estudiante`,
          {
            variant: 'error',
          },
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.DISCOUNTS],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.DISCOUNT_SELECTED_MEMBERS],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.CONCEPT_SELECTED_MEMBERS],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.RATES],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.CONCEPT_SELECTED_MEMBERS_PAGINATED],
          refetchType: 'all',
        });
      },
      ...queryParams,
    },
    false,
  );
};

export default useRemoveMember;
