import useUserInfo from 'src/features/payment-management/hooks/useUserInfo';

const useIsArgentina = () => {
  const { data: userData } = useUserInfo();
  const { country } = userData ?? {};
  const isArgentina = country.code.toUpperCase() === 'AR';

  return { isArgentina };
};

useIsArgentina.propTypes = {};

export default useIsArgentina;
