import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';
import { Api as api } from 'src/shared/services/api';

import { paymentManagementSingleRate } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const SUCCESS_MESSAGE = t`Concepto eliminado con éxito.`;
const ERROR_MESSAGE = t`Hubo un error al intentar eliminar el concepto.`;

const useDeleteRate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const apiDelete = ({ id: rateId }) =>
    api.delete({
      url: paymentManagementSingleRate(rateId),
    });

  return useMutation({
    mutationFn: apiDelete,
    onMutate: ({ id: rateId }) => {
      queryClient.removeQueries({
        queryKey: [QUERY_KEY.RATES, rateId],
      });
      queryClient.removeQueries({
        queryKey: [QUERY_KEY.CONCEPT_SELECTED_MEMBERS, rateId],
      });
    },
    onSuccess: () => enqueueSnackbar(SUCCESS_MESSAGE, { variant: 'success' }),
    onError: ({ message }) =>
      enqueueSnackbar(message ?? ERROR_MESSAGE, { variant: 'error' }),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.RATES],
        refetchType: 'all',
      }),
  });
};

export default useDeleteRate;
