import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';
import { t } from '@lingui/macro';

const BaseSubmitButton = ({
  children = t`Enviar`,
  form,
  disabled,
  isLoading,
  ...rest
}) => {
  const endIcon = isLoading ? (
    <CircularProgress size={10} color="primary" />
  ) : undefined;

  return (
    <Button
      form={form}
      type="submit"
      color="primary"
      variant="contained"
      disabled={disabled}
      endIcon={endIcon}
      {...rest}
    >
      {children}
    </Button>
  );
};

BaseSubmitButton.propTypes = {
  children: PropTypes.any,
  form: PropTypes.string,
  disabled: PropTypes.bool,
};

export default BaseSubmitButton;
