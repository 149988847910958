import { useGetOne } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { paymentManagementConfig } from 'src/shared/services/url/UrlPaymentManagement';
import oneElementArraySerializer from 'src/shared/services/api/query/serializer/oneElementArraySerializer';

const useGetPaymentsModuleConfig = (options) => {
  const dataSerializer = oneElementArraySerializer();
  return useGetOne(
    [QUERY_KEY.PAYMENTS_MODULE_CONFIG],
    paymentManagementConfig(),
    undefined,
    false,
    false,
    options,
    dataSerializer,
  );
};

export default useGetPaymentsModuleConfig;
