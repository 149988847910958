import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { familyTaxInformationUrl } from 'src/shared/services/url/UrlPaymentManagement';
import { useCreate } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const SUCCESS_MESSAGE = t`Datos de facturación creados con éxito.`;
const ERROR_MESSAGE = t`Hubo un error al intentar crear los datos de facturación`;

const useCreateFamilyTaxInformation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useCreate(
    familyTaxInformationUrl(),
    {
      onSuccess: () => {
        enqueueSnackbar(SUCCESS_MESSAGE, {
          variant: 'success',
        });
      },
      onError: ({ message: errorMessage }) => {
        enqueueSnackbar(errorMessage ?? ERROR_MESSAGE, { variant: 'error' });
      },
      onSettled: () => {
        queryClient.invalidateQueries([QUERY_KEY.FAMILY_TAX_INFORMATION], {
          refetchType: 'all',
        });
      },
    },
    false,
  );
};

export default useCreateFamilyTaxInformation;
