import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import useAvailableStructure from 'src/shared/hooks/useAvailableStructure';
import { PUBLISH_ALLOWED_ROLES } from 'src/features/newsfeed/helpers';
import { sortBySequence } from 'src/shared/helpers/sortBySequence';
import sortByName from 'src/shared/utils/sortByName';

const usePublicationSelector = ({
  levels,
  selectedLevels,
  setSelectedLevels,
  divisions,
  showExtracurriculars,
  setDivisions,
  selectedDivisions,
  setSelectedDivisions,
  selectedRoles,
  setSelectedRoles,
  setSubjects,
  selectedSubjects,
  setSelectedSubjects,
  multiple = true,
  isPortalView,
}) => {
  const { extracurriculars } = useAvailableStructure();
  const [firstRender, setFirstRender] = useState(true);
  const elementExists = (where, what) =>
    where.some((item) => what.includes(item));

  const handleToggleOption = (items, selectedItems, setSelectedItems) => {
    if (!elementExists(selectedItems, items)) {
      if (multiple) {
        setSelectedItems((oldValue) => [...oldValue, ...items]);
      } else {
        setSelectedItems(items);
      }
    } else {
      const filteredSelectedItems = selectedItems.filter(
        (selectedItem) => !items.includes(selectedItem),
      );
      setSelectedItems(filteredSelectedItems);
    }
  };

  const formatDivisions = useCallback(
    (selectedItems, divisionArray) =>
      levels
        .filter((level) => selectedItems.find((item) => item === level.id))
        .map((level) => {
          const hasExtracurriculars =
            Boolean(extracurriculars.find((el) => el.level_id === level.id)) &&
            showExtracurriculars;
          const labelItem = {
            ...level,
            id: `${level.id}-level`,
            optionGroup: true,
            levelId: level.id,
          };
          divisionArray.push(labelItem);
          const { courses } = level;

          sortBySequence(courses).forEach((course) =>
            sortByName(course.divisions).map((div) => {
              if (
                isPortalView &&
                !div.roles.some((role) => PUBLISH_ALLOWED_ROLES.includes(role))
              ) {
                return null;
              }

              const division = {
                ...div,
                name: `${course.name} ${div.name}`,
                levelId: labelItem.levelId,
                sequence: course.sequence,
              };

              divisionArray.push(division);
              return division;
            }),
          );

          if (hasExtracurriculars) {
            divisionArray.push({
              ...level,
              id: `${level.id}-extracurricular`,
              divisionId: `${level.id}-extracurricular`,
              levelId: level.id,
              name: t`Materias Especiales`,
              isExtracurricular: true,
            });
          }

          return divisionArray ?? [[]];
        }),
    [extracurriculars, levels, showExtracurriculars, isPortalView],
  );

  const formatSubjects = useCallback(
    (selectedDiv, overrideDivisions) => {
      const subs = [];
      const divs = overrideDivisions?.length ? overrideDivisions : divisions;
      const filteredDiv = divs.filter(
        (div) => selectedDiv.includes(div.id) && !div.optionGroup,
      );

      filteredDiv.forEach((div) => {
        const { id, name, levelId, isExtracurricular } = div;
        subs.push({ id: `${id}-division`, name, optionGroup: true });

        if (div.subjects) {
          div.subjects.forEach((sub) => {
            subs.push(sub);
          });
        }
        if (isExtracurricular) {
          const filteredExtracurriculars = extracurriculars.filter(
            (el) => levelId === el.level_id,
          );
          if (filteredExtracurriculars.length) {
            subs.push(...filteredExtracurriculars);
          }
        }
      });
      return subs;
    },
    [divisions, extracurriculars],
  );

  const handleReset = useCallback(() => {
    setSelectedLevels([]);
    setSelectedDivisions([]);
    setSelectedRoles([]);
    setSelectedSubjects([]);
    setDivisions([]);
    setSubjects([]);
    return false;
  }, [
    setDivisions,
    setSelectedDivisions,
    setSelectedLevels,
    setSelectedRoles,
    setSelectedSubjects,
    setSubjects,
  ]);

  const handleResetElements = useCallback(
    (elements = []) => {
      elements.forEach((element) => {
        switch (element) {
          case 'levels':
            setSelectedLevels([]);
            break;
          case 'divisions':
            setSelectedDivisions([]);
            break;
          case 'subjects':
            setSelectedSubjects([]);
            break;
          case 'roles':
            setSelectedRoles([]);
            break;
          default:
            break;
        }
      });
    },
    [
      setSelectedDivisions,
      setSelectedLevels,
      setSelectedRoles,
      setSelectedSubjects,
    ],
  );

  const handleChangeLevel = useCallback(
    (items) => {
      if (!items.length) handleReset();
      const divisionsList = [];
      if (!elementExists(selectedLevels, items)) {
        const formattedDivisions =
          formatDivisions(items, divisionsList)[0] ?? [];
        const formattedSubjects = formatSubjects(formattedDivisions);

        if (multiple) {
          setSelectedLevels((oldValue) => [...oldValue, ...items]);
          setDivisions((oldValue) => [...oldValue, ...formattedDivisions]);
          if (setSubjects) {
            setSubjects(formattedSubjects);
          }
        } else {
          setSelectedDivisions([]);
          setSelectedSubjects([]);
          setSelectedLevels(items);
          setDivisions(formattedDivisions);

          if (setSubjects) {
            setSubjects(formattedSubjects);
          }
        }
      } else {
        const filteredLevels = selectedLevels.filter(
          (level) => !items.includes(level),
        );
        const filteredDivisions = divisions.filter(
          (div) => !items.includes(div.levelId),
        );
        const filteredSelectedDivisions = selectedDivisions.filter(
          (selectedDiv) => {
            const division = divisions.find((div) => div.id === selectedDiv);
            return !items.includes(division?.levelId);
          },
        );

        setSelectedLevels(filteredLevels);
        setDivisions(filteredDivisions);
        setSelectedDivisions(filteredSelectedDivisions);
      }
    },
    [
      divisions,
      formatDivisions,
      formatSubjects,
      setSubjects,
      multiple,
      selectedDivisions,
      selectedLevels,
      setDivisions,
      setSelectedDivisions,
      setSelectedLevels,
      handleReset,
      setSelectedSubjects,
    ],
  );

  const handleChangeDivision = useCallback(
    (items) => {
      if (!elementExists(selectedDivisions, items)) {
        if (multiple) {
          setSelectedDivisions((oldValue) => {
            const newDivisions = [...oldValue, ...items];

            if (setSubjects) {
              setSubjects(formatSubjects(newDivisions));
            }

            return newDivisions;
          });
        } else {
          setSelectedSubjects([]);
          setSelectedDivisions(items);

          if (setSubjects) {
            setSubjects(formatSubjects(items));
          }
        }
      } else {
        const filteredSelectedItems = selectedDivisions.filter(
          (selectedItem) => !items.includes(selectedItem),
        );

        setSelectedDivisions(filteredSelectedItems);

        if (setSubjects) {
          setSubjects(formatSubjects(filteredSelectedItems));
        }
      }
    },
    [
      formatSubjects,
      multiple,
      selectedDivisions,
      setSelectedDivisions,
      setSubjects,
      setSelectedSubjects,
    ],
  );

  const handleRoleChange = (items) => {
    handleToggleOption(items, selectedRoles, setSelectedRoles);
  };

  const handleChangeSubject = (items) => {
    handleToggleOption(items, selectedSubjects, setSelectedSubjects);
  };

  useEffect(() => {
    if (selectedLevels.length > 0 && firstRender && levels.length > 0) {
      const [formattedDivisions] = formatDivisions(selectedLevels, []);
      if (!divisions.length) {
        if (multiple) {
          setDivisions((old) => [...old, ...(formattedDivisions ?? [])]);
        } else {
          setDivisions(formattedDivisions);
        }
      }

      if (selectedDivisions.length) {
        setSubjects(formatSubjects(selectedDivisions, formattedDivisions));
      }
      setFirstRender(false);
    }
  }, [
    formatDivisions,
    firstRender,
    multiple,
    selectedLevels,
    setDivisions,
    selectedDivisions,
    setSubjects,
    formatSubjects,
    levels,
    divisions,
  ]);

  return {
    handleChangeLevel,
    handleChangeDivision,
    handleRoleChange,
    handleChangeSubject,
    handleReset,
    handleResetElements,
  };
};

usePublicationSelector.propTypes = {
  levels: PropTypes.array,
  selectedLevels: PropTypes.array,
  setSelectedLevels: PropTypes.func,
  divisions: PropTypes.array,
  setDivisions: PropTypes.func,
  selectedDivisions: PropTypes.array,
  setSelectedDivisions: PropTypes.func,
  selectedRoles: PropTypes.array,
  setSelectedRoles: PropTypes.func,
  multiple: PropTypes.bool,
};

export default usePublicationSelector;
