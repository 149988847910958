import { useSelector } from 'react-redux';

const useIsStaff = () => {
  const { isTeacher, isPreceptor, isExecutive, isAdmin } = useSelector(
    ({ user }) => ({
      isTeacher: user.userIs.teacher,
      isPreceptor: user.userIs.preceptor,
      isExecutive: user.userIs.executive,
      isAdmin: user.userIs.admin,
    }),
  );

  return isTeacher || isPreceptor || isExecutive || isAdmin;
};
export default useIsStaff;
