import { atom } from 'jotai';
import { atomFamily, atomWithDefault } from 'jotai/utils';

export const stepConfigFamily = atomFamily(
  ({ name, limit = 0, current = 0 }) => atom({ name, limit, current }),
  (oldAtom, newAtom) => oldAtom.name === newAtom.name,
);

const stepFamily = atomFamily(
  ({ name, limit }) => {
    const stepConfigAtom = stepConfigFamily({ name, limit });
    return atomWithDefault((get) => get(stepConfigAtom));
  },
  (oldAtom, newAtom) => oldAtom.name === newAtom.name,
);

export default stepFamily;
