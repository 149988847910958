export const applyPagination = (items, page, limit) =>
  items.slice(page * limit, page * limit + limit);

export const getColumnValue = (item, key) => {
  let value = item;
  const keyPath = key?.split('.');

  while (value && keyPath?.length) {
    value = value[keyPath.shift()];
  }

  return value;
};
