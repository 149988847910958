import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import { t } from '@lingui/macro';
import UserInfoViewDialog from 'src/features/my-account/components/dialogs/UserInfoViewDialog';

import SearchBar from './SearchBar';
import useStyles from './styles';

const Search = ({ initialValue }) => {
  const classes = useStyles();
  const ref = useRef(null);
  const [searchBarOpened, setSearchBarOpened] = useState(false);

  const toggleSearchBar = () => {
    setSearchBarOpened((prevState) => !prevState);
  };

  return (
    <div className={classes.container}>
      {searchBarOpened && (
        <SearchBar searchTerm={initialValue} onClose={toggleSearchBar} />
      )}
      {!searchBarOpened && (
        <Tooltip title={t`Buscar`}>
          <IconButton ref={ref} onClick={toggleSearchBar} size="large">
            <SearchIcon size={18} />
          </IconButton>
        </Tooltip>
      )}

      <UserInfoViewDialog />
    </div>
  );
};

Search.propTypes = {
  initialValue: PropTypes.string,
};

export default Search;
