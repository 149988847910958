import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  paperScrollPaper: {
    height: 350,
    borderRadius: '10px',
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  decoCircle: {
    height: '90px',
    width: '17px',
    position: 'absolute',
    top: '26px',
    left: '26px',
  },
  decoHalfCircle: {
    height: '70px',
    width: '75px',
    position: 'absolute',
    top: '79px',
    right: '0',
  },
  infoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '20px',
    paddingBottom: '18px',
  },
  button: {
    borderRadius: '20px',
    padding: '8px 16px 8px 16px',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
}));

export default useStyles;
