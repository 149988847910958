import abstractSerializer from 'src/shared/services/api/query/serializer/abstractSerializer';

const deserializeTotalPaid = (data) => {
  const paymentMethods = Object.keys(data);

  const total = paymentMethods.reduce(
    (acc, current) => acc + Number(data[current]),
    0,
  );

  const totalPaid = paymentMethods.map((paymentMethod) => {
    const percentage = ((Number(data[paymentMethod]) * 100) / total).toFixed(2);
    return {
      name: paymentMethod,
      total: data[paymentMethod],
      percentage,
    };
  });

  return totalPaid;
};

const deserialize = ({ data }) => {
  return deserializeTotalPaid(data);
};

const totalPaidSerializer = abstractSerializer(null, deserialize);

export default totalPaidSerializer;
