import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Chip, ListItemButton, Tooltip } from '@mui/material';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import { X as CloseIcon } from 'react-feather';
import clsx from 'clsx';
import theme from 'src/theme';
import useStyles from './styles';

const ScaleOption = memo(
  ({ name, value, color, solo, displayValue, visible, disabled, ...rest }) => {
    const classes = useStyles();
    const chipName = `${name} ${displayValue && `(${value})`}`;
    const chipColor = disabled ? theme.palette.grey['100'] : color;
    const textColor = theme.palette.getContrastText(chipColor);
    const showTooltip = solo ? chipName.length > 12 : chipName.length > 18;
    return (
      <ConditionalWrapper
        condition={!solo}
        wrapper={(children) => (
          <ListItemButton {...rest} className={classes.optionContainer}>
            {children}
          </ListItemButton>
        )}
      >
        <ConditionalWrapper
          condition={showTooltip}
          wrapper={(children) => <Tooltip title={chipName}>{children}</Tooltip>}
        >
          <Chip
            {...rest}
            label={chipName}
            className={clsx({
              [classes.baseOption]: true,
              [classes.disabled]: disabled,
            })}
            style={{ backgroundColor: color, color: textColor }}
            deleteIcon={
              <CloseIcon
                size={12}
                className={classes.closeIcon}
                color={textColor}
              />
            }
          />
        </ConditionalWrapper>
      </ConditionalWrapper>
    );
  },
);

ScaleOption.displayName = 'ScaleOption';

ScaleOption.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  color: PropTypes.string,
  displayValue: PropTypes.bool,
  solo: PropTypes.bool,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ScaleOption;
