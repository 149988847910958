import abstractSerializer from 'src/shared/services/api/query/serializer/abstractSerializer';

const oneElementArraySerializer = () => {
  const deserialize = (data) => {
    if (Array.isArray(data)) {
      return data[0];
    }
    return data;
  };

  return abstractSerializer(null, deserialize);
};

export default oneElementArraySerializer;
