import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import { getFamilies } from 'src/shared/services/url/UrlUser';

const useFamiliesInformation = (params, enabled = true) => {
  return useGetOne(
    [QUERY_KEY.FAMILY_INFO, params],
    getFamilies(),
    { ...params },
    false,
    false,
    {
      enabled,
    },
  );
};

export default useFamiliesInformation;
