import { getCountry } from './manageBlendedStorage';
import esLang from 'src/config/tinymce/lang/es';
import deLang from 'src/config/tinymce/lang/de';
import ptLang from 'src/config/tinymce/lang/pt';

export const getLocaleByCountry = () => {
  let locale;
  const country = getCountry();
  switch (country.toLowerCase()) {
    case 'br':
      locale = 'pt';
      break;
    default:
      locale = 'es';
  }
  return locale;
};

export const getTinyLanguageByCountry = () => {
  let language;
  const country = getCountry();
  switch (country.toLowerCase()) {
    case 'br':
      language = 'pt_PT';
      break;
    default:
      language = 'es';
  }
  return language;
};

export const getTinyLanguagePackByCountry = () => {
  const country = getCountry();
  switch (country.toLowerCase()) {
    case 'br':
      return ptLang;
    case 'de':
      return deLang;
    default:
      return esLang;
  }
};
