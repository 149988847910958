import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { Api as api } from 'src/shared/services/api';
import { getUserChildren } from 'src/shared/services/url/UrlUser';
import queryKey from 'src/shared/constants/queryKey';
import queryClient from 'src/config/query';

const useRemoveUserChildren = ({ ...options } = {}) => {
  const { enqueueSnackbar } = useSnackbar();

  const removeUserSonMutation = ({ userId, children }) =>
    api.delete({
      url: getUserChildren(userId),
      data: { children },
    });

  return useMutation({
    mutationFn: removeUserSonMutation,
    onSettled: () => {
      queryClient.invalidateQueries([queryKey.USER_SONS]);
    },
    onSuccess: () => {
      enqueueSnackbar(t`Hijo/a desvinculado con exito.`, {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(t`Hubo un error al intentar desvincular a tu hijo/a.`);
    },
    ...options,
  });
};

export default useRemoveUserChildren;
