import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ga4 from 'react-ga4';

const TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
const isProduction = process.env.NODE_ENV === 'production';

export const initializeAnalytics = () =>
  ga4.initialize(TRACKING_ID, {
    testMode: !isProduction,
  });

const sendPageview = (path) =>
  ga4.send({
    hitType: 'pageview',
    page: path,
  });

export const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // window.gtag para saber que ya esta iniciado
    if (window.gtag) {
      const path = location.pathname + location.search;
      sendPageview(path);
    }
  }, [location]);
};

export default useAnalytics;
