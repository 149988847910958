import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router';

import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';
import { usePut } from 'src/shared/services/api/query';
import { setMessagesConversationNotSeen } from 'src/shared/services/url/UrlCommunication';
import router from 'src/shared/utils/router';

const useMarkMessageAsNotSeen = (
  lastMessageId,
  conversationId,
  params = {},
) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const filter = {
    subject: params.subject ?? '',
    users: params.users ?? '',
    without_seen: params.unreadFilter || '',
  };

  return usePut(setMessagesConversationNotSeen(lastMessageId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        QUERY_KEY.CONVERSATIONS_LIST,
        filter,
      ]);
      await queryClient.invalidateQueries([
        QUERY_KEY.UNREAD_CONVERSATIONS_LIST,
        filter,
      ]);
      await queryClient.invalidateQueries([QUERY_KEY.UNREAD_CONVERSATIONS]);

      history.push(router.communication.inbox.root);
    },
  });
};

export default useMarkMessageAsNotSeen;
