import React from 'react';
import AttendanceScaleCell from 'src/features/attendance/components/AttendanceDayDialog/AttendanceScaleCell';
import UserCell from 'src/features/attendance/components/AttendanceDayDialog/UserCell';
import { t } from '@lingui/macro';
import {
  getAttendanceSubjectsUrl,
  getAttendanceUrl,
  getGeneralAttendanceStatusUrl,
  getSubjectsAttendanceStatusUrl,
} from 'src/shared/services/url/UrlSis';

const ATTENDANCE_DAY_DIALOG_COLUMNS = [
  {
    title: t`Alumno`,
    key: 'student.id',
    render: (student) => <UserCell {...student} />,
  },
];

const handleParseColumns = (data, baseColumns = []) => {
  const columns = [...baseColumns];
  data.forEach((col) => {
    columns.push({
      title: col.name,
      id: col.id,
      key: 'id',
      render: (props) => <AttendanceScaleCell {...props} />,
    });
  });
  return columns;
};

const handleParsedRows = (data, elements) => {
  const clonedDivisions = [];
  elements.forEach((element) => {
    const foundItems = data.filter((foundAttendance) => {
      if (foundAttendance.subject_id) {
        return element.subjectId === foundAttendance.subject_id;
      }
      return element.divisionId === foundAttendance.division_id;
    });
    const parsedItem = { ...element };

    foundItems.forEach((foundItem) => {
      parsedItem.sentData = {
        ...parsedItem?.sentData,
        [foundItem.date]: { ...foundItem, value: foundItem.qty },
      };
    });
    clonedDivisions.push(parsedItem);
  });
  return clonedDivisions;
};

const handleStatusUrl = (isSubject) => {
  if (isSubject) {
    return getSubjectsAttendanceStatusUrl();
  }
  return getGeneralAttendanceStatusUrl();
};

const handleSaveAttendanceUrl = (isSubject) => {
  if (isSubject) {
    return getAttendanceSubjectsUrl();
  }
  return getAttendanceUrl();
};

export {
  ATTENDANCE_DAY_DIALOG_COLUMNS,
  handleParseColumns,
  handleParsedRows,
  handleStatusUrl,
  handleSaveAttendanceUrl,
};
