import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Alert,
  Box,
  Typography,
  Divider,
  Tooltip,
} from '@mui/material';
import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { X as CloseIcon } from 'react-feather';

import FeatherIconButton from 'src/components/FeatherIconButton';

export const GenericConfirmDialog = ({
  onConfirm,
  onClose,
  open,
  title,
  content,
  confirmText,
  cancelText,
  status = {
    severity: '',
    loading: false,
    message: '',
  },
  disableBackDropClick,
  withTitleCloseButton,
  withTitleDivider,
  withoutCanceling = false,
  actionsProps,
  titleProps,
  confirmButtonProps,
  tooltipConfirmTitle = null,
  ...rest
}) => {
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' && disableBackDropClick) return;
    onClose(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
    >
      <DialogTitle {...titleProps}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant={'h2'}>
            {title ?? <Trans>Confirmar acción</Trans>}
          </Typography>
          {withTitleCloseButton && (
            <FeatherIconButton
              onClick={onClose}
              Icon={CloseIcon}
              sx={{ color: 'text.secondary' }}
            />
          )}
        </Box>
      </DialogTitle>

      {withTitleDivider && <Divider />}

      <DialogContent>
        {!status.severity && (
          <>
            {typeof content === 'string' ? (
              <DialogContentText>
                {content ?? <Trans>¿Deseas continuar?</Trans>}
              </DialogContentText>
            ) : (
              content
            )}
          </>
        )}

        {status && status.severity && (
          <Alert severity={status.severity}>{status.message}</Alert>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 2 }} {...actionsProps}>
        {!withoutCanceling && (
          <Button onClick={handleClose} disabled={status.loading}>
            {cancelText ?? <Trans>Cancelar</Trans>}
          </Button>
        )}
        <Tooltip title={tooltipConfirmTitle} arrow>
          <span>
            <LoadingButton
              onClick={onConfirm}
              color="primary"
              variant="contained"
              loading={status.loading}
              {...confirmButtonProps}
            >
              {confirmText ?? <Trans>Confirmar</Trans>}
            </LoadingButton>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

GenericConfirmDialog.propTypes = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  status: PropTypes.shape({
    severity: PropTypes.string,
    loading: PropTypes.bool,
    message: PropTypes.string,
  }),
  disableBackDropClick: PropTypes.bool,
  withTitleCloseButton: PropTypes.bool,
  withTitleDivider: PropTypes.bool,
  actionsProps: PropTypes.object,
  titleProps: PropTypes.object,
  confirmButtonProps: PropTypes.object,
  withoutCanceling: PropTypes.bool,
  tooltipConfirmTitle: PropTypes.string,
};
