import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import { useSetAtom } from 'jotai';

import { USER_DEFAULT_IMG } from 'src/config/constants';
import getInitials from 'src/shared/utils/getInitials';
import { dialogs } from 'src/shared/constants';
import { useOpenDialog } from 'src/shared/hooks';
import { selectedUserInfoAtom } from 'src/features/my-account/atoms';

import useStyles from './styles';
import ConditionalWrapper from '../ConditionalWrapper';
import { useTheme } from '@mui/styles';

const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const formatFullName = (name, lastName) => `${lastName}, ${name}`;

const UserAvatar = ({
  user = {},
  size = SIZES.LARGE,
  width,
  height,
  variant = 'circle',
  children,
  childrenProps,
  displayFullName,
  formatName = formatFullName,
  fullNameClassName,
  isClickable,
  showTooltip,
  avatarStyles,
  showAlias,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { name, last_name: lastName, photo, alias } = user;
  const fullName = formatName(name, lastName);
  const userAlias = alias || fullName;
  const displayName = showAlias ? userAlias : fullName;
  const userName = size === SIZES.SMALL ? name : fullName;
  const userInitials = getInitials(userName);
  const sizeClass = clsx({
    [classes.avatar]: true,
    [classes.avatarSmall]: size === SIZES.SMALL,
    [classes.avatarMedium]: size === SIZES.MEDIUM,
    [classes.isClickable]: isClickable,
  });
  const openUserInfoViewDialog = useOpenDialog(dialogs.USER_INFO_DIALOG);
  const setSelectedUserInfo = useSetAtom(selectedUserInfoAtom);

  const photoSrc = useMemo(() => {
    if (!photo) return null;

    return photo.indexOf(USER_DEFAULT_IMG) <= -1 ? photo : null;
  }, [photo]);

  const onClickAvatar = (e) => {
    if (isClickable) {
      e.stopPropagation();
      setSelectedUserInfo(user);
      openUserInfoViewDialog(true);
    }
  };

  return (
    <Box
      {...props}
      onClick={onClickAvatar}
      {...(isClickable && { sx: { cursor: 'pointer' } })}
    >
      <Box display="flex" alignItems="center">
        <ConditionalWrapper
          condition={Boolean(showTooltip)}
          wrapper={(tooltipChildren) => (
            <Tooltip title={displayName}>{tooltipChildren}</Tooltip>
          )}
        >
          <Avatar
            src={photoSrc}
            variant={variant}
            className={sizeClass}
            alt={`${name} ${lastName}`}
            style={{
              width,
              height,
              ...avatarStyles,
              backgroundColor: photoSrc
                ? 'initial'
                : theme.palette.primary.main,
            }}
          >
            {userInitials}
          </Avatar>
        </ConditionalWrapper>

        {displayFullName && (
          <Box display="flex">
            <Typography
              className={clsx(classes.avatarFullName, fullNameClassName)}
            >
              {displayName}
            </Typography>
          </Box>
        )}
      </Box>

      {children && <Box {...childrenProps}>{children}</Box>}
    </Box>
  );
};

UserAvatar.displayName = 'UserAvatar';

UserAvatar.propTypes = {
  user: PropTypes.object,
  size: PropTypes.oneOf([...Object.values(SIZES)]),
  children: PropTypes.node,
  width: PropTypes.number,
  height: PropTypes.number,
  variant: PropTypes.oneOf(['circle', 'circular', 'rounded', 'square']),
  childrenProps: PropTypes.object,
  displayFullName: PropTypes.bool,
  showTooltip: PropTypes.bool,
  formatName: PropTypes.func,
  fullNameClassName: PropTypes.string,
  isClickable: PropTypes.bool,
  avatarStyles: PropTypes.object,
  showAlias: PropTypes.bool,
};

export default memo(UserAvatar);
