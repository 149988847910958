import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { useDelete } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';
import { deleteConversation } from 'src/shared/services/url/UrlCommunication';

const useDeleteConversation = (conversationId, userId) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const queryKey = {
    predicate: (query) => {
      return query.queryKey[0] === QUERY_KEY.CONVERSATIONS_LIST;
    },
  };

  const updateConversations = (pages) => {
    return pages.map((page) => {
      return {
        ...page,
        data: page.data.filter(({ id }) => id !== conversationId),
      };
    });
  };

  const prevValue = queryClient.getQueryData(queryKey);

  return useDelete(
    deleteConversation(conversationId, userId),
    {
      onMutate: () => {
        const pages = updateConversations(prevValue.pages);
        queryClient.setQueriesData(queryKey, (oldValue) => {
          return {
            ...oldValue,
            pages,
          };
        });
      },
      onSuccess: () => {
        enqueueSnackbar(t`El mensaje se borró con éxito.`, {
          variant: 'success',
        });
      },
      onError: () => {
        queryClient.setQueryData(queryKey, prevValue);
        enqueueSnackbar(t`No pudimos borrar el mensaje.`, {
          variant: 'error',
        });
      },
    },
    false,
  );
};

export default useDeleteConversation;
