import { useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';

import { Api as api } from 'src/shared/services/api';
import {
  paymentManagementConfig,
  paymentManagementSingleConfig,
  paymentManagementBootstrap,
  paymentMethod,
  singlePaymentMethod,
  paymentManagementVariation,
  paymentManagementSingleVariation,
} from 'src/shared/services/url/UrlPaymentManagement';
import useTryCatch from 'src/shared/hooks/useTryCatch';
import { CURRENCIES } from 'src/config/constants';

import { parseConfigFormValuesToApi } from './helpers';
import useCurrencies from '../../../../shared/hooks/useCurrencies';

const defaultErrorMessage = t`Ha ocurrido un error. Intente mas tarde.`;

const defaultPaginationParams = { page: 0, limit: 20 };

const usePaymentManagementConfig = () => {
  const { handleTryCatch } = useTryCatch();
  const { currencies } = useCurrencies();
  const [isCreatingConfig, setIsCreatingConfig] = useState(false);
  const [isCreatingConfigVariation, setIsCreatingConfigVariation] =
    useState(false);
  const [isCreatingPaymentMethod, setIsCreatingPaymentMethod] = useState(false);
  const [isEditingPaymentMethod, setIsEditingPaymentMethod] = useState(false);
  const [isEditingConfigVariation, setIsEditingConfigVariation] =
    useState(false);
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(false);
  const [isDeletingPaymentMethod, setIsDeletingPaymentMethod] = useState(false);
  const [isLoadingModuleBootstrap, setIsLoadingModuleBootstrap] =
    useState(false);
  const [isEditingConfig, setIsEditingConfig] = useState(false);
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodsPagination, setPaymentMethodsPagination] = useState({
    total: 0,
    page: 0,
    limit: 20,
  });
  const [currentPaymentManagementConfig, setCurrentPaymentManagementConfig] =
    useState(null);
  const [moduleBootstrap, setModuleBootstrap] = useState(null);

  const currentInstitutionId = useSelector(
    ({ user }) => user.selectedInstitution.id,
  );

  const { enqueueSnackbar } = useSnackbar();

  const fetchModuleBootstrap = useCallback(async () => {
    setIsLoadingModuleBootstrap(true);

    try {
      const { data } = await api.get({
        url: paymentManagementBootstrap(),
      });

      setModuleBootstrap(data);
    } catch (error) {
      enqueueSnackbar(error.message ?? defaultErrorMessage, {
        variant: 'error',
      });
    } finally {
      setIsLoadingModuleBootstrap(false);
    }
  }, [enqueueSnackbar]);

  const fetchPaymentManagementConfig = useCallback(async () => {
    setIsLoadingConfig(true);

    await handleTryCatch(async () => {
      const { data: raw } = await api.get({
        getRaw: true,
        url: paymentManagementConfig(),
      });

      const { data: rawDiscount } = await api.get({
        getRaw: true,
        url: paymentManagementVariation(),
        data: {
          entity_type: 'invoice_discount_before_due_date',
        },
      });

      const { data: rawDueInterest } = await api.get({
        getRaw: true,
        url: paymentManagementVariation(),
        data: {
          entity_type: 'invoice_interests_after_due_date',
        },
      });

      const { data: rawDailyInterest } = await api.get({
        getRaw: true,
        url: paymentManagementVariation(),
        data: {
          entity_type: 'invoice_daily_interest',
        },
      });

      const [config] = raw.data;
      const fallbackNumberDayDailyInterest =
        config?.number_of_day_to_daily_interest < 0
          ? 0
          : config?.number_of_day_to_daily_interest;

      const parsedConfig = {
        ...config,
        number_of_day_to_daily_interest: fallbackNumberDayDailyInterest,
        interestVariation: rawDueInterest.data[0],
        dailyInterestVariation: rawDailyInterest.data[0],
        discountVariation: rawDiscount.data[0],
        currency:
          currencies.find((currency) => currency.id === config?.currency) ??
          CURRENCIES.ARS,
      };

      setCurrentPaymentManagementConfig(parsedConfig);
    }, defaultErrorMessage);

    setIsLoadingConfig(false);
  }, [currencies, handleTryCatch]);

  const fetchPaymentMethods = useCallback(
    async ({ page = 0, limit = 20, search = '' } = defaultPaginationParams) => {
      setIsLoadingPaymentMethods(true);

      try {
        const { data: raw } = await api.get({
          getRaw: true,
          url: paymentMethod(),
          data: {
            name: search,
            page: !page ? 1 : page + 1,
            perPage: limit,
          },
        });

        setPaymentMethods(raw.data);
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingPaymentMethods(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleCreateConfigVariation = useCallback(
    async (variation) => {
      setIsCreatingConfigVariation(true);

      try {
        await api.post({
          url: paymentManagementVariation(),
          data: {
            ...variation,
          },
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });

        throw new Error(error);
      } finally {
        setIsCreatingConfigVariation(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleEditConfigVariation = useCallback(
    async (variation) => {
      setIsEditingConfigVariation(false);
      try {
        await api.post({
          url: paymentManagementSingleVariation(variation.id),
          data: {
            ...variation,
          },
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });

        throw new Error(error);
      } finally {
        setIsEditingConfigVariation(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleCreatePaymentManagementConfig = useCallback(
    async (values) => {
      setIsCreatingConfig(true);

      const {
        discountVariation,
        discountType,
        discountFixedValue,
        discountPercentageValue,
        interestVariation,
        interestType,
        interestFixedValue,
        interestPercentageValue,
        dailyInterestVariation,
        dailyInterestType,
        dailyInterestFixedValue,
        dailyInterestPercentageValue,
        selectedInterestType,
      } = values;

      const payload = parseConfigFormValuesToApi(values);

      try {
        await api.post({
          url: paymentManagementConfig(),
          data: {
            ...payload,
            institution_id: currentInstitutionId,
          },
        });

        // Create a custom variation for early payment discount if it is enabled
        if (discountVariation) {
          await handleEditConfigVariation({
            ...discountVariation,

            apply_type: discountType === 'fixed' ? 'value' : 'percent',
            value:
              discountType === 'fixed'
                ? discountFixedValue
                : discountPercentageValue,
          });
        } else {
          await handleCreateConfigVariation({
            name: t`Descuento pronto pago`,
            entity_type: 'invoice_discount_before_due_date',
            type: 'discount',
            recurrent: true,
            apply_type: discountType === 'fixed' ? 'value' : 'percent',
            entity_id: null,
            value:
              discountType === 'fixed'
                ? discountFixedValue
                : discountPercentageValue,
          });
        }

        // pain... to much pain... but it works
        if (selectedInterestType === 'due' || selectedInterestType === 'both') {
          if (interestVariation) {
            await handleEditConfigVariation({
              ...interestVariation,
              apply_type: interestType === 'fixed' ? 'value' : 'percent',
              value:
                interestType === 'fixed'
                  ? interestFixedValue
                  : interestPercentageValue,
            });
          } else {
            await handleCreateConfigVariation({
              name: t`Recargo por mora`,
              entity_type: 'invoice_interests_after_due_date',
              type: 'increment',
              recurrent: true,
              apply_type: interestType === 'fixed' ? 'value' : 'percent',
              entity_id: null,
              value:
                interestType === 'fixed'
                  ? interestFixedValue
                  : interestPercentageValue,
            });
          }
        }

        if (
          selectedInterestType === 'daily' ||
          selectedInterestType === 'both'
        ) {
          if (dailyInterestVariation) {
            await handleEditConfigVariation({
              ...dailyInterestVariation,
              apply_type: dailyInterestType === 'fixed' ? 'value' : 'percent',
              value:
                dailyInterestType === 'fixed'
                  ? dailyInterestFixedValue
                  : dailyInterestPercentageValue,
            });
          } else {
            await handleCreateConfigVariation({
              name: t`Interés Diario`,
              entity_type: 'invoice_daily_interest',
              type: 'increment',
              recurrent: true,
              apply_type: interestType === 'fixed' ? 'value' : 'percent',
              entity_id: null,
              value:
                interestType === 'fixed'
                  ? dailyInterestFixedValue
                  : dailyInterestPercentageValue,
            });
          }
        }

        await fetchPaymentManagementConfig();

        enqueueSnackbar(t`Configuración guardada con éxito.`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });

        throw new Error(error);
      } finally {
        setIsCreatingConfig(false);
      }
    },
    [
      currentInstitutionId,
      enqueueSnackbar,
      fetchPaymentManagementConfig,
      handleCreateConfigVariation,
      handleEditConfigVariation,
    ],
  );

  const handleEditPaymentManagementConfig = useCallback(
    async ({ id, ...values }) => {
      setIsEditingConfig(true);

      const {
        discountVariation,
        discountType,
        discountFixedValue,
        discountPercentageValue,
        interestVariation,
        interestType,
        interestFixedValue,
        interestPercentageValue,
        dailyInterestVariation,
        dailyInterestType,
        dailyInterestFixedValue,
        dailyInterestPercentageValue,
        selectedInterestType,
        withInterest,
      } = values;

      const payload = parseConfigFormValuesToApi(values);

      try {
        await api.put({
          url: paymentManagementSingleConfig(id),
          data: {
            ...payload,
            institution_id: currentInstitutionId,
          },
        });

        if (discountVariation) {
          await api.put({
            url: paymentManagementSingleVariation(discountVariation.id),
            data: {
              ...discountVariation,
              apply_type: discountType === 'fixed' ? 'value' : 'percent',
              value:
                discountType === 'fixed'
                  ? discountFixedValue
                  : discountPercentageValue,
            },
          });
        } else {
          await api.post({
            url: paymentManagementVariation(),
            data: {
              name: t`Descuento pronto pago`,
              entity_type: 'invoice_discount_before_due_date',
              type: 'discount',
              recurrent: true,
              apply_type: discountType === 'fixed' ? 'value' : 'percent',
              entity_id: null,
              value:
                discountType === 'fixed'
                  ? discountFixedValue
                  : discountPercentageValue,
            },
          });
        }

        if (
          withInterest &&
          (selectedInterestType === 'due' || selectedInterestType === 'both')
        ) {
          if (interestVariation) {
            await api.put({
              url: paymentManagementSingleVariation(interestVariation.id),
              data: {
                ...interestVariation,
                apply_type: interestType === 'fixed' ? 'value' : 'percent',
                value:
                  interestType === 'fixed'
                    ? interestFixedValue
                    : interestPercentageValue,
              },
            });
          } else {
            await api.post({
              url: paymentManagementVariation(),
              data: {
                name: t`Recargo por mora`,
                entity_type: 'invoice_interests_after_due_date',
                type: 'increment',
                recurrent: true,
                entity_id: null,
                apply_type: interestType === 'fixed' ? 'value' : 'percent',
                value:
                  interestType === 'fixed'
                    ? interestFixedValue
                    : interestPercentageValue,
              },
            });
          }
        }

        if (
          withInterest &&
          (selectedInterestType === 'daily' || selectedInterestType === 'both')
        ) {
          if (dailyInterestVariation) {
            await api.put({
              url: paymentManagementSingleVariation(dailyInterestVariation.id),
              data: {
                ...dailyInterestVariation,
                apply_type: dailyInterestType === 'fixed' ? 'value' : 'percent',
                value:
                  dailyInterestType === 'fixed'
                    ? dailyInterestFixedValue
                    : dailyInterestPercentageValue,
              },
            });
          } else {
            await api.post({
              url: paymentManagementVariation(),
              data: {
                name: t`Interés Diario`,
                entity_type: 'invoice_daily_interest',
                type: 'increment',
                recurrent: true,
                apply_type: interestType === 'fixed' ? 'value' : 'percent',
                entity_id: null,
                value:
                  interestType === 'fixed'
                    ? dailyInterestFixedValue
                    : dailyInterestPercentageValue,
              },
            });
          }
        }

        await fetchPaymentManagementConfig();

        enqueueSnackbar(t`Configuración guardada con éxito.`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsEditingConfig(false);
      }
    },
    [currentInstitutionId, enqueueSnackbar, fetchPaymentManagementConfig],
  );

  const handleCreatePaymentMethod = useCallback(
    async (values) => {
      setIsCreatingPaymentMethod(true);
      try {
        const { data } = await api.post({
          url: paymentMethod(),
          data: values,
        });

        setPaymentMethods((oldMethods) => [data, ...oldMethods]);

        setPaymentMethodsPagination((oldPagination) => ({
          ...oldPagination,
          total: oldPagination.total + 1,
        }));

        enqueueSnackbar(t`Método de pago creado con éxito.`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsCreatingPaymentMethod(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleEditPaymentMethod = useCallback(
    async ({ id, ...values }) => {
      setIsEditingPaymentMethod(true);
      try {
        const { data } = await api.put({
          url: singlePaymentMethod(id),
          data: values,
        });

        setPaymentMethods((oldMethods) =>
          oldMethods.map((method) => {
            if (method.id === id) {
              return data;
            }

            return method;
          }),
        );

        enqueueSnackbar(t`Método de pago editado con éxito.`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsEditingPaymentMethod(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleDeletePaymentMethod = useCallback(
    async (id) => {
      setIsDeletingPaymentMethod(true);
      try {
        await api.delete({
          url: singlePaymentMethod(id),
        });

        enqueueSnackbar(t`Método de pago eliminado con éxito.`, {
          variant: 'success',
        });

        setPaymentMethods((oldMethods) =>
          oldMethods.filter((method) => method.id !== id),
        );

        setPaymentMethodsPagination((oldPagination) => ({
          ...oldPagination,
          total: oldPagination.total - 1,
        }));
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsDeletingPaymentMethod(false);
      }
    },
    [enqueueSnackbar],
  );

  const handlePaymentMethodsPageChange = useCallback(
    (newPage) => {
      fetchPaymentMethods({
        ...paymentMethodsPagination,
        page: newPage,
      });
    },
    [fetchPaymentMethods, paymentMethodsPagination],
  );

  const handlePaymentMethodsLimitPageChange = useCallback(
    (limit) => {
      fetchPaymentMethods({
        ...paymentMethodsPagination,
        limit,
      });
    },
    [fetchPaymentMethods, paymentMethodsPagination],
  );

  return useMemo(
    () => ({
      fetchPaymentManagementConfig,
      fetchPaymentMethods,
      fetchModuleBootstrap,

      handleCreatePaymentManagementConfig,
      handleCreatePaymentMethod,
      handleEditPaymentManagementConfig,
      handleEditPaymentMethod,
      handleDeletePaymentMethod,
      handlePaymentMethodsLimitPageChange,
      handlePaymentMethodsPageChange,

      currentPaymentManagementConfig,
      paymentMethodsPagination,
      moduleBootstrap,
      paymentMethods,

      isLoadingModuleBootstrap,
      isCreatingConfig,
      isCreatingPaymentMethod,
      isEditingConfig,
      isEditingPaymentMethod,
      isDeletingPaymentMethod,
      isLoadingPaymentMethods,
      isLoadingConfig,
      isCreatingConfigVariation,
      isEditingConfigVariation,
    }),
    [
      currentPaymentManagementConfig,
      fetchModuleBootstrap,
      fetchPaymentManagementConfig,
      fetchPaymentMethods,
      handleCreatePaymentManagementConfig,
      handleCreatePaymentMethod,
      handleDeletePaymentMethod,
      handleEditPaymentManagementConfig,
      handleEditPaymentMethod,
      handlePaymentMethodsLimitPageChange,
      handlePaymentMethodsPageChange,
      isCreatingConfigVariation,
      isEditingConfigVariation,
      isCreatingConfig,
      isCreatingPaymentMethod,
      isDeletingPaymentMethod,
      isEditingConfig,
      isEditingPaymentMethod,
      isLoadingConfig,
      isLoadingModuleBootstrap,
      isLoadingPaymentMethods,
      moduleBootstrap,
      paymentMethods,
      paymentMethodsPagination,
    ],
  );
};

export default usePaymentManagementConfig;
