import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    borderColor: theme.palette.grey[700],
    textTransform: 'capitalize',
    fontWeight: 'normal',
    padding: `${theme.spacing(0.5)} ${theme.spacing()}`,
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
