import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  TextField as MuiTextField,
  InputLabel,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

import useStyles from './styles';

const TextField = ({
  gridProps = { xs: 12 },
  inputGridProps = { xs: 12 },
  name,
  disabled,
  label,
  helperText: textFieldHelperText,
  disableHelperText = false,
  loading,
  maxLength,
  inputProps,
  withFloatingLabel = false,
  ...rest
}) => {
  const classes = useStyles();
  const [field, meta] = useField(name);
  const { isSubmitting } = useFormikContext();

  const parsedValue = String(field.value ?? '');
  const valueLength = Number(parsedValue.trim().length);
  const helperText =
    textFieldHelperText ?? maxLength ? (
      <span className={classes.helperText}>
        {textFieldHelperText}
        {maxLength && <span>{`${valueLength}/${maxLength}`}</span>}
      </span>
    ) : (
      meta.touched && meta.error
    );

  const InputProps = loading
    ? {
        endAdornment: (
          <InputAdornment position="end">
            <CircularProgress size={12} color="primary" />
          </InputAdornment>
        ),
      }
    : undefined;

  return (
    <Grid item {...gridProps}>
      <FormControl className={classes.formControl}>
        {!withFloatingLabel && label && (
          <InputLabel
            classes={{ formControl: classes.MuiInputLabelFormControl }}
          >
            {label}
          </InputLabel>
        )}
        <Grid item {...inputGridProps}>
          <MuiTextField
            fullWidth
            classes={{ root: classes.MuiInput }}
            error={Boolean(meta.touched && meta.error)}
            helperText={
              !disableHelperText
                ? (meta.touched && meta.error) || helperText
                : null
            }
            variant="outlined"
            autoComplete="off"
            disabled={isSubmitting || disabled || loading}
            InputProps={InputProps}
            inputProps={{
              ...inputProps,
              maxLength,
              'data-testid': `input-${name}`,
            }}
            {...(withFloatingLabel && {
              label,
              className: classes.floatingLabel,
            })}
            {...field}
            {...rest}
          />
        </Grid>
      </FormControl>
    </Grid>
  );
};

TextField.propTypes = {
  gridProps: PropTypes.object,
  inputGridProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  disableHelperText: PropTypes.bool,
  loading: PropTypes.bool,
  maxLength: PropTypes.number,
  inputProps: PropTypes.object,
  withFloatingLabel: PropTypes.bool,
};

export default TextField;
