const QUERY_KEY = {
  USER_INFO: 'UserInfo',
  USER_CHILDREN: 'UserChildren',
  USER_PARENT: 'UserParent',
  CREATE_USER_PICKUP: 'CreateUserPickUp',
  USER_PICKUP: 'UserPickUp',
  USER_PICKUP_CATEGORY: 'UserPickUpCategory',
  USER_PICKUP_CATEGORY_DAY: 'UserPickUpCategoryDay',
  USER_PICKUP_REPORT: 'UserPickUpReport',
  USER_REPORT_CARD: 'UserReportCard',
  STUDENT_ATTENDANCE: 'StudentAttendance',
  STUDENT_ATTENDANCE_TOTAL: 'StudentAttendanceTotal',
  USER_PRIMARY_REPORT: 'UserPrimaryReport',
  STUDENT_SCORE: 'StudentScore',
  STUDENT_FINAL_SCORE: 'StudentFinalScore',
  PERMISSION_SEND_MESSAGE: 'PermissionSendMessage',
  USER_CONFIG_NOTIFICATIONS: 'UserConfigNotifications',
  USER_ACTIONS_MENU: 'UserActionsMenu',
  PROFILE_OPTIONS: 'ProfileOptions',
  FULL_STRUCTURE: 'FullStructure',
  PROFILE_PERMITS: 'ProfilePermits',
  CONVERSATION_WHITE_LIST: 'ConversationWhiteList',
  USER_CUSTOM_ROLE: 'UserCustomRole',
  USER_ALIAS: 'UserAlias',
  INSTITUTION_MODULES: 'InstitutionModules',
  STUDENT_NOTEBOOK_TYPE: 'StudentNotebookType',
  STUDENT_NOTEBOOK: 'StudentNotebook',
  USER_SPECIAL_PERMISSION: 'UserSpecialPermission',
};

export { QUERY_KEY };
