import Axios from 'axios';
import { Api } from 'src/shared/services/api';
import {
  getConfigApp,
  setInstitutionUrl,
} from 'src/shared/services/url/UrlConfiguration';

const effects = (dispatch) => ({
  async setConfiguration(generalConfiguration) {
    try {
      await dispatch.configuration.setGeneralConfiguration(
        generalConfiguration,
      );
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.log(exception);
      throw exception;
    }
  },

  async setLegacyActiveModules(activeModules) {
    try {
      await dispatch.configuration.setActiveModules(activeModules);
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.log(exception);
      throw exception;
    }
  },

  async setSisInstitution({ legacyAuth, externalId }) {
    const data = new FormData();
    data.append('institucion_id', externalId);

    await Axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authentication: legacyAuth,
      },
      url: setInstitutionUrl(),
      data,
    });
  },

  async initGeneralConfiguration() {
    try {
      const { data: generalConfiguration } = await Api.get({
        url: getConfigApp(),
      });
      await this.setConfiguration(generalConfiguration);
    } catch (exception) {
      console.log(exception);
      throw exception;
    }
  },
});

export default effects;
