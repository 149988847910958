import React from 'react';
import { Divider, Box } from '@mui/material';
import { isDatePickerView } from '@mui/x-date-pickers/internals/utils/date-utils';
import { VIEW_HEIGHT } from '@mui/x-date-pickers/internals/constants/dimensions';
import { DateCalendar, DigitalClock } from '@mui/x-date-pickers';
import useStyles from './styles';

const RenderDateDigitalTimeView = ({
  sx,
  ampm,
  minDate,
  minTime,
  maxDate,
  maxTime,
  ampmInClock,
  yearsPerRow,
  timeStep,
  view,
  views,
  focusedView,
  timeViewsCount,
  disableIgnoringDatePartForTimeValidation,
  ...props
}) => {
  const {
    fullWidth,
    inputFormat,
    textField,
    viewRenderers,
    localeText,
    onSelectedSectionsChange,
    timeSteps,
    openTo,
    showViewSwitcher,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Box display="flex" my={0}>
      <DateCalendar
        view={isDatePickerView(view) ? view : 'day'}
        views={views.filter(isDatePickerView)}
        focusedView={
          focusedView && isDatePickerView(focusedView) ? focusedView : null
        }
        minDate={minDate}
        maxDate={maxDate}
        yearsPerRow={yearsPerRow}
        {...rest}
      />
      {timeViewsCount > 0 && (
        <>
          <Divider orientation="vertical" />
          <DigitalClock
            view={'hours'}
            focusedView={
              focusedView && !isDatePickerView(focusedView) ? 'hours' : null
            }
            views={['hours']}
            minTime={minTime}
            maxTime={maxTime}
            ampm={ampm}
            timeStep={timeStep}
            className={classes.customDigitalClock}
            sx={{
              borderBottom: 0,
              width: 'auto',
              maxHeight: VIEW_HEIGHT,
              ...(Array.isArray(sx) ? sx : [sx]),
            }}
            disableIgnoringDatePartForTimeValidation={
              disableIgnoringDatePartForTimeValidation
            }
            {...rest}
          />
        </>
      )}
    </Box>
  );
};

export default RenderDateDigitalTimeView;
