import { familyExternalEntity } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { useGetPaginatedList } from 'src/shared/services/api/query';

const useFamilyExternalEntity = (params = {}) => {
  return useGetPaginatedList(
    [QUERY_KEY.FAMILY_EXTERNAL_ENTITY, params],
    familyExternalEntity(),
    { sort: 'id', ...params },
  );
};

export default useFamilyExternalEntity;
