import { externalEntity } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { useGetPaginatedList } from 'src/shared/services/api/query';

const useExternalEntity = (params = {}) => {
  return useGetPaginatedList(
    [QUERY_KEY.EXTERNAL_ENTITY, params],
    externalEntity(),
    { sort: 'id', ...params },
  );
};

export default useExternalEntity;
