import { useRef, useCallback, useState, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';

import { Api as api } from 'src/shared/services/api';
import {
  paymentManagementRates,
  paymentManagementConcepts,
  paymentManagementUsers,
  paymentManagementSingleRate,
  paymentManagementUsersRemove,
  paymentManagementRemoveUserFromRate,
  singleUserRates,
  paymentManagementVariationUser,
  paymentManagementVariationUsersRemove,
  paymentManagementSingleVariation,
  paymentManagementVariation,
} from 'src/shared/services/url/UrlPaymentManagement';

import { getMonthRange, toDateFormat } from 'src/shared/utils/dates';
import dayjs from 'dayjs';
import { apiFilter } from 'src/shared/utils/apiFilter';
import usePaymentManagementConfig from 'src/features/payment-management/hooks/usePaymentManagementConfig';
import { handleFindDiscounts } from '../../tables/HighRoleConceptsTable/helpers';

const defaultErrorMessage = t`Ha ocurrido un error. Intente mas tarde.`;

const defaultParams = { page: 0, limit: 20, search: '', byRecurrent: false };

const usePaymentManagementConcepts = () => {
  const { fetchPaymentManagementConfig, currentPaymentManagementConfig } =
    usePaymentManagementConfig();

  const [rates, setRates] = useState([]);
  const [concepts, setConcepts] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [pagination, setPagination] = useState({
    total: 0,
    page: 0,
    limit: 20,
  });
  const [isLoadingRates, setIsLoadingRates] = useState(false);
  const [isLoadingConcepts, setIsLoadingConcepts] = useState(false);
  const [isCreatingConcept, setIsCreatingConcept] = useState(false);
  const [isDeletingConcept, setIsDeletingConcept] = useState(false);
  const [isEditingConcept, setIsEditingConcept] = useState(false);
  const [isLoadingSelectedMembers, setIsLoadingSelectedMembers] =
    useState(false);
  const [isRemovingConceptFromUser, setIsRemovingConceptFromUser] =
    useState(false);
  const [isLoadingUsersByConcept, setIsLoadingUsersByConcept] = useState(false);
  const [isAttachingConceptsToMember, setIsAttachingConceptsToMember] =
    useState(false);
  const [filteredSelectedMembers, setFilteredSelectedMembers] = useState(null);
  const [lastSearchTextSelectedMembers, setLastSearchTextSelectedMembers] =
    useState('');

  const [filteredUsersByConcept, setFilteredUsersByConcept] = useState(null);
  const [lastSearchTextUsersByConcept, setLastSearchTextUsersByConcept] =
    useState('');

  const [usersByConcept, setUsersByConcept] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isConceptDialogOpen, setIsConceptDialogOpen] = useState(false);
  const [isDiscountDialogOpen, setIsDiscountDialogOpen] = useState(false);
  const [activeEntity, setActiveEntity] = useState(null);
  const [entityToDelete, setEntityToDelete] = useState(null);
  const [unfoldedConcept, setUnfoldedConcept] = useState(null);

  // Should only be use to determine whats going to be affected by the resetStepFields run
  const [fieldsToBeAffectedInReset, setFieldsToBeAffectedInReset] = useState(
    {},
  );

  const initialSelectedMembers = useRef([]);
  const initialSelectedConcepts = useRef([]);

  // DISCOUNTS
  const [isDeletingDiscountFromRate, setIsDeletingDiscountFromRate] =
    useState(false);
  const [isCreatingDiscount, setIsCreatingDiscountToRate] = useState(false);
  const [isEditingDiscount, setIsEditingDiscountToRate] = useState(false);

  const [isLoadingDiscountsByRate, setIsLoadingDiscountsByRate] =
    useState(false);
  const [discountsByRate, setDiscountsByRate] = useState([]);

  const { userIs } = useSelector(({ user }) => user);

  const { institutionPeriodId, institutionId } = useSelector(({ user }) => ({
    institutionPeriodId: user.selectedInstitutionPeriodId,
    institutionId: user.selectedInstitution.id,
  }));

  const { enqueueSnackbar } = useSnackbar();

  const resetStepsFields = useCallback(() => {
    const findField = (field) => fieldsToBeAffectedInReset?.[field];

    if (!fieldsToBeAffectedInReset) {
      setCurrentStep(0);
      setSelectedMembers([]);
    }

    // TODO: Find a better way
    if (findField('currentStep')) setCurrentStep(findField('currentStep'));
    else if (findField('selectedMembers'))
      setSelectedMembers(findField('selectedMembers'));
  }, [fieldsToBeAffectedInReset]);

  const fetchPaymentManagementUsersByConcept = useCallback(
    async (rate) => {
      setIsLoadingUsersByConcept(true);

      try {
        const { data: raw } = await api.get({
          url: paymentManagementUsers(rate.id),
          getRaw: true,
          data: {
            perPage: 2000,
          },
        });

        setUsersByConcept(raw.data);
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingUsersByConcept(false);
      }
    },
    [enqueueSnackbar],
  );

  const fetchPaymentManagementRates = useCallback(
    async ({
      page = 0,
      limit = 20,
      search,
      byRecurrent,
      ...params
    } = defaultParams) => {
      setIsLoadingRates(true);
      const currentMonthIndex = dayjs().get('month');
      const { startAt, endAt } = getMonthRange(currentMonthIndex);
      try {
        const { data: raw, headers } = await api.get({
          url: paymentManagementRates(),
          getRaw: true,
          data: {
            expand: 'userRates,concept,variations',
            name: search,
            page: !page ? 1 : page + 1,
            perPage: limit,
            recurrent: byRecurrent,
            sort: '-id',
            ...(!byRecurrent && !userIs.admin
              ? {
                  [apiFilter.startAtGte]: startAt.format('YYYY-MM-DD'),
                  [apiFilter.endAtLte]: endAt.format('YYYY-MM-DD'),
                }
              : undefined),
            ...params,
          },
        });

        initialSelectedConcepts.current = raw.data;
        setRates(raw.data);
        setPagination({
          page,
          limit,
          total: Number(headers['x-pagination-total-count']),
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingRates(false);
      }
    },
    [enqueueSnackbar, userIs],
  );

  const fetchPaymentManagementConcepts = useCallback(
    async (params) => {
      setIsLoadingConcepts(true);
      try {
        const { data: raw } = await api.get({
          url: paymentManagementConcepts(),
          getRaw: true,
          data: { expand: 'userRates', 'per-page': 2000, ...params },
        });

        setConcepts(raw.data);
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingConcepts(false);
      }
    },
    [enqueueSnackbar],
  );

  const debouncedFetchAllPaymentConfigConcepts = useDebouncedCallback(
    (event) => {
      fetchPaymentManagementRates(event);
    },
    300,
  );

  const fetchPaymentManagementSelectedMembers = useCallback(
    async (rate) => {
      setIsLoadingSelectedMembers(true);

      try {
        const { data: raw } = await api.get({
          url: paymentManagementUsers(rate.id),
          getRaw: true,
          data: { expand: 'userRates', 'per-page': 1000 },
        });
        setSelectedMembers(raw.data);
        initialSelectedMembers.current = raw.data;
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingSelectedMembers(false);
      }
    },
    [enqueueSnackbar],
  );

  const fetchPaymentManagementDiscountSelectedMembers = useCallback(
    async (discountId) => {
      setIsLoadingSelectedMembers(true);

      try {
        const { data: raw } = await api.get({
          url: paymentManagementVariationUser(discountId),
          getRaw: true,
          data: { expand: 'userRates', 'per-page': 1000 },
        });

        setLastSearchTextSelectedMembers('');
        setSelectedMembers(raw.data);
        initialSelectedMembers.current = raw.data;
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingSelectedMembers(false);
      }
    },
    [enqueueSnackbar],
  );

  const handlePageChange = useCallback(
    async (newPage) => {
      await fetchPaymentManagementRates({
        ...pagination,
        page: newPage,
      });
    },
    [fetchPaymentManagementRates, pagination],
  );

  const handleLimitChange = useCallback(
    async (limit) => {
      await fetchPaymentManagementRates({
        ...pagination,
        limit,
      });
    },
    [fetchPaymentManagementRates, pagination],
  );

  const handleSearch = useCallback(
    async (event) => {
      const { value: query } = event.target;
      const { pathname } = window.location;
      const isRecurrent = !pathname.includes('no-recurrente');

      setSearchValue(query);

      await debouncedFetchAllPaymentConfigConcepts({
        ...pagination,
        byRecurrent: isRecurrent,
        search: query,
      });
    },
    [debouncedFetchAllPaymentConfigConcepts, pagination],
  );

  const handleClearSearch = useCallback(async () => {
    setSearchValue('');

    await fetchPaymentManagementRates({
      ...pagination,
      search: '',
    });
  }, [fetchPaymentManagementRates, pagination]);

  const handleSelectMember = useCallback((member) => {
    setSelectedMembers((oldSelectedMembers) => {
      const isMemberExistent = oldSelectedMembers.find(
        (oldMember) => oldMember.id === member.id,
      );

      if (isMemberExistent) {
        return oldSelectedMembers.filter(
          (oldMember) => oldMember.id !== member.id,
        );
      }

      return [...oldSelectedMembers, member];
    });
  }, []);

  const handleSelectAllMembers = useCallback(
    (allMembers) => {
      if (allMembers.length === selectedMembers.length) {
        setSelectedMembers([]);
      } else {
        allMembers.map((member) => handleSelectMember(member));
      }
    },
    [handleSelectMember, selectedMembers.length],
  );

  const handleFilterSelectedMemberByName = useCallback(
    (searchText = '') => {
      let searchTerm = searchText;

      if (searchTerm == null) {
        searchTerm = lastSearchTextSelectedMembers;
      }

      setFilteredSelectedMembers(() => {
        if (searchTerm) {
          return selectedMembers.filter((member) =>
            `${member.name} ${member.last_name}`
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
          );
        }

        return null;
      });

      setLastSearchTextSelectedMembers(searchTerm);
    },
    [selectedMembers, lastSearchTextSelectedMembers],
  );

  const handleFilterUsersByConcept = useCallback(
    (searchText = '') => {
      let searchTerm = searchText;

      if (searchTerm == null) {
        searchTerm = lastSearchTextUsersByConcept;
      }

      setFilteredUsersByConcept(() => {
        if (searchTerm) {
          return usersByConcept.filter((member) =>
            `${member.name} ${member.last_name}`
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
          );
        }

        return null;
      });

      setLastSearchTextUsersByConcept(searchTerm);
    },
    [usersByConcept, lastSearchTextUsersByConcept],
  );

  const handleCreateConcept = useCallback(
    async ({ rate, members }) => {
      setIsCreatingConcept(true);

      try {
        let conceptId;

        if (rate.isNewConcept) {
          const { data: rawConcept } = await api.post({
            url: paymentManagementConcepts(),
            data: {
              institution_id: institutionId,
              institution_period_id: institutionPeriodId,
              name: rate.concept,
              requires_superior_auth: false,
            },
          });

          conceptId = rawConcept.id;
        }

        const { data: newRate } = await api.post({
          url: paymentManagementRates(),
          data: {
            name: rate.name,
            concept_id: conceptId ?? rate.concept.id,
            currency: currentPaymentManagementConfig?.currency?.id,
            value: rate.amount,
            recurrent: rate.isMonthly,
            ...(!rate.isMonthly
              ? {
                  start_at: toDateFormat(rate.startAt, 'YYYY-MM-DD'),
                  finish_at: toDateFormat(rate.endAt, 'YYYY-MM-DD'),
                }
              : undefined),
          },
        });

        await api.post({
          url: paymentManagementUsers(newRate.id),
          data: members.map((member) => ({
            user_id: member.id,
          })),
        });

        const concept = conceptId
          ? { name: rate.concept, id: conceptId }
          : { name: rate.concept.name, id: rate.concept.id };

        setRates((oldRates) => [
          {
            ...newRate,
            total_student: members.length,
            concept,
            variations: [],
          },
          ...oldRates,
        ]);

        setPagination((oldPagination) => ({
          ...oldPagination,
          total: oldPagination.total + 1,
        }));

        enqueueSnackbar(t`El concepto "${rate.name}" se ha creado con exito.`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsCreatingConcept(false);
      }
    },
    [
      currentPaymentManagementConfig,
      enqueueSnackbar,
      institutionId,
      institutionPeriodId,
    ],
  );

  const handleDeleteConcept = useCallback(
    async (rate) => {
      setIsDeletingConcept(true);
      try {
        await api.delete({
          url: paymentManagementSingleRate(rate.id),
        });

        setRates((oldRates) =>
          oldRates.filter((oldRate) => oldRate.id !== rate.id),
        );

        setPagination((oldPagination) => ({
          ...oldPagination,
          total: oldPagination.total - 1,
        }));

        enqueueSnackbar(
          t`El concepto "${rate.name}" se ha eliminado con exito.`,
          {
            variant: 'success',
          },
        );
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsDeletingConcept(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleEditConcept = useCallback(
    async ({ rate, members }) => {
      setIsEditingConcept(true);

      const removeMembersUrl = rate.isDiscount
        ? paymentManagementVariationUsersRemove(rate.variationId)
        : paymentManagementUsersRemove(rate.id);
      const updateRateVariationUrl = rate.isDiscount
        ? paymentManagementSingleVariation(rate.variationId)
        : paymentManagementSingleRate(rate.id);
      const addMembersUrl = rate.isDiscount
        ? paymentManagementVariationUser(rate.variationId)
        : paymentManagementUsers(rate.id);

      try {
        let conceptId;

        if (rate.isNewConcept) {
          const { data: rawConcept } = await api.post({
            url: paymentManagementConcepts(),
            data: {
              institution_id: institutionId,
              institution_period_id: institutionPeriodId,
              name: rate.concept,
              requires_superior_auth: false,
            },
          });

          conceptId = rawConcept.id;
        }

        // Carlito's fault
        const newMembers = members.filter(
          (member) =>
            !initialSelectedMembers.current.find(
              (selected) => member.id === selected.id,
            ),
        );

        const deletedMembers = initialSelectedMembers.current.filter(
          (selected) => !members.find((member) => selected.id === member.id),
        );

        const finalMembers = [
          ...initialSelectedMembers.current,
          ...newMembers,
        ].filter(
          (filteredMember) =>
            !deletedMembers.find(
              (deletedMember) => deletedMember.id === filteredMember.id,
            ),
        );

        if (newMembers.length > 0) {
          await api.post({
            url: addMembersUrl,
            data: newMembers.map((member) => ({
              user_id: member.id,
              recurrent: rate.isMonthly,
              ...(!rate.isMonthly
                ? {
                    start_at: toDateFormat(rate.startAt, 'YYYY-MM-DD', true),
                    finish_at: toDateFormat(rate.endAt, 'YYYY-MM-DD', true),
                  }
                : undefined),
            })),
          });
        }

        if (deletedMembers.length > 0) {
          await api.patch({
            url: removeMembersUrl,
            data: {
              user_id: deletedMembers.map((member) => member.id),
            },
          });
        }

        const { data: newRate } = await api.put({
          url: updateRateVariationUrl,
          data: {
            name: rate.name,
            concept_id: conceptId ?? rate.concept.id,
            value: rate.amount,
            recurrent: rate.isMonthly,
            ...(!rate.isMonthly
              ? {
                  start_at: toDateFormat(rate.startAt, 'YYYY-MM-DD', true),
                  finish_at: toDateFormat(rate.endAt, 'YYYY-MM-DD', true),
                }
              : undefined),
          },
        });

        setSelectedMembers(finalMembers);

        setRates((oldRates) =>
          oldRates.map((oldRate) => {
            if (oldRate.id === rate.id) {
              return { ...oldRate, ...newRate, concept: rate.concept };
            }

            return oldRate;
          }),
        );

        enqueueSnackbar(
          t`El concepto "${rate.name}" se ha editado con exito.`,
          {
            variant: 'success',
          },
        );
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });

        throw new Error(error);
      } finally {
        setIsEditingConcept(false);
      }
    },
    [enqueueSnackbar, institutionId, institutionPeriodId],
  );

  const handleAttachConceptsToMember = useCallback(
    async (newRates, user) => {
      setIsAttachingConceptsToMember(true);

      try {
        const message =
          newRates.length > 1
            ? t`Se le ha asignado ${newRates.length} conceptos a ${user.last_name} ${user.name}`
            : t`Se le ha asignado ${newRates[0].name} a ${user.last_name} ${user.name}`;

        await api.post({
          url: singleUserRates(user.user_id),
          data: {
            rate_id: newRates.map((rate) => rate.id),
          },
        });

        enqueueSnackbar(message, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });

        throw new Error(error);
      } finally {
        setIsAttachingConceptsToMember(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleRemoveConceptFromUser = useCallback(
    async (conceptsToRemove, user) => {
      setIsRemovingConceptFromUser(true);

      try {
        await api.patch({
          url: paymentManagementRemoveUserFromRate(user.user_id),
          data: {
            rate_id: conceptsToRemove.map((concept) => concept.id),
          },
        });

        const message =
          conceptsToRemove.length > 1
            ? t`Se le ha removido ${conceptsToRemove.length} de ${user.name} ${user.last_name}`
            : t`Se le ha removido ${conceptsToRemove[0].name} de ${user.name} ${user.last_name}`;

        enqueueSnackbar(message, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });

        throw new Error(error);
      } finally {
        setIsRemovingConceptFromUser(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleRemoveAllSelected = () => {
    setSelectedMembers([]);
  };

  const handleClickDiscounts = useCallback(
    (rate) => {
      const { id: rateId } = rate;
      const filteredRates = rates.filter((el) => !el.isDiscount);
      const foundIndex = filteredRates.findIndex((el) => el.id === rateId);
      const foundDiscounts =
        handleFindDiscounts(filteredRates, foundIndex) ?? [];
      const discountAlreadyInList =
        foundDiscounts.length > 0 &&
        rates.findIndex(
          (el) => el.id === foundDiscounts[0].id && el.isDiscount,
        ) > -1;
      if (discountAlreadyInList) {
        setRates(filteredRates);
      } else {
        const newRatesArray = [
          ...filteredRates.slice(0, foundIndex + 1),
          ...foundDiscounts,
          ...filteredRates.slice(foundIndex + 1),
        ];
        setRates(newRatesArray);
      }
    },
    [rates, setRates],
  );

  const fetchPaymentManagementDiscountsByRate = useCallback(
    async ({ search, byRecurrent, ...params } = defaultParams) => {
      setIsLoadingDiscountsByRate(true);

      try {
        const { data: raw } = await api.get({
          url: paymentManagementVariation(),
          getRaw: true,
          data: {
            name: search,
            perPage: 2000,
            entity_type: 'rate',
            recurrent: byRecurrent,
            expand: 'rate,users',
            ...params,
          },
        });

        setDiscountsByRate(raw.data);
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingDiscountsByRate(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleEditDiscountToRate = useCallback(
    async ({ discount, members }) => {
      setIsEditingDiscountToRate(true);

      try {
        // Carlito's fault
        const newMembers = members.filter(
          (member) =>
            !initialSelectedMembers.current.find(
              (selected) => member.id === selected.id,
            ),
        );

        const deletedMembers = initialSelectedMembers.current.filter(
          (selected) => !members.find((member) => selected.id === member.id),
        );

        const finalMembers = [
          ...initialSelectedMembers.current,
          ...newMembers,
        ].filter(
          (filteredMember) =>
            !deletedMembers.find(
              (deletedMember) => deletedMember.id === filteredMember.id,
            ),
        );

        if (newMembers.length > 0) {
          await api.post({
            url: paymentManagementVariationUser(discount.id),
            data: newMembers.map((member) => ({
              user_id: member.id,
            })),
          });
        }

        if (deletedMembers.length > 0) {
          await api.patch({
            url: paymentManagementVariationUsersRemove(discount.id),
            data: {
              user_id: deletedMembers.map((member) => member.id),
            },
          });
        }

        const { data: newDiscount } = await api.put({
          url: paymentManagementSingleVariation(discount.id),
          data: {
            entity_id: discount.rate.id,
            entity_type: 'rate',
            name: discount.name,
            value: discount.amount,
            type: 'discount',
            apply_type: 'percent',

            recurrent: discount.isMonthly,
            ...(!discount.isMonthly
              ? {
                  start_at: toDateFormat(discount.startAt, 'YYYY-MM-DD'),
                  finish_at: toDateFormat(discount.endAt, 'YYYY-MM-DD'),
                }
              : undefined),
          },
        });

        setDiscountsByRate((oldDiscounts) =>
          oldDiscounts.map((oldDiscount) => {
            if (oldDiscount.id === discount.id) {
              return {
                ...oldDiscount,
                ...newDiscount,
                total_student: members.length,
              };
            }

            return oldDiscount;
          }),
        );

        setSelectedMembers(finalMembers);

        enqueueSnackbar(
          t`El descuento "${newDiscount.name}" editado con exito.`,
          {
            variant: 'success',
          },
        );
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsEditingDiscountToRate(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleCreateDiscountToRate = useCallback(
    async ({ discount, members }) => {
      setIsCreatingDiscountToRate(true);

      try {
        const { data: newDiscount } = await api.post({
          url: paymentManagementVariation(),
          data: {
            entity_id: activeEntity.rate.id,
            entity_type: 'rate',
            name: discount.name,
            value: discount.amount,
            type: 'discount',
            apply_type: 'percent',

            recurrent: activeEntity.rate.isMonthly,
            ...(!activeEntity.rate.isMonthly
              ? {
                  start_at: toDateFormat(discount.startAt, 'YYYY-MM-DD'),
                  finish_at: toDateFormat(discount.endAt, 'YYYY-MM-DD'),
                }
              : undefined),
          },
        });

        await api.post({
          url: paymentManagementVariationUser(newDiscount.id),
          data: members.map((member) => ({
            user_id: member.id,
          })),
        });

        setDiscountsByRate((oldDiscounts) => [
          { ...newDiscount, total_student: members.length },
          ...oldDiscounts,
        ]);

        setRates((oldRates) =>
          oldRates.map((oldRate) => {
            if (oldRate.id === activeEntity.rate.id) {
              return {
                ...oldRate,
                variations: [
                  ...oldRate.variations,
                  { ...newDiscount, total_student: members.length },
                ],
              };
            }

            return oldRate;
          }),
        );

        enqueueSnackbar(
          t`El descuento "${newDiscount.name}" creado con exito.`,
          {
            variant: 'success',
          },
        );
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsCreatingDiscountToRate(false);
      }
    },
    [activeEntity, enqueueSnackbar],
  );

  const handleDeleteDiscountFromRate = useCallback(
    async (discount) => {
      setIsDeletingDiscountFromRate(true);

      try {
        await api.delete({
          url: paymentManagementSingleVariation(discount.id),
        });

        setDiscountsByRate((oldDiscounts) =>
          oldDiscounts.filter((oldDiscount) => oldDiscount.id !== discount.id),
        );

        setRates((oldRates) =>
          oldRates.map((oldRate) => {
            if (oldRate.id === discount.entity_id) {
              return {
                ...oldRate,
                variations: oldRate.variations.filter(
                  (oldVariation) => oldVariation.id !== discount.id,
                ),
              };
            }

            return oldRate;
          }),
        );

        enqueueSnackbar(
          t`El descuento "${discount.name}" se ha eliminado con exito.`,
          {
            variant: 'success',
          },
        );
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsDeletingDiscountFromRate(false);
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    fetchPaymentManagementConfig();
  }, [fetchPaymentManagementConfig]);

  return useMemo(
    () => ({
      fetchPaymentManagementRates, // -
      fetchPaymentManagementConcepts,
      fetchPaymentManagementSelectedMembers, // -
      fetchPaymentManagementUsersByConcept,
      fetchPaymentManagementDiscountsByRate,
      fetchPaymentManagementDiscountSelectedMembers, // -

      handleClearSearch,
      handleSearch,
      handleLimitChange,
      handlePageChange,
      handleSelectMember,
      handleSelectAllMembers,
      handleRemoveAllSelected,
      handleFilterUsersByConcept,
      handleFilterSelectedMemberByName,
      handleCreateConcept,
      handleDeleteConcept,
      handleEditConcept,
      handleAttachConceptsToMember,
      handleRemoveConceptFromUser,
      handleClickDiscounts,
      handleEditDiscountToRate,
      handleCreateDiscountToRate,
      handleDeleteDiscountFromRate,

      rates,
      concepts,
      searchValue,
      filteredUsersByConcept,
      filteredSelectedMembers,
      selectedMembers,
      currentStep,
      pagination,
      usersByConcept,
      discountsByRate,
      activeEntity,
      entityToDelete,
      unfoldedConcept,

      setUnfoldedConcept,
      setCurrentStep,
      setSelectedMembers,
      resetStepsFields,
      setRates,
      setActiveEntity,
      setIsConceptDialogOpen,
      setEntityToDelete,
      setIsDiscountDialogOpen,
      setFieldsToBeAffectedInReset,

      isLoadingUsersByConcept,
      isEditingConcept,
      isEditingDiscount,
      isLoadingRates,
      isLoadingConcepts,
      isCreatingConcept,
      isDeletingConcept,
      isLoadingSelectedMembers,
      isRemovingConceptFromUser,
      isAttachingConceptsToMember,
      isConceptDialogOpen,
      isDiscountDialogOpen,
      isDeletingDiscountFromRate,
      isCreatingDiscount,
      isLoadingDiscountsByRate,
    }),
    [
      activeEntity,
      concepts,
      currentStep,
      discountsByRate,
      entityToDelete,
      fetchPaymentManagementConcepts,
      fetchPaymentManagementDiscountSelectedMembers,
      fetchPaymentManagementDiscountsByRate,
      fetchPaymentManagementRates,
      fetchPaymentManagementSelectedMembers,
      fetchPaymentManagementUsersByConcept,
      filteredUsersByConcept,
      filteredSelectedMembers,
      handleAttachConceptsToMember,
      handleClearSearch,
      handleClickDiscounts,
      handleCreateConcept,
      handleCreateDiscountToRate,
      handleDeleteConcept,
      handleDeleteDiscountFromRate,
      handleEditConcept,
      handleEditDiscountToRate,
      handleFilterUsersByConcept,
      handleFilterSelectedMemberByName,
      handleLimitChange,
      handlePageChange,
      handleRemoveConceptFromUser,
      handleSearch,
      handleSelectAllMembers,
      handleSelectMember,
      isAttachingConceptsToMember,
      isConceptDialogOpen,
      isCreatingConcept,
      isCreatingDiscount,
      isDeletingConcept,
      isDeletingDiscountFromRate,
      isDiscountDialogOpen,
      isEditingConcept,
      isEditingDiscount,
      isLoadingConcepts,
      isLoadingDiscountsByRate,
      isLoadingRates,
      isLoadingSelectedMembers,
      isLoadingUsersByConcept,
      isRemovingConceptFromUser,
      pagination,
      rates,
      resetStepsFields,
      searchValue,
      selectedMembers,
      unfoldedConcept,
      usersByConcept,
    ],
  );
};

export default usePaymentManagementConcepts;
