import JWT from 'jwt-decode';

import { Api } from 'src/shared/services/api';
import {
  getSchema,
  getInstitutionPeriods,
  getSons,
  getUserInfo,
  getUserRole,
  login,
  refreshToken as refreshTokenUrl,
  setDefaultInstitution as setDefaultInstitutionUrl,
} from 'src/shared/services/url/UrlUser';
import { HIGH_ROLE, LOW_ROLE, ROLE_LIST } from 'src/config/general';
import {
  getSelectedInstitution,
  getSelectedPeriod,
  setLegacyToken,
  setRefreshToken,
  setInstitutionColor,
} from 'src/shared/utils/manageBlendedStorage';
import {
  getNotification as getNotificationUrl,
  hasNotifications as hasNotificationsUrl,
  markNotificationViewed as getReadNotificationUrl,
} from 'src/shared/services/url/UrlCommunication';
import { getCountryLocale } from 'src/shared/utils/locales/manageLocalization';
import { i18n } from '@lingui/core';
import dayjs from 'dayjs';
import ga4 from 'react-ga4';
import { uniqueByKey } from 'src/shared/utils/uniqueByKey';

const effects = (dispatch) => ({
  async loginFromSIS(data) {
    await dispatch.user.logout();
    await dispatch.user.setIsFirstLogin(data.isFirstLogin);
    await dispatch.user.setLoginUser(data);
    await dispatch.user.refreshUserData();
  },

  async reLogin({ username, password, countryId }) {
    const { data } = await Api.post({
      url: login(),
      data: {
        username,
        password,
        country_id: countryId,
      },
    });

    setLegacyToken(data.token);
    setRefreshToken(data.refresh_token);
    await this.setLoginUser(data);
    await this.updateTokenExpired(false);
  },

  async resfreshUserToken(refreshToken) {
    try {
      const { data } = await Api.post({
        url: refreshTokenUrl(),
        data: {
          refresh_token: refreshToken,
        },
      });

      setLegacyToken(data.token);
      setRefreshToken(data.refresh_token);
      await this.setLoginUser(data);
      await this.updateTokenExpired(false);
    } catch {
      await this.updateTokenExpired(true);
    }
  },

  // ESTA FUNCION ES LA DE LOGIN DEL TEMPLATE NO SE ESTA USANDO AHORA
  async loginUser(data) {
    try {
      dispatch.user.logout();
      localStorage.setItem('bld-jwt', data.token);
      await dispatch.user.setLoginUser(data);
      await dispatch.user.refreshUserData();
      await dispatch.configuration.initGeneralConfiguration();
    } catch (error) {
      dispatch.user.logout();
      throw error;
    }

    return true;
  },

  async refreshToken(token) {
    await Api.post({
      url: refreshTokenUrl(),
      data: {
        refresh_token: token,
      },
    });
  },

  async setLoginUser({ token, refresh_token }) {
    await dispatch.user.setApiKey(token);
    await dispatch.user.setRefreshToken(refresh_token);
    await dispatch.user.setIsLogin(true);
  },

  async setUserLocale(locale) {
    const localeConfig = await getCountryLocale(locale);

    i18n.load(localeConfig.name, localeConfig.texts);
    i18n.loadLocaleData({
      [localeConfig.name]: {
        plurals: localeConfig.plurals,
      },
    });

    i18n.activate(localeConfig.name);
    dayjs.locale(localeConfig.time);

    await dispatch.user.setLocale({ isLoaded: true, locale });
  },

  async refreshUserData(periodId, { user }) {
    try {
      const { apiKey, data, isFirstLogin } = user;
      const { id } = data ?? {};
      const { uid } = JWT(apiKey);

      const userId = id ?? uid;
      await dispatch.user.getAllInstitutions(periodId);

      const { locale } = await dispatch.user.getUserInfo(userId);
      await dispatch.user.getUserSons();
      await dispatch.user.getUserRole();
      await dispatch.user.setUserIs();
      await dispatch.user.setUserLocale(locale);
      if (!isFirstLogin) await dispatch.user.initChangelog();
    } catch (error) {
      throw error;
    }
  },

  async getUserInfo(uid) {
    const { data } = await Api.get({ url: getUserInfo(uid) });
    await dispatch.user.setData(data);

    return data;
  },

  async getUserRole(...[, { user }]) {
    const { data } = await Api.get({ url: getUserRole() });
    const selectedRoles = data.find(
      (institution) =>
        institution.institution_period_id === user.selectedInstitutionPeriodId,
    );
    await dispatch.user.setRole(
      uniqueByKey(
        [...selectedRoles?.role_divisions, ...selectedRoles?.role_subjects],
        'role_id',
      ),
    );
    await dispatch.user.setRelations(data);
  },

  async getAllInstitutions(periodId) {
    if (periodId) await dispatch.user.setSelectedInstitutionPeriodId(periodId);
    const { data: institutionPeriods } = await Api.get({
      url: getInstitutionPeriods(),
    });
    const selectedPeriod = getSelectedPeriod(institutionPeriods[0]);
    const { data: schema } = await Api.get({
      url: getSchema(periodId ?? selectedPeriod.id),
    });
    const institution = Array.isArray(schema)
      ? getSelectedInstitution(schema)
      : schema;

    await dispatch.user.setInstitutionPeriods(institutionPeriods);
    await dispatch.user.setInstitutions(schema);
    await dispatch.user.selectInstitution(institution);
  },

  async selectInstitution(institution) {
    // TODO: REVISAR DONDE SE USA ESTO PORQUE TIENE PINTA DE ESTAR MAL,
    //  PERO POR AHORA LO DEJO MENOS FEO AL MENOS
    const selectedInstitution = {
      ...institution,
      name: institution.name ? institution.name : institution.custom_name,
    };

    const selectedPeriod = getSelectedPeriod(selectedInstitution);
    const activePeriod = selectedInstitution.periods?.find(
      (period) => period.active === true,
    );
    const periodId = selectedPeriod.id;

    setInstitutionColor(selectedInstitution.color);
    await dispatch.user.setSelectedInstitution(selectedInstitution);
    await dispatch.user.setSelectedInstitutionPeriodId(periodId);
    await dispatch.user.setActiveInstitutionPeriodId(activePeriod?.id);
  },

  async setDefaultInstitutionPeriod(periodId) {
    await Api.patch({
      url: setDefaultInstitutionUrl(),
      data: {
        user_selected: true,
        institution_period_id: periodId,
      },
    });
    dispatch.user.setSelectedInstitutionPeriodId(periodId);
  },

  async getUserSons() {
    const { data } = await Api.get({ url: getSons() });
    const sons = data.map(({ id, children }) => {
      const item = {
        ...children,
        relationId: id,
      };

      delete item.external_ids;

      return item;
    });
    await dispatch.user.setSonsData(sons);
  },

  async updateIsMultiRol(value) {
    await this.setIsMultiRol(value);
  },

  async resetIsMultiRol(...[, { user }]) {
    const { selectedInstitutionPeriodId, relations } = user;
    const activeRoles = relations.find(
      (institution) =>
        institution.institution_period_id === selectedInstitutionPeriodId,
    );
    const subjectsRoles = activeRoles?.role_subjects;
    const isParent = subjectsRoles?.some(
      (role) => role.role_name === ROLE_LIST.PARENT,
    );
    const isHighRol = subjectsRoles?.some((role) =>
      HIGH_ROLE.includes(role.role_name),
    );
    const isMultiRol = isParent && isHighRol;
    await this.updateIsMultiRol(isMultiRol);
    if (!isMultiRol) {
      if (isHighRol) {
        await this.setUserIs(ROLE_LIST.TEACHER);
      } else {
        const userDefaultRole = isParent ? ROLE_LIST.PARENT : ROLE_LIST.STUDENT;
        await this.setUserIs(userDefaultRole);
      }
    }
  },

  // TODO: UNIFICAR ESTA FUNCION Y 'getUserRole' QUE HACEN LO MISMO BASICAMENTE
  async updateUserRoles(...[, { user }]) {
    const { selectedInstitutionPeriodId, relations } = user;
    const activeRoles = relations.find(
      (institution) =>
        institution.institution_period_id === selectedInstitutionPeriodId,
    );
    await dispatch.user.setRole(
      uniqueByKey(
        [...activeRoles?.role_divisions, ...activeRoles?.role_subjects],
        'role_id',
      ),
    );
    await this.resetIsMultiRol();
  },

  async setUserIs(selectedRole, { user }) {
    const userRoles = user?.role;
    const hasHighRole = userRoles?.some((role) =>
      HIGH_ROLE.includes(role.role_name),
    );
    const hasLowRole = userRoles?.some((role) =>
      LOW_ROLE.includes(role.role_name),
    );
    const activeRole =
      selectedRole || (hasHighRole ? ROLE_LIST.TEACHER : ROLE_LIST.STUDENT);
    const isHighRole = HIGH_ROLE.includes(activeRole);
    const isLowRole = LOW_ROLE.includes(activeRole);
    const isMultiRole = hasHighRole && hasLowRole;

    const userHasRole = (multiRole, searchedRole) =>
      userRoles?.some((role) => role.role_name === searchedRole);

    const userIs = {
      admin: userHasRole(isMultiRole, ROLE_LIST.ADMIN),
      financialAdmin: userHasRole(isMultiRole, ROLE_LIST.FINANCIAL_ADMIN),
      [ROLE_LIST.FINANCIAL_ADMIN]: userHasRole(
        isMultiRole,
        ROLE_LIST.FINANCIAL_ADMIN,
      ),
      financialExecutive: userHasRole(
        isMultiRole,
        ROLE_LIST.FINANCIAL_EXECUTIVE,
      ),
      [ROLE_LIST.FINANCIAL_EXECUTIVE]: userHasRole(
        isMultiRole,
        ROLE_LIST.FINANCIAL_EXECUTIVE,
      ),
      executive: userHasRole(isMultiRole, ROLE_LIST.EXECUTIVE),
      preceptor: userHasRole(isMultiRole, ROLE_LIST.PRECEPTOR),
      teacher: userHasRole(isMultiRole, ROLE_LIST.TEACHER),
      parent: userHasRole(isMultiRole, ROLE_LIST.PARENT),
      student: userHasRole(isMultiRole, ROLE_LIST.STUDENT),
      multiRole: isMultiRole,
      highRole: isHighRole,
      lowRole: isLowRole,
      unlinked: !userRoles?.length,
    };
    await this.updateUserIs({
      userIs,
      selectedRole: activeRole,
    });
  },

  // Notifications effects
  async getNotifications(params) {
    const { data: response, headers } = await Api.get({
      url: getNotificationUrl(),
      getRaw: true,
      data: params,
    });

    await this.setNotifications({
      data: response.data,
      pagination: {
        total: Number(headers['x-pagination-total-count']),
      },
    });
  },

  async hasNotifications(params, { user }) {
    if (user.apiKey) {
      const response = await Api.get({
        url: hasNotificationsUrl(),
        data: params,
      });

      await this.setHasNotifications(response);
    }
  },

  async getLazyNotifications(params) {
    const { data: response, headers } = await Api.get({
      url: getNotificationUrl(),
      getRaw: true,
      data: params,
    });

    await this.setLazyNotifications({
      data: response.data,
      pagination: {
        total: Number(headers['x-pagination-total-count']),
      },
    });
  },

  async markNotificationAsRead(notificationId) {
    await Api.post({
      url: getReadNotificationUrl(notificationId),
    });

    await this.setNotificationAsRead(notificationId);
  },

  async adaptToWebView(isWebView) {
    await this.setIsWebView(isWebView);
  },

  async setUserPropertiesAnalytics(_, { user }) {
    const { data, selectedInstitution, selectedInstitutionPeriodId, role } =
      user;
    const userRoles = role
      .reduce((accRoles, { role_name }) => {
        if (!accRoles.includes(role_name)) {
          accRoles.push(role_name);
        }
        return accRoles;
      }, [])
      .join(', ');

    const userProperties = {
      institution_id: selectedInstitution.id.toString(),
      institution_name: selectedInstitution.name,
      institution_period_id: selectedInstitutionPeriodId.toString(),
      user_role: userRoles,
      country: data.country?.code,
    };

    ga4.gtag('set', 'user_properties', {
      ...userProperties,
    });
  },
});

export default effects;
