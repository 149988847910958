import getUserFullname from './getUserFullname';

const sortAlphabetically = (a, b) => {
  const aName = getUserFullname(a);
  const bName = getUserFullname(b);

  return aName.localeCompare(bName);
};

const reduce = (arr) => (by) => {
  return arr
    .reduce((acc, curr) => {
      if (curr.rsvp === by) return [...acc, curr];

      return acc;
    }, [])
    .sort(sortAlphabetically);
};

const sortInvitedUsers = (users) => {
  const reduceRsvpBy = reduce(users);

  const yes = reduceRsvpBy(1);
  const no = reduceRsvpBy(0);
  const maybe = reduceRsvpBy(2);
  const rest = reduceRsvpBy(null);

  return [...yes, ...no, ...maybe, ...rest];
};

export default sortInvitedUsers;
