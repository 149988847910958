const hardCodeInitValuesCol = {
  productCode: '1',
  productDescription: 'Cuota escolar - Aviso de pago - Saldo deudor',
  productUnit: {
    id: 1,
    name: 'Uni',
  },
  productUnitAmount: 1,
  neto: '-',
  iva: '-',
  switchReceiver: true,

  prefix: '',
  last_tax_bill_number: '',
  resolutionNumber: '',
  minNumber: '',
  maxNumber: '',
  startDate: '',
  endDate: '',

  document: '',
  verifyDigit: '',
  name: '',
  taxRegime: '',

  nameFamily: '',
  documentTypeFamily: '',
  documentFamily: '',
};

export { hardCodeInitValuesCol };
