import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';

import { MAX_FILE_SIZE_DEFAULT } from 'src/config/general';
import useUploads from 'src/shared/hooks/useUploads';

import RejectedFiles from './RejectedFiles';
import UploadButton from './UploadButton';
import UploadMenu from './UploadMenu';
import useStyles from './styles';
import ContainerFiles from './ContainerFiles';

const FilesDropzone = memo(
  ({
    className,
    disabled,
    maxSize = MAX_FILE_SIZE_DEFAULT,
    buttonId = nanoid(),
    multiple,
    hideButton = false,
    subFolder,
    setProgress = () => {},
    onRemoveDisabled,
    acceptedFiles,
    buttonLabel,
    buttonProps,
    downloadable,
    isDroppable = false,
    ...rest
  }) => {
    const classes = useStyles();
    const [anchorAttachmentsMenu, setAnchorAttachmentsMenu] = useState(null);

    const {
      handleRemoveFile,
      handleDrop,
      progressUpdate,
      postFiles,
      rejectedFileList,
      setPostFiles,
    } = useUploads(buttonId, subFolder);
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: handleDrop,
      maxSize,
      multiple,
      ...(acceptedFiles && { accept: acceptedFiles }),
    });

    const handleAttachMenuOpen = (event) => {
      setAnchorAttachmentsMenu(event.currentTarget);
    };

    const handleAttachMenuClose = () => {
      setAnchorAttachmentsMenu(null);
    };

    useEffect(() => {
      if (progressUpdate.undefined) {
        delete progressUpdate.undefined;
      }
      const progressUpdateObject = Object.values(progressUpdate);
      setProgress(progressUpdateObject);
    }, [progressUpdate, setProgress]);

    return (
      <div className={clsx(classes.root, className)} {...rest}>
        {!hideButton && (
          <UploadButton
            disabled={disabled}
            onClose={handleAttachMenuClose}
            onOpen={handleAttachMenuOpen}
            getRootProps={getRootProps}
            buttonLabel={buttonLabel}
            buttonProps={buttonProps}
          />
        )}

        <UploadMenu
          rootProps={getRootProps}
          inputProps={getInputProps}
          buttonId={buttonId}
          anchor={anchorAttachmentsMenu}
          onClose={handleAttachMenuClose}
        />

        <ContainerFiles
          postFiles={postFiles}
          setPostFiles={setPostFiles}
          onRemoveDisabled={onRemoveDisabled}
          onRemove={handleRemoveFile}
          progressUpdate={progressUpdate}
          downloadable={downloadable}
          isDroppable={isDroppable}
        />

        {rejectedFileList.length > 0 && (
          <RejectedFiles files={rejectedFileList} />
        )}
      </div>
    );
  },
);

FilesDropzone.displayName = 'FilesDropzone';

FilesDropzone.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dropzone: PropTypes.bool,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  uploadedFiles: PropTypes.array,
  buttonId: PropTypes.string,
  hideButton: PropTypes.bool,
  subFolder: PropTypes.string,
  setProgress: PropTypes.func,
  onRemoveDisabled: PropTypes.bool,
  acceptedFiles: PropTypes.array,
  buttonLabel: PropTypes.string,
  buttonProps: PropTypes.any,
  downloadable: PropTypes.bool,
};

export default FilesDropzone;
