import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import useStyles from './styles';

const SplashScreen = memo(({ logo }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {logo && (
        <Box display="flex" justifyContent="center" mb={6}>
          {logo}
        </Box>
      )}
      <CircularProgress />
    </div>
  );
});

SplashScreen.displayName = 'SplashScreen';

SplashScreen.propTypes = {
  logo: PropTypes.node,
};

export default SplashScreen;
