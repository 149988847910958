// initial state
const state = {
  activeCountries: [],
  attendanceTypes: [],
  eventTypes: [],
  notificationTypes: [],
  roleTypes: [],
  activeModules: [],
};

export default state;
