import createRouter from './createRouter';

const assistantRouter = createRouter('assistant', {
  root: `/mi-asistente`,
  documents: `/mi-asistente/documentos`,
  faq: `/mi-asistente/preguntas-frecuentes`,
  thread: `/mi-asistente/conversaciones`,
  threadById: (threadId) => `/mi-asistente/conversaciones/${threadId}`,
});

export default assistantRouter;
