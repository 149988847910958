import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 360,
  },
  institutionInfo: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
  },

  institutionIcon: {
    margin: `0 auto ${theme.spacing(2)} auto`,
    width: 48,
    height: 48,
  },

  helpBlock: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
