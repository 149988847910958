import { atom } from 'jotai';

const selectedAllBillingInvoicesAtom = atom({
  isOpen: false,
  allSelected: false,
  count: 0,
});
selectedAllBillingInvoicesAtom.debugLabel = 'selectedAllBillingInvoicesAtom';

export default selectedAllBillingInvoicesAtom;
