import { t } from '@lingui/macro';

const STATUS_MAP = {
  BLANK: {
    id: 0,
    name: 'blank',
  },
  DRAFT: {
    id: 1,
    name: 'draft',
  },
  PENDING: {
    id: 2,
    name: 'pending',
    tooltip: t`Calificaciones enviadas`,
  },
  APPROVED: {
    id: 3,
    name: 'approved',
    tooltip: t`Calificaciones aprobadas`,
  },
  EDITED: {
    id: 4,
    name: 'unsent',
    tooltip: t`Calificaciones editadas`,
  },
};

const matrixToArray = (str) => {
  return str.match(/(-?[0-9.]+)/g);
};

export { STATUS_MAP, matrixToArray };
