const PROFILE_DIALOGS = {
  DELETE_USER_CUSTOM_ROLE_DIALOG: 'DeleteUserAliasDialog',
  USER_CUSTOM_ROLE_DIALOG: 'UserCustomRoleDialog',
  DELETE_USER_DIALOG: 'DeleteUserDialog',
  RESET_PASSWORD_DIALOG: 'RestPasswordDialog',
  SHOW_IN_MESSAGES_DIALOG: 'ShowInMessagesDialog',
  CREATE_NEW_USER_PASSWORD: 'CreateNewUserPassword',
};

export default PROFILE_DIALOGS;
