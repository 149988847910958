import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
