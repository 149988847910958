import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  customDigitalClock: {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'lightgray',
      borderRadius: '25px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'gray',
    },
  },
}));

export default useStyles;
