import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import reportTotalByPaymentSerializer from 'src/features/payment-management/helpers/totalPaidSerializer';
import { useGetPaginatedList } from 'src/shared/services/api/query';
import { totalPaidByPaymentMethodUrl } from 'src/shared/services/url/UrlPaymentManagement';
import { toDateFormat } from 'src/shared/utils/dates';

const useTotalPaidByPaymentMethod = ({
  dateRange,
  status,
  enabled = true,
  hasOnlinePayment,
}) => {
  const [dateFrom, dateTo] = dateRange;
  const params = {
    start: toDateFormat(dateFrom, 'YYYY-MM-DD'),
    end: toDateFormat(dateTo, 'YYYY-MM-DD'),
    status,
    ...(hasOnlinePayment && { hasOnlinePayment: true }),
  };

  return useGetPaginatedList(
    [QUERY_KEY.TOTAL_PAID_BY_PAYMENT_METHOD, params],
    totalPaidByPaymentMethodUrl(),
    { ...params },
    true,
    { enabled },
    reportTotalByPaymentSerializer,
  );
};

export default useTotalPaidByPaymentMethod;
