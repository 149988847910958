import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dateDay: {
    color: theme.palette.grey['500'],
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 500,
  },
  dateFormatted: {
    color: theme.palette.common.black,
    textAlign: 'center',
    fontSize: 12,
  },
}));

export default useStyles;
