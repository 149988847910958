import { useQuery } from '@tanstack/react-query';
import { Api as api } from 'src/shared/services/api';
import QUERY_KEY from 'src/shared/constants/queryKey';
import { userModules } from 'src/shared/services/url/UrlUser';

const useModules = ({ ...options } = {}) => {
  const queryFn = () =>
    api.get({
      url: userModules(),
    });

  const query = useQuery({
    queryKey: [QUERY_KEY.USER_MODULES],
    queryFn,
    ...options,
  });

  return {
    ...query,
    data: query.data?.data ?? [],
  };
};

export default useModules;
