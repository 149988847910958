import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import useStyles from './styles';

const WeekDayColumn = ({ weekItem }) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography className={classes.dateDay}>
        {dayjs(weekItem).format('ddd').replace('.', '')}
      </Typography>
      <Typography className={classes.dateFormatted}>
        {dayjs(weekItem).format('DD/MM')}
      </Typography>
    </Box>
  );
};

WeekDayColumn.propTypes = {
  weekItem: PropTypes.object,
};

export default WeekDayColumn;
