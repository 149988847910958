import { Calendar, Layout, User } from 'react-feather';
import React from 'react';
import { Trans } from '@lingui/macro';

const searchFilters = [
  {
    id: 1,
    name: <Trans>Personas</Trans>,
    slug: 'users',
    icon: <User size={18} />,
    className: 'searchBarInputPeople',
  },
  {
    id: 2,
    name: <Trans>Publicaciones</Trans>,
    slug: 'posts',
    icon: <Layout size={18} />,
    className: 'searchBarInputPosts',
  },
  {
    id: 3,
    name: <Trans>Eventos</Trans>,
    slug: 'events',
    icon: <Calendar size={18} />,
    className: 'searchBarInputEvents',
  },
];

export default searchFilters;
