import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Grow, IconButton } from '@mui/material';
import { ArrowLeft, ArrowRight } from 'react-feather';

import { useBreakpoints } from 'src/shared/hooks';

import ChangelogDots from '../ChangelogDots';

const ChangelogNavigation = memo(
  ({ onNextStep, onPrevStep, currentStep, stepCount }) => {
    const isFirstStep = currentStep === 0;
    const isLastStep = currentStep === stepCount - 1;
    const { isMobile } = useBreakpoints();

    return (
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          zIndex: 10,
        }}
      >
        <Grow in={!isFirstStep}>
          <IconButton
            disabled={isFirstStep}
            onClick={onPrevStep}
            sx={{
              position: 'absolute',
              left: (theme) => theme.spacing(2),
              top: isMobile ? '90%' : 'calc(50% - 16px)',
              backgroundColor: 'grey.100',

              '&:hover': {
                backgroundColor: 'grey.200',
              },
            }}
            size={isMobile ? 'medium' : 'large'}
          >
            <ArrowLeft size={16} />
          </IconButton>
        </Grow>

        <ChangelogDots currentStep={currentStep} stepsCount={stepCount} />

        <Grow in={!isLastStep}>
          <IconButton
            onClick={onNextStep}
            sx={{
              position: 'absolute',
              right: (theme) => theme.spacing(2),
              top: isMobile ? '90%' : 'calc(50% - 16px)',
              backgroundColor: 'grey.100',

              '&:hover': {
                backgroundColor: 'grey.200',
              },
            }}
            size={isMobile ? 'medium' : 'large'}
          >
            <ArrowRight size={16} />
          </IconButton>
        </Grow>
      </Box>
    );
  },
);

ChangelogNavigation.displayName = 'ChangelogNavigation';

ChangelogNavigation.propTypes = {
  onNextStep: PropTypes.func,
  onPrevStep: PropTypes.func,
  onFinish: PropTypes.func,
  currentStep: PropTypes.number,
  stepCount: PropTypes.number,
};

export default ChangelogNavigation;
