import JWT from 'jwt-decode';
import { getLegacyToken } from 'src/shared/utils/manageBlendedStorage';

const defaultLocaleConfig = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

const currencies = {
  ar: { code: 'ARS', locale: 'es-AR' }, // default'ARS',
  mx: { code: 'MXN', locale: 'es-MX' },
};

export const toLocaleCurrency = (amount, config = {}) => {
  const token = getLegacyToken();
  const { country_code } = JWT(token);
  const { abs, ...restOfConfig } = config;
  const currency = currencies[country_code] ?? currencies['ar'];

  const localize = (amountToLocalize) =>
    Number(amountToLocalize).toLocaleString(currency.locale, {
      style: 'currency',
      currency: currency.code,
      ...defaultLocaleConfig,
      ...restOfConfig,
    });

  if (abs) return localize(Math.abs(Number(amount ?? 0)));

  return localize(amount ?? 0);
};

const DEFAULT_DISCOUNT_CONFIG = { round: false, roundLength: 2 };

export const toDiscountedAmount = (
  amount,
  discount,
  baseConfig = DEFAULT_DISCOUNT_CONFIG,
  isFixedNumber,
) => {
  const config = { ...DEFAULT_DISCOUNT_CONFIG, ...baseConfig };
  if (isFixedNumber) return -Number(discount);

  if (config.round) {
    return -Math.abs((amount * discount) / 100).toFixed(config.roundLength);
  }

  return -Number((amount * discount) / 100);
};

export const decimalSeparator = function () {
  return toLocaleCurrency(1.1, { style: 'decimal' }).substring(1, 2);
};

export const thousandSeparator = function () {
  return toLocaleCurrency(1000, { style: 'decimal' }).substring(1, 2);
};
