import { atom } from 'jotai';
import stepFamily from './stepFamily';

const incStepAtom = atom(null, (get, set, payload) => {
  const stepAtom = stepFamily(payload);

  const step = get(stepAtom);
  const { current, limit } = step;

  set(stepAtom, {
    ...step,
    current: current < limit ? current + 1 : current,
  });
});

export default incStepAtom;
