import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  scaleSelector: {
    display: 'inline-flex',
    width: 150,
  },
  scaleSelectorInputRoot: {
    '& .MuiOutlinedInput-root': {
      padding: theme.spacing(),
      minHeight: theme.spacing(4),
      backgroundColor: theme.palette.common.white,
      '& .MuiAutocomplete-input': {
        minWidth: 'unset',
        display: 'none',
      },
    },
  },
  hiddenMenu: {
    visibility: 'hidden',
  },
  root: {
    padding: 0,
    paddingLeft: theme.spacing(),
  },
  scaleSelectorInputRootWithChips: {
    '& .MuiOutlinedInput-root': {
      padding: theme.spacing(),
      minHeight: theme.spacing(4),
      backgroundColor: theme.palette.common.white,
      '& .MuiAutocomplete-input': {
        minWidth: 'unset',
        padding: 0,
      },
    },
  },
}));

export default useStyles;
