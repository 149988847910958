import { t } from '@lingui/macro';

import { isValidUrl } from 'src/shared/helpers';
import { getOpenGraphUrl } from 'src/shared/services/url/UrlCommunication';
import { Api } from 'src/shared/services/api';
import {
  getTinyLanguageByCountry,
  getTinyLanguagePackByCountry,
} from 'src/shared/utils/manageLanguages';
import theme from 'src/theme';
import linkifyHtml from 'linkifyjs/lib/linkify-html';

import EditorIcons from '../assets/icons';
/* eslint import/no-webpack-loader-syntax: off */
import EditorBaseStyles from '!!raw-loader!../assets/css/baseStyles.css';
import { TINYMCE_KEY } from 'src/config/general';

const handleLinks = () => {
  const urlInput = document.getElementsByClassName('tox-textfield')[0];
  const formGroup = urlInput.closest('.tox-form__group');
  urlInput.setAttribute('placeholder', t`https://ejemplo.com`);
  const handleUrlInput = ({ target }) => {
    const { value } = target;
    if (!isValidUrl(value) && !formGroup.classList.contains('error')) {
      formGroup.classList.add('error');
    } else if (isValidUrl(value) && formGroup.classList.contains('error')) {
      formGroup.classList.remove('error');
    }
  };
  urlInput.addEventListener('keyup', handleUrlInput);
  urlInput.addEventListener('paste', handleUrlInput);
};

const DEFAULT_FORMAT_OPTIONS = {
  bold: true,
  italic: true,
  underline: true,
  bullist: true,
};

export const loadBaseConfig = (
  toolbarOptions,
  pluginOptions,
  placeholder,
  formatGroupItems,
) => ({
  license_key: TINYMCE_KEY,
  width: '100%',
  menubar: false,
  branding: false,
  statusbar: false,
  toolbar_location: 'bottom',
  toolbar_groups: {
    formatGroup: {
      icon: 'format',
      tooltip: t`Opciones de formato`,
      items: Object.keys(formatGroupItems ?? DEFAULT_FORMAT_OPTIONS).join(' '),
    },
  },
  toolbar: toolbarOptions.join(' '),
  plugins: pluginOptions,
  placeholder,
  inline_boundaries: false,
  content_style: EditorBaseStyles,
  elementpath: false,
  language: getTinyLanguageByCountry(),
  link_title: false,
  language_url: getTinyLanguagePackByCountry(),
  contextmenu: false,
  link_list(success) {
    success();
    setTimeout(() => {
      handleLinks();
    }, 100);
  },
});

export const loadEditorIcons = (editor) => {
  const {
    BoldIcon,
    ItalicIcon,
    UnderlineIcon,
    FormatIcon,
    ListIcon,
    AttachmentIcon,
    EmojiIcon,
    DesktopIcon,
    GoogleDriveIcon,
    OneDriveIcon,
    DropboxIcon,
    LinkIcon,
  } = EditorIcons();
  const icons = [
    { name: 'bold', icon: BoldIcon },
    { name: 'italic', icon: ItalicIcon },
    { name: 'underline', icon: UnderlineIcon },
    { name: 'unordered-list', icon: ListIcon },
    { name: 'format', icon: FormatIcon },
    { name: 'attachments', icon: AttachmentIcon },
    { name: 'emoji', icon: EmojiIcon },
    { name: 'desktop', icon: DesktopIcon },
    { name: 'google-drive', icon: GoogleDriveIcon },
    { name: 'one-drive', icon: OneDriveIcon },
    { name: 'dropbox', icon: DropboxIcon },
    { name: 'link', icon: LinkIcon },
  ];
  for (let i = 0; i < icons.length; i++) {
    editor.ui.registry.addIcon(icons[i].name, icons[i].icon);
  }
};

export const addUploadButton = (
  editor,
  uploadFromDesktop = () => {},
  uploadFromDropbox = () => {},
  uploadFromGoogleDrive = () => {},
  uploadFromOneDrive = () => {},
) => {
  editor.ui.registry.addMenuButton('uploadAttachment', {
    icon: 'attachments',
    tooltip: t`Adjuntar archivos`,
    fetch(callback) {
      const items = [
        {
          type: 'menuitem',
          text: t`Escritorio`,
          icon: 'desktop',
          onAction() {
            uploadFromDesktop();
          },
        },
        // COMMENTED FOR NOW - BRIAN
        // {
        //   type: 'menuitem',
        //   text: t`Dropbox`,
        //   icon: 'dropbox',
        //   onAction() {
        //     uploadFromDropbox();
        //   },
        // },
        {
          type: 'menuitem',
          text: t`Google Drive`,
          icon: 'google-drive',
          onAction() {
            uploadFromGoogleDrive();
          },
        },
        {
          type: 'menuitem',
          text: t`One Drive`,
          icon: 'one-drive',
          onAction() {
            uploadFromOneDrive();
          },
        },
      ];
      callback(items);
    },
  });
};

const calculate = {
  '+': (...args) => args.reduce((accum, val) => accum + val, 0),
  '-': (...args) =>
    args.reduce((accum, val) => (val === args[0] ? args[0] : accum - val), 0),
};

const initResizeEditor = (editorId, targetSelector) => {
  const FILE_ROW_HEIGHT = 69 + parseInt(theme.spacing(2), 10);
  const editorContainer = document.getElementById(
    `editor-${editorId}`,
  ).parentElement;
  const targetContainer = editorContainer.querySelector(targetSelector); // #editorMedia
  const toxContainer = editorContainer.querySelector('.tox-tinymce');
  const openGraphContainer = targetContainer.querySelector('#openGraphData');
  const rejectedFilesContainer =
    targetContainer.querySelector('#rejected-files');

  const editorIframe = document.getElementById(`editor-${editorId}_ifr`);
  const editorWrapper = editorIframe.parentElement.parentElement; // .tox-sidebar-wrap
  const editorToolbar = editorContainer.querySelector('.tox-editor-header');
  const toxEditArea = editorContainer.querySelector('.tox-edit-area');

  return {
    targetContainer,
    editorWrapper,
    editorToolbar,
    FILE_ROW_HEIGHT,
    toxContainer,
    toxEditArea,
    openGraphContainer,
    rejectedFilesContainer,
  };
};

const resetEditorSize = (editorId, targetSelector) => {
  const { targetContainer, editorWrapper, openGraphContainer } =
    initResizeEditor(editorId, targetSelector);

  targetContainer.style.opacity = 0;
  targetContainer.style.marginTop = '0px';
  editorWrapper.style.paddingBottom = '0px';

  if (openGraphContainer) {
    openGraphContainer.classList.remove('calculated');
  }

  return false;
};

const resizeEditor = (
  editorId,
  targetSelector,
  action = 'add',
  totalFiles,
  editor,
  hasRejected = false,
) => {
  const operator = action === 'add' ? '+' : '-';
  const {
    targetContainer,
    editorWrapper,
    FILE_ROW_HEIGHT,
    toxContainer,
    openGraphContainer,
    rejectedFilesContainer,
  } = initResizeEditor(editorId, targetSelector);

  if (targetContainer == null) return;
  if (totalFiles === 0 && !openGraphContainer && !hasRejected) {
    resetEditorSize(editorId, targetSelector);
    return;
  }

  const prevPaddingBottom = parseInt(
    editorWrapper.style.paddingBottom || 0,
    10,
  );
  const prevHeight = parseInt(editor.container?.style?.height ?? 0, 10);
  const wasGraphDataCalculated =
    openGraphContainer?.classList.contains('calculated') ?? true;
  const wasRejectedFilesCalculated =
    rejectedFilesContainer?.classList.contains('calculated') ?? true;
  const graphDataHeight =
    !wasGraphDataCalculated || action === 'removeOG'
      ? openGraphContainer.offsetHeight
      : 0;
  const rejectedFilesHeight = !wasRejectedFilesCalculated
    ? rejectedFilesContainer.offsetHeight
    : 0;

  let newPaddingBottom = calculate[operator](
    prevPaddingBottom,
    FILE_ROW_HEIGHT * (totalFiles ?? 0),
    graphDataHeight,
  );

  let newHeight = calculate[operator](
    prevHeight,
    FILE_ROW_HEIGHT * (totalFiles ?? 0),
    graphDataHeight,
  );

  if (hasRejected) {
    newHeight += rejectedFilesHeight;
  }
  if (action === 'removeOG') {
    newPaddingBottom = prevPaddingBottom - graphDataHeight;
  }

  editorWrapper.style.paddingBottom = `${newPaddingBottom}px`;
  targetContainer.style.marginTop = `-${newPaddingBottom}px`;
  targetContainer.style.opacity = 1;
  toxContainer.style.height = `${newHeight}px`;
  // eslint-disable-next-line no-param-reassign
  if (editor.container) {
    // eslint-disable-next-line no-param-reassign
    editor.container.style.height = `${newHeight}px`;
  }

  if (!wasGraphDataCalculated) {
    openGraphContainer.classList.add('calculated');
  }
  if (!wasRejectedFilesCalculated) {
    rejectedFilesContainer.classList.add('calculated');
  }
};

export const handleResizeEditor = (editor) => {
  editor.addCommand(
    'editorChange',
    (ui, { editorId, action, totalFiles, hasRejected }) => {
      resizeEditor(
        editorId,
        '#editorMedia',
        action,
        totalFiles,
        editor,
        hasRejected,
      );
    },
  );
};

const getOpenGraph = (url) => {
  Api.get({
    url: getOpenGraphUrl(),
    data: {
      url,
    },
  });
};
export const handlePasteContent = async (
  args,
  openGraph,
  setOpenGraph,
  buttonId,
) => {
  const regex = /href=["'](.*?)["']/;
  const match = regex.exec(args.content);
  const href = match ? match[1] : null;

  if (href && isValidUrl(href)) {
    try {
      if (!openGraph) {
        const { data: graphData } = await getOpenGraph(href);
        if (graphData) {
          setOpenGraph(graphData);
          window.tinymce.execCommand('editorChange', null, {
            editorId: buttonId,
            action: 'add',
          });
        }
      }
    } catch ({ message }) {
      // eslint-disable-next-line no-console
      console.log(message);
    }

    const linked = linkifyHtml(args.content, {
      className: 'tinymce-link',
    });

    args.target.setContent(linked);
  }
};
