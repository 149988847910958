import React, { useEffect } from 'react';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  SvgIcon,
} from '@mui/material';
import { Menu as MenuIcon } from 'react-feather';
import { useSetAtom } from 'jotai';

import { NotificationsButton } from 'src/features/notifications';
import useSuspendedInstitution from 'src/shared/hooks/data/useSuspendedInstiution';
import useIsMountedRef from 'src/shared/hooks/useIsMountedRef';
import router from 'src/shared/utils/router';
import isMobileNavOpenAtom from 'src/shared/atoms/navbar/isMobileNavOpenAtom';

import Account from './Account';
import Search from './Search';
import Messages from './Messages';
import useUserIsDebtor from 'src/shared/hooks/data/useUserIsDebtor';

const TopBar = (props) => {
  const isFirstFetch = useIsMountedRef();
  const setIsNavOpen = useSetAtom(isMobileNavOpenAtom);
  const { data: suspendedData } = useSuspendedInstitution();
  const isDebtor = useUserIsDebtor();
  const isSuspended = suspendedData?.blacklist;

  useEffect(() => {
    if (isFirstFetch.current && isSuspended) {
      window.location.href = router.service.suspendedService;
    }
  }, [isFirstFetch, isSuspended]);

  useEffect(() => {
    if (isDebtor) {
      window.location.href = router.service.blockedUser;
    }
  }, [isDebtor]);

  return (
    <AppBar id="fidu-header" {...props}>
      <Toolbar>
        <Hidden mdUp>
          <IconButton onClick={() => setIsNavOpen(true)} size="large">
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>

        <Box mr={2} flexGrow={1} />
        <Box mr={2}>
          <Search />
        </Box>
        <Messages />
        <NotificationsButton />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
