import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { putFamilyInformation } from 'src/shared/services/url/UrlUser';
import { usePut } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import SHARED_QUERY_KEY from 'src/shared/constants/queryKey';

const useUpdateFamilyInformation = (familyId) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return usePut(
    putFamilyInformation(familyId),
    {
      onSuccess: () => {
        enqueueSnackbar(t`Información actualizada con éxito.`, {
          variant: 'success',
        });
      },
      onError: ({ message: errorMessage }) => {
        enqueueSnackbar(errorMessage ?? t`Hubo un error al actualizar`, {
          variant: 'error',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries([QUERY_KEY.FAMILY_INFO]);
        queryClient.invalidateQueries(SHARED_QUERY_KEY.USE_STUDENTS_SEARCH, {
          refetchType: 'all',
        });
      },
    },
    false,
  );
};

export default useUpdateFamilyInformation;
