import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { Api as api } from 'src/shared/services/api';
import { currencyListUrl } from 'src/shared/services/url/UrlPaymentManagement';

const parseCurrencies = (list) =>
  list.map((el) => ({
    ...el,
    id: el.iso_code,
    name: el.description,
  }));

const useCurrencies = () => {
  const [currencies, setCurrencies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const fetchCurrencies = useCallback(async () => {
    setIsLoading(false);
    try {
      const { data } = await api.get({
        url: currencyListUrl(),
      });

      setCurrencies(parseCurrencies(data));
    } catch (error) {
      enqueueSnackbar(t`Ha ocurrido un error, intente mas tarde.`, {
        variant: 'error',
      });
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      await fetchCurrencies();
    })();
  }, [fetchCurrencies]);

  return useMemo(
    () => ({
      currencies,
      isLoading,
    }),
    [currencies, isLoading],
  );
};

export default useCurrencies;
