import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { t } from '@lingui/macro';
import { Grid, DialogActions, DialogContent } from '@mui/material';

import Form, { DateTimeField, SubmitButton } from 'src/components/Form';

import ScheduledElementFormSchema from './schema';
import dayjs from 'dayjs';

const formId = 'scheduled-element-form';

const ScheduledElementForm = ({
  initialValues,
  disabled,
  submitLabel,
  buttonId,
  minDate,
  maxDate,
  ...props
}) => {
  return (
    <Formik
      validationSchema={ScheduledElementFormSchema}
      initialValues={initialValues}
      {...props}
    >
      {({ values }) => {
        const minTimeStartDate = dayjs(values?.start_date).isSame(
          dayjs(),
          'day',
        )
          ? dayjs()
          : null;
        return (
          <>
            <DialogContent>
              <Form id={formId}>
                <Grid
                  container
                  spacing={2}
                  alignItems="flex-end"
                  paddingX={2}
                  mt={1}
                >
                  <Grid item md={12}>
                    <DateTimeField
                      name="start_date"
                      placeholder={t`DD/MM/YYYY`}
                      minDate={minDate}
                      maxDate={maxDate}
                      minTime={minTimeStartDate}
                      timeSteps={{ minutes: 15 }}
                      skipDisabled
                      disabled={disabled}
                      label={t`Fecha y hora`}
                      ampm
                    />
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>

            <DialogActions>
              <Grid container spacing={2} textAlign="end" paddingX={2}>
                <SubmitButton disabled={disabled} form={formId} id={buttonId}>
                  {submitLabel}
                </SubmitButton>
              </Grid>
            </DialogActions>
          </>
        );
      }}
    </Formik>
  );
};

ScheduledElementForm.propTypes = {
  disabled: PropTypes.bool,
  initialValues: PropTypes.object,
  submitLabel: PropTypes.string,
  buttonId: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  minTime: PropTypes.object,
};

export default ScheduledElementForm;
