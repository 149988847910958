import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { Api as api } from 'src/shared/services/api';
import { updateExternalLinks as updateExternalLinksUrl } from 'src/shared/services/url/UrlUser';

import { QUERY_KEY } from '../../constants';

const useDeleteExternalLink = ({ onSuccess, ...params } = {}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const mutationFn = (id) =>
    api.delete({
      url: updateExternalLinksUrl(id),
    });

  return useMutation({
    mutationFn,
    onSuccess: async () => {
      if (onSuccess) onSuccess();
      await queryClient.invalidateQueries([QUERY_KEY.EXTERNAL_LINKS]);
      enqueueSnackbar(t`Se ha eliminado el acceso directo con exito.`, {
        variant: 'success',
      });
    },
    onError: ({ message }) => {
      enqueueSnackbar(
        message ??
          t`Ocurrió un problema al intentar eliminar el acceso directo.`,
        {
          variant: 'error',
        },
      );
    },
    ...params,
  });
};

export default useDeleteExternalLink;
