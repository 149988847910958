import { useGetPaginatedList } from 'src/shared/services/api/query';
import { paymentManagementUsers } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const useRateUsersPaginated = (
  { enabled = true, id, ...params } = {},
  queryParams,
) => {
  return useGetPaginatedList(
    [QUERY_KEY.CONCEPT_SELECTED_MEMBERS_PAGINATED, id],
    paymentManagementUsers(id),
    params,
    true,
    {
      enabled,
      ...(!enabled && { cacheTime: 0 }),
      ...queryParams,
    },
    false,
    0,
    10,
  );
};

export default useRateUsersPaginated;
