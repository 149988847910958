import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router';

import { useCreate } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';
import { postDraftConversation } from 'src/shared/services/url/UrlCommunication';
import router from 'src/shared/utils/router';

const useCreateDraftConversation = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useCreate(
    postDraftConversation(),
    {
      onSuccess: () => {
        enqueueSnackbar(t`Mensaje guardado en borrador con éxito.`, {
          variant: 'success',
        });
        queryClient.refetchQueries({
          queryKey: [QUERY_KEY.DRAFT_CONVERSATIONS_LIST],
        });
        history.push(router.communication.draft.root);
      },
      onError: () => {
        enqueueSnackbar(t`Hubo un error al guardar su mensaje en borradores.`, {
          variant: 'error',
        });
      },
    },
    false,
  );
};

export default useCreateDraftConversation;
