import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  userName: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.common.links,
    marginLeft: theme.spacing(1),
  },
}));

export default useStyles;
