import { t } from '@lingui/macro';
import { getDefaultStore, useSetAtom } from 'jotai';

import {
  paymentOrderAtom,
  paymentStatusMessageAtom,
} from 'src/features/payment-management/atoms';
import { CreatePaymentOrdersStore } from 'src/shared/atoms/stepWizard/stepWizardStore';
import { stepWizardIncAtom } from 'src/shared/atoms/stepWizard/stepWizardAtom';
import useCreatePaymentOrder from './useCreatePaymentOrder';
import usePaymentConfig from '../usePaymentConfig';
import { useOpenDialog } from 'src/shared/hooks';
import { dialogs } from 'src/shared/constants';
import { isJSON } from 'src/shared/helpers';

const ERROR_MESSAGE = t`Ha ocurrido un error al intentar crear el pago.`;
const SUCCESS_MESSAGE = t`En breve será redireccionado al portal de pagos...`;

const useDLocalPayment = () => {
  const defaultStore = getDefaultStore();
  const openDialog = useOpenDialog(dialogs.CREATE_PAYMENT_ORDERS_DIALOG);
  const setPaymentStatus = useSetAtom(paymentStatusMessageAtom, {
    store: defaultStore,
  });
  const setPaymentOrder = useSetAtom(paymentOrderAtom, {
    store: defaultStore,
  });
  const { data: financialConfig } = usePaymentConfig();
  const { mutate: createPaymentOrder } = useCreatePaymentOrder();
  const nextStep = () => CreatePaymentOrdersStore.set(stepWizardIncAtom);
  const hasPaymentDirectEnabled =
    financialConfig?.dlocal_payment_direct_enabled;

  const onPaymentOrderSuccessDirect = ({ data }) => {
    setPaymentOrder(data);
    nextStep();
  };

  const onPaymentSuccessRedirect = ({ data }) => {
    const redirectUrl = data?.external_order?.redirect_url;
    setPaymentStatus({
      message: SUCCESS_MESSAGE,
      isOpen: true,
      isSuccess: true,
    });

    setTimeout(() => {
      window.location.href = redirectUrl;
    }, 3000);
  };

  const onPaymentError = ({ message: error }) => {
    const message = isJSON(error) ? JSON.parse(error)?.message : error;
    setPaymentStatus({
      message: message ?? ERROR_MESSAGE,
      isOpen: true,
      isSuccess: false,
    });
  };

  const handlePaymentOrder = (body) => {
    return createPaymentOrder(body, {
      onSuccess: hasPaymentDirectEnabled
        ? onPaymentOrderSuccessDirect
        : onPaymentSuccessRedirect,
      onError: onPaymentError,
      onSettled: () => {
        if (!hasPaymentDirectEnabled) openDialog(false);
      },
    });
  };

  return { handlePaymentOrder };
};

export default useDLocalPayment;
