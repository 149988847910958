import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import usePaymentManagementConcepts from 'src/features/payment-management/hooks/usePaymentManagementConcepts';

const defaultContextValues = {
  fetchPaymentManagementRates: () => {},
  fetchPaymentManagementConcepts: () => {},
  fetchPaymentManagementSelectedMembers: () => {},
  fetchPaymentManagementDiscountsByRate: () => {},
  fetchPaymentManagementUsersByConcept: () => {},
  fetchPaymentManagementDiscountSelectedMembers: () => {},

  handleClearSearch: () => {},
  handleSearch: () => {},
  handleLimitChange: () => {},
  handlePageChange: () => {},
  handleSelectMember: () => {},
  handleSelectAllMembers: () => {},
  handleRemoveAllSelected: () => {},
  handleFilterUsersByConcept: () => {},
  handleFilterSelectedMemberByName: () => {},
  handleCreateConcept: () => {},
  handleDeleteConcept: () => {},
  handleEditConcept: () => {},
  handleAttachConceptsToMember: () => {},
  handleRemoveConceptFromUser: () => {},
  handleCreateDiscountToRate: () => {},
  handleEditDiscountToRate: () => {},
  handleDeleteDiscountFromRate: () => {},
  handleClickDiscounts: () => {},

  activeEntity: null,
  rates: [],
  concepts: [],
  discountsByRate: [],
  searchValue: '',
  initialSelectedMembers: [],
  selectedMembers: [],
  currentStep: 0,
  filteredUsersByConcept: [],
  filteredSelectedMembers: [],
  entityToDelete: null,
  unfoldedConcept: null,
  pagination: {
    total: 0,
    page: 0,
    limit: 15,
  },

  setCurrentStep: () => {},
  setUnfoldedConcept: () => {},
  setEntityToDelete: () => {},
  setSelectedMembers: () => {},
  resetStepsFields: () => {},
  setActiveEntity: () => {},
  setIsConceptDialogOpen: () => {},
  setRates: () => {},
  setIsDiscountDialogOpen: () => {},
  setFieldsToBeAffectedInReset: () => {},

  isLoadingRates: false,
  isEditingConcept: false,
  isLoadingConcepts: false,
  isDeletingConcept: false,
  isLoadingSelectedMembers: false,
  isRemovingConceptFromUser: false,
  isConceptDialogOpen: false,
  isEditingDiscountToRate: false,
  isDeletingDiscountFromRate: false,
  isDiscountDialogOpen: false,
};

const PaymentManagementConcepts = createContext(defaultContextValues);

const PaymentManagementConceptsProvider = ({ children }) => {
  const concept = usePaymentManagementConcepts();

  return (
    <PaymentManagementConcepts.Provider value={concept}>
      {children}
    </PaymentManagementConcepts.Provider>
  );
};

const usePaymentManagementConceptsContext = () =>
  useContext(PaymentManagementConcepts);

PaymentManagementConceptsProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export {
  PaymentManagementConceptsProvider,
  usePaymentManagementConceptsContext,
};
export default PaymentManagementConcepts;
