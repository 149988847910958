import { subject } from 'src/features/subjects/model';
import { grading } from 'src/features/grading/models';
import { user } from './User';
import { vc } from '../../features/virtual-classroom/models';
import { configuration } from './Configuration';

export default {
  user,
  vc,
  configuration,
  subject,
  grading,
};
