import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { IconButton, Tooltip } from '@mui/material';
import { ExternalLink } from 'react-feather';

const ExternalFileButton = memo(({ link }) => {
  return (
    <Tooltip title={t`Abrir`}>
      <IconButton
        aria-label={t`Abrir`}
        component="a"
        href={link}
        target="_blank"
        size="large"
      >
        <ExternalLink size={16} />
      </IconButton>
    </Tooltip>
  );
});

ExternalFileButton.displayName = 'ExternalFileButton';

ExternalFileButton.propTypes = {
  link: PropTypes.string,
};

export default ExternalFileButton;
