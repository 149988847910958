import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Download as DownloadIcon } from 'react-feather';
import IconButton from '@mui/material/IconButton';
import { CircularProgress, Tooltip } from '@mui/material';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';

import { downloadFile, downloadFileWebView } from 'src/shared/utils/fileHelper';
import useStyles from '../../styles';

const DownloadFile = memo(({ link, fileName, className, toolbarButton }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const isWebView = useSelector((store) => store.user?.isWebView);

  const handleDownload = () => {
    if (isWebView) {
      downloadFileWebView(link, fileName);
    } else {
      downloadFile({ link, original_file_name: fileName, setLoading });
    }
  };

  return (
    <Tooltip title={<Trans>Descargar</Trans>}>
      <IconButton
        className={clsx({
          [classes.toolbarButtons]: toolbarButton,
          className,
        })}
        onClick={handleDownload}
        size="large"
      >
        {!loading && <DownloadIcon size={16} />}
        {loading && <CircularProgress className={classes.loading} size={16} />}
      </IconButton>
    </Tooltip>
  );
});

DownloadFile.displayName = 'DownloadFile';

DownloadFile.propTypes = {
  link: PropTypes.string,
  fileName: PropTypes.string,
  className: PropTypes.string,
  toolbarButton: PropTypes.bool,
};

export default DownloadFile;
