import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { Alert, Collapse, FormControl, Grid, InputLabel } from '@mui/material';
import { FilesDropzoneContext } from 'src/shared/context/FileDropzoneContext';
import useFirstRender from 'src/shared/hooks/useFirstRender';

import FilesDropzone from 'src/components/FilesDropzone';
import useStyles from './styles';

const AttachmentField = ({
  name,
  subFolder,
  gridProps = { xs: 12 },
  FilesDropzoneProps,
  label,
  buttonLabel,
  buttonProps,
  acceptedFiles,
}) => {
  const classes = useStyles();
  const [field, meta] = useField(name);
  const [attachments, setAttachments] = useState(field.value);
  const [isUploading, setIsUploading] = useState(false);
  const { setFieldValue } = useFormikContext();

  const isFirstRender = useFirstRender();

  useEffect(() => {
    if (!isUploading && !isFirstRender) {
      setFieldValue(name, attachments);
    }
  }, [attachments, isFirstRender, isUploading, setFieldValue, name]);

  return (
    <FilesDropzoneContext.Provider
      value={{
        postFiles: attachments,
        setPostFiles: setAttachments,
        isUploading,
        setIsUploading,
      }}
    >
      <Grid item {...gridProps}>
        <FormControl className={classes.formControl}>
          {label && (
            <InputLabel
              classes={{ formControl: classes.MuiInputLabelFormControl }}
            >
              {label}
            </InputLabel>
          )}

          <FilesDropzone
            className={classes.fileInput}
            subFolder={subFolder}
            acceptedFiles={acceptedFiles}
            buttonLabel={buttonLabel}
            buttonProps={buttonProps}
            {...FilesDropzoneProps}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Collapse in={Boolean(meta.error)}>
          <Alert severity="info">{meta.error}</Alert>
        </Collapse>
      </Grid>
    </FilesDropzoneContext.Provider>
  );
};

AttachmentField.propTypes = {
  name: PropTypes.string.isRequired,
  subFolder: PropTypes.string,
  gridProps: PropTypes.object,
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonProps: PropTypes.any,
  FilesDropzoneProps: PropTypes.object,
  acceptedFiles: PropTypes.array,
};

export default AttachmentField;
