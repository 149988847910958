import createRouter from './createRouter';

const billingStatementRouter = createRouter('billingStatement', {
  root: `/estado-de-cuenta`,
  account: `/estado-de-cuenta/cuenta`,
  invoices: `/estado-de-cuenta/facturas`,
  taxInformation: `/estado-de-cuenta/datos-de-facturacion`,
});

export default billingStatementRouter;
