import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Menu, MenuItem } from '@mui/material';
import { Monitor } from 'react-feather';
import { Trans } from '@lingui/macro';

import useStyles from './styles';
import GoogleDriveUpload from './GoogleDriveUpload';
import OneDriveUpload from './OneDriveUpload';

const UploadMenu = memo(
  ({ buttonId, anchor, onClose, rootProps, inputProps }) => {
    const isWebView = useSelector((state) => state?.user?.isWebView);
    const anchorOrigin = { vertical: 'bottom', horizontal: 'left' };
    const classes = useStyles();

    return (
      <>
        <Menu
          id="uploadsMenu"
          anchorEl={anchor}
          keepMounted
          open={Boolean(anchor)}
          onClose={onClose}
          anchorOrigin={anchorOrigin}
        >
          <MenuItem
            id={`upload-desktop-${buttonId}`}
            {...rootProps({
              onClick: onClose,
            })}
          >
            <Monitor size={16} className={classes.attachMenuIcon} />
            <Trans>Escritorio</Trans>
          </MenuItem>
          {!isWebView && (
            <GoogleDriveUpload onClose={onClose} buttonId={buttonId} />
          )}
          {!isWebView && (
            <OneDriveUpload onClose={onClose} buttonId={buttonId} />
          )}
        </Menu>
        <input {...inputProps()} />
      </>
    );
  },
);

UploadMenu.displayName = 'UploadMenu';

UploadMenu.propTypes = {
  buttonId: PropTypes.string,
  anchor: PropTypes.any,
  onClose: PropTypes.func,
  rootProps: PropTypes.any,
  inputProps: PropTypes.any,
};

export default UploadMenu;
