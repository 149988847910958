import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Chip, Autocomplete as MuiAutocomplete } from '@mui/material';
import { X as CloseIcon } from 'react-feather';
import { Trans, t } from '@lingui/macro';
import clsx from 'clsx';
import SearchField from '../SearchField';
import useStyles from './styles';

const defaultGetOptionSelected = (option, value) =>
  option.name === (value?.name ?? '');
const defaultGetOptionLabel = (option) => option?.name ?? '';

const AutocompleteField = forwardRef(
  (
    {
      options,
      loading = false,
      label,
      placeholder,
      getOptionLabel,
      getOptionSelected,
      gridProps = { xs: 12 },
      onClear,
      helperText,
      error,
      endAdornment,
      startAdornment,
      readOnly,
      className,
      disablePortal,
      name,
      ...rest
    },
    ref,
  ) => {
    const classes = useStyles();

    return (
      <Grid item {...gridProps}>
        <MuiAutocomplete
          className={className ? clsx(classes.root, className) : classes.root}
          classes={{
            groupLabel: classes.groupLabel,
          }}
          ref={ref}
          isOptionEqualToValue={getOptionSelected ?? defaultGetOptionSelected}
          getOptionLabel={getOptionLabel ?? defaultGetOptionLabel}
          options={options}
          loading={loading}
          noOptionsText={<Trans>Sin resultados</Trans>}
          disablePortal={disablePortal}
          renderInput={(params) => (
            <SearchField
              {...params}
              helperText={helperText}
              error={error}
              name={name}
              placeholder={!readOnly ? placeholder : ''}
              label={label}
              loading={loading}
              onClear={onClear}
              endAdornment={endAdornment}
              {...(startAdornment && {
                startAdornment: (
                  <>
                    {startAdornment}
                    {params.InputProps.startAdornment}
                  </>
                ),
              })}
              readOnly={readOnly}
            />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                key={option.id ?? index}
                size="small"
                label={`${option.name} ${option?.last_name ?? ''}`}
                deleteIcon={<CloseIcon size={16} />}
                {...getTagProps({ index })}
              />
            ))
          }
          clearText={t`Borrar`}
          loadingText={t`Cargando...`}
          {...(readOnly && { disableClearable: true, popupIcon: null })}
          readOnly={readOnly}
          {...rest}
        />
      </Grid>
    );
  },
);

AutocompleteField.propTypes = {
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  gridProps: PropTypes.object,
  onClear: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  endAdornment: PropTypes.node,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  startAdornment: PropTypes.node,
};

export default AutocompleteField;
