import update from 'immutability-helper';

const reducers = {
  setGeneralConfiguration(state, configurations) {
    const {
      active_countries: activeCountries,
      attendance_type: attendanceTypes,
      event_type: eventTypes,
      notification_type: notificationTypes,
      roles: roleTypes,
    } = configurations;

    return update(state, {
      activeCountries: { $set: activeCountries },
      attendanceTypes: { $set: attendanceTypes },
      eventTypes: { $set: eventTypes },
      notificationTypes: { $set: notificationTypes },
      roleTypes: { $set: roleTypes },
    });
  },
  setActiveModules(state, activeModules) {
    return update(state, {
      activeModules: { $set: activeModules },
    });
  },
};

export default reducers;
