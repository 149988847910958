import { API_SIS_BASE } from 'src/config/general';

export const finalScoreCalculate = () =>
  `${API_SIS_BASE}/final-score/calculate`;

export const updateFinalScore = (id) => `${API_SIS_BASE}/final-score/${id}`;
export const fetchFinalScoreUrl = () => `${API_SIS_BASE}/final-score/list`;
export const fetchFinalScoreApprovalListUrl = () =>
  `${API_SIS_BASE}/final-score/approve-list`;
export const approveFinalScoreUrl = () => `${API_SIS_BASE}/final-score/approve`;

export const scoreScaleHasScores = (id) =>
  `${API_SIS_BASE}/score-scale/has-scores/${id}`;

export const scoreScaleReassignValue = () =>
  `${API_SIS_BASE}/score-scale/reassigned-score-scale-value`;

export const getAssessmentTypeFinalScores = () =>
  `${API_SIS_BASE}/assessment-type-final-score`;

export const updateAssessmentTypeFinalScoreBulk = () =>
  `${API_SIS_BASE}/assessment-type-final-score/update-bulk`;

export const assessmentTypeFinalScore = () =>
  `${API_SIS_BASE}/assessment-type-final-score`;

export const assessmentReportScore = () => `${API_SIS_BASE}/report/score`;
