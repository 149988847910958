import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { CheckField } from 'src/components/Form';

const CheckToggleField = ({
  name,
  label,
  color = 'grey.800',
  onChange,
  ...props
}) => {
  return (
    <Grid item xs={12} {...props}>
      {label && (
        <Typography variant="h2" mb={2}>
          {label}
        </Typography>
      )}
      <FormGroup row>
        <CheckField
          onChange={onChange}
          name={name}
          icon={<CancelIcon sx={{ fontSize: 26 }} />}
          checkedIcon={<HighlightOffIcon sx={{ fontSize: 26 }} />}
          sx={{
            color: color,
            '&.Mui-checked': {
              color: color,
            },
          }}
          gridProps={null}
        />
        <CheckField
          onChange={onChange}
          name={name}
          icon={<CheckCircleOutlineIcon sx={{ fontSize: 26 }} />}
          checkedIcon={<CheckCircleIcon sx={{ fontSize: 26 }} />}
          sx={{
            color: color,
            '&.Mui-checked': {
              color: color,
            },
          }}
          gridProps={null}
        />
      </FormGroup>
    </Grid>
  );
};

CheckToggleField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  color: PropTypes.string,
  onChange: PropTypes.func,
};

export default CheckToggleField;
