import { getInstitutionTaxInformationUrl } from 'src/shared/services/url/UrlPaymentManagement';
import { useGetOne } from 'src/shared/services/api/query';
import { QUERY_KEY } from '../../constants/queryKey';
import institutionTaxInformationSerializer from '../../helpers/institutionTaxInformationSerializer';

const useGetInstitutionTaxInformation = ({ queryParams, ...params } = {}) => {
  return useGetOne(
    [QUERY_KEY.INSTITUTION_TAX_INFORMATION, params],
    getInstitutionTaxInformationUrl(),
    {
      expand: 'tax_regime,active_resolution,extra_data',
      sort: 'name',
      ...params,
    },
    false,
    false,
    queryParams,
    institutionTaxInformationSerializer,
  );
};

export default useGetInstitutionTaxInformation;
