import React from 'react';
import { Trans } from '@lingui/macro';
import { Box, Button, Stack, Typography } from '@mui/material';

import Page from 'src/components/Page';
import router from 'src/shared/utils/router';
import Image from 'src/components/Image';
import FailImage from 'src/assets/images/emptystates/empty-state-illustration.svg';

const FallbackComponent = ({ error }) => {
  const { userAgent } = window.navigator;

  return (
    <Page
      metadata={{
        title: '¡Ups! Algo no anda bien...',
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        height={(theme) => `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          maxWidth="100%"
          width={{
            xs: '80%',
            sm: 400,
          }}
          gap={{
            xs: 2,
            md: 3,
          }}
        >
          <Typography variant="h1" textAlign="center">
            <Trans>
              ¡Ups!
              <br />
              Algo no anda bien...
            </Trans>
          </Typography>
          <Image src={FailImage} width="50%" />
          <Typography variant="h3" textAlign={'center'}>
            <Trans>
              La aplicación ha tenido un problema. Si el problema persiste,
              contacta al soporte técnico.
            </Trans>
          </Typography>

          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            padding={2}
            sx={{ backgroundColor: 'grey.100' }}
          >
            <Typography variant="body2">
              <b>Error:</b>
              <br />
              {error?.toString()}
            </Typography>
            <Typography variant="body2">
              <b>User Agent:</b>
              <br />
              {userAgent}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.href = router.communication.newsfeed.root;
            }}
          >
            <Trans>¡Vamos de vuelta!</Trans>
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              window.location.href = router.external.help;
            }}
          >
            <Trans>Visitar el centro de ayuda</Trans>
          </Button>
        </Stack>
      </Stack>
    </Page>
  );
};

export default FallbackComponent;
