import React, { memo } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import useStyles from './styles';
import UserResultRow from './UsersResultRow';
import ResultLabel from '../ResultLabel';

const UserResults = memo(({ userRows, showLabel }) => {
  const classes = useStyles();
  return (
    <List className={classes.root}>
      {showLabel && <ResultLabel text="Personas" />}
      {userRows.map((user) => (
        <UserResultRow key={user.id} user={user} clickable />
      ))}
    </List>
  );
});

UserResults.displayName = 'UserResults';

UserResults.propTypes = {
  userRows: PropTypes.array,
  showLabel: PropTypes.bool,
};

export default UserResults;
