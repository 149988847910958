import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  largeBadge: {
    border: `1px solid ${theme.palette.container.light}`,
    borderRadius: 5,
    background: 'white',
    bottom: 5,
    right: 2,
    padding: 0,
  },
  icon: {
    color: theme.palette.primary.main,
    padding: 0,
  },
  isRead: {
    border: `1px solid ${theme.palette.divider}`,
    '& svg': {
      width: '16px !important',
      color: 'inherit !important',
    },
  },
}));

export default useStyles;
