import React, { useRef, useState } from 'react';
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grow,
  Box,
  useMediaQuery,
  Divider,
} from '@mui/material';
import {
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';

import Link from 'src/components/Link';

import NavigationList from '..';

const NavigationItem = (props) => {
  const {
    title,
    href,
    selected,
    icon,
    onClick,
    external,
    sx,
    action,
    enableNesting,
    items,
    divider,
    moduleId,
    enabled,
    isLastItem,
    defaultSelected,
    ...restOfProps
  } = props;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const buttonRef = useRef(null);
  const [isCollapsed, setIsCollapsed] = useState(selected || defaultSelected);
  const [isHovered, setIsHovered] = useState(isMobile);

  const isNested = items && Array.isArray(items) && items.length > 0;

  const handleClick = () => {
    if (isNested) setIsCollapsed(!isCollapsed);
    if (onClick) onClick(props, !isCollapsed);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <ListItemButton
        {...(!isMobile && {
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
        })}
        ref={buttonRef}
        selected={selected}
        onClick={handleClick}
        sx={{
          ...(isNested && {
            '*': {
              fontWeight: (theme) =>
                `${theme.typography.fontWeightBold} !important`,
            },
          }),
          ...sx,
        }}
        {...(href && {
          component: Link,
          href,
        })}
        {...(external && {
          target: '_blank',
          component: 'a',
          href,
        })}
        {...restOfProps}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}

        {title && (
          <ListItemText
            primaryTypographyProps={{
              noWrap: true,
              ...(typeof title === 'string' && {
                title,
              }),
            }}
            primary={title}
          />
        )}

        {action && (
          <Grow in={isHovered}>
            <Box
              sx={{
                //TODO: REMOVER CUANDO PODAMOS ACTUALIZAR LOS ICONOS EN EL DESIGN SYSTEM
                '& svg': {
                  color: (theme) => theme.palette.text.primary,
                },
              }}
            >
              {action}
            </Box>
          </Grow>
        )}

        {!action && isNested && (
          <>
            {isCollapsed ? (
              <ArrowDropUpIcon fontSize="small" />
            ) : (
              <ArrowDropDownIcon fontSize="small" />
            )}
          </>
        )}
      </ListItemButton>

      {isNested && (
        <Collapse mountOnEnter unmountOnExit in={isCollapsed}>
          <NavigationList options={items} />
        </Collapse>
      )}

      {divider && !isLastItem && (
        <Divider sx={{ marginY: 1 }} variant="middle" component="li" />
      )}
    </>
  );
};

export default NavigationItem;
