import { useGetList } from 'src/shared/services/api/query';
import { getDraftConversation } from 'src/shared/services/url/UrlCommunication';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';

const useGetDraftConversations = (params = {}) => {
  const filter = {
    subject: params.subject ?? '',
    users: params.users ?? '',
    without_seen: params.unreadFilter || '',
  };

  const KEY = QUERY_KEY.DRAFT_CONVERSATIONS_LIST;

  return useGetList(
    [KEY, filter],
    getDraftConversation(),
    {
      expand: 'sender',
      ...params,
    },
    true,
    false,
  );
};

export default useGetDraftConversations;
