import { Api as api } from 'src/shared/services/api';
import axios from 'axios';
import { getLocalStorageKey } from 'src/shared/utils/manageLocalStorage';
import BLENDED_STORAGE_KEYS from 'src/shared/utils/storageKeys';

import { DEFAULT_SCORE_SCALE } from 'src/config/constants';
import { levelPeriodsUrl } from 'src/shared/services/url/UrlUser';
import { getMembersBySubjectId } from 'src/shared/services/url/UrlVirtualClassroom';
import {
  approveAssessmentUrl,
  assessmentTypeUrl,
  finalScoreApproveListUrl,
  finalScoreApproveUrl,
  finalScoreRejectUrl,
  finalScoreUpdateBulk,
  getAssessmentsUrl,
  getPendingApprovalUrl,
  getScoresUrl,
  rejectAssessmentUrl,
  scoreConfigBySubjectId,
  scoreConfigUrl,
  scoreScaleUrl,
  assessmentTypeFinalScore,
  periodFinalScores,
  getScoresListUrl,
} from 'src/shared/services/url/UrlSis';
import {
  getLegacyReportCardPreview,
  sendLegacyReportCards,
} from 'src/shared/services/url/UrlLegacy';
import { ROLE_LIST } from 'src/config/general';
import {
  fetchFinalScoreUrl,
  getAssessmentTypeFinalScores,
} from 'src/shared/services/url/UrlGrading';
import { t } from '@lingui/macro';
import { nanoid } from 'nanoid';

import { findLevelPeriodByDate } from './helpers';
import sortByLastName from '../../../shared/utils/sortByLastName';
import parseExternalId from '../../../shared/utils/parseExternalId';

const LEGACY_AUTH_TOKEN = getLocalStorageKey(BLENDED_STORAGE_KEYS.LEGACY_AUTH);

const handleTryCatch = async (
  tryFunction,
  catchFunction,
  errorMessage,
  finallyFunction,
) => {
  try {
    if (tryFunction) await tryFunction();
  } catch (exception) {
    if (catchFunction) catchFunction();
    // eslint-disable-next-line no-console
    console.log(exception);
    throw exception;
  } finally {
    if (finallyFunction) finallyFunction();
  }
};

const effects = (dispatch) => ({
  async getLevelPeriods(levelId) {
    handleTryCatch(async () => {
      const { data } = await api.get({
        url: levelPeriodsUrl(),
        data: { level_id: levelId },
      });
      const levelPeriods = (data ?? []).map((el) => ({
        ...el,
        externalId: parseExternalId(el.external_id),
      }));
      await dispatch.grading.setLevelPeriods(levelPeriods);
    });
  },

  async getStudentsBySubjectId(subjectId) {
    const { data } = await api.get({
      url: getMembersBySubjectId(subjectId),
      data: { role: ROLE_LIST.STUDENT, paginate: false },
    });
    const parsedUsers = data.map((el) => ({ ...el, user_id: el.id }));
    const sortedUsers = sortByLastName(parsedUsers);
    await dispatch.grading.setStudents(sortedUsers);
    await dispatch.grading.setScores(null);
    await dispatch.grading.setScoresQueue([]);
  },

  async getScoreConfig(subjectId) {
    handleTryCatch(async () => {
      const { data } = await api.get({
        url: scoreConfigBySubjectId(subjectId),
      });
      await dispatch.grading.setScoreConfig({ config: data, subjectId });
    });
  },

  async getScoreScales({ level_id, subject_id }) {
    handleTryCatch(async () => {
      const extraParams = { expand: 'scoreScaleValues' };
      const { data: subjectScales } = await api.get({
        url: scoreScaleUrl(),
        data: { ...extraParams, subject_id },
      });
      if (!subjectScales.length) {
        const { data: levelScales } = await api.get({
          url: scoreScaleUrl(),
          data: { ...extraParams, level_id },
        });
        return dispatch.grading.setAssessmentScales([
          DEFAULT_SCORE_SCALE,
          ...levelScales,
        ]);
      }
      return dispatch.grading.setAssessmentScales([
        DEFAULT_SCORE_SCALE,
        ...subjectScales,
      ]);
    });
  },

  async getAssessmentTypes({ level_id, subject_id }) {
    handleTryCatch(async () => {
      const { data: subjectAssessmentTypes } = await api.get({
        url: assessmentTypeUrl(),
        data: { subject_id },
      });
      if (!subjectAssessmentTypes.length) {
        const { data: levelAssessmentTypes } = await api.get({
          url: assessmentTypeUrl(),
          data: { level_id },
        });
        return dispatch.grading.setAssessmentTypes(levelAssessmentTypes);
      }
      return dispatch.grading.setAssessmentTypes(subjectAssessmentTypes);
    });
  },

  async getAssessmentConfig({ level_id, subject_id }) {
    const data = { level_id, subject_id };
    handleTryCatch(async () => {
      await this.getScoreScales(data);
      await this.getAssessmentTypes(data);
    });
  },

  async getReportCardConfig({ level_id, subject_id }) {
    return new Promise((resolve) => {
      handleTryCatch(async () => {
        const sendData = { level_id, subject_id };
        const { data } = await api.get({
          url: scoreConfigUrl(),
          data: sendData,
        });
        resolve(data);
      });
    });
  },

  async getReportCardScalesConfig({ level_id, subject_id }) {
    return new Promise((resolve) => {
      handleTryCatch(async () => {
        const sendData = { expand: 'scoreScaleValues', level_id, subject_id };
        const { data } = await api.get({
          url: scoreScaleUrl(),
          data: sendData,
        });
        resolve(data);
      });
    });
  },

  async getDivisionUsesAssessmentTypes({ subject_id }) {
    return new Promise((resolve) => {
      handleTryCatch(async () => {
        const sendData = {
          subject_id,
          'per-page': 10000,
          expand: 'scoreValue,assessmentType',
        };
        const { data } = await api.get({
          url: getAssessmentTypeFinalScores(),
          data: sendData,
        });
        resolve({
          hasAssessmentTypes: data.length > 0,
          assessmentTypesScores: data,
        });
      });
    });
  },

  async getAssessments(
    { date_from, date_to, subject_id, ...rest },
    { grading },
  ) {
    handleTryCatch(async () => {
      const { levelPeriods } = grading;
      const { data } = await api.get({
        url: getAssessmentsUrl(),
        data: {
          subject_id,
          date_from,
          date_to,
          'per-page': 1000,
          ...rest,
        },
      });

      const parsedAssessments = data.map(({ date, ...el }) => {
        const foundLevelPeriod = findLevelPeriodByDate(date, levelPeriods);
        return {
          ...el,
          date,
          level_period_id: foundLevelPeriod.id,
        };
      });
      await dispatch.grading.setAssessments(parsedAssessments);
    });
  },

  async handleUpdateAssessmentsList(assessment, { grading }) {
    const { assessments } = grading;
    const assessmentsList = [...assessments];

    if (assessment?.id && !assessment?.isAdding) {
      const foundIndex = assessmentsList.findIndex(
        (el) => el.id === assessment.id,
      );
      if (foundIndex > -1) {
        assessmentsList[foundIndex] = {
          ...assessmentsList[foundIndex],
          ...assessment,
        };
      }
    } else if (assessment) {
      assessmentsList.unshift(assessment);
    }

    return dispatch.grading.setAssessments(assessmentsList);
  },

  async updateAssessments(
    { id, localUpdate, level_period_id, ...values },
    { grading },
  ) {
    try {
      const action = id ? 'put' : 'post';
      const { assessments, scores } = grading;
      const isAdding = !id;
      const foundAssessment = assessments.find((el) => el.id === id);
      const hasUpdatedScale =
        values?.score_scale_id !== foundAssessment?.score_scale_id && !isAdding;

      let newData;

      if (!localUpdate) {
        const { data } = await api[action]({
          url: getAssessmentsUrl(id),
          data: values,
        });

        newData = { ...data, level_period_id };

        if (hasUpdatedScale) {
          await dispatch.grading.setScores(
            scores.filter((el) => el.assessment_id !== id),
          );
        }
      }

      await this.handleUpdateAssessmentsList({
        id,
        level_period_id,
        isAdding,
        ...values,
        ...newData,
      });
    } catch (error) {
      throw new Error(error);
    }
  },

  async removeAssessment({ id }, { grading }) {
    const { assessments } = grading;
    handleTryCatch(async () => {
      if (id) {
        await api.delete({
          url: getAssessmentsUrl(id),
        });
        dispatch.grading.setAssessments(
          assessments.filter((el) => el.id !== id),
        );
      }
    });
  },

  async getScores(payload, { grading }) {
    handleTryCatch(async () => {
      const { assessments } = grading;
      const assessmentsIds = [...assessments].map((el) => el.id);
      if (!assessmentsIds.length) return;
      const { data } = await api.post({
        url: `${getScoresListUrl()}?per-page=2000`,
        data: {
          assessment_id: assessmentsIds,
          expand: 'scoreValue',
        },
      });
      await dispatch.grading.setScores(data);
    });
  },

  async updateScores(payload, { grading }) {
    handleTryCatch(async () => {
      const { scoresQueue } = grading;
      const newScores = [...scoresQueue];
      const foundIndex = newScores.findIndex(
        (el) =>
          el.user_id === payload.user_id &&
          el.assessment_id === payload.assessment_id,
      );
      if (foundIndex > -1) {
        const isUnsavedRemoval = !payload.id && payload.remove;
        if (isUnsavedRemoval) {
          newScores.splice(foundIndex, 1);
        } else {
          newScores[foundIndex] = {
            ...newScores[foundIndex],
            ...payload,
          };
        }
      } else {
        newScores.push(payload);
      }
      await dispatch.grading.setScoresQueue(newScores);
    });
  },

  async saveScores(groupedScores, { grading }) {
    const { scoresQueue, scores } = grading;
    const scoresPromises = [];
    const removingScores = scoresQueue.filter((el) => el.remove);
    const addingScores = scoresQueue.filter((el) => !el.remove);

    groupedScores.map((groupedScore) =>
      scoresPromises.push(
        api.post({
          url: getScoresUrl(),
          data: groupedScore,
        }),
      ),
    );

    const updatedScores = scores
      .filter((score) => {
        const isRemoved = removingScores.find(
          (removingScore) =>
            score.user_id === removingScore.user_id &&
            score.assessment_id === removingScore.assessment_id,
        );
        const isUpdatedOrAdded = addingScores.find(
          (addingScore) =>
            score.user_id === addingScore.user_id &&
            score.assessment_id === addingScore.assessment_id,
        );
        return !isRemoved && !isUpdatedOrAdded;
      })
      .concat(scoresQueue);

    await Promise.all(scoresPromises);
    await dispatch.grading.setScores(updatedScores);
    await dispatch.grading.setScoresQueue([]);
  },

  async saveFinalScoresBulk(updatedFinalScores, { grading }) {
    await handleTryCatch(async () => {
      const { finalScoresQueue } = grading;
      await api.post({
        url: finalScoreUpdateBulk(),
        data: { final_scores: updatedFinalScores },
      });
      const updatedIds = updatedFinalScores.map((el) => el.id);
      const updatedQueue = finalScoresQueue.filter(
        (el) => !updatedIds.includes(el.id),
      );
      await dispatch.grading.addOrUpdateFinalScores(updatedFinalScores);
      await dispatch.grading.setFinalScoresQueue(updatedQueue);
    });
  },

  async getPendingApproval(isFinalScore) {
    const parseFinalScores = (finalScores) => {
      const allFinalScores = Array.isArray(finalScores)
        ? [...finalScores]
        : [finalScores];
      return allFinalScores.map((el) => {
        const levelPeriod = Array.isArray(el.level_period)
          ? el.level_period[0]
          : el.level_period;
        return {
          ...el,
          id: nanoid(),
          title: t`Calificación parcial: ${levelPeriod.name}`,
          subject_id: el.subject.id,
          level_period_id: levelPeriod.id,
        };
      });
    };
    const endpoint = isFinalScore
      ? finalScoreApproveListUrl()
      : getPendingApprovalUrl();
    const { data } = await api.get({
      url: endpoint,
      // TODO: PLEASE FIX PER-PAGE
      data: { status: 2, 'per-page': 1000 },
    });
    return isFinalScore ? parseFinalScores(data) : data;
  },

  async approveAssessment(payload, { grading }) {
    handleTryCatch(async () => {
      const { pendingApproval } = grading;
      const foundAssessmentsIds = [];

      payload.forEach((assessments) => {
        foundAssessmentsIds.push(...assessments.assessment_ids);
        api.post({
          url: approveAssessmentUrl(),
          data: assessments,
        });
      });

      const filteredRejectedAssessments = pendingApproval.filter(
        (el) => !foundAssessmentsIds.includes(el.id),
      );

      await dispatch.grading.setPendingApproval(filteredRejectedAssessments);
    });
  },

  async approveFinalScores(payload, { grading }) {
    handleTryCatch(async () => {
      const { pendingApproval } = grading;
      const foundFinalScoresIds = [];
      payload.forEach(({ level_period_id, subject_id, id }) => {
        foundFinalScoresIds.push(id);
        api.post({
          url: finalScoreApproveUrl(),
          data: {
            level_period_id: [level_period_id],
            subject_id: [subject_id],
          },
        });
      });
      const filteredFinalScores = pendingApproval.filter(
        (el) => !foundFinalScoresIds.includes(el.id),
      );
      await dispatch.grading.setPendingApproval(filteredFinalScores);
    });
  },

  async rejectAssessment(payload, { grading }) {
    await handleTryCatch(async () => {
      const { pendingApproval } = grading;
      const { assessment_ids, level_period_id, subject_id, isFinalScores } =
        payload;

      const filteredRejectedAssessments = pendingApproval.filter((el) => {
        if (isFinalScores) {
          return !(
            level_period_id.includes(el.level_period_id) &&
            subject_id.includes(el.subject_id)
          );
        }

        return !assessment_ids?.includes(el.id);
      });

      const endpoint = isFinalScores
        ? finalScoreRejectUrl()
        : rejectAssessmentUrl();

      await api.post({
        url: endpoint,
        data: payload,
      });

      await dispatch.grading.setPendingApproval(filteredRejectedAssessments);
    });
  },

  async updateFilters(payload) {
    handleTryCatch(async () => {
      await dispatch.grading.setActiveFilters({ ...payload });
    });
  },

  async updateAverageCalculator({ isOpen, levelPeriodId, studentsIds }) {
    handleTryCatch(async () => {
      await dispatch.grading.setAverageCalculator({
        isOpen,
        levelPeriodId,
        studentsIds,
      });
    });
  },

  async updateAllFinalScores(newFinalScoresList) {
    handleTryCatch(async () =>
      dispatch.grading.addOrUpdateFinalScores(newFinalScoresList),
    );
  },

  async fetchFinalScores({ subject_id, user_id, ...rest }, { grading }) {
    handleTryCatch(async () => {
      const { levelPeriods, students, reportCard } = grading;
      const studentsList = [...students, ...reportCard.students];
      const studentsIds = studentsList.map((el) => el.id);
      const { data } = await api.post({
        url: `${fetchFinalScoreUrl()}?expand=scoreValue`,
        data: {
          subject_id,
          user_id,
          level_period_id: levelPeriods.map((el) => el.id),
          'per-page': 1000,
          expand: 'scoreValue',
          ...rest,
        },
      });

      const filteredFinalScoresByStudents = data.filter((el) =>
        studentsIds.includes(el.user_id),
      );

      await dispatch.grading.setFinalScores(filteredFinalScoresByStudents);
    });
  },

  async generateReportCardPreview({
    period_id,
    send,
    fullPage,
    division_id,
    include_criteria,
    studentId,
    subjects,
    average,
    showPendingSubjects,
    calificaciones_parciales,
  }) {
    handleTryCatch(async () => {
      const formData = new FormData();

      formData.append('students[0][id]', studentId);
      formData.append('include_criteria', include_criteria);
      formData.append('division_id', division_id);
      formData.append('fullPage', fullPage);
      formData.append('send', send);
      formData.append('period_id', period_id);
      formData.append('average', average);
      formData.append('showPendingSubjects', showPendingSubjects);
      Object.keys(calificaciones_parciales).forEach((userId) => {
        Object.keys(calificaciones_parciales[userId]).forEach((periodId) => {
          Object.keys(calificaciones_parciales[userId][periodId]).forEach(
            (subjectId) => {
              if (
                calificaciones_parciales[userId][periodId][subjectId]
                  ?.nota_final
              ) {
                formData.append(
                  `calificaciones_parciales[${userId}][${periodId}][${subjectId}][nota_final][valor]`,
                  calificaciones_parciales[userId][periodId][subjectId]
                    .nota_final.valor,
                );
                formData.append(
                  `calificaciones_parciales[${userId}][${periodId}][${subjectId}][nota_final][umbral]`,
                  calificaciones_parciales[userId][periodId][subjectId]
                    .nota_final.umbral,
                );
              }
              if (
                calificaciones_parciales[userId][periodId][subjectId].criterios
              ) {
                calificaciones_parciales[userId][periodId][
                  subjectId
                ].criterios.forEach((criterio, indexCriterio) => {
                  formData.append(
                    `calificaciones_parciales[${userId}][${periodId}][${subjectId}][criterios][${indexCriterio}][id]`,
                    criterio.id,
                  );
                  formData.append(
                    `calificaciones_parciales[${userId}][${periodId}][${subjectId}][criterios][${indexCriterio}][valor]`,
                    criterio.valor,
                  );
                  formData.append(
                    `calificaciones_parciales[${userId}][${periodId}][${subjectId}][criterios][${indexCriterio}][nombre]`,
                    criterio.nombre,
                  );
                });
              }
            },
          );
        });
      });

      subjects.forEach((subject, idx) => {
        formData.append(`subjects[${idx}][id]`, subject.externalId);
        formData.append(`subjects[${idx}][indice]`, subject.index ?? idx);
        formData.append(
          `subjects[${idx}][nombre]`,
          subject.shownName ?? subject.subjectName ?? subject.name,
        );
      });

      const { data } = await axios.post(
        getLegacyReportCardPreview(),
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authentication: LEGACY_AUTH_TOKEN,
          },
        },
      );

      dispatch.grading.setReportCard({
        previewDialog: {
          open: true,
          preview: data.vistaHTML,
        },
      });
    });
  },

  async sendReportCards(
    {
      period_id,
      send,
      fullPage,
      division_id,
      include_criteria,
      subjects,
      average,
      showPendingSubjects,
      calificaciones_parciales,
    },
    { grading },
  ) {
    handleTryCatch(async () => {
      const formData = new FormData();
      const { reportCard } = grading;
      const { students } = reportCard;

      students.forEach((student, index) => {
        formData.append(
          `students[${index}][id]`,
          parseExternalId(student.external_id),
        );
      });

      formData.append('include_criteria', include_criteria);
      formData.append('division_id', division_id);
      formData.append('fullPage', fullPage);
      formData.append('send', send);
      formData.append('period_id', period_id);
      formData.append('average', average);
      formData.append('showPendingSubjects', showPendingSubjects);

      Object.keys(calificaciones_parciales).forEach((userId) => {
        Object.keys(calificaciones_parciales[userId]).forEach((periodId) => {
          Object.keys(calificaciones_parciales[userId][periodId]).forEach(
            (subjectId) => {
              if (
                calificaciones_parciales[userId][periodId][subjectId]
                  ?.nota_final
              ) {
                formData.append(
                  `calificaciones_parciales[${userId}][${periodId}][${subjectId}][nota_final][valor]`,
                  calificaciones_parciales[userId][periodId][subjectId]
                    .nota_final.valor,
                );
                formData.append(
                  `calificaciones_parciales[${userId}][${periodId}][${subjectId}][nota_final][umbral]`,
                  calificaciones_parciales[userId][periodId][subjectId]
                    .nota_final.umbral,
                );
              }
              if (
                calificaciones_parciales[userId][periodId][subjectId].criterios
              ) {
                calificaciones_parciales[userId][periodId][
                  subjectId
                ].criterios.forEach((criterio, indexCriterio) => {
                  formData.append(
                    `calificaciones_parciales[${userId}][${periodId}][${subjectId}][criterios][${indexCriterio}][id]`,
                    criterio.id,
                  );
                  formData.append(
                    `calificaciones_parciales[${userId}][${periodId}][${subjectId}][criterios][${indexCriterio}][valor]`,
                    criterio.valor,
                  );
                  formData.append(
                    `calificaciones_parciales[${userId}][${periodId}][${subjectId}][criterios][${indexCriterio}][nombre]`,
                    criterio.nombre,
                  );
                });
              }
            },
          );
        });
      });

      subjects.forEach((subject, idx) => {
        formData.append(`subjects[${idx}][id]`, subject.externalId);
        formData.append(`subjects[${idx}][indice]`, subject.index ?? idx);
        formData.append(
          `subjects[${idx}][nombre]`,
          subject.shownName ?? subject.subjectName ?? subject.name,
        );
      });

      await axios.post(sendLegacyReportCards(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authentication: LEGACY_AUTH_TOKEN,
        },
      });

      dispatch.grading.setReportCard({
        previewDialog: { ...reportCard.previewDialog, generated: true },
      });
    });
  },

  async fetchAssessmentTypesFinalScores({ level_period_id, subject_id }) {
    handleTryCatch(async () => {
      const { data } = await api.get({
        url: assessmentTypeFinalScore(),
        data: {
          subject_id,
          level_period_id,
          'per-page': 5000,
          expand: 'scoreValue,assessmentType.scoreScale',
        },
      });

      await dispatch.grading.setAssessmentTypeFinalScores(data);
    });
  },

  async clearAllFinalScores({ levelPeriodId }, { grading }) {
    return handleTryCatch(async () => {
      await api.delete({
        url: periodFinalScores({
          levelPeriodId,
          subjectId: grading.activeSubjectId,
        }),
      });

      const finalScoresWithoutCurrentPeriod = grading.finalScores.filter(
        (finalScore) => {
          return finalScore.level_period_id !== levelPeriodId;
        },
      );

      await dispatch.grading.setFinalScores(finalScoresWithoutCurrentPeriod);
    });
  },

  async handleSaveScores(props, { grading }) {
    const { scoresQueue } = grading;
    if (scoresQueue && scoresQueue.length) {
      const groupedScores = scoresQueue.reduce(
        (scoreGroup, item) => ({
          ...scoreGroup,
          [item.assessment_id]: {
            assessment_id: item.assessment_id,
            students: [
              ...(scoreGroup[item.assessment_id]?.students || []),
              item,
            ],
          },
        }),
        {},
      );
      await this.saveScores(Object.values(groupedScores));
    }
  },

  async handleSaveFinalScores(props, { grading }) {
    const { finalScoresQueue } = grading;
    if (finalScoresQueue && finalScoresQueue.length > 0) {
      const finalScoresPayload = finalScoresQueue.map(
        ({ id, numerical_value, score_scale_value_id }) => ({
          id,
          numerical_value,
          score_scale_value_id,
        }),
      );
      await this.saveFinalScoresBulk(finalScoresPayload);
    }
  },
});

export default effects;
