import { useCallback, useState } from 'react';
import { getDefaultStore, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useParams } from 'react-router-dom';

import {
  dlocalPaymentProcessErrorAtom,
  paymentOrderAtom,
  paymentOrderFormDataAtom,
  selectedDLocalPaymentMethodAtom,
} from 'src/features/payment-management/atoms';
import { CreatePaymentOrdersStore } from 'src/shared/atoms/stepWizard/stepWizardStore';
import {
  stepWizardIncAtom,
  stepWizardSetAtom,
} from 'src/shared/atoms/stepWizard/stepWizardAtom';
import usePaymentConfig from './data/usePaymentConfig';
import useDLocalPaymentMethods from './data/dLocal/useDLocalPaymentMethods';
import useDLocalPayment from './data/dLocal/useDLocalPayment';
import useDLocalAttachPaymentMethod from './data/dLocal/useDLocalAttachPaymentMethod';
import useDLocalPaymentOrderProcess from './data/dLocal/useDLocalPaymentOrderProcess';
import useGetDLocalAccountInformation from './data/dLocal/useGetDLocalAccountInformation';
import router from 'src/shared/utils/router';
import useParentBalance from './useParentBalance';
import {
  dlocalPaymentMethod,
  paymentStatus,
  paymentProvider as paymentOrderProvider,
} from '../constants/payments';

const useDLocalCreatePaymentOrder = () => {
  const defaultStore = getDefaultStore();
  const { orderId } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [paymentOrder, setPaymentOrder] = useAtom(paymentOrderAtom, {
    store: defaultStore,
  });
  const setPaymentProcessError = useSetAtom(dlocalPaymentProcessErrorAtom);
  const paymentOrderFormData = useAtomValue(paymentOrderFormDataAtom, {
    store: defaultStore,
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useAtom(
    selectedDLocalPaymentMethodAtom,
  );
  const { transactions } = useParentBalance();
  const { handlePaymentOrder } = useDLocalPayment();

  const { data: financialConfig } = usePaymentConfig();

  const { data: dlocalPaymentMethods, isLoading: isLoadingPaymentMethods } =
    useDLocalPaymentMethods();

  const { data: dlocalAccountInfo } = useGetDLocalAccountInformation({
    enabled: financialConfig?.provider === paymentOrderProvider.DLOCAL,
  });

  const { mutateAsync: attachPaymentMethod } = useDLocalAttachPaymentMethod(
    orderId ?? paymentOrder?.id,
  );
  const { mutateAsync: paymentOrderProcess } = useDLocalPaymentOrderProcess(
    orderId ?? paymentOrder?.id,
  );

  const hasPaymentDirectEnabled =
    financialConfig?.dlocal_payment_direct_enabled;
  const paymentProvider = financialConfig?.provider;
  const paymentOrderUrl = router.collections.paymentOrder('').slice(0, -1);
  const callbackUrl = `${window.location.origin}${paymentOrderUrl}`;

  const nextStep = () => CreatePaymentOrdersStore.set(stepWizardIncAtom);
  const setStep = useSetAtom(stepWizardSetAtom);

  const handleCreatePayment = (order) => {
    const { name, document, amountPartial, email } =
      order ?? paymentOrderFormData;

    const amount = amountPartial;
    const body = {
      value: amount,
      transactions,
      payer: {
        name,
        email,
        document,
      },
      callback_url: callbackUrl,
    };

    handlePaymentOrder(body);
  };

  const handleAttachPaymentMethod = async () => {
    const body = {
      payment_method_id: selectedPaymentMethod.id,
    };
    setSubmitting(true);
    try {
      const order = await attachPaymentMethod(body);

      if (selectedPaymentMethod?.type === dlocalPaymentMethod.CARD) {
        setPaymentOrder(order.data);
        nextStep();
      } else {
        const { data } = await paymentOrderProcess({
          ...body,
          payment_method_type: selectedPaymentMethod?.type,
        });

        if (data.status === paymentStatus.REJECTED) {
          throw new Error(data?.status_detail);
        }

        setPaymentProcessError(null);
        setPaymentOrder(data);
        setStep(4);
      }
    } catch (error) {
      setPaymentProcessError(error);
      setStep(4);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDownloadTicket = useCallback(
    (isBankTransfer = false) => {
      const { external_order } = paymentOrder ?? {};
      const { ticket, bank_transfer } = external_order ?? {};
      const url = isBankTransfer
        ? bank_transfer?.redirect_url
        : ticket?.image_url;

      window.location.href = `${url}?pdf_mode=DOWNLOAD`;
    },
    [paymentOrder],
  );

  return {
    paymentProvider,
    paymentOrder,
    dlocalAccountInfo,
    dlocalPaymentMethods,
    isLoadingPaymentMethods,
    hasPaymentDirectEnabled,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    submitting,
    nextStep,
    handleDownloadTicket,
    handleCreatePayment,
    handleAttachPaymentMethod,
  };
};

export default useDLocalCreatePaymentOrder;
