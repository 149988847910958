/**
 * Function that adds or removes an item from an array based on a specified key.
 * If the item is found in the array, it is removed. If not found, the item is added.
 *
 * @param {Array} array - The array to modify.
 * @param {Object} item - The item to add or remove.
 * @param {string} idKey - The key to identify the item in the array.
 * @returns {Array} - The modified array with the item added or removed.
 */
const addOrRemove = (array = [], item = {}, idKey = 'id') => {
  const arrayCopy = [...array];
  const index = arrayCopy.findIndex((i) => i[idKey] === item[idKey]);
  if (index > -1) {
    arrayCopy.splice(index, 1);
  } else {
    arrayCopy.push(item);
  }
  return arrayCopy;
};

export default addOrRemove;
