import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1) / 2,
    position: 'absolute',
    maxHeight: 360,
    overflow: 'auto',
  },
  tab: {
    textTransform: 'none',
  },
  tabHeader: {
    boxShadow: '0 1px 0 rgba(0, 0, 0, 0.08)',
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },
  tabContent: {
    padding: 0,
    paddingTop: theme.spacing(1),
  },
}));

export default useStyles;
