import {
  getLocalStorageKey,
  setLocalStorageKey,
} from 'src/shared/utils/manageLocalStorage';
import BLENDED_STORAGE_KEYS from 'src/shared/utils/storageKeys';

export const getSelectedInstitution = (schema) => {
  const localStorageInstitution = getLocalStorageKey(
    BLENDED_STORAGE_KEYS.SELECTED_INSTITUTION,
  );
  return (
    schema.find(
      (institution) => institution.id === localStorageInstitution?.id,
    ) ?? schema[0]
  );
};

export const getSelectedPeriod = (institution) => {
  const localStoragePeriod = getLocalStorageKey(
    BLENDED_STORAGE_KEYS.SELECTED_PERIOD,
  );
  return (
    institution?.periods?.find(
      (period) => period.id === localStoragePeriod?.id,
    ) ??
    institution.periods.find((period) => period.active === true) ??
    institution.periods[0]
  );
};

export const setSelectedInstitution = (institution) => {
  const institutionData = { ...institution };
  delete institutionData.groups;
  delete institutionData.periods;
  setLocalStorageKey(
    BLENDED_STORAGE_KEYS.SELECTED_INSTITUTION,
    institutionData,
  );
};

export const setSelectedPeriod = (period) => {
  const periodData = { ...period };
  setLocalStorageKey(BLENDED_STORAGE_KEYS.SELECTED_PERIOD, periodData);
};

export const getLegacyAuth = () =>
  getLocalStorageKey(BLENDED_STORAGE_KEYS.LEGACY_AUTH);

export const getLegacyToken = () =>
  getLocalStorageKey(BLENDED_STORAGE_KEYS.LEGACY_JWT);

export const setLegacyToken = (token) =>
  setLocalStorageKey(BLENDED_STORAGE_KEYS.LEGACY_JWT, token);

export const getRefreshToken = () =>
  getLocalStorageKey(BLENDED_STORAGE_KEYS.REFRESH_TOKEN);

export const setRefreshToken = (refresh) =>
  setLocalStorageKey(BLENDED_STORAGE_KEYS.REFRESH_TOKEN, refresh);

export const getIsFirstLogin = () =>
  getLocalStorageKey(BLENDED_STORAGE_KEYS.FIRST_LOGIN) || false;

export const getCountry = () =>
  getLocalStorageKey(BLENDED_STORAGE_KEYS.COUNTRY) || 'ar';

export const setCountry = (country) =>
  setLocalStorageKey(BLENDED_STORAGE_KEYS.COUNTRY, country);

export const setInstitutionColor = (color) =>
  setLocalStorageKey(BLENDED_STORAGE_KEYS.INSTITUTION_COLOR, color);
