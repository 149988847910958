import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',

    '& > * + *': {
      backgroundColor: 'transparent',
    },

    '&:hover': {
      '& > * + *': {
        transitionDuration: theme.transitions.duration.standard,
        transitionProperty: 'background-color',
        backgroundColor: theme.palette.grey[100],
      },
    },

    '& iframe': {
      width: '100%',
      height: '350px !important',
      marginBottom: -4,
    },
  },

  bigCard: {
    flexDirection: 'column',
  },

  content: {
    padding: theme.spacing(2),
    flex: 1,
    '& > *': {
      height: '100%',
      width: '100%',
      textAlign: 'left',
    },
  },

  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    flexGrow: 1,
  },

  description: {
    flex: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflow: 'hidden',
    color: theme.palette.text.secondary,

    // Webkit base solution only
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
  },

  domain: {
    color: theme.palette.text.secondary,
  },

  thumbnail: {
    width: 150,
    minHeight: 150,

    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
  },

  closeOpenGraph: {
    borderRadius: '100%',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.secondary,
    padding: theme.spacing(),
    position: 'relative',
    zIndex: 1,
  },

  closeVideo: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export default useStyles;
