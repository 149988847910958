import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';
import { useDebouncedCallback } from 'use-debounce';

import { Api as api } from 'src/shared/services/api';
import {
  paymentManagementSingleFamily,
  paymentManagementFamilyUsers,
} from 'src/shared/services/url/UrlPaymentManagement';
import { apiFilter } from 'src/shared/utils/apiFilter';
import useFamilies from 'src/shared/hooks/useFamilies';

const defaultErrorMessage = t`Ha ocurrido un error. Intente mas tarde.`;

const usePaymentManagementBalances = () => {
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [filterValue, setFilterValue] = useState({
    min: undefined,
    max: undefined,
    sort: false,
  });
  const [isLoadingFamilyDetails, setIsLoadingFamilyDetails] = useState(true);
  const [isLoadingFamilyMembers, setIsLoadingFamilyMembers] = useState(false);
  const [familyDetails, setFamilyDetails] = useState({});
  const [familyMembers, setFamilyMembers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const {
    isFetching: isLoadingFamilyBalances,
    data: balancesByFamily,
    pagination,
  } = useFamilies({
    name: debouncedSearchText,
    expand: 'parent,children',
    sort: filterValue.sort ? 'balance' : '-balance',
    [apiFilter.balanceGte]: filterValue.min,
    [apiFilter.balanceLte]: filterValue.max,
  });

  const fetchFamilyDetails = useCallback(
    async (familyId) => {
      setIsLoadingFamilyDetails(true);
      try {
        const { data } = await api.get({
          url: paymentManagementSingleFamily(familyId),
          data: { expand: 'parent,children' },
        });

        const { data: members } = await api.get({
          url: paymentManagementFamilyUsers(familyId),
        });

        setFamilyDetails({ ...data, members });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingFamilyDetails(false);
      }
    },
    [enqueueSnackbar],
  );

  const fetchFamilyMembers = useCallback(
    async (familyId) => {
      setIsLoadingFamilyMembers(true);
      try {
        const { data: members } = await api.get({
          url: paymentManagementFamilyUsers(familyId),
          data: {
            expand: 'variations,rates.concept',
          },
        });

        setFamilyMembers(members);
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingFamilyMembers(false);
      }
    },
    [enqueueSnackbar],
  );

  const debouncedSearch = useDebouncedCallback((query) => {
    pagination?.setPage(0);
    setDebouncedSearchText(query);
  }, 300);

  const handleSearch = useCallback(
    async ({ target }) => {
      const { value } = target;

      setSearchText(value);
      await debouncedSearch(value);
    },
    [debouncedSearch],
  );

  const handleClearSearch = useCallback(async () => {
    setSearchText('');
    await debouncedSearch('');
  }, [debouncedSearch]);

  const handleFilter = useCallback(
    (values) => {
      pagination?.setPage(0);
      setFilterValue((old) => ({ ...old, ...values }));
    },
    [pagination],
  );

  const handleClearFilter = useCallback(() => {
    pagination?.setPage(0);
    setFilterValue({ min: undefined, max: undefined, sort: '-balance' });
  }, [pagination]);

  return {
    fetchFamilyMembers,
    fetchFamilyDetails,
    handleSearch,
    handleClearSearch,
    handleFilter,
    handleClearFilter,

    setFamilyMembers,

    isLoadingFamilyMembers,
    isLoadingFamilyDetails,
    isLoadingFamilyBalances,

    pagination,
    balancesByFamily,
    search: searchText,
    familyDetails,
    familyMembers,
    filterValue,
  };
};

export default usePaymentManagementBalances;
