import { atomWithReset } from 'jotai/utils';

const initialValues = {
  accountingAccount: '',
  amount: '',
  billable: false,
  concept: null,
  name: '',
  monthsActivated: [],
  isExtraordinary: false,
  institutionTaxInformation: null,
  iva: '',
  productCode: '',
  educationalComplement: false,
  onlinePaymentAccount: null,
};

const draftCreateRateAtom = atomWithReset(initialValues);

export default draftCreateRateAtom;
