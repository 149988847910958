import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStyles from './styles';

const Main = ({ isWebView, marginWrapper, children }) => {
  const classes = useStyles();
  const marginTop = isWebView ? 0 : '64px';

  return (
    <main
      style={{
        margin: `${marginTop} 0 0 ${marginWrapper}px`,
      }}
      className={clsx({
        [classes.wrapper]: true,
        [classes.wrapperWebView]: isWebView,
      })}
    >
      <div className={classes.contentContainer}>
        <div className={classes.content}>{children}</div>
      </div>
    </main>
  );
};

Main.propTypes = {
  isWebView: PropTypes.bool,
  children: PropTypes.node,
  marginWrapper: PropTypes.number,
};

export default Main;
