import '@fullcalendar/react';
// tenemos que importar "@fullcalendar/react" por mas que no se use antes de usar los plugins.
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

const getPlugins = () => {
  return [dayGridPlugin, timeGridPlugin, listPlugin];
};

export default getPlugins;
