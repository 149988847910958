import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  container: {
    position: 'relative',
  },
  searchBarContainer: {
    width: 540,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    height: '100%',
    left: theme.spacing(1.5),
    position: 'absolute',
    color: theme.palette.text.secondary,
  },
  closeButton: {
    height: '100%',
    right: 0,
    position: 'absolute',
    color: theme.palette.text.secondary,
  },
  closeIcon: {
    color: theme.palette.text.secondary,
    fontSize: 16,
  },
  searchBarInput: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(),
    transition: theme.transitions.create('width, padding'),
    width: '100%',
    paddingLeft: theme.spacing(5),
  },
  searchBarInputEvents: {
    paddingLeft: ({ input }) =>
      `${input?.clientWidth + parseInt(theme.spacing(2), 10)}px`,
  },
  searchBarInputPeople: {
    paddingLeft: ({ input }) =>
      `${input?.clientWidth + parseInt(theme.spacing(2), 10)}px`,
  },
  searchBarInputPosts: {
    paddingLeft: ({ input }) =>
      `${input?.clientWidth + parseInt(theme.spacing(2), 10)}px`,
  },
  closeFilterButton: {
    padding: `6px ${theme.spacing(1)}`,
    top: 4,
    left: 4,
    position: 'absolute',
    zIndex: 1,
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    fontWeight: 'normal',
    fontSize: 12,
    backgroundColor: theme.palette.grey[200],

    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  closeFilterIcon: {
    marginLeft: theme.spacing(1),
  },
  loading: {
    position: 'absolute',
    top: 12,
    right: 42,
  },
}));

export default useStyles;
