import { useGetList } from 'src/shared/services/api/query';

import { QUERY_KEY } from 'src/features/sidebar/constants';
import { getExternalLinks } from 'src/shared/services/url/UrlUser';

const useExternalLinks = ({ periodId, enabled, ...params }) => {
  return useGetList(
    [QUERY_KEY.EXTERNAL_LINKS],
    getExternalLinks(periodId),
    { sort: '-id', ...params },
    false,
    false,
    {
      enabled,
      ...(!enabled && { cacheTime: 0 }),
    },
  );
};

export default useExternalLinks;
