import { useCallback, useContext } from 'react';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import api from 'src/shared/services/api/api';
import GooglePicker from 'src/components/GooglePicker';
import useTinyMCEEditor from 'src/shared/hooks/useTinyMCEEditor';
import { FILE_TYPES, getFileType } from 'src/shared/utils/fileHelper';
import { FilesDropzoneContext } from 'src/shared/context/FileDropzoneContext';
import { uploadGoogleDriveImageUrl } from 'src/shared/services/url/UrlCommunication';

const googleDrivePrefix = 'https://drive.google.com/u/1/uc';

const useGoogleDrive = (handleMenuClose, dropzoneId) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setPostFiles } = useContext(FilesDropzoneContext);
  const { handleUpdateEditorFiles } = useTinyMCEEditor(dropzoneId);

  const uploadGoogleDriveImage = (fileId, extension) => {
    return api.post({
      url: uploadGoogleDriveImageUrl(),
      data: {
        id: fileId,
        extension,
      },
    });
  };

  const handleGDriveImageUpload = useCallback(async (files) => {
    if (!files.length) return [];

    try {
      const driveFiles = files.map((file) => {
        const extension = file.name.split('.').pop() || 'jpg';
        return uploadGoogleDriveImage(file.id, `.${extension}`);
      });

      const result = await Promise.all(driveFiles);

      if (result.status === 'rejected') {
        console.error(
          t`Hubo un error al intentar cargar los archivos de Google Drive.`,
        );
      }

      return result.map(({ data }) => data);
    } catch (error) {
      throw new Error(error.message);
    }
  }, []);

  const handleParseFiles = (files) =>
    files.map(
      ({
        id,
        name: original_file_name,
        mimeType: mime_type,
        url: storage_file_name,
        preview,
      }) => {
        const parsedFile = {
          id,
          storage_file_name,
          original_file_name,
          mime_type,
          link: storage_file_name,
          preview,
          type_id: FILE_TYPES.drive,
          completed: true,
        };

        if (getFileType(mime_type) === 'picture') {
          const extension = original_file_name.split('.').pop() || 'jpg'; // JPG DEFAULT POR LAS DUDAS
          parsedFile.type_id = FILE_TYPES.default;
          parsedFile.storage_file_name = `${id}.${extension}`;
        }

        return parsedFile;
      },
    );

  const handleSelectedDriveFiles = useCallback(
    async (files) => {
      if (files.length) {
        const googleDriveImages = files.filter((file) => {
          return getFileType(file.mimeType) === 'picture';
        });

        const restOfFiles = files.filter(
          (file) => getFileType(file.mimeType) !== 'picture',
        );

        const storedGoogleDriveImages = await handleGDriveImageUpload(
          googleDriveImages,
        );

        const images = storedGoogleDriveImages.map((image) => {
          const rawImage = googleDriveImages.find(
            (localImage) => localImage.id === image.drive_id,
          );

          if (rawImage) {
            return {
              id: image.drive_id,
              mimeType: rawImage.mimeType,
              name: rawImage.name,
              preview: `${googleDrivePrefix}?id=${image.drive_id}`,
              url: image.storage_file_name,
            };
          }

          return image;
        });
        const parsedFiles = handleParseFiles([...images, ...restOfFiles]);
        setPostFiles((oldState) => [...oldState, ...parsedFiles]);
        handleUpdateEditorFiles(parsedFiles.length, false);
        closeSnackbar();
      }
    },
    [
      closeSnackbar,
      handleGDriveImageUpload,
      handleUpdateEditorFiles,
      setPostFiles,
    ],
  );

  const { showPicker } = GooglePicker(handleSelectedDriveFiles);

  const handleShowPicker = () => {
    handleMenuClose();
    enqueueSnackbar(
      t`Recuerda que los archivos de Google Drive deben ser públicos para compartirlos`,
      {
        variant: 'info',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      },
    );
    setTimeout(() => {
      closeSnackbar();
    }, 5000);
    if (showPicker) {
      showPicker();
    }
  };

  return {
    handleShowPicker,
  };
};

export default useGoogleDrive;
