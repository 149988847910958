// handle state changes with pure functions
import update from 'immutability-helper';
import { removeLocalStorageKey } from 'src/shared/utils/manageLocalStorage';
import BLENDED_STORAGE_KEYS from 'src/shared/utils/storageKeys';

const reducers = {
  logout(state) {
    return update(state, {
      isLogin: { $set: false },
      isMultiRol: { $set: false },
      selectedRole: { $set: null },
      userIs: { $set: {} },
      apiKey: { $set: '' },
      refreshToken: { $set: '' },
      isTokenExpired: { $set: false },
      data: { $set: {} },
      role: { $set: [] },
      relations: { $set: [] },
      institutions: { $set: [] },
      selectedInstitution: { $set: {} },
      selectedInstitutionPeriodId: { $set: 0 },
      isWebView: { $set: false },
      notifications: {
        $set: {
          data: [],
          hasUnreadNotifications: false,
          pagination: {
            total: 0,
          },
        },
      },
      preferences: {
        $set: { calendarView: '' },
      },
      changelog: {
        $set: {
          ...state.changelog,
          isClosed: false,
        },
      },
      errorLog: {
        $set: {
          counter: 0,
        },
      },
      isFirstLogin: { $set: false },
      webinar: {
        $set: {
          ...state.webinar,
        },
      },
      userLocale: { $set: { isLoaded: false, locale: undefined } },
    });
  },

  setIsLogin(state, isLogin) {
    return update(state, {
      isLogin: { $set: isLogin },
      isTokenExpired: { $set: false },
    });
  },

  setApiKey(state, apiKey) {
    return update(state, {
      apiKey: { $set: apiKey?.trim() },
    });
  },

  setRefreshToken(state, token) {
    return update(state, {
      refreshToken: { $set: token?.trim() },
    });
  },

  setData(state, data) {
    return update(state, {
      data: { $merge: data },
    });
  },

  setIsNewYearManager(state, isNewYearManager) {
    return update(state, {
      isNewYearManager: { $set: isNewYearManager },
    });
  },

  setRole(state, role = []) {
    return update(state, {
      role: { $set: role },
    });
  },

  setRelations(state, role = []) {
    return update(state, {
      relations: { $set: role },
    });
  },

  setSonsData(state, sonsData) {
    return update(state, {
      sonsData: { $set: sonsData },
    });
  },

  setInstitutions(state, institutions) {
    return update(state, {
      institutions: { $set: institutions },
    });
  },

  setInstitutionPeriods(state, institutionPeriods) {
    return update(state, {
      institutionPeriods: { $set: institutionPeriods },
    });
  },

  setSelectedInstitution(state, selectedInstitution) {
    return update(state, {
      selectedInstitution: { $set: selectedInstitution },
    });
  },

  setSelectedInstitutionPeriodId(state, selectedInstitutionPeriodId) {
    return update(state, {
      selectedInstitutionPeriodId: { $set: selectedInstitutionPeriodId },
    });
  },

  setActiveInstitutionPeriodId(state, activeInstitutionPeriodId) {
    return update(state, {
      activeInstitutionPeriodId: { $set: activeInstitutionPeriodId },
    });
  },

  setIsMultiRol(state, isMultiRol) {
    return update(state, {
      isMultiRol: { $set: isMultiRol },
    });
  },

  updateUserIs(state, { userIs, selectedRole }) {
    return update(state, {
      userIs: { $set: userIs },
      selectedRole: { $set: selectedRole },
    });
  },

  // Notifications reducers
  setNotifications(state, payload) {
    return update(state, {
      notifications: {
        data: { $set: payload.data },
        pagination: { $set: payload.pagination },
      },
    });
  },

  setHasNotifications(state, payload) {
    return update(state, {
      notifications: {
        $merge: {
          hasUnreadNotifications: payload.data.total_pending_viewed > 0,
        },
      },
    });
  },

  setLazyNotifications(state, payload) {
    return update(state, {
      notifications: {
        data: { $push: payload.data },
        pagination: { $set: payload.pagination },
      },
    });
  },

  setNotificationAsRead(state, notificationId) {
    return update(state, {
      notifications: {
        pagination: { $set: state.notifications.pagination },
        data: {
          $apply: (notifications) =>
            notifications.map((notification) => {
              if (notification.id === notificationId) {
                return {
                  ...notification,
                  read: true,
                  viewed: true,
                };
              }

              return notification;
            }),
        },
      },
    });
  },

  updateTokenExpired(state, isValid) {
    return update(state, {
      isTokenExpired: { $set: isValid },
    });
  },

  setIsWebView(state, isWebView) {
    return update(state, {
      isWebView: { $set: isWebView },
    });
  },

  setIsFirstLogin(state, firstLogin) {
    if (!firstLogin) removeLocalStorageKey(BLENDED_STORAGE_KEYS.FIRST_LOGIN);

    return update(state, {
      isFirstLogin: { $set: firstLogin },
    });
  },

  setHasTrainingUser(state, hasTrainingUser) {
    return update(state, {
      hasTrainingUser: { $set: hasTrainingUser },
    });
  },

  setTourFinished(state, tour) {
    return update(state, {
      tours: {
        [tour]: { $set: false },
      },
    });
  },

  setTourGradingFinished(state, tour, step) {
    return update(state, {
      tours: {
        [tour]: { [step]: { $set: false } },
      },
    });
  },

  setStateTourGrading(state) {
    return update(state, {
      tours: {
        $set: {
          ...state.tours,
          grading: {
            stepInit: true,
            stepWithNotes: true,
            stepWithoutNotes: true,
            stepAddNotes: true,
          },
        },
      },
    });
  },

  setYearPassDialog(state, value) {
    return update(state, {
      $set: {
        ...state,
        yearPassDialog: value,
      },
    });
  },

  setCalendarView(state, view) {
    return update(state, {
      preferences: {
        calendarView: { $set: view },
      },
    });
  },

  initChangelog(state) {
    const isTheSameUser = state.changelog.changelogUserId === state.data.id;

    const dynamicProps = {
      isSeen: { $set: false },
      counter: { $set: 1 },
      isClosed: { $set: false },
    };
    return update(state, {
      changelog: {
        changelogUserId: { $set: state.data.id },
        ...(!isTheSameUser ? dynamicProps : {}),
      },
    });
  },

  setChangelogSeen(state, isSeen) {
    const changelogSeen = Boolean(isSeen) || state.changelog.counter >= 3;
    return update(state, {
      changelog: {
        isSeen: { $set: changelogSeen },
        isClosed: { $set: true },
        counter: { $set: state.changelog.counter + 1 },
        changelogUserId: { $set: state.data.id },
      },
    });
  },

  setWebinarSeen(state, { isSeen, date }) {
    return update(state, {
      webinar: {
        isSeen: { $set: isSeen },
        date: { $set: date },
      },
    });
  },

  setLocale(state, { isLoaded, locale }) {
    return update(state, {
      $set: {
        ...state,
        userLocale: { isLoaded, locale },
      },
    });
  },

  setNewInstitutionPeriod(state, value = {}) {
    return update(state, {
      newInstitutionPeriod: { $set: value },
    });
  },
};

export default reducers;
