import { useSetAtom } from 'jotai';
import { useHistory } from 'react-router';

import { selectedContactsAtom, sendSubjectAtom } from '../../atoms';
import router from 'src/shared/utils/router';

const useSendMessageToUser = () => {
  const history = useHistory();
  const setSelectedContacts = useSetAtom(selectedContactsAtom);
  const setSendSubject = useSetAtom(sendSubjectAtom);

  const sendMessageToUser = (users, title) => {
    const contacts = Array.isArray(users) ? users : [users];
    setSelectedContacts(contacts);
    setSendSubject(title);
    history.push(router.communication.inbox.newMessage);
  };

  return { sendMessageToUser };
};

export default useSendMessageToUser;
