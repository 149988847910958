import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { importStatusBatchTransaction } from 'src/shared/services/url/UrlPaymentManagement';
import { Api as api } from 'src/shared/services/api';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { FINISHED_IMPORT_STATUS } from 'src/features/payment-management/constants/paymentImport';

const useImportStatusBatchTransaction = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const mutationFn = async ({ transactionId }) =>
    api.get({
      url: importStatusBatchTransaction(transactionId),
    });

  const onSuccess = ({ data }) => {
    if (data.status === FINISHED_IMPORT_STATUS) {
      queryClient.invalidateQueries([QUERY_KEY.INVOICES]);
      queryClient.invalidateQueries([QUERY_KEY.INVOICE_DOWN_PAYMENTS]);
      queryClient.invalidateQueries([QUERY_KEY.FAMILY_DETAILS]);
      queryClient.invalidateQueries([QUERY_KEY.PAYMENTS_OVERVIEW]);
    }
  };

  const onError = (error) => {
    const { message } = error;
    enqueueSnackbar(message ?? t`Ha ocurrido un problema.`, {
      variant: 'error',
    });
  };

  return useMutation({
    mutationFn,
    onSuccess,
    onError,
  });
};

export default useImportStatusBatchTransaction;
