import React, { memo, useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
  InputBase,
  Fade,
  ClickAwayListener,
} from '@mui/material';
import { Search as SearchIcon, X as CloseIcon } from 'react-feather';
import clsx from 'clsx';
import { t } from '@lingui/macro';
import { useDebouncedCallback } from 'use-debounce';

import useStyles from './styles';
import SearchResults from '../SearchResults';
import useSearch from 'src/shared/hooks/data/useSearch';
import searchFilters from 'src/shared/layouts/MainLayout/TopBar/Search/SearchResults/SearchSuggestions/filters';

const getFilterPlaceholder = (filter = {}) => {
  switch (filter.slug) {
    case 'users':
      return t`Buscar personas`;

    case 'posts':
      return t`Buscar publicaciones`;

    case 'events':
      return t`Buscar eventos`;

    default:
      return t`Buscar publicaciones, eventos y personas`;
  }
};

const SearchBar = ({ searchTerm = '', onClose }) => {
  const filterButtonRef = useRef();
  const defaultInputPlaceholder = getFilterPlaceholder();
  const classes = useStyles({ input: filterButtonRef.current });
  const closeRef = useRef(null);
  const inputRef = useRef(null);
  const [inputText, setInputText] = useState(searchTerm);
  const [query, setQuery] = useState(searchTerm);
  const [activeFilter, setActiveFilter] = useState(searchFilters[0]);
  const [inputClasses, setInputClasses] = useState(classes.searchBarInput);
  const [inputPlaceholder, setInputPlaceholder] = useState(
    defaultInputPlaceholder,
  );

  useEffect(() => {
    setInputPlaceholder(getFilterPlaceholder(searchFilters[0]));
    setInputClasses(
      clsx(classes.searchBarInput, classes[searchFilters[0].className]),
    );
  }, [classes]);

  const debouncedSearch = useDebouncedCallback((value) => {
    setQuery(value);
  }, 500);

  const { data, isFetching } = useSearch(query, {
    filter: activeFilter?.slug,
    expand: 'alias,custom_role,authAssignments',
  });

  const handleSelectFilter = (filter) => {
    const filterPlaceholder = getFilterPlaceholder(filter);

    setActiveFilter(filter);
    setInputPlaceholder(filterPlaceholder);
    setInputClasses(clsx(classes.searchBarInput, classes[filter.className]));
    inputRef.current.focus();
  };

  const handleRemoveFilter = () => {
    setActiveFilter(null);
    setInputPlaceholder(defaultInputPlaceholder);
    setInputClasses(classes.searchBarInput);
    setInputText('');
    setQuery('');
  };

  const handleSearch = useCallback(
    (event) => {
      setInputText(event.target.value);
      debouncedSearch(event.target.value);
    },
    [debouncedSearch],
  );

  const handleKeyDown = (event) => {
    const searchValue = event.target.value;
    const isBackSpace = event.keyCode === 8;
    if (!searchValue.length && isBackSpace) {
      handleRemoveFilter();
    }
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={classes.container}>
        <Fade in={Boolean(!activeFilter)}>
          <SearchIcon className={classes.searchIcon} size={18} />
        </Fade>

        <Fade in={Boolean(activeFilter)}>
          <Button
            ref={filterButtonRef}
            className={classes.closeFilterButton}
            variant="contained"
            onClick={handleRemoveFilter}
            disableElevation
          >
            {activeFilter?.name}
            <CloseIcon className={classes.closeFilterIcon} size={16} />
          </Button>
        </Fade>

        <InputBase
          fullWidth
          value={inputText}
          placeholder={inputPlaceholder}
          classes={{
            root: classes.searchBarContainer,
            input: inputClasses,
          }}
          autoFocus
          inputProps={{ 'aria-label': inputPlaceholder }}
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
          inputRef={inputRef}
        />

        {isFetching && (
          <CircularProgress size={15} className={classes.loading} />
        )}

        <Tooltip title={t`Cerrar`}>
          <IconButton
            className={classes.closeButton}
            ref={closeRef}
            onClick={onClose}
            size="large"
          >
            <CloseIcon className={classes.closeIcon} size={18} />
          </IconButton>
        </Tooltip>

        {((!activeFilter && !inputText.length) || data) && (
          <SearchResults
            onSelectFilter={handleSelectFilter}
            activeFilter={activeFilter}
            results={data}
            searchQuery={inputText}
            loading={isFetching}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

SearchBar.propTypes = {
  searchTerm: PropTypes.string,
  onClose: PropTypes.func,
};

export default memo(SearchBar);
