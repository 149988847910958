import createRouter from './createRouter';
import mergeRouters from './mergeRouters';

/////////////////////////////////////////////////
// TODO: Estas son legacy routes, agregar prefix /integraciones cuando se migre el modulo
const reportCard = createRouter('reportCard', {
  root: `/boletines`,
});

// TODO: Estas son legacy routes, agregar prefix /integraciones cuando se migre el modulo
const officialReportsRouter = createRouter('officialReports', {
  root: `/reportes`,
});

/////////////////////////////////////////////////

const primaryReportsRouter = createRouter('primaryReports', {
  root: `/informes`,
  history: `/informes/historial`,
});

const attendanceRouter = createRouter('attendance', {
  root: `/integraciones/asistencias`,
  subject: `/integraciones/asistencias/materia`,
  total: `/integraciones/asistencias/totales`,
});

const gradingRouter = createRouter('grading', {
  root: `/integraciones/calificaciones`,
  general: `/integraciones/calificaciones/general`,
  add: `/integraciones/calificaciones/agregar`,
  send: `/integraciones/calificaciones/envio`,
  reportCard: `/integraciones/calificaciones/boletines`,
  reportCardAttendances: `/integraciones/calificaciones/boletines/asistencias`,
  reportCardComments: `/integraciones/calificaciones/boletines/observaciones`,
  reportCardSignatures: `/integraciones/calificaciones/boletines/firmas`,
  reportCardConcepts: `/integraciones/calificaciones/boletines/conceptos`,
  reportCardPendingSubjects: `/integraciones/calificaciones/boletines/asignaturas-pendientes`,
});

const virtualClassroom = createRouter('virtualClassroom', {
  root: `/integraciones/tareas`,
  questions: `/integraciones/tareas/preguntas`,
  students: `/integraciones/tareas/alumnos`,
  scheduled: `/integraciones/tareas/programadas`,
  task: (taskId) => `/integraciones/tareas/${taskId}`,
  question: (taskId) => `/integraciones/tareas/preguntas/${taskId}`,
});

const documentsRouter = createRouter('documents', {
  root: `/integraciones/documentos`,
  module: (id) => `/integraciones/documentos/${id}`,
});

const routers = mergeRouters([
  virtualClassroom,
  attendanceRouter,
  gradingRouter,
  reportCard,
  primaryReportsRouter,
  officialReportsRouter,
  documentsRouter,
]);

export default routers;
