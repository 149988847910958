const QUERY_KEY = {
  USE_USERS_SEARCH: 'useUsersSearch',
  INSTITUTION_PERIODS: 'InstitutionPeriods',
  USER_SONS: 'UserSons',
  USER_PARENTS: 'UserParents',
  USER_ROLES: 'UserRoles',
  USER_MODULES: 'UserModules',
  EXTERNAL_LINKS: 'ExternalLinks',
  REPORT_BATCH: 'ReportBatch',
  INSTITUTION_CONFIG: 'InstitutionConfig',
  INSTITUTION_USERS: 'InstitutionUsers',
  LEVEL_PERIOD_BY_ID: 'LevelPeriodById',
  INSTITUTION_USER: 'InstitutionUser',
  USER_SPECIAL_PERMISSION: 'UserSpecialPermission',
  STORE_REDIRECT_ID: 'StoreRedirectId',
  SIS_BATCH_TRANSACTION: 'SISBatchTransaction',
  USER_BATCH_TRANSACTION: 'UserBatchTransaction',
};

export default QUERY_KEY;
