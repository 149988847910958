import React from 'react';
import { Trans } from '@lingui/macro';
import { DashboardCustomizeOutlined as DashboardCustomizeOutlinedIcon } from '@mui/icons-material';

import useRoutes from '../useRoutes';
import { MANAGEMENT_ITEMS } from './constants';

const useIntegrationsConfig = () => {
  const { createRoutes } = useRoutes();

  const routes = createRoutes([
    {
      id: 'management',
      title: <Trans>Gestión académica</Trans>,
      enabled: true,
      items: MANAGEMENT_ITEMS,
    },
  ]);

  return {
    id: 'integrations',
    subheader: <Trans>Integraciones</Trans>,
    icon: <DashboardCustomizeOutlinedIcon size={18} />,
    prefix: 'integraciones',
    items: routes,
  };
};

export default useIntegrationsConfig;
