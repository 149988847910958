import createRouter from './createRouter';

const profileRouter = createRouter('me', {
  account: `/mi-cuenta`,
  attendances: `/mi-cuenta/inasistencias`,
  authorized: `/mi-cuenta/autorizados`,
  grading: `/mi-cuenta/calificaciones`,
  reportCard: `/mi-cuenta/boletines`,
  primaryReport: `/mi-cuenta/informes`,
  behavior: `/mi-cuenta/seguimiento`,
  password: `/mi-cuenta/contrasena`,
  notifications: `/mi-cuenta/notificaciones`,
  taxInformation: `/mi-cuenta/datos-facturacion`,
  createTaxInformation: (familyId) =>
    `/mi-cuenta/datos-facturacion/familia/${familyId}/crear`,
  updateTaxInformation: ({ familyId, taxId }) =>
    `/mi-cuenta/datos-facturacion/familia/${familyId}/editar/${taxId}`,
  language: `/mi-cuenta/language`,
});

export default profileRouter;
