import { useEffect } from 'react';
import { getDefaultStore, useAtom, useSetAtom } from 'jotai';
import { useParams, useRouteMatch } from 'react-router-dom';

import {
  paymentProvider,
  paymentStatus,
} from 'src/features/payment-management/constants/payments';
import {
  dlocalPaymentProcessErrorAtom,
  paymentOrderAtom,
} from 'src/features/payment-management/atoms';
import { useOpenDialog } from 'src/shared/hooks';
import { dialogs } from 'src/shared/constants';
import router from 'src/shared/utils/router';
import useUserInfo from './useUserInfo';
import usePaymentConfig from './data/usePaymentConfig';
import useGetPaymentOrderById from './data/dLocal/useGetPaymentOrderById';
import { CreatePaymentOrdersStore } from 'src/shared/atoms/stepWizard/stepWizardStore';

const useFixedPaymentDialog = () => {
  const defaultStore = getDefaultStore();
  const { isWebView } = useUserInfo();
  const { orderId } = useParams();
  const openDialog = useOpenDialog(dialogs.CREATE_PAYMENT_ORDERS_DIALOG);
  const setPaymentProcessError = useSetAtom(dlocalPaymentProcessErrorAtom, {
    store: CreatePaymentOrdersStore,
  });
  const isPaymentOnlinePath = useRouteMatch(
    router.collections.paymentOrderOnline(':orderId'),
  );
  const [paymentOrder, setPaymentOrder] = useAtom(paymentOrderAtom, {
    store: defaultStore,
  });

  const { data: financialConfig } = usePaymentConfig();

  const { provider, dlocal_payment_direct_enabled } = financialConfig ?? {};

  const shouldFixedDialogue =
    isWebView ||
    (isPaymentOnlinePath?.isExact && provider === paymentProvider.PAYCODE) ||
    (isPaymentOnlinePath?.isExact && !dlocal_payment_direct_enabled);

  const { data: paymentOrderData } = useGetPaymentOrderById(orderId, {
    enabled: shouldFixedDialogue,
  });

  useEffect(() => {
    if (shouldFixedDialogue && paymentOrderData && !paymentOrder?.id) {
      if (paymentOrder.status === paymentStatus.REJECTED) {
        setPaymentProcessError(paymentOrder.status_detail);
      } else {
        setPaymentOrder(paymentOrderData);
      }
      openDialog(true);
    }
  }, [
    shouldFixedDialogue,
    paymentOrder?.id,
    paymentOrderData,
    setPaymentOrder,
    openDialog,
    paymentOrder.status,
    paymentOrder.status_detail,
    setPaymentProcessError,
  ]);

  return shouldFixedDialogue;
};

export default useFixedPaymentDialog;
