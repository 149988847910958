import { QUERY_KEY } from 'src/features/my-account/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import { getScoresUrl } from 'src/shared/services/url/UrlSis';

import { STATUS_MAP } from 'src/features/grading/tables/GradingManagementTable/helpers';

const useStudentScore = (params) => {
  return useGetOne(
    [QUERY_KEY.STUDENT_SCORE, params],
    getScoresUrl(),
    {
      'assessment.status': STATUS_MAP.APPROVED.id,
      'assessment.visible': true,
      expand: 'assessment,subject,scoreValue',
      'per-page': 10000,
      sort: '-assessment.date',
      take_exam: true,
      ...params,
    },
    false,
    false,
    {
      enabled: Boolean(params.user_id) && Boolean(params.institution_period_id),
    },
  );
};

export default useStudentScore;
