import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemAvatar, Checkbox, IconButton } from '@mui/material';
import { X as CloseIcon } from 'react-feather';
import clsx from 'clsx';
import { useSetAtom } from 'jotai';

import parseExternalId from 'src/shared/utils/parseExternalId';
import UserAvatar from 'src/components/UserAvatar';

import { useOpenDialog } from 'src/shared/hooks';
import { dialogs } from 'src/shared/constants';
import { selectedUserInfoAtom } from 'src/features/my-account/atoms';

import UserResultRowSkeleton from './Skeleton';
import DisplayName from './DisplayName';
import useStyles from './styles';
import router from 'src/shared/utils/router';

const UserResultRow = memo(
  ({
    user = {},
    dense = true,
    loading,
    checked,
    onCheck,
    onRemove,
    clickable,
    disabledCheck = false,
    showCourse,
    formattedCourse,
    showAlias,
    ...rest
  }) => {
    const classes = useStyles();
    const { external_id } = user;
    const externalId = parseExternalId(external_id);

    const openUserInfoViewDialog = useOpenDialog(dialogs.USER_INFO_DIALOG);
    const setSelectedUserInfo = useSetAtom(selectedUserInfoAtom);

    const userProfileLink = externalId
      ? router.profile.profile(externalId)
      : '';
    const onClickUser = () => {
      setSelectedUserInfo(user);
      openUserInfoViewDialog(true);
    };

    return loading ? (
      <UserResultRowSkeleton dense={dense} />
    ) : (
      <ListItem
        component={clickable && userProfileLink ? 'a' : 'div'}
        className={clsx({
          [classes.userRow]: true,
          [classes.userRowDense]: dense,
        })}
        {...(clickable ? { onClick: onClickUser } : {})}
        {...(clickable && userProfileLink ? { button: true } : {})}
        {...rest}
      >
        {onCheck && (
          <Checkbox
            checked={checked}
            onChange={onCheck}
            disabled={disabledCheck}
            className={classes.checkbox}
          />
        )}
        <ListItemAvatar
          classes={{
            root: classes.listItemAvatarRoot,
          }}
        >
          <UserAvatar user={user} isClickable={!clickable} />
        </ListItemAvatar>
        <DisplayName
          user={user}
          showCourse={showCourse}
          showAlias={showAlias}
          formattedCourse={formattedCourse}
        />
        {onRemove && (
          <IconButton onClick={onRemove} size="large">
            <CloseIcon size={16} />
          </IconButton>
        )}
      </ListItem>
    );
  },
);

UserResultRow.displayName = 'UserResultRow';

UserResultRow.propTypes = {
  user: PropTypes.object,
  dense: PropTypes.bool,
  loading: PropTypes.bool,
  onCheck: PropTypes.func,
  onRemove: PropTypes.func,
  checked: PropTypes.bool,
  clickable: PropTypes.bool,
  disabledCheck: PropTypes.bool,
  showCourse: PropTypes.bool,
  showAlias: PropTypes.bool,
  formattedCourse: PropTypes.string,
};

export default UserResultRow;
