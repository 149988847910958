import { paymentOrganizationType } from 'src/shared/services/url/UrlPaymentManagement';
import { useGetList } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const useOrganizationTypes = ({ enabled = true, ...params } = {}) =>
  useGetList(
    [QUERY_KEY.ORGANIZATION_TYPES],
    paymentOrganizationType(),
    { ...params },
    true,
    false,
    { enabled },
  );

export default useOrganizationTypes;
