import React from 'react';
import PropTypes from 'prop-types';
import { Box, Fade } from '@mui/material';

import step1 from 'src/assets/images/changelog/02-23/step-1-background.png';
import step2 from 'src/assets/images/changelog/02-23/step-2-background.png';

const backgrounds = {
  0: step1,
  1: step2,
};

const ChangelogBackground = ({ currentStep }) => (
  <Fade in timeout={1000} key={currentStep}>
    <Box
      alt="Current step background"
      sx={{
        position: 'absolute',
        top: (theme) => theme.spacing(-4),
        left: (theme) => theme.spacing(-4),
        right: (theme) => theme.spacing(-4),
        bottom: (theme) => theme.spacing(-4),
        zIndex: 0,
        backgroundImage: `url(${backgrounds[currentStep]})`,
        transition: 'background-image 0.5s ease-in-out',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  </Fade>
);

ChangelogBackground.propTypes = {
  currentStep: PropTypes.number,
};

export default ChangelogBackground;
