import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { IconButton, Tooltip } from '@mui/material';
import { X as CloseIcon } from 'react-feather';

const DeleteFile = memo(({ file, onRemove }) => {
  return (
    <Tooltip title={<Trans>Eliminar</Trans>}>
      <IconButton
        aria-label={<Trans>Eliminar archivo</Trans>}
        onClick={() => onRemove(file, 'link' in file)}
        size="large"
      >
        <CloseIcon size={16} />
      </IconButton>
    </Tooltip>
  );
});

DeleteFile.displayName = 'DeleteFile';

DeleteFile.propTypes = {
  file: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default DeleteFile;
