import { useMemo } from 'react';
import { useParams } from 'react-router';
import { getDefaultStore, useAtom } from 'jotai';

import { authorizationsAtom } from 'src/features/my-account/atoms';
import useUserPickUp from 'src/features/my-account/hooks/data/useUserPickUp';
import useUserPickUpCategory from 'src/features/my-account/hooks/data/useUserPickUpCategory';
import useUserPickUpSelectedDetail from 'src/features/my-account/hooks/data/useUserPickUpSelectedDetail';
import useUserInfoView from 'src/features/my-account/hooks/useUserInfoView';
import useUserPickUpCategoryDay from 'src/features/my-account/hooks/data/useUserPickUpCategoryDay';
import useIsSchoolManagement from 'src/shared/hooks/useIsSchoolManagement';
import usePickupAccessRoles from 'src/features/pickups/hooks/usePickupAccessRoles';
import useModulesAreEnabled from 'src/shared/hooks/useModulesAreEnabled';
import { BLENDED_MODULES } from 'src/config/constants';
import { checkRole } from 'src/shared/helpers';

const useAuthorizatedInfo = (selectedUserId, enabled = true) => {
  const { userId } = useParams();
  const isSchoolManagement = useIsSchoolManagement();
  const pickupAccessRoles = usePickupAccessRoles();
  const pickupsIsEnabled = useModulesAreEnabled(BLENDED_MODULES.PICKUPS);

  const isEnabled =
    enabled &&
    isSchoolManagement &&
    pickupsIsEnabled &&
    checkRole(pickupAccessRoles) &&
    Boolean(selectedUserId || userId);

  const userParams = useMemo(
    () => ({ userId: selectedUserId || userId, enabled: isEnabled }),
    [isEnabled, selectedUserId, userId],
  );

  //OBTAINING DATA FROM ATOMs
  const defaultStore = getDefaultStore();
  const [
    {
      authorizatedSelected,
      modalAuthorizatedDetailReadOnly,
      modalAddAuthorized,
    },
    setAuthorizationsAtom,
  ] = useAtom(authorizationsAtom, {
    store: defaultStore,
  });

  //OBTAINING DATA FROM APIs
  const { data: userPickupData } = useUserPickUp(userParams);

  const {
    data: userPickUpCategoryData,
    isLoading: userPickUpCategoryIsLoading,
  } = useUserPickUpCategory(userParams);

  const {
    data: userPickUpCategoryDayData,
    isLoading: userPickUpCategoryDayIsLoading,
  } = useUserPickUpCategoryDay(userParams);

  const {
    data: authorizatedSelectedDetail,
    refetch: authorizatedSelectedDetailRefetch,
  } = useUserPickUpSelectedDetail(authorizatedSelected);

  const { userInfo } = useUserInfoView({ userId });

  //SETTING DATA
  const authorizatedUsersNames = useMemo(() => {
    const names = userPickupData
      ?.filter(({ active }) => active)
      .map((user) => user.authorized_person_name);

    return names?.join(', ') || '-';
  }, [userPickupData]);

  const pickUpCategoryNames = useMemo(() => {
    const names = userPickUpCategoryDayData?.map(
      ({ pickup_category, pickup_sub_category }) =>
        pickup_sub_category ? pickup_sub_category.name : pickup_category.name,
    );

    const clearList = [...new Set(names)];

    return clearList?.join(', ') || '-';
  }, [userPickUpCategoryDayData]);

  const categoriesData = useMemo(
    () =>
      userPickUpCategoryData?.filter(
        (category) =>
          category?.active &&
          category?.level_id === userInfo?.course[0]?.level_id,
      ),
    [userInfo, userPickUpCategoryData],
  );

  const setAuthorizedSelected = (user) => {
    setAuthorizationsAtom((prev) => ({
      ...prev,
      authorizatedSelected: user,
    }));
  };

  const setModalAuthorizatedDetailReadOnly = async (state, user) => {
    await setAuthorizationsAtom((prev) => ({
      ...prev,
      authorizatedSelected: user,
      modalAuthorizatedDetailReadOnly: state,
    }));
    if (state) authorizatedSelectedDetailRefetch();
  };

  const setModalAddAuthorized = (state, user) => {
    setAuthorizationsAtom((prev) => ({
      ...prev,
      authorizatedSelected: user,
      modalAddAuthorized: state,
    }));
  };

  return {
    authorizatedSelected,
    authorizatedSelectedDetail,
    authorizatedUsersNames,
    modalAddAuthorized,
    modalAuthorizatedDetailReadOnly,
    pickUpCategoryNames,
    categoriesData,
    userPickUpCategoryDayData,
    userPickupData,
    userPickUpCategoryIsLoading,
    userPickUpCategoryDayIsLoading,
    setAuthorizedSelected,
    setModalAddAuthorized,
    setModalAuthorizatedDetailReadOnly,
  };
};

export default useAuthorizatedInfo;
