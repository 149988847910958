import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material';

import TableActions from 'src/components/Table/TableActions';
import { getColumnValue } from 'src/components/Table/utils';

import useStyles from './styles';

const Body = ({
  items = [],
  columns = [],
  selectedItems = [],
  actions,
  onSelectOneItem,
  onClickItem,
  stickyFirstColumn,
  enableChecks,
  checkProps = {},
  TableRowComponent = TableRow,
  hover,
  onRowMouseEnter,
  onRowMouseLeave,
  loadingLastRow,
  checkPosition = 'start',
}) => {
  const classes = useStyles();
  const {
    disabled: checkboxPropsDisabled = () => {},
    selectedAllDisabled,
    ...restOfCheckProps
  } = checkProps;

  const handleMouseDown = (event, item) => {
    if (event.button === 1) {
      if (onClickItem) {
        onClickItem(item, true);
      }
    }
  };

  const handleItemClick = (event, item) => {
    if (onClickItem) {
      const alt = event.ctrlKey || event.button === 1;
      onClickItem(item, alt);
    }
  };

  return (
    <TableBody>
      {items.map((item, idx) => {
        const selectedItemsIds = selectedItems.map((selected) => selected.id);
        const isDisabled =
          typeof checkboxPropsDisabled === 'function'
            ? checkboxPropsDisabled(item)
            : checkboxPropsDisabled;
        const isItemSelected =
          selectedItemsIds.includes(item.id) && !isDisabled;

        // DO NOT USE!! DEPRECATED
        if (item.render) {
          // eslint-disable-next-line no-console
          console.warn(
            'item.render is deprecated, use TableRowComponente instead',
          );

          const Comp = item.render;
          const rendererProps = {
            item,
            items,
            actions,
            onSelectOneItem,
            isItemSelected,
            selectedItems,
          };

          return <Comp key={item.id ?? idx} {...rendererProps} />;
        }

        const Checks = () => {
          return (
            <TableCell padding="checkbox">
              <Checkbox
                inputProps={{ 'data-testid': 'table-checkbox' }}
                size="small"
                checked={isItemSelected}
                onClick={(e) => e.stopPropagation()}
                onChange={(event) => onSelectOneItem(event, item)}
                value={isItemSelected}
                // TODO: Find a better approach. Make a v2 table component soon
                {...restOfCheckProps}
                disabled={
                  typeof checkboxPropsDisabled === 'function'
                    ? checkboxPropsDisabled(item)
                    : checkboxPropsDisabled
                }
              />
            </TableCell>
          );
        };

        return (
          <TableRowComponent
            data-testid={`table-row-${item.id}`}
            key={item.id ?? idx}
            selected={items.indexOf(item.id ?? idx) !== -1}
            className={clsx({
              [classes.clickable]: onClickItem,
              [classes.tableRow]: true,
            })}
            onMouseDown={(event) => handleMouseDown(event, item)}
            onClick={(event) => handleItemClick(event, item)}
            row={{ ...item }}
            hover={hover}
            {...(onRowMouseEnter && {
              onMouseEnter: () => onRowMouseEnter(item),
            })}
            {...(onRowMouseLeave && {
              onMouseLeave: () => onRowMouseLeave(item),
            })}
            ref={item.ref || null}
          >
            {enableChecks && checkPosition === 'start' && <Checks />}

            {columns.map((column, index) => {
              const isFirstColumn = index === 0;
              const value = getColumnValue(item, column.key);

              return (
                <TableCell
                  align={column.align}
                  key={column.key}
                  className={clsx({
                    [classes.stickyColumn]: stickyFirstColumn && isFirstColumn,
                  })}
                  style={column.style}
                  {...column.props}
                >
                  {column.render
                    ? column.render(item, items)
                    : column.format?.(value) ?? value}
                </TableCell>
              );
            })}

            {actions && (
              <TableActions actions={actions} item={{ ...item, index: idx }} />
            )}
            {enableChecks && checkPosition === 'end' && <Checks />}
          </TableRowComponent>
        );
      })}
      {loadingLastRow && (
        <TableRow>
          <TableCell colSpan={columns?.length}>{loadingLastRow}</TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

Body.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedItems: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onClickItem: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      baseUrl: PropTypes.string,
      handler: PropTypes.func,
      icon: PropTypes.any,
    }),
  ),
  onSelectOneItem: PropTypes.func,
  stickyFirstColumn: PropTypes.bool,
  enableChecks: PropTypes.bool,
  checkProps: PropTypes.object,
  TableRowComponent: PropTypes.any,
  hover: PropTypes.bool,
  onRowMouseEnter: PropTypes.func,
  onRowMouseLeave: PropTypes.func,
  loadingLastRow: PropTypes.node,
};

export default Body;
