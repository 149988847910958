import { Api as api } from 'src/shared/services/api';

import {
  subjectLinks as subjectLinksUrl,
  singleSubjectLink as singleSubjectLinkUrl,
} from 'src/shared/services/url/UrlUser';

const effects = () => ({
  async getSelectedLink(subjectId) {
    const { data } = await api.get({
      url: subjectLinksUrl(subjectId),
      data: {
        is_favorite: 1,
      },
    });

    await this.setSelectedLink(data[0]);
  },

  async getLinks(subjectId) {
    this.toggleLinkLoadingStatus();

    const { data: raw } = await api.get({
      url: subjectLinksUrl(subjectId),
      getRaw: true,
      data: {
        'per-page': 40,
      },
    });

    this.setLinks(raw.data);
    this.toggleLinkLoadingStatus();
  },

  async createLink(subjectId, store, values) {
    const { data } = await api.post({
      url: subjectLinksUrl(subjectId),
      data: {
        name: values.name,
        link: values.url,
        is_favorite: false,
      },
    });

    await this.attachLink(data);
  },

  async editLink(subjectId, store, values) {
    const { data } = await api.put({
      url: singleSubjectLinkUrl(subjectId, values.id),
      data: {
        name: values.name,
        link: values.url,
        is_favorite: values.is_favorite,
      },
    });

    await this.updateLink(data);

    if (data.is_favorite) {
      await this.setSelectedLink(data);
    }
  },

  async addLinkToFavorite(subjectId, store, values) {
    const { data } = await api.put({
      url: singleSubjectLinkUrl(subjectId, values.id),
      data: {
        name: values.name,
        link: values.url,
        is_favorite: !values.is_favorite,
      },
    });

    await this.updateLink(data);
    await this.setSelectedLink(!values.is_favorite ? data : null);
  },

  async deleteLink(subjectId, store, linkId) {
    await api.delete({
      url: singleSubjectLinkUrl(subjectId, linkId),
    });

    await this.detachLink(linkId);

    if (store.subject.selectedLink?.id === linkId) {
      await this.setSelectedLink();
    }
  },
});

export default effects;
