import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    ...theme.typography.h4,
    lineHeight: 'unset',
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#fff',
  },
  avatarSmall: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    fontSize: 8,
  },
  avatarMedium: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 10,
  },
  avatarFullName: {
    fontSize: 14,
    marginLeft: theme.spacing(2),
  },
  isClickable: {
    cursor: 'pointer',
  },
}));

export default useStyles;
