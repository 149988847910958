/**
 * @author Andrelo
 * Defines how a serializer should be,
 * the serializer should be used in case a data transformation is needed,
 * either after receiving or before sending.
 */

const SerializerInterface = (serializer, deserializer) => {
  return {
    serialize: serializer,
    deserialize: deserializer,
  };
};

export default SerializerInterface;
