import { PMS_URL, BOF_URL, PMS_BILL_URL } from 'src/config/general';

export const paymentManagementRates = () => `${PMS_URL}/api/rate`;

export const paymentManagementConcepts = () => `${PMS_URL}/api/concept`;

export const paymentManagementConceptsById = (conceptId) =>
  `${PMS_URL}/api/concept/${conceptId}`;

export const paymentManagementUsers = (rateId) =>
  `${PMS_URL}/api/rate/${rateId}/user`;

export const paymentManagementRemoveUserFromRate = (userId) =>
  `${PMS_URL}/api/user/${userId}/rate/remove`;

export const paymentManagementUsersRemove = (rateId) =>
  `${PMS_URL}/api/rate/${rateId}/user/remove`;

export const batchDeleteRateUsers = (id) =>
  `${PMS_URL}/api/rate/batch/${id}/user`;

export const paymentManagementSingleRate = (rateId) =>
  `${PMS_URL}/api/rate/${rateId}`;

export const paymentNotifications = () =>
  `${PMS_URL}/api/transaction/payment-notification`;

export const paymentInvoices = () => `${PMS_URL}/api/invoice`;

export const singleInvoice = (invoiceId) =>
  `${PMS_URL}/api/transaction/payment-notification/${invoiceId}`;

export const paySingleInvoice = (invoiceId) =>
  `${PMS_URL}/api/transaction/payment-notification/${invoiceId}/payment`;

export const paySingleInvoiceHistory = (invoiceId) =>
  `${PMS_URL}/api/transaction/payment-notification/${invoiceId}/payment-orders`;

export const paymentManagementConfig = () => `${PMS_URL}/api/config`;

export const updatePaymentManagementConfig = (id) =>
  `${PMS_URL}/api/config/${id}`;

export const paymentManagementBootstrap = () => `${PMS_URL}/api/wizard`;

export const paymentManagementSingleConfig = (configId) =>
  `${PMS_URL}/api/config/${configId}`;

export const paymentManagementFamilies = () => `${PMS_URL}/api/family`;

export const paymentManagementFamiliesReport = () =>
  `${PMS_URL}/api/report/balance-by-family-report`;

export const paymentManagementSingleFamily = (familyId) =>
  `${PMS_URL}/api/family/${familyId}`;

export const paymentManagementFamilyUsers = (familyId) => `
  ${PMS_URL}/api/family/${familyId}/user
`;

export const paymentManagementInvoice = () => `${PMS_URL}/api/invoice`;

export const paymentManagementInvoiceDelete = () =>
  `${PMS_URL}/api/invoice/delete`;

export const paymentManagementDeleteDraftInvoice = () =>
  `${PMS_URL}/api/transaction/init/delete`;

export const singlePaymentManagementInvoice = (invoiceId) =>
  `${PMS_URL}/api/invoice/${invoiceId}`;

export const paymentManagementVariation = () => `${PMS_URL}/api/variation`;

export const paymentManagementVariationUser = (variationId) =>
  `${PMS_URL}/api/variation/${variationId}/user`;

export const paymentManagementSingleVariation = (variationId) =>
  `${PMS_URL}/api/variation/${variationId}`;

export const paymentManagementVariationUsersRemove = (variationId) =>
  `${PMS_URL}/api/variation/${variationId}/user/remove`;

export const paymentManagementVariationFamily = (variationId) =>
  `${PMS_URL}/api/variation/${variationId}/family`;

export const paymentManagementVariationFamilyRemove = (variationId) =>
  `${PMS_URL}/api/variation/${variationId}/family/remove`;

export const paymentManagementInvoiceStatus = () =>
  `${PMS_URL}/api/invoice/change-status`;

export const payment = () => `${PMS_URL}/api/payment`;

export const invoicePayment = (paymentId) =>
  `${PMS_URL}/api/payment/${paymentId}/approve`;

export const invoicePaymentReject = (paymentId) =>
  `${PMS_URL}/api/payment/${paymentId}/reject`;

export const paymentMethod = () => `${PMS_URL}/api/payment-method`;

export const singleInvoicePayment = (paymentId) =>
  `${PMS_URL}/api/payment/${paymentId}`;

export const singlePaymentMethod = (paymentMethodId) =>
  `${PMS_URL}/api/payment-method/${paymentMethodId}`;

export const initTransaction = () => `${PMS_URL}/api/transaction/init`;

export const singleUserRates = (userId) => `${PMS_URL}/api/user/${userId}/rate`;

export const removeUserFromRate = (userId) =>
  `${PMS_URL}/api/user/${userId}/rate/remove`;

export const overviewBalancesUrl = () => `${PMS_URL}/api/report/total`;

export const invoiceResetInvoiceDraft = () =>
  `${PMS_URL}/api/invoice/clear-draft`;

export const currencyListUrl = () => `${PMS_URL}/api/currency`;

export const lastInvoiceUrl = () => `${PMS_URL}/api/invoice/last`;

export const lastPeriodInfoUrl = () =>
  `${PMS_URL}/api/invoice/last-period-info`;

export const assignPaymentMethod = () => `${PMS_URL}/api/payment-method/assign`;

export const removePaymentMethod = () => `${PMS_URL}/api/payment-method/remove`;

export const getInstitutionTaxInformation = () =>
  `${PMS_URL}/api/institution-tax-information?expand=tax_regime,cfdi_use`;

export const createTaxBillUrl = () => `${PMS_URL}/api/tax-bill`;
export const createTaxBillArgUrl = () => `${PMS_BILL_URL}/api/tax-bill`;

export const generateTaxBillPDF = (invoiceId) =>
  `${PMS_URL}/api/tax-bill/${invoiceId}/download`;

export const cancelledTaxBill = (invoiceId) =>
  `${PMS_URL}/api/tax-bill/${invoiceId}`;

export const satProductCode = () => `${PMS_URL}/api/tax-bill/sat-product-codes`;

export const salesPointUrl = () => `${PMS_URL}/api/afip/sales-point`;
export const salesPointArgUrl = () => `${PMS_BILL_URL}/api/afip/sales-point`;

export const receiptTypeUrl = () => `${PMS_URL}/api/afip/receipt-type`;
export const receiptTypeArgUrl = () => `${PMS_BILL_URL}/api/afip/receipt-type`;

export const serviceTypeUrl = () => `${PMS_URL}/api/afip/service-type`;
export const serviceTypeArgUrl = () => `${PMS_BILL_URL}/api/afip/service-type`;

export const totalPaidByPaymentMethodUrl = () =>
  `${PMS_URL}/api/report/total-paid-by-payment-method`;

export const invoiceUnpaid = () => `${BOF_URL}/invoice/unpaid`;

export const insitutionGeneratePaymentRequest = () =>
  ` ${BOF_URL}/payments/request`;

export const taxRegimeUrl = () => `${PMS_URL}/api/tax-regime`;

export const taxIdentificationUrl = () => `${PMS_URL}/api/tax-identification`;

export const CFDIUrl = (id) => `${PMS_URL}/api/facturama/cfid-use?rfc=${id}`;

export const paymentMethodUrl = () => `${PMS_URL}/api/payment-method`;

export const personTypeUrl = () => `${PMS_URL}/api/tax-person-type`;

export const familyTaxInformationUrl = () =>
  `${PMS_URL}/api/family-tax-information`;

export const singleFamilyTaxInformationUrl = (userId) =>
  `${PMS_URL}/api/family-tax-information/${userId}`;

export const updateInstitutionTaxInformationByIdUrl = (id) =>
  `${PMS_URL}/api/institution-tax-information/${id}`;

export const getInstitutionTaxInformationUrl = () =>
  `${PMS_URL}/api/institution-tax-information`;

export const createInstitutionTaxInformationUrl = () =>
  `${PMS_URL}/api/institution-tax-information`;

export const downloadCsr = (institutionId) =>
  `${PMS_URL}/api/institution-tax-information/${institutionId}/download-csr`;

export const getInfoInstitution = (institution_id) =>
  `${BOF_URL}/institution?institution_id=${institution_id}`;

export const updateInfoInstitution = (institution_id) =>
  `${BOF_URL}/institution/${institution_id}`;

export const paymentTaxServiceStatusUrl = () =>
  `${PMS_URL}/api/report/payment-notification-tax-service-status`;

export const paymentCheckGenerateInvoice = (id) =>
  `${PMS_URL}/api/tax-bill/batch/${id}`;

export const paymentInvoiceBatchById = (id) =>
  `${PMS_URL}/api/invoice/batch/${id}`;

export const paymentOrganizationType = () =>
  `${PMS_URL}/api/alegra/organization-types`;

export const paymentIdentificationType = () =>
  `${PMS_URL}/api/alegra/identification-types`;

export const getDepartments = () => `${PMS_URL}/api/alegra/departments`;

export const getCities = () => `${PMS_URL}/api/alegra/municipalities`;

export const paymentOrders = () => `${PMS_URL}/api/payment-orders`;

export const dLocalAccount = () => `${PMS_URL}/api/dlocal-account`;

export const dLocalAccountInformation = () =>
  `${PMS_URL}/api/dlocal-account/account-information`;

export const getDLocalBankAccountInformation = () =>
  `${PMS_URL}/api/dlocal-account/get-bank-account`;

export const getDLocalBankAccountBalance = () =>
  `${PMS_URL}/api/dlocal-account/get-balances`;

export const getDLocalBankAccountPayoutBalance = () =>
  `${PMS_URL}/api/dlocal-account/get-payout-balance`;

export const getDLocalAccountPayoutInformation = () =>
  `${PMS_URL}/api/dlocal-account/get-payout-information`;

export const getDLocalBankCodes = () =>
  `${PMS_URL}/api/dlocal-account/get-bank-code`;

export const createDLocalPayout = () =>
  `${PMS_URL}/api/dlocal-account/create-payout`;

export const dLocalBankAccountInformation = () =>
  `${PMS_URL}/api/dlocal-account/bank-account`;

export const getDLocalAccountDocuments = () =>
  `${PMS_URL}/api/dlocal-account/get-documents`;

export const dLocalAccountDocuments = () =>
  `${PMS_URL}/api/dlocal-account/document`;

export const dLocalAccountContactInformation = () =>
  `${PMS_URL}/api/dlocal-account/get-contact-information`;

export const createDLocalAccountContactInformation = () =>
  `${PMS_URL}/api/dlocal-account/contact-information`;

export const updateDLocalAccountContactInformation = () =>
  `${PMS_URL}/api/dlocal-account/update-contact-information`;

export const createDLocalAccountCompanyInformation = () =>
  `${PMS_URL}/api/dlocal-account/company-information`;

export const dLocalAccountDirectors = () =>
  `${PMS_URL}/api/dlocal-account/get-directors`;

export const updateDLocalDirectors = () =>
  `${PMS_URL}/api/dlocal-account/update-directors`;

export const createDLocalDirectors = () =>
  `${PMS_URL}/api/dlocal-account/directors`;

export const dLocalAccountCompanyInformation = () =>
  `${PMS_URL}/api/dlocal-account/get-company-information`;

export const updateDLocalAccountCompanyInformation = () =>
  `${PMS_URL}/api/dlocal-account/update-company-information`;

export const dLocalKYCAccount = () =>
  `${PMS_URL}/api/dlocal-account/account-kyc-information`;

export const dLocalPaymentOrders = () => `${PMS_URL}/api/payment-orders`;

export const dLocalPaymentMethods = () =>
  `${PMS_URL}/api/dlocal-payment-method`;

export const dLocalAttachPaymentMethod = (paymentOrderId) =>
  `${PMS_URL}/api/payment-orders/${paymentOrderId}/payment-method`;

export const dLocalPaymentOrderProcess = (paymentOrderId) =>
  `${PMS_URL}/api/payment-orders/${paymentOrderId}/process`;

export const invoicesReportUrl = () =>
  `${PMS_URL}/api/report/payment-notification-report`;

export const invoiceMonthlyReportUrl = () =>
  `${PMS_URL}/api/report/monthly-report`;

export const billingReportUrl = () =>
  `${PMS_URL}/api/report/tax-billing-report`;

export const invoiceAdjustment = (id) =>
  `${PMS_URL}/api/invoice/${id}/adjustment`;

export const externalEntity = () => `${PMS_URL}/api/external-entity`;

export const updateExternalEntity = (entityId) =>
  `${PMS_URL}/api/external-entity/${entityId}`;

export const familyExternalEntity = () =>
  `${PMS_URL}/api/family-external-entity`;

export const updateFamilyExternalEntity = (entityId) =>
  `${PMS_URL}/api/family-external-entity/${entityId}`;

export const familyTemplate = () =>
  `${PMS_URL}/api/family-external-entity/template`;

export const familyImportPayment = () =>
  `${PMS_URL}/api/family-external-entity/import-payment`;

export const familyExportPayment = () =>
  `${PMS_URL}/api/family-external-entity/export-payment-error`;

export const importStatusBatchTransaction = (transactionId) =>
  `${PMS_URL}/api/family-external-entity/status-batch-transaction/${transactionId}`;

export const invoiceImportTemplate = () => `${PMS_URL}/api/invoice/template`;

export const downloadConflictedTemplate = () =>
  `${PMS_URL}/api/invoice/template`;

export const importInvoice = () =>
  `${PMS_URL}/api/invoice/import-file-template`;

export const exportInvoiceError = () =>
  `${PMS_URL}/api/invoice/export-template-error`;

export const debtReminder = () => `${PMS_URL}/api/reminder`;

export const updateDebtReminder = (reminderId) =>
  `${PMS_URL}/api/reminder/${reminderId}`;

export const getPaymentsTransactions = (family_id) =>
  `${PMS_URL}/api/family/${family_id}/transaction`;

export const deleteInvoiceAdjustment = (invoiceId, adjusmentId) =>
  `${PMS_URL}/api/invoice/${invoiceId}/adjustment/${adjusmentId}`;

export const invoiceTotalSend = () => `${PMS_URL}/api/invoice/total-send`;

export const campaign = () => `${PMS_URL}/api/campaign`;

export const campaignById = (id) => `${PMS_URL}/api/campaign/${id}`;

export const campaignSend = (id) => `${PMS_URL}/api/campaign/${id}/send`;

export const campaignErrorFile = (id) =>
  `${PMS_URL}/api/campaign/${id}/error-file`;

export const campaignVariation = (id) =>
  `${PMS_URL}/api/campaign/${id}/variation`;

export const campaignVariationById = (id, variationId) =>
  `${PMS_URL}/api/campaign/${id}/variation/${variationId}`;

export const campaignTemplateUpload = () => `${PMS_URL}/api/invoice/campaign`;

export const setTermsAndConditions = () =>
  `${PMS_URL}/api/paycode/set-terms-conditions`;

export const getPaycodeAccount = () => `${PMS_URL}/api/paycode/get-account`;

export const getPaymentTotalInfo = () =>
  `${PMS_URL}/api/payment/get-total-information`;

export const downloadCashFlow = () =>
  `${PMS_URL}/api/report/payment-cash-flow-report`;

export const sendToParents = () => `${PMS_URL}/api/invoice/send-to-parent`;

export const paymentButton = () => `${PMS_URL}/api/payment-button`;

export const paymentManagementUsersBulk = (rateId) =>
  `${PMS_URL}/api/rate/${rateId}/user-bulk`;

export const paymentManagementRateBatch = (rateId) =>
  `${PMS_URL}/api/rate/batch/${rateId}`;

export const paymentManagementVariationUsersBulk = (variationId) =>
  `${PMS_URL}/api/variation/${variationId}/user-bulk`;

export const paymentManagementVariationBatch = (rateId) =>
  `${PMS_URL}/api/variation/batch/${rateId}`;

export const PaymentBatchTransactionsId = (batchId) =>
  `${PMS_URL}/api/report-batch-transaction/batch/${batchId}`;

export const rateReportUser = (rateId) =>
  `${PMS_URL}/api/rate/${rateId}/report-user`;

export const reportDueDate = () => `${PMS_URL}/api/rate/report-due-date`;

export const reportType = () => `${PMS_URL}/api/rate/report-total-type`;

export const reportPaidByStudent = () =>
  `${PMS_URL}/api/rate/report-paid-by-student`;
