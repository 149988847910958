import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Grid, Button, CircularProgress } from '@mui/material';
import { t, Trans } from '@lingui/macro';

import Form, { SubmitButton, TextField } from 'src/components/Form';
import ExternalLinkFormSchema from './schema';
import StructureFields from './StructureFields';
import useExternalLinkForm from '../../hooks/useExternalLinkForm';

const defaultValues = {
  link: '',
  name: '',
};

const ExternalLinkForm = ({
  onCancel,
  onSubmit,
  onDelete,
  submitText = t`Crear acceso`,
  selectedExternalLink,
  ...props
}) => {
  const { divisions, roles, levels, selectedStructure } =
    useExternalLinkForm(selectedExternalLink);

  if (!divisions.length) {
    return <CircularProgress size={16} />;
  }

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        ...defaultValues,
        ...selectedExternalLink,
      }}
      validationSchema={ExternalLinkFormSchema}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form gridProps={{ pt: 1 }}>
            <TextField
              withFloatingLabel
              label={t`Nombre del acceso`}
              name="name"
              autoComplete="off"
            />

            <TextField
              withFloatingLabel
              label={t`Url`}
              name="link"
              autoComplete="off"
            />

            <StructureFields
              initialValues={selectedStructure}
              roles={roles}
              levels={levels}
            />

            <Grid
              item
              container
              justifyContent={onDelete ? 'space-between' : 'flex-end'}
              alignItems="center"
              spacing={4}
            >
              {onDelete && (
                <Grid item xs>
                  <Button onClick={() => onDelete(values)}>
                    <Trans>Eliminar</Trans>
                  </Button>
                </Grid>
              )}

              <Grid item>
                <Button
                  variant="text"
                  color="primary"
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  <Trans>Cancelar</Trans>
                </Button>
              </Grid>

              <SubmitButton
                gridProps={null}
                variant="contained"
                color="primary"
              >
                {submitText}
              </SubmitButton>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

ExternalLinkForm.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
  }),
  onDelete: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ExternalLinkForm;
