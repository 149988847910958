const QUERY_KEY = {
  INVOICE_DOWN_PAYMENTS: 'InvoiceDownPayments',
  INVOICE_DOWN_PAYMENTS_HISTORY: 'InvoiceDownPaymentsHistory',
  TOTAL_PAID_BY_PAYMENT_METHOD: 'TotalPaidByPaymentMethod',
  FAMILY: 'Family',
  FAMILY_TAX_INFORMATION: 'FamilyTaxInformation',
  FAMILY_DETAILS: 'FamilyDetails',
  INSTITUTION_TAX_INFORMATION: 'InstitutionTaxInformation',
  INSTITUTION_TAX_INFORMATION_BY_ID: 'InstitutionTaxInformationById',
  USE_CFID_OPTIONS: 'useCfidOptions',
  PAYMENT_METHOD_OPTIONS: 'paymentMethodOptions',
  PAYMENT_METHOD_INSTITUTION_OPTIONS: 'paymentMethodInstitutionOptions',
  PERSON_TYPE_OPTIONS: 'PersonTypeOptions',
  DLOCAL_ACCOUNT_PAYOUT_INFORMATION: 'DLocalAccountPayoutInformation',
  TAX_IDENTIFICATION_OPTIONS: 'TaxIdentificationOptions',
  TAX_REGIME_OPTIONS: 'TaxRegimeOptions',
  INVOICES: 'Invoices',
  LAST_INVOICE: 'LastInvoice',
  LAST_PERIOD: 'LastPeriod',
  RATES: 'Rates',
  RATES_USER_BATCH: 'RatesUserBatch',
  DISCOUNTS: 'Discounts',
  DISCOUNT_SELECTED_MEMBERS: 'DiscountSelectedMembers',
  DISCOUNT_SELECTED_FAMILY_MEMBERS: 'DiscountSelectedFamilyMembers',
  CONCEPT_SELECTED_MEMBERS: 'ConceptSelectedMembers',
  CONCEPT_SELECTED_MEMBERS_PAGINATED: 'ConceptSelectedMembersPaginated',
  CONCEPTS: 'Concepts',
  DOWNLOAD_CSR: 'DownloadCsr',
  PAYMENT_TAX_SERVICE_STATUS: 'PaymentTaxServiceStatus',
  CREATE_TAX_BILL: 'CreateTaxBill',
  PAYMENT_GENERATE_INVOICE_PROGRESS: 'PaymentGenerateInvoiceProgress',
  PAYMENTS_OVERVIEW: 'PaymentsOverview',
  SALES_POINT: 'salesPoint',
  SERVICE_TYPE: 'ServiceType',
  RECEIPT_TYPE: 'ReceiptType',
  GENERATE_TAX_BILL_DOCUMENT: 'GenerateTaxBillDocument',
  PAYMENT_ORDER: 'PaymentOrder',
  PAYMENTS_MODULE_CONFIG: 'PaymentsModuleConfig',
  DLOCAL_ACCOUNT: 'DLocalAccount',
  DLOCAL_BANK_ACCOUNT: 'DLocalBankAccount',
  DLOCAL_BANK_ACCOUNT_BALANCE: 'DLocalBankAccountBalance',
  DLOCAL_BANK_ACCOUNT_PAYOUT_BALANCE: 'DLocalBankAccountPayoutBalance',
  DLOCAL_PAYMENT_ORDER: 'DLocalPaymentOrder',
  DLOCAL_ACCOUNT_DOCUMENTS: 'DLocalAccountDocuments',
  DLOCAL_ACCOUNT_CONTACT_INFORMATION: 'DLocalAccountContactInformation',
  DLOCAL_ACCOUNT_DIRECTORS: 'DLocalAccountDirectors',
  DLOCAL_ACCOUNT_COMPANY_INFORMATION: 'DLocalAccountCompanyInformation',
  DLOCAL_BANK_CODES: 'DLocalBankCodes',
  UPDATE_DLOCAL_ACCOUNT_CONTACT_INFORMATION:
    'UpdateDLocalAccountContactInformation',
  DLOCAL_ACCOUNT_KYC: 'DLocalAccountKYC',
  DLOCAL_PAYMENT_METHODS: 'DLocalPaymentMethods',
  USERS_PERMISSIONS: 'usersPermissions',
  INVOICE_BATCH_PROGRESS: 'InvoiceBatchProgress',
  FAMILY_INFO: 'FamilyInformation',
  MEMBERS_SEARCH: 'MembersSearch',
  UNPARENT_STUDENTS: 'UnparentStudents',
  ORGANIZATION_TYPES: 'OrganizationTypes',
  IDENTIFICATION_TYPES: 'IdentificationTypes',
  DEPARTMENTS: 'Departments',
  PAYMENT_CONFIG: 'PaymentConfig',
  CITIES: 'Cities',
  INVOICE_ADJUSTMENT: 'InvoiceAdjustment',
  EXTERNAL_ENTITY: 'ExternalEntity',
  FAMILY_EXTERNAL_ENTITY: 'FamilyExternalEntity',
  PAYMENT_DEBT_REMINDER: 'PaymentDebtReminder',
  PAYMENTS_TRANSACTIONS: 'PaymentsTransactions',
  PAYMENT_INVOICE_TOTAL_SEND: 'PaymentInvoiceTotalSend',
  CAMPAIGN: 'Campaign',
  CAMPAIGNS: 'Campaigns',
  CAMPAIGN_VARIATION: 'CampaignVariation',
  CAMPAIGN_VARIATIONS: 'CampaignVariations',
  DOWNLOAD_BILLING_REPORT: 'downloadBillingReport',
  PAYCODE_ACCOUNT: 'PaycodeAccount',
  PAYMENT: 'Payment',
  PAYMENT_TOTAL_INFO: 'PaymentTotalInfo',
  DOWNLOAD_BALANCE_FAMILY: 'DownloadBalanceFamily',
  PAYMENT_BUTTON: 'PaymentButton',
  DISCOUNT_USER_BATCH: 'DiscountUserBatch',
  SAT_PRODUCT_CODE: 'SatProductCode',
  PAYMENT_MANAGEMENT_FAMILY: 'paymentManagementFamily',
  PAYMENT_BATCH_TRANSACTION: 'PaymentBatchTransaction',
  BATCH_DELETE_RATE_USERS: 'BatchDeleteUsers',
};

export { QUERY_KEY };
