import { QUERY_KEY } from 'src/features/my-account/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import { updateUser } from 'src/shared/services/url/UrlUser';

const useUserCustomRole = ({ userId, enabled } = {}) => {
  return useGetOne(
    [QUERY_KEY.USER_CUSTOM_ROLE, userId],
    updateUser(userId),
    { expand: 'custom_role,custom_role_object,roles.division.course' },
    false,
    false,
    { enabled: enabled ?? Boolean(userId) },
  );
};

export default useUserCustomRole;
