import { useEffect, useState } from 'react';
import { getDefaultStore, useAtom, useAtomValue, useSetAtom } from 'jotai';

import useLocale from 'src/shared/hooks/useLocale';
import { getFieldStyle } from './helpers';
import {
  dlocalFormDataAtom,
  dlocalPaymentProcessErrorAtom,
  paymentOrderAtom,
  selectedDLocalPaymentMethodAtom,
} from 'src/features/payment-management/atoms';
import useDLocalPaymentOrderProcess from 'src/features/payment-management/hooks/data/dLocal/useDLocalPaymentOrderProcess';
import useCreateOnlinePaymentOrder from '../useCreateOnlinePaymentOrder';
import useGetDLocalAccountInformation from '../data/dLocal/useGetDLocalAccountInformation';
import { paymentStatus } from '../../constants/payments';

const dlocalForm = 'dlocal-form';
let dlocalInstance;
let panField;
let cvvField;
let expirationField;

const useDLocalSmartFields = () => {
  const defaultStore = getDefaultStore();
  const [submitting, setSubmitting] = useState(false);
  const [paymentOrder, setPaymentOrder] = useAtom(paymentOrderAtom, {
    store: defaultStore,
  });
  const selectedPaymentMethod = useAtomValue(selectedDLocalPaymentMethodAtom);
  const { nextStep } = useCreateOnlinePaymentOrder();
  const { mutateAsync: paymentOrderProcess } = useDLocalPaymentOrderProcess(
    paymentOrder.id,
  );
  const setPaymentProcessError = useSetAtom(dlocalPaymentProcessErrorAtom);
  const { data: dlocalAccountInfo } = useGetDLocalAccountInformation();
  const [dlocalFormData, setDLocalFormData] = useAtom(dlocalFormDataAtom);
  const { locale } = useLocale({ partIndex: 0 });
  const { locale: country } = useLocale({ partIndex: 1 });

  const { cardholder, cvv, pan, expiration } = dlocalFormData;
  const hasErrors =
    !pan?.complete ||
    !expiration?.complete ||
    !cvv?.complete ||
    !Boolean(cardholder?.value);

  const initializeSmartFields = () => {
    const form = document.getElementById(dlocalForm);

    dlocalInstance = window.dlocal(dlocalAccountInfo?.uid);

    const fields = dlocalInstance.fields({
      locale,
      country: country?.toUpperCase(),
    });

    panField = fields.create('pan', getFieldStyle('4111 1111 1111 1111'));

    cvvField = fields.create('cvv', getFieldStyle('123'));

    expirationField = fields.create('expiration', getFieldStyle());

    panField.mount(form.querySelector('#pan'));
    cvvField.mount(form.querySelector('#cvv'));
    expirationField.mount(form.querySelector('#expiration'));

    registerEvents(
      [panField, cvvField, expirationField],
      ['pan', 'cvv', 'expiration'],
    );
  };

  const registerEvents = (fields, fieldsNames) => {
    const form = document.getElementById(dlocalForm);

    fields.forEach(function (field, idx) {
      field.on('change', function ({ empty, error, complete }) {
        const element = form.querySelector(`#${fieldsNames[idx]}`);
        element.classList.remove('focus');
        setDLocalFormData((oldValue) => ({
          ...oldValue,
          [fieldsNames[idx]]: { empty, error, complete, hasFocus: true },
        }));
      });
      field.on('blur', function ({ empty, error, complete }) {
        const element = form.querySelector(`#${fieldsNames[idx]}`);
        element.classList.remove('focus');
        setDLocalFormData((oldValue) => ({
          ...oldValue,
          [fieldsNames[idx]]: { empty, error, complete, hasFocus: false },
        }));
      });

      field.on('focus', function ({ empty, error, complete }) {
        const element = form.querySelector(`#${fieldsNames[idx]}`);
        element.classList.remove('focus');
        setDLocalFormData((oldValue) => ({
          ...oldValue,
          [fieldsNames[idx]]: { empty, error, complete, hasFocus: true },
        }));
      });
    });

    panField.on('brand', function ({ brand }) {
      setDLocalFormData((oldValue) => ({
        ...oldValue,
        brand,
      }));
    });
  };

  const handleChangeCardholder = ({ target }) => {
    setDLocalFormData((oldValue) => ({
      ...oldValue,
      cardholder: {
        ...(oldValue.cardholder ?? {}),
        value: target.value,
        hasFocus: true,
      },
    }));
  };

  const handleFocusCardHolder = (event) => {
    setDLocalFormData((oldValue) => ({
      ...oldValue,
      cardholder: { ...oldValue?.cardholder, hasFocus: true },
    }));
  };

  const handleBlurCardHolder = (event) => {
    setDLocalFormData((oldValue) => ({
      ...oldValue,
      cardholder: { ...oldValue?.cardholder, hasFocus: false },
    }));
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      const result = await dlocalInstance.createToken(panField, {
        name: cardholder.value,
      });

      const { data } = await paymentOrderProcess({
        token: result.token,
        payment_method_type: selectedPaymentMethod?.type,
      });

      if (data.status === paymentStatus.REJECTED) {
        throw new Error(data?.status_detail);
      }
      setPaymentProcessError(null);
      setPaymentOrder(data);
      nextStep();
    } catch (error) {
      setPaymentProcessError(error);
      nextStep();
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (dlocalAccountInfo) {
      initializeSmartFields();
      setDLocalFormData({
        cardholder: { value: paymentOrder?.payer?.full_name, hasFocus: false },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dlocalAccountInfo]);

  return {
    paymentOrder,
    dlocalForm,
    submitting,
    dlocalFormData,
    hasErrors,
    setDLocalFormData,
    initializeSmartFields,
    handleChangeCardholder,
    handleFocusCardHolder,
    handleBlurCardHolder,
    handleOnSubmit,
  };
};

export default useDLocalSmartFields;
