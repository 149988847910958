import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { createFamilyInformation } from 'src/shared/services/url/UrlUser';
import { useCreate } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const ERROR_MESSAGE = t`Hubo un error al crear la familia`;

const useCreateFamilyInformation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useCreate(
    createFamilyInformation(),
    {
      onSuccess: () => {
        enqueueSnackbar(t`Información creada con éxito.`, {
          variant: 'success',
        });
      },
      onError: ({ message: errorMessage }) => {
        enqueueSnackbar(errorMessage ?? ERROR_MESSAGE, { variant: 'error' });
      },
      onSettled: () => {
        queryClient.invalidateQueries([QUERY_KEY.FAMILY_INFO], {
          refetchType: 'all',
        });
        queryClient.invalidateQueries([QUERY_KEY.UNPARENT_STUDENTS], {
          refetchType: 'all',
        });
      },
    },
    false,
  );
};

export default useCreateFamilyInformation;
