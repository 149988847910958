import { useGetList } from 'src/shared/services/api/query';
import { getConversation } from 'src/shared/services/url/UrlCommunication';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';

const useGetConversations = (params = {}) => {
  const filter = {
    subject: params.subject ?? '',
    users: params.users ?? '',
    without_seen: params.unreadFilter || '',
  };

  const KEY = params.unreadFilter
    ? QUERY_KEY.UNREAD_CONVERSATIONS_LIST
    : QUERY_KEY.CONVERSATIONS_LIST;

  return useGetList(
    [KEY, filter],
    getConversation(),
    {
      ...filter,
      expand: 'last_message,has_attachments,sender_id',
    },
    true,
    false,
  );
};

export default useGetConversations;
