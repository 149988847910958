import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Box, CardContent, Link, Typography } from '@mui/material';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import OpenGraphCard from 'src/components/OpenGraphCard';

import useStyles from './styles';

const PostCardContent = memo(
  ({ children, metadata, sanitize, TypographyProps, ...rest }) => {
    const classes = useStyles();
    const { site_url } = metadata ?? {};
    const isHomework = site_url?.includes('?task=');

    return (
      <CardContent classes={{ root: classes.root }} {...rest}>
        <Typography variant="body" component="p" {...TypographyProps}>
          <ConditionalWrapper
            condition={sanitize}
            wrapper={(html) => (
              <span
                // eslint-disable-next-line react/no-danger
                className={classes.sanitize}
                dangerouslySetInnerHTML={{
                  __html: html,
                }}
              />
            )}
          >
            {children}
          </ConditionalWrapper>
        </Typography>

        {metadata && (
          <Box pt={2}>
            <Link
              {...(isHomework ? {} : { href: site_url })}
              target="_blank"
              className={classes.link}
            >
              <OpenGraphCard graphData={metadata} />
            </Link>
          </Box>
        )}
      </CardContent>
    );
  },
);

PostCardContent.displayName = 'PostCardContent';

PostCardContent.propTypes = {
  children: PropTypes.string.isRequired,
  sanitize: PropTypes.bool,
  TypographyProps: PropTypes.object,
  metadata: PropTypes.object,
};

export default PostCardContent;
