import { useCallback } from 'react';
import { Trans, t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { Button, IconButton, ListItem, Tooltip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';

import useExternalLinks from 'src/shared/hooks/data/useExternalLinks';
import { useSetAtom } from 'jotai';

import {
  openExternalLinkDialogAtom,
  selectedExternalLinkAtom,
} from '../../atoms';

const AddLinkButton = () => {
  const setOpenExternalLinkDialog = useSetAtom(openExternalLinkDialogAtom);

  return (
    <ListItem>
      <Button
        startIcon={<AddIcon />}
        onClick={() => setOpenExternalLinkDialog(true)}
      >
        <Trans>Añadir</Trans>
      </Button>
    </ListItem>
  );
};

const useLinkSelector = () => {
  const setSelectedExternalLink = useSetAtom(selectedExternalLinkAtom);
  const setOpenExternalLinkDialog = useSetAtom(openExternalLinkDialogAtom);

  const {
    selectedInstitutionPeriodId,
    userIs,
    data: userData,
  } = useSelector((store) => store.user);

  const { data } = useExternalLinks({
    periodId: selectedInstitutionPeriodId,
  });

  const handleEditLink = useCallback(
    (item) => (event) => {
      event.preventDefault();
      event.stopPropagation();
      setSelectedExternalLink(item);
      setOpenExternalLinkDialog(true);
    },
    [setSelectedExternalLink, setOpenExternalLinkDialog],
  );

  const renderLinkAction = useCallback(
    (link) => (
      <Tooltip title={t`Editar acceso directo`}>
        <IconButton size="small" onClick={handleEditLink(link)}>
          <EditIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    ),
    [handleEditLink],
  );

  const enhanceLinks = useCallback(
    (links = []) => {
      const enhancedLinks = links.map((link) => ({
        id: link.id,
        href: link.link,
        title: link.name,
        external: true,

        ...((userIs.admin ||
          (userIs.executive && userData?.id === link.user_id)) && {
          action: renderLinkAction(link),
        }),
      }));

      if (!enhancedLinks.length) {
        enhancedLinks.push({
          id: 'empty',
          title: t`No hay accesos directos disponibles.`,
          disabled: true,
        });
      }

      if (userIs.admin || userIs.executive) {
        enhancedLinks.push({
          id: 'new',
          title: t`Añadir`,
          Component: AddLinkButton,
        });
      }

      return enhancedLinks;
    },
    [renderLinkAction, userIs, userData?.id],
  );

  return {
    id: 'institution-links',
    title: <Trans>Accesos directos</Trans>,
    enabled: true,
    items: enhanceLinks(data),
    defaultSelected: true,
  };
};

export default useLinkSelector;
