import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, SwipeableDrawer, Typography } from '@mui/material';
import { ChevronLeft as ArrowLeft } from 'react-feather';
import { Trans } from '@lingui/macro';
import { useHistory } from 'react-router';

import { useBreakpoints } from 'src/shared/hooks';
import ScrollBar from 'src/components/ScrollBar';
import router from 'src/shared/utils/router';
import useGetPaymentsModuleConfig from 'src/features/payment-management/hooks/useGetPaymentsModuleConfig';
import useIsFinancialManagement from 'src/shared/hooks/useIsFinancialManagement';

import useStyles from './styles';
import Tab from './Tab';
import { navConfigMyAccount, ITEM_TAX_INFORMATION_ID } from '../helpers';

const NavBar = ({ onToggle, isOpenDrawer }) => {
  const classes = useStyles();
  const history = useHistory();
  const isFinancialManagement = useIsFinancialManagement();
  const [institutionSelectorClasses, setInstitutionSelectorClasses] = useState(
    clsx(classes.institutionSelectorBox),
  );
  const [containerClasses, setContainerClasses] = useState(
    clsx(classes.institutionSelectorContainer),
  );
  const { isMobile } = useBreakpoints();
  const variant = isMobile ? 'temporary' : 'permanent';

  const { data: paymentsConfig } = useGetPaymentsModuleConfig({
    enabled: isFinancialManagement,
  });
  const isActiveModuleTaxBilling = paymentsConfig?.enable_tax_billing;

  const handleScroll = ({ target }) => {
    const scrollPosition = target.scrollTop;
    const hasFixedClass = institutionSelectorClasses.includes(
      classes.institutionSelectorBoxFixed,
    );
    if (scrollPosition > 0 && !hasFixedClass) {
      setInstitutionSelectorClasses(
        clsx(
          classes.institutionSelectorBox,
          classes.institutionSelectorBoxFixed,
        ),
      );
      setContainerClasses(
        clsx(
          classes.institutionSelectorContainer,
          classes.institutionSelectorContainerFixed,
        ),
      );
    } else if (scrollPosition <= 0 && hasFixedClass) {
      setInstitutionSelectorClasses(clsx(classes.institutionSelectorBox));
      setContainerClasses(clsx(classes.institutionSelectorContainer));
    }
  };

  const tabs = useMemo(() => {
    return navConfigMyAccount
      .map((nav) => ({
        ...nav,
        items: nav.items.filter(
          (item) =>
            item.id !== ITEM_TAX_INFORMATION_ID || isActiveModuleTaxBilling,
        ),
      }))
      .filter((nav) => nav.items.length > 0);
  }, [isActiveModuleTaxBilling]);

  const content = (
    <>
      <ScrollBar onScroll={(event) => handleScroll(event)}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          width="auto"
          className={containerClasses}
          sx={{
            overflowX: 'hidden',
          }}
        >
          <Box>
            <Button
              sx={{ width: '100%', display: 'flex' }}
              className={classes.buttonBack}
              onClick={() => {
                history.push(router.communication.newsfeed.root);
              }}
              startIcon={<ArrowLeft />}
            >
              <Typography varian="h3" fontSize={'14px'}>
                <Trans>Volver</Trans>
              </Typography>
            </Button>
          </Box>
          <Box>
            {tabs.filter(Boolean).map((tab) => (
              <Tab tab={tab} key={tab.id} />
            ))}
          </Box>
        </Box>
      </ScrollBar>
    </>
  );

  return (
    <SwipeableDrawer
      anchor="left"
      classes={{
        root: classes.modal,
        paper: classes.drawer,
      }}
      PaperProps={{
        sx: { width: 216 },
      }}
      onClose={onToggle}
      open={isOpenDrawer}
      onOpen={onToggle}
      variant={variant}
    >
      {content}
    </SwipeableDrawer>
  );
};

NavBar.propTypes = {
  onToggle: PropTypes.func,
  isOpenDrawer: PropTypes.bool,
};

export default NavBar;
