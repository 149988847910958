import React, { memo } from 'react';
import PropTypes from 'prop-types';

import DownloadFile from 'src/components/FileLightbox/actions/DownloadFile';

import { useSelector } from 'react-redux';
import { checkForCustomLink } from 'src/shared/utils/fileHelper';
import handleCustomLink from 'src/shared/utils/customLinks';
import DeleteFile from './DeleteFile';
import PreviewFile from './PreviewFile';
import ExternalFileButton from './ExternalFileButton';
import { CircularProgress } from '@mui/material';

const FileItemActions = memo(
  ({
    file,
    onRemove,
    downloadable,
    onShowPreview,
    canPreview,
    isExternal,
    onRemoveDisabled = false,
    loading = false,
  }) => {
    const { id, link, type_id, original_file_name, app_id } = file;
    const isCustomLink = checkForCustomLink(type_id);
    const { userDocumentNumber, appHashes } = useSelector(({ user }) => ({
      userDocumentNumber: user.data.document_number,
      appHashes: user.data.app_hashes,
    }));
    const fileAppHashes = appHashes.find((el) => el.app_id === app_id);

    const fileLink = isCustomLink
      ? handleCustomLink(link, userDocumentNumber, fileAppHashes)
      : link;

    if (loading) {
      return <CircularProgress size={20} sx={{ m: 2 }} />;
    }

    return (
      <>
        {onRemove && !onRemoveDisabled && (
          <DeleteFile file={file} onRemove={onRemove} />
        )}

        {downloadable && !isExternal && (
          <DownloadFile link={link} fileName={original_file_name} />
        )}

        {canPreview && onShowPreview && (
          <PreviewFile fileId={id} onShowPreview={onShowPreview} />
        )}

        {isExternal && <ExternalFileButton link={fileLink} />}
      </>
    );
  },
);

FileItemActions.displayName = 'FileItemActions';

FileItemActions.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    link: PropTypes.string,
    type_id: PropTypes.number,
    original_file_name: PropTypes.string,
    app_id: PropTypes.number,
  }),
  onRemove: PropTypes.func,
  onShowPreview: PropTypes.func,
  onClick: PropTypes.func,
  downloadable: PropTypes.bool,
  canPreview: PropTypes.bool,
  isExternal: PropTypes.bool,
  onRemoveDisabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default FileItemActions;
