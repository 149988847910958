// convert event model to calendar event

import { getColorByEventType } from 'src/features/calendar/helpers';

const eventToCalendarEvent = (event) => {
  const { start_datetime: start, end_datetime: end } = event;

  const calendarEvent = {
    ...event,
    allDay: event.all_day,
    color: event.color ?? getColorByEventType(event.event_type_id),
    start,
  };

  if (event.all_day === true) {
    return calendarEvent;
  }

  return { ...calendarEvent, end };
};

export default eventToCalendarEvent;
