import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { X as CloseIcon } from 'react-feather';

import useStyles from './styles';

const AlertDialog = ({
  title,
  description,
  children,
  onClose,
  onAccept,
  disabled,
  cancelVariant = 'text',
  confirmVariant = 'contained',
  showCloseButton = false,
  showCancelButton = true,
  cancelText = t`Cancelar`,
  confirmText = t`Confirmar`,
  buttonId,
  confirmButtonProps,
  isLoading,
  ...rest
}) => {
  const classes = useStyles();
  const isString = typeof children === 'string';
  return (
    <Dialog onClose={onClose} {...rest}>
      <Box
        pr={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.headerContent}
      >
        {title && (
          <Box
            px={3}
            py={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
            <Typography variant="body2" className={classes.dialogSubtitle}>
              {description}
            </Typography>
          </Box>
        )}
        {showCloseButton && (
          <IconButton onClick={onClose} edge="end" size="large">
            <CloseIcon size={16} />
          </IconButton>
        )}
      </Box>

      <DialogContent
        classes={{
          root: classes.dialogContent,
        }}
      >
        {isString ? (
          <Typography varinat="h2">{children}</Typography>
        ) : (
          <>{children}</>
        )}
      </DialogContent>

      <DialogActions
        classes={{
          root: classes.dialogActions,
        }}
      >
        {showCancelButton && (
          <LoadingButton
            loading={isLoading}
            onClick={onClose}
            disabled={disabled}
            variant={cancelVariant}
            color="primary"
          >
            {cancelText}
          </LoadingButton>
        )}

        <LoadingButton
          onClick={onAccept}
          disabled={disabled}
          color="primary"
          variant={confirmVariant}
          loading={isLoading}
          id={buttonId}
          {...confirmButtonProps}
        >
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  cancelVariant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  confirmVariant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  confirmButtonProps: PropTypes.object,
  buttonId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  isLoading: PropTypes.bool,
};

export default AlertDialog;
