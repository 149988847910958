import BrandingStep from './Steps/BrandingStep';
import AppStep from './Steps/AppStep';
import FeaturesStep from './Steps/FeaturesStep';
import BlogStep from './Steps/BlogStep';

export const steps = [BrandingStep, AppStep, FeaturesStep, BlogStep];

export const BACKGROUND_ORDERS = [
  'greenModule',
  'blueModule',
  'grayModule',
  'greenModule',
];
