import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import Table from '../Table';
import TableSkeleton from '../Table/TableSkeleton';
import LoadingComponent from '../../features/behaviour/components/LoadingComponent';

const InfiniteScrollTable = ({
  columns = [],
  items = [],
  loading,
  onClickItem,
  hasNextPage,
  onLoadMore,
  ...rest
}) => {
  const [tableRef, { rootRef }] = useInfiniteScroll({
    hasNextPage,
    onLoadMore,
  });
  const rows = useMemo(
    () =>
      items.map((item, index) => ({
        ...item,
        ...(index + 1 === items.length ? { ref: tableRef } : {}),
      })) || [],
    [items, tableRef],
  );

  return (
    <Table
      ref={rootRef}
      items={rows}
      columns={columns}
      loading={loading}
      onClickItem={onClickItem}
      loadingComponent={<TableSkeleton />}
      loadingLastRow={hasNextPage && <LoadingComponent />}
      elevation={0}
      {...rest}
    />
  );
};

InfiniteScrollTable.propTypes = {
  columns: PropTypes.array.isRequired,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onClickItem: PropTypes.func,
  hasNextPage: PropTypes.bool,
  onLoadMore: PropTypes.func.isRequired,
};

export default InfiniteScrollTable;
