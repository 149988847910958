import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  ClickAwayListener,
  Radio,
} from '@mui/material';
import { t } from '@lingui/macro';
import { ArrowDropDown } from '@mui/icons-material';
import clsx from 'clsx';
import ScrollBar from 'src/components/ScrollBar';
import UserAvatar from 'src/components/UserAvatar';

import useStyles from './styles';

const PublicationSelect = ({
  menuData = [],
  label = '',
  initialValue = [],
  selectAll,
  multiple = true,
  selectedItems = [],
  onSelectItem = () => {},
  disabled,
  type,
  ...props
}) => {
  const classes = useStyles();
  const selectAllValue = selectedItems.join(',');
  const [isOpen, setIsOpen] = useState(false);
  const scrollbarStyles = { maxHeight: 215, minWidth: 215 };
  const filteredSelectedItems = selectedItems.filter(
    (item) =>
      menuData.filter(
        (menuItem) =>
          parseInt(menuItem.id, 10) === parseInt(item, 10) &&
          !menuItem.optionGroup,
      ).length !== 0,
  );
  const selectValue = filteredSelectedItems.length ? filteredSelectedItems : '';

  const handleOpen = (value, event) => {
    if (!disabled) {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      setIsOpen((prev) => value ?? !prev);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onToggleAll = (event) => {
    handleOpen(true, event);
    const itemsIds = menuData
      .filter((item) => !item.optionGroup)
      .map((item) => item.id);
    return onSelectItem([...itemsIds]);
  };

  const onToggleOption = (id, event) => {
    handleOpen(true, event);
    onSelectItem([id]);
  };

  const handleRenderPlaceholder = () => {
    if (filteredSelectedItems.length === 1) {
      const item = menuData.find(
        (selectItem) => selectItem.id === filteredSelectedItems[0],
      );
      return item?.name;
    }
    return label;
  };

  const handleIconComponent = () => {
    if (filteredSelectedItems.length > 1) {
      return (
        <Avatar variant="rounded" className={classes.menuCount}>
          {filteredSelectedItems.length}
        </Avatar>
      );
    }
    return <ArrowDropDown />;
  };

  const isSelected = (itemId) =>
    filteredSelectedItems.length === menuData.length ||
    filteredSelectedItems
      .map((id) => parseInt(id, 10))
      .includes(parseInt(itemId, 10));

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClose}
    >
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        displayEmpty
        className={clsx({
          [classes.publicationSelectActive]: filteredSelectedItems.length > 0,
        })}
        classes={{
          select: classes.publicationSelectRoot,
        }}
        variant="outlined"
        value={selectValue}
        defaultValue=""
        renderValue={handleRenderPlaceholder}
        IconComponent={handleIconComponent}
        onClick={(e) => handleOpen(null, e)}
        open={isOpen}
        disabled={disabled}
        {...props}
      >
        <ScrollBar style={scrollbarStyles}>
          {selectAll && multiple && (
            <MenuItem
              key="all"
              value={selectAllValue}
              onClick={(e) => onToggleAll(e)}
              className={classes.menuItem}
            >
              <Checkbox
                checked={
                  filteredSelectedItems.length ===
                  menuData.filter((menuItem) => !menuItem.optionGroup).length
                }
              />
              <ListItemText primary={t`Todos`} />
            </MenuItem>
          )}

          {menuData.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              disabled={item.optionGroup}
              onClick={(e) => onToggleOption(item.id, e)}
              className={clsx({
                [classes.filterGroup]: item.optionGroup,
                [classes.menuItem]: !item.optionGroup,
                [classes.subItem]: item.isSubItem,
              })}
            >
              {!item.optionGroup &&
                (multiple ? (
                  <Checkbox checked={isSelected(item.id)} />
                ) : (
                  <Radio
                    color="primary"
                    checked={isSelected(item.id)}
                    className={clsx({ [classes.hiddenInput]: type === 'none' })}
                  />
                ))}
              {item.photo && <UserAvatar mr={1} size="medium" user={item} />}
              <ListItemText
                primary={item.name}
                primaryTypographyProps={{ noWrap: false }}
              />
            </MenuItem>
          ))}
        </ScrollBar>
      </Select>
    </ClickAwayListener>
  );
};

PublicationSelect.propTypes = {
  menuData: PropTypes.array.isRequired,
  label: PropTypes.string,
  initialValue: PropTypes.array,
  selectAll: PropTypes.bool,
  multiple: PropTypes.bool,
  selectedItems: PropTypes.array.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['none']),
};

export default PublicationSelect;
