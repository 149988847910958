import { atom } from 'jotai';

import externalLinkSerializer from './serializers/externalLinkSerializer';

export const openExternalLinkDialogAtom = atom(false);
export const openLinkedSonDialogAtom = atom(false);

export const selectedExternalLinkAtom = atom(null, (get, set, externalLink) => {
  const deserializedLink = externalLink
    ? externalLinkSerializer.deserialize(externalLink)
    : null;

  set(selectedExternalLinkAtom, deserializedLink);
});
