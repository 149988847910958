/**
 * Creates a router object with a dynamic schema.
 *
 * @template T
 * @template {string | number} K
 * @param {K} routerKey - The key for the router.
 * @param {T} routerSchema - The dynamic router schema.
 * @returns {T & { key: K }} A router object with the inferred schema and a key.
 */
const createRouter = (routerKey, routerSchema) => {
  return {
    ...routerSchema,
    key: routerKey,
  };
};

export default createRouter;
