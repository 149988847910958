import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { Api as api } from 'src/shared/services/api';
import {
  getAttendanceUrl,
  attendanceScaleUrl,
  attendanceShiftUrl,
  approveAttendancesUrl,
  attendanceTotalUrl,
  getAttendanceSubjectsUrl,
} from 'src/shared/services/url/UrlSis';
import useAvailableStructure from 'src/shared/hooks/useAvailableStructure';
import useTryCatch from 'src/shared/hooks/useTryCatch';
import { getUsersByDivisionsUrl } from 'src/shared/services/url/UrlUser';
import { getMembersBySubjectId } from 'src/shared/services/url/UrlVirtualClassroom';
import { ROLE_LIST } from 'src/config/general';
import {
  handleParseColumns,
  ATTENDANCE_DAY_DIALOG_COLUMNS,
  handleStatusUrl,
  handleSaveAttendanceUrl,
  handleParsedRows,
} from './helpers';

const useAttendance = () => {
  const location = useLocation();
  const {
    divisions,
    subjects,
    extracurriculars,
    levels,
    findDivisionById,
    highestRole,
  } = useAvailableStructure('attendance');
  const { enqueueSnackbar } = useSnackbar();
  const [generalAttendance, setGeneralAttendance] = useState();
  const [attendanceByDivision, setAttendanceByDivision] = useState();
  const [totalAttendances, setTotalAttendances] = useState();
  const [attendanceShifts, setAttendanceShifts] = useState();
  const [attendanceScales, setAttendanceScales] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [params, setParams] = useState();
  const { handleTryCatch } = useTryCatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState();
  const [activeScales, setActiveScales] = useState([]);
  const [activeAttendances, setActiveAttendances] = useState([]);
  const [savedActiveAttendances, setSavedActiveAttendances] = useState([]);
  const [activeShifts, setActiveShifts] = useState([]);
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [isSubject, setIsSubject] = useState();

  const formattedExtracurriculars = useMemo(
    () =>
      extracurriculars.map((el) => {
        const levelInfo =
          levels.find((level) => level.id === el.level_id) ?? {};
        return {
          ...el,
          subjectName: el.name,
          levelId: el.level_id,
          subjectId: el.id,
          divisionName: '-',
          divisionId: `${el.level_id}-extracurricular`,
          levelName: levelInfo.name ?? '-',
        };
      }),
    [extracurriculars, levels],
  );

  const fetchAttendancesStatus = useCallback(
    async (dataParams) => {
      setIsLoading(true);
      try {
        if (subjects.length || divisions.length) {
          const structureData = isSubject
            ? [...subjects, ...formattedExtracurriculars]
            : [...divisions];
          const { data } = await api.get({
            url: handleStatusUrl(isSubject),
            data: dataParams,
          });
          const parsedRows = handleParsedRows(data, structureData);
          setGeneralAttendance(parsedRows);
          setError(null);
        }
      } catch (apiError) {
        const { message } = apiError;
        setError(apiError);
        enqueueSnackbar(
          message ?? t`Hubo un problema intentando obtener las asistencias`,
          {
            variant: 'error',
          },
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      divisions,
      enqueueSnackbar,
      formattedExtracurriculars,
      isSubject,
      subjects,
    ],
  );

  const fetchAttendanceByDivision = useCallback(
    async (divisionId, date) => {
      try {
        const { data } = await api.get({
          url: getAttendanceUrl(),
          data: { division_id: divisionId, date, 'per-page': 1000 },
        });
        setAttendanceByDivision(data);
        setActiveAttendances(data);
        setSavedActiveAttendances(data);
        setError(null);
      } catch (apiError) {
        const { message } = apiError;
        setError(apiError);
        enqueueSnackbar(
          message ?? t`Hubo un problema intentando obtener las asistencias`,
          {
            variant: 'error',
          },
        );
      }
    },
    [enqueueSnackbar, setActiveAttendances],
  );

  const fetchAttendanceBySubject = useCallback(
    async (subjectId, date) => {
      try {
        const { data } = await api.get({
          url: getAttendanceSubjectsUrl(),
          data: { subject_id: subjectId, date, 'per-page': 1000 },
        });
        setAttendanceByDivision(data);
        setActiveAttendances(data);
        setSavedActiveAttendances(data);
        setError(null);
      } catch (apiError) {
        const { message } = apiError;
        setError(apiError);
        enqueueSnackbar(
          message ?? t`Hubo un problema intentando obtener las asistencias`,
          {
            variant: 'error',
          },
        );
      }
    },
    [enqueueSnackbar, setActiveAttendances],
  );

  const fetchAttendance = useCallback(
    async (elementId, date) => {
      if (isSubject) {
        await fetchAttendanceBySubject(elementId, date);
      } else {
        await fetchAttendanceByDivision(elementId, date);
      }
    },
    [fetchAttendanceByDivision, fetchAttendanceBySubject, isSubject],
  );

  const fetchUsersBySubjectId = useCallback(
    async (subjectId) => {
      try {
        const { data } = await api.get({
          url: getMembersBySubjectId(subjectId),
          data: { role: ROLE_LIST.STUDENT, 'per-page': 2000 },
        });
        return data.sort((a, b) => a.last_name.localeCompare(b.last_name));
      } catch ({ message }) {
        enqueueSnackbar(message, { variant: 'error' });
        return false;
      }
    },
    [enqueueSnackbar],
  );

  const fetchUsersByDivisions = useCallback(
    async ({ divisionsArray, roles, perPage = 2000 }) => {
      try {
        const { data } = await api.post({
          url: getUsersByDivisionsUrl(
            `expand=authAssignments&return_user_object=1&per-page=${perPage}`,
          ),
          data: {
            divisions: divisionsArray,
            roles,
          },
        });
        return data;
      } catch ({ message }) {
        enqueueSnackbar(message, { variant: 'error' });
        return false;
      }
    },
    [enqueueSnackbar],
  );

  const fetchAttendanceShiftByLevelId = useCallback(
    async (levelId) => {
      try {
        const { data } = await api.get({
          url: attendanceShiftUrl(),
          data: { level_id: levelId },
        });
        setActiveShifts(data);
        const parsedAttendanceShifts = handleParseColumns(
          data,
          ATTENDANCE_DAY_DIALOG_COLUMNS,
        );
        setAttendanceShifts(parsedAttendanceShifts);
        setError(null);
      } catch (apiError) {
        const { message } = apiError;
        setError(apiError);
        enqueueSnackbar(
          message ?? t`Hubo un problema intentando obtener las planillas`,
          {
            variant: 'error',
          },
        );
      }
    },
    [enqueueSnackbar, setActiveShifts],
  );

  const fetchAttendanceScale = useCallback(
    async (scaleParams) => {
      try {
        const { data } = await api.get({
          url: attendanceScaleUrl(),
          data: { 'per-page': 100, ...scaleParams },
        });
        setAttendanceScales(data);
        setActiveScales((oldValue) => [...oldValue, ...data]);
        setError(null);
      } catch (apiError) {
        const { message } = apiError;
        setError(apiError);
        enqueueSnackbar(
          message ?? t`Hubo un problema intentando obtener las escalas`,
          {
            variant: 'error',
          },
        );
      }
    },
    [enqueueSnackbar, setActiveScales],
  );

  const handleApproveAttendances = useCallback(
    async (divisionId) => {
      try {
        await api.post({
          url: approveAttendancesUrl(),
          data: { division_id: divisionId },
        });
        enqueueSnackbar(t`Asistencias enviadas con éxito`, {
          variant: 'success',
        });
        setError(null);
      } catch (apiError) {
        const { message } = apiError;
        setError(apiError);
        enqueueSnackbar(
          message ?? t`Hubo un problema intentando enviar las asistencias`,
          {
            variant: 'error',
          },
        );
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleSaveAttendances = useCallback(
    async (saveData) => {
      await api.post({
        url: handleSaveAttendanceUrl(isSubject),
        data: saveData,
      });
    },
    [isSubject],
  );

  const handleParseTotalAttendances = useCallback(
    (attendances) => {
      const clonedAttendances = [...attendances];
      return clonedAttendances.map((attendance) => {
        const divisionInfo = findDivisionById(attendance.division_id) ?? {};
        return { ...attendance, ...divisionInfo };
      });
    },
    [findDivisionById],
  );

  const fetchTotalAttendances = useCallback(
    (paramsData) =>
      handleTryCatch(
        async () => {
          setIsLoading(true);
          const { data } = await api.get({
            url: attendanceTotalUrl(),
            data: { ...paramsData, expand: 'user', 'per-page': 100 },
          });
          const parsedAttendances = handleParseTotalAttendances(data);
          setTotalAttendances(parsedAttendances);
          setError(null);
          setIsLoading(false);
        },
        t`Hubo un problema intentando obtener las asistencias totales`,
        (apiError) => {
          setError(apiError);
        },
      ),
    [handleParseTotalAttendances, handleTryCatch],
  );

  useEffect(() => {
    if (location) {
      const { pathname } = location;
      const isSubjectSection =
        pathname.includes('materia') || highestRole === ROLE_LIST.TEACHER;
      setIsSubject(isSubjectSection);
    }
  }, [location, highestRole]);

  useEffect(() => {
    if (params) {
      fetchAttendancesStatus(params);
    }
  }, [params, fetchAttendancesStatus]);

  return {
    isLoading,
    error,
    setGeneralAttendance,
    generalAttendance,
    totalAttendances,
    attendanceByDivision,
    fetchAttendanceByDivision,
    fetchUsersByDivisions,
    fetchAttendanceShiftByLevelId,
    fetchAttendancesStatus,
    fetchAttendanceScale,
    attendanceShifts,
    attendanceScales,
    params,
    setParams,
    handleApproveAttendances,
    handleSaveAttendances,
    fetchTotalAttendances,
    selectedDay,
    setSelectedDay,
    activeScales,
    setActiveScales,
    activeAttendances,
    setActiveAttendances,
    activeShifts,
    setActiveShifts,
    selectedShifts,
    setSelectedShifts,
    isDialogOpen,
    setIsDialogOpen,
    setAttendanceShifts,
    fetchAttendance,
    savedActiveAttendances,
    setSavedActiveAttendances,
    fetchUsersBySubjectId,
  };
};

export default useAttendance;
