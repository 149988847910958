import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { Button } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const UploadButton = memo(
  ({ disabled, getRootProps, onOpen, onClose, buttonLabel, buttonProps }) => {
    const isWebView = useSelector((state) => state?.user?.isWebView);
    return (
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AttachFileIcon />}
        disabled={disabled}
        onClick={onOpen}
        {...buttonProps}
        {...(isWebView
          ? getRootProps({
              onClick: onClose,
            })
          : {})}
      >
        {buttonLabel ?? <Trans>Añadir Material</Trans>}
      </Button>
    );
  },
);

UploadButton.displayName = 'UploadButton';

UploadButton.propTypes = {
  disabled: PropTypes.bool,
  getRootProps: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  buttonLabel: PropTypes.string,
  buttonProps: PropTypes.any,
};

export default UploadButton;
