import * as Yup from 'yup';
import { t } from '@lingui/macro';

const ExternalLinkFormSchema = Yup.object().shape({
  name: Yup.string().required(t`Este campo es requerido.`),
  link: Yup.string()
    .required(t`Este campo es requerido.`)
    .url(t`Debe ser una URL válida, por ejemplo: https://www.google.com`),
  roles: Yup.array().min(1, t`Debes seleccionar al menos un rol`),
  divisions: Yup.array().min(1, t`Debes seleccionar al menos una división`),
});

export default ExternalLinkFormSchema;
