import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import { ROLE_LIST } from 'src/config/general';

import { INSTITUTION_CONFIG_ITEMS, MANAGEMENT_CONFIG_ITEMS } from './constants';
import { BLENDED_MODULES } from 'src/config/constants';
import useGetPaymentsModuleConfig from 'src/features/payment-management/hooks/useGetPaymentsModuleConfig';
import useIsFinancialManagement from 'src/shared/hooks/useIsFinancialManagement';
import {
  getItemsCollections,
  getItemsCommunication,
} from 'src/features/sidebar/hooks/useConfigSelector/helpers';

const useConfigSelector = () => {
  const user = useSelector(({ user }) => ({
    isWebView: user.isWebView,
    apiKey: user.apiKey,
    institutionId: user.selectedInstitution?.id,
  }));
  const isFinancialManagement = useIsFinancialManagement();
  const userIsWebAndLoggedIn = Boolean(user.apiKey) && !user.isWebView;
  const { data: paymentsConfig } = useGetPaymentsModuleConfig({
    enabled: userIsWebAndLoggedIn && isFinancialManagement,
  });
  const isActiveModuleTaxBilling = paymentsConfig?.enable_tax_billing;

  const itemsCollections = getItemsCollections(isActiveModuleTaxBilling);
  const itemsCommunication = getItemsCommunication(user.institutionId);

  return [
    {
      id: 'institution-config',
      title: <Trans>Red</Trans>,
      enabled: true,
      roles: [ROLE_LIST.ADMIN, ROLE_LIST.EXECUTIVE, ROLE_LIST.PRECEPTOR],
      items: INSTITUTION_CONFIG_ITEMS,
    },
    {
      id: 'communication-config',
      title: <Trans>Community Hub</Trans>,
      enabled: true,
      roles: [ROLE_LIST.ADMIN],
      items: itemsCommunication,
    },
    {
      id: BLENDED_MODULES.PAYMENT_MANAGEMENT.id,
      title: <Trans>Financial Hub</Trans>,
      roles: [ROLE_LIST.ADMIN, ROLE_LIST.FINANCIAL_ADMIN],
      enabled: true,
      items: itemsCollections,
    },
    {
      id: 'management-config',
      title: <Trans>Gestión académica</Trans>,
      enabled: true,
      roles: [
        ROLE_LIST.ADMIN,
        ROLE_LIST.EXECUTIVE,
        ROLE_LIST.PRECEPTOR,
        ROLE_LIST.TEACHER,
      ],
      items: MANAGEMENT_CONFIG_ITEMS,
    },
  ];
};

export default useConfigSelector;
