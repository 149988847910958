import { useAtomValue, getDefaultStore } from 'jotai';

import { dialogStateFamily } from 'src/shared/atoms';

const useDialogIsOpen = (dialogName) => {
  const defaultStore = getDefaultStore();

  const dialogStateAtom = dialogStateFamily({
    dialogName,
  });

  const { isOpen } = useAtomValue(dialogStateAtom, {
    store: defaultStore,
  });

  return isOpen;
};

export default useDialogIsOpen;
