import { getDefaultStore, useAtomValue } from 'jotai';

import {
  paymentOrderAtom,
  paymentOrderFormDataAtom,
} from 'src/features/payment-management/atoms';
import { CreatePaymentOrdersStore } from 'src/shared/atoms/stepWizard/stepWizardStore';
import { stepWizardIncAtom } from 'src/shared/atoms/stepWizard/stepWizardAtom';
import usePaymentConfig from './data/usePaymentConfig';
import router from 'src/shared/utils/router';
import useParentBalance from './useParentBalance';
import usePaycodePayment from './data/paycode/usePaycodePayment';

const usePaycodeCreatePaymentOrder = () => {
  const defaultStore = getDefaultStore();
  const paymentOrder = useAtomValue(paymentOrderAtom, {
    store: defaultStore,
  });

  const paymentOrderData = useAtomValue(paymentOrderFormDataAtom, {
    store: defaultStore,
  });

  const { transactions } = useParentBalance();
  const { handlePaymentOrder } = usePaycodePayment();
  const { data: financialConfig } = usePaymentConfig();

  const paymentProvider = financialConfig?.provider;
  const paymentOrderUrl = router.collections.paymentOrder('').slice(0, -1);
  const callbackUrl = `${window.location.origin}${paymentOrderUrl}`;

  const nextStep = () => CreatePaymentOrdersStore.set(stepWizardIncAtom);

  const handleCreatePayment = (order) => {
    const { name, document, amountPartial, email } = order ?? paymentOrderData;

    const amount = amountPartial;
    const body = {
      value: amount,
      transactions,
      payer: {
        name,
        email,
        document,
      },
      callback_url: callbackUrl,
    };

    handlePaymentOrder(body);
  };

  return {
    paymentProvider,
    paymentOrder,
    hasPaymentDirectEnabled: false,
    nextStep,
    handleCreatePayment,
  };
};

export default usePaycodeCreatePaymentOrder;
