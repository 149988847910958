import * as Yup from 'yup';
import { t } from '@lingui/macro';
const REQUIRED_TEXT = t`Este campo es requerido.`;

const PaymentGenerateBillsSchemaMx = Yup.object().shape({
  wayToPay: Yup.object().required(REQUIRED_TEXT),
  switchReceiver: Yup.boolean(),
  zipCodeFamily: Yup.string().when('switchReceiver ', {
    is: true,
    then: (schema) => schema.required(REQUIRED_TEXT),
  }),
});

export default PaymentGenerateBillsSchemaMx;
