import { t } from '@lingui/macro';
import React from 'react';
import { GenericConfirmDialog } from 'src/components/Dialogs';
import { dialogs } from 'src/shared/constants';
import { useDialogIsOpen, useOpenDialog } from 'src/shared/hooks';
import useDeleteExternalLink from '../../mutations/useDeleteExternalLink';
import { useAtom } from 'jotai';
import {
  openExternalLinkDialogAtom,
  selectedExternalLinkAtom,
} from '../../atoms';

const DeleteExternalLinkDialog = () => {
  const isOpen = useDialogIsOpen(dialogs.DELETE_EXTERNAL_LINK_DIALOG);
  const setDialogIsOpen = useOpenDialog(dialogs.DELETE_EXTERNAL_LINK_DIALOG);
  const [, setOpenExternalLinkDialog] = useAtom(openExternalLinkDialogAtom);

  const [selectedExternalLink, setSelectedExternalLink] = useAtom(
    selectedExternalLinkAtom,
  );

  const handleCloseDialog = () => {
    setOpenExternalLinkDialog(false);
    setDialogIsOpen(false);
    setSelectedExternalLink(null);
  };

  const { mutateAsync: deleteExternalLink, isLoading } = useDeleteExternalLink({
    onSuccess: () => handleCloseDialog(),
  });

  return (
    <GenericConfirmDialog
      title={t`Acceso directo`}
      content={t`¿Deseas eliminar este acceso directo? Ya no será visible para los usuarios de la institución.`}
      status={{
        loading: isLoading,
      }}
      open={isOpen}
      onConfirm={() => {
        deleteExternalLink(selectedExternalLink.id);
      }}
      onClose={() => {
        setOpenExternalLinkDialog(true);
        setDialogIsOpen(false);
      }}
    />
  );
};

export default DeleteExternalLinkDialog;
