import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Clock } from 'react-feather';
import { t } from '@lingui/macro';

import ScheduledElementDialog from 'src/components/Dialogs/ScheduledElementDialog';
import useStyles from './styles';

const ScheduleElement = memo(
  forwardRef(
    (
      {
        onClick,
        onClose,
        isDialogOpen,
        onConfirm,
        values = {},
        disabled,
        elementLabel = t`tarea`,
        buttonId,
      },
      ref,
    ) => {
      const classes = useStyles();
      const isEditing = Boolean(values?.id);
      const menuLabel = isEditing
        ? t`Reprogramar`
        : t`Programar ${elementLabel?.toLowerCase()}`;

      return (
        <>
          <MenuItem onClick={onClick} disabled={disabled} ref={ref}>
            <ListItemIcon>
              <Clock size={16} className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary={menuLabel} />
          </MenuItem>
          <ScheduledElementDialog
            isEditing={isEditing}
            onClose={onClose}
            isOpen={isDialogOpen}
            onConfirm={onConfirm}
            values={values}
            confirmText={menuLabel}
            title={menuLabel}
            buttonId={buttonId}
          />
        </>
      );
    },
  ),
);

ScheduleElement.displayName = 'ScheduleElement';

ScheduleElement.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  values: PropTypes.object,
  disabled: PropTypes.bool,
  elementLabel: PropTypes.string,
  buttonId: PropTypes.string,
};

export default ScheduleElement;
