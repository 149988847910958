import { ROLE_LIST } from 'src/config/general';
import { toLocaleCurrency } from 'src/shared/utils/currencies';
import { getAmountToPay } from 'src/features/payment-management/components/InvoiceDetailsDialog/InvoiceDetailsContent/helpers';
import { CONCEPT_TYPES } from '../views/configuration/PaymentManagementConceptsView/helpers';
import { COUNTRY_CODE } from 'src/shared/constants/countryCode';
import {
  DLOCAL_PRIVACY_NOTICE_LINK,
  DLOCAL_TERMS_AND_CONDITIONS_LINK,
  PAYCODE_PRIVACY_NOTICE_LINK,
  PAYCODE_TERMS_AND_CONDITIONS_LINK,
  paymentProvider,
} from '../constants/payments';

const PAYMENT_MANAGEMENT_BALANCE_ROLES = [
  ROLE_LIST.ADMIN,
  ROLE_LIST.FINANCIAL_ADMIN,
  ROLE_LIST.FINANCIAL_EXECUTIVE,
];

const PAYMENT_MANAGEMENT_ROLES = [
  ROLE_LIST.ADMIN,
  ROLE_LIST.FINANCIAL_ADMIN,
  ROLE_LIST.FINANCIAL_EXECUTIVE,
  ROLE_LIST.PARENT,
];

const STATUS_GENERATE_INVOICES = {
  TO_PROCESS: 'to_process',
  PROCESSING: 'processing',
  DONE: 'process',
  FAIL: 'fail',
};

const handleRenderInvoiceValue = (invoice) => {
  const amountToPay = getAmountToPay(invoice, false, true);
  return toLocaleCurrency(amountToPay);
};

const VARIATIONS_TYPE = {
  DISCOUNT: 'discount',
};

const DISCOUNT_TYPES = {
  FIXED_VALUE: 'value',
  PERCENTAGE: 'percent',
};

const DISCOUNT_ENTITY_TYPES = {
  RATE: 'rate',
  FAMILY_DISCOUNT: 'family_discount',
};

const INVOICE_VARIATION_ENTITY_TYPES = {
  INVOICE_VARIATION: 'invoice_variation',
  INVOICE_INTERESTS_AFTER_DUE_DATE: 'invoice_interests_after_due_date',
};

const DISCOUNT_FIELDS = {
  FIXED_VALUE: 'amountValue',
  PERCENTAGE: 'percentValue',
};

const PAYMENTS_TRANSACTIONS_ENTITY_TYPES = {
  ADD_POSITIVE_BALANCE: 'add_positive_balance',
  INITIAL_TRANSACTION: 'initial_transaction',
  INVOICE: 'invoice',
  PAYMENT: 'payment',
  USE_POSITIVE_BALANCE: 'use_positive_balance',
};

const PAYMENTS_TRANSACTIONS_OPERATION_TYPES = {
  ADD: 'add',
  SUBS: 'subs',
};

const COUNTRY_LIST_DOWNLOAD_XML = [COUNTRY_CODE.MEXICO];

const COUNTRY_LIST_DOWNLOAD_BILLING_REPORT = [COUNTRY_CODE.MEXICO];

const COUNTRY_LIST_CANCELLED_TAX_BILL = [COUNTRY_CODE.MEXICO];

const ROLE_LIST_DOWNLOAD_BILLING_REPORT = [
  ROLE_LIST.ADMIN,
  ROLE_LIST.FINANCIAL_ADMIN,
];

const getConceptType = (rate) => {
  if (rate?.is_extraordinary) {
    return CONCEPT_TYPES.EXTRAORDINARY;
  }
  if (rate?.recurrent) {
    return CONCEPT_TYPES.RECURRENT;
  }
  return CONCEPT_TYPES.NOT_RECURRENT;
};

const getProviderLogo = (provider) => {
  return provider ? (
    <img
      width={94}
      src={require(`src/assets/images/payments/${provider}.png`)}
      alt={provider}
    />
  ) : null;
};

const getProviderTermsPrivacyLink = (provider) => {
  switch (provider) {
    case paymentProvider.DLOCAL:
      return {
        term: DLOCAL_TERMS_AND_CONDITIONS_LINK,
        privacy: DLOCAL_PRIVACY_NOTICE_LINK,
      };

    case paymentProvider.PAYCODE:
      return {
        term: PAYCODE_TERMS_AND_CONDITIONS_LINK,
        privacy: PAYCODE_PRIVACY_NOTICE_LINK,
      };

    default:
      return {};
  }
};

const OPTIONS_DOCUMENT_TYPE_ARG = [
  {
    id: 'cuit',
    name: 'CUIT',
  },
];

export {
  PAYMENT_MANAGEMENT_BALANCE_ROLES,
  STATUS_GENERATE_INVOICES,
  handleRenderInvoiceValue,
  DISCOUNT_TYPES,
  DISCOUNT_ENTITY_TYPES,
  DISCOUNT_FIELDS,
  PAYMENT_MANAGEMENT_ROLES,
  INVOICE_VARIATION_ENTITY_TYPES,
  PAYMENTS_TRANSACTIONS_ENTITY_TYPES,
  PAYMENTS_TRANSACTIONS_OPERATION_TYPES,
  getConceptType,
  COUNTRY_LIST_DOWNLOAD_XML,
  COUNTRY_LIST_DOWNLOAD_BILLING_REPORT,
  ROLE_LIST_DOWNLOAD_BILLING_REPORT,
  getProviderLogo,
  getProviderTermsPrivacyLink,
  COUNTRY_LIST_CANCELLED_TAX_BILL,
  OPTIONS_DOCUMENT_TYPE_ARG,
  VARIATIONS_TYPE,
};
