import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';
import Zendesk from 'react-zendesk';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { getPersistor } from '@rematch/persist';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GoogleOAuthProvider } from '@react-oauth/google';

import store from 'src/shared/stores/configureStore';
import theme from 'src/theme';
import Routes from 'src/Routes';
import useUnload from 'src/shared/hooks/useUnload';
import { removeStorageKey } from 'src/shared/helpers';
import SplashScreen from 'src/components/SplashScreen';
import MainErrorBoundary from 'src/components/ErrorBoundaries/MainErrorBoundary';
import SessionExpirationDialog from 'src/components/Dialogs/SessionExpirationDialog';
import {
  getLegacyToken,
  getIsFirstLogin,
  getRefreshToken,
} from 'src/shared/utils/manageBlendedStorage';
import { isProduction, environment, release, dsn } from 'src/config/constants';
import { FIREBASE_WEB_CLIENT_ID, ZENDESK_KEY } from 'src/config/general';
import queryClient from 'src/config/query';
import Satismeter from 'src/shared/utils/satismeter';
import 'dayjs/locale/es';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/en-gb';

import { useBreakpoints } from './shared/hooks';
import useZendeskWidget from './shared/hooks/useZendeskWidget';
import router from './shared/utils/router';
import {
  DEFAULT_LOCALE,
  getLocale,
} from './shared/utils/locales/manageLocalization';
import { getAdapterLocale } from './shared/utils/locales/localizationConfig';
import useBeforeRender from './shared/hooks/useBeforeRender';
import { initializeAnalytics } from './shared/hooks/useAnalytics';
import { ThemeApp } from 'src/theme';
import IntercomContainer from './components/IntercomContainer';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

Sentry.init({
  dsn,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  environment,
  release,
  normalizeDepth: 10,
  tracesSampleRate: isProduction ? 0.001 : 1,
  sampleRate: isProduction ? 0.05 : 1,
  beforeSend(event) {
    const { user } = store.getState();

    if (user.data) {
      Sentry.setUser({
        email: user.data.email,
        id: user.data.id,
      });
    }

    return event;
  },
});

let cleanHistoryListener = () => {};
if (isProduction) {
  (async () => {
    await Satismeter.load();
    cleanHistoryListener = history.listen(() => {
      Satismeter.trackEvent('pageView');
    });
  })();
}

const App = () => {
  useBeforeRender();
  const { isMobile } = useBreakpoints();
  const { user } = store.getState();
  const { userLocale } = user;

  const locale = getLocale(userLocale.locale).toLowerCase();
  const { hackZendeskWidget } = useZendeskWidget();
  useUnload(() => {
    removeStorageKey('last_page');
    cleanHistoryListener();
  });

  const appInit = async () => {
    const { isFirstLogin } = getIsFirstLogin();
    const legacyUserJWT = getLegacyToken();
    const refreshJWT = getRefreshToken();
    const pathName = window.location.pathname;
    const { isWebView } = user;
    const isLoginFromWebView = isWebView || pathName.includes('/loader');
    const REDIRECT_BLACKLIST = [
      router.auth.login,
      router.auth.recoveryPassword,
      router.auth.logout,
      router.service.redirectDownloadApp,
      router.collections.paymentOrder(''),
      router.collections.paymentOrderOnline(''),
    ];

    if (
      legacyUserJWT === null &&
      !REDIRECT_BLACKLIST.some((route) => pathName.includes(route)) &&
      !isLoginFromWebView
    ) {
      const params = pathName ? `?redirect=${pathName}` : '';
      const redirectURL = `/login${params}`;
      window.location.replace(redirectURL);

      return;
    }

    // si llego aca es porque esta loggeado
    // lo ejecuto con store.dispatch porque el useDispatch romperia
    if (user.apiKey !== legacyUserJWT && !isLoginFromWebView) {
      await store.dispatch.user.loginFromSIS({
        token: legacyUserJWT,
        refresh_token: refreshJWT,
        isFirstLogin,
      });
    }

    if (isProduction) {
      Satismeter.setUser();
      Satismeter.trackEvent('pageView');
      if (!isLoginFromWebView) {
        try {
          initializeAnalytics();
          store.dispatch.user.setUserPropertiesAnalytics();
        } catch (error) {
          console.error('ga4 start error!', error);
        }
      }
    }

    await store.dispatch.vc.initializeUserBasicData({
      subjects: true,
      lectures: user.userIs.teacher,
      notifications: {
        page: 1,
        per_page: 5,
      },
    });
  };

  useEffect(() => {
    // LOAD USER LOCALE OR DEFAULT LOCALE
    (async () => {
      const { data: userData } = user;
      const { locale } = userData;
      await store.dispatch.user.setUserLocale(locale ?? DEFAULT_LOCALE);
    })();
  }, [user]);

  return (
    <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
      <GoogleOAuthProvider clientId={FIREBASE_WEB_CLIENT_ID}>
        <StyledEngineProvider injectFirst>
          <Provider store={store}>
            <ThemeApp>
              <CssBaseline />
              <StylesProvider jss={jss}>
                <PersistGate
                  loading={<SplashScreen />}
                  persistor={getPersistor()}
                  onBeforeLift={appInit}
                >
                  <QueryClientProvider client={queryClient}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={getAdapterLocale(locale)}
                    >
                      <SnackbarProvider maxSnack={1}>
                        <IntercomContainer>
                          <Router history={history}>
                            <MainErrorBoundary>
                              <Routes />
                            </MainErrorBoundary>
                            <SessionExpirationDialog />
                          </Router>
                        </IntercomContainer>
                      </SnackbarProvider>
                    </LocalizationProvider>
                    {isProduction && !isMobile && (
                      <Zendesk
                        defer
                        zendeskKey={ZENDESK_KEY}
                        color={{ theme: theme.palette.primary.main }}
                        onLoaded={hackZendeskWidget}
                      />
                    )}
                    <ReactQueryDevtools position={'bottom-right'} />
                  </QueryClientProvider>
                </PersistGate>
              </StylesProvider>
            </ThemeApp>
          </Provider>
        </StyledEngineProvider>
      </GoogleOAuthProvider>
    </I18nProvider>
  );
};

export default App;
