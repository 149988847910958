import { useParams } from 'react-router';
import useUserInfo from 'src/features/payment-management/hooks/useUserInfo';

const useProfileUserId = () => {
  const { userId: paramUserId } = useParams();
  const { data: userData } = useUserInfo();
  const profileUserId = paramUserId ?? userData.id;
  const isMyAccount = Number(profileUserId) === userData.id;

  return { profileUserId, isMyAccount };
};

useProfileUserId.propTypes = {};

export default useProfileUserId;
