import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { responsiveFontSizes, createTheme, ThemeProvider } from '@mui/material';

import { THEMES } from 'src/config/constants';

import baseTypography from './typography';
import baseOverrides from './overrides';
import palette from './palette';
import { softShadows } from './shadows';

const baseConfig = {
  direction: 'ltr',
  typography: baseTypography,
  components: baseOverrides,
};

const themeConfigs = [
  {
    name: THEMES.BLENDED,
    shadows: softShadows,
    spacing: 8,
    shape: {
      borderRadius: 4,
    },
    mixins: {
      toolbar: {
        minHeight: 64,
      },
    },
  },
];

const createBlendedTheme = (settings = {}, { colorPrimary } = {}) => {
  let themeConfig = themeConfigs.find((theme) => theme.name === 'BLENDED');

  if (!themeConfig) {
    // eslint-disable-next-line no-console
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  const paletteConfig = {
    palette: {
      ...palette,
      ...(colorPrimary && {
        primary: {
          main: colorPrimary,
        },
      }),
    },
  };

  let theme = createTheme({
    ...baseConfig,
    ...themeConfig,
    ...{ direction: settings.direction },
    ...paletteConfig,
  });

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

export const ThemeApp = ({ children }) => {
  const { selectedInstitution } = useSelector(({ user }) => ({
    selectedInstitution: user.selectedInstitution,
  }));

  const theme = useMemo(
    () => createBlendedTheme({}, { colorPrimary: selectedInstitution.color }),
    [selectedInstitution],
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default createBlendedTheme();
