import { t } from '@lingui/macro';

export const REQUIRED_FIELD = t`Este campo es requerido`;
export const INVALID_NUMBER = t`Número o formato no válido, favor ingresar solo el número de identificación sin guiones ni puntos`;
export const INVALID_URL = t`La página web no es válida`;
export const INVALID_EMAIL = t`El email ingresado no es válido`;
export const INVALID_ZIP_CODE = t`El código postal no es válido`;
export const MIN_CHAR = ({ min }) =>
  t`La cantidad de caracteres debe ser mayor o igual a ${min}`;
export const MAX_CHAR = ({ max }) =>
  t`La cantidad de caracteres debe ser menor o igual a ${max}`;
export const MIN_AMOUNT = ({ min }) =>
  t`El número ingresado debe ser mayor o igual a ${min}`;
export const MAX_AMOUNT = ({ max }) =>
  t`El número ingresado debe ser menor o igual a ${max}`;
export const NOT_MATCH_PASSWORD = t`Las contraseñas no coinciden`;
export const ONLY_NUMBER = t`Este campo solo adminte números`;
