import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { Box, Grow, CircularProgress } from '@mui/material';

import BundledEditor from 'src/components/BundledEditor';
import OpenGraphCard from 'src/components/OpenGraphCard';
import usePublicationEditor from 'src/features/newsfeed/hooks/usePublicationEditor';
import { isValidUrl } from 'src/shared/helpers';
import PublicationFiles from './PublicationFiles';
import {
  loadBaseConfig,
  loadEditorIcons,
  addUploadButton,
  handleResizeEditor,
} from './helpers';
import './assets/css/editorStyles.css';
import useStyles from './styles';
import useInitConfigEditor from 'src/shared/hooks/useInitConfigEditor';
import sanitizeText from 'src/shared/utils/sanitazeText';

const PublicationEditor = ({
  placeholder,
  initialValue,
  value = '',
  onChange,
  attachments,
  fontFormats,
  emojis,
  links,
  onInit = () => {},
  onLoadOpenGraph,
  openGraph,
  slim,
  className,
  formatOptions,
  setFileUploadProgress = () => {},
  subFolder,
  isDroppable,
  ...props
}) => {
  const [inputId] = useState(uuidv4());
  const classes = useStyles();
  const pluginOptions = ['autoresize', 'paste', 'lists', 'autolink', 'link'];
  const toolbarOptions = [];
  const { initConfigEditor } = useInitConfigEditor();
  const { handlePasteContent, isFetchingOpenGraph, handleLinkAdded } =
    usePublicationEditor();

  if (attachments) {
    toolbarOptions.push('uploadAttachment');
  }
  if (fontFormats) {
    toolbarOptions.push('formatGroup');
  }
  if (emojis) {
    pluginOptions.push('emoticons');
    toolbarOptions.push('emoticons');
  }
  if (links) {
    toolbarOptions.push('link');
    pluginOptions.push('link');
  }

  const handleUploadFromDesktop = () => {
    const inputElement = document.getElementById(`upload-desktop-${inputId}`);
    inputElement.click();
  };

  const handleUploadFromDropbox = () => {};

  const handleUploadFromGoogleDrive = () => {
    const inputElement = document.getElementById(`upload-gdrive-${inputId}`);
    inputElement.click();
  };

  const handleUploadFromOneDrive = () => {
    const inputElement = document.getElementById(`upload-oneDrive-${inputId}`);
    inputElement.click();
  };

  const baseConfig = loadBaseConfig(
    toolbarOptions,
    pluginOptions,
    placeholder,
    formatOptions,
  );

  const handleUpdateEditor = (editorId, action, totalFiles) => {
    window.tinymce.execCommand('editorChange', null, {
      editorId,
      action,
      totalFiles,
    });
  };

  const handleOnInit = () => {
    handleUpdateEditor(inputId, 'add');
    onInit();
  };

  const handleOpenGraph = async (graphData) => {
    if (onLoadOpenGraph) onLoadOpenGraph(graphData);
    handleUpdateEditor(inputId, 'add');
  };

  const handleCloseOpenGraph = (e) => {
    e.stopPropagation();
    e.preventDefault();
    handleUpdateEditor(inputId, 'removeOG');
    onLoadOpenGraph(null);
  };

  useEffect(() => {
    if (openGraph) {
      handleUpdateEditor(inputId, 'add');
    }
  }, [openGraph, inputId]);

  const text = sanitizeText(value);

  return (
    <div
      className={clsx(
        classes.editorContainer,
        { [classes.slim]: slim },
        className,
      )}
    >
      <Grow in={isFetchingOpenGraph}>
        <CircularProgress className={classes.spinner} size={15} />
      </Grow>

      <BundledEditor
        id={`editor-${inputId}`}
        scriptLoading={{ async: true }}
        init={{
          ...baseConfig,
          ...initConfigEditor,
          setup(editor) {
            loadEditorIcons(editor);
            addUploadButton(
              editor,
              handleUploadFromDesktop,
              handleUploadFromDropbox,
              handleUploadFromGoogleDrive,
              handleUploadFromOneDrive,
            );
            handleResizeEditor(editor);
          },
          paste_preprocess: async (plugin, args) => {
            await handlePasteContent(args, openGraph, handleOpenGraph, inputId);
          },
        }}
        onInit={handleOnInit}
        initialValue={initialValue}
        value={text}
        onEditorChange={(content) => {
          onChange(content);
          if (isValidUrl(content)) {
            handleLinkAdded(content, openGraph, handleOpenGraph, inputId);
          }
        }}
        {...props}
      />
      <Box
        m={3}
        id="editorMedia"
        className={clsx(classes.openGraph, {
          [classes.openGraphVideo]: openGraph?.site_video_url,
        })}
      >
        <PublicationFiles
          buttonId={inputId}
          setProgress={setFileUploadProgress}
          subFolder={subFolder}
          isDroppable={isDroppable}
        />
        {openGraph && (
          <OpenGraphCard
            onClose={handleCloseOpenGraph}
            graphData={openGraph}
            canClose
            editorId={inputId}
          />
        )}
      </Box>
    </div>
  );
};

PublicationEditor.propTypes = {
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  attachments: PropTypes.bool,
  links: PropTypes.bool,
  fontFormats: PropTypes.bool,
  emojis: PropTypes.bool,
  onInit: PropTypes.func,
  onUpload: PropTypes.func,
  uploadedFiles: PropTypes.array,
  slim: PropTypes.bool,
  openGraph: PropTypes.object,
  onLoadOpenGraph: PropTypes.func,
  className: PropTypes.string,
  formatOptions: PropTypes.shape({
    bold: PropTypes.bool,
    italic: PropTypes.bool,
    underline: PropTypes.bool,
    bullist: PropTypes.bool,
  }),
  setFileUploadProgress: PropTypes.func,
  subFolder: PropTypes.string,
};

export default PublicationEditor;
