import React from 'react';
import {
  Card,
  CardContent,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useSetAtom } from 'jotai';

import { useOpenDialog } from 'src/shared/hooks';
import { dialogs } from 'src/shared/constants';
import { selectedEventAtom } from 'src/features/calendar/atoms';
import router from 'src/shared/utils/router';

import useStyles from './styles';

const EventResultRow = ({ event }) => {
  const classes = useStyles();
  const history = useHistory();
  const setOpen = useOpenDialog(dialogs.EVENT_DETAILS_DIALOG);
  const setEvent = useSetAtom(selectedEventAtom);
  const eventDate = dayjs
    .utc(event.start_datetime)
    .local()
    .format('MMM D')
    .split(' ');
  const eventStartTime = dayjs
    .utc(event.start_datetime)
    .local()
    .format('HH:mm');
  const eventEndTime = dayjs.utc(event.end_datetime).local().format('HH:mm');
  const sameStartAndEnd = event.start_datetime === event.end_datetime;
  // TODO: LOS EVENTOS NO TIENEN UNA URL ESPECIFICA PARA CADA EVENTO (EJ: EVENTS/[ID]).
  // CUANDO TENGAN HAY QUE REEMPLAZARLO

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.cardContent}>
        <ListItemButton
          className={classes.eventBox}
          onClick={() => {
            setOpen(true);
            setEvent(event);
            history.push(router.communication.calendar.root);
          }}
        >
          <ListItemAvatar>
            <Typography className={classes.eventDate}>
              {eventDate[0]}
              <br />
              {eventDate[1]}
            </Typography>
          </ListItemAvatar>
          <ListItemText
            className={classes.eventDetails}
            classes={{
              primary: classes.eventDetailsTitle,
              secondary: classes.eventDetailsDesc,
            }}
            primary={event.title}
            secondary={
              sameStartAndEnd
                ? eventStartTime
                : `${eventStartTime}-${eventEndTime}`
            }
          />
        </ListItemButton>
      </CardContent>
    </Card>
  );
};

EventResultRow.propTypes = {
  event: PropTypes.object,
};

export default EventResultRow;
