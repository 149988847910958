import { QUERY_KEY } from 'src/features/my-account/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import { permissionSendMessage } from 'src/shared/services/url/UrlCommunication';

const usePermissionSendMessage = ({ userId }) => {
  return useGetOne(
    [QUERY_KEY.PERMISSION_SEND_MESSAGE, userId],
    permissionSendMessage(userId),
    {},
    false,
    false,
    { enabled: Boolean(userId) },
  );
};
export default usePermissionSendMessage;
