import { QUERY_KEY } from 'src/features/my-account/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import { getUserParent } from 'src/shared/services/url/UrlUser';

const useUserParent = ({ userId, isStudent }) => {
  return useGetOne(
    [QUERY_KEY.USER_PARENT, userId],
    getUserParent(userId),
    { expand: 'custom_role' },
    false,
    false,
    { enabled: Boolean(userId && isStudent) },
  );
};

export default useUserParent;
