import React, { useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { CircularProgress, Box, Grid } from '@mui/material';

import NotificationsList from 'src/features/notifications/components/NotificationsList';
import useNotifications from 'src/features/notifications/hooks/useNotifications';
import NotificationsTitle from 'src/features/notifications/components/NotificationsTitle';
import EmptyState from 'src/components/EmptyState';

import useStyles from './styles';

const NotificationsView = () => {
  const classes = useStyles();

  const {
    handleLoadNotifications,
    handleClickNotification,
    handleMarkNotificationAsRead,
    handleLoadMoreNotifications,

    notifications,
    readLoading,
    pendingNotification,
    initialLoading,
    canLoadMore,
    loading,
  } = useNotifications({
    perPage: 50,
  });

  useEffect(() => {
    handleLoadNotifications();
  }, [handleLoadNotifications]);

  if (initialLoading) {
    return (
      <Box p={2} textAlign="center">
        <CircularProgress color="primary" size={25} />
      </Box>
    );
  }

  return (
    <Grid container display={'flex'} width={'100%'} justifyContent={'center'}>
      <Grid item xs={12} md={7}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          padding={4}
        >
          <NotificationsTitle />
        </Box>
        {!notifications?.data?.length ? (
          <EmptyState
            type="illustration"
            title={
              <Trans>
                Oups!
                <br />
                No hay notificaciones aún.
              </Trans>
            }
          />
        ) : (
          <NotificationsList
            className={classes.list}
            disablePadding
            notifications={notifications?.data}
            onClickNotification={handleClickNotification}
            onMarkNotificationAsRead={handleMarkNotificationAsRead}
            pendingNotification={pendingNotification}
            readLoading={readLoading}
            canLoadMore={canLoadMore}
            loading={loading}
            onLoadMore={handleLoadMoreNotifications}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default NotificationsView;
