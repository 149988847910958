import { useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { Api as api } from 'src/shared/services/api';
import {
  invoicePayment,
  paymentInvoices,
  paySingleInvoice,
  singleInvoicePayment,
  payment,
  paymentManagementInvoice,
  paymentManagementInvoiceStatus,
  invoicePaymentReject,
  singlePaymentManagementInvoice,
  invoiceResetInvoiceDraft,
  overviewBalancesUrl,
  paymentNotifications,
  lastInvoiceUrl,
} from 'src/shared/services/url/UrlPaymentManagement';
import { INVOICE_STATUS } from 'src/config/constants';
import { downloadFileWebView } from 'src/shared/utils/fileHelper';
import {
  sortByDateProp,
  toDateFormat,
  findMonthByIndex,
} from 'src/shared/utils/dates';
import { MONTHS } from 'src/features/payment-management/views/management/PaymentMassNotification/utils';
import { apiFilter } from 'src/shared/utils/apiFilter';
import { useAtom } from 'jotai';
import managementInvoicesFiltersAtom from '../../atoms/managementInvoicesFiltersAtom';

const defaultErrorMessage = t`Ha ocurrido un error. Intente mas tarde.`;
const defaultParams = { page: 0, limit: 20, search: '', byStatus: 'pending' };

const usePaymentManagementInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [invoicesList, setInvoicesList] = useState([]);
  const [invoiceDownPayments, setInvoiceDownPayments] = useState([]);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [isLoadingDownPayments, setIsLoadingDownPayments] = useState(false);
  const [isSendingPaymentVoucher, setIsSendingPaymentVoucher] = useState(false);
  const [isChangingInvoiceStatus, setIsChangingInvoiceStatus] = useState(false);
  const [isCreatingPaymentRequest, setIsCreatingPaymentRequest] =
    useState(false);
  const [isResettingInvoiceDate, setIsResettingInvoiceDate] = useState(false);
  const [isDownloadingInvoice, setIsDownloadingInvoice] = useState(false);
  const [isDeletingInvoice, setIsDeletingInvoice] = useState(false);
  const [isApprovingPayment, setIsApprovingPayment] = useState(false);
  const [isDeletingDownPayment, setIsDeletingDownPayment] = useState(false);
  const [isDecliningDownPayment, setIsDecliningDownPayment] = useState(false);
  const [paymentVoucherToView, setPaymentVoucherToView] = useState(null);
  const [isLoadingOverviewBalances, setIsLoadingOverviewBalances] =
    useState(false);
  const [overviewBalances, setOverviewBalances] = useState({
    paid: {
      total: 0,
      quantity: 0,
    },
    pending: {
      total: 0,
      quantity: 0,
    },
    due: {
      total: 0,
      quantity: 0,
    },
    to_review: {
      total: 0,
      quantity: 0,
    },
  });

  const [pagination, setPagination] = useState({
    total: 0,
    page: 0,
    limit: 20,
    totalPages: 1,
    hasNextPage: true,
  });
  const [filters, setFilters] = useAtom(managementInvoicesFiltersAtom);
  const [selectedMonth, setSelectedMonth] = useState();
  const [lastInvoiceDate, setLastInvoiceDate] = useState({
    date: null,
    monthIndex: null,
    nextMonthIndex: null,
    monthData: null,
    isInitialValue: true,
  });

  const { institutionPeriod, userIs } = useSelector(({ user }) => ({
    institutionPeriod: user.selectedInstitutionPeriodId,
    userIs: user.userIs,
  }));

  const { enqueueSnackbar } = useSnackbar();

  const parseLastInvoiceDate = (
    { invoiced_at: invoicedAt, status },
    isInitialValue,
  ) => {
    const invoiceDate = dayjs(invoicedAt);
    const monthIndex = invoiceDate.get('month');
    const nextMonthIndex = dayjs()
      .month(monthIndex)
      .add(1, 'month')
      .get('month');
    return {
      date: invoiceDate,
      monthIndex,
      nextMonthIndex,
      monthData: findMonthByIndex(monthIndex, MONTHS),
      isInitialValue: Boolean(isInitialValue),
      status,
    };
  };

  const fetchPaymentManagementInvoices = useCallback(
    async ({
      page = 0,
      limit = 20,
      search = '',
      byStatus = 'pending',
      byFamilyId,
      dateRange,
      total,
      ...rest
    } = defaultParams) => {
      const [startAt, endAt] = dateRange ?? [];
      const isDraft = byStatus === 'draft';

      setIsLoadingInvoices(true);
      try {
        const url = isDraft ? paymentInvoices() : paymentNotifications();
        const { data: raw, headers } = await api.get({
          url,
          getRaw: true,
          data: {
            name: search,
            page: !page ? 1 : page + 1,
            'per-page': limit,
            status: byStatus,
            family_id: byFamilyId,
            ...rest,
            ...(dateRange
              ? {
                  [apiFilter.invoicedAtGte]: toDateFormat(
                    startAt,
                    'YYYY-MM-DD',
                    true,
                  ),
                  [apiFilter.invoicedAtLte]: toDateFormat(
                    endAt,
                    'YYYY-MM-DD',
                    true,
                  ),
                }
              : {}),
          },
        });

        setInvoices(raw.data);
        setInvoicesList((oldState) => [...oldState, ...raw.data]);
        setPagination({
          page,
          limit,
          total: Number(headers['x-pagination-total-count']),
          totalPages: Number(headers['x-pagination-page-count']),
          hasNextPage:
            Number(headers['x-pagination-current-page']) <
            Number(headers['x-pagination-page-count']),
        });
        if (isDraft && raw.data.length > 0) {
          const [firstInvoice] = raw.data;
          const firstInvoiceDate = parseLastInvoiceDate(firstInvoice);
          setSelectedMonth(firstInvoiceDate.monthData);
        }
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingInvoices(false);
      }
    },
    [enqueueSnackbar],
  );

  const fetchLastInvoice = useCallback(async () => {
    try {
      const { data } =
        (await api.get({
          url: lastInvoiceUrl(),
        })) ?? {};
      if (data) {
        const parsedInvoiceDate = parseLastInvoiceDate(data);
        const lastInvoiceIsDraft = parsedInvoiceDate.status === 'draft';
        const monthIndex = lastInvoiceIsDraft
          ? parsedInvoiceDate.monthIndex
          : parsedInvoiceDate.nextMonthIndex;
        setLastInvoiceDate(parsedInvoiceDate);
        setSelectedMonth(MONTHS.find((month) => month?.id === monthIndex));
      }
      setLastInvoiceDate((oldValue) => ({
        ...oldValue,
        isInitialValue: false,
      }));
    } catch (error) {
      enqueueSnackbar(error.message ?? defaultErrorMessage, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar]);

  const fetchPaymentManagementInvoiceDownPayments = useCallback(
    async (invoiceId) => {
      setIsLoadingDownPayments(true);
      try {
        const { data: raw } = await api.get({
          url: paySingleInvoice(invoiceId),
          data: { expand: 'user' },
          getRaw: true,
        });
        const sortedData = sortByDateProp(raw.data, 'created_at');

        setInvoiceDownPayments(sortedData);
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingDownPayments(false);
      }
    },
    [enqueueSnackbar],
  );

  const fetchOverviewBalances = useCallback(
    async (start, end) => {
      setIsLoadingOverviewBalances(true);
      try {
        const { data } = await api.get({
          url: overviewBalancesUrl(),
          data: {
            start,
            end,
          },
        });

        setOverviewBalances(data);
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingOverviewBalances(false);
      }
    },
    [enqueueSnackbar],
  );

  const shouldBePending = useMemo(() => {
    const countDownPaymentInPending = invoiceDownPayments.filter(
      (downPayment) => downPayment.status === 'pending',
    ).length;

    return userIs.parent && !userIs.financialAdmin
      ? countDownPaymentInPending === 1
      : countDownPaymentInPending <= 1;
  }, [invoiceDownPayments, userIs.financialAdmin, userIs.parent]);

  const handleNewInvoiceStatus = useCallback(
    (prevStatus, remainingBalance) => {
      if (remainingBalance > 0 && shouldBePending) {
        return INVOICE_STATUS.PENDING;
      }
      if (userIs.parent && !userIs.financialAdmin) {
        return INVOICE_STATUS.TO_REVIEW;
      }
      if (userIs.financialAdmin && remainingBalance === 0) {
        return INVOICE_STATUS.PAID;
      }
      if (remainingBalance > 0 && !shouldBePending) {
        return INVOICE_STATUS.TO_REVIEW;
      }
      if (userIs.financialAdmin && remainingBalance > 0) {
        return INVOICE_STATUS.PENDING;
      }
      return prevStatus;
    },
    [shouldBePending, userIs.financialAdmin, userIs.parent],
  );

  const handleChangeInvoiceBalance = useCallback(
    (invoiceId, value) => {
      setInvoices((oldInvoices) =>
        oldInvoices.map((oldInvoice) => {
          const matchInvoice = Array.isArray(invoiceId)
            ? invoiceId.includes(oldInvoice.id)
            : oldInvoice.id === invoiceId;

          const changedValue =
            userIs.parent && !userIs.financialAdmin ? 0 : value;

          if (matchInvoice) {
            const newBalance = oldInvoice.balance - changedValue;
            const updatedInvoice = {
              ...oldInvoice,
              status: handleNewInvoiceStatus(oldInvoice.status, newBalance),
              balance: newBalance,
            };
            setPaymentVoucherToView(updatedInvoice);
            return updatedInvoice;
          }

          return oldInvoice;
        }),
      );
    },
    [handleNewInvoiceStatus, userIs.financialAdmin, userIs.parent],
  );

  const handleSendPaymentVoucher = useCallback(
    async (values) => {
      setIsSendingPaymentVoucher(true);

      try {
        const attachment = values.attachment
          ? {
              original_file_name: values.attachment.original_file_name,
              mime_type: values.attachment.type,
              storage_file_name: values.attachment.storage_file_name,
            }
          : undefined;
        const { data } = await api.post({
          url: payment(),
          data: {
            value: values.value,
            payment_method_id: values.payment_method_id,
            transactions: values.invoiceId,
            paid_at: values.paid_at,
            attachment,
          },
        });

        setInvoiceDownPayments((oldValues) => [...oldValues, data]);
        handleChangeInvoiceBalance(values.invoiceId, values.value);

        enqueueSnackbar(t`Pago enviado con éxito.`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });

        throw new Error(error);
      } finally {
        setIsSendingPaymentVoucher(false);
      }
    },
    [enqueueSnackbar, handleChangeInvoiceBalance],
  );

  const handlePageChange = (page, params) => {
    fetchPaymentManagementInvoices({
      ...params,
      total: pagination.total,
      limit: pagination.limit,
      page,
    });
  };

  const handleLimitPageChange = (limit, params) => {
    fetchPaymentManagementInvoices({
      ...params,
      total: pagination.total,
      page: pagination.page,
      limit,
    });
  };

  const handleCreatePaymentRequest = useCallback(
    async (values) => {
      setIsCreatingPaymentRequest(true);

      try {
        await api.post({
          url: paymentManagementInvoice(),
          data: {
            institution_period_id: institutionPeriod,
            ...values,
          },
        });

        enqueueSnackbar(t`Los avisos de pago se estan generando.`, {
          variant: 'info',
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });

        throw new Error(error);
      } finally {
        setIsCreatingPaymentRequest(false);
      }
    },
    [enqueueSnackbar, institutionPeriod],
  );

  const handleChangeInvoiceStatus = useCallback(
    async (invoicesToChangeStatus, status, config) => {
      setIsChangingInvoiceStatus(true);

      try {
        await api.patch({
          url: paymentManagementInvoiceStatus(),
          data: {
            invoice_id: invoicesToChangeStatus.map((invoice) => invoice.id),
            status,
          },
        });

        setInvoices((oldInvoices) => {
          if (config?.filter) {
            return oldInvoices.filter(
              (oldInvoice) =>
                !invoicesToChangeStatus.find(
                  (invoice) => invoice.id === oldInvoice.id,
                ),
            );
          }

          return oldInvoices.map((oldInvoice) => {
            const foundInvoice = invoicesToChangeStatus.find(
              (invoice) => invoice.id === oldInvoice.id,
            );

            if (foundInvoice) {
              return {
                ...oldInvoice,
                status,
              };
            }

            return oldInvoice;
          });
        });

        enqueueSnackbar(t`El estado se ha actualizado con éxito.`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsChangingInvoiceStatus(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleApproveDownPayment = useCallback(
    async (paymentItem) => {
      setIsApprovingPayment(true);

      try {
        await api.patch({
          url: invoicePayment(paymentItem.id),
        });

        setInvoiceDownPayments((oldDownPayments) =>
          oldDownPayments.map((oldDownPayment) => {
            if (oldDownPayment.id === paymentItem.id) {
              return {
                ...oldDownPayment,
                status: 'approved',
              };
            }

            return oldDownPayment;
          }),
        );

        const { id: invoiceId } = paymentVoucherToView;
        handleChangeInvoiceBalance(invoiceId, paymentItem.value);

        enqueueSnackbar(t`El pago se ha aprobado con éxito.`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsApprovingPayment(false);
      }
    },
    [enqueueSnackbar, handleChangeInvoiceBalance, paymentVoucherToView],
  );

  const handleDeleteInvoice = useCallback(
    async ({ id, reason }) => {
      setIsDeletingInvoice(true);

      try {
        await api.patch({
          url: singlePaymentManagementInvoice(id),
          data: {
            description: reason,
          },
        });

        setInvoices((oldInvoices) =>
          oldInvoices.filter((oldInvoice) => oldInvoice.id !== id),
        );

        setPagination((oldPagination) => ({
          ...oldPagination,
          total: oldPagination.total - 1,
        }));

        enqueueSnackbar(t`El aviso de pago se ha eliminado con éxito.`, {
          variant: 'success',
        });
      } catch ({ message: error }) {
        // SOME BACKEND BS
        const { message } = error ?? {};
        enqueueSnackbar(message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsDeletingInvoice(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleDeleteDownPayment = useCallback(
    async (downPaymentId) => {
      setIsDeletingDownPayment(true);

      try {
        await api.delete({
          url: singleInvoicePayment(downPaymentId),
        });

        const { id: invoiceId } = paymentVoucherToView;
        const { value } = invoiceDownPayments.find(
          (downPayment) => downPayment.id === downPaymentId,
        );
        handleChangeInvoiceBalance(invoiceId, -value);

        setInvoiceDownPayments((oldInvoiceDownPayments) =>
          oldInvoiceDownPayments.filter(
            (oldInvoiceDownPayment) =>
              oldInvoiceDownPayment.id !== downPaymentId,
          ),
        );

        enqueueSnackbar(t`El pago se ha eliminado con éxito.`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsDeletingDownPayment(false);
      }
    },
    [
      enqueueSnackbar,
      handleChangeInvoiceBalance,
      invoiceDownPayments,
      paymentVoucherToView,
    ],
  );

  const handleDownloadInvoice = useCallback(
    async (invoice) => {
      setIsDownloadingInvoice(true);

      try {
        const blob = await api.get({
          url: singlePaymentManagementInvoice(invoice.id),
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf',
          },
        });

        downloadFileWebView(
          URL.createObjectURL(blob),
          t`Aviso de pago - ${invoice.family.name}.pdf`,
        );
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsDownloadingInvoice(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleDeclineDownPayment = useCallback(
    async (paymentId, values) => {
      setIsDecliningDownPayment(true);

      try {
        await api.patch({
          url: invoicePaymentReject(paymentId),
          data: { description: values.reason },
        });

        enqueueSnackbar(t`Ha rechazado el pago con éxito.`, {
          variant: 'success',
        });

        const { id: invoiceId } = paymentVoucherToView;
        handleChangeInvoiceBalance(invoiceId, 0);

        setInvoiceDownPayments((oldDownPayments) =>
          oldDownPayments.map((oldDownPayment) => {
            if (oldDownPayment.id === paymentId) {
              return {
                ...oldDownPayment,
                status: 'reject',
              };
            }

            return oldDownPayment;
          }),
        );
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });

        throw new Error(error);
      } finally {
        setIsDecliningDownPayment(false);
      }
    },
    [enqueueSnackbar, handleChangeInvoiceBalance, paymentVoucherToView],
  );

  const handleResetInvoiceDate = useCallback(
    async (invoiceDate) => {
      setIsResettingInvoiceDate(true);

      try {
        await api.patch({
          url: invoiceResetInvoiceDraft(),
          data: {
            invoiced_at: invoiceDate,
          },
        });

        enqueueSnackbar(
          t`Ha reiniciado la facturación para la fecha: ${toDateFormat(
            invoiceDate,
            'DD/MM/YYYY',
          )}.`,
          {
            variant: 'success',
          },
        );

        setInvoices((oldInvoices) =>
          oldInvoices.filter(
            (oldInvoice) => !oldInvoice.invoiced_at.includes(invoiceDate),
          ),
        );
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });

        throw new Error(error);
      } finally {
        setIsResettingInvoiceDate(false);
      }
    },
    [enqueueSnackbar],
  );

  return {
    fetchPaymentManagementInvoices, // -
    fetchPaymentManagementInvoiceDownPayments,
    fetchOverviewBalances,

    handleSendPaymentVoucher,
    handlePageChange, // -
    handleLimitPageChange, // -
    handleCreatePaymentRequest, // -
    handleChangeInvoiceStatus, // -
    handleApproveDownPayment,
    handleDeleteInvoice,
    handleDeleteDownPayment,
    handleDownloadInvoice,
    handleDeclineDownPayment,
    handleResetInvoiceDate, // -

    setPaymentVoucherToView,
    setInvoiceDownPayments,
    setFilters, // -
    setSelectedMonth, // -

    isLoadingOverviewBalances,
    isDeletingDownPayment,
    isDeletingInvoice,
    isApprovingPayment,
    isSendingPaymentVoucher,
    isLoadingInvoices, // -
    isLoadingDownPayments,
    isCreatingPaymentRequest,
    isChangingInvoiceStatus,
    isDecliningDownPayment,
    isResettingInvoiceDate,
    isDownloadingInvoice,

    filters, // -
    invoices, // -
    invoicesList, // -
    pagination, // -
    invoiceDownPayments,
    overviewBalances,
    paymentVoucherToView,
    selectedMonth, // -
    fetchLastInvoice, // -
    lastInvoiceDate, // -
    setLastInvoiceDate, // -
  };
};

export default usePaymentManagementInvoices;
