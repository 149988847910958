import React from 'react';
import PropTypes from 'prop-types';
import { TextField as MuiTextField, InputAdornment } from '@mui/material';
import { useFormikContext } from 'formik';

import useStyles from '../styles';

const BaseCurrencyInput = ({
  disabled,
  name,
  InputProps,
  helperText,
  symbol,
  adornmentPosition,
  ...props
}) => {
  const { isSubmitting, touched, errors } = useFormikContext();
  const classes = useStyles();

  return (
    <MuiTextField
      {...props}
      name={name}
      fullWidth
      InputProps={
        InputProps ?? {
          disabled: isSubmitting || disabled,
          ...(adornmentPosition === 'start'
            ? {
                startAdornment: (
                  <InputAdornment position="start">{symbol}</InputAdornment>
                ),
              }
            : {
                endAdornment: (
                  <InputAdornment position="end">{symbol}</InputAdornment>
                ),
              }),
        }
      }
      classes={{ root: classes.MuiInput }}
      error={Boolean(touched[name] && errors[name])}
      helperText={(touched[name] && errors[name]) || helperText}
      variant="outlined"
      disabled={isSubmitting || disabled}
      {...props}
    />
  );
};

BaseCurrencyInput.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  symbol: PropTypes.string,
  InputProps: PropTypes.object,
  adornmentPosition: PropTypes.oneOf(['start', 'end']),
};

export default BaseCurrencyInput;
