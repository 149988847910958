import { useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { checkObjectIsEmpty } from 'src/shared/helpers';
import useUserInfo from 'src/features/payment-management/hooks/useUserInfo';
import { selectedUserFamilyAtom } from 'src/features/my-account/atoms';
import { useFamilyTaxInformation } from 'src/features/payment-management/hooks';
import { useOrganizationTypes } from 'src/shared/hooks';
import useCity from 'src/features/payment-management/hooks/data/useCity';
import useDepartment from 'src/features/payment-management/hooks/data/useDepartment';
import { COUNTRY_CODE } from 'src/shared/constants/countryCode';
import router from 'src/shared/utils/router';

const useUserTaxInfo = () => {
  const history = useHistory();
  const { data: userData } = useUserInfo();
  const { families } = userData;
  const [selectedFamily, setSelectedFamily] = useAtom(selectedUserFamilyAtom);

  const { countryCode } = useSelector(({ user }) => ({
    countryCode: user?.data?.country?.code,
  }));

  const { data: organizationTypes } = useOrganizationTypes({
    enabled: countryCode.toLowerCase() === COUNTRY_CODE.COLOMBIA,
  });

  const { data: municipalities } = useCity({
    enabled: countryCode.toLowerCase() === COUNTRY_CODE.COLOMBIA,
  });

  const { data: departments } = useDepartment({
    enabled: countryCode.toLowerCase() === COUNTRY_CODE.COLOMBIA,
  });

  const { data: familyTaxInformation, isLoading: isLoadingFamilyTaxInfo } =
    useFamilyTaxInformation({
      familyId: selectedFamily.id,
    });

  const userTaxInfo = useMemo(
    () => familyTaxInformation?.find(({ user_id }) => user_id === userData.id),
    [familyTaxInformation, userData.id],
  );

  const organizationType = useMemo(
    () =>
      organizationTypes?.find(
        ({ code }) => code === Number(userTaxInfo?.person_type_id),
      ),
    [organizationTypes, userTaxInfo],
  );

  const municipality = useMemo(
    () =>
      municipalities?.find(({ code }) => code === userTaxInfo?.municipality),
    [municipalities, userTaxInfo],
  );

  const department = useMemo(
    () => departments?.find(({ code }) => code === userTaxInfo?.department),
    [departments, userTaxInfo],
  );

  const handleChangeFamily = (event) => {
    setSelectedFamily(event.target.value);
  };

  const handleEdit = () => {
    const userTaxInfoRoute = userTaxInfo?.id
      ? router.me.updateTaxInformation({
          familyId: selectedFamily.id,
          taxId: userTaxInfo.id,
        })
      : router.me.createTaxInformation(selectedFamily.id);

    history.push({
      pathname: userTaxInfoRoute,
    });
  };

  useEffect(() => {
    const isEmptyFamilySelected = checkObjectIsEmpty(selectedFamily);
    if (isEmptyFamilySelected) {
      setSelectedFamily(families[0]);
    }
  }, [families, selectedFamily, setSelectedFamily]);

  return {
    families,
    selectedFamily,
    userTaxInfo,
    isLoadingFamilyTaxInfo,
    municipality,
    organizationType,
    department,
    handleEdit,
    handleChangeFamily,
  };
};

export default useUserTaxInfo;
