import { QUERY_KEY } from 'src/features/my-account/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import { pickupCategoryDayUrl } from 'src/shared/services/url/UrlUser';

const useUserPickUpCategoryDay = ({ enabled = true, userId }) => {
  return useGetOne(
    [QUERY_KEY.USER_PICKUP_CATEGORY_DAY, userId],
    pickupCategoryDayUrl(),
    {
      user_id: userId,
    },
    true,
    false,
    { enabled },
  );
};

export default useUserPickUpCategoryDay;
