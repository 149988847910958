import { useCallback } from 'react';

const useTinyMCEEditor = (dropzoneId) => {
  const handleUpdateEditorFiles = useCallback(
    (totalFiles, remove) => {
      if (window.tinymce) {
        window.tinymce.execCommand('editorChange', null, {
          editorId: dropzoneId,
          action: remove ? 'remove' : 'add',
          totalFiles,
        });
      }
    },
    [dropzoneId],
  );

  return { handleUpdateEditorFiles };
};

useTinyMCEEditor.propTypes = {};

export default useTinyMCEEditor;
