import { useState } from 'react';
import Papa from 'papaparse';
import { useDebouncedCallback } from 'use-debounce';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { useDialogIsOpen, useOpenDialog } from 'src/shared/hooks';
import { dialogs } from 'src/shared/constants';
import {
  useDownloadExportErrors,
  useDownloadImportsTemplate,
  useFamilyImportPayment,
  useImportStatusBatchTransaction,
} from 'src/features/payment-management/hooks';
import { FINISHED_IMPORT_STATUS } from 'src/features/payment-management/constants/paymentImport';

const usePaymentImport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({});
  const [transaction, setTransaction] = useState({});
  const [statusTransaction, setStatusTransaction] = useState({});
  const [csvRows, setCsvRows] = useState(0);
  const openPaymentImportDialog = useOpenDialog(dialogs.PAYMENT_IMPORT_DIALOG);
  const isPaymentImportOpen = useDialogIsOpen(dialogs.PAYMENT_IMPORT_DIALOG);

  const openConfirmImportDialog = useOpenDialog(
    dialogs.PAYMENT_IMPORT_CONFIRM_DIALOG,
  );
  const confirmPaymentImportIsOpen = useDialogIsOpen(
    dialogs.PAYMENT_IMPORT_CONFIRM_DIALOG,
  );

  const openProgressImportDialog = useOpenDialog(
    dialogs.PAYMENT_IMPORT_PROGRESS_DIALOG,
  );
  const progressImportIsOpen = useDialogIsOpen(
    dialogs.PAYMENT_IMPORT_PROGRESS_DIALOG,
  );

  const openFinishedImportDialog = useOpenDialog(
    dialogs.PAYMENT_IMPORT_FINISHED_DIALOG,
  );
  const finishedImportIsOpen = useDialogIsOpen(
    dialogs.PAYMENT_IMPORT_FINISHED_DIALOG,
  );

  const { mutate: downloadTemplate, isLoading: isDownloadingCSV } =
    useDownloadImportsTemplate();

  const { mutate: downloadExportErrors, isLoading: isDownloadingErrors } =
    useDownloadExportErrors();

  const { mutateAsync: importPayment, isLoading: isImportCSV } =
    useFamilyImportPayment();

  const { mutateAsync: statusBatchTransaction } =
    useImportStatusBatchTransaction();

  const formatCsvErrors = (errors) => {
    const errrorsMessage = errors.map(
      ({ message, row }) => `Linea ${row + 1} (${message})`,
    );

    return errrorsMessage.toString();
  };
  const showCsvErrors = (message = '') => {
    enqueueSnackbar(t`Ha ocurrido un problema. ${message}`, {
      variant: 'error',
    });
  };

  const parseCSV = useDebouncedCallback((values, formikBag) => {
    try {
      const { file_url } = values.file[0];
      Papa.parse(file_url, {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: ({ data, errors }) => {
          if (errors.length) {
            const msg = formatCsvErrors(errors);
            formikBag.setFieldError(
              'file',
              t`El archivo .csv contiene errores. ${msg}`,
            );
            showCsvErrors();
            formikBag.setSubmitting(false);
            return;
          }

          setFormValues(values);
          setCsvRows(data.length);
          openPaymentImportDialog(false);
          openConfirmImportDialog(true);
        },
      });
    } catch (error) {
      const { message } = error;
      showCsvErrors(message);
    }
  }, 500);

  const handlePaymentImportSubmit = (values, formikBag) => {
    parseCSV(values, formikBag);
  };

  const handleImportConfirm = async () => {
    const { externalEntity, paymentMethod } = formValues;
    const [file] = formValues.file;

    await importPayment(
      {
        payment_method_id: paymentMethod.id,
        external_entity_id: externalEntity.id,
        file: {
          original_file_name: file.original_file_name,
          mime_type: file.type,
          storage_file_name: file.storage_file_name,
        },
      },
      {
        onSuccess: ({ data }) => {
          setTransaction(data);
          openProgressImportDialog(true);
          openConfirmImportDialog(false);
        },
      },
    );
  };

  const handleImportProgressConfirm = async () => {
    await statusBatchTransaction(
      {
        transactionId: transaction.id,
      },
      {
        onSuccess: ({ data }) => {
          if (data.status === FINISHED_IMPORT_STATUS) {
            setStatusTransaction(data);
            openProgressImportDialog(false);
            openFinishedImportDialog(true);
          }
        },
      },
    );
  };

  const handlePaymentImportClose = () => {
    openPaymentImportDialog(false);
  };

  const handleImportProgressClose = () => {
    openProgressImportDialog(false);
  };

  const handleImportConfirmClose = () => {
    openConfirmImportDialog(false);
  };

  const handleImportFinishedClose = () => {
    openFinishedImportDialog(false);
  };

  const handleDownloadCSV = ({ id }) => {
    downloadTemplate({ external_entity_id: id });
  };

  const handleDownloadCSVErrors = () => {
    downloadExportErrors();
  };

  return {
    csvRows,
    formValues,
    isPaymentImportOpen,
    confirmPaymentImportIsOpen,
    progressImportIsOpen,
    finishedImportIsOpen,
    isDownloadingCSV,
    isImportCSV,
    isDownloadingErrors,
    statusTransaction,
    openPaymentImportDialog,
    openConfirmImportDialog,
    openProgressImportDialog,
    handlePaymentImportSubmit,
    handleImportConfirm,
    handleImportProgressConfirm,
    handlePaymentImportClose,
    handleImportConfirmClose,
    handleImportProgressClose,
    handleImportFinishedClose,
    handleDownloadCSV,
    handleDownloadCSVErrors,
  };
};

export default usePaymentImport;
