import React from 'react';
import { Trans } from '@lingui/macro';
import { SettingsOutlined as SettingsOutlinedIcon } from '@mui/icons-material';

import { BLENDED_MODULES } from 'src/config/constants';

import useRoutes from '../useRoutes';
import useConfigSelector from '../useConfigSelector';

const useSettingsConfig = () => {
  const { createRoutes } = useRoutes();
  const configSelector = useConfigSelector();

  return {
    id: BLENDED_MODULES.CONFIG.id,
    name: BLENDED_MODULES.CONFIG.name,
    subheader: <Trans>Configuración</Trans>,
    icon: <SettingsOutlinedIcon size={18} />,
    prefix: 'configuracion',
    items: createRoutes(configSelector),
  };
};

export default useSettingsConfig;
