import { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

const useNotifications = (config = {}) => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [readLoading, setReadLoading] = useState(false);
  const [pendingNotificationId, setPendingNotificationId] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const notificationsWereFetched = useRef(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    notifications,
    getLazyNotifications,
    markNotificationAsRead,
    getUnreadNotifications,
    getNotifications,
  } = useSelector((store) => ({
    notifications: store.user.notifications,
    getLazyNotifications: dispatch.user.getLazyNotifications,
    getNotifications: dispatch.user.getNotifications,
    markNotificationAsRead: dispatch.user.markNotificationAsRead,
    getUnreadNotifications: dispatch.user.hasNotifications,
  }));

  const hasNotifications = notifications?.data.length > 0;
  const canLoadMore =
    notifications?.data.length < notifications?.pagination.total;

  const handleLoadMoreNotifications = async () => {
    setLoading(true);
    try {
      await getLazyNotifications({ page: page + 1, perPage: 5 });
      setPage(page + 1);
    } finally {
      setLoading(false);
    }
  };

  const handleMarkNotificationAsRead = async (notificationId) => {
    try {
      setReadLoading(true);
      setPendingNotificationId(notificationId);
      await markNotificationAsRead(notificationId);
      await getUnreadNotifications();
    } catch ({ message }) {
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setReadLoading(false);
      setPendingNotificationId(null);
    }
  };

  const handleDebouncedScroll = async () => {
    await handleLoadMoreNotifications();
  };

  const handleLoadNotifications = useCallback(async () => {
    setInitialLoading(true);
    try {
      await getNotifications({
        page: 1,
        perPage: config.perPage ?? 5,
      });

      notificationsWereFetched.current = true;
    } finally {
      setInitialLoading(false);
    }
  }, [
    getNotifications,
    setInitialLoading,
    config.perPage,
    notificationsWereFetched,
  ]);

  const handleClickNotification = async (notification) => {
    await handleMarkNotificationAsRead(notification.id);

    history.push(notification.link);
  };

  return {
    handleClickNotification,
    handleLoadNotifications,
    handleMarkNotificationAsRead,
    handleLoadMoreNotifications,
    handleDebouncedScroll,
    getUnreadNotifications,

    hasNotifications,
    readLoading,
    pendingNotificationId,
    initialLoading,
    notifications,
    notificationsWereFetched,
    canLoadMore,
    loading,
  };
};

export default useNotifications;
