import { toLocaleCurrency } from 'src/shared/utils/currencies';
import { hardCodeInitValuesCol } from 'src/features/payment-management/forms/PaymentGenerateBillsForm/COL/helpers';

const formattedDataCol = (
  familyTaxInformation,
  institutionTaxInformation,
  invoices,
  isBatch,
) => {
  const initialValues = { ...hardCodeInitValuesCol };
  let valuesFamily = {};
  let valuesInvoice = {};
  let valuesInstitution = {};
  let valuesTaxes = {};
  let valuesResolution = {};

  if (familyTaxInformation) {
    initialValues.hasResponsibleBiller = true;
    initialValues.switchReceiver = false;
    valuesFamily = {
      documentFamily: familyTaxInformation.document,
      documentTypeFamily: familyTaxInformation.document_name,
      nameFamily: familyTaxInformation.name,
    };
  }

  if (institutionTaxInformation && invoices.length) {
    valuesInstitution = {
      document: institutionTaxInformation.document,
      name: institutionTaxInformation.name,
      taxRegime: institutionTaxInformation.tax_regime_id,
      verifyDigit: institutionTaxInformation.verify_digit,
    };

    if (institutionTaxInformation.active_resolution) {
      const { data, last_tax_bill_number } =
        institutionTaxInformation.active_resolution;

      valuesResolution = {
        ...data,
        last_tax_bill_number,
      };
    }

    if (!isBatch) {
      const { total_invoiced } = invoices[0];
      const totalAmount = toLocaleCurrency(total_invoiced);

      valuesInvoice = {
        productUnitPrice: totalAmount,
        productTotal: totalAmount,
      };

      valuesTaxes = {
        total: totalAmount,
      };
    }
  }

  if (institutionTaxInformation && invoices.length) {
    const { tax_regime: taxRegimeIntitution } = institutionTaxInformation;

    if (taxRegimeIntitution?.name !== 'No responsable de IVA') {
      const valueTotal = Number(invoices[0].total);
      const valueNeto = valueTotal / 1.19;
      const valueIva = valueTotal - valueNeto;

      valuesTaxes.neto = toLocaleCurrency(valueNeto, {
        maximumFractionDigits: 2,
      });

      valuesTaxes.iva = toLocaleCurrency(valueIva, {
        maximumFractionDigits: 2,
      });
    }
  }

  const values = {
    ...initialValues,
    ...valuesFamily,
    ...valuesInvoice,
    ...valuesInstitution,
    ...valuesTaxes,
    ...valuesResolution,
  };

  return values;
};

export default formattedDataCol;
