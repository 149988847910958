import useLocale from '../useLocale';

const useInitConfigEditor = () => {
  const { locale } = useLocale({ partIndex: 0 });

  const initConfigEditor = {
    language: locale,
  };

  return {
    initConfigEditor,
  };
};

export default useInitConfigEditor;
