import { useGetOne } from 'src/shared/services/api/query';
import { QUERY_KEY } from '../../constants/queryKeys';
import { getConversationById } from 'src/shared/services/url/UrlCommunication';
import useUserInfo from 'src/features/payment-management/hooks/useUserInfo';

const useConversationById = (conversationId) => {
  const { selectedInstitutionPeriodId } = useUserInfo();

  const query = useGetOne(
    [QUERY_KEY.CONVERSATIONS_BY_ID, conversationId],
    getConversationById(selectedInstitutionPeriodId),
    {
      id: conversationId,
      expand: 'last_message,sender_id',
    },
    true,
    false,
    {
      enabled: Boolean(conversationId),
    },
  );
  const data = query?.data;
  const conversation = data && Array.isArray(data) ? data[0] : data;

  return {
    ...query,
    data: conversation,
  };
};

export default useConversationById;
