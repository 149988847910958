import { useCallback, useMemo } from 'react';

import { useUsersSearch } from 'src/shared/hooks';
import { DISCOUNT_ENTITY_TYPES } from 'src/features/payment-management/helpers';
import { STUDENT_ROLE_ID } from 'src/config/constants';

import { default as useSelectedDiscountMembers } from '../data/discounts/useSelectedMembers';
import { default as useSelectedConceptMembers } from '../data/concepts/useSelectedMembers';
import useRemoveMember from '../data/discounts/useRemoveMember';
import useAddMember from '../data/discounts/useAddMember';
import useRemoveFamilyMember from '../data/discounts/useRemoveFamilyMember';
import useAddFamilyMember from '../data/discounts/useAddFamilyMember';
import useSelectedFamilyMembers from '../data/discounts/useSelectedFamilyMembers';

const usePaymentConfigDiscounts = (discount) => {
  const isFamilyDiscount = useMemo(
    () => discount?.entityType === DISCOUNT_ENTITY_TYPES.FAMILY_DISCOUNT,
    [discount],
  );

  const { users: students } = useUsersSearch({
    roles: [STUDENT_ROLE_ID],
    withFamily: true,
  });

  const {
    loading: isLoadingStudents,
    search,
    users: searchStudents,
    handleSearch,
    handleChangeParam,
  } = useUsersSearch({
    roles: [STUDENT_ROLE_ID],
    withFamily: true,
  });

  const {
    data: selectedUserMembers,
    filteredData: filteredSelectedUserMembers,
    isLoading: selectedUserMembersLoading,
    isFetching: selectedMembersFetching,
  } = useSelectedDiscountMembers(discount?.id, {
    enabled: !isFamilyDiscount,
  });

  const {
    data: selectedFamilyMembers,
    isLoading: selectedFamilyMembersLoading,
    isFetching: selectedFamilyMembersFetching,
  } = useSelectedFamilyMembers(discount?.id, isFamilyDiscount);

  const {
    data: conceptMembers,
    filteredData: filteredConceptMembers,
    isLoading: selectedConceptMembersLoading,
  } = useSelectedConceptMembers(discount?.entityId);

  const { mutate: removeMember } = useRemoveMember(discount?.id);
  const { mutate: addMember } = useAddMember(discount?.id, conceptMembers);
  const { mutate: removeFamilyMember, isLoading: loadingRemoveFamily } =
    useRemoveFamilyMember();
  const { mutate: addFamilyMember, isLoading: loadingAddFamily } =
    useAddFamilyMember();

  const isUpdatingFamily = loadingRemoveFamily || loadingAddFamily;

  const filteredSelectedStudents = useMemo(
    () =>
      students.filter(({ families }) => {
        const family_id = families[0]?.id;
        const selectedFamilyIds = selectedFamilyMembers.map(({ id }) => id);
        return selectedFamilyIds.includes(family_id);
      }),
    [selectedFamilyMembers, students],
  );

  const selectedMembers = useMemo(
    () => (isFamilyDiscount ? filteredSelectedStudents : selectedUserMembers),
    [filteredSelectedStudents, isFamilyDiscount, selectedUserMembers],
  );

  const filteredSelectedMembers = useMemo(
    () =>
      isFamilyDiscount ? filteredSelectedStudents : filteredSelectedUserMembers,
    [filteredSelectedStudents, filteredSelectedUserMembers, isFamilyDiscount],
  );

  const handleChangeFilters = useCallback(
    ({ divisions }) => {
      handleChangeParam({
        divisions,
        filter: { family_id: { neq: null } },
      });
    },
    [handleChangeParam],
  );

  const handleSelectByUser = (member, checked) => {
    if (checked) addMember([{ user_id: member.id }]);
    else removeMember({ user_id: [member.id] });
  };

  const handleSelectUserAll = () => {
    if (conceptMembers?.length) {
      addMember(
        conceptMembers
          ?.filter(
            (member) =>
              !selectedMembers.some(
                (selectedMember) => selectedMember.id === member.id,
              ),
          )
          ?.map((member) => {
            return { user_id: member.id };
          }),
      );
    }
  };

  const handleRemoveUserAll = () => {
    if (selectedMembers?.length) {
      removeMember({
        user_id: selectedMembers.map((member) => member.id),
      });
    }
  };

  const getFamilyId = (member) => {
    return member.families[0]?.id;
  };

  const handleSelectByFamily = (member, checked) => {
    const family_id = getFamilyId(member);
    if (checked) {
      addFamilyMember({
        discountId: discount?.id,
        data: [{ family_id }],
      });
    } else {
      removeFamilyMember({
        discountId: discount?.id,
        data: { family_id: [family_id] },
      });
    }
  };

  const handleSelectFamilyAll = () => {
    if (searchStudents?.length) {
      const selectedFamilyIds = [
        ...new Set(selectedMembers.map((member) => getFamilyId(member))),
      ];
      const allStudents = searchStudents?.filter(
        (member) => !selectedFamilyIds.includes(getFamilyId(member)),
      );
      const allFamilyIds = [
        ...new Set(allStudents.map((member) => getFamilyId(member))),
      ];
      const data = allFamilyIds?.map((id) => ({
        family_id: id,
      }));

      if (data.length) {
        addFamilyMember({ discountId: discount?.id, data });
      } else {
        handleRemoveFamilyAll();
      }
    }
  };

  const handleRemoveFamilyAll = () => {
    if (selectedMembers?.length) {
      const selectedFamilyIds = [
        ...new Set(selectedMembers.map((member) => getFamilyId(member))),
      ];
      removeFamilyMember({
        discountId: discount?.id,
        data: {
          family_id: selectedFamilyIds,
        },
      });
    }
  };

  const handleSelect = (member, checked) => {
    if (isFamilyDiscount) {
      handleSelectByFamily(member, checked);
    } else {
      handleSelectByUser(member, checked);
    }
  };

  const handleSelectAll = (member, checked) => {
    if (isFamilyDiscount) {
      handleSelectFamilyAll();
    } else {
      handleSelectUserAll();
    }
  };

  const handleRemoveAll = () => {
    if (isFamilyDiscount) {
      handleRemoveFamilyAll();
    } else {
      handleRemoveUserAll();
    }
  };
  return {
    isUpdatingFamily,
    totalStudents: selectedMembers.length,
    isFamilyDiscount,
    isLoadingStudents,
    search,
    searchStudents,
    selectedMembers,
    filteredConceptMembers,
    selectedUserMembersLoading,
    selectedFamilyMembersLoading,
    selectedConceptMembersLoading,
    selectedMembersFetching,
    filteredSelectedMembers,
    selectedFamilyMembersFetching,
    handleSearch,
    handleChangeFilters,
    handleSelect,
    handleSelectAll,
    handleRemoveAll,
  };
};

export default usePaymentConfigDiscounts;
