import { getDepartments } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import optionsArraySerializer from 'src/shared/services/api/query/serializer/optionsArraySerializer';

const useDepartment = ({ ...options } = {}) => {
  const keyName = 'value';
  const keyId = 'code';
  const deserializer = optionsArraySerializer(keyName, keyId);

  return useGetOne(
    [QUERY_KEY.DEPARTMENTS],
    getDepartments(),
    {},
    true,
    false,
    options,
    deserializer,
  );
};

export default useDepartment;
