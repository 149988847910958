import { useLocation } from 'react-router-dom';

import { useGetList } from 'src/shared/services/api/query';
import { getScheduledConversationDetail } from 'src/shared/services/url/UrlCommunication';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';

const path = '/conversacion';

const useGetScheduledConversationDetail = (conversationId) => {
  const location = useLocation();

  const query = useGetList(
    [QUERY_KEY.SCHEDULED_DETAIL, conversationId],
    getScheduledConversationDetail(conversationId),
    { expand: 'sender' },
    true,
    false,
    {
      enabled: Boolean(conversationId && !location.pathname.includes(path)),
    },
  );

  const data = query?.data;
  const conversation = data && Array.isArray(data) ? data[0] : data;

  return {
    ...query,
    data: conversation,
  };
};

export default useGetScheduledConversationDetail;
