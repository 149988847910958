import { QUERY_KEY } from 'src/features/my-account/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import { getFinalScoresUrl } from 'src/shared/services/url/UrlSis';

import { STATUS_MAP } from 'src/features/grading/tables/GradingManagementTable/helpers';

const useStudentFinalScore = (params) => {
  return useGetOne(
    [QUERY_KEY.STUDENT_FINAL_SCORE, params],
    getFinalScoresUrl(),
    {
      'assessment.status': STATUS_MAP.APPROVED.id,
      expand: 'subject,scoreValue',
      'per-page': 10000,
      sort: '-created_at',
      ...params,
    },
    false,
    false,
    {
      enabled: Boolean(params.user_id) && Boolean(params.institution_period_id),
    },
  );
};

export default useStudentFinalScore;
