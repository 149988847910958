import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import { Form as FormikForm } from 'formik';

import AttachmentField from './AttachmentField';
import AutocompleteField from './AutocompleteField';
import BlockField from './BlockField';
import CheckField from './CheckField';
import ColorPickerField from './ColorPickerField';
import CurrencyField from './CurrencyField';
import DateField from './DateField';
import DateTimeField from './DateTimeField';
import FieldGroup from './FieldGroup';
import FileField from './FileField';
import MultipleSelectField from './MultipleSelectField';
import RadioField from './RadioField';
import RadioGroup from './RadioGroup';
import RichTextField from './RichTextField';
import SearchField from './SearchField';
import SelectField from './SelectField';
import SubmitButton from './SubmitButton';
import SwitchField from './SwitchField';
import TextField from './TextField';
import TimeField from './TimeField';
import DropZoneField from './DropZoneField';
import BaseSubmitButton from './BaseSubmitButton';
import ChipsSelector from './ChipsSelector';
import CheckSelector from './CheckSelector';
import CheckSelectorExpandable from './CheckSelectorExpandable';
import CheckToggleField from './CheckToggleField';

const Form = ({ children, gridProps, ...rest }) => {
  return (
    <FormikForm {...rest}>
      <Grid container spacing={2} {...gridProps}>
        {children}
      </Grid>
    </FormikForm>
  );
};

Form.propTypes = {
  children: PropTypes.any,
  gridProps: PropTypes.object,
};

export {
  AttachmentField,
  AutocompleteField,
  BlockField,
  CheckField,
  ColorPickerField,
  CurrencyField,
  DateField,
  DateTimeField,
  FieldGroup,
  FileField,
  MultipleSelectField,
  RadioField,
  RadioGroup,
  RichTextField,
  SearchField,
  SelectField,
  SubmitButton,
  SwitchField,
  TextField,
  TimeField,
  DropZoneField,
  BaseSubmitButton,
  ChipsSelector,
  CheckSelector,
  CheckSelectorExpandable,
  CheckToggleField,
};
export default Form;
