import { useQueryClient } from '@tanstack/react-query';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { useDelete } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { paymentManagementConceptsById } from 'src/shared/services/url/UrlPaymentManagement';

const ERROR_MESSAGE = t`Hubo un error al intentar eliminar la categoría`;

const useDeleteConcept = (conceptId) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useDelete(
    paymentManagementConceptsById(conceptId),
    {
      enabled: Boolean(conceptId),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.CONCEPTS],
          refetchType: 'all',
        });
        enqueueSnackbar(t`La categoría ha sido eliminada con éxito`, {
          variant: 'success',
        });
      },
      onError: ({ message }) => {
        enqueueSnackbar(message ?? ERROR_MESSAGE, { variant: 'error' });
      },
    },
    false,
  );
};

export default useDeleteConcept;
