import { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

const useDownload = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadedFile, setDownloadedFile] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleDownload = useCallback(
    async (fileUrl) => {
      setIsDownloading(true);
      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const fileName = fileUrl.split('/').pop();

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        setDownloadedFile(new File([blob], fileName));

        a.href = url;
        a.download = fileName;
        a.click();
      } catch (error) {
        enqueueSnackbar(t`Hubo un error al intentar descargar el archivo.`, {
          variant: 'error',
        });
      } finally {
        setIsDownloading(false);
      }
    },
    [enqueueSnackbar],
  );

  return {
    isDownloading,
    downloadedFile,
    handleDownload,
  };
};

export default useDownload;
