import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link as RRDLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

const Link = forwardRef(({ to, href, ...props }, ref) => {
  const anchor = to || href;

  return <MuiLink component={RRDLink} to={anchor} ref={ref} {...props} />;
});

Link.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
};

export default Link;
