import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { ReactComponent as BoldIcon } from './svg/bold.svg';
import { ReactComponent as ItalicIcon } from './svg/italic.svg';
import { ReactComponent as ListIcon } from './svg/list.svg';
import { ReactComponent as AttachmentIcon } from './svg/paperclip.svg';
import { ReactComponent as EmojiIcon } from './svg/smile.svg';
import { ReactComponent as FormatIcon } from './svg/type.svg';
import { ReactComponent as UnderlineIcon } from './svg/underline.svg';
import { ReactComponent as DesktopIcon } from './svg/desktop.svg';
import { ReactComponent as GoogleDriveIcon } from './svg/google-drive.svg';
import { ReactComponent as OneDriveIcon } from './svg/one-drive.svg';
import { ReactComponent as DropboxIcon } from './svg/dropbox.svg';
import { ReactComponent as LinkIcon } from './svg/link.svg';

const EditorIcons = () => {
  const svgIconStyle = {
    width: 16,
    height: 16,
    '& path, rect': {
      fill: 'transparent',
    },
  };
  return {
    BoldIcon: ReactDOMServer.renderToString(
      <BoldIcon className={svgIconStyle} />,
    ),
    ItalicIcon: ReactDOMServer.renderToString(
      <ItalicIcon className={svgIconStyle} />,
    ),
    ListIcon: ReactDOMServer.renderToString(
      <ListIcon className={svgIconStyle} />,
    ),
    AttachmentIcon: ReactDOMServer.renderToString(
      <AttachmentIcon className={svgIconStyle} />,
    ),
    EmojiIcon: ReactDOMServer.renderToString(
      <EmojiIcon className={svgIconStyle} />,
    ),
    FormatIcon: ReactDOMServer.renderToString(
      <FormatIcon className={svgIconStyle} />,
    ),
    UnderlineIcon: ReactDOMServer.renderToString(
      <UnderlineIcon className={svgIconStyle} />,
    ),
    DesktopIcon: ReactDOMServer.renderToString(
      <DesktopIcon className={svgIconStyle} />,
    ),
    GoogleDriveIcon: ReactDOMServer.renderToString(
      <GoogleDriveIcon className={svgIconStyle} />,
    ),
    OneDriveIcon: ReactDOMServer.renderToString(
      <OneDriveIcon className={svgIconStyle} />,
    ),
    DropboxIcon: ReactDOMServer.renderToString(
      <DropboxIcon className={svgIconStyle} />,
    ),
    LinkIcon: ReactDOMServer.renderToString(
      <LinkIcon className={svgIconStyle} />,
    ),
  };
};

export default EditorIcons;
