import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Stack, Typography } from '@mui/material';

import SwitchField from '../SwitchField';
import useStyles from './styles';

const BlockField = ({
  title,
  subtitle,
  name,
  disabled,
  gridProps = { xs: 12 },
  FieldComponent = SwitchField,
  onChange,
  vertical,
  checkPosition = 'end',
  titleProps,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      className={classes.root}
      alignItems="center"
      flexDirection={!vertical && 'row'}
      {...gridProps}
    >
      <Grid item xs={vertical ? 0 : 10}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="body1" className={classes.title} {...titleProps}>
            {title}
          </Typography>

          {checkPosition === 'title' && (
            <FieldComponent
              name={name}
              disabled={disabled}
              onChange={onChange}
              {...(!vertical && {
                gridProps: { sx: { '& fieldset': { alignItems: 'end' } } },
              })}
            />
          )}
        </Stack>

        {subtitle && (
          <Typography variant="body2" className={classes.subtitle}>
            {subtitle}
          </Typography>
        )}
      </Grid>

      {checkPosition === 'end' && (
        <Grid item xs={vertical ? 0 : 2}>
          <FieldComponent
            name={name}
            disabled={disabled}
            onChange={onChange}
            {...(!vertical && {
              gridProps: { sx: { '& fieldset': { alignItems: 'end' } } },
            })}
          />
        </Grid>
      )}
    </Grid>
  );
};

BlockField.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  gridProps: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  FieldComponent: PropTypes.func,
  vertical: PropTypes.bool,
  checkPosition: PropTypes.string,
  titleProps: PropTypes.object,
};

export default BlockField;
