import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { singleFamilyTaxInformationUrl } from 'src/shared/services/url/UrlPaymentManagement';
import Api from 'src/shared/services/api/api';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const SUCCESS_MESSAGE = t`Datos de facturación eliminados con éxito.`;
const ERROR_MESSAGE = t`Hubo un error al intentar eliminados los datos de facturación`;

const useDeleteFamilyTaxInformation = (showSuccesMessage = true) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const apiDelete = (familyTaxInformationId, params = {}) =>
    Api.delete({
      url: singleFamilyTaxInformationUrl(familyTaxInformationId),
      data: params,
    });

  return useMutation(apiDelete, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY.FAMILY_TAX_INFORMATION]);
      if (showSuccesMessage) {
        enqueueSnackbar(SUCCESS_MESSAGE, { variant: 'success' });
      }
    },
    onError: ({ message: errorMessage }) => {
      enqueueSnackbar(errorMessage ?? ERROR_MESSAGE, { variant: 'error' });
    },
  });
};

export default useDeleteFamilyTaxInformation;
