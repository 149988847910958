/* eslint-disable consistent-return */
import { useSnackbar } from 'notistack';
import { DEFAULT_ERROR_MESSAGE } from 'src/config/constants';

export const useErrorMessage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const withErrorMessage = (callback) => async (params) => {
    try {
      return await callback(params);
    } catch ({ message: error, response }) {
      const backendMessage = error?.message ?? response?.data?.message ?? error;
      enqueueSnackbar(backendMessage ?? DEFAULT_ERROR_MESSAGE, {
        variant: 'error',
      });
    }
  };

  return withErrorMessage;
};
