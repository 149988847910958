import { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import Compressor from 'compressorjs';
import { useDebouncedCallback } from 'use-debounce';

import { FilesDropzoneContext } from 'src/shared/context/FileDropzoneContext';
import { generateImagePreview } from 'src/shared/utils/generateImagePreview';
import {
  FILE_UPLOAD_TYPE_DEFAULT,
  FILE_UPLOAD_TYPE_VIDEO,
  VIMEO_BLACKLIST_FOLDERS,
} from 'src/config/constants';
import { getFileType } from 'src/shared/utils/fileHelper';
import waitTime from 'src/shared/utils/wait';

import {
  generateFileToken,
  uploadFileToAzure,
  uploadFileToVimeo,
} from 'src/shared/services/uploadService';
import useIsMountedRef from '../useIsMountedRef';

const useUploads = (dropzoneId, subFolder) => {
  const isFirstFetch = useIsMountedRef();
  const user = useSelector((state) => state?.user);
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedInstitutionPeriodId,
    data: { id: userId },
  } = user;
  const [progressUpdate, setProgressUpdate] = useState({});
  const [cancelTokens, setCancelTokens] = useState({});
  const [rejectedFileList, setRejectedFileList] = useState([]);
  const {
    droppedFiles,
    setDroppedFiles,
    postFiles,
    setPostFiles,
    setIsUploading,
  } = useContext(FilesDropzoneContext);
  const [alreadyUploadedFiles, setAlreadyUploadedFiles] = useState(
    postFiles ?? [],
  );
  const MAX_WIDTH = 1920;
  const MAX_WIDTH_THUMBNAIL = 500;
  const QUALITY = 0.8;
  const CONVERT_SIZE = 2000000;
  const COMPRESS_BLACKLIST_EXTENSIONS = useMemo(() => ['.gif'], []);
  const QUALITY_THUMBNAIL = 0.7;

  const getFileInfo = useCallback((file = {}) => {
    const fileExtension = `.${file.name?.split('.').pop()}`;
    const fileType = getFileType(fileExtension);
    return {
      isPicture: fileType === 'picture',
      isVideo: fileType === 'video',
      fileExtension,
    };
  }, []);

  const handleUpdateEditorFiles = useCallback(
    (totalFiles, remove, hasRejected = false) => {
      if (window.tinymce) {
        window.tinymce.execCommand('editorChange', null, {
          editorId: dropzoneId,
          action: remove ? 'remove' : 'add',
          totalFiles,
          hasRejected,
        });
      }
    },
    [dropzoneId],
  );

  const handleUploadProgress = useCallback(
    (id, progressEvent = { loaded: 0, total: 100 }) => {
      const percentCompleted =
        (progressEvent.loaded * 100) / progressEvent.total;
      setProgressUpdate((prevProgress) => ({
        ...prevProgress,
        [id]: { progress: percentCompleted },
      }));
    },
    [setProgressUpdate],
  );

  const handleReplaceUploadedFile = useCallback(
    (fileToReplace) => {
      setPostFiles((oldFiles) =>
        oldFiles.map((oldFile) => {
          if (oldFile.id === fileToReplace.id) {
            return fileToReplace;
          }
          return oldFile;
        }),
      );
    },
    [setPostFiles],
  );

  const handleCompressImage = useCallback(
    async (file, isThumbnail) => {
      const { isPicture, fileExtension } = getFileInfo(file);

      if (
        !file ||
        !isPicture ||
        COMPRESS_BLACKLIST_EXTENSIONS.includes(fileExtension)
      ) {
        return file;
      }

      try {
        const compressImage = new Promise((resolve, reject) => {
          // eslint-disable-next-line no-new
          new Compressor(file, {
            quality: isThumbnail ? QUALITY_THUMBNAIL : QUALITY,
            maxWidth: isThumbnail ? MAX_WIDTH_THUMBNAIL : MAX_WIDTH,
            convertSize: CONVERT_SIZE,
            success(compressedFile) {
              resolve(compressedFile);
            },
            error({ message }) {
              reject(message);
            },
          });
        });
        return await compressImage;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return enqueueSnackbar(t`Ha ocurrido un error al subir el archivo.`, {
          variant: 'error',
        });
      }
    },
    [COMPRESS_BLACKLIST_EXTENSIONS, enqueueSnackbar, getFileInfo],
  );

  const handleUploadToStorage = useCallback(
    async (file, thumbnail) => {
      if (file.completed === false) {
        try {
          const { token, filename } = await generateFileToken(
            file.extension,
            subFolder,
          );
          const fileUrl = token.split('?se')[0];
          const fileData = {
            id: file.id,
            storage_file_name: filename,
            original_file_name: file.name,
            type: file.type,
            size: file.size,
            preview: file.preview,
            type_id: FILE_UPLOAD_TYPE_DEFAULT,
            file_url: fileUrl,
          };

          try {
            if (thumbnail) {
              const { token: thumbnailToken } = await generateFileToken(
                thumbnail.extension,
                subFolder,
              );
              const thumbnailUrl = thumbnailToken.split('?se')[0];
              await uploadFileToAzure(
                thumbnailToken,
                thumbnail,
                null,
                setCancelTokens,
              );
              fileData.thumbnail_images = thumbnailUrl;
            }

            await uploadFileToAzure(
              token,
              file,
              (progressEvent) => {
                handleUploadProgress(file.id, progressEvent);
              },
              setCancelTokens,
            );
            handleReplaceUploadedFile(fileData);
          } catch ({ message }) {
            const isManuallyCanceled = message === 'Canceled';
            if (!isManuallyCanceled) {
              enqueueSnackbar(t`Ha ocurrido un error al subir el archivo.`, {
                variant: 'error',
              });
            }
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('Error getting token: ', error);
        }
      }
    },
    [
      enqueueSnackbar,
      handleReplaceUploadedFile,
      handleUploadProgress,
      subFolder,
    ],
  );

  const handleVimeoUpload = useCallback(
    async (
      file,
      fileName = `BLD_PID${selectedInstitutionPeriodId}_U${userId}`,
    ) => {
      if (file.completed === false) {
        const fileData = {
          id: file.id,
          original_file_name: file.name,
          mime_type: 'video/mp4',
          size: file.size,
          type_id: FILE_UPLOAD_TYPE_VIDEO,
        };

        try {
          const vimeoUpload = new Promise((resolve, reject) => {
            const updateVideoData = (videoLink) => {
              fileData.storage_file_name = videoLink;
              handleReplaceUploadedFile(fileData);
              resolve();
            };

            uploadFileToVimeo({
              file,
              fileName,
              onProgress: handleUploadProgress,
              onReject: reject,
              onComplete: updateVideoData,
            });
          });
          await vimeoUpload;
        } catch (error) {
          enqueueSnackbar(t`Ha ocurrido un error al subir el archivo.`, {
            variant: 'error',
          });
        }
      }
    },
    [
      enqueueSnackbar,
      handleReplaceUploadedFile,
      handleUploadProgress,
      selectedInstitutionPeriodId,
      userId,
    ],
  );

  const handleParseFile = useCallback(
    async (file) => {
      const { isPicture, isVideo, fileExtension } = getFileInfo(file);
      const compressedFile = await handleCompressImage(file);
      let compressedThumbnail;
      let parsedThumbnail;

      if (isPicture) {
        await generateImagePreview(compressedFile);
        compressedThumbnail = await handleCompressImage(file, true);
      }

      const parsedFile = Object.assign(compressedFile, {
        id: nanoid(),
        extension: fileExtension,
        completed: false,
      });

      if (compressedThumbnail) {
        parsedThumbnail = Object.assign(compressedThumbnail, {
          id: nanoid(),
          extension: fileExtension,
          completed: false,
        });
      }

      setPostFiles((oldFiles) => [...oldFiles, parsedFile]);
      handleUploadProgress(file.id);
      handleUpdateEditorFiles(1, false);
      await waitTime(100);

      if (isVideo && !VIMEO_BLACKLIST_FOLDERS.includes(subFolder)) {
        await handleVimeoUpload(parsedFile);
      } else {
        await handleUploadToStorage(parsedFile, parsedThumbnail);
      }
    },
    [
      getFileInfo,
      handleCompressImage,
      handleUpdateEditorFiles,
      handleUploadProgress,
      handleUploadToStorage,
      handleVimeoUpload,
      setPostFiles,
      subFolder,
    ],
  );

  const handleUpload = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length) {
        setIsUploading(true);
        const filesToUpload = acceptedFiles.map((file) =>
          handleParseFile(file),
        );

        await Promise.allSettled(filesToUpload);

        setIsUploading(false);
      }
    },
    [handleParseFile, setIsUploading],
  );

  const handleRemoveFile = (removeFile) => {
    setProgressUpdate((oldProgress) => {
      delete oldProgress[removeFile.id];
      return oldProgress;
    });

    setPostFiles((oldFiles) =>
      oldFiles.filter((file) => file.id !== removeFile.id),
    );
    handleUpdateEditorFiles(1, true);

    if (cancelTokens[removeFile.id]) {
      cancelTokens[removeFile.id].cancel('Canceled');
    }
  };

  const handleDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length) {
        const files = [...acceptedFiles];
        if (setDroppedFiles) {
          setDroppedFiles([]);
        }
        handleUpload(files);
      }

      if (rejectedFiles?.length) {
        const rejected = rejectedFiles.filter(
          (file) => getFileType(file.type) !== 'video',
        );

        setRejectedFileList(rejected);

        if (rejected?.length > 0 && rejectedFileList?.length === 0) {
          handleUpdateEditorFiles(0, false, true);
        }

        const videoFiles = rejectedFiles.filter(
          (file) => getFileType(file.type) === 'video',
        );
        if (setDroppedFiles) {
          setDroppedFiles([]);
        }
        handleUpload(videoFiles);
      } else {
        setRejectedFileList([]);
      }
    },
    [
      handleUpload,
      setDroppedFiles,
      setRejectedFileList,
      rejectedFileList,
      handleUpdateEditorFiles,
    ],
  );

  const handleInitPostFiles = useDebouncedCallback((totalFiles) => {
    handleUpdateEditorFiles(totalFiles, false);
  }, 500);

  useEffect(() => {
    if (droppedFiles?.length) {
      handleDrop([...droppedFiles]);
    }
  }, [droppedFiles, handleDrop]);

  useEffect(() => {
    if (!postFiles?.length > 0 && alreadyUploadedFiles.length) {
      handleUpdateEditorFiles(0, true);
    }
    setAlreadyUploadedFiles(postFiles ?? []);
  }, [
    alreadyUploadedFiles.length,
    handleUpdateEditorFiles,
    postFiles,
    setIsUploading,
  ]);

  useEffect(() => {
    if (isFirstFetch.current && alreadyUploadedFiles.length) {
      isFirstFetch.current = false;
      handleInitPostFiles(alreadyUploadedFiles.length);
    }
  }, [alreadyUploadedFiles.length, handleInitPostFiles, isFirstFetch]);

  return {
    handleRemoveFile,
    handleDrop,
    progressUpdate,
    postFiles,
    rejectedFileList,
    setPostFiles,
  };
};

export default useUploads;
