import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { usePut } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { updateDebtReminder } from 'src/shared/services/url/UrlPaymentManagement';

const useUpdateDebtReminder = (reminderId) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return usePut(
    updateDebtReminder(reminderId),
    {
      onSuccess: () => {
        enqueueSnackbar(t`Recordatorio actualizado con éxito.`, {
          variant: 'success',
        });
      },
      onError: ({ message }) => {
        enqueueSnackbar(
          message ?? t`Hubo un error al intentar actualizar el recordatorio`,
          {
            variant: 'error',
          },
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries([QUERY_KEY.PAYMENT_DEBT_REMINDER], {
          refetchType: 'all',
        });
      },
    },
    false,
  );
};

export default useUpdateDebtReminder;
