import { CircularProgress, List, ListSubheader, Box } from '@mui/material';
import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';
import { useLocation } from 'react-router';

import { isItemSelected } from 'src/features/sidebar/utils';
import {
  staggerListContainer,
  staggerListItem,
} from 'src/features/sidebar/animations';

import NavigationItem from './NavigationItem';

const defaultIsItemDisabled = (item) => item.disabled;

const NavigationList = (
  {
    subheader,
    options,
    isLoading,
    onClickItem,
    isItemDisabled = defaultIsItemDisabled,
    renderItemAction,
    ...props
  },
  ref,
) => {
  const location = useLocation();

  if (isLoading) {
    return (
      <Box
        component={motion.div}
        variants={staggerListItem}
        sx={{ my: 2, display: 'flex', justifyContent: 'center' }}
      >
        <CircularProgress size={14} color="primary" />
      </Box>
    );
  }
  return (
    <List
      {...props}
      component={motion.nav}
      ref={ref}
      variants={staggerListContainer}
      initial="hidden"
      animate="visible"
      exit="exit"
      {...(subheader && {
        subheader: <ListSubheader component="div">{subheader}</ListSubheader>,
      })}
    >
      {!isLoading &&
        options.map((option, index) => {
          const disabled = isItemDisabled(option);
          const selected = isItemSelected(option, location.pathname);
          const action = renderItemAction?.(option);
          const Option = option.Component ?? NavigationItem;
          const isLastItem = index === options.length - 1;

          return (
            <motion.span
              key={`${option.id}-${index}`}
              variants={staggerListItem}
            >
              <Option
                isLastItem={isLastItem}
                selected={selected}
                disabled={disabled}
                onClick={onClickItem}
                action={action}
                divider={option.divider}
                {...option}
              >
                {option.children}
              </Option>
            </motion.span>
          );
        })}
    </List>
  );
};

export default forwardRef(NavigationList);
