import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ROLE_LIST } from 'src/config/general';
import parseExternalId from 'src/shared/utils/parseExternalId';
import {
  filterLevelsByPermits,
  filterDivisionsByPermits,
  filterSubjectsByPermits,
} from './helpers';

const DEFAULT_DATA_BY_ROLE = {
  admin: [],
  executive: [],
  preceptor: [],
  parent: [],
  students: [],
};

const useAvailableStructure = (modulePermits = null) => {
  const user = useSelector((state) => state.user);
  const ROLE_KEYS = useMemo(() => Object.values(ROLE_LIST), []);
  const {
    selectedInstitution,
    selectedInstitutionPeriodId,
    relations,
    userIs,
    role: userRoles,
  } = user;

  const userRoleKeys = useMemo(
    () =>
      userRoles.reduce((acc, { role_name }) => {
        if (ROLE_KEYS.includes(role_name)) {
          return [...acc, role_name];
        }

        return acc;
      }, []),
    [ROLE_KEYS, userRoles],
  );

  const institutionStructure =
    selectedInstitution?.periods?.find(
      (period) => period.id === selectedInstitutionPeriodId, ///BUG: WHEN THIS CONDITION IS SUCCESS TEST FALLS IN AN INFINITE LOOP, SO IT´S IMPOSIBLE MAKE TEST
    ) ?? {};
  const [levels, setLevels] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [levelsByRoles, setLevelsByRoles] = useState(DEFAULT_DATA_BY_ROLE);
  const [divisionsByRole, setDivisionsByRole] = useState(DEFAULT_DATA_BY_ROLE);
  const [subjectsByRole, setSubjectsByRole] = useState(DEFAULT_DATA_BY_ROLE);
  const [extracurricularsByRole, setExtracurricularsByRole] =
    useState(DEFAULT_DATA_BY_ROLE);
  const { extracurriculars } = institutionStructure;
  const parsedExtracurricular = useMemo(
    () =>
      extracurriculars?.map((el) => ({
        ...el,
        externalId: parseExternalId(el.external_id),
      })),
    [extracurriculars],
  );
  const highestRole = Object.values(ROLE_LIST).find(
    (roleKey) => userIs[roleKey] === true,
  );

  const findExtraCurricularRoles = useCallback(
    (extracurricularsId) => {
      const currentInstitutionRelations =
        relations.find(
          (rel) => rel.institution_period_id === selectedInstitutionPeriodId,
        ) ?? {};
      const { role_subjects } = currentInstitutionRelations;
      const extraSubjectRoles = role_subjects?.reduce(
        (subjectRoles, item) => [
          ...subjectRoles,
          ...(item.subject_ids.includes(extracurricularsId)
            ? [item.role_name]
            : []),
        ],
        [],
      );

      return extraSubjectRoles;
    },
    [relations, selectedInstitutionPeriodId],
  );

  const handleParseExtracurriculars = useCallback(
    (extraCurricularSubjects) => {
      return (
        extraCurricularSubjects?.map((item) => ({
          ...item,
          subjectId: item.id,
          subjectName: item.name,
          divisionName: '-',
          levelName: '-',
          externalId: parseExternalId(item.external_id),
          roles: findExtraCurricularRoles(item.id),
        })) ?? []
      );
    },
    [findExtraCurricularRoles],
  );

  const handleExtractStructureFromLevels = useCallback(
    (levelsArray = []) => {
      const divisionsArray = [];
      const subjectsArray = [];
      const extracurricularsArray = [];
      const parsedExtracurriculars =
        handleParseExtracurriculars(extracurriculars);

      parsedExtracurriculars.forEach((extracurricular) => {
        if (
          userRoleKeys.some((roleKey) =>
            extracurricular.roles.includes(roleKey),
          )
        ) {
          subjectsArray.push(extracurricular);
          extracurricularsArray.push(extracurricular);
        }
      });

      levelsArray.forEach((level) =>
        level.courses.forEach((course) =>
          course.divisions.forEach((division) => {
            const divisionBase = {
              divisionId: division.id,
              divisionName: `${course.name} ${division.name}`,
              courseId: course.id,
              courseName: course.name,
              levelId: level.id,
              levelName: level.name,
              roles: division.roles,
              externalId: parseExternalId(division.external_id),
            };
            divisionsArray.push(divisionBase);
            division.subjects.forEach((subject) => {
              subjectsArray.push({
                ...divisionBase,
                ...subject,
                externalId: parseExternalId(subject.external_id),
                subjectId: subject.id,
                subjectName: subject.name,
                divisionId: division.id,
                divisionName: `${course.name} ${division.name}`,
              });
            });
          }),
        ),
      );
      return { divisionsArray, subjectsArray, extracurricularsArray };
    },
    [extracurriculars, handleParseExtracurriculars, userRoleKeys],
  );

  const getStructure = useCallback(
    (accessibleLevels) => {
      if (accessibleLevels?.length) {
        const { divisionsArray, subjectsArray } =
          handleExtractStructureFromLevels(accessibleLevels);
        const accessibleDivisions = filterDivisionsByPermits(
          divisionsArray,
          modulePermits,
        );
        const accessibleSubjects = filterSubjectsByPermits(
          subjectsArray,
          modulePermits,
        );

        setDivisions(accessibleDivisions);
        setSubjects(accessibleSubjects);
      }
    },
    [handleExtractStructureFromLevels, modulePermits],
  );

  const findDivisionById = useCallback(
    (divisionId) => divisions.find((el) => el.divisionId === divisionId),
    [divisions],
  );

  const fetchLevelsByRole = useCallback(
    (role) => {
      const currentInstitutionRelations = relations.find(
        (rel) => rel.institution_period_id === selectedInstitutionPeriodId,
      );
      if (levels.length) {
        const { role_divisions } = currentInstitutionRelations ?? {};
        const filteredRoleDivisions =
          role_divisions?.find((div) => div.role_name === role) ?? {};
        const { division_ids } = filteredRoleDivisions ?? {};
        const filteredLevelsIds = divisions
          .filter((div) => division_ids?.includes(div.divisionId))
          .map((el) => el.levelId);
        return levels.filter((el) => filteredLevelsIds.includes(el.id));
      }

      return [];
    },
    [divisions, levels, relations, selectedInstitutionPeriodId],
  );

  const getStructureByRole = useCallback(() => {
    const roleLevels = {};
    const roleDivisions = {};
    const roleSubjects = {};
    const roleExtracurriculars = {};

    for (let index = 0; index < ROLE_KEYS.length; index++) {
      const role = ROLE_KEYS[index];
      const roleLevel = fetchLevelsByRole(role);
      const { divisionsArray, subjectsArray, extracurricularsArray } =
        handleExtractStructureFromLevels(roleLevel);

      roleLevels[role] = roleLevel;
      roleDivisions[role] = divisionsArray.filter((el) =>
        el.roles.includes(role),
      );
      roleSubjects[role] = subjectsArray;
      roleExtracurriculars[role] = extracurricularsArray;
    }

    setLevelsByRoles(roleLevels);
    setDivisionsByRole(roleDivisions);
    setSubjectsByRole(roleSubjects);
    setExtracurricularsByRole(roleExtracurriculars);
  }, [ROLE_KEYS, fetchLevelsByRole, handleExtractStructureFromLevels]);

  useEffect(() => {
    const accessibleLevels = filterLevelsByPermits(
      institutionStructure.levels,
      modulePermits,
    );

    setLevels(accessibleLevels);
    getStructure(accessibleLevels);
  }, [getStructure, institutionStructure.levels, modulePermits]);

  useEffect(() => {
    if (levels && levels.length) {
      getStructureByRole();
    }
  }, [getStructureByRole, levels]);

  return {
    getStructure,
    findDivisionById,
    fetchLevelsByRole,
    levels,
    divisions,
    subjects,
    extracurriculars: parsedExtracurricular,
    levelsByRoles,
    divisionsByRole,
    subjectsByRole,
    extracurricularsByRole,
    highestRole,
  };
};

export default useAvailableStructure;
