const INSTITUTIONS_DIALOGS = {
  PERIOD_CREATE_DIALOG: 'PeriodCreateDialog',
  PERIOD_PROMOTIONS_MAP_DIALOG: 'PeriodPromotionsMapDialog',
  PERIOD_CREATE_IN_PROGRESS_DIALOG: 'PeriodCreateInProgressDialog',
  PERIOD_FAIL_DIALOG: 'PeriodFailDialog',
  PERIOD_NEW_CYCLE_DIALOG: 'PeriodNewCycleDialog',
  PERIOD_ACTIVATE_DIALOG: 'PeriodActivateDialog',
  PERIOD_MODULES_INFO_DIALOG: 'PeriodModulesInfoDialog',
  PERIOD_REMOVE_CONFIRM: 'PeriodRemoveConfirm',
};

export default INSTITUTIONS_DIALOGS;
