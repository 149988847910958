import { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';

import useNavbarSections from './useNavbarSections';

const useNavBar = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const sections = useNavbarSections();

  const options = useMemo(() => {
    if (isMobile) {
      return sections.map((section) => ({
        ...section,
        title: section.subheader,
      }));
    }

    return sections;
  }, [sections, isMobile]);

  return options;
};

export default useNavBar;
