import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  previewDialog: {
    margin: 0,
    padding: 0,
    '& #react-doc-viewer': {
      background: 'transparent',
    },
    '& #react-doc-viewer #header-bar': {
      display: 'none',
    },
    '& #image-img': {
      maxWidth: '100%',
      maxHeight: 680,
      width: 'auto',
    },
    '& #image-renderer': {
      backgroundColor: 'transparent',
    },
  },
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer',
    },
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  image: {
    width: 130,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    margin: `${theme.spacing(2)} 0`,
  },
  progress: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  uploadSuccess: {
    // color: '#6CD36C',
    color: '#FFF',
    position: 'absolute',
    zIndex: 2,
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  maxSizeIndicator: {
    color: theme.palette.text.secondary,
    fontWeight: 'normal',
    opacity: 0.8,
  },
  fileActions: {
    opacity: 0,
    transition: '0.3s',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    position: 'absolute',
    top: 0,
    zIndex: 2,
    textAlign: 'center',
    '&:hover': {
      visibility: 'visible',
      opacity: 1,
    },
  },
  hide: {
    display: 'none',
  },
}));

export default useStyles;
