import { useGetList } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';
import useUserInfo from 'src/features/payment-management/hooks/useUserInfo';
import { getMessagesUserSearch } from 'src/shared/services/url/UrlCommunication';

const useHighRoleContacts = ({ enabled, searchTerm, ...params }) => {
  const { selectedInstitutionPeriodId } = useUserInfo();

  return useGetList(
    [QUERY_KEY.CONTACTS_LIST, searchTerm, params],
    getMessagesUserSearch(selectedInstitutionPeriodId),
    {
      search_term: searchTerm,
      expand:
        'alias,custom_role,courses,authAssignments,parent.parent,children.children',
      sort: 'last_name',
      ...params,
    },
    true,
    true,
    { enabled },
  );
};

export default useHighRoleContacts;
