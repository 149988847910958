import { useMemo } from 'react';
import { getDefaultStore, useAtom, useAtomValue } from 'jotai';

import {
  batchDeleteRateUsersAtom,
  inputPaymentRateUsersFilterAtom,
  rateUsersPaginatedDataAtom,
  selectedRateAtom,
  selectionPaymentRateUsersAtom,
} from 'src/features/payment-management/atoms';
import { CampaignConfigAtom } from 'src/features/payment-management/atoms/CampaignConfigDraftAtom';
import useRateUsersPaginated from 'src/features/payment-management/hooks/data/concepts/useRateUsersPaginated';
import { useRemoveMember } from 'src/features/payment-management/hooks/data/concepts';
import useDownloadRateReportUser from 'src/features/payment-management/hooks/data/concepts/useDownloadRateReportUser';
import useGetBatchDeleteRateUsers from 'src/features/payment-management/hooks/data/concepts/useGetBatchDeleteRateUsers';

const usePaymentRateUsers = () => {
  const defaultStore = getDefaultStore();

  const selectedConcept = useAtomValue(selectedRateAtom);

  const [config, setConfig] = useAtom(CampaignConfigAtom, {
    store: defaultStore,
  });

  const [batchDeleteRateUsers, setBatchDeleteRateUsers] = useAtom(
    batchDeleteRateUsersAtom,
    {
      store: defaultStore,
    },
  );

  const [inputPaymentUsersFilter, setInputPaymentUsersFilter] = useAtom(
    inputPaymentRateUsersFilterAtom,
    {
      store: defaultStore,
    },
  );

  const [selectionPaymentRateUsersList, setSelectionPaymentRateUsersList] =
    useAtom(selectionPaymentRateUsersAtom, {
      store: defaultStore,
    });

  const [rateUsersPaginatedData, setRateUsersPaginatedData] = useAtom(
    rateUsersPaginatedDataAtom,
    {
      store: defaultStore,
    },
  );

  const {
    isRefetching: rateUsersPaginatedIsRefetching,
    pagination: rateUsersPaginatedPagination,
    refetch: refetchRateUsers,
  } = useRateUsersPaginated(
    {
      id: selectedConcept?.id,
      enabled: Boolean(selectedConcept?.id),
      search_term: inputPaymentUsersFilter,
    },
    { onSuccess: ({ data }) => setRateUsersPaginatedData(data) },
  );

  const { isLoading: removeIsLoading, mutate: removeRateMember } =
    useRemoveMember(selectedConcept?.id, {
      onSuccess: (data) => setBatchDeleteRateUsers(data?.data),
    });

  const { mutateAsync: downloadReportUser, isLoading: isLoadingReportUser } =
    useDownloadRateReportUser(selectedConcept?.id);

  const { data: deleteBatch } = useGetBatchDeleteRateUsers({
    batchDeleteRateUsersId: batchDeleteRateUsers?.id,
    queryParams: {
      enabled: !!batchDeleteRateUsers?.id,
      ...(batchDeleteRateUsers?.id && {
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: true,
        refetchInterval: 5000,
      }),
      onSuccess: (data) => setBatchDeleteRateUsers(data),
    },
  });

  const thereIsNoUsersSelected = useMemo(
    () => !selectionPaymentRateUsersList.usersSelected?.length,
    [selectionPaymentRateUsersList.usersSelected?.length],
  );

  const resetSectionPaymentRateUsersList = () =>
    setSelectionPaymentRateUsersList({
      isOpen: false,
      allSelected: false,
      count: 0,
      usersSelected: [],
    });

  return {
    batchDeleteRateUsers,
    config,
    deleteBatch,
    isLoadingReportUser,
    rateUsersPaginatedData,
    rateUsersPaginatedIsRefetching,
    rateUsersPaginatedPagination,
    removeIsLoading,
    selectedConcept,
    selectionPaymentRateUsersList,
    thereIsNoUsersSelected,
    downloadReportUser,
    refetchRateUsers,
    removeRateMember,
    resetSectionPaymentRateUsersList,
    setBatchDeleteRateUsers,
    setConfig,
    setInputPaymentUsersFilter,
    setSelectionPaymentRateUsersList,
  };
};

export default usePaymentRateUsers;
