import { t } from '@lingui/macro';
import * as Yup from 'yup';

const REQUIRED_TEXT = t`Debe escribir el DNI de su hijo/a para continuar`;

const ExternalLinkFormSchema = Yup.object().shape({
  students: Yup.string().required(REQUIRED_TEXT),
});

export default ExternalLinkFormSchema;
