import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import { getFamilyInformation } from 'src/shared/services/url/UrlUser';

const useFamilyInformation = ({ familyId }) => {
  return useGetOne(
    [QUERY_KEY.FAMILY_INFO, familyId],
    getFamilyInformation(familyId),
    {},
    false,
    false,
    { enabled: Boolean(familyId) },
  );
};

export default useFamilyInformation;
