import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Drawer, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    position: 'relative',
  },
}));

const BulkOperations = ({ open, className, children, ...rest }) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="bottom"
      open={open}
      PaperProps={{ elevation: 1 }}
      variant="persistent"
      {...rest}
    >
      <Box className={clsx(classes.root, className)}>{children}</Box>
    </Drawer>
  );
};

BulkOperations.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default BulkOperations;
