import formattedDataAR from 'src/features/payment-management/hooks/usePaymentGenerateBillsForm/formattedData/AR';
import formattedDataMX from 'src/features/payment-management/hooks/usePaymentGenerateBillsForm/formattedData/MX';
import formattedDataCol from 'src/features/payment-management/hooks/usePaymentGenerateBillsForm/formattedData/COL';

const getSelectedPaymentMethod = (paymentMethods, paymentMethodId) => {
  return paymentMethods.find(
    (paymentMethod) => paymentMethod.id === paymentMethodId,
  );
};

export {
  formattedDataAR,
  formattedDataMX,
  formattedDataCol,
  getSelectedPaymentMethod,
};
