import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Grid } from '@mui/material';
import getInitials from 'src/shared/utils/getInitials';
import useStyles from './styles';
import ProfilePhotoActions from './ProfilePhotoActions';

const ProfilePhoto = ({ user = {}, canEdit }) => {
  const classes = useStyles();
  const { photo, name, last_name } = user;
  const fullName = `${name} ${last_name}`;
  const userInitials = getInitials(fullName);

  return (
    <Grid container item xs={12}>
      <Grid
        item
        xs={12}
        sx={{
          paddingTop: '100%',
          position: 'relative',
        }}
      >
        <Box
          sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <Avatar
            src={photo}
            variant="square"
            className={classes.avatar}
            alt={`photo-user-${fullName}`}
          >
            {userInitials}
          </Avatar>
        </Box>
      </Grid>
      {canEdit && <ProfilePhotoActions user={user} />}
    </Grid>
  );
};

ProfilePhoto.propTypes = {
  canEdit: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    last_name: PropTypes.string,
    photo: PropTypes.string,
  }),
};

export default ProfilePhoto;
