import { useSelector } from 'react-redux';

import useUserInformation from 'src/features/my-account/hooks/data/useUserInformation';
import { BLOCKED_ACCOUNT_CHECK_INTERVAL } from 'src/shared/constants/settings';

const useUserIsDebtor = () => {
  const {
    data: { id: userId },
  } = useSelector(({ user }) => user);

  const { data: userData } = useUserInformation({
    userId: userId,
    enabled: Boolean(userId),
    refetchInterval: BLOCKED_ACCOUNT_CHECK_INTERVAL,
  });

  return userData?.is_debtor || false;
};

export default useUserIsDebtor;
