import { useMutation } from '@tanstack/react-query';
import { useErrorMessage } from '../common/useErrorMessage';
import Api from '../../api';

export const useDelete = (url, queryParams = {}, defaultError = true) => {
  const withErrorMessage = useErrorMessage();

  const apiDelete = (params = {}) =>
    Api.delete({
      url,
      data: params,
    });

  const mutationFunction = defaultError
    ? withErrorMessage(apiDelete)
    : apiDelete;

  return useMutation(mutationFunction, queryParams);
};
