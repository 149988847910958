const QUERY_KEY = {
  PICK_UP_LIST: 'PickUpList',
  PICK_UP_CONFIG: 'PickUpConfig',
  PICK_UP_REPORT: 'PickUpReport',
  PICK_UP_CATEGORIES: 'PickUpCategories',
  PICK_UP_CATEGORy_DAY: 'PickUpCategoryDay',
  PICK_UP_AUTHORIZED: 'PickUpAuthorized',
  PICK_UP_OUTPUT_RANGE: 'PickUpOutputRange',
  PICK_UP_REASON: 'PickUpReason',
  PICK_UP_SUBCATEGORIES: 'PickUSubCategories',
  PICK_UP_REPORT_BATCH_TRANSACTION: 'PickUpReportBatchTransaction',
};

export { QUERY_KEY };
