import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

/**
 * A component for guarding routes based on user roles and conditions.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to render.
 * @param {string[]} props.roles - An array of roles that are allowed to access the guarded route.
 * @param {string[]} [props.rolesBlackList] - An array of roles that are not allowed to access the guarded route.
 * @param {string} [props.redirectTo] - The URL to redirect if the user does not have the required role or condition fails.
 * @param {boolean} [props.hide] - An optional  boolean to hide the child components.
 * @returns {ReactNode|null} The child components if the user has the required role and condition is met, otherwise null.
 */
const Guard = ({
  children,
  roles = [],
  rolesBlackList = [],
  redirectTo,
  hide,
}) => {
  const history = useHistory();
  const userIs = useSelector(({ user }) => user.userIs);
  const hasRole =
    roles.some((role) => userIs[role]) &&
    !rolesBlackList.some((role) => userIs[role]);

  if (!hasRole || hide === true) {
    if (redirectTo) {
      return history.push(redirectTo);
    }
    return null;
  }

  return children;
};

Guard.propTypes = {
  children: PropTypes.node.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  rolesBlackList: PropTypes.arrayOf(PropTypes.string),
  redirectTo: PropTypes.string,
  hide: PropTypes.bool,
};

export default memo(Guard);
