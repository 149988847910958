import abstractSerializer from 'src/shared/services/api/query/serializer/abstractSerializer';
import { COUNTRY_CODE } from 'src/shared/constants/countryCode';

const deserializer = (data) => {
  if (data.extra_data?.aut_rvoe) {
    const levelsArray = JSON.parse(data.extra_data?.aut_rvoe || '');
    const formattedLevel = levelsArray?.length
      ? levelsArray?.reduce((acc, item) => {
          acc[`level-${item.level}`] = item.value;
          return acc;
        }, {})
      : [];
    return { ...data, ...formattedLevel };
  }
  return data;
};

const serialize = (data) => {
  // Filtrar las entradas del objeto que tienen valores null
  const filteredData = Object.fromEntries(
    Object.entries(data).filter(([_, value]) => value !== null),
  );

  delete filteredData.cert_saved;
  delete filteredData.key_saved;

  if (filteredData.country_code === COUNTRY_CODE.MEXICO) {
    const levelsArray = Object.keys(filteredData)
      .filter((key) => key.startsWith('level-'))
      .map((key) => ({
        level: parseInt(key.split('-')[1]),
        value: filteredData[key],
      }));
    if (levelsArray.length) {
      return {
        aut_rvoe: JSON.stringify(levelsArray),
      };
    }
    if (filteredData.cert?.length) {
      const body = {
        cert: filteredData.cert[0],
        key: filteredData.key[0],
        password: filteredData.password,
      };
      return body;
    }
  }

  if (filteredData.cert?.length) {
    const body = {
      cert: filteredData.cert[0],
    };
    return body;
  }

  return {
    ...filteredData,
    ...(filteredData.logo?.length && {
      logo:
        filteredData.logo[0]?.thumbnail_images || filteredData.logo[0]?.file,
    }),
  };
};

const institutionTaxInformationSerializer = abstractSerializer(
  serialize,
  deserializer,
);

export default institutionTaxInformationSerializer;
