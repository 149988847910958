import dayjs from 'dayjs';
import React from 'react';
import AttendanceStatusCell from './AttendanceStatusCell';
import WeekDayColumn from './WeekDayColumn';

const ATTENDANCE_STATUS_LIST = {
  EMPTY: 0,
  LOADED: 1,
  SENT: 2,
};

const getWeekColumns = (weekDays) =>
  weekDays.map((weekItem) => {
    const key = dayjs(weekItem).format('YYYY-MM-DD');
    return {
      key: `sentData.${key}`,
      title: <WeekDayColumn weekItem={weekItem} />,
      render: (props) => <AttendanceStatusCell {...props} keyData={key} />,
    };
  });

export { ATTENDANCE_STATUS_LIST, getWeekColumns };
