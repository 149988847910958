import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { Alert, Button, Snackbar } from '@mui/material';

import useStyles from './styles';
import router from 'src/shared/utils/router';

const NewYearManagerTrigger = memo(() => {
  const classes = useStyles();
  const { isNewYearManager } = useSelector(({ user }) => user);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={isNewYearManager}
    >
      <Alert
        classes={{ action: classes.action }}
        severity="warning"
        action={
          <Button
            color="inherit"
            size="small"
            href={router.configuration.newYear}
          >
            <Trans>COMENZAR</Trans>
          </Button>
        }
      >
        <Trans>
          Fuiste designado como encargado para el proceso de pase de año en tu
          institución
        </Trans>
      </Alert>
    </Snackbar>
  );
});

NewYearManagerTrigger.displayName = 'NewYearManagerTrigger';

export default NewYearManagerTrigger;
