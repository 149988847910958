import { matchPath } from 'react-router';

export const isNavbarOptionSelected = (navItem, pathname) => {
  if (navItem.items?.length > 0) {
    return navItem.items.some((item) => isNavbarOptionSelected(item, pathname));
  }

  return Boolean(
    matchPath(pathname, {
      path: navItem.href,
      exact: false,
      sensitive: false,
    }),
  );
};

export const isItemSelected = (option, pathname, selected) => {
  if (selected) {
    if (selected.id === option.id) {
      return true;
    }
    return false;
  }

  return option.prefix
    ? pathname.startsWith('/' + option.prefix)
    : isNavbarOptionSelected(option, pathname);
};
