import React from 'react';
import PropTypes from 'prop-types';
import { ListItemText, Tooltip } from '@mui/material';

import ConditionalWrapper from 'src/components/ConditionalWrapper';
import { formatUserRole } from 'src/shared/helpers';
import { ROLE_LIST } from 'src/config/general';

const DisplayName = ({ user, showCourse, showAlias, formattedCourse }) => {
  const {
    name,
    last_name,
    authAssignments,
    custom_role,
    role,
    courses,
    alias,
  } = user;
  const userName = `${last_name}, ${name}`;
  const userAlias = alias || userName;
  const displayName = showAlias ? userAlias : userName;

  const handleUserRoles = () => {
    if (custom_role) return [custom_role];
    if (authAssignments) {
      return authAssignments.flatMap((userRole) =>
        formatUserRole(userRole.item_name),
      );
    }

    // FALLBACK BC BACKEND PEOPLE
    // UPDATE: 17/08 - NO ME ACUERDO POR QUE ERA ESTO, PERO POR LAS DUDAS, DEJO EL FALLBACK
    return [formatUserRole(role)];
  };

  const userRoles = handleUserRoles();

  const isStudent = userRoles.includes(formatUserRole(ROLE_LIST.STUDENT));
  const course =
    showCourse && isStudent
      ? formattedCourse || (courses.length ? ` - ${courses[0]?.name}` : '')
      : '';

  return (
    <ConditionalWrapper
      wrapper={(children) => <Tooltip title={displayName}>{children}</Tooltip>}
      condition={displayName.length >= 30}
    >
      <ListItemText
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        primary={displayName}
        secondary={`${userRoles.join(', ')}${course}`}
      />
    </ConditionalWrapper>
  );
};

DisplayName.propTypes = {
  user: PropTypes.object,
  showCourse: PropTypes.bool,
  showAlias: PropTypes.bool,
  formattedCourse: PropTypes.string,
};

export default DisplayName;
