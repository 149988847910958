import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { formatUserRole } from 'src/shared/helpers';
import sortByName from 'src/shared/utils/sortByName';

const useRoles = ({ excludeRoles = [] } = {}) => {
  const { configuration } = useSelector((store) => ({
    configuration: store.configuration,
  }));

  const { roleTypes } = configuration;

  const roles = useMemo(() => {
    const roleList = Object.keys(roleTypes).map((roleId) => ({
      id: parseInt(roleId, 10),
      value: roleTypes[roleId],
      name: formatUserRole(roleTypes[roleId], true),
    }));

    return sortByName(roleList);
  }, [roleTypes]);

  const rolesFiltered = useMemo(
    () => roles.filter((rol) => !excludeRoles.includes(rol.value)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roles],
  );

  if (excludeRoles?.length > 0) {
    return rolesFiltered;
  }

  return roles;
};

export default useRoles;
