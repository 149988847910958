import { useCallback, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { useSetAtom } from 'jotai';

import {
  useStudentFinalScore,
  useStudentScore,
} from 'src/features/my-account/hooks';
import { sortByDateProp } from 'src/shared/utils/dates';
import { uniqueByKey } from 'src/shared/utils/uniqueByKey';
import { divisionSelectorAtom } from 'src/shared/atoms';
import { STATUS_MAP } from 'src/features/grading/tables/GradingManagementTable/helpers';

const filterScoresBySelection = (scores, selection) => {
  return scores.reduce((acc, score) => {
    const matchDivision = score.subject.division_id === selection.divisionId;
    const matchSubject = score.subject.id === selection.subjectId;

    if (
      (score.subject.is_extracurricular && !selection.subjectId) ||
      (score.subject.is_extracurricular && matchSubject)
    ) {
      acc.push(score);

      return acc;
    }

    const filter = selection.subjectId
      ? matchDivision && matchSubject
      : matchDivision;

    if (filter) {
      acc.push(score);
    }

    return acc;
  }, []);
};

const useStudentGradingInfo = (studentInfo) => {
  const setDivisionSelector = useSetAtom(divisionSelectorAtom);
  const [selectedPeriod, setSelectedPeriod] = useState({});
  const [selectedDivision, setSelectedDivision] = useState({});
  const [selectedSubject, setSelectedSubject] = useState({});
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [commentText, setCommentText] = useState('');

  const { data: finalScores, isLoading: isLoadingFinalScores } =
    useStudentFinalScore({
      user_id: studentInfo?.id,
      institution_period_id: selectedPeriod?.id,
      status: STATUS_MAP.APPROVED.id,
    });

  const { data: scores, isLoading: isLoadingScores } = useStudentScore({
    user_id: studentInfo?.id,
    institution_period_id: selectedPeriod?.id,
  });

  const unparsedScores = useMemo(
    () => [...(scores || []), ...(finalScores || [])],
    [finalScores, scores],
  );

  const studentDivisions = useMemo(() => {
    const divisions = studentInfo.course.map((course) => ({
      courseId: course.id,
      courseName: course.name,
      divisionId: course.division.id,
      divisionName: `${course.name} ${course.division.name}`,
      levelId: course.level_id,
    }));

    return uniqueByKey(divisions, 'divisionId');
  }, [studentInfo]);

  const studentSubjects = useMemo(() => {
    const subjects = unparsedScores.map((score) => ({
      name: score.subject.name,
      id: score.subject.id,
    }));

    return uniqueByKey(subjects, 'id');
  }, [unparsedScores]);

  const filteredScores = useMemo(() => {
    return filterScoresBySelection(unparsedScores, {
      divisionId: selectedDivision.divisionId,
      subjectId: selectedSubject.id,
    });
  }, [selectedDivision.divisionId, selectedSubject, unparsedScores]);

  const scoreList = useMemo(() => {
    const scoresArray = filteredScores.map((score) => {
      const isFinal = Boolean(score.data);

      return {
        isFinal,
        id: score.id,
        subjectName: score.subject.name,
        date: isFinal
          ? score.approved_at ?? score.created_at
          : score.assessment?.date,
        assessment: isFinal ? t`Final` : score.assessment?.title,
        scoreValue:
          score.scoreValue?.short_name ??
          score.scoreValue?.name ??
          score.numerical_value,
        comment: isFinal ? '' : score.comment,
        absent: score?.absent,
      };
    });

    return sortByDateProp(scoresArray, 'date', true);
  }, [filteredScores]);

  const handleChangePeriod = useCallback(
    (period) => {
      setSelectedPeriod(period);
      setSelectedSubject({});
      setDivisionSelector({});
    },
    [setSelectedPeriod, setDivisionSelector],
  );

  const handleChangeDivision = useCallback(
    (division) => {
      setSelectedDivision(division);
    },
    [setSelectedDivision],
  );

  const handleChangeSubject = useCallback(
    (subject) => {
      setSelectedSubject(subject);
    },
    [setSelectedSubject],
  );

  const handleClickCurrents = useCallback(() => {
    setSelectedSubject({});
  }, [setSelectedSubject]);

  const handleShowComment = useCallback((comment) => {
    setCommentText(comment);
    setOpenCommentDialog(true);
  }, []);

  return {
    scoreList,
    isLoading: isLoadingFinalScores || isLoadingScores,
    studentDivisions,
    studentSubjects,
    selectedSubject,
    openCommentDialog,
    commentText,
    handleShowComment,
    setOpenCommentDialog,
    handleChangePeriod,
    handleChangeDivision,
    handleChangeSubject,
    handleClickCurrents,
  };
};

export default useStudentGradingInfo;
