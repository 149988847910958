import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar/dist/simplebar.min.css';

const ScrollBar = forwardRef(({ children, ...props }, ref) => (
  <SimpleBar style={{ maxHeight: '100%' }} {...props}>
    {children}
  </SimpleBar>
));

ScrollBar.displayName = 'ScrollBar';

ScrollBar.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ScrollBar;
