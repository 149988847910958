import createRouter from './createRouter';

const profileRouter = createRouter('profile', {
  // PERFIL
  profile: (profileId) => `/perfiles/${profileId}`,
  attendances: (profileId) => `/perfiles/${profileId}/inasistencias`,
  authorized: (profileId) => `/perfiles/${profileId}/autorizados`,
  pickupCategory: (profileId) => `/perfiles/${profileId}/salidas-habituales`,
  grading: (profileId) => `/perfiles/${profileId}/calificaciones`,
  reportCard: (profileId) => `/perfiles/${profileId}/boletines`,
  primaryReport: (profileId) => `/perfiles/${profileId}/informes`,
  behavior: (profileId) => `/perfiles/${profileId}/seguimiento`,
  billing: (profileId) => `/perfiles/${profileId}/facturacion`,

  // LEGACY
  legacyProfile: (profileId) => `/perfil/index/${profileId}`,
});

export default profileRouter;
