import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { tablet } from 'src/shared/utils/breakpoints';

import { useBreakpoints } from 'src/shared/hooks';
import useUserInfo from 'src/features/payment-management/hooks/useUserInfo';
import NavBar from './NavBar';
import TopBar from '../MainLayout/TopBar';
import useStyles from './styles';
import Main from '../components/Main';

const MyAccountLayout = ({ children }) => {
  const { isMobile } = useBreakpoints();
  const { isWebView: userIsInWebView, apiKey } = useUserInfo();
  const [isNavOpen, setIsNavOpen] = useState(true);
  const classes = useStyles();
  const isWebView = userIsInWebView || !apiKey;
  const fallbackMargin = isWebView || isMobile ? 0 : 51;

  const marginWrapper = isNavOpen ? 216 : fallbackMargin;
  return (
    <div className={classes.root}>
      {!isWebView && (
        <>
          <TopBar
            onMobileNavOpen={() => setIsNavOpen((old) => !old)}
            sx={{
              [tablet]: {
                width: ` calc(100% - ${marginWrapper}px)`,
                marginLeft: 255,
              },
            }}
          />
          <NavBar
            isOpenDrawer={isNavOpen}
            onToggle={() => setIsNavOpen((oldValue) => !oldValue)}
            setIsNavOpen={setIsNavOpen}
          />
        </>
      )}
      <Main marginWrapper={marginWrapper} isWebView={isWebView}>
        {children}
      </Main>
    </div>
  );
};

MyAccountLayout.propTypes = {
  children: PropTypes.any,
};

export default MyAccountLayout;
