import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';

import { getUserInformation } from 'src/shared/services/url/UrlUser';
import { useGetList } from 'src/shared/services/api/query';
import QUERY_KEY from 'src/shared/constants/queryKey';

const useUsersSearch = ({ roles = [], ids, enabled, withFamily } = {}) => {
  const [search, setSearch] = useState('');
  const [params, setParams] = useState({
    divisions: [],
    roles,
    ...(withFamily && {
      filter: {
        family_id: { neq: null },
      },
    }),
  });

  const [debouncedSearch] = useDebounce(search, 1000);

  const selectedInstitutionPeriodId = useSelector(
    ({ user }) => user.selectedInstitutionPeriodId,
  );

  const query = useGetList(
    [
      QUERY_KEY.USE_USERS_SEARCH,
      debouncedSearch,
      params.divisions,
      params.roles,
    ],
    `${getUserInformation()}?expand=parent,families`,
    {
      'per-page': 2000,
      institutionPeriods: [selectedInstitutionPeriodId],
      search_term: debouncedSearch,
      ids,
      ...params,
      ...(roles && { roles }),
    },
    true,
    true,
    {
      enabled,
    },
  );

  const handleSearch = useCallback((searchTerm) => {
    setSearch(searchTerm);
  }, []);

  const handleChangeParam = useCallback((newParams) => {
    setParams((old) => ({
      ...old,
      ...newParams,
    }));
  }, []);

  const users = query.data ?? [];

  return {
    users,
    loading: query.isLoading,
    isFetching: query.isFetching,
    search,
    handleSearch,
    handleChangeParam,
  };
};

export default useUsersSearch;
