/* eslint-disable no-param-reassign */
// handle state changes with pure functions
import update from 'immutability-helper';

const reducers = {
  setAllSubjects(state, subjects) {
    return update(state, {
      subjects: { $set: subjects },
    });
  },

  setAllLectures(state, lectures) {
    return update(state, {
      lectures: { $set: lectures },
    });
  },

  setLecturesBySubjectId(state, lecturesBySubjectId) {
    return update(state, {
      lecturesBySubjectId: { $set: lecturesBySubjectId },
    });
  },

  setTasks(state, tasks) {
    return update(state, {
      tasks: { $set: tasks },
    });
  },

  setTaskDetails(state, task) {
    return update(state, {
      taskDetails: { $set: task },
    });
  },

  setTasksPage(state, tasksPage) {
    return update(state, {
      userHomeworks: { $set: tasksPage },
    });
  },

  setMembers(state, members) {
    return update(state, {
      members: { $set: members },
    });
  },

  setStudentsBySubjectId(state, studentsBySubjectId) {
    return update(state, {
      studentsBySubjectId: { $set: studentsBySubjectId },
    });
  },

  setTeachersBySubjectId(state, teachersBySubjectId) {
    return update(state, {
      teachersBySubjectId: { $set: teachersBySubjectId },
    });
  },

  setUserHomeworks(state, userHomeworks) {
    return update(state, {
      userHomeworks: { $set: userHomeworks },
    });
  },

  deleteTask(state, deletedTaskId) {
    return update(state, {
      userHomeworks: {
        pagination: {
          $set: { total: state.userHomeworks.pagination.total - 1 },
        },
        data: {
          $apply: (tasksPage) =>
            tasksPage.filter((task) => task.id !== deletedTaskId),
        },
      },
    });
  },

  setQuestions(state, questions) {
    return update(state, {
      questions: { $set: questions },
    });
  },

  setQuestion(state, question) {
    return update(state, {
      questions: {
        pagination: { $set: state.questions.pagination },
        data: {
          $unshift: [question],
        },
      },
    });
  },

  setAnswers(state, answers) {
    return update(state, {
      answers: { $set: answers },
    });
  },

  setAnswer(state, question) {
    const questionWithAnswers = {
      ...question,
      answers: [],
    };

    return update(state, {
      answers: {
        pagination: { $set: state.answers.pagination },
        data: { $unshift: [questionWithAnswers] },
      },
    });
  },

  setAnswersByQuestionId(state, answer) {
    return update(state, {
      answers: {
        pagination: { $set: state.questions.pagination },
        data: {
          $apply: (questions) =>
            questions.map((question) => {
              if (question.id === answer.question_id) {
                return {
                  ...question,
                  answers: [...question.answers, answer],
                };
              }

              return question;
            }),
        },
      },
    });
  },

  markAnswerAsViewed(state, unreadAnswers) {
    return update(state, {
      answers: {
        pagination: { $set: state.questions.pagination },
        data: {
          $apply: (questions) =>
            questions.map((question) =>
              update(question, {
                answers: {
                  $apply: (answers) =>
                    answers.map((answer) => {
                      const isNotRead = unreadAnswers.includes(answer.id);

                      if (isNotRead) {
                        return update(answer, {
                          viewed: { $set: true },
                        });
                      }

                      return answer;
                    }),
                },
              }),
            ),
        },
      },
    });
  },

  markQuestionsAsViewed(state, unreadQuestions) {
    return update(state, {
      answers: {
        pagination: { $set: state.questions.pagination },
        data: {
          $apply: (questions) =>
            questions.map((question) => {
              const ids = unreadQuestions.map(({ id }) => id);
              const isNotRead = ids.includes(question.id);

              if (isNotRead) {
                return update(question, {
                  viewed: { $set: true },
                });
              }

              return question;
            }),
        },
      },
    });
  },

  putQuestion(state, newQuestion) {
    return update(state, {
      answers: {
        pagination: { $set: state.questions.pagination },
        data: {
          $apply: (questions) =>
            questions.map((question) => {
              if (question.id === newQuestion.id) {
                return newQuestion;
              }

              return question;
            }),
        },
      },
    });
  },

  deleteQuestion(state, deletedQuestionId) {
    return update(state, {
      answers: {
        pagination: { $set: state.questions.pagination },
        data: {
          $apply: (questions) =>
            questions.filter((question) => question.id !== deletedQuestionId),
        },
      },
    });
  },

  putQuestionAnswer(state, answer) {
    return update(state, {
      answers: {
        pagination: { $set: state.questions.pagination },
        data: {
          $apply: (questions) =>
            questions.map((question) => {
              if (question.id === answer.question_id) {
                return {
                  ...question,
                  answers: question.answers.map((currentAns) => {
                    if (currentAns.id === answer.id) {
                      return answer;
                    }

                    return currentAns;
                  }),
                };
              }

              return question;
            }),
        },
      },
    });
  },

  deleteQuestionAnswer(state, { answerId, questionId }) {
    return update(state, {
      answers: {
        pagination: { $set: state.questions.pagination },
        data: {
          $apply: (questions) =>
            questions.map((question) => {
              if (question.id === questionId) {
                return {
                  ...question,
                  answers: question.answers.filter(
                    (currentAns) => currentAns.id !== answerId,
                  ),
                };
              }

              return question;
            }),
        },
      },
    });
  },

  setToggleQuestionsSidebar(state, force) {
    return update(state, {
      questionsSidebarOpen: { $set: force || !state.questionsSidebarOpen },
    });
  },
};

export default reducers;
