import { useCallback } from 'react';
import useGetInstitutionTaxInformation from 'src/features/payment-management/hooks/data/useGetInstitutionTaxInformation';

const useInstitutionTaxInformationById = (institutionTaxInformationId) => {
  const { data } = useGetInstitutionTaxInformation();

  const taxInformation = useCallback(() => {
    return data?.find(
      (taxInformation) => taxInformation.id === institutionTaxInformationId,
    );
  }, [data, institutionTaxInformationId]);

  return taxInformation() || {};
};

export default useInstitutionTaxInformationById;
