import usePaymentConfig from './data/usePaymentConfig';

import { paymentProvider } from '../constants/payments';
import useDLocalCreatePaymentOrder from './useDLocalCreatePaymentOrder';
import usePaycodeCreatePaymentOrder from './usePaycodeCreatePaymentOrder';

const useCreateOnlinePaymentOrder = () => {
  const { data: financialConfig } = usePaymentConfig();
  const provider = financialConfig?.provider;
  const dLocalCreatePaymentOrder = useDLocalCreatePaymentOrder();
  const paycodeCreatePaymentOrder = usePaycodeCreatePaymentOrder();

  switch (provider) {
    case paymentProvider.DLOCAL:
      return {
        ...dLocalCreatePaymentOrder,
      };

    case paymentProvider.PAYCODE:
      return {
        ...paycodeCreatePaymentOrder,
      };

    default:
      return {};
  }
};

export default useCreateOnlinePaymentOrder;
