import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    width: 350,
  },
  subtitle: {
    padding: theme.spacing(1, 2, 2, 2),
    color: theme.palette.text.secondary,
  },
  emptyImage: {
    maxHeight: 165,
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
