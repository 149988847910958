import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    paddingLeft: 2,
    borderLeft: '2px solid transparent',

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '& a:hover': {
      textDecoration: 'unset',
    },

    '&.Mui-selected': {
      borderLeft: `2px solid ${theme.palette.primary.main}`,

      '& .MuiButton-label': {
        color: theme.palette.primary.main,
      },
    },
  },
  active: {
    marginLeft: 2,
    color: theme.palette.primary.main,
    '& span': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
