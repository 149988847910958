import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Avatar, Dialog, Typography, DialogContent, Box } from '@mui/material';
import { Trans } from '@lingui/macro';

import SessionExpirationForm from 'src/shared/forms/SessionExpirationForm';
import router from 'src/shared/utils/router';

import useStyles from './styles';

const SessionExpirationDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  // WHERE NOT TO SHOW THE DIALOG
  const BLACKLIST = [
    router.auth.login,
    router.auth.logout,
    router.collections.paymentOrder(''),
  ];

  const { isTokenExpired, isAdmin, selectedInstitution, userData, reLogin } =
    useSelector(({ user }) => ({
      isTokenExpired: user.isTokenExpired,
      selectedInstitution: user.selectedInstitution,
      userData: user.data,
      reLogin: dispatch.user.reLogin,
      isAdmin: user.userIs.admin,
    }));
  const isBlacklisted = BLACKLIST.some((route) => pathname.includes(route));
  const isOpen = Boolean(isTokenExpired) && !isBlacklisted;

  const handleSubmit = async (values, formikBag) => {
    try {
      await reLogin({
        username: userData.email ?? userData.document_number,
        countryId: userData.country_id,
        password: values.password,
      });

      window.location.reload();
    } catch (error) {
      formikBag.setErrors({ password: error.message });
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  const handleLogOut = async () => {
    history.push(router.auth.logout);
  };

  return (
    <Dialog open={Boolean(isOpen)} classes={{ paper: classes.root }}>
      <DialogContent>
        <Typography variant="h2">
          <Trans>Tu sesión de fidu ha expirado.</Trans>
        </Typography>
        <Typography variant="h4">
          <Trans>¡Lo sentimos! Ingresa nuevamente con tu usuario.</Trans>
        </Typography>

        <div className={classes.institutionInfo}>
          <Avatar
            src={isAdmin ? selectedInstitution.icon_url : userData.photo}
            className={classes.institutionIcon}
          />
          <Typography variant="h2">
            {isAdmin ? selectedInstitution.name : userData.name}
          </Typography>
        </div>

        <SessionExpirationForm onSubmit={handleSubmit} />

        <div className={classes.helpBlock}>
          <Box onClick={handleLogOut}>
            <Typography sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
              <Trans>Cerrar sesión</Trans>
            </Typography>
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SessionExpirationDialog;
