import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import useStyles from './styles';

const FilterButton = ({ text, icon, ...rest }) => {
  const classes = useStyles();
  return (
    <Box mr={1}>
      <Button
        className={classes.root}
        size="small"
        variant="outlined"
        startIcon={icon}
        {...rest}
      >
        {text}
      </Button>
    </Box>
  );
};

FilterButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.any,
};

export default FilterButton;
