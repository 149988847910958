import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Link } from '@mui/material';

import useStyles from './styles';

const ExternalLink = forwardRef(
  (
    {
      href = '#',
      target = '_self',
      fullWidth,
      inline,
      children,
      onClick,
      ...rest
    },
    ref,
  ) => {
    const classes = useStyles();
    const anchorClasses = [classes.externalLink];
    if (fullWidth) anchorClasses.push(classes.fullWidth);
    if (inline) anchorClasses.push(classes.inline);

    const handleRedirect = (event) => {
      event.preventDefault();
      if (onClick) onClick();
      window.open(href, target);
    };

    return (
      <Link
        ref={ref}
        className={anchorClasses.join(' ')}
        onClick={handleRedirect}
        href={href}
        underline="hover"
        {...rest}
      >
        {children}
      </Link>
    );
  },
);

ExternalLink.displayName = 'ExternalLink';

ExternalLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.oneOf(['_blank', '_self']),
  fullWidth: PropTypes.bool,
  inline: PropTypes.bool,
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
};

export default ExternalLink;
