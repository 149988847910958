import React from 'react';
import PropTypes from 'prop-types';
import { Icon, MenuItem } from '@mui/material';
import { Icon as MDIIcon } from '@mdi/react';
import { mdiMicrosoftOnedrive } from '@mdi/js';
import { Trans } from '@lingui/macro';
import useOneDrive from 'src/shared/hooks/useOneDrive';

const OneDriveUpload = ({ buttonId, onClose }) => {
  const { showOneDrivePicker } = useOneDrive(onClose, buttonId);

  return (
    <MenuItem
      id={`upload-oneDrive-${buttonId}`}
      onClick={() => showOneDrivePicker()}
    >
      <Icon sx={{ mr: 1 }}>
        <MDIIcon path={mdiMicrosoftOnedrive} size="16px" />
      </Icon>
      <Trans>One Drive</Trans>
    </MenuItem>
  );
};

OneDriveUpload.propTypes = {
  buttonId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OneDriveUpload;
