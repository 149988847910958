import { useMutation } from '@tanstack/react-query';

import api from 'src/shared/services/api/api';
import { markNotificationViewAll } from 'src/shared/services/url/UrlCommunication';
import useNotifications from 'src/features/notifications/hooks/useNotifications';

const useMarkNotificationViewAll = () => {
  const { handleLoadNotifications, getUnreadNotifications } =
    useNotifications();

  const mutationFn = async () =>
    api.patch({
      url: markNotificationViewAll(),
    });

  return useMutation({
    mutationFn,
    onSuccess: async () => {
      await handleLoadNotifications();
      await getUnreadNotifications();
    },
  });
};

export default useMarkNotificationViewAll;
