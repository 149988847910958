import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  IconButton,
  SvgIcon,
  TableCell,
  Menu,
  MenuItem,
  ListItemIcon,
  CircularProgress,
  ListItemText,
} from '@mui/material';
import { MoreVertical as MoreVerticalIcon } from 'react-feather';

const TableActions = ({ actions, item }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggleActions = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAction = (row, handler) => (event) => {
    event.stopPropagation();
    handler(row);
    handleClose();
  };

  return (
    <>
      <TableCell align="right">
        <IconButton size="small" onClick={handleToggleActions}>
          <SvgIcon fontSize="small">
            <MoreVerticalIcon />
          </SvgIcon>
        </IconButton>
      </TableCell>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {actions.map((action, idx) => {
          const ActionIcon = action.icon ?? Fragment;
          const disabled =
            typeof action.disabled === 'function'
              ? action.disabled(item)
              : action.disabled;

          return (
            <MenuItem
              disabled={disabled || action.loading}
              onClick={handleClickAction(item, action.handler)}
              key={action.name ?? idx}
            >
              {ActionIcon && (
                <ListItemIcon>
                  <ActionIcon />
                </ListItemIcon>
              )}
              <ListItemText primary={action.name} />
              {action.loading && <CircularProgress color="primary" size={12} />}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

TableActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  item: PropTypes.object,
};

export default TableActions;
