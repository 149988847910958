import { Trans } from '@lingui/macro';

import { ROLE_LIST, VC_ROUTES_MAP_COUNTRY_ACCESS } from 'src/config/general';
import router from 'src/shared/utils/router';

const COLLECTIONS_PARENT_ROUTES = [
  {
    id: 'balance',
    title: <Trans>Estado de cuenta</Trans>,
    href: (id) => router.collections.familyBalance(id),
    roles: [ROLE_LIST.PARENT],
    enabled: true,
  },
  {
    id: 'family',
    title: <Trans>Grupo Familiar</Trans>,
    href: (id) => router.collections.familyGroup(id),
    roles: [ROLE_LIST.PARENT],
    enabled: true,
  },
];

const getInstitutionCollectionsItems = (
  isFinancialExecutive,
  enabledCashFlow,
  enabledCollection,
) => [
  {
    id: 'control',
    title: isFinancialExecutive ? (
      <Trans>Control de pagos</Trans>
    ) : (
      <Trans>Financial Manager</Trans>
    ),
    href: router.collections.control,
    enabled: true,
    roles: [
      ROLE_LIST.ADMIN,
      ROLE_LIST.FINANCIAL_ADMIN,
      ROLE_LIST.FINANCIAL_EXECUTIVE,
    ],
  },

  {
    id: 'families',
    title: <Trans>Estados de cuenta</Trans>,
    href: router.collections.families,
    enabled: true,
    roles: [
      ROLE_LIST.ADMIN,
      ROLE_LIST.FINANCIAL_ADMIN,
      ROLE_LIST.FINANCIAL_EXECUTIVE,
    ],
  },
  {
    id: 'payment-notification',
    title: <Trans>Emitir avisos de pago</Trans>,
    href: router.collections.generateInvoices,
    roles: [ROLE_LIST.ADMIN, ROLE_LIST.FINANCIAL_ADMIN],
    enabled: true,
    divider: true,
  },
  {
    id: 'billing',
    title: <Trans>Facturación</Trans>,
    href: router.collections.billing,
    roles: [ROLE_LIST.ADMIN, ROLE_LIST.FINANCIAL_ADMIN],
    enabled: true,
    countries: VC_ROUTES_MAP_COUNTRY_ACCESS.PAYMENT_MANAGEMENT_BILLING,
  },
  {
    id: 'online-collection',
    href: router.collections.onlineCollection,
    title: <Trans>Recaudación online</Trans>,
    enabled: enabledCollection,
    roles: [ROLE_LIST.ADMIN, ROLE_LIST.FINANCIAL_ADMIN],
  },
  {
    id: 'cash-flow',
    href: router.collections.cashFlow,
    title: <Trans>Flujo de Caja</Trans>,
    enabled: enabledCashFlow,
    roles: [ROLE_LIST.ADMIN, ROLE_LIST.FINANCIAL_ADMIN],
  },
];

const getParentRoleCollectionItems = (user) => {
  const families = user.data?.families ?? [];
  const selectedFamily = families.find(
    (family) => family.institution_id === user.selectedInstitution.id,
  );

  return COLLECTIONS_PARENT_ROUTES.map((route) => ({
    ...route,
    href: route.href(selectedFamily?.id),
  }));
};

export const getCollectionsItems = (
  user,
  enabledCashFlow,
  enabledCollection,
) => {
  const isFinancialExecutive = user.userIs.financialExecutive;
  const isAdmin =
    user.userIs.admin || user.userIs.financialAdmin || isFinancialExecutive;
  const isParent = user.userIs.parent;

  return !isAdmin && isParent
    ? getParentRoleCollectionItems(user)
    : getInstitutionCollectionsItems(
        isFinancialExecutive,
        enabledCashFlow,
        enabledCollection,
      );
};
