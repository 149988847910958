import React, { useRef, useState, useEffect, memo } from 'react';
import { useHistory } from 'react-router';
import { t, Trans } from '@lingui/macro';

import ScrollBar from 'src/components/ScrollBar';
import {
  Box,
  Button,
  IconButton,
  Popover,
  Tooltip,
  Badge,
  CircularProgress,
} from '@mui/material';
import { Bell } from 'react-feather';

import NotificationsList from 'src/features/notifications/components/NotificationsList';
import { useNotifications } from 'src/features/notifications/hooks';
import router from 'src/shared/utils/router';
import NotificationsTitle from 'src/features/notifications/components/NotificationsTitle';
import EmptyState from 'src/components/EmptyState';

import useStyles from './styles';

const NotificationsButton = () => {
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();
  const ref = useRef(null);
  const history = useHistory();

  const {
    handleLoadNotifications,
    handleClickNotification,
    handleMarkNotificationAsRead,
    handleDebouncedScroll,

    notifications,
    hasNotifications,
    readLoading,
    pendingNotificationId,
    initialLoading,
    notificationsWereFetched,
    canLoadMore,
    loading,
  } = useNotifications();

  const handleClickSeeAllNotifications = () => {
    setOpen(false);
    history.push(router.notifications.root);
  };

  useEffect(() => {
    if (isOpen && !notificationsWereFetched.current) {
      handleLoadNotifications();
    }
  }, [isOpen, handleLoadNotifications, notificationsWereFetched]);

  return (
    <>
      <Tooltip title={t`Notificaciones`}>
        <IconButton ref={ref} onClick={() => setOpen(true)} size="large">
          <Badge
            classes={{ badge: classes.badge }}
            color="primary"
            variant="dot"
            invisible={!notifications?.hasUnreadNotifications}
          >
            <Bell size={18} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={isOpen}
      >
        <Box
          p={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <NotificationsTitle
            propsText={{
              variant: 'h2',
            }}
          />
        </Box>

        {initialLoading && (
          <Box p={2} textAlign="center">
            <CircularProgress color="primary" size={25} />
          </Box>
        )}

        {!initialLoading && !hasNotifications ? (
          <EmptyState
            type="illustration"
            title={
              <Trans>
                Oups!
                <br />
                No hay notificaciones aún.
              </Trans>
            }
            boxContainerStyle={{ paddingBottom: 5 }}
          />
        ) : (
          <>
            <ScrollBar style={{ maxHeight: 300 }}>
              <NotificationsList
                className={classes.list}
                disablePadding
                notifications={notifications.data}
                onClickNotification={async (notification) => {
                  await handleClickNotification(notification);
                  setOpen(false);
                }}
                onMarkNotificationAsRead={handleMarkNotificationAsRead}
                pendingNotificationId={pendingNotificationId}
                readLoading={readLoading}
                canLoadMore={canLoadMore}
                onLoadMore={handleDebouncedScroll}
                loading={loading}
              />
            </ScrollBar>
            <Box p={2} display="flex" justifyContent="center">
              <Button
                classes={{ text: classes.MuiButtonText }}
                variant="text"
                color="primary"
                onClick={handleClickSeeAllNotifications}
                size="small"
              >
                <Trans>Ver todas las notificaciones</Trans>
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default memo(NotificationsButton);
