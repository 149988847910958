import store from 'src/shared/stores/configureStore';
import { getLegacyAuth } from 'src/shared/utils/manageBlendedStorage';
import router from 'src/shared/utils/router';

const onRequestFulfillment = (config) => {
  const states = store.getState();
  const {
    user: { apiKey, selectedInstitutionPeriodId, newInstitutionPeriod },
  } = states;

  const legacyKey = getLegacyAuth();
  if (apiKey && !config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${apiKey}`;
  }
  if (legacyKey && !config.headers.Authentication) {
    config.headers.Authentication = legacyKey;
  }

  if (apiKey && selectedInstitutionPeriodId) {
    let requestData;

    switch (config.method) {
      case 'get':
      case 'delete':
      case 'patch':
        requestData = 'params';
        break;

      default:
        requestData = 'data';
    }

    if (
      requestData === false ||
      config[requestData] instanceof FormData ||
      config.postRaw // post the raw data
    )
      return config;

    config[requestData] =
      typeof config[requestData] === 'string'
        ? JSON.parse(config[requestData])
        : config[requestData];

    /**  Refetching data from the new cycle with the new period id **/
    const pathname = window.location.pathname;

    if (
      pathname.includes(`${router.institution.institutionPeriod}/`) &&
      pathname !== router.institution.institutionPeriodDashboard
    ) {
      config[requestData] = {
        institution_period_id: newInstitutionPeriod.id,
        ...config[requestData],
      };
    }
    /****************************************/

    if (!config.url.includes('/api/user/relations')) {
      config[requestData] = {
        institution_period_id: selectedInstitutionPeriodId,
        ...config[requestData],
      };
    }

    return config;
  }

  return config;
};

const interceptor = (instance) => {
  instance.interceptors.request.use(onRequestFulfillment);
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const {
        user: { refreshToken },
      } = store.getState();

      if (error?.response?.status === 401) {
        if (error.config.url.includes('/ajax/')) {
          window.location.href = router.auth.logout;
        }

        if (refreshToken && !error.config.isRetry) {
          await store.dispatch.user.resfreshUserToken(refreshToken);

          delete error.config.headers.Authorization;
          error.config.isRetry = true;
          return instance.request(error.config);
        }

        store.dispatch.user.updateTokenExpired(true);
      }

      return Promise.reject(error);
    },
  );

  return instance;
};

export default interceptor;
