import React from 'react';
import { t } from '@lingui/macro';
import { toLocaleCurrency } from 'src/shared/utils/currencies';
import ActionsCell from './ActionsCell';
import DiscountCell from './DiscountCell';
import StudentsCell from './StudentsCell';

const handleFindDiscounts = (filteredRates, foundIndex) => {
  return filteredRates[foundIndex]?.variations.map((el) => ({
    ...el,
    id: `${el.id}-v`, // THIS IS TO AVOID 'REPEATED' KEY IDS WITH CONCEPTS
    variationId: el.id,
    isDiscount: true,
    rateValue: filteredRates[foundIndex].value,
    rateId: filteredRates[foundIndex].id,
    concept: filteredRates[foundIndex].concept,
  }));
};

const columns = () => [
  {
    title: t`Categoría`,
    key: 'concept.name',
  },
  {
    title: t`Concepto`,
    key: 'name',
  },
  {
    title: t`Estudiantes`,
    key: 'totalStudents',
    props: { align: 'center' },
    render: (cell) => <StudentsCell {...cell} />,
  },
  {
    title: t`Descuentos`,
    key: 'discounts',
    props: { align: 'center' },
    render: (cell) => <DiscountCell {...cell} />,
  },
  {
    title: t`Importe`,
    key: 'amount',
    format: toLocaleCurrency,
  },
  {
    title: '',
    key: 'actions',
    render: (cell) => <ActionsCell {...cell} />,
  },
];

const columnsIsExtraordinary = () => [
  {
    title: t`Categoría`,
    key: 'concept.name',
  },
  {
    title: t`Concepto`,
    key: 'name',
  },
  {
    title: t`Importe`,
    key: 'amount',
    props: { align: 'right' },
    format: toLocaleCurrency,
  },
  {
    title: '',
    props: { width: 50 },
    key: 'actions',
    render: (cell) => {
      return <ActionsCell {...cell} extraordinary />;
    },
  },
];

export { columns, columnsIsExtraordinary, handleFindDiscounts };
