import React from 'react';
import PropTypes from 'prop-types';
import { useScrollTrigger } from '@mui/material';

const ElevationScroll = ({ children, listener, propsOnThreshold }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: listener,
  });

  return React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      ...child.props,
      ...(trigger ? propsOnThreshold : {}),
    });
  });
};

ElevationScroll.propTypes = {
  children: PropTypes.object.isRequired,
  propsOnThreshold: PropTypes.object.isRequired,
  listener: PropTypes.object,
};

export default ElevationScroll;
