import React from 'react';
import PropTypes from 'prop-types';
import { Icon as MDIIcon } from '@mdi/react';
import { mdiGoogleDrive } from '@mdi/js';
import { Trans } from '@lingui/macro';
import { MenuItem, Icon } from '@mui/material';
import useGoogleDrive from 'src/shared/hooks/useGoogleDrive';

const GoogleDriveUpload = ({ buttonId, onClose }) => {
  const { handleShowPicker } = useGoogleDrive(onClose, buttonId);

  return (
    <MenuItem id={`upload-gdrive-${buttonId}`} onClick={handleShowPicker}>
      <Icon sx={{ mr: 1 }}>
        <MDIIcon path={mdiGoogleDrive} size="16px" />
      </Icon>
      <Trans>Google Drive</Trans>
    </MenuItem>
  );
};

GoogleDriveUpload.propTypes = {
  buttonId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default GoogleDriveUpload;
