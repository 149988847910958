import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    overflow: 'hidden',
    borderRadius: theme.spacing(1),

    '& .MuiSvgIcon-root': {
      fontSize: '18px !important',
      color: theme.palette.text.secondary,
    },
  },
  accountMenu: {
    zIndex: theme.zIndex.modal + 1,
  },
  externalLink: {
    display: 'block',
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
  listItemIcon: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
  },
  listItemText: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
    textAlign: 'left',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  popover: {
    width: 240,
    '& .MuiDivider-root': {
      margin: `${theme.spacing(1)} 0`,
    },
    '& *': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  accountInfo: {
    marginRight: theme.spacing(1),
    textAlign: 'left',
  },
  accountName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 150,
  },
  logoutButton: {
    width: '100%',
    '&[disabled]': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },
}));

export default useStyles;
