import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { Box, Grid, InputLabel, Chip, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';

import useStyles from './styles';
import { useField } from 'formik';
import FileList from 'src/components/Form/DropZoneField/fileList';
import { Trash2 as CloseIcon } from 'react-feather';

const DropZoneField = ({
  name,
  helperText,
  label,
  maxFiles,
  accept,
  children,
  ...props
}) => {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [field, meta, helpers] = useField(name);
  const hasFiles = field.value?.length && Boolean(field.value[0]);

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      helpers.setValue([...acceptedFiles]);
    },
    [helpers],
  );

  const handleRemove = useCallback(
    (file) => {
      helpers.setValue(field.value.filter((fileItem) => fileItem !== file));
    },
    [field.value, helpers],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: maxFiles,
    onDropAccepted: onDropAccepted,
    accept: accept ?? ['image/jpeg'],
    onFileDialogCancel: () => {
      helpers.setTouched(name, true);
    },
  });

  useEffect(() => {
    if (meta.touched || !hasFiles) {
      setEditMode(true);
    }
  }, [hasFiles, meta.touched]);

  if (!editMode && hasFiles) {
    return (
      <Grid item xs={12}>
        {label && <InputLabel>{label}</InputLabel>}
        {field.value.map((item, index) => (
          <Chip
            sx={{ px: 1 }}
            key={item?.storage_file_name ?? index}
            label={
              item?.original_file_name ?? item?.storage_file_name ?? item?.name
            }
            deleteIcon={<CloseIcon size={16} />}
            onDelete={() => {
              helpers.setValue([]);
              setEditMode(true);
            }}
          />
        ))}
        {meta.error && (
          <Grid item xs={12}>
            <Typography color="error">{meta.error}</Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      {label && <InputLabel>{label}</InputLabel>}
      <Box
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive,
          [props.className]: true,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {children}
        <Box>
          <>{helperText}</>
        </Box>
        {meta.error && meta.touched && (
          <Box>
            <Typography color="error">{meta.error}</Typography>
          </Box>
        )}
      </Box>
      {Boolean(hasFiles) && (
        <FileList files={field.value} onRemove={handleRemove} />
      )}
    </Grid>
  );
};
export default DropZoneField;
