import React, { memo, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, Grid } from '@mui/material';
import { useFormikContext } from 'formik';

import StructureFilters from 'src/components/Filters/StructureFilters';

const StructureFields = memo(({ initialValues, roles, levels }) => {
  const firstRender = useRef(false);
  const { setFieldValue, setFieldTouched, getFieldMeta } = useFormikContext();

  const rolesMeta = getFieldMeta('roles');
  const divisionsMeta = getFieldMeta('divisions');

  const handleChange = useCallback(
    async (newValues) => {
      if (firstRender.current) {
        await setFieldValue(
          'roles',
          roles.filter((rol) => newValues.roles.includes(rol.id)),
        );
        await setFieldValue('divisions', newValues.divisions);

        if (newValues.roles.length) {
          await setFieldTouched('roles', true);
        }
        if (newValues.divisions.length) {
          await setFieldTouched('divisions', true);
        }
      }
    },
    [setFieldValue, roles, setFieldTouched],
  );

  useEffect(() => {
    if (!firstRender.current) firstRender.current = true;
  }, []);

  return (
    <Grid item xs={12} px={2} sx={{ minHeight: 70 }}>
      <StructureFilters
        variant="roles"
        overrides={{ levels, roles }}
        initialValues={initialValues}
        hiddenElements={['subjects']}
        onChange={handleChange}
      />

      {divisionsMeta.touched && divisionsMeta.error && (
        <FormHelperText error>{divisionsMeta.error}</FormHelperText>
      )}
      {!divisionsMeta.error && rolesMeta.touched && rolesMeta.error && (
        <FormHelperText error>{rolesMeta.error}</FormHelperText>
      )}
    </Grid>
  );
});

StructureFields.displayName = 'StructureFields';

StructureFields.propTypes = {
  initialValues: PropTypes.object,
  roles: PropTypes.array,
  levels: PropTypes.array,
};

export default StructureFields;
