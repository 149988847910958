import React, { memo, useEffect, useState } from 'react';
import { Box, Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import WebinarContent from './WebinarContent';
import useStyles from './styles';

const WebinarDialog = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const CURRENT_WEBINAR_DATE = dayjs.utc('2022-07-25T00:00:00-03:00').local();
  const WEBINAR_LINK = 'https://www.blog.appblended.com/post/calificaciones';
  const { isWebinarSeen, webinarDate, setWebinarSeen } = useSelector(
    ({ user }) => ({
      isWebinarSeen: user.webinar?.isSeen,
      webinarDate: user.webinar?.date,
      setWebinarSeen: dispatch.user.setWebinarSeen,
    }),
  );
  const formattedWebinarDate = dayjs(webinarDate).local();
  const isSameDate =
    dayjs(formattedWebinarDate).diff(CURRENT_WEBINAR_DATE, 'day') === 0;
  const isActiveWebinar =
    dayjs(CURRENT_WEBINAR_DATE).diff(dayjs(), 'minutes') >= 10; // FALTAN 10 MIN O MAS

  const handleClose = () => {
    setIsOpen(false);
    setWebinarSeen({
      isSeen: true,
      date: dayjs(CURRENT_WEBINAR_DATE).format(),
    });
  };

  useEffect(() => {
    if (((!isOpen && !isWebinarSeen) || !isSameDate) && isActiveWebinar) {
      setIsOpen(true);
    }
  }, [isActiveWebinar, isOpen, isSameDate, isWebinarSeen]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{
        paperScrollPaper: classes.paperScrollPaper,
      }}
    >
      <Box display="flex" className={classes.root}>
        <WebinarContent
          date={CURRENT_WEBINAR_DATE}
          onClose={handleClose}
          link={WEBINAR_LINK}
        />
      </Box>
    </Dialog>
  );
});

WebinarDialog.displayName = 'WebinarDialog';

export default WebinarDialog;
