import useModules from 'src/shared/hooks/useModules';

const useModulesAreEnabled = (modulesToVerify) => {
  const { data: modules } = useModules();

  return modules.some(
    ({ enabled, name_web }) => name_web === modulesToVerify.name && enabled,
  );
};

export default useModulesAreEnabled;
