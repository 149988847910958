import React, { Fragment, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { AlertCircle, MoreVertical } from 'react-feather';
import { t } from '@lingui/macro';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CircularProgress from '@mui/material/CircularProgress';
import { ListItemText, Tooltip } from '@mui/material';

import useStyles from './styles';
import ScheduleElement from '../../ScheduleElement';

const PostCardHeader = memo(
  ({
    title,
    subheader,
    avatar,
    classes,
    className,
    disabled,
    onClickAction,
    actions,
    customActionComponent,
    ...props
  }) => {
    const compClasses = useStyles();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [actionsElement, setActionsElement] = useState(null);
    const actionList = Array.isArray(actions) ? actions : actions?.options;

    const handleClick = (event) => {
      setActionsElement(event.currentTarget);
    };

    const handleClose = () => {
      setActionsElement(null);
    };

    const handleClickAction = (type, values) => {
      handleClose();
      onClickAction(type, values);
    };

    const handleToggleDialog = () => {
      setIsDialogOpen((oldValue) => !oldValue);
    };

    const handleConfirmSchedule = (type, values) => {
      handleClickAction(type, values);
      handleToggleDialog();
    };

    return (
      <CardHeader
        {...props}
        className={className}
        classes={{ ...compClasses, ...classes }}
        title={title}
        subheader={subheader}
        avatar={avatar}
        action={
          <>
            {customActionComponent && <>{customActionComponent}</>}
            {actions && (
              <>
                <IconButton
                  aria-label="post-options"
                  aria-haspopup="true"
                  onClick={handleClick}
                  disabled={disabled}
                  size="small"
                >
                  <MoreVertical />
                </IconButton>
                <Menu
                  anchorEl={actionsElement}
                  keepMounted
                  open={Boolean(actionsElement)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {actionList.map((action) => {
                    const ActionIcon = action.icon ?? Fragment;

                    if (action.type === 'schedule') {
                      return (
                        <ScheduleElement
                          key={action.label}
                          buttonId="edit_scheduled_post"
                          isDialogOpen={isDialogOpen}
                          onClose={handleToggleDialog}
                          onClick={handleToggleDialog}
                          onConfirm={(values) => {
                            handleConfirmSchedule(action.type, values);
                          }}
                          elementLabel={t`publicación`}
                          values={action.values}
                        />
                      );
                    }

                    return (
                      <MenuItem
                        key={action.label}
                        onClick={() => handleClickAction(action.type)}
                        disabled={action.disabled || action.loading}
                      >
                        {action.icon && (
                          <ListItemIcon className={compClasses.actionIcon}>
                            <ActionIcon />
                          </ListItemIcon>
                        )}

                        <ListItemText primary={action.label} />

                        {action.loading && (
                          <ListItemIcon className={compClasses.actionLoading}>
                            <CircularProgress color="primary" />
                          </ListItemIcon>
                        )}

                        {!action.loading && action.tooltip && (
                          <Tooltip title={action.tooltip}>
                            <AlertCircle
                              className={compClasses.actionTooltip}
                            />
                          </Tooltip>
                        )}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </>
            )}
          </>
        }
      />
    );
  },
);

PostCardHeader.displayName = 'PostCardHeader';

PostCardHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  avatar: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      external_id: PropTypes.string.isRequired,
    }),
    PropTypes.node,
  ]),
  classes: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClickAction: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      icon: PropTypes.any,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  customActionComponent: PropTypes.node,
};

export default PostCardHeader;
