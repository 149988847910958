import { familyTaxInformationUrl } from 'src/shared/services/url/UrlPaymentManagement';
import { useGetPaginatedList } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import useInstitutionCountryAccess from '../useInstitutionCountryAccess';
import { VC_ROUTES_MAP_COUNTRY_ACCESS } from 'src/config/general';

const useFamilyTaxInformation = ({ familyId }) => {
  const { hasInstitutionCountryAccess } = useInstitutionCountryAccess();

  const hasCountryAccess = hasInstitutionCountryAccess(
    VC_ROUTES_MAP_COUNTRY_ACCESS.PAYMENT_MANAGEMENT_BILLING,
  );

  return useGetPaginatedList(
    [QUERY_KEY.FAMILY_TAX_INFORMATION, familyId],
    familyTaxInformationUrl(),
    { familyId, expand: 'payment_method,tax_regime,person_type' },
    true,
    { enabled: Boolean(familyId) && hasCountryAccess },
  );
};

export default useFamilyTaxInformation;
