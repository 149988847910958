const SCHOOL_MANAGER_DIALOGS = {
  MANAGE_LEVELS: 'ManageLevels',
  MANAGE_COURSES: 'ManageCourses',
  DELETE_COURSE: 'DeleteCourse',
  DELETE_DIVISION: 'DeleteDivision',
  DELETE_SUBJECTS: 'DeleteSubjects',
  DELETE_STUDENTS_SPECIAL_SUBJECT: 'DeleteStudentsSpecialSubject',
  CREATE_COURSES: 'CreateCourses',
  MOVE_STUDENTS: 'MoveStudents',
  CREATE_DIVISION: 'CreateDivision',
  CREATE_SUBJECT: 'CreateSubject',
  UNSUBSCRIBE_USERS: 'UnsubscribeUsers',
  UNSUBSCRIBE_USERS_CONFIRM: 'UnsubscribeUsersConfirm',
  COMMUNITY_FILTERS: 'CommunityFilters',
  REPORT_COMMUNITY: 'ReportCommunity',
};

export default SCHOOL_MANAGER_DIALOGS;
