import { useMemo } from 'react';

import {
  useFamilyInformation,
  useFamilyTaxInformation,
} from 'src/features/payment-management/hooks';
import sortByName from 'src/shared/utils/sortByName';
import { ROLE_LIST } from 'src/config/general';

const useFamilyGroupTaxInfo = (familyId) => {
  const { data: userFamilyInfo, isLoading: isLoadingUserFamily } =
    useFamilyInformation({
      familyId,
    });

  const { data: familyTaxInformation, isLoading: isLoadingFamilyTaxInfo } =
    useFamilyTaxInformation({ familyId });

  const familyThirdPartyUsers = useMemo(() => {
    const noUsers = familyTaxInformation?.filter(({ user_id }) => !user_id);

    return sortByName(noUsers ?? []);
  }, [familyTaxInformation]);

  const familyChildren = useMemo(() => {
    const children = userFamilyInfo?.users.filter(
      ({ role }) => role === 'child',
    );

    return sortByName(children ?? []);
  }, [userFamilyInfo]);

  const familyParents = useMemo(() => {
    const parents = userFamilyInfo?.users
      .filter(({ role }) => role === ROLE_LIST.PARENT)
      .map((parent) => {
        const parentTaxInfo = familyTaxInformation?.find(
          ({ user_id }) => user_id === parent.user_id,
        );
        return {
          ...parent,
          tax_id: parentTaxInfo?.id,
          is_responsible: Boolean(parentTaxInfo?.is_responsible),
        };
      });

    return sortByName(parents ?? []);
  }, [familyTaxInformation, userFamilyInfo]);

  return {
    userFamilyInfo,
    isLoadingUserFamily,
    familyTaxInformation,
    isLoadingFamilyTaxInfo,
    familyThirdPartyUsers,
    familyChildren,
    familyParents,
  };
};

export default useFamilyGroupTaxInfo;
