import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Circle, Minus, CheckCircle } from 'react-feather';
import { Box, Button, Grow } from '@mui/material';
import clsx from 'clsx';
import { Trans } from '@lingui/macro';

import { useAttendanceContext } from 'src/features/attendance/contexts/AttendanceContext';
import { ATTENDANCE_STATUS_LIST } from 'src/features/attendance/components/AttendanceListTable/helpers';
import useStyles from './styles';

const AttendanceStatusCell = memo(
  ({ sentData, keyData, id: divisionId, ...rest }) => {
    const classes = useStyles();
    const { status } = sentData?.[keyData] ?? {};
    const [isHovering, setIsHovering] = useState(false);
    const { setIsDialogOpen, setSelectedDay } = useAttendanceContext();
    let RenderComponent;
    switch (status) {
      case ATTENDANCE_STATUS_LIST.LOADED:
        RenderComponent = Circle;
        break;
      case ATTENDANCE_STATUS_LIST.SENT:
        RenderComponent = CheckCircle;
        break;
      default:
        RenderComponent = Minus;
        break;
    }

    const handleMouseEnter = () => {
      setIsHovering(true);
    };

    const handleMouseLeave = () => {
      setIsHovering(false);
    };

    const handleClick = () => {
      setIsDialogOpen(true);
      setSelectedDay({
        day: keyData,
        data: { ...sentData, divisionId, ...rest },
      });
    };

    return (
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isHovering ? (
          <Grow in={isHovering}>
            <Button
              size="small"
              className={classes.openButton}
              onClick={handleClick}
            >
              <Trans>Abrir</Trans>
            </Button>
          </Grow>
        ) : (
          <RenderComponent
            size={16}
            className={clsx({
              [classes.statusIcon]: true,
              [classes.statusDisabled]: status === 0 || !status,
            })}
          />
        )}
      </Box>
    );
  },
);

AttendanceStatusCell.displayName = 'AttendanceStatusCell';

AttendanceStatusCell.propTypes = {
  sentData: PropTypes.object,
  keyData: PropTypes.string,
  id: PropTypes.number,
};

export default AttendanceStatusCell;
