import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      marginRight: 5,
    },
  },
  MuiInputLabelFormControl: {
    position: 'relative',
    transform: 'none',
    fontWeight: 500,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
    whiteSpace: 'unset',
    maxWidth: 'unset',
  },
  MuiInput: {
    paddingLeft: 0,
  },
  floatingLabel: {
    '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
      top: '-7px',
    },
  },
  helperText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
