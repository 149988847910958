import { t } from '@lingui/macro';
import { ROLE_LIST } from 'src/config/general';
import store from 'src/shared/stores/configureStore';
import BLENDED_STORAGE_KEYS from '../utils/storageKeys';

export const getLectureById = (lectures = [], lectureId) =>
  lectures.find((l) => l.id === lectureId);

export const getSubjectById = (subjects = [], subjectId) =>
  subjects.find((s) => s.id === subjectId);

export const handleCanUseSnakeCase = (objectParams) => {
  const keys = Object.keys(objectParams);
  const canUse = keys.some((el) => el.includes('>') || el.includes('<'));
  return canUse === false;
};

export const removeEmptyObjectKey = (object, allowNull = false) => {
  const filledKeys = Object.keys(object).filter((objectKey) => {
    const isEmptyKey =
      object[objectKey] === '' ||
      (object[objectKey] === null && !allowNull) ||
      object[objectKey] === undefined;

    return !isEmptyKey;
  });

  return filledKeys.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: object[curr],
    }),
    {},
  );
};

export const getDescriptionByStatusId = (statusId) => {
  switch (statusId) {
    case 1:
      return t`Pendiente`;
    case 2:
      return t`Entregada`;
    case 3:
      return t`Fuera de término`;
    case 4:
      return t`No Entregada`;
    case 5:
      return t`Corregida`;
    case 6:
      return t`Rehacer`;
    default:
      return t`Pendiente`;
  }
};

export const getHistoryDescriptionByStatusId = (statusId) => {
  switch (statusId) {
    case 1:
      return t`Tarea pendiente`;
    case 2:
      return t`Tarea entregada`;
    case 3:
      return t`Tarea entregada fuera de término`;
    case 4:
      return t`Tarea no entregada`;
    case 5:
      return t`Tarea corregida`;
    case 6:
      return t`Tarea rechazada`;
    default:
      return t`Tarea pendiente`;
  }
};

export const getColorByStatusId = (statusId) => {
  switch (statusId) {
    case 1:
      return 'warning';
    case 2:
      return 'success';
    case 3:
      return 'success';
    case 4:
      return 'error';
    case 5:
      return 'info';
    default:
      return 'warning';
  }
};

// Extracted from Stackoverflow :) - https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${bytes / (k ** i).toFixed(dm)} ${sizes[i]}`;
};

export const removeStorageKey = (key) => {
  if (window.localStorage.getItem(key)) {
    window.localStorage.removeItem(key);
  }
};

export const formatUserRole = (role, plural) => {
  switch (role) {
    case ROLE_LIST.ADMIN:
      return plural ? t`Administradores` : t`Administrador`;

    case ROLE_LIST.PARENT:
      return plural ? t`Responsables familiares` : t`Responsable familiar`;

    case ROLE_LIST.EXECUTIVE:
      return plural ? t`Directivos` : t`Directivo`;

    case ROLE_LIST.PRECEPTOR:
      return plural ? t`Preceptores` : t`Preceptor`;

    case ROLE_LIST.STUDENT:
      return plural ? t`Estudiantes` : t`Estudiante`;

    case ROLE_LIST.TEACHER:
      return plural ? t`Docentes` : t`Docente`;

    case ROLE_LIST.FINANCIAL_EXECUTIVE:
      return plural ? t`Ejecutivos Financieros` : t`Ejecutivo Financiero`;

    case ROLE_LIST.FINANCIAL_ADMIN:
      return plural
        ? t`Administradores Financieros`
        : t`Administrador Financiero`;

    default:
      return role;
  }
};

export const isValidUrl = (string) => {
  const expressionURL =
    /[-a-zA-Z0-9@:%_.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_.~#?&//=]*)?/gi;
  const regexpURL = new RegExp(expressionURL);

  const expressionEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  const regexpEmail = new RegExp(expressionEmail);

  if (regexpEmail.test(string)) {
    return false;
  }

  return regexpURL.test(string);
};

export const intersperse = (arr = [], separator) => {
  return arr.reduce((acc, curr) => [acc, separator, curr]);
};

export const fillWithPlaceholder = (count) =>
  Array.from(Array(count), (_, n) => n);

export const sortByNewestDate = (currentDateElement, prevDateElement) => {
  return new Date(prevDateElement) - new Date(currentDateElement);
};

export const sortUsersByName = (users, orderByLastName, reverse) => {
  const result = users?.sort((currentUser, nextUser) => {
    [currentUser, nextUser].map((user) => {
      const fullName = orderByLastName
        ? `${user.last_name} ${user.name}`
        : `${user.name} ${user.last_name}`;
      return Object.assign(user, { fullName });
    });
    return currentUser.fullName.localeCompare(nextUser.fullName);
  });
  if (reverse) return result.reverse();
  return result;
};

export const formatDivisionSubjectLink = (subject) => {
  return `${subject.name} ${
    subject.course ? `(${subject.course.name} ${subject.division.name})` : ''
  }`;
};

export const sendMessageToUser = (users, title) => {
  const usersData = [];
  if (Array.isArray(users)) {
    users.forEach((user) => {
      const { id, name, last_name, photo } = user;
      usersData.push({ id, name, last_name, photo });
    });
  } else {
    const { id, name, last_name, photo } = users;
    const userData = { id, name, last_name, photo };
    usersData.push(userData);
  }

  localStorage.setItem(
    BLENDED_STORAGE_KEYS.INBOX_NEW_DM,
    JSON.stringify(usersData),
  );

  if (title) {
    localStorage.setItem(
      BLENDED_STORAGE_KEYS.INBOX_NEW_DM_TITLE,
      JSON.stringify(title),
    );
  }
};

export const addCapitalLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const checkRole = (targetRole = [], user) => {
  const states = store.getState();
  const checkUser = user ?? states.user;
  const userRoles = checkUser?.roles ?? checkUser.role ?? [];

  return userRoles
    .map((r) => r.role_name || r.item_name)
    .some((userRole) => targetRole.includes(userRole));
};

export const checkObjectIsEmpty = (obj = {}) => Object.keys(obj).length === 0;

export const sortByRoleRank = (a, b, roleRank, key) => {
  const aIndex = roleRank.indexOf(a[key]);
  const bIndex = roleRank.indexOf(b[key]);
  if (aIndex === -1) return 1; // no está en la lista, va al final
  if (bIndex === -1) return -1; // no está en la lista, va al final
  return aIndex - bIndex;
};

export const expressionFindAComma = /,(?!.*,)/gim;

export const formatUserRolesName = (roles, genreId) => {
  const formattedRoles = [...new Set(roles)];

  return formattedRoles
    .map((role) => formatUserRole(role, false, genreId))
    .join(', ')
    .replace(expressionFindAComma, ` y`);
};

export const formatDivisionName = (roles) => {
  const divisions = [];
  roles.forEach((role) => {
    if (role.division) {
      divisions.push(`${role.division.course?.name} ${role.division.name}`);
    }
  });
  return divisions.join(', ').replace(expressionFindAComma, ` y`);
};

export const formatSubjectName = (roles) => {
  const subjects = [];
  roles.forEach((role) => {
    if (role.subject) {
      subjects.push(`${role.subject.name}`);
    }
  });
  return subjects.join(', ').replace(expressionFindAComma, ` y`);
};

export const sanitizeStr = (str) =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(' ', '')
    .toLowerCase();

export const stringMatch = (str1, str2) =>
  Boolean(sanitizeStr(str1).includes(sanitizeStr(str2)));

export const getUserFullName = (user) => {
  if (!user) return '';
  const { name, last_name } = user;
  return last_name ? `${name} ${last_name}` : name;
};

export const getUserAlias = (user) => {
  if (!user) return '';
  const { alias } = user;
  return alias || getUserFullName(user);
};

export const getUserRoles = (user) => {
  const { authAssignments, custom_role, role } = user;
  if (custom_role) {
    return [custom_role];
  } else if (authAssignments) {
    const formattedRoles = authAssignments.map((userRole) =>
      formatUserRole(userRole.item_name),
    );
    return [...new Set(formattedRoles)];
  } else if (role) {
    return [formatUserRole(role)];
  }
};

export const getUserDivisions = ({ courses }) => {
  const divisionsText = [];
  if (courses) {
    courses.forEach((course) => {
      const [division] = course.division;
      divisionsText.push(`${course?.name} ${division?.name}`);
    });
  }

  return divisionsText.join(',');
};

export const isJSON = (str) => {
  try {
    return JSON.parse(str) && Boolean(str);
  } catch (e) {
    return false;
  }
};

export const sortAuthAssignmentsByHighRole = (authAssignments) => {
  return (
    authAssignments.sort((a, b) => {
      const roles = Object.values(ROLE_LIST);
      return roles.indexOf(a.item_name) - roles.indexOf(b.item_name);
    }) ?? []
  );
};

export const getDivisionsId = (level) => {
  return level.courses.flatMap((course) =>
    course.divisions.map((division) => division.id),
  );
};

export const getColorById = (id = 0) => {
  const hues = [200, 90, 270, 60, 0]; // (azul, verde, púrpura, amarillo, rojo)
  const saturation = 50; // Saturación constante para colores pastel
  const lightness = 80; // Luminosidad constante para colores claros
  const colorIndex = Math.floor(parseInt(id, 10)) % hues.length;
  const hue = hues[colorIndex]; // Selecciona el tono basado en el id
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const levelsAsStaffOnly = (levels) => {
  const { user } = store.getState();

  const condition = (role) =>
    role === ROLE_LIST.ADMIN ||
    role === ROLE_LIST.EXECUTIVE ||
    role === ROLE_LIST.PRECEPTOR;

  const userLevelsAsStaff =
    user.selectedInstitution?.periods[0]?.levels?.filter((level) =>
      level.courses.some((course) =>
        course.divisions.some((division) => division.roles.some(condition)),
      ),
    );

  return levels?.filter((level) =>
    userLevelsAsStaff?.some((levelAsStaff) => level.id === levelAsStaff.id),
  );
};
