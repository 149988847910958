import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle, Grid } from '@mui/material';

// no usar este componente directamente,
// extender como se hace en "../DialogTitle"

const DialogHeaderBase = ({ right, left, center, ...props }) => (
  <DialogTitle {...props}>
    <Grid
      container
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        item
        sx={{
          textAlign: 'left',
        }}
      >
        {left}
      </Grid>
      <Grid
        item
        sx={{
          textAlign: 'center',
        }}
      >
        {center}
      </Grid>

      <Grid
        item
        sx={{
          textAlign: 'right',
        }}
      >
        {right}
      </Grid>
    </Grid>
  </DialogTitle>
);

DialogHeaderBase.propTypes = {
  right: PropTypes.element,
  left: PropTypes.element,
  showLogo: PropTypes.bool,
};

export default DialogHeaderBase;
