import { Trans } from '@lingui/macro';

import { ROLE_LIST, VC_ROUTES_MAP_COUNTRY_ACCESS } from 'src/config/general';
import { BLENDED_MODULES } from 'src/config/constants';
import router from 'src/shared/utils/router';
import { MESSAGES_EXCLUDED_INSTITUTIONS_IDS } from 'src/config/general';

export const collectionsConfigItems = (isActiveModuleTaxBilling) => [
  {
    id: 'payment-control',
    title: <Trans>Recargos y descuentos</Trans>,
    href: router.configuration.invoices,
    enabled: true,
  },
  {
    id: 'payment-methods',
    title: <Trans>Medios de pago</Trans>,
    href: router.configuration.paymentMethods,
    enabled: true,
  },
  {
    id: 'recurrent-concepts',
    title: <Trans>Conceptos</Trans>,
    href: router.configuration.concepts,
    enabled: true,
  },
  {
    id: 'import-balance',
    title: <Trans>Importar saldos</Trans>,
    href: router.configuration.template,
    enabled: true,
  },
  {
    id: 'billing-data',
    title: <Trans>Datos de facturación</Trans>,
    href: router.configuration.billing,
    countries: VC_ROUTES_MAP_COUNTRY_ACCESS.PAYMENT_MANAGEMENT_BILLING,
    enabled: isActiveModuleTaxBilling,
  },
  {
    id: 'payment-button',
    title: <Trans>Botón de pago</Trans>,
    href: router.configuration.paymentButton,
    enabled: true,
  },
  {
    id: 'permissions',
    title: <Trans>Permisos</Trans>,
    href: router.configuration.permissions,
    roles: [ROLE_LIST.ADMIN],
    enabled: true,
  },
  {
    id: 'payment-reminder',
    title: <Trans>Recordatorios de deuda</Trans>,
    href: router.configuration.debtReminders,
    enabled: true,
  },
];

const communicationConfigItems = (isActiveMessages) => [
  {
    id: BLENDED_MODULES.BEHAVIOUR.id,
    title: <Trans>Seguimiento</Trans>,
    href: router.configuration.behavior,
    roles: [ROLE_LIST.ADMIN],
    enabled: true,
  },
  {
    id: 'pickups',
    moduleId: BLENDED_MODULES.PICKUPS.id,
    title: <Trans>Autorizaciones</Trans>,
    href: router.configuration.pickups,
    roles: [ROLE_LIST.ADMIN],
    enabled: true,
  },
  {
    id: 'messages',
    title: <Trans>Mensajes</Trans>,
    href: router.configuration.messages,
    roles: [ROLE_LIST.ADMIN],
    enabled: isActiveMessages,
  },
  {
    id: 'year-pass',
    title: <Trans>Informes</Trans>,
    href: router.configuration.reports,
    roles: [ROLE_LIST.ADMIN, ROLE_LIST.EXECUTIVE],
    enabled: true,
  },
];

const getItemsCollections = (isActiveModuleTaxBilling) => {
  return collectionsConfigItems(isActiveModuleTaxBilling).filter(
    (item) => item.enabled,
  );
};

const getItemsCommunication = (institutionId) => {
  const hasActiveMessage = !MESSAGES_EXCLUDED_INSTITUTIONS_IDS.includes(
    institutionId.toString(),
  );

  return communicationConfigItems(hasActiveMessage).filter(
    (item) => item.enabled,
  );
};

export { getItemsCollections, getItemsCommunication };
