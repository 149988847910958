import dayjs from 'dayjs';

import { useGetList } from 'src/shared/services/api/query';
import { paymentManagementRates } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { apiFilter } from 'src/shared/utils/apiFilter';
import { getMonthRange, toDateFormat } from 'src/shared/utils/dates';
import useUserInfo from '../../useUserInfo';
import ratesSerializer from '../../../helpers/ratesSerializer';

const useRates = ({
  dateRange,
  recurrent,
  enabled = true,
  isExtraordinary,
  conceptId,
  expand,
  period,
  ...rest
}) => {
  const { userIs } = useUserInfo();
  const currentMonthIndex = dayjs().get('month');
  const date = dateRange || getMonthRange(currentMonthIndex);
  const isAdmin =
    userIs.admin || userIs.financialAdmin || userIs.financialExecutive;

  const includeExpandTotal = expand?.includes('total');

  const params = {
    expand: expand ?? 'userRates,concept,variations',
    perPage: 2000,
    sort: '-id',
    ...(isExtraordinary !== undefined && { is_extraordinary: isExtraordinary }),
    recurrent,
    ...(date && !recurrent && !isAdmin && !includeExpandTotal
      ? {
          [apiFilter.startAtGte]: toDateFormat(date[0], 'YYYY-MM-DD'),
          [apiFilter.endAtLte]: toDateFormat(date[1], 'YYYY-MM-DD'),
        }
      : {}),
    concept_id: conceptId,
    ...(includeExpandTotal && { period: toDateFormat(period, 'YYYY-MM-DD') }),
    ...rest,
  };

  return useGetList(
    [QUERY_KEY.RATES, params],
    paymentManagementRates(),
    { ...params },
    true,
    false,
    { enabled },
    ratesSerializer,
  );
};

export default useRates;
