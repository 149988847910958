import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Grow, Alert } from '@mui/material';

import { Trans } from '@lingui/macro';

import { MAX_FILE_SIZE_DEFAULT } from 'src/config/general';
import { formatBytes } from 'src/shared/helpers';

const RejectedFiles = memo(({ files, maxSize = MAX_FILE_SIZE_DEFAULT }) => {
  return (
    <Grow in id="rejected-files">
      <Box mt={2}>
        <Alert severity="error">
          <Trans>Los siguientes archivos excedieron el limite máximo</Trans>
          {` (${formatBytes(maxSize)}):`}

          <ul>
            {files.map((file, index) => (
              <li key={file.id ?? index}>
                <b>{file.name}</b>
              </li>
            ))}
          </ul>
        </Alert>
      </Box>
    </Grow>
  );
});

RejectedFiles.displayName = 'RejectedFiles';

RejectedFiles.propTypes = {
  files: PropTypes.array.isRequired,
  maxSize: PropTypes.number,
};

export default RejectedFiles;
