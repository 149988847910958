import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  toolbarButtons: {
    opacity: 0.7,
    color: '#fff',
    '&:hover': {
      opacity: 1,
    },
  },
  loading: {
    color: '#fff',
  },
}));

export default useStyles;
