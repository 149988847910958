import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  userRow: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  },
  userRowDense: {
    padding: `0 ${theme.spacing(2)}`,
  },
}));

export default useStyles;
