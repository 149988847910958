import { useSetAtom, getDefaultStore } from 'jotai';
import { useMemo } from 'react';

import { openDialogAtom } from 'src/shared/atoms';

const useOpenDialogs = (dialogName) => {
  const defaultStore = getDefaultStore();
  const openDialog = useSetAtom(openDialogAtom, {
    store: defaultStore,
  });

  return useMemo(
    () => (isOpen) =>
      openDialog({
        dialogName,
        isOpen,
      }),
    [openDialog, dialogName],
  );
};

export default useOpenDialogs;
