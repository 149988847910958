import useUserInfo from 'src/features/payment-management/hooks/useUserInfo';
import { ROLE_LIST } from 'src/config/general';

/**
 * useCanCustomizeRole - Hook para chequear si se le puede asignar un rol custom a un usuario
 *
 * @param {Object} profileUser - Los datos del usuario a chequear
 * @returns {Object} - Un objeto con el estado de 'canAssignCustomRole'
 */

const useCanCustomizeRole = (profileUser) => {
  const { userIs, data: userData } = useUserInfo();

  const isMyProfile = userData.id === profileUser?.id;

  const profileUserIsStaff = profileUser?.roles.some(
    (role) =>
      role.item_name === ROLE_LIST.EXECUTIVE ||
      role.item_name === ROLE_LIST.PRECEPTOR ||
      role.item_name === ROLE_LIST.TEACHER,
  );

  if (userIs.admin) {
    return profileUserIsStaff || isMyProfile;
  }

  if (userIs.executive) {
    const profileUserIsPreceptorOrTeacher = profileUser?.roles.some(
      (role) =>
        role.item_name === ROLE_LIST.PRECEPTOR ||
        role.item_name === ROLE_LIST.TEACHER,
    );

    return profileUserIsPreceptorOrTeacher && !isMyProfile;
  }

  return false;
};

export default useCanCustomizeRole;
