import { useGetList } from 'src/shared/services/api/query';
import { getScheduledConversation } from 'src/shared/services/url/UrlCommunication';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';

const useGetScheduledConversations = (params = {}) => {
  const filter = {
    subject: params.subject ?? '',
    users: params.users ?? '',
    without_seen: params.unreadFilter || '',
  };

  return useGetList(
    [QUERY_KEY.SCHEDULED_CONVERSATIONS_LIST, filter],
    getScheduledConversation(),
    {
      expand: 'sender',
      ...params,
      ...filter,
    },
    true,
    false,
    {
      refetchInterval: 180000,
    },
  );
};

export default useGetScheduledConversations;
