export const apiFilter = {
  valueGt: 'filter[value][>]',
  valueGte: 'filter[value][>=]',
  valueLt: 'filter[value][<]',
  valueLte: 'filter[value][<=]',
  invoicedAtGt: 'filter[invoiced_at][>]',
  invoicedAtGte: 'filter[invoiced_at][>=]',
  invoicedAtLt: 'filter[invoiced_at][<]',
  invoicedAtLte: 'filter[invoiced_at][<=]',
  createAtGt: 'filter[created_at][>]',
  createAtGte: 'filter[created_at][>=]',
  createAtLt: 'filter[created_at][<]',
  createAtLte: 'filter[created_at][<=]',
  updateAtGt: 'filter[updated_at][>]',
  updateAtGte: 'filter[updated_at][>=]',
  updateAtLt: 'filter[updated_at][<]',
  updateAtLte: 'filter[updated_at][<=]',
  startAtGt: 'filter[start_at][>]',
  startAtGte: 'filter[start_at][>=]',
  startAtLt: 'filter[start_at][<]',
  startAtLte: 'filter[start_at][<=]',
  endAtGt: 'filter[finish_at][>]',
  endAtGte: 'filter[finish_at][>=]',
  endAtLt: 'filter[finish_at][<]',
  endAtLte: 'filter[finish_at][<=]',
  paidAtGt: 'filter[paid_at][>]',
  paidAtGte: 'filter[paid_at][>=]',
  paidAtLt: 'filter[paid_at][<]',
  paidAtLte: 'filter[paid_at][<=]',
  balanceGte: 'filter[balance][>=]',
  balanceLte: 'filter[balance][<=]',
};
