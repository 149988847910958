import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, ButtonBase, Typography } from '@mui/material';
import { useHistory } from 'react-router';

import useStyles from './styles';
import CloseOpenGraph from './CloseOpenGraph';
import router from 'src/shared/utils/router';
import attachment from 'src/assets/images/link-attachment.png';

const OpenGraphCard = ({ onClick, onClose, graphData = {}, canClose }) => {
  const history = useHistory();
  const classes = useStyles();

  const {
    site_url,
    site_title,
    site_name,
    site_description,
    images_urls,
    site_video_url,
  } = graphData;
  const [thumbnail] = images_urls ?? [];

  const handleRedirect = () => {
    if (onClick) {
      onClick();
    } else {
      const isHomework = site_url.includes('?task=');
      if (isHomework) {
        const homeworkId = site_url.split('?task=')[1];
        history.push(router.integrations.virtualClassroom.task(homeworkId));
      }
    }
  };

  const isFromYoutube = site_url.indexOf('youtube') !== -1;

  const isAVideoLink = useMemo(() => isFromYoutube, [isFromYoutube]);

  const createEmbedLink = (link) => {
    if (isFromYoutube) {
      const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = link.match(regExp);
      if (match && match[7].length === 11) {
        return `https://www.youtube.com/embed/${match[7]}`;
      }
    }
  };

  return (
    <Box
      onClick={handleRedirect}
      className={clsx(classes.root, { [classes.bigCard]: site_video_url })}
      id="openGraphData"
    >
      {!site_video_url && isAVideoLink && (
        <>
          <CloseOpenGraph
            classes={clsx(classes.closeOpenGraph, classes.closeVideo)}
            onClose={onClose}
          />
          <iframe
            id="editor-c045081c-4a19-4f08-89a9-837aac7d182f_ifr"
            allowtransparency="true"
            className="tox-edit-area__iframe"
            width="100%"
            height="275"
            src={createEmbedLink(site_url)}
            border="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title={site_title}
          ></iframe>
        </>
      )}

      {!site_video_url && !isAVideoLink && (
        <>
          <Box className={classes.thumbnail}>
            <img src={thumbnail ?? attachment} alt={site_name} />
          </Box>

          <ButtonBase className={classes.content}>
            <Box className={classes.contentWrapper}>
              <Box display="flex">
                <Typography variant="h3" className={classes.title}>
                  {site_title}
                </Typography>
                {canClose && (
                  <CloseOpenGraph
                    classes={clsx(classes.closeOpenGraph, classes.closeVideo)}
                    onClose={onClose}
                  />
                )}
              </Box>

              <Typography className={classes.description}>
                {site_description}
              </Typography>

              <Typography variant="body2" className={classes.domain}>
                {site_name}
              </Typography>
            </Box>
          </ButtonBase>
        </>
      )}

      {site_video_url && (
        <>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: site_video_url,
            }}
          />
          {canClose && (
            <CloseOpenGraph
              classes={clsx(classes.closeOpenGraph, classes.closeVideo)}
              onClose={onClose}
            />
          )}
        </>
      )}
    </Box>
  );
};

OpenGraphCard.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  canClose: PropTypes.bool,
  graphData: PropTypes.shape({
    site_type: PropTypes.string,
    site_url: PropTypes.string,
    site_title: PropTypes.string,
    site_name: PropTypes.string,
    site_description: PropTypes.string,
    images_urls: PropTypes.arrayOf(PropTypes.string),
    site_video_url: PropTypes.string,
  }).isRequired,
};

export default OpenGraphCard;
