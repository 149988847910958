import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const ChangelogDots = memo(({ currentStep, stepsCount }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      justifyContent: 'center',
      mb: 2,
    }}
  >
    {Array(stepsCount)
      .fill(null)
      .map((_, idx) => (
        <Box
          key={idx}
          sx={{
            borderRadius: '50%',
            height: '10px',
            width: '10px',
            backgroundColor: 'grey.100',
            transition: 'background-color 0.1s ease-in-out',
            ...(currentStep === idx && {
              backgroundColor: '#0071F1',
            }),
          }}
        />
      ))}
  </Box>
));

ChangelogDots.displayName = 'ChangelogDots';

ChangelogDots.propTypes = {
  currentStep: PropTypes.number,
  stepsCount: PropTypes.number,
};

export default ChangelogDots;
