import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';
import dayjs from 'dayjs';

import {
  studentNoteBookTypes,
  studentNoteBook,
  studentNoteBookSearch,
  studentNoteBookTypeById,
  signStudentNoteBook,
  viewStudentNoteBook,
} from 'src/shared/services/url/UrlCommunication';
import { Api as api } from 'src/shared/services/api';
import router from 'src/shared/utils/router';

const defaultParams = {
  page: 0,
  limit: 20,
};

const useStudentNotebook = () => {
  const location = useLocation();
  const history = useHistory();
  const { notebookModuleId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [notebookModules, setNotebookModules] = useState([]);
  const [notebookModuleFilters, setNotebookModuleFilters] = useState({});
  const [isNotebookModulesLoading, setIsNotebookModulesLoading] =
    useState(true);

  const [selectedNotebookModule, setSelectedNotebookModule] = useState(null);
  const currentUser = useSelector(({ user }) => user.data);

  const [signingNotebookRecordId, setSigningNotebookRecordId] = useState([]);
  const [notebookModuleRecords, setNotebookModuleRecords] = useState([]);
  const [notebookModuleRecordsPagination, setNotebookModuleRecordsPagination] =
    useState({
      total: 0,
      limit: 20,
      page: 0,
    });
  const [isNotebookModuleRecordsLoading, setIsNotebookModuleRecordsLoading] =
    useState(false);

  const { selectedInstitutionPeriodId } = useSelector(({ user }) => ({
    selectedInstitutionPeriodId: user.selectedInstitutionPeriodId,
  }));

  const fetchNotebookModules = useCallback(async () => {
    setIsNotebookModulesLoading(true);
    const blacklist = ['Convivencia'];

    try {
      const { data: raw } = await api.get({
        url: studentNoteBookTypes(selectedInstitutionPeriodId),
        getRaw: true,
      });

      const allowedModules = (raw.data ?? []).filter(
        (module) => !blacklist.includes(module.name),
      );

      setNotebookModules(allowedModules);
    } catch (message) {
      enqueueSnackbar(message ?? t`Ha ocurrido un problema.`);
    } finally {
      setIsNotebookModulesLoading(false);
    }
  }, [enqueueSnackbar, selectedInstitutionPeriodId]);

  const fetchNotebookModuleRecords = useCallback(
    async (params = defaultParams) => {
      setIsNotebookModuleRecordsLoading(true);

      const {
        page = defaultParams.page,
        limit = defaultParams.limit,
        ...restOfParams
      } = params;

      const promise = notebookModuleFilters.division_id ? api.post : api.get;

      try {
        const {
          data: { data: records },
          headers,
        } = await promise({
          url: notebookModuleFilters.division_id
            ? studentNoteBookSearch()
            : studentNoteBook(),
          getRaw: true,
          data: {
            ...restOfParams,
            student_notebook_type_id: notebookModuleId,
            page: !page ? 1 : page + 1,
            perPage: limit,
            ...notebookModuleFilters,
          },
        });

        const { role } = restOfParams;

        if (!role) {
          records.sort((a, b) =>
            `${a.user.last_name} ${a.user.name}`.localeCompare(
              `${b.user.last_name} ${b.user.name}`,
            ),
          );
        }

        setNotebookModuleRecords(records);
        setNotebookModuleRecordsPagination({
          total: Number(headers['x-pagination-total-count']),
          limit,
          page,
          role,
        });
      } catch ({ message }) {
        enqueueSnackbar(message ?? t`Ha ocurrido un problema.`, {
          variant: 'error',
        });
      } finally {
        setIsNotebookModuleRecordsLoading(false);
      }
    },
    // TODO: Include notebookModuleFilters object when StructureFilters doesn't trigger on every check
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notebookModuleFilters.division_id, notebookModuleId, enqueueSnackbar],
  );

  const markNotebookModuleRecordAsViewed = useCallback(
    async (recordId) => {
      try {
        await api.post({
          url: viewStudentNoteBook(recordId),
          getRaw: true,
        });

        setNotebookModuleRecords((oldNotebookRecords) => {
          return oldNotebookRecords.map((oldRecord) => {
            if (oldRecord.id === recordId) {
              return {
                ...oldRecord,
                viewed: true,
              };
            }

            return oldRecord;
          });
        });
      } catch ({ message }) {
        enqueueSnackbar(
          message ?? t`Ha ocurrido un error al marcar el registro como visto`,
          {
            variant: 'error',
          },
        );
      } finally {
        setSigningNotebookRecordId(null);
      }
    },
    [enqueueSnackbar],
  );

  const handleChangeNotebookRecordsPage = useCallback(
    (newPage) => {
      fetchNotebookModuleRecords({
        ...notebookModuleRecordsPagination,
        page: newPage,
      });
    },
    [fetchNotebookModuleRecords, notebookModuleRecordsPagination],
  );

  const handleChangeNotebookRecordsLimit = useCallback(
    (limit) => {
      fetchNotebookModuleRecords({
        ...notebookModuleRecordsPagination,
        limit,
      });
    },
    [fetchNotebookModuleRecords, notebookModuleRecordsPagination],
  );

  const handleToggleNotebookModuleSignature = useCallback(
    async (event) => {
      const { target } = event;

      try {
        setSelectedNotebookModule((oldNotebookModule) => ({
          ...oldNotebookModule,
          require_signature: target.checked,
        }));

        setNotebookModules((oldNotebookModules) =>
          oldNotebookModules.map((oldNotebookModule) => {
            if (oldNotebookModule.id === selectedNotebookModule.id) {
              return {
                ...oldNotebookModule,
                require_signature: !oldNotebookModule.require_signature,
              };
            }

            return oldNotebookModule;
          }),
        );

        await api.put({
          url: studentNoteBookTypeById({
            notebookTypeId: notebookModuleId,
          }),
          data: {
            institution_period_id: selectedInstitutionPeriodId,
            require_signature: target.checked,
          },
        });
      } catch ({ message }) {
        enqueueSnackbar(message ?? t`Ha ocurrido un problema.`, {
          variant: 'error',
        });
        setSelectedNotebookModule((oldNotebookModule) => ({
          ...oldNotebookModule,
          require_signature: !target.checked,
        }));
      }
    },
    [
      notebookModuleId,
      enqueueSnackbar,
      selectedNotebookModule,
      selectedInstitutionPeriodId,
    ],
  );

  const handleNotebookModuleFilterChange = useCallback(
    async ({ divisions }) => {
      setNotebookModuleFilters((oldFilters) => ({
        ...oldFilters,
        division_id: divisions,
      }));
    },
    [],
  );

  const handleSignNotebookRecord = useCallback(
    async (notebookRecord) => {
      setSigningNotebookRecordId(notebookRecord.id);

      try {
        await api.post({
          url: signStudentNoteBook(notebookRecord.id),
          getRaw: true,
        });

        setNotebookModuleRecords((oldNotebookRecords) => {
          return oldNotebookRecords.map((oldRecord) => {
            if (oldRecord.id === notebookRecord.id) {
              return {
                ...oldRecord,
                signed: true,
                signed_datetime: dayjs(),

                signed_users: {
                  $push: [currentUser],
                },
              };
            }

            return oldRecord;
          });
        });
        enqueueSnackbar(t`${selectedNotebookModule?.name} firmado con éxito.`, {
          variant: 'success',
        });
      } catch ({ message }) {
        enqueueSnackbar(message ?? t`Ha ocurrido un problema.`, {
          variant: 'error',
        });
      } finally {
        setSigningNotebookRecordId(null);
      }
    },
    [currentUser, enqueueSnackbar, selectedNotebookModule],
  );

  useEffect(() => {
    fetchNotebookModules();
  }, [fetchNotebookModules]);

  useEffect(() => {
    if (notebookModules.length > 0) {
      const currentNotebookModule = notebookModules.find(
        (notebookModule) =>
          Number(notebookModule.id) === Number(notebookModuleId),
      );

      if (currentNotebookModule) {
        setSelectedNotebookModule(currentNotebookModule);
      } else if (location.pathname === router.integrations.documents.root) {
        history.push(
          router.integrations.documents.module(notebookModules[0].id),
        );
      } else {
        history.push(
          router.communication.pending.pending(notebookModules[0].id),
        );
      }
    }
  }, [notebookModuleId, notebookModules, history, location]);

  return {
    notebookModules,
    selectedNotebookModule,
    setSelectedNotebookModule,
    isNotebookModulesLoading,

    notebookModuleRecords,
    isNotebookModuleRecordsLoading,
    signingNotebookRecordId,
    notebookModuleRecordsPagination,

    handleToggleNotebookModuleSignature,
    handleNotebookModuleFilterChange,
    handleChangeNotebookRecordsPage,
    handleChangeNotebookRecordsLimit,
    handleSignNotebookRecord,
    markNotebookModuleRecordAsViewed,
    fetchNotebookModuleRecords,
  };
};

export default useStudentNotebook;
