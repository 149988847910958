import { useGetOne } from 'src/shared/services/api/query';
import { paymentManagementSingleRate } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import ratesSerializer from 'src/features/payment-management/helpers/ratesSerializer';

const useGetRateById = ({
  enabled = true,
  id,
  queryParams = {},
  ...params
} = {}) => {
  return useGetOne(
    [QUERY_KEY.RATES, id, params],
    paymentManagementSingleRate(id),
    { expand: 'concept,variations', ...params },
    true,
    false,
    {
      enabled,
      ...(!enabled && { cacheTime: 0 }),
      ...queryParams,
    },
    ratesSerializer,
  );
};

export default useGetRateById;
