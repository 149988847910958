import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router';

import {
  noInboxActiveConversationAtom,
  inboxSearchQueryAtom,
} from '../../atoms';
import { useGetDraftConversations, useCreateDraftConversation } from '..';
import router from 'src/shared/utils/router';

const useDraftConversations = () => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useAtom(inboxSearchQueryAtom);
  const [activeConversation, setActiveConversation] = useAtom(
    noInboxActiveConversationAtom,
  );

  const {
    data: draftConversation,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useGetDraftConversations({
    subject: searchQuery,
    users: searchQuery,
  });

  const { mutateAsync: createDraftConversation } = useCreateDraftConversation();

  const handleClickConversation = useCallback(
    (conversation) => {
      if (conversation) {
        setActiveConversation(conversation);
        history.push(router.communication.draft.conversation(conversation?.id));
      }
    },
    [history, setActiveConversation],
  );

  const handleGoBack = () => {
    history.push(router.communication.draft.root);
  };

  return {
    createDraftConversation,
    searchQuery,
    setSearchQuery,
    draftConversation,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    activeConversation,
    setActiveConversation,
    handleClickConversation,
    handleGoBack,
  };
};

export default useDraftConversations;
