import abstractSerializer from 'src/shared/services/api/query/serializer/abstractSerializer';

const deserializeExternalLink = (link) => ({
  id: link.id,
  link: link.link,
  name: link.name,
  roles: link.roles ?? [],
  divisions: link.division_ids ?? [],
});

const serializeExternalLink = (link) => ({
  id: link.id,
  link: link.link,
  name: link.name,
  roles: link.roles.map((roles) => roles.value),
  division_ids: link.divisions,
});

const deserialize = (data) => {
  if (Array.isArray(data)) {
    return data.map((behaviour) => deserializeExternalLink(behaviour));
  }

  return deserializeExternalLink(data);
};

const serialize = (data) => {
  if (Array.isArray(data)) {
    return data.map((behaviour) => serializeExternalLink(behaviour));
  }

  return serializeExternalLink(data);
};

const externalLinkSerializer = abstractSerializer(serialize, deserialize);

export default externalLinkSerializer;
