import React, { forwardRef } from 'react';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';

import PageBase from 'src/components/Page/PageBase';
import { PUBLIC_URL } from 'src/config/general';

const Page = forwardRef(({ metadata = {}, ...params }, ref) => {
  const { title, description, type, image } = params;

  const location = useLocation();
  const url = `${PUBLIC_URL}/${location.pathname}`;

  return (
    <>
      <Helmet>
        <title>{metadata.title ?? title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content={type} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={image} />
      </Helmet>
      <PageBase {...params} ref={ref} />
    </>
  );
});

export default Page;
