import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Edit as EditIcon, Trash as DeleteIcon } from 'react-feather';
import { Trans, t } from '@lingui/macro';
import GenericMoreButton from 'src/components/GenericMoreButton';
import { stopPropagation } from 'src/shared/utils/events';

const DEFAULT_OPTIONS = {
  edit: { label: <Trans>Editar</Trans>, disabled: false },
  delete: { label: <Trans>Eliminar</Trans>, disabled: false },
};

const BasicActionsMenu = ({
  onEdit,
  onDelete,
  defaultOptions = {
    edit: DEFAULT_OPTIONS.edit,
    delete: DEFAULT_OPTIONS.delete,
  },
  unshift,
  children,
  tooltip = t`Acciones`,
  ...rest
}) => {
  return (
    <GenericMoreButton tooltip={tooltip} {...rest}>
      {unshift && children}

      {onEdit && (
        <MenuItem
          onClick={stopPropagation(onEdit)}
          disabled={defaultOptions.edit.disabled}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary={defaultOptions.edit.label} />
        </MenuItem>
      )}

      {onDelete && (
        <MenuItem
          onClick={stopPropagation(onDelete)}
          disabled={defaultOptions.delete.disabled}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary={defaultOptions.delete.label} />
        </MenuItem>
      )}

      {!unshift && children}
    </GenericMoreButton>
  );
};

BasicActionsMenu.displayName = 'BasicActionsMenu';

BasicActionsMenu.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  defaultOptions: PropTypes.shape({
    edit: PropTypes.shape({
      label: PropTypes.string,
      disabled: PropTypes.bool,
    }),
    delete: PropTypes.shape({
      label: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  }),
  unshift: PropTypes.bool,
  children: PropTypes.node,
  tooltip: PropTypes.string,
};

export default memo(BasicActionsMenu);
