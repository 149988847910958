import { useGetPaginatedList } from 'src/shared/services/api/query';
import { paymentManagementVariationFamily } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const useSelectedFamilyMembers = (discountId, enabled = true, params) => {
  const query = useGetPaginatedList(
    [QUERY_KEY.DISCOUNT_SELECTED_FAMILY_MEMBERS, discountId],
    paymentManagementVariationFamily(discountId),
    params,
    true,
    { enabled: enabled && !!discountId },
  );

  return {
    ...query,
    data: query?.data ?? [],
  };
};

export default useSelectedFamilyMembers;
