import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import useGenerateFileToken from 'src/shared/hooks/uploads/useGenerateFileToken';
import useUploadFileToAzure from 'src/shared/hooks/uploads/useUploadFileToAzure';
import { QUERY_KEY } from 'src/features/my-account/constants/queryKey';

const ERROR_MESSAGE = t`Ha ocurrido un error al intentar subir el archivo.`;

const useUploadProfilePhoto = (options) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: generateFileToken } = useGenerateFileToken();
  const { mutateAsync: uploadFileToAzure } = useUploadFileToAzure();

  const mutationFn = async ({ userId, file }) => {
    const fileExtension = `.${file.name.split('.').pop()}`;
    const { data: tokenData } = await generateFileToken({
      extension: fileExtension,
    });
    const finalUrl = tokenData.token.split('?se=')[0];
    await uploadFileToAzure({ tokenUrl: tokenData.token, file });

    return { userId, finalUrl };
  };

  const onSuccess = ({ userId, finalUrl }) => {
    const queryKey = {
      predicate: (query) => {
        return (
          query.queryKey[0] === QUERY_KEY.USER_INFO &&
          query.queryKey[1] === userId
        );
      },
    };

    const prevValue = queryClient.getQueryData(queryKey);

    queryClient.setQueriesData(queryKey, (oldValue) => ({
      ...prevValue,
      data: { ...oldValue, photo: finalUrl },
    }));
    return { prevValue };
  };

  return useMutation({
    mutationFn,
    onSuccess,
    onError: () => enqueueSnackbar(ERROR_MESSAGE, { variant: 'error' }),
    ...options,
  });
};

export default useUploadProfilePhoto;
