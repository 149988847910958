import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { t } from '@lingui/macro';

import InfoTitle from 'src/features/my-account/components/InfoTitle';

const FamilyInfo = ({ userInfo }) => {
  const { families = [] } = userInfo ?? {};

  if (!families.length) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <InfoTitle title={t`familia`}>
        <Box display={'flex'} flexDirection={'column'} paddingTop={1}>
          {families
            .filter((family) => Boolean(family))
            .map((family, index) => {
              const text = `${family.name} (ID:${family.id})`;
              return (
                <Typography
                  key={index}
                  mb={0.5}
                  variant={'body1'}
                  color="primary"
                >
                  {text}
                </Typography>
              );
            })}
        </Box>
      </InfoTitle>
    </Grid>
  );
};

FamilyInfo.propTypes = {
  userInfo: PropTypes.object,
};

export default FamilyInfo;
