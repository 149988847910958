import { atom } from 'jotai';

const initialValues = {
  isOpen: false,
  allSelected: false,
  count: 0,
  usersSelected: [],
};

const selectionPaymentRateUsersAtom = atom(initialValues);
selectionPaymentRateUsersAtom.debugLabel = 'selectionPaymentRateUsersAtom';

export default selectionPaymentRateUsersAtom;
