import { useGetList } from 'src/shared/services/api/query';
import { getConversation } from 'src/shared/services/url/UrlCommunication';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';

const useGetUnreadConversations = () => {
  const query = useGetList(
    [QUERY_KEY.UNREAD_CONVERSATIONS],
    getConversation(),
    { without_seen: true },
    true,
    false,
    { select: undefined },
  );

  const { data } = query ?? {};
  const parsedData = data?.pages.map(({ data }) => data).flat() ?? [];

  return {
    ...query,
    data: parsedData,
    total: data?.pages[0].totalItems ?? 0,
  };
};

export default useGetUnreadConversations;
