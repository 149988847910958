import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableContainer,
  TablePagination,
  Alert,
  TableRow,
  TableCell,
  TableBody as TableBodyMUI,
} from '@mui/material';

import ScrollBar from 'src/components/ScrollBar';
import ElevationScroll from 'src/components/ElevationScroll';
import { useBreakpoints } from 'src/shared/hooks';

import BulkOperations from './BulkOperations';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TableActions from './TableActions';
import TableSkeleton from './TableSkeleton';

import { useStyles } from './styles';

const TableList = forwardRef(
  (
    {
      className,
      items = [],
      columns = [],
      actions,
      title,
      subheader,
      page,
      limit,
      onPageChange,
      onLimitPageChange,
      pagination,
      filters,
      loading,
      placeholder,
      elevation,
      onClickItem,
      stickyHeader,
      stickyFirstColumn,
      tableContainerProps,
      emptyState,
      loadingComponent = <TableSkeleton />,
      enableChecks = false,
      checkProps,
      bulkOperation,
      selectedItemsExternal,
      onSelectItems = (items, isSelectedAll, itemSelected) => {},
      showEmptyTable,
      TableRowComponent,
      PaginationActionsComponent,
      hasTableHead = true,
      hover,
      onRowMouseEnter,
      onRowMouseLeave,
      TotalSelectionComponent,
      rowsPerPageOptions = [5, 10, 20, 50, 100],
      loadingLastRow,
      checkPosition,
      alertComponent,
      showAlert,
      actionsHeader,
      withDeselectionDefault,
      ...rest
    },
    ref,
  ) => {
    const classes = useStyles();
    const { isMobile } = useBreakpoints();
    const [selectedItemsInternal, setSelectedItems] = useState([]);
    const [sortedItems, setSortedItems] = useState([]);
    const tableContainerRef = useRef();

    const columnToSort = useRef(null);
    const bodyItems = sortedItems.length ? sortedItems : items;

    const selectedItems = selectedItemsExternal || selectedItemsInternal;

    const handleSelectAllItems = useCallback(
      (event) => {
        setSelectedItems(event.target.checked ? items : []);

        if (onSelectItems) {
          onSelectItems(event.target.checked ? items : [], true);
        }
      },
      [items, onSelectItems],
    );

    const handleSelectOneItem = useCallback(
      (...[, item]) => {
        const selectedItemsIds = selectedItems.map(
          (selectedItem) => selectedItem.id,
        );

        if (!selectedItemsIds.includes(item.id)) {
          if (onSelectItems) {
            onSelectItems([...selectedItems, item], false, item);
          }
          setSelectedItems((prevSelected) => [...prevSelected, item]);
        } else {
          if (onSelectItems) {
            onSelectItems(
              [...selectedItems.filter((prevItem) => prevItem.id !== item.id)],
              false,
              item,
            );
          }

          setSelectedItems((prevSelected) =>
            prevSelected.filter((prevItem) => prevItem.id !== item.id),
          );
        }
      },
      [onSelectItems, selectedItems],
    );

    const handleSortChange = useCallback(
      (columnKey) => {
        const column = columns.find((col) => col.key === columnKey);
        columnToSort.current = columnKey;

        if (column.sort && typeof column.sort === 'function') {
          // Since .sort mutates the data send a copy instead.
          const itemsToSort = Array.from(sortedItems.length > 0 ? [] : items);

          setSortedItems(column.sort(itemsToSort));
        }
      },
      [columns, items, sortedItems],
    );

    const handlePageChange = (...[, newPage]) => {
      onPageChange(newPage);
    };

    const handleLimitChange = (event) => {
      onLimitPageChange(event.target.value);
    };

    const selectedSomeItems =
      selectedItems.length > 0 && selectedItems.length < items.length;
    const selectedAllItems = selectedItems.length === items.length;

    useEffect(() => {
      const column = columns.find((col) => col.key === columnToSort.current);

      if (column) {
        // Since .sort mutates the data send a copy instead.
        const itemsToSort = Array.from(items);

        setSortedItems(column.sort(itemsToSort));
      }
    }, [columns, items]);

    return (
      <Box className={clsx(classes.root, className)} {...rest}>
        <Card elevation={elevation}>
          {(title || subheader) && (
            <>
              <CardHeader title={title} subheader={subheader} />
              <Divider />
            </>
          )}

          {filters && (
            <>
              <Box p={2}>{filters}</Box>
              <Divider />
            </>
          )}

          <ScrollBar>
            <TableContainer ref={tableContainerRef} {...tableContainerProps}>
              {loading && loadingComponent}

              {!loading && (items.length > 0 || showEmptyTable) && (
                <Table
                  stickyHeader={stickyHeader}
                  {...(isMobile && {
                    size: 'small',
                  })}
                >
                  {hasTableHead && (
                    <ElevationScroll
                      listener={tableContainerRef.current}
                      propsOnThreshold={{
                        className: classes.elevatedHead,
                      }}
                    >
                      <TableHead
                        checkProps={checkProps}
                        enableChecks={enableChecks}
                        withActions={Boolean(actions)}
                        columns={columns}
                        selectedAllItems={selectedAllItems}
                        selectedSomeItems={selectedSomeItems}
                        onSelectAllItems={handleSelectAllItems}
                        onSortChange={handleSortChange}
                        stickyFirstColumn={stickyFirstColumn}
                        selectedCount={selectedItems.length}
                        totalCount={pagination?.total}
                        TotalSelectionComponent={TotalSelectionComponent}
                        actionsHeader={actionsHeader}
                        withDeselectionDefault={withDeselectionDefault}
                      />
                    </ElevationScroll>
                  )}
                  {showAlert && (
                    <TableBodyMUI>
                      <TableRow sx={{ width: '100%' }}>
                        <TableCell colSpan={columns.length + 1}>
                          {alertComponent}
                        </TableCell>
                      </TableRow>
                    </TableBodyMUI>
                  )}
                  <TableBody
                    stickyFirstColumn={stickyFirstColumn}
                    checkProps={checkProps}
                    enableChecks={enableChecks}
                    checkPosition={checkPosition}
                    items={bodyItems}
                    selectedItems={selectedItems ?? []}
                    columns={columns}
                    actions={actions}
                    onSelectOneItem={handleSelectOneItem}
                    onClickItem={onClickItem}
                    TableRowComponent={TableRowComponent}
                    hover={hover}
                    onRowMouseEnter={onRowMouseEnter}
                    onRowMouseLeave={onRowMouseLeave}
                    loadingLastRow={loadingLastRow}
                  />
                </Table>
              )}

              {!loading &&
                !items.length &&
                !emptyState &&
                !showEmptyTable &&
                emptyState !== false && (
                  <Box p={2}>
                    <Alert severity="info">
                      {placeholder || t`No se encontraron resultados.`}
                    </Alert>
                  </Box>
                )}
              {!loading && !items.length && !showEmptyTable && emptyState}
            </TableContainer>
          </ScrollBar>

          {!!items.length && pagination && (
            <TablePagination
              component="div"
              classes={{ spacer: classes.paginationSpacer }}
              count={pagination.total}
              labelRowsPerPage={
                pagination.labelRowsPerPage ?? t`Filas por página`
              }
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={pagination.page}
              rowsPerPage={pagination.limit}
              rowsPerPageOptions={rowsPerPageOptions}
              ActionsComponent={PaginationActionsComponent}
            />
          )}
        </Card>

        <BulkOperations open={bulkOperation && selectedItems.length > 0}>
          {bulkOperation}
        </BulkOperations>
      </Box>
    );
  },
);

TableList.propTypes = {
  onPageChange: PropTypes.func,
  onLimitPageChange: PropTypes.func,
  className: PropTypes.string,
  items: PropTypes.array,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      format: PropTypes.func,
      key: PropTypes.string.isRequired,
      render: PropTypes.func,
      renderColumnCell: PropTypes.func,
    }),
  ),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      baseUrl: PropTypes.string,
      handler: PropTypes.func,
      icon: PropTypes.any,
    }),
  ),
  page: PropTypes.number,
  limit: PropTypes.number,
  pagination: PropTypes.object,
  filters: PropTypes.any,
  subheader: PropTypes.string,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  elevation: PropTypes.number,
  onClickItem: PropTypes.func,
  stickyHeader: PropTypes.bool,
  stickyFirstColumn: PropTypes.bool,
  tableContainerProps: PropTypes.object,
  emptyState: PropTypes.node,
  loadingComponent: PropTypes.node,
  bulkOperation: PropTypes.node,
  enableChecks: PropTypes.bool,
  onSelectItems: PropTypes.func,
  checkProps: PropTypes.object,
  showEmptyTable: PropTypes.bool,
  TableRowComponent: PropTypes.any,
  hover: PropTypes.bool,
  onRowMouseEnter: PropTypes.func,
  onRowMouseLeave: PropTypes.func,
  PaginationActionsComponent: PropTypes.elementType,
  selectedItemsExternal: PropTypes.array,
  hasTableHead: PropTypes.bool,
  TotalSelectionComponent: PropTypes.node,
  rowsPerPageOptions: PropTypes.array,
  loadingLastRow: PropTypes.node,
  showAlert: PropTypes.bool,
  alertComponent: PropTypes.node,
  checkPosition: PropTypes.string,
  actionsHeader: PropTypes.bool,
  withDeselectionDefault: PropTypes.bool,
};

export default TableList;
export { BulkOperations, TableActions, TableHead, TableBody };
