import {
  mdiImage,
  mdiVideo,
  mdiVolumeHigh,
  mdiFileWordBox,
  mdiFileExcelBox,
  mdiFilePowerpointBox,
  mdiFormatText,
  mdiFilePdfBox,
  mdiZipBox,
  mdiHelp,
  mdiOpenInNew,
  mdiLanguageMarkdown,
} from '@mdi/js';
import { t } from '@lingui/macro';

export const getFileType = (extOrMimeType) => {
  let fileType;

  switch (extOrMimeType?.toLowerCase()) {
    case '.png':
    case 'image/png':
    case '.jpg':
    case '.jpeg':
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/pjpeg':
    case '.gif':
    case 'image/gif':
    case '.bmp':
    case 'image/bmp':
    case 'image/x-windows-bmp':
      fileType = 'picture';
      break;
    case '.mp4':
    case 'video/mp4':
    case '.m4v':
    case 'wmv':
    case 'video/wmv':
    case 'video/ogg':
    case '.webm':
    case 'video/webm':
    case '.3gp':
    case 'video/3gp':
    case '.flv':
    case 'video/flv':
    case '.avi':
    case 'video/avi':
    case 'video/msvideo':
    case 'video/x-msvideo':
    case 'video/x-troff-msvideo':
    case 'video/mpeg':
    case 'video/x-mpeg':
    case '.mov':
    case 'video/quicktime':
    case '.mkv':
      fileType = 'video';
      break;
    case '.mp3':
    case '.wma':
    case '.wav':
    case '.ogg':
    case 'audio/mpeg3':
    case 'audio/x-mpeg-3':
    case 'audio/mpeg':
    case 'audio/ogg':
    case 'audio/wav':
    case 'audio/x-wav':
      fileType = 'audio';
      break;
    case '.doc':
    case '.docx':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
    case 'application/vnd.ms-word.document.macroEnabled.12':
    case 'application/vnd.ms-word.template.macroEnabled.12':
      fileType = 'word';
      break;
    case '.xls':
    case '.xlsx':
    case '.xlsm':
    case 'application/excel':
    case 'application/vnd.ms-excel':
    case 'application/x-excel':
    case 'application/x-msexcel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
    case 'application/vnd.ms-excel.sheet.macroEnabled.12':
    case 'application/vnd.ms-excel.template.macroEnabled.12':
    case 'application/vnd.ms-excel.addin.macroEnabled.12':
    case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
      fileType = 'excel';
      break;
    case '.csv':
    case 'text/csv':
      fileType = 'csv';
      break;
    case '.ppt':
    case '.pptx':
    case 'application/mspowerpoint':
    case 'application/powerpoint':
    case 'application/vnd.ms-powerpoint':
    case 'application/x-mspowerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.openxmlformats-officedocument.presentationml.template':
    case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
    case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
    case '.pps':
    case '.ppsx':
      fileType = 'powerpoint';
      break;
    case '.txt':
    case 'text/plain':
      fileType = 'text';
      break;
    case '.pdf':
    case 'application/pdf':
      fileType = 'adobe';
      break;
    case '.rar':
    case '.zip':
    case '.7z':
    case 'application/zip':
    case 'multipart/x-zip':
    case 'application/x-compressed':
    case 'application/x-rar':
    case 'application/x-rar-compressed':
    case 'application/x-zip-compressed':
      fileType = 'compress';
      break;
    case 'undefined':
      fileType = 'others';
      break;
    case 'application/vnd.google-apps.document':
      fileType = 'google-document';
      break;
    case 'application/vnd.google-apps.spreadsheet':
      fileType = 'google-spreadsheet';
      break;
    case 'application/vnd.google-apps.presentation':
      fileType = 'google-presentation';
      break;
    case 'application/vnd.google-apps.file':
      fileType = 'google-file';
      break;
    case 'application/vnd.google-apps.video':
      fileType = 'google-video';
      break;
    case 'application/vnd.google-apps.audio':
      fileType = 'google-audio';
      break;
    case 'text/markdown':
    case '.markdn':
    case '.markdown':
    case '.md':
    case '.mdown':
      fileType = 'markdown';
      break;
    default:
      fileType = 'undefined';
  }
  return fileType;
};

export const getFileStyles = (fileType) => {
  const fileStyles = {
    icon: mdiHelp,
    fileClass: fileType,
  };

  switch (fileType) {
    case 'picture':
      fileStyles.icon = mdiImage;
      break;
    case 'video':
    case 'google-video':
      fileStyles.icon = mdiVideo;
      break;
    case 'audio':
    case 'google-audio':
      fileStyles.icon = mdiVolumeHigh;
      break;
    case 'word':
    case 'google-document':
      fileStyles.icon = mdiFileWordBox;
      break;
    case 'excel':
    case 'csv':
    case 'google-spreadsheet':
      fileStyles.icon = mdiFileExcelBox;
      break;
    case 'powerpoint':
    case 'google-presentation':
      fileStyles.icon = mdiFilePowerpointBox;
      break;
    case 'text':
      fileStyles.icon = mdiFormatText;
      break;
    case 'adobe':
      fileStyles.icon = mdiFilePdfBox;
      break;
    case 'compress':
    case 'google-file':
      fileStyles.icon = mdiZipBox;
      break;
    case 'others':
      fileStyles.icon = mdiHelp;
      break;
    case 'external':
      fileStyles.icon = mdiOpenInNew;
      break;
    case 'markdown':
      fileStyles.icon = mdiLanguageMarkdown;
      break;
    default:
      fileStyles.icon = mdiHelp;
      break;
  }

  return fileStyles;
};

export const GOOGLE_DRIVE_FILE_TYPES = [
  'google-document',
  'google-spreadsheet',
  'google-presentation',
];
export const DOCUMENT_FILE_TYPES = [
  'word',
  'excel',
  'powerpoint',
  'adobe',
  'text',
  ...GOOGLE_DRIVE_FILE_TYPES,
];
export const PREVIEWABLE_FILE_TYPE = [
  'picture',
  ...DOCUMENT_FILE_TYPES.filter(
    (type) => !GOOGLE_DRIVE_FILE_TYPES.includes(type),
  ),
];

export const downloadFile = ({
  link,
  original_file_name: fileName,
  setLoading,
}) => {
  const xhr = new XMLHttpRequest();
  if (setLoading) setLoading(true);
  xhr.responseType = 'blob';
  xhr.onload = () => {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = fileName;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
  };
  xhr.onprogress = (e) => {
    if (e.lengthComputable && e.loaded === e.total && setLoading) {
      setLoading(false);
    } else if (setLoading) {
      setLoading(false);
    }
  };
  xhr.open('GET', link);
  xhr.send();
};

export const downloadFileWebView = (link, fileName) => {
  const a = document.createElement('a');
  a.download = fileName;
  a.href = link;
  a.target = '_blank';
  a.click();
};

export const downloadFileBlob = (blobParts, options, fileName) => {
  const blob = new Blob([blobParts], options);
  const url = window.URL.createObjectURL(blob);

  downloadFileWebView(url, fileName);
};

export const FILE_TYPES = {
  default: 1,
  drive: 3,
  custom_link: 4,
  oneDrive: 5,
};

export const handleSubtitle = (fileType) => {
  switch (fileType) {
    case FILE_TYPES.custom_link:
      return t`Enlace externo`;
    case FILE_TYPES.drive:
      return t`Google Drive`;
    case FILE_TYPES.oneDrive:
      return t`One Drive`;
    default:
      return undefined;
  }
};

export const isGoogleDriveFile = (fileType) => fileType === FILE_TYPES.drive;

export const checkForCustomLink = (fileType) =>
  fileType === FILE_TYPES.custom_link;

export default {};
