import state from './state';
import reducers from './reducers';
import effects from './effects';

// este es el modelo del que habla la documentacion de rematch
// eslint-disable-next-line import/prefer-default-export
export const vc = {
  state,
  reducers,
  effects,
};
