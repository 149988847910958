import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { usePut } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';
import { updateConversation } from 'src/shared/services/url/UrlCommunication';

const useUpdateConversation = (conversationId) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return usePut(
    updateConversation(conversationId),
    {
      onSuccess: () => {
        enqueueSnackbar(t`Mensaje actualizado con éxito.`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t`Hubo un error al intentar actualizar el mensaje`, {
          variant: 'error',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries([QUERY_KEY.CONVERSATIONS_LIST], {
          refetchType: 'all',
        });
        queryClient.invalidateQueries([
          QUERY_KEY.CONVERSATIONS_BY_ID,
          `${conversationId}`,
        ]);
      },
    },
    false,
  );
};

export default useUpdateConversation;
