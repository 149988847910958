import { useSnackbar } from 'notistack';
import { useState, useEffect, useCallback } from 'react';
import { t } from '@lingui/macro';

import { getTaskDetails } from 'src/shared/services/url/UrlVirtualClassroom';
import { Api as api } from 'src/shared/services/api';

const initialPagination = {
  page: 0,
  limit: 10,
  total: 0,
};

const useTasks = (url, params) => {
  const [tasks, setTasks] = useState(null);
  const [error, setError] = useState(null);
  const [intermittentError, setIntermittentError] = useState(null);
  const [pagination, setPagination] = useState(initialPagination);

  const { enqueueSnackbar } = useSnackbar();

  const {
    subject_id,
    user_id,
    status_id,
    lecture_id,
    expand,
    role,
    task_user_status_id,
  } = params;

  const fetchTasks = useCallback(
    async ({ page, limit } = initialPagination) => {
      setTasks(null);
      setError(null);
      try {
        const { data: raw, headers } = await api.get({
          url,
          data: {
            subject_id,
            lecture_id,
            status_id,
            user_id,
            expand,
            role,
            'task_user.status_id': task_user_status_id,
            page: !page ? 1 : page + 1,
            'per-page': limit,
          },
          getRaw: true,
        });
        setTasks(raw.data);
        setError(null);
        setPagination({
          page,
          limit: Number(headers['x-pagination-per-page']),
          total: Number(headers['x-pagination-total-count']),
        });
      } catch (apiError) {
        setTasks(null);
        setError(apiError);
      }
    },
    [
      url,
      subject_id,
      lecture_id,
      status_id,
      user_id,
      expand,
      role,
      task_user_status_id,
    ],
  );

  const handlePageChange = (newPage) => {
    fetchTasks({
      ...pagination,
      page: newPage,
    });
  };

  const handleLimitPageChange = (limit) => {
    fetchTasks({
      ...pagination,
      limit,
    });
  };

  const handleRemoveTask = async (task) => {
    try {
      await api.delete({
        url: `${url}/${task.id}`,
      });

      enqueueSnackbar(t`Se borró la tarea con éxito.`, { variant: 'success' });

      setTasks((oldTasks) =>
        oldTasks.filter((oldTask) => oldTask.id !== task.id),
      );
    } catch ({ message }) {
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const handleEditTask = async (task) => {
    try {
      const { data } = await api.put({
        url: getTaskDetails(task.id),
        data: task,
      });

      if (intermittentError) setIntermittentError(null);

      setTasks((oldTasks) =>
        oldTasks.map((oldTask) => {
          if (oldTask.id === task.id) {
            return data;
          }

          return oldTask;
        }),
      );
    } catch (apiError) {
      enqueueSnackbar(apiError.message, { variant: 'error' });
      setIntermittentError(apiError);
    }
  };

  const fetchTaskDetails = useCallback(async (homeworkId) => {
    const { data } = await api.get({
      url: getTaskDetails(homeworkId),
      data: { expand: 'task_users, attachments' },
    });
    return data;
  }, []);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  return {
    handlePageChange,
    handleLimitPageChange,
    handleRemoveTask,
    handleEditTask,
    tasks,
    error,
    intermittentError,
    pagination,
    fetchTaskDetails,
  };
};

export default useTasks;
