import createRouter from './createRouter';

const authRouter = createRouter('auth', {
  login: `/login`,
  recoveryPassword: `/recuperar`,
  logout: `/logout`,
  firstLogin: `/ingreso`,
});

export default authRouter;
