import router from '../router';

// TODO: Remove this function when the backend fixes the link
/**
 * Fixes the link to be backward compatible with new `router` schema
 * @param {string} link - Old Link to be fixed
 * @returns {string} - New router compatible link
 */
export const routerCompatibleLink = (link) => {
  if (link === '/') {
    return router.communication.newsfeed.root;
  }

  // Notifications
  if (link.includes('/verpost/index/')) {
    return link.replace('/verpost/index/', '/comunicacion/publicaciones/');
  }

  if (link.startsWith('/pagos/familia/')) {
    return link.replace('/pagos/familia/', '/pagos/familias/');
  }

  if (link.startsWith('/eventos/index/')) {
    // TODO: Ask backend to return an id instead of a date
    return link.replace(
      /^\/eventos\/index\/\d{2}-\d{2}-\d{2}$/,
      '/comunicacion/calendario',
    );
  }

  if (link.startsWith('/perfil/index')) {
    return link.replace('/perfil/index', '/perfiles');
  }

  if (link.startsWith('/perfiles#')) {
    return link.replace(/#/g, '/');
  }

  // Old routes ---------
  // Communication
  if (link.startsWith('/mensajes')) {
    return link.replace('/mensajes/', '/comunicacion/mensajes/');
  }

  if (link.startsWith('/portal')) {
    return link.replace('/portal', '/comunicacion/publicaciones/');
  }

  if (link.startsWith('/materias')) {
    return link.replace('/materias', '/comunicacion/materias');
  }

  if (link.startsWith('/grupos')) {
    return link.replace('/grupos', '/comunicacion/grupos');
  }

  if (link.startsWith('/autorizaciones')) {
    return link.replace('/autorizaciones', '/comunicacion/autorizaciones');
  }

  if (link.startsWith('/seguimiento')) {
    return link.replace('/seguimiento', '/comunicacion/seguimiento');
  }

  // Integrations
  if (link.startsWith('/tareas')) {
    return link.replace('/tareas', '/integraciones/tareas');
  }

  if (link.startsWith('/tarea')) {
    return link.replace('/tarea', '/integraciones/tareas');
  }

  if (link.startsWith('/task')) {
    return link.replace('/task', '/integraciones/tareas');
  }

  if (link.startsWith('/calificaciones')) {
    return link.replace('/calificaciones', '/integraciones/calificaciones');
  }

  if (link.startsWith('/asistencias')) {
    return link.replace('/asistencias', '/integraciones/asistencias');
  }

  if (link.startsWith('/documentos')) {
    return link.replace('/documentos', '/integraciones/documentos');
  }

  return router.errors[404];
};

export * from './theme';
