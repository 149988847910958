import { paymentIdentificationType } from 'src/shared/services/url/UrlPaymentManagement';
import { useGetList } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const useIdentificationTypes = ({ enabled = true, ...params } = {}) =>
  useGetList(
    [QUERY_KEY.DIAN_IDENTIFICATION_TYPES],
    paymentIdentificationType(),
    { ...params },
    true,
    false,
    { enabled },
  );

export default useIdentificationTypes;
