import { QUERY_KEY } from 'src/features/my-account/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import { getAttendanceUrl } from 'src/shared/services/url/UrlSis';
import { ATTENDANCE_STATUS_LIST } from 'src/features/attendance/components/AttendanceListTable/helpers';

const useStudentAttendance = (params) => {
  const enabled =
    Boolean(params.user_id) && Boolean(params.institution_period_id);

  return useGetOne(
    [QUERY_KEY.STUDENT_ATTENDANCE, params],
    getAttendanceUrl(),
    {
      computable: true,
      expand: 'attendanceScale,attendanceShift',
      page: 1,
      'per-page': 10000,
      status: ATTENDANCE_STATUS_LIST.SENT,
      visible: true,
      ...params,
    },
    false,
    false,
    {
      enabled,
      ...(!enabled && { cacheTime: 0 }),
    },
  );
};

export default useStudentAttendance;
