const PAYMENTS_DIALOGS = {
  MONTHLY_REPORT_DIALOG: 'MonthlyReportDialog',
  CONFIRM_DELETE_ADJUSTMENT_DIALOG: 'ConfirmDeleteAdjustmentDialog',
  PAYMENT_NOTIFICATION_STUDENTS_DIALOG: 'PaymentNotificationStudentsDialog',
  EMPTY_DISCOUNT_DIALOG: 'EmptyDiscountsDialog',
  CONFIRM_DELETE_USER_DIALOG: 'ConfirmDeleteUSerDialog',
  DOWNLOAD_BILLING_REPORT_DIALOG: 'DownloadBillingReportDialog',
  PAYMENT_CONFIG_CONCEPTS_DIALOG: 'PaymentConfigConceptsDialog',
  PAYMENT_CONCEPTS_REMOVE_CONFIRM: 'PaymentConceptsRemoveConfirm',
  PAYMENT_CONCEPTS_CREATE_DIALOG: 'PaymentConceptsCreateDialog',
  PAYMENT_RATES_FILTERS_DIALOG: 'PaymentRatesFiltersDialog',
  PAYMENT_RATES_CREATE_DIALOG: 'PaymentRatesCreateDialog',
  PAYMENT_RATES_REMOVE_CONFIRM: 'PaymentRatesRemoveConfirm',
  PAYMENT_TAX_INFO_CREATE_DIALOG: 'PaymentTaxInfoCreateDialog',
  PAYMENT_CONCEPT_DETAILS_DIALOG: 'PaymentConceptDetailsDialog',
  PAYMENT_ADD_MEMBERS_RATE_DIALOG: 'PaymentAddMembersRateDialog',
  PAYMENT_DISCOUNTS_CREATE_DIALOG: 'PaymentDiscountSCreateDialog',
  PAYMENT_DISCOUNT_REMOVE_CONFIRM: 'PaymentDiscountRemoveConfirm',
  PAYMENT_DISCOUNT_DETAILS_DIALOG: 'PaymentDiscounttDetailsDialog',
  GENERATE_BILL_DIALOG: 'GenerateBillDialog',
  CREATE_INSTITUION_TAX_INFORMATION: 'CreateInstitutionTaxInformation',
  FILTERS_INVOICES_HIGH_TABLE: 'FiltersInvoicesHighTableDialog',
  PAYMENT_ADD_MEMBERS_DISCOUNT_DIALOG: 'PaymentAddMembersDiscountDialog',
};

export default PAYMENTS_DIALOGS;
