import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { familyExternalEntity } from 'src/shared/services/url/UrlPaymentManagement';
import { useCreate } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const useCreateFamilyExternalEntity = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useCreate(
    familyExternalEntity(),
    {
      onSuccess: () => {
        enqueueSnackbar(t`Entidad externa creada con éxito.`, {
          variant: 'success',
        });
      },
      onError: ({ message }) => {
        enqueueSnackbar(
          message ?? t`Hubo un error al intentar crear la entidad externa`,
          {
            variant: 'error',
          },
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries([QUERY_KEY.FAMILY_EXTERNAL_ENTITY]);
      },
    },
    false,
  );
};

export default useCreateFamilyExternalEntity;
