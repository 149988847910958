import { useSetAtom } from 'jotai';

import { disableDialogAtom } from 'src/shared/atoms';

const useDisableDialog = (dialogName) => {
  const disableDialog = useSetAtom(disableDialogAtom);

  return (isDisabled) => {
    disableDialog({
      dialogName,
      isDisabled,
    });
  };
};

export default useDisableDialog;
