import { t } from '@lingui/macro';

export const dlocalPaymentMethod = {
  CASH: 'CASH',
  CARD: 'CARD',
  BANK_TRANSFER: 'BANK_TRANSFER',
};

export const paycodePaymentMethod = {
  CREDIT: 'CREDITO',
};

export const paymentStatus = {
  REJECTED: 'REJECTED',
  PAID: 'PAID',
  PENDING: 'PENDING',
};

export const paymentProvider = {
  DLOCAL: 'dlocal',
  PAYCODE: 'paycode',
};

export const currencies = {
  ARG: 'ARS',
  MXN: 'MXN',
};

export const DLOCAL_TERMS_AND_CONDITIONS_LINK =
  'https://dlocal.com/legal/terms-and-conditions/';

export const DLOCAL_PRIVACY_NOTICE_LINK =
  'https://dlocal.com/legal/privacy-notice/';

export const PAYCODE_TERMS_AND_CONDITIONS_LINK =
  'https://www.paycode.com.mx/terms.html';

export const PAYCODE_PRIVACY_NOTICE_LINK =
  'https://www.paycode.com.mx/privacidad.html';

export const MX_TAXS_OPTIONS = [
  {
    name: t`IVA Exento`,
    value: null,
  },
  {
    name: t`IVA 0%`,
    value: 0,
  },
  {
    name: t`IVA 16%`,
    value: 16,
  },
];
