import NEWSFEED_DIALOGS from './dialogs/newsfeed';
import EVENTS_DIALOGS from './dialogs/events';
import PICKUPS_DIALOGS from './dialogs/pickups';
import PAYMENTS_DIALOGS from './dialogs/payments';
import PROFILE_DIALOGS from './dialogs/profile';
import SETTINGS_DIALOGS from './dialogs/settings';
import BEHAVIOUR_DIALOGS from './dialogs/behaviour';
import GRADING_DIALOGS from './dialogs/grading';
import SCHOOL_MANAGER_DIALOGS from './dialogs/schoolManagerDialogs';
import REPORT_DIALOGS from './dialogs/reports';
import INSTITUTIONS_DIALOGS from './dialogs/institutions';
import ASSISTANT_DIALOGS from './dialogs/assistant';

const dialogs = {
  ...NEWSFEED_DIALOGS,
  ...EVENTS_DIALOGS,
  ...PICKUPS_DIALOGS,
  ...PAYMENTS_DIALOGS,
  ...PROFILE_DIALOGS,
  ...SETTINGS_DIALOGS,
  ...BEHAVIOUR_DIALOGS,
  ...GRADING_DIALOGS,
  ...SCHOOL_MANAGER_DIALOGS,
  ...REPORT_DIALOGS,
  ...INSTITUTIONS_DIALOGS,
  ...ASSISTANT_DIALOGS,
  TOTAL_PAID_DETAILS_DIALOG: 'TotalPaidDetailsDialog',
  TOTAL_TO_REVIEW_DETAILS_DIALOG: 'TotalToReviewDetailsDialog',
  CONFIRM_RESPONSIBLE_DIALOG: 'ConfirmResponsibleDialog',
  CONFIRM_DRAFT_INVOICES_DIALOG: 'ConfirmDraftInvoicesDialog',
  DISCOUNT_MEMBERS_DIALOG: 'DiscountMembersDialog',
  EDIT_DISCOUNT_DIALOG: 'EditDiscountDialog',
  CONCEPT_MEMBERS_DIALOG: 'ConceptMembersDialog',
  EDIT_CONCEPT_DIALOG: 'EditConceptDialog',
  ALL_INVOICES_DIALOG: 'AllInvoicesDialog',
  DELETE_CONCEPT_DIALOG: 'DeleteConceptDialog',
  INVOICE_DETAILS_DIALOG: 'InvoiceDetailsDialog',
  REMOVE_DRAFT_INVOICE_DIALOG: 'RemoveDraftInvoiceDialog',
  DELETE_INVOICE_RESPONSIBLE_DIALOG: 'DeleteInvoiceResponsibleDialog',
  FAMILY_INFO_DIALOG: 'FamlyInfoDialog',
  FAMILY_GROUP_DIALOG: 'FamlyGroupDialog',
  GENERATED_BILLS_FAILS_DIALOG: 'GeneratedBillsFailsDialog',
  NOT_PERMISSION_GENERATE_BILLS_DIALOG: 'NotPermissionGenerateBillsDialog',
  INVOICE_VOUCHER_ATTACHMENT_DIALOG: 'InvoiceVoucherAttachmentDialog',
  INVOICE_DOWN_PAYMENTS: 'InvoiceDownPaymentsDialog',
  DLOCAL_REGISTER_DIALOG: 'DLocalRegisterDialog',
  ALERT_PAYMENT_ORDERS_DIALOG: 'AlertPaymentOrdersDialog',
  SELECT_UNPARENT_STUDENTS_DIALOG: 'SelectUnparentStudentsDialog',
  CONFIRM_NEW_FAMILY_GROUP_DIALOG: 'ConfirmNewFamilyGroupDialog',
  ASSIGN_TO_FAMILY_GROUP_DIALOG: 'AssignToFamilyGroupDialog',
  ADMINS_ADD_PERMISSIONS_DIALOG: 'AdminsAddPermissionsDialog',
  USER_INFO_DIALOG: 'UserInfoViewDialog',
  EDIT_USER_INFO_DIALOG: 'EditUserInfoDialog',
  CONFIRM_DELETE_USER_PHOTO: 'ConfirmDeleteUserPhoto',
  EDIT_USER_PASSWORD_DIALOG: 'EditUserPasswordDialog',
  CUSTOM_FIELD_DIALOG: 'CustomFieldDialog',
  INVOICE_PAYMENT_ADJUSTMENT_DIALOG: 'InvoicePaymentAdjustmentDialog',
  CONFIGURATION_EXTRAORDINARY_PAYMENT_DIALOG:
    'ConfigurationExtraordinaryPayment',
  ADD_CONCEPTS_DIALOG: 'AddConceptsDialog',
  CREATE_PAYMENT_ORDERS_DIALOG: 'CreatePaymentOrdersDialog',
  ADD_PAYMENT_EXTRAORDINARY_DIALOG: 'AddPaymentExtraordinaryDialog',
  CREATE_EXTERNAL_ENTITY_DIALOG: 'CreateExternalEntityDialog',
  FAMILY_EXTERNAL_ENTITY_DIALOG: 'FamilyExternalEntityDialog',
  CONFIRM_DELETE_FAMILY_EXTERNAL_ENTITY: 'ConfirmDeleteFamilyExternalEntity',
  PAYMENT_IMPORT_DIALOG: 'PaymentImportDialog',
  PAYMENT_IMPORT_CONFIRM_DIALOG: 'PaymentImportConfirmDialog',
  PAYMENT_IMPORT_PROGRESS_DIALOG: 'PaymentImportProgressDialog',
  PAYMENT_IMPORT_FINISHED_DIALOG: 'PaymentImportFinishedDialog',
  SPECIAL_PERMISSIONS_DIALOG: 'SpecialPermissionsDialog',
  USER_PERMISSIONS_LEGACY_DIALOG: 'UserPermissionsLegacyDialog',
  USER_PERMISSIONS_UIA_DIALOG: 'UserPermissionsUIADialog',
  PAYMENT_NOTIFICATION_IMPORT_DIALOG: 'PaymentNotificationImportDialog',
  PAYMENT_CAMPAIGN_ADVANCED_CONFIG_DIALOG:
    'PaymentCampaignAdvancedConfigDialog',
  PAYMENT_CAMPAIGN_CONFIG_RESUME_DIALOG: 'PaymentCampaignConfigResumeDialog',
  PAYMENT_NOTIFICATION_IMPORT_FINISHED_DIALOG:
    'PaymentNotificationImportFinishedDialog',
  PAYMENT_DEBT_REMINDER_DIALOG: 'PaymentDebtReminderDialog',
  PAYMENT_FAMILY_ASSIGN_CONCEPT_DIALOG: 'PaymentFamilyAssignConceptDialog',
  INBOX_MESSAGE_CANCEL_DIALOG: 'InboxMessageCancelDialog',
  INBOX_REDIRECT_DIALOG: 'InboxRedirectDialog',
  SEND_PUBLICATIONS_REMINDERS_DIALOG: 'SendPublicationRemindersDialog',
  ACTIVITY_WIZARD_DIALOG: 'ActivityWizardDialog',
  ACTIVITY_DETAIL_DIALOG: 'ActivityDetailDialog',
  ENROLL_REMOVE_CONFIRM_DIALOG: 'EnrollRemoveConfirmDialog',
  ACTIVITY_FORM_EXIT_CONFIRM: 'ActivityFormExitConfirm',
  ACTIVITY_DELETE_CONFIRM: 'ActivityDeleteConfirm',
  DELETE_EXTERNAL_LINK_DIALOG: 'DeleteExternalLinkDialog',
  PAYMENTS_TRANSACTIONS: 'PaymentsTransactionsDialog',
  LINKED_SONS_DIALOG: 'LinkedSonsDialogs',
  ADD_MEMBERS_GRUOP_DIALOG: 'AddMembersGroupDialog',
  ADD_FAMILY_EXTERNAL_ENTITY: 'AddFamilyExternalEntity',
  EDIT_FAMILY_EXTERNAL_ENTITY: 'EditFamilyExternalEntity',
  PAYMENT_CONCEPTS_FAMILY_DIALOG: 'PaymentConceptsFamilyDialog',
  CREATE_USER_DIALOG: 'CreateUserDialog',
  PAYMENT_CONCEPTS_MANAGE_STUDENTS: 'PaymentConceptsManageStudents',
  CONFIG_BLOCK_FAMILY_ACCESS: 'ConfigBlockFamilyAccess',
  ADD_MEMBER_CONVERSATIONS_DIALOG: 'AddMemberConversationsDialog',
  ADD_USERS: 'AddUsers',
  DOWNLOAD_TYPE_REPORT_DIALOG: 'DownloadTypeReportDialog',
  DOWNLOAD_REPORT_PAID_STUDENT: 'DownlandReportPaidByStudentDialog',
};

export default dialogs;
