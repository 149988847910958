import { useCallback, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useHistory, useParams } from 'react-router';

import useUserInfo from 'src/features/payment-management/hooks/useUserInfo';
import {
  inboxActiveConversationAtom,
  inboxSearchQueryAtom,
  unreadFilterAtom,
} from '../../atoms';
import {
  useGetConversations,
  useUpdateConversation,
  useDeleteConversation,
  useCreateMessage,
  useConversationById,
  useMarkMessageAsNotSeen,
} from '../../hooks';
import router from 'src/shared/utils/router';

const useInboxConversations = () => {
  const history = useHistory();
  const { data: userData } = useUserInfo();
  const { conversationId } = useParams();
  const [unreadFilter, setUnreadFilter] = useAtom(unreadFilterAtom);
  const [searchQuery, setSearchQuery] = useAtom(inboxSearchQueryAtom);
  const [activeConversation, setActiveConversation] = useAtom(
    inboxActiveConversationAtom,
  );
  const [lastMessageId, setLastMessageId] = useState(null);

  const {
    data: conversations,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useGetConversations({
    subject: searchQuery,
    users: searchQuery,
    unreadFilter,
  });

  const { data: conversation } = useConversationById(conversationId);

  const { mutateAsync: createMessage } = useCreateMessage();

  const { mutateAsync: updateConversation } = useUpdateConversation(
    activeConversation?.id,
  );

  const { mutateAsync: deleteConversation } = useDeleteConversation(
    activeConversation?.id,
    userData.id,
  );

  useEffect(() => {
    const lastMessage = conversations?.find(
      (conversation) => conversation.id === Number(conversationId),
    )?.last_message?.id;
    if (lastMessage) setLastMessageId(lastMessage);
  }, [conversationId, conversations]);

  const { mutateAsync: markMessageAsNotSeen } = useMarkMessageAsNotSeen(
    lastMessageId,
    activeConversation?.id,
    {
      subject: searchQuery,
      users: searchQuery,
      unreadFilter,
    },
  );

  const handleClickConversation = useCallback(
    (conversation) => {
      if (conversation) {
        setActiveConversation(conversation);
        history.push(router.communication.inbox.conversation(conversation?.id));
      }
    },
    [history, setActiveConversation],
  );

  const handleGoBack = () => {
    history.push(router.communication.inbox.root);
  };

  const handleBlockReply = () => {
    const { no_reply } = activeConversation;
    const body = {
      no_reply: !no_reply,
    };
    updateConversation(body, {
      onSuccess: () => {
        setActiveConversation({
          ...activeConversation,
          no_reply: !no_reply,
        });
      },
    });
  };

  useEffect(() => {
    if (conversationId && conversation) {
      setActiveConversation(conversation);
    }
  }, [
    activeConversation,
    conversation,
    conversationId,
    conversations,
    history,
    setActiveConversation,
  ]);

  return {
    searchQuery,
    setSearchQuery,
    conversations,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    activeConversation,
    setActiveConversation,
    createMessage,
    updateConversation,
    deleteConversation,
    handleClickConversation,
    handleGoBack,
    handleBlockReply,
    unreadFilter,
    setUnreadFilter,
    markMessageAsNotSeen,
  };
};

export default useInboxConversations;
