import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
    '& ul': {
      display: 'block',
      listStyleType: 'disc',
      marginBlockStart: '1em',
      marginBlockEnd: '1em',
      marginInlineStart: 0,
      marginInlineEnd: 0,
      paddingInlineStart: '40px',
    },
  },
  link: {
    textDecoration: 'unset',
    color: 'unset',
    '&:hover': {
      textDecoration: 'unset',
      color: 'unset',
    },
  },
  sanitize: {
    overflowWrap: 'break-word',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'unset',
      '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.primary.dark,
      },
    },
  },
}));

export default useStyles;
