import { useCallback } from 'react';
import Compressor from 'compressorjs';

import { getFileType } from 'src/shared/utils/fileHelper';

const MAX_WIDTH = 1920;
const QUALITY = 0.8;
const CONVERT_SIZE = 2000000;
const COMPRESS_BLACKLIST_EXTENSIONS = ['.gif'];

const getFileInfo = (file = {}) => {
  const fileExtension = `.${file.name?.split('.').pop()}`;
  const fileType = getFileType(fileExtension);
  return {
    isPicture: fileType === 'picture',
    isVideo: fileType === 'video',
    fileExtension,
  };
};

const useCompressImage = ({ quality, maxWidth, convertSize } = {}) => {
  const handleCompressImage = useCallback(
    async (file, onError) => {
      const { isPicture, fileExtension } = getFileInfo(file);

      if (
        !file ||
        !isPicture ||
        COMPRESS_BLACKLIST_EXTENSIONS.includes(fileExtension)
      ) {
        return file;
      }

      try {
        const compressImage = new Promise((resolve, reject) => {
          return new Compressor(file, {
            quality: quality ?? QUALITY,
            maxWidth: maxWidth ?? MAX_WIDTH,
            convertSize: convertSize ?? CONVERT_SIZE,
            success(compressedFile) {
              resolve(compressedFile);
            },
            error({ message }) {
              reject(message);
            },
          });
        });

        return await compressImage;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        if (onError) onError();
      }
    },
    [convertSize, maxWidth, quality],
  );
  return { handleCompressImage };
};

useCompressImage.propTypes = {};

export default useCompressImage;
