import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getDefaultStore, useAtom } from 'jotai';
import { useHistory } from 'react-router';

import {
  useCreateScheduleConversation,
  useGetScheduleConfig,
  useIsStaff,
  useScheduledConversationDetail,
  useUpdateDraft,
  useUpdateScheduled,
} from 'src/features/messages/hooks';
import {
  noInboxActiveConversationAtom,
  scheduledModalOpenAtom,
  userCantSendModalAtom,
} from 'src/features/messages/atoms';
import { DRAFT } from 'src/features/messages/constants';
import router from 'src/shared/utils/router';
import {
  getHourFromStringTime,
  getMinutesFromStringTime,
} from 'src/features/messages/helpers';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';

const useScheduledConfig = () => {
  const defaultStore = getDefaultStore();
  const isStaff = useIsStaff();
  const history = useHistory();
  const queryClient = useQueryClient();

  const updateLists = () => {
    queryClient.refetchQueries({
      queryKey: [QUERY_KEY.SCHEDULED_CONVERSATIONS_LIST],
    });
    queryClient.refetchQueries({
      queryKey: [QUERY_KEY.DRAFT_CONVERSATIONS_LIST],
    });
  };

  const [scheduledModalOpen, setScheduledModalOpen] = useAtom(
    scheduledModalOpenAtom,
    { store: defaultStore },
  );
  const [userCantSendModalOpen, setUserCantSendModalOpen] = useAtom(
    userCantSendModalAtom,
    { store: defaultStore },
  );

  const [activeConversation, setActiveConversation] = useAtom(
    noInboxActiveConversationAtom,
    {
      store: defaultStore,
    },
  );

  const { user: userInfo } = useSelector((user) => user);

  const {
    data: scheduledConfig,
    isLoading,
    isFetching,
  } = useGetScheduleConfig();

  const { draftState, scheduledState, scheduledDetail } =
    useScheduledConversationDetail();

  const { mutateAsync: createScheduleConversation } =
    useCreateScheduleConversation();

  const { mutateAsync: updateDraft } = useUpdateDraft(draftState?.id);

  const { mutateAsync: updateScheduled } = useUpdateScheduled(
    scheduledState?.id || activeConversation?.id,
  );

  const handleCancelSchedule = async () => {
    const body = {
      files: scheduledDetail.files,
      message: scheduledDetail.message,
      no_reply: scheduledDetail.no_reply,
      recipients_id: scheduledDetail.recipients_id,
      scheduled_date: scheduledDetail.scheduled_date,
      subject: scheduledDetail.subject,
      status: DRAFT,
    };
    await updateScheduled(body);
    updateLists();
    history.push(router.communication.draft.root);
  };

  const isParentOrStudent = useMemo(
    () => (userInfo.userIs.student || userInfo.userIs.parent) && !isStaff,
    [isStaff, userInfo],
  );

  const thisUserCanNotCreateMessageRightNow = useMemo(() => {
    const isWeekend =
      scheduledConfig?.restrict_weekend &&
      (dayjs().day() === 0 || dayjs().day() === 6);

    const now = dayjs();
    const minTime = dayjs()
      .utc()
      .hour(getHourFromStringTime(scheduledConfig?.open_time_at))
      .minute(getMinutesFromStringTime(scheduledConfig?.open_time_at))
      .local();
    const maxTime = dayjs()
      .utc()
      .hour(getHourFromStringTime(scheduledConfig?.close_time_at))
      .minute(getMinutesFromStringTime(scheduledConfig?.close_time_at))
      .local();
    const isNotAvailableTime = now.isBefore(minTime) || now.isAfter(maxTime);

    return (
      isParentOrStudent &&
      (isWeekend || (scheduledConfig?.restrict_schedule && isNotAvailableTime))
    );
  }, [isParentOrStudent, scheduledConfig]);

  const restrictWeekends = useCallback(
    (date) => {
      const day = date.day();
      if (isParentOrStudent && scheduledConfig?.restrict_weekend) {
        return day === 0 || day === 6;
      } else {
        return;
      }
    },
    [isParentOrStudent, scheduledConfig?.restrict_weekend],
  );

  const minScheduledTime = useMemo(
    () =>
      scheduledConfig &&
      dayjs()
        .utc()
        .hour(getHourFromStringTime(scheduledConfig?.open_time_at))
        .minute(getMinutesFromStringTime(scheduledConfig?.open_time_at))
        .local(),
    [scheduledConfig],
  );

  const maxScheduledTime = useMemo(
    () =>
      scheduledConfig &&
      dayjs()
        .utc()
        .hour(getHourFromStringTime(scheduledConfig?.close_time_at))
        .minute(getMinutesFromStringTime(scheduledConfig?.close_time_at))
        .local(),
    [scheduledConfig],
  );

  const isNotRestricted = useMemo(
    () => isStaff || !scheduledConfig?.restrict_schedule,
    [isStaff, scheduledConfig?.restrict_schedule],
  );

  const isRestricted = useMemo(
    () =>
      isParentOrStudent &&
      scheduledConfig &&
      scheduledConfig?.restrict_schedule,
    [isParentOrStudent, scheduledConfig],
  );

  const setNoReply = async (body) => {
    try {
      await updateScheduled(body);
      await setActiveConversation(scheduledDetail);
      history.push(
        router.communication.scheduled.conversation(activeConversation.id),
      );
    } catch (error) {}
  };

  return {
    createScheduleConversation,
    handleCancelSchedule,
    restrictWeekends,
    setNoReply,
    setScheduledModalOpen,
    setUserCantSendModalOpen,
    updateDraft,
    updateScheduled,
    scheduledConfig,
    scheduledModalOpen,
    isLoading,
    isFetching,
    isNotRestricted,
    isParentOrStudent,
    isRestricted,
    minScheduledTime,
    maxScheduledTime,
    thisUserCanNotCreateMessageRightNow,
    userCantSendModalOpen,
  };
};

export default useScheduledConfig;
