import { API_COMMUNICATION_BASE, API_USER_BASE } from 'src/config/general';

export const getActiveModulesLegacy = () => '/ajax/base/get_user_modules';

export const getConfigApp = () => `${API_COMMUNICATION_BASE}/api/config/app`;

export const getDivisions = () => `${API_USER_BASE}/api/division`;

export const getDivisionById = (divisionId) =>
  `${API_USER_BASE}/api/division/${divisionId}`;

export const getInstitutionConfig = (id) =>
  `${API_USER_BASE}/api/institution/${id}/view`;

export const getLevels = () => `${API_USER_BASE}/api/level`;

export const getPeriods = (id) => `${API_USER_BASE}/api/level/${id}/period`;

export const getPeriodById = (levelId, periodId) =>
  `${API_USER_BASE}/api/level/${levelId}/period/${periodId}`;
export const getLevelId = (id) => `${API_USER_BASE}/api/level/${id}`;

export const getCourses = (levelId) =>
  `${API_USER_BASE}/api/course?level_id=${levelId}`;

export const updateCourse = (courseId) =>
  `${API_USER_BASE}/api/course/${courseId}`;

export const createCourse = () => `${API_USER_BASE}/api/course`;

export const getStudents = (divisionId) =>
  `${API_USER_BASE}/api/division/${divisionId}/students`;

export const getStudentsBatch = (batchId) =>
  `${API_USER_BASE}/api/auth-assignment/get-batch-transaction/${batchId}`;

export const getStudentsDeleteBatch = (batchId) =>
  `${API_USER_BASE}/api/institution-user/delete-batch-transaction/${batchId}`;

export const createStudentsBatch = () =>
  `${API_USER_BASE}/api/auth-assignment/create-batch-transaction`;

export const changeStudentsBatch = () =>
  `${API_USER_BASE}/api/auth-assignment/change-batch-transaction`;

export const postDeleteStudentsBatch = () =>
  `${API_USER_BASE}/api/auth-assignment/delete-batch-transaction`;

export const postInstitutionUsersBatch = () =>
  `${API_USER_BASE}/api/institution-user/delete-batch`;

export const getSubjects = () => `${API_USER_BASE}/api/subject`;

export const getSubjectById = (subjectId) =>
  `${API_USER_BASE}/api/subject/${subjectId}`;

export const permissionsFieldsConfig = () =>
  `${API_USER_BASE}/api/user-configuration`;

export const permissionsUserFieldsConfig = () =>
  `${API_USER_BASE}/api/user-configuration/user`;

export const setInstitutionUrl = () => '/ajax/institucion/set_institucion';

export const updateInstitutionConfig = (id) =>
  `${API_USER_BASE}/api/institution/${id}`;

export const setFamilyDebtor = (familyId) =>
  `${API_USER_BASE}/api/family/${familyId}/debtor`;

export const getTeachers = () => `${API_USER_BASE}/api/user/institution`;

export const getMembersBySubject = (subjectId) =>
  `${API_USER_BASE}/api/subject/${subjectId}/members`;
