import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  editor: {
    '& .tox-tinymce--toolbar-bottom': {
      borderBottom: '0 !important',
    },

    '& .tox-editor-container': {
      '&:hover': {
        '& .tox-edit-area': {
          borderTop: `1px solid ${theme.palette.grey[800]}`,
          borderLeft: `1px solid ${theme.palette.grey[800]}`,
          borderRight: `1px solid ${theme.palette.grey[800]}`,
        },

        '& .tox-editor-header': {
          borderTop: `1px solid ${theme.palette.grey[800]}`,
        },
      },

      '& .tox-toolbar__primary': {
        borderTop: '0 !important',
      },

      '& .tox-sidebar-wrap': {
        padding: '0 !important',
      },

      '& .tox-edit-area': {
        padding: '0 !important',
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        borderTopRightRadius: theme.spacing(1),
        borderTopLeftRadius: theme.spacing(1),
      },

      '& .tox-editor-header': {
        border: `1px solid ${theme.palette.grey[300]}`,
        boxShadow: 'none',
        marginBottom: '0 !important',
        borderBottomLeftRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
        overflow: 'hidden',
      },
    },
  },

  container: {
    position: 'relative',
  },

  loaderContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    opacity: 0.8,
    zIndex: 2,
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),

    '& .tox-tinymce--toolbar-bottom': {
      height: 100,
    },
  },

  // TODO: Maybe override from theme
  MuiInputLabelFormControl: {
    position: 'relative',
    transform: 'none',
    fontWeight: 500,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
  },

  formControl: {
    width: '100%',
  },
}));

export default useStyles;
