import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { AttachMoney as AttachMoneyIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import { BLENDED_MODULES } from 'src/config/constants';
import { getCollectionsItems } from './utils';
import useRoutes from '../useRoutes';
import usePaymentConfig from 'src/features/payment-management/hooks/data/usePaymentConfig';
import { paymentProvider } from 'src/features/payment-management/constants/payments';
import { COUNTRY_CODE } from 'src/shared/constants/countryCode';

const useCollectionsConfig = () => {
  const user = useSelector(({ user }) => user);
  const { createRoutes } = useRoutes();
  const countryCode = user?.data?.country?.code;
  const userIsWebAndLoggedIn = Boolean(user.apiKey) && !user.isWebView;
  const { data: financialConfig } = usePaymentConfig({
    enabled:
      userIsWebAndLoggedIn && (user.userIs.admin || user.userIs.financialAdmin),
  });
  const enabledCashFlow =
    financialConfig?.provider === paymentProvider.PAYCODE &&
    countryCode.toLowerCase() === COUNTRY_CODE.MEXICO;

  const enabledCollection =
    financialConfig?.provider === paymentProvider.DLOCAL;

  const items = getCollectionsItems(user, enabledCashFlow, enabledCollection);
  const filterEnabledItems = items.filter((item) => item.enabled);

  const config = useMemo(
    () => ({
      id: BLENDED_MODULES.PAYMENT_MANAGEMENT.id,
      name: BLENDED_MODULES.PAYMENT_MANAGEMENT.name,
      subheader: <Trans>Financial Hub</Trans>,
      icon: <AttachMoneyIcon size={18} />,
      items: createRoutes(filterEnabledItems),
      prefix: 'pagos',
    }),
    [createRoutes, filterEnabledItems],
  );

  return config;
};

export default useCollectionsConfig;
