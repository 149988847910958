import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { useCreate } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';
import { getConversationMessages } from 'src/shared/services/url/UrlCommunication';

const useReplyMessage = (conversationId, ...params) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useCreate(
    getConversationMessages(conversationId),
    {
      onSuccess: () => {
        enqueueSnackbar(t`Su respuesta ha sido enviada.`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t`Hubo un error al enviar su respuesta.`, {
          variant: 'error',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          QUERY_KEY.MESSAGES_LIST,
          { conversationId, ...params },
        ]);
        queryClient.invalidateQueries([QUERY_KEY.CONVERSATIONS_LIST]);
        queryClient.invalidateQueries([QUERY_KEY.UNREAD_CONVERSATIONS_LIST]);
      },
    },
    false,
  );
};

export default useReplyMessage;
