import { useMutation } from '@tanstack/react-query';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { invoiceImportTemplate } from 'src/shared/services/url/UrlPaymentManagement';
import { Api as api } from 'src/shared/services/api';
import { downloadFileBlob } from 'src/shared/utils/fileHelper';

const useDownloadIInvoiceImportTemplate = () => {
  const { enqueueSnackbar } = useSnackbar();

  const mutationFn = async (params = {}) =>
    api.get({
      url: invoiceImportTemplate(),
      headers: {
        Accept: 'text/csv',
      },
      data: params,
    });

  const onSuccess = (data) => {
    downloadFileBlob(data, { type: 'text/csv' }, t`Plantilla|fidu.csv`);
  };

  const onError = (error) => {
    const { message } = error;
    enqueueSnackbar(message ?? t`Ha ocurrido un problema.`, {
      variant: 'error',
    });
  };

  return useMutation({
    mutationFn,
    onSuccess,
    onError,
  });
};

export default useDownloadIInvoiceImportTemplate;
