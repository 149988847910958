import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { IconButton, Tooltip } from '@mui/material';
import { Eye as PreviewIcon } from 'react-feather';

const PreviewFile = memo(({ onShowPreview, fileId }) => {
  return (
    <Tooltip title={<Trans>Previsualizar</Trans>}>
      <IconButton
        aria-label={<Trans>Previsualizar archivo</Trans>}
        component="span"
        onClick={() => onShowPreview(fileId)}
        size="large"
      >
        <PreviewIcon size={16} />
      </IconButton>
    </Tooltip>
  );
});

PreviewFile.displayName = 'PreviewFile';

PreviewFile.propTypes = {
  onShowPreview: PropTypes.func,
  fileId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PreviewFile;
