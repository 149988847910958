import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import { t } from '@lingui/macro';
import { formatSubjectName } from 'src/shared/helpers';

import InfoTitle from '../InfoTitle';

const DIVISIONS_LIMIT = 6;
const TRUNCATE_AT = 3;

const SubjectInfo = ({ roles = [], textProps, loading }) => {
  const subjectsName = formatSubjectName(roles);
  const subjectsArray = subjectsName.split(',');
  const truncatedSubjects = subjectsArray.splice(0, TRUNCATE_AT).join(', ');
  const [shouldTruncate, setShowingDivisions] = useState(
    subjectsArray.length > DIVISIONS_LIMIT,
  );

  const handleToggleShowMore = () => {
    setShowingDivisions(false);
  };

  if (!loading && !subjectsName.length) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <InfoTitle title={t`materias`}>
        <Box display={'flex'} flexDirection={'row'} mt={0}>
          {loading ? (
            <Skeleton width={80} />
          ) : (
            <>
              {shouldTruncate ? (
                <>
                  {truncatedSubjects} y{' '}
                  <Button
                    variant="text"
                    sx={{ p: 0, ml: 1, minWidth: 'unset' }}
                    onClick={handleToggleShowMore}
                  >
                    {subjectsArray.length} más
                  </Button>
                </>
              ) : (
                <Typography
                  color={'text.secondary'}
                  variant="overline"
                  fontSize={'12px'}
                  {...textProps}
                >
                  {subjectsName}
                </Typography>
              )}
            </>
          )}
        </Box>
      </InfoTitle>
    </Grid>
  );
};

SubjectInfo.propTypes = {
  textProps: PropTypes.object,
  roles: PropTypes.array,
  loading: PropTypes.array,
};

export default SubjectInfo;
