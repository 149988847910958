import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { Avatar, IconButton } from '@mui/material';
import useStyles from './styles';

const PreviewButton = memo(
  forwardRef(({ color, ...rest }, ref) => {
    const classes = useStyles();
    const avatarStyles = {
      bgcolor: color,
      width: 18,
      height: 18,
      margin: '0 auto',
    };
    return (
      <IconButton className={classes.button} {...rest} ref={ref}>
        <Avatar variant="rounded" sx={avatarStyles}>
          {' '}
        </Avatar>
      </IconButton>
    );
  }),
);

PreviewButton.displayName = 'PreviewButton';

PreviewButton.propTypes = {
  color: PropTypes.string,
};

export default PreviewButton;
