import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';

import { getUserInformation } from 'src/shared/services/url/UrlUser';
import { useGetPaginatedList } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const useMembersSearch = ({ enabled = true, page, perPage, ...rest }) => {
  const [search, setSearch] = useState('');
  const [params, setParams] = useState({ divisions: [], roles: [] });

  const [debouncedSearch] = useDebounce(search, 500);

  const selectedInstitutionPeriodId = useSelector(
    ({ user }) => user.selectedInstitutionPeriodId,
  );

  const query = useGetPaginatedList(
    [QUERY_KEY.MEMBERS_SEARCH, debouncedSearch, params, rest],
    `${getUserInformation()}?expand=authAssignments`,
    {
      institutionPeriods: [selectedInstitutionPeriodId],
      search_term: debouncedSearch,
      ...params,
      ...rest,
    },
    true,
    {
      enabled,
    },
    null,
    page,
    perPage,
    true,
  );

  const handleSearch = useCallback((searchTerm) => {
    setSearch(searchTerm);
  }, []);

  const handleChangeParam = useCallback((newParams) => {
    setParams((old) => ({ ...old, ...newParams }));
  }, []);

  return {
    ...query,
    members: query.data ?? [],
    search,
    handleSearch,
    handleChangeParam,
  };
};

export default useMembersSearch;
