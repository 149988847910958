import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { getLocaleByCountry } from 'src/shared/utils/manageLanguages';

const locale = getLocaleByCountry();
dayjs.locale(locale);
dayjs.extend(localeData);

const MONTHS = dayjs.months().map((month, idx) => ({
  id: idx,
  name: `${month[0].toUpperCase()}${month.slice(1)}`,
}));

const handleInvoiceAvailableMonths = (
  nextToInvoiceMonth,
  monthsBefore = 3,
  monthsAfter = 9,
) => {
  const initialMonth = dayjs(nextToInvoiceMonth, 'YYYY-MM');
  const months = [];

  // INSERTAR LOS MESES ANTERIORES AL QUE SE PASA
  for (let i = 1; i <= monthsBefore; i++) {
    const month = initialMonth.subtract(i, 'month');
    const monthName = month.format('MMMM');
    const monthNameParsed = `${monthName[0].toUpperCase()}${monthName.slice(
      1,
    )}`;

    months.unshift({
      id: month.month(),
      date: month.format('YYYY-MM-DD'),
      name: monthNameParsed,
    });
  }

  // INSERTAR LOS MESES DESPUES AL QUE SE PASA
  for (let i = 0; i < monthsAfter; i++) {
    const month = initialMonth.add(i, 'month');
    const monthName = month.format('MMMM');
    const monthNameParsed = `${monthName[0].toUpperCase()}${monthName.slice(
      1,
    )}`;

    months.push({
      id: month.month(),
      date: month.format('YYYY-MM-DD'),
      name: monthNameParsed,
    });
  }

  return months.sort((a, b) => dayjs(a.date) - dayjs(b.date));
};

export { MONTHS, handleInvoiceAvailableMonths };
