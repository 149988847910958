import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { useCreate } from 'src/shared/services/api/query';
import { paymentManagementConcepts } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const SUCCESS_MESSAGE = t`Categoría creada con éxito.`;
const ERROR_MESSAGE = t`Hubo un error al intentar crear la categoría del concepto.`;

const useCreateConcept = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useCreate(
    paymentManagementConcepts(),
    {
      onSuccess: () => enqueueSnackbar(SUCCESS_MESSAGE, { variant: 'success' }),
      onError: ({ message }) =>
        enqueueSnackbar(message ?? ERROR_MESSAGE, { variant: 'error' }),
      onSettled: () =>
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.CONCEPTS],
          refetchType: 'all',
        }),
    },
    false,
  );
};

export default useCreateConcept;
