import makeStyles from '@mui/styles/makeStyles';
import typography from '../../../theme/typography';

const useStyles = makeStyles((theme) => ({
  fileRow: {
    width: 400,
    position: 'relative',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    display: 'flex',
    minHeight: 69,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginRight: 0,
      maxHeight: 70,
    },
  },
  fileRowDense: {
    minHeight: theme.spacing(4),
    height: 42,
    padding: theme.spacing(0.5),
    '& $fileRowContainer': {
      width: theme.spacing(4),
      minWidth: theme.spacing(4),
      height: theme.spacing(4),
      marginRight: 0,
    },
    '& $fileIcon': {
      top: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
  },
  fileRowContainer: {
    width: 120,
    maxWidth: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(1),
    backgroundColor: '#ccc',
    borderRadius: 4,
    overflow: 'hidden',
    position: 'relative',
    float: 'left',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: 80,
      maxHeight: 80,
      flexDirection: 'row',
    },
  },
  imagePreview: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    opacity: 1,
    transition: '.3s',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  fileIcon: {
    color: '#fff',
    fontSize: theme.spacing(3),
    display: 'block',
    marginLeft: 12,
    top: 12,
    position: 'relative',
  },
  word: {
    backgroundColor: '#0090FF',
  },
  'google-document': {
    backgroundColor: '#0090FF',
  },
  excel: {
    backgroundColor: '#30B866',
  },
  csv: {
    backgroundColor: '#30B866',
  },
  'google-spreadsheet': {
    backgroundColor: '#30B866',
  },
  powerpoint: {
    backgroundColor: '#FF543E',
  },
  'google-presentation': {
    backgroundColor: '#FF543E',
  },
  adobe: {
    backgroundColor: '#FF543E',
  },
  text: {
    backgroundColor: '#FFD300',
  },
  compress: {
    backgroundColor: '#e44841',
  },
  'google-file': {
    backgroundColor: '#e44841',
  },
  picture: {
    backgroundColor: '#42C030',
  },
  video: {
    backgroundColor: '#44486B',
  },
  'google-video': {
    backgroundColor: '#44486B',
  },
  audio: {
    backgroundColor: '#63D1F4',
  },
  'google-audio': {
    backgroundColor: '#63D1F4',
  },
  external: {
    backgroundColor: '#006',
  },
  fileLoader: {
    ...typography.body1,
    textAlign: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0, 0.4)',
    borderRadius: theme.shape.borderRadius,
    fontWeight: 500,
    color: '#fff',
    zIndex: 1,
  },
  fileInfoContainer: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  fileName: {
    ...typography.body1,
    color: theme.palette.text.primary,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '160px',
    },
  },
  fileSize: {
    ...typography.body2,
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
