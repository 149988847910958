import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, InputLabel } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';

import {
  decimalSeparator,
  thousandSeparator,
} from 'src/shared/utils/currencies';
import BaseCurrencyInput from './BaseCurrencyInput';
import useStyles from './styles';

const CurrencyField = ({
  gridProps = { xs: 12 },
  name,
  disabled,
  label,
  helperText,
  InputProps,
  symbol = '$',
  min = 0,
  max,
  step = 'any',
  adornmentPosition = 'start',
  decimals,
  withFloatingLabel = false,
  ...rest
}) => {
  const classes = useStyles();
  const [field, , helpers] = useField(name);
  const { isSubmitting } = useFormikContext();

  return (
    <Grid item {...gridProps}>
      <FormControl className={classes.formControl}>
        {!withFloatingLabel && label && (
          <InputLabel
            classes={{ formControl: classes.MuiInputLabelFormControl }}
          >
            {label}
          </InputLabel>
        )}

        <NumberFormat
          min={min}
          max={max}
          name={name}
          step={step}
          symbol={symbol}
          value={field.value}
          customInput={BaseCurrencyInput}
          InputProps={InputProps}
          disabled={isSubmitting || disabled}
          thousandSeparator={thousandSeparator()}
          decimalSeparator={decimalSeparator()}
          helperText={helperText}
          adornmentPosition={adornmentPosition}
          isNumericString
          onValueChange={(val) => helpers.setValue(val.floatValue)}
          onBlur={field.onBlur}
          type="text"
          {...(withFloatingLabel && { label })}
          {...rest}
        />
      </FormControl>
    </Grid>
  );
};

CurrencyField.propTypes = {
  gridProps: PropTypes.object,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  symbol: PropTypes.string,
  InputProps: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.string,
  decimals: PropTypes.bool,
  decimalLength: PropTypes.number,
  adornmentPosition: PropTypes.oneOf(['start', 'end']),
  withFloatingLabel: PropTypes.bool,
};

export default CurrencyField;
