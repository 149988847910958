import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useUserLevels = () => {
  const { selectedInstitution, selectedInstitutionPeriodId } = useSelector(
    (state) => state.user,
  );

  const userLevels = useMemo(() => {
    const selectedPeriod = selectedInstitution.periods.find(
      (period) => period.id === selectedInstitutionPeriodId,
    );
    return selectedPeriod?.levels || null;
  }, [selectedInstitution, selectedInstitutionPeriodId]);

  return { userLevels };
};

export default useUserLevels;
