import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Chip,
  Grid,
  FormControl,
  InputLabel,
  ListItemButton,
} from '@mui/material';
import { useFormikContext, useField } from 'formik';
import { X as CloseIcon } from 'react-feather';
import { t } from '@lingui/macro';
import update from 'immutability-helper';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { AutocompleteField } from 'src/components/Form';

import useStyles from './styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectField = ({
  gridProps = { xs: 12 },
  name,
  disabled,
  label,
  loading = false,
  onChange,
  onInputChange,
  onBlur,
  addOnBlur = true,
  multiple,
  withSelectAll,
  renderOption,
  options: rawOptions,
  renderTags,
  sx,
  disableOnLoading = true,
  hideCheckbox,
  ...rest
}) => {
  const { setFieldValue, isValid, isSubmitting } = useFormikContext();
  const [field, meta, helpers] = useField(name);
  const classes = useStyles();
  let options = rawOptions;

  if (withSelectAll && multiple) {
    options = update(options, {
      $unshift: [{ id: 'all', name: t`Todos` }],
    });
  }

  const handleBlur = (event) => {
    helpers.setTouched(true);
    if (event.target.value && multiple && addOnBlur && isValid) {
      setFieldValue(field.name, [...field.value, event.target.value]);
    }

    if (onBlur) onBlur(event);
  };

  const handleChanges = (event, newValue, reason, changeFn, details) => {
    const allOptions = newValue?.length === options?.length ? [] : rawOptions;

    const value =
      Array.isArray(newValue) && newValue.find((val) => val.id === 'all')
        ? allOptions
        : newValue;

    if (typeof changeFn === 'function') {
      return changeFn(event, value, reason, details);
    }

    return setFieldValue(field.name, value);
  };

  return (
    <Grid item {...gridProps} sx={sx}>
      <FormControl className={classes.formControl}>
        {label && (
          <InputLabel
            classes={{ formControl: classes.MuiInputLabelFormControl }}
          >
            {label}
          </InputLabel>
        )}

        <AutocompleteField
          fullWidth
          data-testid={`select-${name}`}
          name={name}
          disabled={isSubmitting || (loading && disableOnLoading) || disabled}
          multiple={multiple}
          loading={loading}
          value={field.value}
          error={Boolean(meta.touched && meta.error)}
          helperText={meta.touched && meta.error ? meta.error : undefined}
          clearOnBlur={multiple}
          onBlur={handleBlur}
          onChange={(event, newValue, reason, details) => {
            handleChanges(event, newValue, reason, onChange, details);
          }}
          renderOption={(props, option, state) => {
            const isChecked =
              withSelectAll && multiple && option?.id === 'all'
                ? rawOptions.length > 0 &&
                  field.value.length === rawOptions.length
                : state.selected;

            return (
              <ListItemButton
                {...props}
                data-testid={`select-option-${option?.id}`}
                key={option?.id ?? option?.name}
              >
                {multiple && !hideCheckbox && (
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={isChecked}
                  />
                )}
                {renderOption ? renderOption(option, state) : option.name}
              </ListItemButton>
            );
          }}
          options={options ?? []}
          renderTags={(tagValue, getTagProps) => {
            if (tagValue.length === rawOptions.length && withSelectAll) {
              return (
                <Chip
                  size="small"
                  variant="outlined"
                  label={t`Todos`}
                  deleteIcon={<CloseIcon />}
                  {...getTagProps({ index: 0 })}
                />
              );
            }

            return renderTags(tagValue, getTagProps);
          }}
          {...(onInputChange && {
            onInputChange: (event, newValue, reason) => {
              handleChanges(event, newValue, reason, onInputChange);
            },
          })}
          {...rest}
        />
      </FormControl>
    </Grid>
  );
};

SelectField.propTypes = {
  onChange: PropTypes.func,
  gridProps: PropTypes.object,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  withSelectAll: PropTypes.bool,
  renderOption: PropTypes.func,
  options: PropTypes.array.isRequired,
  renderTags: PropTypes.func,
  sx: PropTypes.object,
  disableOnLoading: PropTypes.bool,
  onInputChange: PropTypes.func,
  onBlur: PropTypes.func,
  addOnBlur: PropTypes.bool,
  hideCheckbox: PropTypes.bool,
};

export default SelectField;
