import { useEffect, useRef } from 'react';

const useInterval = (callback, delay = 5000, disabled = false) => {
  const storeCallback = useRef();

  useEffect(() => {
    storeCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (disabled) return () => {};

    const intervalId = setInterval(() => {
      storeCallback.current();
    }, delay);

    return () => {
      clearInterval(intervalId);
    };
  }, [callback, delay, disabled]);
};

export default useInterval;
