import { makeStyles } from '@mui/styles';

const paymentStatusColors = {
  canceled: 'error',
  pending: 'warning',
  completed: 'success',
  rejected: 'error',
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& th': {
      borderBottom: '1px solid transparent',
      transition: `border ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeIn}`,
    },
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  elevatedHead: {
    '& th': {
      borderColor: theme.palette.divider,
    },
  },
  paginationSpacer: {
    display: 'none',
  },
}));

export { paymentStatusColors, useStyles };
