import { taxIdentificationUrl } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { useGetPaginatedList } from 'src/shared/services/api/query';

const useTaxIdentification = ({ queryParams } = {}) => {
  return useGetPaginatedList(
    [QUERY_KEY.TAX_IDENTIFICATION_OPTIONS],
    taxIdentificationUrl(),
    {},
    true,
    { ...queryParams },
  );
};

export default useTaxIdentification;
