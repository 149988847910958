import dayjs from 'dayjs';

import abstractSerializer from 'src/shared/services/api/query/serializer/abstractSerializer';
import { toDateFormat } from 'src/shared/utils/dates';

const deserializePickUpConfig = (data = {}) => ({
  id: data.id,
  hour_limit: dayjs(data.hour_limit, 'HH:mm:ss'),
  view_roles: data.view_roles ?? [],
  confirm_roles: data.confirm_roles ?? [],
  habitual_close_at: dayjs(data.habitual_close_at),
  habitual_open_at: dayjs(data.habitual_open_at),
  recurrent_close_at: dayjs(data.recurrent_close_at),
  recurrent_open_at: dayjs(data.recurrent_open_at),
  recurrent_max_people: data.recurrent_max_people ?? '',
  level_id: data.level_id,
  confirm_age: data.confirm_age,
});

const serializePickUpConfig = (data = {}) => ({
  id: data.id,
  hour_limit: toDateFormat(data.hour_limit, 'HH:mm:ss'),
  view_roles: data.view_roles ?? [],
  confirm_roles: data.confirm_roles ?? [],
  habitual_close_at: toDateFormat(data.habitual_close_at, 'YYYY-MM-DD'),
  habitual_open_at: toDateFormat(data.habitual_open_at, 'YYYY-MM-DD'),
  recurrent_close_at: toDateFormat(data.recurrent_close_at, 'YYYY-MM-DD'),
  recurrent_open_at: toDateFormat(data.recurrent_open_at, 'YYYY-MM-DD'),
  recurrent_max_people: data.recurrent_max_people,
  level_id: data.level_id,
  confirm_age: data.confirm_age,
});

const deserialize = (data) => {
  if (Array.isArray(data)) {
    return data.map((config) => deserializePickUpConfig(config));
  }
  return deserializePickUpConfig(data);
};

const serialize = (data) => {
  if (Array.isArray(data)) {
    return data.map((config) => serializePickUpConfig(config));
  }

  return serializePickUpConfig(data);
};

const pickupConfigSerializer = abstractSerializer(serialize, deserialize);

export default pickupConfigSerializer;
