import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  floatingLabel: {
    '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
      top: '-7px',
    },
  },
});

export default useStyles;
