// initial state
import { DEFAULT_REPORT_CARD_STATE } from './helpers';

const state = {
  scores: null,
  scoresQueue: [],
  students: [],
  studentAlert: { isDirty: false, isOpen: false, closeModal: false },
  assessments: [],
  pendingApproval: [],
  assessmentsFetched: false,
  finalScoresFetched: false,
  assessmentsQueue: [],
  activeAssessment: {},
  assessmentTypes: [],
  assessmentScales: [],
  levelPeriods: [],
  config: {},
  activeSubjectId: null,
  lastSent: '2021-06-08 14:52:32',
  activeFilters: {
    subject: null,
    levelPeriods: [],
    student: null,
    assessmentType: null,
  },
  activeAssessmentTypeByLevelPeriod: [],
  averageCalculator: {
    isOpen: false,
    levelPeriodId: null,
    studentsIds: null,
  },
  finalScores: [],
  finalScoresQueue: [],
  reportCard: DEFAULT_REPORT_CARD_STATE,
  assessmentTypeFinalScores: [],
  assessmentTypesColumns: [],
};

export default state;
