/**
 * Merges multiple router objects into a single object.
 *
 * @template T
 * @param {T[]} routers - The routers to merge.
 * @returns {Record<T['key'], T>} A merged router object.
 */
const mergeRouters = (routers) => {
  return routers.reduce((combined, router) => {
    combined[router.key] = router;

    return combined;
  }, {});
};

export default mergeRouters;
