import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { Box } from '@mui/material';

const ChangelogContent = memo(({ steps, currentStep }) => {
  return (
    <Box
      sx={{
        pt: 5,
        position: 'relative',
        flex: 1,
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <SwipeableViews axis="x" index={currentStep}>
        {steps.map((step, idx) => {
          const Step = step;

          return <Step key={idx} />;
        })}
      </SwipeableViews>
    </Box>
  );
});

ChangelogContent.displayName = 'ChangelogContent';

ChangelogContent.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.number,
  onFinish: PropTypes.func,
};

export default ChangelogContent;
