import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  input: {
    '& .MuiButtonBase-root': {
      padding: theme.spacing(1),
    },

    '& .MuiInputAdornment-positionEnd': {
      paddingLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      borderLeft: `1px solid ${theme.palette.divider}`,
      height: '100%',
    },

    '& svg': {
      width: 18,
      height: 18,
      color: theme.palette.primary.main,
    },
  },

  MuiInputLabelFormControl: {
    position: 'relative',
    transform: 'none',
    fontWeight: 500,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
  },
  floatingLabel: {
    '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
      top: '-7px',
    },
  },
}));

export default useStyles;
