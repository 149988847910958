/**
 * @author Andrelo
 * It implements "SerializerInterface" interface,
 * it must be used overriding the "serialize" and "deserialize" functions.
 * const customSerializer = abstractSerializer(customSerializeFunc, customDeserializeFunc);
 */

import SerializerInterface from './SerializerInterface';

// deserialize
const deserializeItem = (item) => item;

const deserialize = (serializedData) => {
  const data = serializedData?.data ?? serializedData;
  if (Array.isArray(data)) return data.map((item) => deserializeItem(item));

  return deserializeItem(data);
};

// serialize
const serializeItem = (item) => item;

const serialize = (data) => {
  if (Array.isArray(data)) return data.map((item) => serializeItem(item));

  return serializeItem(data);
};

// implementation
const abstractSerializer = (serializer, deserializer) => {
  return SerializerInterface(
    serializer ?? serialize,
    deserializer ?? deserialize,
  );
};

export default abstractSerializer;
