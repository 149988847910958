import React from 'react';
import { Box, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';

import rebrand from 'src/assets/images/changelog/02-23/re-brand.gif';
import { useBreakpoints } from 'src/shared/hooks';

const BrandingStep = () => {
  const { isMobile } = useBreakpoints();

  return (
    <Box
      sx={{
        mt: 5,
        display: 'flex',
        gap: 2,
        px: isMobile ? 2 : 10,
        ...(!isMobile && { pr: 20 }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxWidth: 330,
          ...(isMobile && {
            textAlign: 'center',
          }),
        }}
      >
        {isMobile && (
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <Box
              component="img"
              height={160}
              width={150}
              src={rebrand}
              alt="Logo rebrand gif"
            />
          </Box>
        )}

        <Typography
          fontSize={isMobile ? 13 : 17}
          sx={{ color: '#FF6D3B' }}
          variant="h4"
          gutterBottom
        >
          <Trans>¡NOVEDAD!</Trans>
        </Typography>

        <Typography
          fontSize={isMobile ? 32 : 47}
          color="#0071F1"
          fontFamily="Sora"
          sx={{ lineHeight: (theme) => theme.spacing(6) }}
        >
          <Trans>
            ¡Ahora <br /> somos fidu!
          </Trans>
        </Typography>

        <Typography
          fontSize={isMobile ? 13 : 16}
          variant="h3"
          fontWeight="bold"
        >
          <Trans>
            Encontrarás toda tu información y herramientas disponibles <br />{' '}
            como siempre.
          </Trans>
          <span role="img" aria-label="Smiling sunglasses emoji">
            😎
          </span>
        </Typography>
      </Box>

      {!isMobile && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            flex: 1,
          }}
        >
          <Box
            component="img"
            height={210}
            width={200}
            src={rebrand}
            alt="Logo rebrand gif"
          />
        </Box>
      )}
    </Box>
  );
};

export default BrandingStep;
