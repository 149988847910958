import { useSelector } from 'react-redux';

const useIsFinancialManagement = () => {
  const userIs = useSelector(({ user }) => user.userIs);

  return (
    userIs.admin ||
    userIs.financialAdmin ||
    userIs.financialExecutive ||
    userIs.parent
  );
};

export default useIsFinancialManagement;
