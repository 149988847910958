import { Trans } from '@lingui/macro';
import router from 'src/shared/utils/router';

const usePeriodSelector = () => {
  return {
    id: 'school-year',
    title: <Trans>Ciclos lectivos</Trans>,
    href: router.institution.institutionPeriod,
    enabled: true,
    divider: true,
  };
};

export default usePeriodSelector;
