import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  rootInput: {
    padding: theme.spacing(0.5),
  },
  label: {
    marginLeft: theme.spacing(),
  },
  MuiInputLabelFormControl: {
    position: 'relative',
    transform: 'none',
    fontWeight: 500,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
  },
  MuiInput: {
    paddingLeft: 0,
  },
  selectedInputActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '& svg': {
      color: theme.palette.common.white,
    },
  },
  menuCount: {
    ...theme.typography.body2,
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    borderRadius: theme.shape.borderRadius,
    fontWeight: 500,
  },
}));

export default useStyles;
