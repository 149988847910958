import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton, Menu, ClickAwayListener } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import { t } from '@lingui/macro';
import useStyles from './styles';

const GenericMoreButton = ({
  children,
  tooltip = t`Más opciones`,
  keepMounted,
  close,
  transformOriginMenu,
  onOpen,
  onClose,
  ...rest
}) => {
  const classes = useStyles();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = (e) => {
    e.stopPropagation();
    setOpenMenu(true);
    if (onOpen) onOpen();
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
    if (onClose) onClose();
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (close === true) {
      setOpenMenu(false);
    }
  }, [close]);

  return (
    <>
      <ClickAwayListener onClickAway={handleMenuClose}>
        <Tooltip title={tooltip}>
          <IconButton
            onClick={(e) => handleMenuOpen(e)}
            ref={moreRef}
            size="large"
            {...rest}
          >
            <MoreIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
      <Menu
        keepMounted={keepMounted}
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          onClick: () => {
            setOpenMenu(false);
          },
        }}
        onClick={stopPropagation}
        onClose={handleMenuClose}
        open={openMenu}
        classes={{ paper: classes.menu }}
        transformOrigin={
          transformOriginMenu ?? {
            vertical: 'top',
            horizontal: 'left',
          }
        }
      >
        {children}
      </Menu>
    </>
  );
};

GenericMoreButton.displayName = 'GenericMoreButton';

GenericMoreButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  keepMounted: PropTypes.bool,
  tooltip: PropTypes.any,
  close: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  transformOriginMenu: PropTypes.object,
};

export default GenericMoreButton;
