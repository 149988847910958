import { t } from '@lingui/macro';
import { CUSTOM_FIELD_TYPE } from 'src/shared/utils/customFieldType';
import { toDateFormat } from 'src/shared/utils/dates';

const formatValueCustomField = (field) => {
  const formatDate = 'DD-MM-YYYY';
  const formatDateTime = 'DD-MM-YYYY HH:mm';
  const { type, value } = field;
  const isDate = type === CUSTOM_FIELD_TYPE.DATE;

  switch (type) {
    case CUSTOM_FIELD_TYPE.STRING:
    case CUSTOM_FIELD_TYPE.INTEGER:
      return value;

    case CUSTOM_FIELD_TYPE.DATE:
    case CUSTOM_FIELD_TYPE.DATE_TIME:
      if (value) {
        const format = isDate ? formatDate : formatDateTime;
        return toDateFormat(value, format);
      }
      break;

    case CUSTOM_FIELD_TYPE.BOOLEAN:
      if (value === false) {
        return t`No`;
      }
      return value ? t`Si` : undefined;

    case CUSTOM_FIELD_TYPE.ATTACHMENT:
      return value?.original_file_name;

    default:
      return undefined;
  }
};

export default formatValueCustomField;
