import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    cursor: 'pointer',
  },
  postContent: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '24px',
    fontFamily: 'Manrope',
  },
}));

export default useStyles;
