import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  ListItemButton,
} from '@mui/material';
import { t } from '@lingui/macro';
import GenericMoreButton from 'src/components/GenericMoreButton';
import { useAttendanceContext } from 'src/features/attendance/contexts/AttendanceContext';
import useIsMountedRef from 'src/shared/hooks/useIsMountedRef';

const AttendanceTypeMenu = memo(
  ({ shiftId, studentId, justified, computable, onChange, ...rest }) => {
    const isFirstFetch = useIsMountedRef();
    const { activeAttendances, setActiveAttendances } = useAttendanceContext();
    const [isJustified, setIsJustified] = useState(justified ?? false);
    const [isComputable, setIsComputable] = useState(computable ?? true);

    const handleJustified = () => {
      setIsJustified((oldValueJustified) => {
        return !oldValueJustified;
      });
    };

    const handleNoComputable = () => {
      setIsComputable((oldValueComputable) => {
        return !oldValueComputable;
      });
    };

    useEffect(() => {
      setActiveAttendances((oldValue) => {
        const clonedValues = [...oldValue];
        const foundIndex = oldValue.findIndex((el) => {
          const elementKey = el.subject_id
            ? 'subject_id'
            : 'attendance_shift_id';
          return el.user_id === studentId && el[elementKey] === shiftId;
        });

        clonedValues[foundIndex] = {
          ...clonedValues[foundIndex],
          justified: isJustified ?? false,
          computable: isComputable ?? true,
        };
        return clonedValues;
      });
    }, [isComputable, isJustified, shiftId, studentId, setActiveAttendances]);

    useEffect(() => {
      if (activeAttendances?.length && isFirstFetch.current) {
        isFirstFetch.current = false;
        const foundAttendance = activeAttendances.find((attendance) => {
          const elementKey = attendance.subject_id
            ? 'subject_id'
            : 'attendance_shift_id';
          return (
            attendance.user_id === studentId &&
            attendance[elementKey] === shiftId
          );
        });
        if (foundAttendance) {
          setIsJustified(foundAttendance.justified);
          setIsComputable(foundAttendance.computable);
        }
      }
    }, [activeAttendances, isFirstFetch, shiftId, studentId]);

    useEffect(() => {
      if (justified != null || computable != null) {
        setIsJustified(justified);
        setIsComputable(computable);
      }
    }, [computable, justified]);

    useEffect(() => {
      onChange((oldValue) => ({
        ...oldValue,
        justified: isJustified,
        computable: isComputable,
      }));
    }, [isComputable, isJustified, onChange]);

    return (
      <GenericMoreButton keepMounted {...rest}>
        <FormGroup>
          <ListItemButton>
            <FormControlLabel
              label={t`Justificada`}
              control={
                <Checkbox
                  value={isJustified}
                  checked={isJustified}
                  onChange={handleJustified}
                />
              }
            />
          </ListItemButton>
          <ListItemButton>
            <FormControlLabel
              label={t`No computable`}
              control={
                <Checkbox
                  value={isComputable}
                  checked={!isComputable}
                  onChange={handleNoComputable}
                />
              }
            />
          </ListItemButton>
        </FormGroup>
      </GenericMoreButton>
    );
  },
);

AttendanceTypeMenu.displayName = 'AttendanceTypeMenu';

AttendanceTypeMenu.propTypes = {
  shiftId: PropTypes.number,
  studentId: PropTypes.number,
  justified: PropTypes.bool,
  computable: PropTypes.bool,
  onChange: PropTypes.func,
};

export default AttendanceTypeMenu;
