import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import useMarkNotificationViewAll from 'src/features/notifications/hooks/data/useMarkNotificationViewAll';
import { useNotifications } from 'src/features/notifications/hooks';

const NotificationsTitle = ({ propsText }) => {
  const { mutate, isLoading } = useMarkNotificationViewAll();
  const { notifications } = useNotifications();

  return (
    <>
      <Typography variant="h1" {...propsText}>
        <Trans>Notificaciones</Trans>
      </Typography>
      <LoadingButton
        onClick={mutate}
        loading={isLoading}
        disabled={!notifications?.hasUnreadNotifications}
      >
        <Trans>Marcar todo como leído</Trans>
      </LoadingButton>
    </>
  );
};

NotificationsTitle.propTypes = {
  propsText: PropTypes.object,
};

export default NotificationsTitle;
