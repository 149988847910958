import theme from 'src/theme';

const getFieldStyle = (placeholder = '') => ({
  style: {
    base: {
      color: theme.palette.text,
      fontSize: '14px',
      fontFamily: theme.typography.fontFamily,
      lineHeight: '30px',
      fontSmoothing: 'antialiased',
      fontWeight: '500',
      '::placeholder': {
        color: theme.palette.grey[500],
      },
      iconColor: '#adbfd3',
    },
    focus: {
      '::placeholder': {
        color: theme.palette.grey[500],
      },
    },
  },
  placeholder,
});

export { getFieldStyle };
