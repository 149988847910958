import React from 'react';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';

import { fillWithPlaceholder } from 'src/shared/helpers';

const TableSkeleton = () => {
  const skeletonRows = fillWithPlaceholder(4);
  const skeletonColumns = fillWithPlaceholder(2);

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Skeleton height={29} width={270} variant="squared" />
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            {skeletonColumns.map((skeleton) => (
              <TableCell key={skeleton}>
                <Skeleton width={60} height={10} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {skeletonRows.map((skeleton) => (
            <TableRow key={skeleton}>
              {skeletonColumns.map((column) => (
                <TableCell key={column}>
                  <Skeleton width={250} height={15} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default TableSkeleton;
