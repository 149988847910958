import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  GOOGLE_DRIVE_APP_ID,
  GOOGLE_DRIVE_CLIENT_ID,
  GOOGLE_DRIVE_API_KEY,
} from 'src/config/general';

import loadGoogleScript from './loadGoogleScript';
import './pickerOverwrite.css';

const GOOGLE_DRIVE_SCOPE =
  'https://www.googleapis.com/auth/drive.metadata.readonly';
const GOOGLE_DRIVE_DISCOVREY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
];

const GooglePicker = (callback) => {
  const user = useSelector((state) => state?.user);
  const { isWebView } = user;
  const [pickerApiLoaded, setPickerApiLoaded] = useState(false);
  const [oauthToken, setOauthToken] = useState();

  const handleAuthResult = (authResult) => {
    const GoogleAuth = window.gapi.auth2.getAuthInstance();

    if (GoogleAuth.isSignedIn.get()) {
      setOauthToken(authResult.access_token);
    } else {
      GoogleAuth.signIn();
    }
  };

  useEffect(() => {
    if (!isWebView) {
      const onAuthApiLoad = async () => {
        try {
          const { client, auth2 } = window.gapi ?? {};
          await client?.init({
            apiKey: GOOGLE_DRIVE_API_KEY,
            clientId: GOOGLE_DRIVE_CLIENT_ID,
            scope: GOOGLE_DRIVE_SCOPE,
            discoveryDocs: GOOGLE_DRIVE_DISCOVREY_DOCS,
          });

          const authInstance = auth2?.getAuthInstance();
          const isSignedIn = authInstance?.isSignedIn;
          if (isSignedIn) {
            isSignedIn.listen(handleAuthResult);
          }
        } catch {
          // eslint-disable-next-line no-console
          console.error(
            'Error en la autenticación con Google Drive API, revisa que las credenciales esten bien en el archivo de configuración de ambiente (.env)',
          );
        }
      };

      const loadPicker = () => {
        window.gapi?.load('client');
        window.gapi?.load('auth2', onAuthApiLoad);
        window.gapi?.load('picker', setPickerApiLoaded(true));
      };
      loadGoogleScript(loadPicker);
    }
  }, [isWebView]);

  const pickerCallback = useCallback(
    (data) => {
      let files = [];
      const { action, docs } = data;

      if (action === window.google.picker.Action.PICKED) {
        files = docs.map(({ id, name, mimeType, url }) => ({
          id,
          name,
          mimeType,
          url,
        }));
      }

      callback(files);
    },
    [callback],
  );

  const createPicker = (acessToken = oauthToken) => {
    if (pickerApiLoaded && acessToken && !isWebView) {
      const picker = new window.google.picker.PickerBuilder()
        .addView(window.google.picker.ViewId.DOCS)
        .addView(window.google.picker.ViewId.DOCS_IMAGES)
        .addView(window.google.picker.ViewId.DOCS_VIDEOS)
        .addView(window.google.picker.ViewId.DOCUMENTS)
        .addView(window.google.picker.ViewId.DRAWINGS)
        .addView(window.google.picker.ViewId.FOLDERS)
        .addView(window.google.picker.ViewId.FORMS)
        .addView(window.google.picker.ViewId.PDFS)
        .addView(window.google.picker.ViewId.PRESENTATIONS)
        .addView(window.google.picker.ViewId.SPREADSHEETS)
        .setLocale('es-419')
        .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
        .setAppId(GOOGLE_DRIVE_APP_ID)
        .setOAuthToken(acessToken)
        .setCallback(pickerCallback)
        .build();
      picker.setVisible(true);
    }
  };

  useEffect(createPicker, [
    pickerApiLoaded,
    oauthToken,
    isWebView,
    pickerCallback,
  ]);

  const doAuth = () => {
    const GoogleAuth = window.gapi.auth2.getAuthInstance();

    if (GoogleAuth.isSignedIn.get()) {
      handleAuthResult();
    } else {
      GoogleAuth.signIn();
    }
  };

  const showPicker = () => {
    if (!isWebView) {
      const GoogleAuth = window.gapi.auth2.getAuthInstance();

      const isSingedIn = GoogleAuth.isSignedIn.get();

      const acessToken = GoogleAuth.currentUser
        .get()
        .getAuthResponse().access_token;

      if (!isSingedIn) {
        doAuth();
      } else {
        createPicker(acessToken);
      }
    }
  };

  return { showPicker };
};

export default GooglePicker;
