import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import isBetween from 'dayjs/plugin/isBetween';
import { t } from '@lingui/macro';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(calendar);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

export const toDateFormat = (date, format = 'DD/MM/YYYY', disableUTC) => {
  if (!dayjs(date).isValid()) return null;

  return disableUTC
    ? dayjs(date).format(format)
    : dayjs.utc(date).local().format(format);
};

export const toDateFromNow = (date, withoutSuffix = false) => {
  const dayDifference = dayjs().diff(date, 'day');
  const relativeDate = dayjs.utc(date).local().fromNow(withoutSuffix);
  if (dayDifference > 2) {
    return toDateFormat(date, 'LLL');
  }

  return relativeDate;
};

export const toDateFromNowInHourFormat = (date) => {
  const dayDifference = dayjs().diff(date, 'day');
  const relativeDate = dayjs.utc(date).local().format('HH:mm');
  if (dayDifference > 1) {
    return toDateFormat(date, 'LLL');
  }

  return relativeDate;
};

export const toInboxDate = (date) => {
  const secondsDifference = dayjs()
    .utc()
    .local()
    .diff(dayjs.utc(date), 'second');
  const minuteDifference = dayjs()
    .utc()
    .local()
    .diff(dayjs.utc(date), 'minute');
  const hoursDifference = dayjs().utc().local().diff(dayjs.utc(date), 'hours');
  const yearsDifference = dayjs().utc().local().diff(dayjs.utc(date), 'year');

  if (secondsDifference < 60) {
    return dayjs.utc(date).local().format('HH:mm');
  }
  if (minuteDifference < 60) {
    return dayjs.utc(date).local().format('HH:mm');
  }
  if (hoursDifference < 24) {
    // Si la diferencia es menor a 24 horas, mostramos la cantidad de horas
    return dayjs.utc(date).local().format('HH:mm');
  } else if (yearsDifference < 1) {
    // Si la diferencia es mayor o igual a 24 horas pero dentro del mismo año
    // Mostramos la fecha en formato 'DD/MM'
    return dayjs.utc(date).local().format('DD/MM/YYYY');
  } else {
    // Si la diferencia supera un año, mostramos la fecha en formato 'DD/MM/YYYY'
    return dayjs.utc(date).local().format('DD/MM/YYYY');
  }
};
export const isCurrentMonth = (date) => {
  return dayjs().utc().month() === dayjs.utc(date).month();
};

export const isToday = (date) => {
  return dayjs(date).utc().isToday();
};

export const toIsoDate = (date) => dayjs(date).utc().format();

export const toCalendarDate = (date) => {
  const format = {
    sameDay: `[${t`Hoy`}] - HH:mm[${t`hs`}]`,
    nextDay: `[${t`Mañana`}] - HH:mm[${t`hs`}]`,
    nextWeek: `[${t`El`}] dddd - HH:mm[${t`hs`}]`,
    lastDay: `[${t`Ayer`}] - HH:mm[${t`hs`}]`,
    lastWeek: `${t`[El] dddd [pasado] - HH:mm [hs]`} `,
    sameElse: `DD/MM/YYYY - HH:mm[${t`hs`}]`,
  };
  return dayjs.utc(date).local().calendar(null, format);
};

export const dateIsBetween = (
  date,
  endDate,
  startDate,
  dateComparison,
  exclusionCriteria,
) =>
  dayjs(date).isBetween(startDate, endDate, dateComparison, exclusionCriteria);

export const sortByDateProp = (objectArray, propDate, reverse) => {
  const sortedArray = objectArray.sort(
    (a, b) => new Date(a[propDate]) - new Date(b[propDate]),
  );
  return reverse ? sortedArray.reverse() : sortedArray;
};

export const getMonthRange = (monthIdx) => {
  const month =
    monthIdx >= 0 && monthIdx <= 11 ? dayjs().month(monthIdx) : dayjs();
  return {
    startAt: month.startOf('month'),
    endAt: month.endOf('month'),
  };
};

export const getMinDateAndMaxDateFromLastPeriod = (lastInvoicedPeriod) => {
  const { currentPeriod, lastPeriod } = lastInvoicedPeriod ?? {};

  return {
    minDate: dayjs(currentPeriod ?? lastPeriod).subtract(4, 'month'),
    maxDate: dayjs().add(1, 'year').endOf('year').date(31),
  };
};

export const getMonthRangeByDate = (month = dayjs()) => {
  const monthDate = dayjs(month, 'YYYY-MM-DD');
  return {
    startAt: monthDate.startOf('month'),
    endAt: monthDate.endOf('month'),
  };
};

export const findMonthByIndex = (
  monthIndex = dayjs().month(),
  monthsArray = [],
) => {
  return monthsArray.find((month = {}) =>
    dayjs().month(month.id).isSame(dayjs().month(monthIndex), 'month'),
  );
};

export const addDays = (days = 0, date = new Date()) =>
  dayjs(date).add(days, 'day');

export const addHours = (hours = 0, date = new Date()) =>
  dayjs(date).add(hours, 'hour');

export const getNameMonth = (month = dayjs()) => {
  const monthDate = month.format('MMMM');
  const capitalizedMonth =
    monthDate.charAt(0).toUpperCase() + monthDate.slice(1);
  return capitalizedMonth;
};

export const dateIsAfterCurrent = (date) => {
  const currentDate = dayjs();

  return dayjs(currentDate).isAfter(dayjs(date));
};

export const dateIsBeforeCurrent = (date) => {
  const currentDate = dayjs().startOf('day');

  return dayjs(currentDate).isSameOrBefore(date);
};

export const getMonths = () => {
  const monthNames = dayjs.months();
  return monthNames.map((month, index) => ({
    id: index + 1,
    name: month.charAt(0).toUpperCase() + month.slice(1),
  }));
};
