import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Edit3 as EditIcon } from 'react-feather';
import { Trans } from '@lingui/macro';

const ContainerInfo = ({ title, children, isEditable, onEdit, ...rest }) => (
  <Box
    sx={{
      padding: 5,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'grey.50',
      borderRadius: 1,
    }}
    {...rest}
  >
    <Grid
      container
      direction={'row'}
      justifyContent="space-between"
      alignItems={'center'}
    >
      <Typography color="text.secondary" variant="overline" fontSize={'12px'}>
        {title}
      </Typography>

      {isEditable && (
        <Box display={'flex'} flexDirection="row">
          <Button
            size="small"
            variant="text"
            onClick={onEdit}
            startIcon={<EditIcon size="14px" />}
          >
            <Trans>Editar</Trans>
          </Button>
        </Box>
      )}
    </Grid>
    {children}
  </Box>
);

ContainerInfo.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  isEditable: PropTypes.bool,
  onEdit: PropTypes.func,
};

export default ContainerInfo;
