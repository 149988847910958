import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const TimeGridDayHeader = ({ header }) => {
  const { date } = header;
  const day = dayjs(date);

  return <div>{day.format('dddd DD')}</div>;
};

TimeGridDayHeader.propTypes = {
  header: PropTypes.object,
};

export default TimeGridDayHeader;
