import React from 'react';
import PropTypes from 'prop-types';
import { Field as FormikField, useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { Grid, FormControl } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';

import ConditionalWrapper from 'src/components/ConditionalWrapper';

import TextField from '../TextField';
import useStyles from './styles';

const TimeField = ({
  gridProps = { xs: 12 },
  name,
  disabled,
  ampm = true,
  timeSteps = { minutes: 15 },
  disableToolbar = true,
  label,
  withFloatingLabel = false,
  ...props
}) => {
  const classes = useStyles();
  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <ConditionalWrapper
      condition={Boolean(gridProps)}
      wrapper={(children) => (
        <Grid item {...gridProps}>
          {children}
        </Grid>
      )}
    >
      <FormikField name={name}>
        {({ field, meta, form, ...rest }) => {
          return (
            <FormControl className={classes.formControl}>
              <TimePicker
                {...props}
                {...rest}
                {...field}
                fullWidth
                disableToolbar={disableToolbar}
                ampm={ampm}
                slotProps={{
                  textField: {
                    helperText:
                      Boolean(meta.error && meta.touched) && meta.error,
                    error: Boolean(meta.error && meta.touched),
                    ...(withFloatingLabel && {
                      label,
                      className: classes.floatingLabel,
                    }),
                  },
                }}
                helperText={meta.touched && meta.error}
                inputVariant="outlined"
                disabled={form.isSubmitting || form.isValidating || disabled}
                variant="inline"
                onChange={(date) => {
                  setFieldValue(field.name, dayjs(date));
                }}
                onOpen={() => {
                  setFieldTouched(name, true);
                }}
                timeSteps={timeSteps}
                thresholdToRenderTimeInASingleColumn={100}
                textField={(params) => (
                  <div>
                    <TextField name="timefield" {...params} />
                  </div>
                )}
              />
            </FormControl>
          );
        }}
      </FormikField>
    </ConditionalWrapper>
  );
};

TimeField.propTypes = {
  gridProps: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  ampm: PropTypes.bool,
  name: PropTypes.string.isRequired,
  withFloatingLabel: PropTypes.bool,
};

export default TimeField;
