import React from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { ArrowLeft } from 'react-feather';
import useStyles from 'src/components/Page/styles';

const GoBackButton = ({ goBackLabel, onGoBack, goBackIcon }) => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.goBackWrapper}>
      {goBackLabel ? (
        <Button
          onClick={onGoBack}
          startIcon={goBackIcon ?? <ArrowLeft />}
          color="primary"
        >
          {goBackLabel}
        </Button>
      ) : (
        <IconButton onClick={onGoBack} color="primary">
          {goBackIcon ?? <ArrowLeft />}
        </IconButton>
      )}
    </Box>
  );
};

export default GoBackButton;
