import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { t } from '@lingui/macro';
import { useHistory } from 'react-router-dom';

import useAuthorizatedInfo from 'src/features/my-account/hooks/useAuthorizatedInfo';
import usePickupAccessRoles from 'src/features/pickups/hooks/usePickupAccessRoles';
import useModulesAreEnabled from 'src/shared/hooks/useModulesAreEnabled';
import Guard from 'src/components/Guard';
import InfoTitle from 'src/features/my-account/components/InfoTitle';
import router from 'src/shared/utils/router';
import { useOpenDialog } from 'src/shared/hooks';
import { dialogs } from 'src/shared/constants';
import { BLENDED_MODULES } from 'src/config/constants';
import { checkRole } from 'src/shared/helpers';
import { ROLE_LIST } from 'src/config/general';

const AuthorizedInfo = ({ userInfo }) => {
  const history = useHistory();
  const openDialog = useOpenDialog(dialogs.USER_INFO_DIALOG);
  const profileUserIsStudent = checkRole([ROLE_LIST.STUDENT], userInfo);

  const { authorizatedUsersNames, pickUpCategoryNames } = useAuthorizatedInfo(
    userInfo?.id,
    profileUserIsStudent,
  );

  const handleClickAuthorizedDetails = () => {
    history.push({
      pathname: router.profile.authorized(userInfo?.id),
    });
    openDialog(false);
  };

  const handleClickPickupCategories = () => {
    history.push({
      pathname: router.profile.pickupCategory(userInfo?.id),
    });
    openDialog(false);
  };

  const pickupAccessRoles = usePickupAccessRoles();
  const pickupsIsEnabled = useModulesAreEnabled(BLENDED_MODULES.PICKUPS);

  if (
    !pickupsIsEnabled ||
    !authorizatedUsersNames?.length ||
    !profileUserIsStudent
  ) {
    return null;
  }

  return (
    <Guard roles={pickupAccessRoles}>
      <Grid item xs={12} pt="0 !important">
        <InfoTitle title={t`Autorizados`}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            onClick={handleClickAuthorizedDetails}
            paddingTop={1}
            style={{ cursor: 'pointer' }}
          >
            <Typography align="left" color="primary" mb={0.5} variant={'body1'}>
              {authorizatedUsersNames}
            </Typography>
          </Box>
        </InfoTitle>
        <InfoTitle title={t`Salidas Habituales`}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            onClick={handleClickPickupCategories}
            paddingTop={1}
            style={{ cursor: 'pointer' }}
          >
            <Typography align="left" color="primary" mb={0.5} variant={'body1'}>
              {pickUpCategoryNames}
            </Typography>
          </Box>
        </InfoTitle>
      </Grid>
    </Guard>
  );
};

AuthorizedInfo.propTypes = {
  userInfo: PropTypes.object,
};

export default AuthorizedInfo;
