import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.08)',
    cursor: 'pointer',
    marginRight: -6,
    paddingLeft: theme.spacing(),
  },
  input: {
    '& .MuiButtonBase-root': {
      padding: theme.spacing(0.5),
    },

    '& .MuiInputAdornment-positionEnd': {
      paddingLeft: theme.spacing(0.5),
      marginRight: `-${theme.spacing(1)}`,
      borderLeft: `1px solid ${theme.palette.divider}`,
      height: '100%',
    },

    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      marginBottom: `-${theme.spacing(3)}`,
      bottom: 0,
      marginLeft: 0,
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '& svg': {
      width: 16,
      height: 16,
      color: theme.palette.primary.main,
    },
  },
  timePicker: {
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      marginBottom: `-${theme.spacing(3)}`,
      bottom: 0,
      marginLeft: 0,
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  dialogRoot: {
    '& .MuiDialogContent-root': {
      overflow: 'unset',
    },
  },
  rootLabel: {
    position: 'relative',
    transform: 'none',
    fontWeight: 500,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
