const REPORT_DIALOGS = {
  REPORT_SCALE_VIEW_DIALOG: 'ReportScaleViewDialog',
  REPORT_SCALE_CREATE_DIALOG: 'ReportScaleCreateDialog',
  REPORT_SCALE_REMOVE_CONFIRM: 'ReportScaleRemoveConfirm',
  REPORT_TEMPLATE_FILTERS_DIALOG: 'ReportTemplateFiltersDialog',
  REPORT_TEMPLATE_CREATE_DIALOG: 'ReportTemplateCreateDialog',
  REPORT_TEMPLATE_REMOVE_CONFIRM: 'ReportTemplateRemoveConfirm',
  REPORT_CATEGORY_REMOVE_CONFIRM: 'ReportCategoryRemoveConfirm',
  REPORT_CATEGORY_CREATE_DIALOG: 'ReportCategoryCreateDialog',
  REPORT_TEMPLATE_INCOMPLETE_DIALOG: 'ReportTemplateIncompleteDialog',
  REPORT_CREATE_DIALOG: 'ReportCreateDialog',
  REPORT_FILTERS_DIALOG: 'ReportFiltersDialog',
  REPORT_DETAILS_DIALOG: 'ReportDetailsDialog',
  REPORT_GENERATE_CONFIRM_DIALOG: 'ReportGenerateConfirmDialog',
  REPORT_PREVIEW_DIALOG: 'ReportPreviewDialog',
  REPORT_STUDENT_LIST_DIALOG: 'ReportStudentListDialog',
  REPORT_REMOVE_CONFIRM: 'ReportRemoveConfirm',
  REPORT_CATEGORY_COMPLETE_DIALOG: 'ReportCategoryCompleteDialog',
  REPORT_OBSERVATION_COMPLETE_DIALOG: 'ReportObservationCompleteDialog',
  REPORT_ATTENDANCE: 'ReportAttendance',
};

export default REPORT_DIALOGS;
