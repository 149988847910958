import PropTypes from 'prop-types';
import React from 'react';

import { Grid, Typography } from '@mui/material';

import useStyles from './styles';

const FieldGroup = ({ title, subtitle, children }) => {
  const classes = useStyles();

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" component="label" className={classes.title}>
          {title}
        </Typography>

        {subtitle && (
          <Typography variant="body2" color="textSecondary">
            {subtitle}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

FieldGroup.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default FieldGroup;
