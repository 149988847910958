import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {},
  clickable: {
    transition: `background-color ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeIn}`,
    '&:hover': {
      backgroundColor: 'rgba(51, 51, 51, 0.04)',
      cursor: 'pointer',
    },
  },
  stickyColumn: {
    left: 0,
    zIndex: 1,
    position: 'sticky !important',
    backgroundColor: theme.palette.common.white,
  },
  tableRow: {
    position: 'relative',
    '&:hover': {
      '& $stickyColumn': {
        backgroundColor: theme.palette.grey[50],
      },
    },
  },
}));

export default useStyles;
