import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Skeleton, Typography, Button, Tooltip } from '@mui/material';
import { t } from '@lingui/macro';
import { Edit3 as EditIcon } from 'react-feather';

import { dialogs } from 'src/shared/constants';
import { useOpenDialog } from 'src/shared/hooks';
import { formatUserRolesName } from 'src/shared/helpers';
import useCanCustomizeRole from 'src/features/my-account/hooks/useCanCustomizeRole';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import useUserCustomRole from 'src/features/my-account/hooks/data/custom_role/useUserCustomRole';

const CustomRole = ({ user, isEditable = false }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { data, isFetching } = useUserCustomRole({ userId: user?.id });
  const { custom_role } = data ?? {};
  const roles = user?.roles?.map((role) => role.item_name);
  const rolesName = formatUserRolesName(roles, user?.genre_id);
  const canAssignCustomRole = useCanCustomizeRole(user);
  const openUserCustomRoleDialog = useOpenDialog(
    dialogs.USER_CUSTOM_ROLE_DIALOG,
  );

  return (
    <Grid item>
      {isFetching ? (
        <Skeleton width={150} />
      ) : (
        <ConditionalWrapper
          condition={canAssignCustomRole && isEditable}
          wrapper={(children) => (
            <Tooltip title={t`Personalizar rol`}>
              <Button
                variant="text"
                sx={{ p: 0, minWidth: 0, textAlign: 'left' }}
                {...(canAssignCustomRole &&
                  isEditable && {
                    onClick: () => openUserCustomRoleDialog(true),
                    onMouseEnter: () => setIsHovered(true),
                    onMouseLeave: () => setIsHovered(false),
                    endIcon: isHovered && <EditIcon size={16} />,
                  })}
              >
                {children}
              </Button>
            </Tooltip>
          )}
        >
          <Typography variant="h4" component="h4" color={'text.secondary'}>
            {custom_role ?? rolesName}
          </Typography>
        </ConditionalWrapper>
      )}
    </Grid>
  );
};

CustomRole.propTypes = {
  user: PropTypes.object,
  isEditable: PropTypes.bool,
};

export default CustomRole;
