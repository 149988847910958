import { API_USER_BASE, SIS_URL } from 'src/config/general';

// TO-DO: Refactor in another issue
export const login = () => `${API_USER_BASE}/api/public-user/login`;

export const OAuthLogin = () => `${API_USER_BASE}/api/public-user/oauth-login`;

export const sendVerificationCode = () =>
  `${API_USER_BASE}/api/public-user/recovery-passcode`;

export const loginWitPasscode = () =>
  `${API_USER_BASE}/api/public-user/login-passcode`;

export const logOut = () => `${API_USER_BASE}/api/public-user/logout`;

export const createPassword = () => `${API_USER_BASE}/api/user/create-password`;

export const createPasswordWithKey = () =>
  `${API_USER_BASE}/api/public-user/change-password-with-recovery-key`;

export const storeId = (appName) =>
  `${API_USER_BASE}/api/public-whitelabel/${appName}`;

export const getCountry = () => `${API_USER_BASE}/api/public-country`;

export const basicInformationUser = (user, country) =>
  `${API_USER_BASE}/api/public-user/country/${country}/basic-information/${user}`; // user puede ser dni o mail

export const recoveryPassword = () =>
  `${API_USER_BASE}/api/public-user/recovery-password`;

export const getUserRole = () => `${API_USER_BASE}/api/user/relations`;

export const getUserInfo = (uid) => `${API_USER_BASE}/api/user/basic/${uid}`;

export const getLegacyUserInfo = () =>
  `${SIS_URL}/ajax/configuracion/get_user_data`;

export const updateUser = (uid) => `${API_USER_BASE}/api/user/${uid}`;

export const updateCRUDUser = (uid) =>
  `${API_USER_BASE}/api/user/${uid}/update`;

export const getSons = () => `${API_USER_BASE}/api/parent-user/children`;

export const linkSons = () => `${API_USER_BASE}/api/parent-user`;

export const searchUser = () => `${API_USER_BASE}/api/user`;

export const searchParentUser = () => `${API_USER_BASE}/api/user/parent`;

export const subjects = () => `${API_USER_BASE}/api/subject`;

export const division = (periodId) =>
  `${API_USER_BASE}/api/division/institution-period/${periodId}`;

// eslint-disable-next-line camelcase
export const unlinkSon = (relation_id) =>
  `${API_USER_BASE}/api/parent-user/${relation_id}`;

export const getCalif = (limit) => `${API_USER_BASE}/api/score/${limit}`;

export const setUserInfo = () =>
  `${API_USER_BASE}/api/public-user/save-basic-information`;

export const getSchema = (periodId) =>
  `${API_USER_BASE}/api/institution/schema${
    periodId ? `/institution-period/${periodId}` : ''
  }`;

export const getInstitutionPeriods = () =>
  `${API_USER_BASE}/api/institution/periods`;

export const getInstitutionConfig = () => `${API_USER_BASE}/api/institution`;

export const getRelations = () => `${API_USER_BASE}/api/user/relations`;

export const getExternalLinks = (period) =>
  `${API_USER_BASE}/api/external-link/institution-period/${period}`;

export const updateExternalLinks = (id) =>
  `${API_USER_BASE}/api/external-link/${id}`;

export const createExternalLinks = () => `${API_USER_BASE}/api/external-link`;

export const getUsersByDivisionsUrl = (extraParams) =>
  `${API_USER_BASE}/api/user/by-division${
    extraParams ? `?${extraParams}` : ''
  }`;

export const refreshToken = () =>
  `${API_USER_BASE}/api/public-user/refresh-token`;

export const subjectLinks = (subjectId) =>
  `${API_USER_BASE}/api/subject/${subjectId}/links`;

export const singleSubjectLink = (subjectId, linkId) =>
  `${API_USER_BASE}/api/subject/${subjectId}/links/${linkId}`;

export const getUserInformation = () => `${API_USER_BASE}/api/user/search`;

export const setDefaultInstitution = () =>
  `${API_USER_BASE}/api/user/set-default-institution`;

// export const getInstitutionInfo = (id) =>
//   `${API_USER_BASE}/api/institution/basic/${id}`;

export const levelPeriodsUrl = () => `${API_USER_BASE}/api/level-period`;

export const usersPermissions = () => `${API_USER_BASE}/api/auth-assignment`;

export const createUsersPermissionsBulk = () =>
  `${API_USER_BASE}/api/auth-assignment/bulk`;

export const deleteUserPermission = (id) =>
  `${API_USER_BASE}/api/auth-assignment/${id}`;

export const createPermissions = () =>
  `${API_USER_BASE}/api/auth-assignment/users`;

export const getFamilyInformation = (familyId) =>
  `${API_USER_BASE}/api/family/${familyId}`;

export const putFamilyInformation = (familyId) =>
  `${API_USER_BASE}/api/family/${familyId}`;

export const downloadCommunityInformation = () =>
  `${API_USER_BASE}/api/user/institution/report`;

export const downloadFamilyInformation = () =>
  `${API_USER_BASE}/api/family/download`;

export const createFamilyInformation = () => `${API_USER_BASE}/api/family`;

export const getUnparentStudent = () => `${API_USER_BASE}/api/user/unparent`;

export const getCommunity = () => `${API_USER_BASE}/api/user/institution`;

export const getFamilies = () => `${API_USER_BASE}/api/family`;

export const getUserChildren = (uid) =>
  `${API_USER_BASE}/api/user/${uid}/children`;

export const getUserParent = (uid) => `${API_USER_BASE}/api/user/${uid}/parent`;

export const getUserCustomField = () =>
  `${API_USER_BASE}/api/user/custom-field`;

export const updateUserPassword = (uid) =>
  `${API_USER_BASE}/api/user/${uid}/change-password`;

export const getCustomFieldsUrl = () =>
  `${API_USER_BASE}/api/user/custom-field`;

export const getCustomFieldByIdUrl = (customFieldId) =>
  `${API_USER_BASE}/api/user/custom-field/${customFieldId}`;

export const customFieldOrderUrl = () =>
  `${API_USER_BASE}/api/user/order-custom-field`;

export const removeUser = () => `${SIS_URL}/ajax/configuracion/remove_user`;

export const pickupUrl = () => `${API_USER_BASE}/api/pickup`;

export const pickupListUrl = () => `${API_USER_BASE}/api/pickup/list`;

export const pickupByIdUrl = (pickupId) =>
  `${API_USER_BASE}/api/pickup/${pickupId}`;

export const pickupReportUrl = () => `${API_USER_BASE}/api/pickup/report`;

export const pickupReportDailyUrl = () =>
  `${API_USER_BASE}/api/pickup/report-daily`;

export const pickupReportBatchTransaction = (batchId) =>
  `${API_USER_BASE}/api/report-batch-transaction/batch/${batchId}`;

export const pickupConfigUrl = () => `${API_USER_BASE}/api/pickup-config`;

export const pickupConfigByIdUrl = (pickupId) =>
  `${API_USER_BASE}/api/pickup-config/${pickupId}`;

export const pickupCategoryUrl = () => `${API_USER_BASE}/api/pickup-category`;

export const pickupCategoryByIdUrl = (categoryId) =>
  `${API_USER_BASE}/api/pickup-category/${categoryId}`;

export const pickupSubCategoryUrl = () =>
  `${API_USER_BASE}/api/pickup-sub-category`;

export const pickupSubCategoryByIdUrl = (categoryId) =>
  `${API_USER_BASE}/api/pickup-sub-category/${categoryId}`;

export const pickupOutputRangeUrl = () =>
  `${API_USER_BASE}/api/pickup-output-range`;

export const pickupOutputRangeByIdUrl = (categoryId) =>
  `${API_USER_BASE}/api/pickup-output-range/${categoryId}`;

export const pickupReasonUrl = () => `${API_USER_BASE}/api/pickup-reason`;

export const pickupReasonByIdUrl = (categoryId) =>
  `${API_USER_BASE}/api/pickup-reason/${categoryId}`;

export const pickupCategoryDayUrl = () =>
  `${API_USER_BASE}/api/pickup-category-day`;

export const pickupAuthorizedUrl = () =>
  `${API_USER_BASE}/api/pickup/authorized`;

export const pickupAuthorizedReportUrl = () =>
  `${API_USER_BASE}/api/pickup/authorized/report`;

export const pickupCategoryDayReportUrl = () =>
  `${API_USER_BASE}/api/pickup-category-day/report`;

export const pickupConfirmByIdUrl = (pickupId) =>
  `${API_USER_BASE}/api/pickup/${pickupId}/confirm`;

export const pickupDeclineByIdUrl = (pickupId) =>
  `${API_USER_BASE}/api/pickup/${pickupId}/decline`;

export const institutionUserCustomFieldUrl = (institutionPeriodId) =>
  `${API_USER_BASE}/api/institution-user-custom-field/institution-period/${institutionPeriodId}`;

export const uiaHealthUrl = () => `${API_USER_BASE}/api/health`;

export const uiaSuspendedInstitutionsUrl = () =>
  `${API_USER_BASE}/api/institution-roles-blacklist/user`;

export const userModules = () => `${API_USER_BASE}/api/user/modules`;

export const backlogInstitutionModules = (institutionId) =>
  `${API_USER_BASE}/api/institution/${institutionId}/module?expand=detail`;

export const dashboardInstitution = () =>
  `${API_USER_BASE}/api/dashboard/institution`;

export const dashboardCommunity = () =>
  `${API_USER_BASE}/api/dashboard/community`;

export const userUnparent = () => `${API_USER_BASE}/api/user/unparent`;

export const userSpecialPermission = (userId) =>
  `${API_USER_BASE}/api/special-permission?user_id=${userId}`;

export const specialPermission = () =>
  `${API_USER_BASE}/api/special-permission`;

export const deleteUserSpecialPermission = () =>
  `${API_USER_BASE}/api/special-permission/delete-by-user`;

export const resetPassword = () =>
  `${API_USER_BASE}/api/public-user/recovery-password`;
export const levelPeriodsByIdUrl = (levelId) =>
  `${API_USER_BASE}/api/level/${levelId}/period`;

export const getInstitutionPeriod = () =>
  `${API_USER_BASE}/api/institution-period`;

export const getInstitutionPeriodById = (periodId) =>
  `${API_USER_BASE}/api/institution-period/${periodId}`;

export const institutionPeriodActive = (periodId) =>
  `${API_USER_BASE}/api/institution-period/${periodId}/active`;

export const institutionPeriodByInstitution = (institutionId) =>
  `${API_USER_BASE}/api/institution-period/institution/${institutionId}`;

export const institutionNewCycleUser = () =>
  `${API_USER_BASE}/api/institution-new-cycle-user`;

export const institutionNewCycleUserById = (newCycleUserId) =>
  `${API_USER_BASE}/api/institution-new-cycle-user/${newCycleUserId}`;

export const institutionNewCycle = () =>
  `${API_USER_BASE}/api/institution-new-cycle`;

export const institutionNewCycleStructure = () =>
  `${API_USER_BASE}/api/institution-new-cycle/create-structure`;

export const institutionNewCycleById = (newCycleId) =>
  `${API_USER_BASE}/api/institution-new-cycle/${newCycleId}`;

export const getMigrateBatchTransaction = (batchId) =>
  `${API_USER_BASE}/api/migrate-batch-transaction/batch/${batchId}`;

export const getBatchTransaction = (batchId) =>
  `${API_USER_BASE}/api/batch-transaction/batch/${batchId}`;

export const createNewUserPassword = (userId) =>
  `${API_USER_BASE}/api/user/${userId}/create-password`;

export const getCommunityReport = () => `${API_USER_BASE}/api/user/report`;

export const getAiThread = () => `${API_USER_BASE}/api/thread`;
