import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { X as CloseIcon } from 'react-feather';
import { IconButton } from '@mui/material';

const CloseOpenGraph = memo(({ classes, onClose }) => (
  <IconButton
    component="span"
    aria-label={t`Cerrar`}
    className={classes}
    onClick={onClose}
    size="large"
  >
    <CloseIcon size={14} />
  </IconButton>
));

CloseOpenGraph.displayName = 'CloseOpenGraph';

CloseOpenGraph.propTypes = {
  classes: PropTypes.string,
  onClose: PropTypes.func,
};

export default CloseOpenGraph;
