import { useSelector } from 'react-redux';

import { ROLE_LIST } from 'src/config/general';
import { useAvailableStructure } from 'src/shared/hooks';
import useHighRoleContacts from './useHighRoleContacts';
import useLowRoleContacts from './useLowRoleContacts';
import useIsStaff from '../useIsStaff';

const useGetContacts = ({
  searchTerm = '',
  filters,
  enabled = true,
  ...params
}) => {
  const { roleTypes, selectedRole, userIs } = useSelector((store) => ({
    roleTypes: store.configuration.roleTypes,
    selectedRole: store.user.selectedRole,
    userIs: store.user.userIs,
  }));

  const hasInitialDivisions =
    !userIs.executive &&
    (selectedRole === ROLE_LIST.TEACHER ||
      selectedRole === ROLE_LIST.PRECEPTOR);

  const isStaff = useIsStaff();
  const { divisionsByRole } = useAvailableStructure();
  const isDivisionByRoleLoaded = Boolean(divisionsByRole?.teacher);

  const initialDivisions = [
    ...divisionsByRole.preceptor,
    ...(divisionsByRole?.teacher ?? []),
  ];

  const divisions = hasInitialDivisions
    ? [...new Set(initialDivisions)].map(({ divisionId }) => divisionId)
    : [];

  const filter = {
    division: filters?.divisions?.length ? filters.divisions : divisions,
    roles: filters?.roles?.map((roleId) => roleTypes[roleId]) ?? [],
  };

  const highRoleContacts = useHighRoleContacts({
    enabled: enabled && isStaff && isDivisionByRoleLoaded,
    searchTerm,
    ...filter,
    ...params,
  });
  const lowRoleContacts = useLowRoleContacts({
    enabled: !isStaff,
    searchTerm,
    ...filter,
    ...params,
  });

  return isStaff ? highRoleContacts : lowRoleContacts;
};
export default useGetContacts;
