import { useGetOne } from 'src/shared/services/api/query';
import { dLocalPaymentOrders } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import oneElementArraySerializer from 'src/shared/services/api/query/serializer/oneElementArraySerializer';

const useGetPaymentOrderById = (paymentOrderId, queryParams) => {
  const customSerializer = oneElementArraySerializer();

  return useGetOne(
    [QUERY_KEY.DLOCAL_PAYMENT_ORDER, paymentOrderId],
    dLocalPaymentOrders(),
    { id: paymentOrderId },
    false,
    false,
    queryParams,
    customSerializer,
  );
};

export default useGetPaymentOrderById;
