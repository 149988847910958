import { useCreate } from 'src/shared/services/api/query';
import { getUserChildren } from 'src/shared/services/url/UrlUser';
import queryClient from 'src/config/query';
import queryKey from 'src/shared/constants/queryKey';

const useAddUserChildren = ({ userId, ...options } = {}) => {
  return useCreate(getUserChildren(userId), {
    onSettled: () => {
      queryClient.invalidateQueries([queryKey.USER_SONS]);
    },
    ...options,
  });
};

export default useAddUserChildren;
