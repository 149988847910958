import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, t } from '@lingui/macro';

import Form from 'src/components/Form';
import TextField from 'src/components/Form/TextField';
import SubmitButton from 'src/components/Form/SubmitButton';

import SessionExpirationSchema from './schema';

const initialValues = {
  password: '',
};

const SessionExpirationForm = ({ onSubmit }) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={SessionExpirationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form>
        <TextField type="password" name="password" label={t`Contraseña`} />

        <SubmitButton fullWidth>
          <Trans>Ingresar</Trans>
        </SubmitButton>
      </Form>
    </Formik>
  );
};

SessionExpirationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SessionExpirationForm;
