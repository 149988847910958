import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { useUpdate } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { singleFamilyTaxInformationUrl } from 'src/shared/services/url/UrlPaymentManagement';

const useUpdateFamilyTaxResponsible = (userId) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useUpdate(
    singleFamilyTaxInformationUrl(userId),
    {
      onSuccess: () => {
        enqueueSnackbar(t`Información actualizada con éxito.`, {
          variant: 'success',
        });
      },
      onError: ({ message: errorMessage }) => {
        enqueueSnackbar(errorMessage ?? t`Hubo un error al actualizar`, {
          variant: 'error',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries([QUERY_KEY.FAMILY_TAX_INFORMATION]);
      },
    },
    false,
  );
};

export default useUpdateFamilyTaxResponsible;
