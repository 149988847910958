import { API_SIS_BASE } from 'src/config/general';

// ATTENDANCES
export const getGeneralAttendanceStatusUrl = () =>
  `${API_SIS_BASE}/attendance/status-division`;

export const getSubjectsAttendanceStatusUrl = () =>
  `${API_SIS_BASE}/attendance-subject/status`;

export const getAttendanceUrl = () => `${API_SIS_BASE}/attendance`;

export const getReportAttendance = () => `${API_SIS_BASE}/attendance/report`;

export const getAttendanceSubjectsUrl = () =>
  `${API_SIS_BASE}/attendance-subject`;

export const attendanceShiftUrl = () => `${API_SIS_BASE}/attendance-shift`;

export const attendanceScaleUrl = () => `${API_SIS_BASE}/attendance-scale`;

export const approveAttendancesUrl = () => `${API_SIS_BASE}/attendance/approve`;

export const attendanceTotalUrl = () => `${API_SIS_BASE}/attendance-total`;

export const getReportAttendanceTotal = () =>
  `${API_SIS_BASE}/attendance-total/report`;

export const attendanceLimitUrl = () => `${API_SIS_BASE}/attendance-limit`;

// GRADING
export const scoreScaleUrl = (scaleId) =>
  `${API_SIS_BASE}/score-scale${scaleId ? `/${scaleId}` : ''}`;
export const assessmentTypeUrl = (assessmentTypeId) =>
  `${API_SIS_BASE}/assessment-type${
    assessmentTypeId ? `/${assessmentTypeId}` : ''
  }`;
export const scoreConfigUrl = (configurationId) =>
  configurationId
    ? `${API_SIS_BASE}/score-config/${configurationId}`
    : `${API_SIS_BASE}/score-config`;
export const scoreScaleValueUrl = (scaleId, valueId) =>
  `${API_SIS_BASE}/score-scale/${scaleId}/value${valueId ? `/${valueId}` : ''}`;

export const scoreConfigBySubjectId = (subjectId) =>
  `${API_SIS_BASE}/score-config/subject/${subjectId}`;

export const getAssessmentsUrl = (assessmentId) =>
  `${API_SIS_BASE}/assessment${assessmentId ? `/${assessmentId}` : ''}`;

export const getScoresUrl = () => `${API_SIS_BASE}/score`;

export const getFinalScoresUrl = () => `${API_SIS_BASE}/final-score`;

export const getScoresListUrl = () => `${API_SIS_BASE}/score/list`;

export const pendingApprovalUrl = () =>
  `${API_SIS_BASE}/assessment/pending-approval`;

export const finalScorePendingApprovalUrl = () =>
  `${API_SIS_BASE}/final-score/pending-approval`;

export const getPendingApprovalUrl = () => `${API_SIS_BASE}/assessment/user`;

export const rejectAssessmentUrl = () => `${API_SIS_BASE}/assessment/reject`;

export const approveAssessmentUrl = () => `${API_SIS_BASE}/assessment/approve`;

export const scoreOverviewUrl = () => `${API_SIS_BASE}/score/overview`;

export const finalScoreApproveListUrl = () =>
  `${API_SIS_BASE}/final-score/approve-list`;

export const finalScoreRejectUrl = () => `${API_SIS_BASE}/final-score/reject`;

export const finalScoreApproveUrl = () => `${API_SIS_BASE}/final-score/approve`;

export const finalScoreUpdateBulk = () =>
  `${API_SIS_BASE}/final-score/update-bulk`;

export const assessmentTypeFinalScore = () =>
  `${API_SIS_BASE}/assessment-type-final-score`;

export const periodFinalScores = ({ subjectId, levelPeriodId }) =>
  `${API_SIS_BASE}/final-score/subject/${subjectId}/level_period/${levelPeriodId}`;

export const reportAttendenceBySubject = () =>
  `${API_SIS_BASE}/report/attendance?type=daily-detail-report-by-subject`;

export const reportCardObservation = () =>
  `${API_SIS_BASE}/report-card-observation`;

export const reportCardObservationId = (observationId) =>
  `${API_SIS_BASE}/report-card-observation/${observationId}`;

export const reportCardSignature = () =>
  `${API_SIS_BASE}/report-card-signature`;

export const reportCardSignatureId = (signatureId) =>
  `${API_SIS_BASE}/report-card-signature/${signatureId}`;

export const reportCardConcept = () => `${API_SIS_BASE}/report-card-concept`;

export const reportCardConceptId = (conceptId) =>
  `${API_SIS_BASE}/report-card-concept/${conceptId}`;

export const reportCardTypeConcept = () =>
  `${API_SIS_BASE}/report-card-type-concept`;

export const reportCardTypeConceptId = (typeId) =>
  `${API_SIS_BASE}/report-card-type-concept/${typeId}`;

export const reportCardPendingSubject = () =>
  `${API_SIS_BASE}/report-card-pending-subject`;

export const reportCardPendingSubjectId = (pendingSubjectId) =>
  `${API_SIS_BASE}/report-card-pending-subject/${pendingSubjectId}`;

export const reportCardConfiguration = () =>
  `${API_SIS_BASE}/report-card-configuration`;

export const reportCardConfigurationId = (configId) =>
  `${API_SIS_BASE}/report-card-configuration/${configId}`;

export const reportCardAttendance = () =>
  `${API_SIS_BASE}/report-card-attendance`;

export const reportCardAttendanceId = (attendanceId) =>
  `${API_SIS_BASE}/report-card-attendance/${attendanceId}`;

export const reportCardAttendanceReset = () =>
  `${API_SIS_BASE}/report-card-attendance/reset`;

export const reportCardUser = (userId) =>
  `${API_SIS_BASE}/report-card/user/${userId}`;

export const reportCardGenerate = () => `${API_SIS_BASE}/report-card`;

export const getReportScaleUrl = () => `${API_SIS_BASE}/report-scale`;

export const getReportScaleById = (scaleId) =>
  `${API_SIS_BASE}/report-scale/${scaleId}`;

export const getReportTemplateUrl = () => `${API_SIS_BASE}/report-template`;

export const getReportTemplateById = (templateId) =>
  `${API_SIS_BASE}/report-template/${templateId}`;

export const getReportScaleValueUrl = () =>
  `${API_SIS_BASE}/report-scale-value`;

export const getReportScaleValueById = (scaleValueId) =>
  `${API_SIS_BASE}/report-scale-value/${scaleValueId}`;

export const getReportTemplateCopyUrl = (templateId) =>
  `${API_SIS_BASE}/report-template/${templateId}/copy`;

export const getReportCategoryUrl = () => `${API_SIS_BASE}/report-category`;

export const getReportCategoryById = (categoryId) =>
  `${API_SIS_BASE}/report-category/${categoryId}`;

export const getReportItemUrl = () => `${API_SIS_BASE}/report-item`;

export const getReportItemById = (itemId) =>
  `${API_SIS_BASE}/report-item/${itemId}`;

export const getReportSubcategoryUrl = () =>
  `${API_SIS_BASE}/report-subcategory`;

export const getReportSubcategoryById = (subcategoryId) =>
  `${API_SIS_BASE}/report-subcategory/${subcategoryId}`;

export const getReportUrl = () => `${API_SIS_BASE}/report`;

export const getReportById = (reportId) => `${API_SIS_BASE}/report/${reportId}`;

export const getReportSendUrl = (reportId) =>
  `${API_SIS_BASE}/report/${reportId}/send`;

export const getReportGenerateUrl = (reportId) =>
  `${API_SIS_BASE}/report/${reportId}/generate`;

export const getReportDownloadUrl = (reportId) =>
  `${API_SIS_BASE}/report/${reportId}/download-zip`;

export const getReportPreviewUrl = (reportId, userId) =>
  `${API_SIS_BASE}/report/${reportId}/preview/${userId}`;

export const getReportBatchTransaction = (batchId) =>
  `${API_SIS_BASE}/report-batch-transaction/batch/${batchId}`;

export const getReportSignatureUrl = () => `${API_SIS_BASE}/report-signature`;

export const getReportCategoryStatusUrl = () =>
  `${API_SIS_BASE}/report-category-status/change-status`;

export const getReportObservationUserUrl = () =>
  `${API_SIS_BASE}/report-observation-user`;

export const getReportItemUserUrl = () => `${API_SIS_BASE}/report-item-user`;
