import { useGetList } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { paySingleInvoice } from 'src/shared/services/url/UrlPaymentManagement';

const useInvoiceDownPayments = ({ invoiceId, ...options }) => {
  return useGetList(
    [QUERY_KEY.INVOICE_DOWN_PAYMENTS, invoiceId],
    paySingleInvoice(invoiceId),
    {
      expand: 'user',
      sort: '-paid_at',
    },
    true,
    false,
    options,
  );
};

export default useInvoiceDownPayments;
