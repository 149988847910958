import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Skeleton, Typography } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { t } from '@lingui/macro';

import ChipField from 'src/components/Form/ChipField';

const ChipsSelector = ({
  name,
  title,
  items = [],
  label,
  onChange,
  gridProps = { xs: 12 },
  isLoading = false,
  disableSelectAll,
  disableSelectMultiple,
  ...rest
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);
  const isCheckedAll = field.value?.length === items.length;

  const handleChange = (items) => {
    onChange(items);
  };

  const filtredItems = useMemo(() => {
    const allItem = {
      id: 'all',
      name: t`Todos`,
    };
    return !disableSelectAll && items.length > 1 ? [allItem, ...items] : items;
  }, [items, disableSelectAll]);

  const handleSelectedAdd = () => {
    const value = isCheckedAll ? [] : items;
    setFieldValue(name, value);
    handleChange(value);
  };

  return (
    <Grid item {...gridProps}>
      {title && (
        <Typography variant="h3" mb={2}>
          {title}
        </Typography>
      )}
      {isLoading ? (
        <Skeleton width={100} height={50} />
      ) : (
        <Grid container display={'flex'} flexDirection={'row'} spacing={1}>
          {filtredItems.map((item, index) => {
            return (
              <Grid item md={'auto'} key={index}>
                <ChipField
                  label={item.name}
                  name={name}
                  item={item}
                  onChange={handleChange}
                  selectedAll={handleSelectedAdd}
                  isCheckedAll={isCheckedAll}
                  disableSelectMultiple={disableSelectMultiple}
                  {...rest}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

ChipsSelector.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  items: PropTypes.array,
  onChange: PropTypes.func,
};

export default ChipsSelector;
