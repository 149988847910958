import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import {
  List,
  ListItemAvatar,
  Badge,
  ListItemSecondaryAction,
  CircularProgress,
  ListItemText,
  MenuItem,
  ListItemIcon,
  Box,
  ListItemButton,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import Icon from '@mdi/react';
import { Edit as EditIcon } from 'react-feather';

import GenericMoreButton from 'src/components/GenericMoreButton';
import InfiniteScroll from 'src/components/InfiniteScroll';
import { notificationsIcons } from './utils';

import useStyles from './styles';
import UserAvatar from 'src/components/UserAvatar';
import theme from 'src/theme';

const NotificationsList = ({
  notifications,
  onClickNotification,
  onMarkNotificationAsRead,
  pendingNotificationId,
  onLoadMore,
  loading,
  readLoading,
  canLoadMore,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <List {...rest}>
      <InfiniteScroll
        hasNextPage={canLoadMore}
        onLoadMore={onLoadMore}
        loading={loading}
        loadingComponent={
          <Box p={2} textAlign="center">
            <CircularProgress color="primary" size={25} />
          </Box>
        }
        endComponent={<></>}
      >
        {notifications.map(
          ({
            id,
            notification_type_id,
            viewed,
            text,
            creation_date,
            link,
            user,
          }) => {
            const icon = notificationsIcons[notification_type_id];
            const formattedCreationTime = dayjs
              .utc(creation_date)
              .local()
              .fromNow();

            return (
              <ListItemButton
                disabled={readLoading && id === pendingNotificationId}
                button
                key={id}
                sx={{ background: !viewed && theme.palette.grey['200'] }}
                onClick={() =>
                  onClickNotification({
                    id,
                    notification_type_id,
                    link,
                  })
                }
              >
                <ListItemAvatar>
                  <Badge
                    classes={{ badge: classes.largeBadge }}
                    badgeContent={
                      <Icon path={icon} size={0.6} className={classes.icon} />
                    }
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  >
                    <UserAvatar user={user} />
                  </Badge>
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      dangerouslySetInnerHTML={{
                        __html: text,
                      }}
                      sx={{
                        fontWeight: 'fontWeightMedium',
                        width: '90%',
                      }}
                    />
                  }
                  secondary={
                    <Typography variant="caption" color="textSecondary">
                      {formattedCreationTime}
                    </Typography>
                  }
                />

                {readLoading && id === pendingNotificationId && (
                  <ListItemSecondaryAction>
                    <CircularProgress color="primary" size={10} />
                  </ListItemSecondaryAction>
                )}

                {id !== pendingNotificationId && !viewed && (
                  <ListItemSecondaryAction>
                    <GenericMoreButton size="small">
                      <MenuItem
                        onClick={() => onMarkNotificationAsRead(id)}
                        disabled={readLoading}
                      >
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary={t`Marcar como leido`} />
                      </MenuItem>
                    </GenericMoreButton>
                  </ListItemSecondaryAction>
                )}
              </ListItemButton>
            );
          },
        )}
        {!canLoadMore && (
          <ListItemButton divider disabled>
            <ListItemText
              primary={t`No hay mas notificaciones para cargar`}
              primaryTypographyProps={{
                variant: 'subtitle2',
                color: 'textPrimary',
                align: 'center',
              }}
            />
          </ListItemButton>
        )}
      </InfiniteScroll>
    </List>
  );
};

NotificationsList.propTypes = {
  notifications: PropTypes.array.isRequired,
  pendingNotificationId: PropTypes.number,
  readLoading: PropTypes.bool.isRequired,
  canLoadMore: PropTypes.bool.isRequired,
  onClickNotification: PropTypes.func.isRequired,
  onMarkNotificationAsRead: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default NotificationsList;
