import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from 'react-error-boundary';

import FallbackComponent from './Fallback';

const MainErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary>
      <ErrorBoundary
        FallbackComponent={({ error }) => {
          console.error(error.stack);
          return <FallbackComponent error={error} />;
        }}
      >
        {children}
      </ErrorBoundary>
    </Sentry.ErrorBoundary>
  );
};

MainErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
};

export default MainErrorBoundary;
