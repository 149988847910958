import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { t, Trans } from '@lingui/macro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormikContext } from 'formik';

import addOrRemove from 'src/shared/utils/addOrRemove';
import Table from 'src/components/Table';
import { columns } from './helpers';
import useStyles from './styles';

const CheckSelectorExpandable = ({
  name,
  title,
  items = [],
  selectedItems: selectedExternalItems = [],
  isDisabled,
  checkPosition = 'end',
  gridProps = { xs: 12 },
  tableHeight = 130,
  enableChecks = true,
  ...rest
}) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext({});
  const [expand, setExpand] = useState(false);
  const [selectedItems, selectedItemsExternal] = useState(
    selectedExternalItems,
  );
  const itemsWithAll = [{ name: t`Todos`, id: 'all' }, ...items];

  const formattedItems = selectedExternalItems
    ?.map((div) => div.name)
    .join(', ');

  const handleExpand = (event) => {
    event.stopPropagation();
    if (isDisabled) return;
    setExpand((prev) => !prev);
  };

  const handleSelectAll = () => {
    selectedItemsExternal(itemsWithAll);
    setFieldValue(name, items);
  };

  const handleDeselectAll = () => {
    setFieldValue(name, []);
    selectedItemsExternal([]);
  };

  const onSelectItems = (itemsSelected, e, itemSelected) => {
    const isItemAllSelected = itemSelected.id === 'all';
    const isAllSelected =
      items.length === itemsSelected.filter((item) => item.id !== 'all').length;

    // selecciona all
    if (isItemAllSelected) {
      if (isAllSelected) {
        // se los deselecciona
        handleDeselectAll();
      } else {
        // se seleccionan todos
        handleSelectAll();
      }
      return;
    }

    // si no estan todos seleccionados, se saca el all
    if (!isAllSelected) {
      selectedItemsExternal(itemsSelected.filter((item) => item.id !== 'all'));
      return setFieldValue(
        name,
        itemsSelected.filter((item) => item.id !== 'all'),
      );
    } else {
      // si se seleccionan todos, se selecciona el all
      selectedItemsExternal(itemsWithAll);
      return setFieldValue(name, items);
    }
  };

  const onClickItem = (item) => {
    if (!enableChecks) setExpand(false);
    const newItems = enableChecks ? addOrRemove(selectedItems, item) : [item];
    onSelectItems(newItems, null, item);
  };

  return (
    <ClickAwayListener onClickAway={() => setExpand(false)}>
      <Grid item {...gridProps}>
        {title && (
          <Typography variant="h3" mb={2}>
            {title}
          </Typography>
        )}
        <Grid
          container
          display={'flex'}
          direction={'row'}
          alignItems={'center'}
          paddingRight={1}
          paddingLeft={2}
          sx={{
            border: 'solid 1px',
            borderColor: 'grey.500',
            borderRadius: 1,
            opacity: isDisabled && 0.3,
            cursor: 'pointer',
          }}
        >
          <Grid
            item
            xs={10}
            paddingY={1.5}
            onClick={handleExpand}
            data-testid={`check-selector-${name}`}
          >
            <Grid container direction={'row'}>
              {selectedItems.length && !expand ? (
                <Typography>{formattedItems}</Typography>
              ) : (
                <Typography color={'text.disabled'}>
                  <Trans>Seleccionar...</Trans>
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2} container justifyContent={'flex-end'}>
            <IconButton
              onClick={handleExpand}
              disabled={isDisabled}
              fontSize="large"
              sx={{ height: 24, width: 24 }}
            >
              <ExpandMoreIcon
                sx={{
                  transform: expand ? 'rotate(-180deg)' : 'rotate(0)',
                  transition: '0.2s',
                }}
              />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Collapse
              in={Boolean(expand && !isDisabled)}
              timeout="auto"
              unmountOnExit
            >
              <Table
                className={classes.table}
                showEmptyTable
                enableChecks={enableChecks}
                elevation={0}
                hasTableHead={false}
                items={itemsWithAll}
                columns={columns}
                checkPosition={checkPosition}
                onSelectItems={onSelectItems}
                onClickItem={onClickItem}
                selectedItemsExternal={selectedItems}
                tableContainerProps={{
                  sx: {
                    height: tableHeight,
                  },
                }}
                {...rest}
              />
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
};
CheckSelectorExpandable.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  items: PropTypes.array,
  selectedItems: PropTypes.array,
  isDisabled: PropTypes.bool,
  checkPosition: PropTypes.string,
};

export default CheckSelectorExpandable;
