// THIS IS A TEMPORARY THING, SOON WE WILL MANAGE EVERYTHING ON REACT STATES, BUT MEANWHILE
// THERE IS LOCALSTORAGE ON THE LMS/SIS AND WE NEED TO CONNECT THEM SOMEHOW.
const BLENDED_STORAGE_KEYS = {
  SELECTED_PERIOD: 'bld-inst-per',
  SELECTED_INSTITUTION: 'bld-institution-info',
  FIRST_LOGIN: 'bld-first-login',
  LEGACY_AUTH: 'bld-auth',
  LEGACY_JWT: 'bld-jwt',
  COUNTRY: 'bld-country',
  REFRESH_FORCED: 'bld-forced-refresh',
  REFRESH_TOKEN: 'bld-refresh-jwt',
  INSTITUTION_COLOR: 'bld-color',
  INBOX_NEW_DM: 'bld-new-dm',
  INBOX_NEW_DM_TITLE: 'bld-new-dm-title',
};

export default BLENDED_STORAGE_KEYS;
