import { useSetAtom } from 'jotai';
import selectedConceptAtom from '../atoms/selectedConceptAtom';

const useSetSelectedConcept = () => {
  const setSelectedConcept = useSetAtom(selectedConceptAtom);

  return (concept) => setSelectedConcept(concept);
};

export default useSetSelectedConcept;
