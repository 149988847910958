import { atom } from 'jotai';

const calendarFiltersAtom = atom({
  filters: {
    date_from: undefined,
    date_to: undefined,
    level_id: [],
    division_id: [],
    children_id: [],
    subject_id: [],
  },
});

export default calendarFiltersAtom;
