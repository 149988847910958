import React, { useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  Divider,
  SvgIcon,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Skeleton,
} from '@mui/material';
import { Trans } from '@lingui/macro';
import {
  User as UserIcon,
  HelpCircle as HelpCircleIcon,
  LogOut as LogOutIcon,
  ChevronDown as ChevronDownIcon,
  Activity as ActivityIcon,
} from 'react-feather';
import { Link, useHistory } from 'react-router-dom';

import { formatUserRole } from 'src/shared/helpers';
import { ROLE_LIST } from 'src/config/general';
import ExternalLink from 'src/components/ExternalLink';
import UserAvatar from 'src/components/UserAvatar';
import { COUNTRY_CODE } from 'src/shared/constants/countryCode';
import { haveAccountStatusAccess } from 'src/features/billing-statement/helpers';
import useUserCustomRole from 'src/features/my-account/hooks/data/custom_role/useUserCustomRole';
import ConditionalWrapper from 'src/components/ConditionalWrapper';

import useStyles from './styles';
import router from 'src/shared/utils/router';

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { userData, userIs } = useSelector(({ user }) => ({
    userIs: user.userIs,
    userData: user.data,
  }));

  const userCountry = userData?.country?.code;
  const accountStatusAccess = useMemo(
    () =>
      userCountry?.toLocaleLowerCase() !== COUNTRY_CODE.ARGENTINA &&
      haveAccountStatusAccess(),
    [userCountry],
  );
  const { isLoading: isLoadingCustomRole, data } = useUserCustomRole({
    userId: userData.id,
  });
  const { custom_role } = data ?? {};

  const highestRole = Object.values(ROLE_LIST).find(
    (roleKey) => userIs[roleKey] === true,
  );
  const genre = userData?.genre_id;
  const displayRole = formatUserRole(highestRole, false, genre);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogOut = async () => {
    history.push(router.auth.logout);
  };

  return (
    <>
      <Box
        className={clsx(classes.root, 'account-menu-tour-item')}
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <UserAvatar user={userData} className={classes.avatar} />
        <Hidden mdDown>
          <Box className={classes.accountInfo}>
            <Tooltip title={userData?.name} placement="bottom" arrow>
              <Typography
                variant="h3"
                color="textPrimary"
                className={classes.accountName}
              >
                {userData?.name}
              </Typography>
            </Tooltip>
            <ConditionalWrapper
              condition={isLoadingCustomRole}
              wrapper={(children) => <Skeleton>{children}</Skeleton>}
            >
              <Typography variant="body2" color="textSecondary">
                {custom_role ?? displayRole}
              </Typography>
            </ConditionalWrapper>
          </Box>
        </Hidden>
        <SvgIcon>
          <ChevronDownIcon />
        </SvgIcon>
      </Box>

      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        slotProps={{
          paper: {
            className: classes.popover,
          },
        }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={Link} to={router.me.account}>
          <ListItemIcon className={classes.listItemIcon}>
            <UserIcon />
          </ListItemIcon>
          <ListItemText className={classes.listItemText}>
            <Trans>Mi cuenta</Trans>
          </ListItemText>
        </MenuItem>

        <Divider />

        {accountStatusAccess && (
          <MenuItem component={Link} to={router.billingStatement.root}>
            <ListItemIcon className={classes.listItemIcon}>
              <ActivityIcon />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              <Trans>Estado de cuenta</Trans>
            </ListItemText>
          </MenuItem>
        )}

        <MenuItem
          component={ExternalLink}
          target="_blank"
          href={router.external.help}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <HelpCircleIcon />
          </ListItemIcon>
          <ListItemText className={classes.listItemText}>
            <Trans>Ayuda</Trans>
          </ListItemText>
        </MenuItem>

        <Divider />

        <MenuItem component={ExternalLink} onClick={handleLogOut}>
          <ListItemIcon className={classes.listItemIcon}>
            <LogOutIcon />
          </ListItemIcon>
          <ListItemText className={classes.listItemText}>
            <Trans>Cerrar sesión</Trans>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
