import { useState, useMemo, useCallback } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { PaymentGenerateBillsSchemaMx } from 'src/features/payment-management/forms/PaymentGenerateBillsForm/MX/helpers';
import { COUNTRY_CODE } from 'src/shared/constants/countryCode';
import queryClient from 'src/config/query';
import {
  selectedBillingInvoicesAtom,
  lastProcessedBillingAtom,
} from 'src/features/payment-management/atoms';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';
import useFamilyTaxInformation from '../data/useFamilyTaxInformation';
import useGetInstitutionTaxInformation from '../data/useGetInstitutionTaxInformation';
import useCreateTaxBill from '../data/useCreateTaxBill';
import { STATUS_GENERATE_INVOICES } from '../../helpers';
import {
  formattedDataMX,
  formattedDataAR,
  getSelectedPaymentMethod,
  formattedDataCol,
} from './formattedData';
import PaymentGenerateBillsSchemaArg from '../../forms/PaymentGenerateBillsForm/ARG/schema';
import { QUERY_KEY } from '../../constants/queryKey';
import useInvoiceDownPayments from '../data/invoices/useInvoiceDownPayments';
import usePaymentMethod from '../data/usePaymentMethod';
import useUserInfo from '../useUserInfo';
import { DINING_HALL } from '../../forms/PaymentGenerateBillsForm/ARG/helpers';
import selectedAllBillingInvoicesAtom from '../../atoms/selectedAllBillingInvoicesAtom';
import router from 'src/shared/utils/router';

const usePaymentGenerateBillsForm = (countryCode) => {
  const history = useHistory();
  const { selectedInstitution } = useUserInfo();
  const setBillingProcessed = useSetAtom(lastProcessedBillingAtom);
  const { enqueueSnackbar } = useSnackbar();
  const invoices = useAtomValue(selectedBillingInvoicesAtom);
  const selectedAllBilingInvoices = useAtomValue(
    selectedAllBillingInvoicesAtom,
  );
  const selectedBillingInvoices = useSetAtom(selectedBillingInvoicesAtom);
  const invoicesLength = selectedAllBilingInvoices.allSelected
    ? selectedAllBilingInvoices.count
    : invoices?.length;
  const isBatch = invoicesLength > 1;
  const familyName = invoices?.length === 1 && invoices[0].family?.name;
  const [switchForm, setSwitchForm] = useState(false);
  const familyId = invoices?.map((invoice) => invoice.family_id);

  const { mutateAsync: createTaxBill } = useCreateTaxBill(isBatch);

  const { data: invoiceDownPayments } = useInvoiceDownPayments({
    invoiceId: invoices[0]?.id,
    enabled: invoices?.length === 1,
  });

  const { data: paymentMethods = [] } = usePaymentMethod();

  const paymentMethodsIds = useMemo(
    () =>
      (invoiceDownPayments ?? []).map(
        ({ payment_method_id }) => payment_method_id,
      ),
    [invoiceDownPayments],
  );

  const selectedPaymentMethod = useMemo(
    () => getSelectedPaymentMethod(paymentMethods, paymentMethodsIds?.[0]),
    [paymentMethods, paymentMethodsIds],
  );

  const { data: institutionTaxInformation, isLoading: isLoadingInstitution } =
    useGetInstitutionTaxInformation();

  const { data: familyTaxInformationArray, isLoading: isLoadingFamily } =
    useFamilyTaxInformation({ familyId });

  const familyTaxInformation = useMemo(() => {
    if (Array.isArray(familyTaxInformationArray)) {
      // eslint-disable-next-line no-shadow
      const familyTaxInformation = familyTaxInformationArray.filter(
        (item) => item.is_responsible && item,
      );
      return familyTaxInformation[0] || null;
    }

    return null;
  }, [familyTaxInformationArray]);

  const isLoading = useMemo(() => {
    return isLoadingFamily || isLoadingInstitution;
  }, [isLoadingFamily, isLoadingInstitution]);

  const handleSubmit = async (values) => {
    const body = {
      ...values,
      invoices,
      countryCode,
      invoice_all: selectedAllBilingInvoices.allSelected,
    };

    return createTaxBill(body, {
      onSuccess: ({ data }) => {
        const { status, id: batchId } = data ?? {};
        if (status === STATUS_GENERATE_INVOICES.TO_PROCESS) {
          setBillingProcessed({ batchId, invoices });
          history.push(router.collections.generatingBilling(batchId));
        } else {
          enqueueSnackbar(t`Factura emitida correctamente`, {
            variant: 'success',
          });

          setTimeout(() => {
            history.push(router.collections.billing);
          }, 2000);
        }
      },
      onSettled: () => {
        selectedBillingInvoices([]);
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.INVOICES],
        });
      },
    });
  };

  const onGoBack = () => {
    selectedBillingInvoices([]);
    history.push(router.collections.billing);
  };

  const initialValuesIndivdualMex = useCallback(() => {
    const values = formattedDataMX(
      familyTaxInformation,
      institutionTaxInformation,
      invoices,
      switchForm,
    );
    return {
      ...values,
      wayToPay: selectedPaymentMethod ?? null,
    };
  }, [
    familyTaxInformation,
    institutionTaxInformation,
    invoices,
    selectedPaymentMethod,
    switchForm,
  ]);

  const initialValuesIndivdualArg = useCallback(() => {
    const initialVal = formattedDataAR(
      familyTaxInformation,
      institutionTaxInformation,
      invoices,
      isBatch,
    );

    if (selectedInstitution.id === DINING_HALL.id) {
      return {
        ...initialVal,
        productDescription: DINING_HALL.description,
      };
    }

    return initialVal;
  }, [
    familyTaxInformation,
    institutionTaxInformation,
    invoices,
    isBatch,
    selectedInstitution,
  ]);

  const initialValuesIndivdualCol = useCallback(() => {
    return formattedDataCol(
      familyTaxInformation,
      institutionTaxInformation,
      invoices,
      isBatch,
    );
  }, [familyTaxInformation, institutionTaxInformation, invoices, isBatch]);

  const disabledByCountry = useCallback(
    (isFormValid) => {
      switch (countryCode.toLocaleLowerCase()) {
        case COUNTRY_CODE.ARGENTINA || COUNTRY_CODE.COLOMBIA:
          return !isFormValid;
        case COUNTRY_CODE.MEXICO:
          return !isFormValid && !isBatch;
        default:
          return null;
      }
    },
    [isBatch, countryCode],
  );

  const validationSchema = useMemo(() => {
    switch (countryCode.toLocaleLowerCase()) {
      case COUNTRY_CODE.ARGENTINA:
        return PaymentGenerateBillsSchemaArg;
      case COUNTRY_CODE.MEXICO:
        if (!isBatch) {
          return PaymentGenerateBillsSchemaMx;
        }
        return null;
      default:
        return null;
    }
  }, [countryCode, isBatch]);

  const initialValues = useMemo(() => {
    switch (countryCode.toLocaleLowerCase()) {
      case COUNTRY_CODE.ARGENTINA:
        return initialValuesIndivdualArg();
      case COUNTRY_CODE.MEXICO:
        return initialValuesIndivdualMex();
      case COUNTRY_CODE.COLOMBIA:
        return initialValuesIndivdualCol();
      default:
        return {};
    }
  }, [
    countryCode,
    initialValuesIndivdualMex,
    initialValuesIndivdualArg,
    initialValuesIndivdualCol,
  ]);

  return {
    initialValues,
    validationSchema,
    switchForm,
    isBatch,
    familyName,
    familyId,
    invoicesLength,
    isLoading,
    handleSubmit,
    setSwitchForm,
    onGoBack,
    disabledByCountry,
  };
};

export default usePaymentGenerateBillsForm;
