import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import useStyles from './styles';

const ListWeekHeader = ({ header }) => {
  const classes = useStyles();
  const { date } = header;
  const day = dayjs(date);

  return (
    <Box display="flex">
      <div className={classes.capitalize}>{day.format('dddd')}</div>
      <Box ml="auto">{day.format('DD MMMM, YYYY')}</Box>
    </Box>
  );
};

ListWeekHeader.propTypes = {
  header: PropTypes.object,
};

export default ListWeekHeader;
