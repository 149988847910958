import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';
import { Api as api } from 'src/shared/services/api';

import { paymentManagementSingleRate } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import ratesSerializer from 'src/features/payment-management/helpers/ratesSerializer';

const SUCCESS_MESSAGE = t`Concepto actualizado con éxito.`;
const ERROR_MESSAGE = t`Hubo un error al intentar actualizar el concepto.`;

const useUpdateRate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { serialize } = ratesSerializer;

  const updateRateMutation = (data) =>
    api.patch({
      url: paymentManagementSingleRate(data.id),
      data: serialize(data),
    });

  return useMutation({
    mutationFn: updateRateMutation,
    onSuccess: () => enqueueSnackbar(SUCCESS_MESSAGE, { variant: 'success' }),
    onError: ({ message }) => {
      enqueueSnackbar(message ?? ERROR_MESSAGE, {
        variant: 'error',
      });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.RATES],
        refetchType: 'all',
      }),
  });
};

export default useUpdateRate;
