import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';

import usePeriodSelector from '../usePeriodSelector';
import useLinkSelector from '../useLinkSelector';
import useInstitutionSelector from '../useInstitutionSelector';

const useInstitutionConfig = () => {
  const { selectedInstitution } = useSelector(({ user }) => user);
  const institutions = useInstitutionSelector();
  const period = usePeriodSelector();
  const links = useLinkSelector();

  const config = {
    // En test la institucion matchea con el id de autorizaciones
    // Por eso agrego una letra para que no matchee
    id: `${selectedInstitution.id}b`,
    subheader: selectedInstitution.name,
    icon: (
      <Avatar
        src={selectedInstitution.icon_url}
        sx={{
          width: 32,
          height: 32,
        }}
      />
    ),
    items:
      institutions.length > 1
        ? [...institutions, period, links]
        : [period, links],
    sx: {
      px: 1,
    },
  };

  return config;
};

export default useInstitutionConfig;
