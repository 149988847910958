const useZendeskWidget = () => {
  const hackZendeskWidget = () => {
    const maxHackAttempts = 36;
    let hackAttempt = 0;

    const hackWidget = setInterval(() => {
      if (hackAttempt > maxHackAttempts) clearInterval(hackWidget);

      const zendeskWidget = document.querySelector('#launcher');

      if (zendeskWidget) {
        zendeskWidget.style.zIndex = 1;
        const zendeskWidgetDocument = zendeskWidget.contentWindow.document;

        if (zendeskWidgetDocument.readyState === 'complete') {
          const launcherButton = zendeskWidgetDocument.querySelector('button');
          if (launcherButton) {
            launcherButton.style.padding = 8;

            const launcherButtonIcon =
              launcherButton.querySelectorAll('span')[0];
            launcherButtonIcon.style.paddingRight = 0;

            const launcherButtonText =
              launcherButton.querySelectorAll('span')[1];
            launcherButtonText.style.cssText = 'display: none !important';

            clearInterval(hackWidget);
          }
        }
      }

      hackAttempt += 1;
    }, 300);
  };

  return {
    hackZendeskWidget,
  };
};

export default useZendeskWidget;
