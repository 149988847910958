import { atomWithStorage, createJSONStorage } from 'jotai/utils';

const storage = createJSONStorage(() => localStorage);
const lastProcessedBillingAtom = atomWithStorage(
  'processing-billing',
  null,
  storage,
);
lastProcessedBillingAtom.debugLabel = 'lastProcessedBillingAtom';

export default lastProcessedBillingAtom;
