import { useMutation } from '@tanstack/react-query';
import { useErrorMessage } from '../common/useErrorMessage';
import Api from '../../api';
import abstractSerializer from '../serializer/abstractSerializer';

export const useUpdate = (
  url,
  queryParams = {},
  defaultError = true,
  customSerializer,
) => {
  const withErrorMessage = useErrorMessage();
  const { serialize } = customSerializer || abstractSerializer();

  const apiPatch = ({ allowNull, ...params } = {}) =>
    Api.patch({
      url,
      data: serialize(params || {}),
      allowNull: allowNull,
    });

  const mutationFunction = defaultError ? withErrorMessage(apiPatch) : apiPatch;

  return useMutation(mutationFunction, queryParams);
};
