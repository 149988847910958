import { atom } from 'jotai';
import calendarViewTypeAtom from './calendarViewTypeAtom';
import calendarAtom from './calendarAtom';

const updateCalendarViewTypeAtom = atom(null, (get, set) => {
  const calendar = get(calendarAtom);
  const viewType = calendar?.view?.type ?? '';

  set(calendarViewTypeAtom, viewType);
});

export default updateCalendarViewTypeAtom;
