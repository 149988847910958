import { useGetOne } from 'src/shared/services/api/query';
import { batchDeleteRateUsers } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const useGetBatchDeleteRateUsers = ({
  batchDeleteRateUsersId,
  queryParams,
}) => {
  return useGetOne(
    [QUERY_KEY.BATCH_DELETE_RATE_USERS, batchDeleteRateUsersId],
    batchDeleteRateUsers(batchDeleteRateUsersId),
    {},
    true,
    false,
    { ...queryParams },
  );
};

export default useGetBatchDeleteRateUsers;
