import { QUERY_KEY } from 'src/features/my-account/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import { attendanceTotalUrl } from 'src/shared/services/url/UrlSis';

const useStudentAttendanceTotal = (params) => {
  const enabled =
    Boolean(params.user_id) && Boolean(params.institution_period_id);

  return useGetOne(
    [QUERY_KEY.STUDENT_ATTENDANCE_TOTAL, params],
    attendanceTotalUrl(),
    {
      ...params,
    },
    false,
    false,
    {
      enabled,
      ...(!enabled && { cacheTime: 0 }),
    },
  );
};

export default useStudentAttendanceTotal;
