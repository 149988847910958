import { Trans } from '@lingui/macro';

import { BLENDED_MODULES } from 'src/config/constants';
import { ROLE_LIST } from 'src/config/general';
import router from 'src/shared/utils/router';

export const MANAGEMENT_ITEMS = [
  {
    id: BLENDED_MODULES.ATTENDANCE.id,
    title: <Trans>Asistencias</Trans>,
    href: router.integrations.attendance.root,
    roles: [
      ROLE_LIST.ADMIN,
      ROLE_LIST.PRECEPTOR,
      ROLE_LIST.EXECUTIVE,
      ROLE_LIST.TEACHER,
    ],
  },
  {
    id: BLENDED_MODULES.GRADING.id,
    title: <Trans>Calificaciones</Trans>,
    href: router.integrations.grading.general,
    roles: [
      ROLE_LIST.ADMIN,
      ROLE_LIST.PRECEPTOR,
      ROLE_LIST.EXECUTIVE,
      ROLE_LIST.TEACHER,
    ],
  },
  {
    id: BLENDED_MODULES.VIRTUAL_CLASSROOM.id,
    title: <Trans>Tareas</Trans>,
    href: router.integrations.virtualClassroom.root,
    roles: [
      ROLE_LIST.ADMIN,
      ROLE_LIST.PRECEPTOR,
      ROLE_LIST.EXECUTIVE,
      ROLE_LIST.TEACHER,
      ROLE_LIST.PARENT,
      ROLE_LIST.STUDENT,
    ],
  },
];
