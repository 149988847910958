import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontSize: '14px !important',
    color: theme.palette.text.primary,
  },
  content: {
    color: theme.palette.text.secondary,
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      textDecoration: 'none',
    },
  },
  subheader: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  action: {
    minWidth: 20,
    marginTop: theme.spacing(-1),

    '& svg': {
      width: 20,
    },
  },

  actionTooltip: {
    marginLeft: theme.spacing(3),
    color: theme.palette.text.secondary,
    width: 20,
  },
}));

export default useStyles;
