import React from 'react';
import { Trans } from '@lingui/macro';
import { ROLE_LIST } from 'src/config/general';
import router from 'src/shared/utils/router';

const ITEM_TAX_INFORMATION_ID = 'taxInformation';

const navConfigMyAccount = [
  {
    id: 'my-account',
    title: <Trans>MI CUENTA</Trans>,
    items: [
      {
        id: 'profile',
        title: <Trans>Perfil</Trans>,
        selectedCondition: '/mi-cuenta',
        href: router.me.account,
      },
      {
        id: 'password',
        title: <Trans>Contraseñas</Trans>,
        selectedCondition: '/mi-cuenta/contrasena',
        href: router.me.password,
      },
      {
        id: ITEM_TAX_INFORMATION_ID,
        title: <Trans>Datos de facturación</Trans>,
        selectedCondition: '/mi-cuenta/datos-facturacion',
        href: router.me.taxInformation,
        roles: [ROLE_LIST.PARENT],
      },
      {
        id: 'notifications',
        title: <Trans>Notificaciones</Trans>,
        selectedCondition: '/mi-cuenta/notificaciones',
        href: router.me.notifications,
      },
      {
        id: 'language',
        title: <Trans>Idioma</Trans>,
        selectedCondition: '/mi-cuenta/language',
        href: router.me.language,
      },
    ],
  },
];

export { navConfigMyAccount, ITEM_TAX_INFORMATION_ID };
