import { useMediaQuery } from '@mui/material';

import { slim, mobile, desktop, wide } from 'src/shared/utils/breakpoints';

const useBreakpoints = () => ({
  isSlim: useMediaQuery(slim),
  isMobile: useMediaQuery(mobile),
  isDesktop: useMediaQuery(desktop),
  isWide: useMediaQuery(wide),
});

export default useBreakpoints;
