import React from 'react';
import PropTypes from 'prop-types';
import EventResultRow from './EventResultRow';
import ResultLabel from '../ResultLabel';

const EventResult = ({ eventRows, showLabel }) => (
  <>
    {showLabel && <ResultLabel text="Eventos" />}
    {eventRows?.map((event) => (
      <EventResultRow key={event.id} event={event} />
    ))}
  </>
);

EventResult.propTypes = {
  eventRows: PropTypes.array,
  showLabel: PropTypes.bool,
};

export default EventResult;
