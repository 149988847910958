import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, Box, Link, Skeleton } from '@mui/material';

import useStyles from './styles';
import GoBackButton from 'src/components/Page/GoBackButton';
import theme from 'src/theme';

const PageBase = forwardRef(
  (
    {
      metadata = {},
      title,
      description,
      children,
      className,
      onGoBack,
      goBackLabel,
      goBackIcon,
      breadcrumb,
      loading,
      actions,
      ...rest
    },
    ref,
  ) => {
    const classes = useStyles();

    return (
      <Box ref={ref} {...rest} className={clsx(className, classes.root)}>
        {(onGoBack || title) && (
          <Box
            className={classes.head}
            style={{ paddingBottom: description ? 16 : 0 }}
          >
            <Box display="flex" gap={2} flexGrow={1} alignItems="center">
              {onGoBack && (
                <GoBackButton
                  onGoBack={onGoBack}
                  goBackIcon={goBackIcon}
                  goBackLabel={goBackLabel}
                />
              )}
              <Box display="flex" flexGrow={1} flexDirection="column">
                {loading ? (
                  <Skeleton height={30} width={250} className={classes.title} />
                ) : (
                  <>
                    {breadcrumb && (
                      <Box display="inline-block">
                        <Link href={breadcrumb.href} underline="hover">
                          {breadcrumb.name}
                        </Link>
                      </Box>
                    )}
                    {title && typeof title === 'string' ? (
                      <Typography variant="h1">{title}</Typography>
                    ) : (
                      title
                    )}
                    {description && (
                      <Typography variant="h4" className={classes.description}>
                        {description}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
              {!loading && actions && <Box>{actions}</Box>}
            </Box>
          </Box>
        )}
        {children}
      </Box>
    );
  },
);

PageBase.displayName = 'Page';

PageBase.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
  metadata: PropTypes.object,
  className: PropTypes.string,
  onGoBack: PropTypes.func,
  goBackLabel: PropTypes.string,
  goBackIcon: PropTypes.node,
  actions: PropTypes.node,
  breadcrumb: PropTypes.shape({
    name: PropTypes.string,
    href: PropTypes.string,
  }),
};

export default PageBase;
