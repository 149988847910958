import dayjs from 'dayjs';
import { t } from '@lingui/macro';

import { INVOICE_STATUS } from 'src/config/constants';
import { ROLE_LIST } from 'src/config/general';

const FILTER_PAYMENTS_OVERVIEW_ROLES = [
  ROLE_LIST.ADMIN,
  ROLE_LIST.FINANCIAL_ADMIN,
];

const INVOICE_STATUS_OPTIONS = () => [
  {
    id: INVOICE_STATUS.PAID,
    name: t`Pagados`,
    value: INVOICE_STATUS.PAID,
    className: 'paid',
  },
  {
    id: INVOICE_STATUS.PENDING,
    name: t`Pendientes`,
    value: INVOICE_STATUS.PENDING,
    className: 'pending',
  },
  {
    id: INVOICE_STATUS.DUE,
    name: t`Vencidos`,
    value: INVOICE_STATUS.DUE,
    className: 'due',
  },
  {
    id: INVOICE_STATUS.TO_REVIEW,
    name: t`Informados`,
    titleCard: t`Pagos pendientes de aprobación`,
    value: INVOICE_STATUS.TO_REVIEW,
    className: 'to_review',
  },
];

const DEFAULT_DATE_RANGE = [dayjs().startOf('year'), dayjs().endOf('year')];

export {
  FILTER_PAYMENTS_OVERVIEW_ROLES,
  INVOICE_STATUS_OPTIONS,
  DEFAULT_DATE_RANGE,
};
