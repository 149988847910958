import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  Switch as MuiSwitchField,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { Field as FormikField } from 'formik';

import useStyles from './styles';

const SwitchField = ({
  gridProps = { xs: 12 },
  name,
  label = '',
  disabled,
  labelPlacement = 'start',
  helperText,
  onChange,
  ...rest
}) => {
  const classes = useStyles();

  const handleSwitchChange = (event, field) => {
    const value = event.target.checked;
    if (onChange) onChange(event);
    field.onChange({
      target: {
        name: field.name,
        value,
      },
    });
  };

  return (
    <Grid item {...gridProps}>
      <FormikField name={name}>
        {({ field, form }) => (
          <FormControl
            component="fieldset"
            sx={{ display: 'flex', alignItems: 'baseline' }}
          >
            <FormControlLabel
              classes={{
                labelPlacementStart: classes.MuiLabelPlacementStart,
                label: classes.MuiLabel,
              }}
              label={label}
              labelPlacement={labelPlacement}
              control={
                <MuiSwitchField
                  {...field}
                  disabled={form.isSubmitting || disabled}
                  checked={Boolean(field.value)}
                  onChange={(event) => handleSwitchChange(event, field)}
                  {...rest}
                />
              }
            />
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
        )}
      </FormikField>
    </Grid>
  );
};

SwitchField.propTypes = {
  gridProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  labelPlacement: PropTypes.oneOf(['top', 'start', 'bottom', 'end']),
  onChange: PropTypes.func,
};

export default SwitchField;
