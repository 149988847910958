import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { usePut } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';
import { updateScheduledConversation } from 'src/shared/services/url/UrlCommunication';
import useScheduledConversationDetail from 'src/features/messages/hooks/useScheduledConversationDetail';

const path = '/nuevo';

const useUpdateScheduled = (conversationId) => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { resetScheduled } = useScheduledConversationDetail();

  return usePut(
    updateScheduledConversation(conversationId),
    {
      onSuccess: async () => {
        enqueueSnackbar(t`Mensaje actualizado con éxito.`, {
          variant: 'success',
        });
        queryClient.refetchQueries({
          queryKey: [QUERY_KEY.SCHEDULED_DETAIL, `${conversationId}`],
        });
        queryClient.refetchQueries({
          queryKey: [QUERY_KEY.SCHEDULED_CONVERSATIONS_LIST],
        });
        resetScheduled();
        if (location.pathname.includes(path)) history.goBack();
      },
      onError: (error) => {
        enqueueSnackbar(
          error.message || t`Hubo un error al programar su mensaje.`,
          {
            variant: 'error',
          },
        );
      },
    },
    false,
  );
};

export default useUpdateScheduled;
