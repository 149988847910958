import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import {
  createTaxBillArgUrl,
  createTaxBillUrl,
} from 'src/shared/services/url/UrlPaymentManagement';
import { useCreate } from 'src/shared/services/api/query';
import taxBillGenerateSerializer from '../../helpers/taxBillGenerateSerializer';
import useIsArgentina from 'src/shared/hooks/useIsArgentina';

const ERROR_MESSAGE = t`Hubo un error al intentar crear los datos de facturación`;

const useCreateTaxBill = (batch = false) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isArgentina } = useIsArgentina();
  const countryBasedUrl = isArgentina
    ? createTaxBillArgUrl()
    : createTaxBillUrl();

  const url = countryBasedUrl + (batch ? '/batch' : '');
  return useCreate(
    url,
    {
      onError: ({ message: errorMessage }) => {
        enqueueSnackbar(errorMessage ?? ERROR_MESSAGE, { variant: 'error' });
      },
    },
    false,
    taxBillGenerateSerializer,
  );
};

export default useCreateTaxBill;
