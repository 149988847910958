export const staggerListContainer = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.03,
    },
  },
  exit: { opacity: 0 },
};

export const staggerListItem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
  exit: { opacity: 0, y: 20 },
};
