import { useSelector } from 'react-redux';

import { useMemo } from 'react';

import useIntegrationsConfig from './useIntegrationsConfig';
import useCollectionsConfig from './useCollectionsConfig';
import useInstitutionConfig from './useInstitutionConfig';
import useChildrenConfig from './useChildrenConfig';
import useCommunicationConfig from './useCommunicationConfig';
import useSettingsConfig from './useSettingsConfig';
import useAiAssistantConfig from './useAiAssistantConfig';
import useRoutes from './useRoutes';

const filterSections = (sections) => {
  return sections.filter((section) => {
    if (!section) return false;

    return section.items.length > 0;
  });
};

const useNavbarSections = () => {
  const userIs = useSelector((store) => store.user.userIs);
  const { checkRouteAvailability } = useRoutes();

  const institutionConfig = useInstitutionConfig();
  const integrationsConfig = useIntegrationsConfig();
  const collectionsConfig = useCollectionsConfig();
  const childrenConfig = useChildrenConfig();
  const communicationConfig = useCommunicationConfig();
  const settingsConfig = useSettingsConfig();
  const aiAssistantConfig = useAiAssistantConfig();

  return useMemo(() => {
    const isParent = userIs.parent;
    const sections = [];

    sections.push(institutionConfig);
    sections.push(communicationConfig);

    if (checkRouteAvailability(collectionsConfig.name))
      sections.push(collectionsConfig);

    sections.push(integrationsConfig);

    if (isParent) sections.push(childrenConfig);

    if (checkRouteAvailability(settingsConfig.name))
      sections.push(settingsConfig);

    if (checkRouteAvailability(aiAssistantConfig.name))
      sections.push(aiAssistantConfig);

    return filterSections(sections);
  }, [
    userIs.parent,
    institutionConfig,
    communicationConfig,
    collectionsConfig,
    integrationsConfig,
    childrenConfig,
    checkRouteAvailability,
    settingsConfig,
    aiAssistantConfig,
  ]);
};

export default useNavbarSections;
