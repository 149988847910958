import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  table: {
    '& table:not([cellpadding]) th, table:not([cellpadding]) td': {
      padding: 0,
    },
    '& tr': {
      height: '30px',
      '&:last-child td, &:last-child th': { border: '0' },
      '& td, & th': {
        padding: '0px',
        borderBottom: 'none',
      },
      '& td:last-child': {
        textAlign: 'right',
      },
      '& .MuiCheckbox-root.Mui-checked': {
        color: theme.palette.secondary.main,
      },
    },
  },
}));

export default useStyles;
