import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  editorContainer: {
    position: 'relative',
    '& .tox-tinymce': {
      border: 0,
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderRadius: '4px 4px 0 0',
      // transition: 'height .3s',
      // TRANSITIONS SEEMS TO BE CAUSING DOM ERRORS ON RESIZE
    },
    '& .tox-edit-area': {
      padding: `${theme.spacing(1.5)} ${theme.spacing(0)}`,
    },
    '& .tox-toolbar__primary': {
      padding: `calc(${theme.spacing(1)} / 2) ${theme.spacing(2)} !important`,
      borderTop: `1px solid ${theme.palette.divider} !important`,
      background: 'none !important',
    },
    '& .tox .tox-tbtn--enabled': {
      backgroundColor: theme.palette.grey['50'],
      '& svg': {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
        '& path': {
          fill: 'transparent',
        },
      },
    },
    '& .tox .tox-tbtn': {
      '& svg': {
        color: theme.palette.grey['500'],
        fill: 'transparent',
        width: 16,
        height: 16,
        '& path': {
          width: 16,
          height: 16,
          fill: 'transparent',
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.grey['50'],
        color: theme.palette.primary.main,
        '& svg': {
          color: theme.palette.primary.main,
          fill: 'transparent',
        },
      },
      '&:active': {
        fill: theme.palette.primary.main,
        color: theme.palette.primary.main,
        '& svg': {
          fill: 'transparent !important',
        },
      },
    },
    '& .tox-sidebar-wrap': {
      // transition: 'margin-top .3s, padding-bottom .3s',
      // TRANSITIONS SEEMS TO BE CAUSING DOM ERRORS ON RESIZE
    },
    '& .tox .tox-toolbar-overlord': {
      backgroundColor: '#fff',
      zIndex: 1,
    },
    '& .tox-toolbar:first-child': {
      borderTop: '1px solid #ebebeb !important',
      padding: `${theme.spacing(0.5)} ${theme.spacing()}`,
      background: 'unset',
    },
  },
  slim: {
    '& .tox-sidebar-wrap': {
      paddingLeft: '0 !important',
    },
    '& .tox-edit-area': {
      padding: '0 !important',
    },
    '& .tox-edit-area__iframe': {
      backgroundColor: `${theme.palette.grey[50]} !important`,
    },
    '& .tox-editor-container': {
      flexDirection: 'row !important',
      borderBottom: 'none !important',
      borderTopLeftRadius: theme.shape.borderRadius * 2,
      borderBottomLeftRadius: theme.shape.borderRadius * 2,
      backgroundColor: `${theme.palette.grey[50]} !important`,
    },
    '& .tox-tinymce.tox-tinymce--toolbar-bottom': {
      height: '50px !important',
      borderBottom: 'none !important',
    },
    '& .tox-toolbar__primary': {
      paddingRight: '0 !important',
      borderTop: 'none !important',
      backgroundColor: `${theme.palette.grey[50]} !important`,
    },
  },
  openGraph: {
    position: 'absolute',
    bottom: 48,
    opacity: 0,
    zIndex: 0,
    minWidth: '94%',
  },
  openGraphVideo: {
    width: `calc(100% - ${theme.spacing(6)})`,
  },
  spinner: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 3,
  },
}));

export default useStyles;
