import { taxRegimeUrl } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { useGetPaginatedList } from 'src/shared/services/api/query';

const useTaxRegime = (countryCode) => {
  const params = { country_code: countryCode };

  return useGetPaginatedList(
    [QUERY_KEY.TAX_REGIME_OPTIONS, params],
    taxRegimeUrl(),
    params,
  );
};

export default useTaxRegime;
