import { paymentMethodUrl } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { useGetPaginatedList } from 'src/shared/services/api/query';

const usePaymentMethod = (params = {}) => {
  return useGetPaginatedList(
    [QUERY_KEY.PAYMENT_METHOD_OPTIONS, params],
    paymentMethodUrl(),
    { ...params },
  );
};

export default usePaymentMethod;
