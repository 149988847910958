import { useMutation } from '@tanstack/react-query';
import { uploadFileToAzure } from 'src/shared/services/uploadService';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

const ERROR_MESSAGE = t`Hubo un error al intentar subir el archivo.`;

const useUploadFileToAzure = () => {
  const { enqueueSnackbar } = useSnackbar();

  const updateUploadMutation = ({ tokenUrl, file }) =>
    uploadFileToAzure(tokenUrl, file);

  return useMutation({
    mutationFn: updateUploadMutation,
    onError: () => enqueueSnackbar(ERROR_MESSAGE, { variant: 'error' }),
  });
};

export default useUploadFileToAzure;
