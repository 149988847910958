window.tinymce.addI18n('de', {
  '#': '#',
  Accessibility: 'Barrierefreiheit',
  Accordion: '',
  'Accordion body...': '',
  'Accordion summary...': '',
  Action: 'Aktion',
  Activity: 'Aktivit\xe4t',
  Address: 'Adresse',
  Advanced: 'Erweitert',
  Align: 'Ausrichtung',
  'Align center': 'Zentrieren',
  'Align left': 'Linksb\xfcndig ausrichten',
  'Align right': 'Rechtsb\xfcndig ausrichten',
  Alignment: 'Ausrichtung',
  'Alignment {0}': '',
  All: 'Alle',
  'Alternative description': 'Alternative Beschreibung',
  'Alternative source': 'Alternative Quelle',
  'Alternative source URL': 'URL der alternativen Quelle',
  Anchor: 'Anker',
  'Anchor...': 'Textmarke',
  Anchors: 'Anker',
  'Animals and Nature': 'Tiere und Natur',
  Arrows: 'Pfeile',
  B: 'B',
  'Background color': 'Hintergrundfarbe',
  'Background color {0}': '',
  Black: 'Schwarz',
  Block: 'Blocksatz',
  'Block {0}': '',
  Blockquote: 'Blockzitat',
  Blocks: 'Bl\xf6cke',
  Blue: 'Blau',
  'Blue component': 'Blauanteil',
  Body: 'Inhalt',
  Bold: 'Fett',
  Border: 'Rahmen',
  'Border color': 'Rahmenfarbe',
  'Border style': 'Rahmenstil',
  'Border width': 'Rahmenbreite',
  Bottom: 'Unten',
  'Browse files': '',
  'Browse for an image': 'Bild...',
  'Browse links': '',
  'Bullet list': 'Aufz\xe4hlung',
  Cancel: 'Abbrechen',
  Caption: 'Beschriftung',
  Cell: 'Zelle',
  'Cell padding': 'Zelleninnenabstand',
  'Cell properties': 'Zelleigenschaften',
  'Cell spacing': 'Zellenabstand',
  'Cell styles': 'Zellstil',
  'Cell type': 'Zelltyp',
  Center: 'Zentriert',
  Characters: 'Zeichen',
  'Characters (no spaces)': 'Zeichen (ohne Leerzeichen)',
  Circle: 'Kreis',
  Class: 'Klasse',
  'Clear formatting': 'Formatierung entfernen',
  Close: 'Schlie\xdfen',
  Code: 'Code',
  'Code sample...': 'Codebeispiel...',
  'Code view': 'Code Ansicht',
  'Color Picker': 'Farbwahl',
  'Color swatch': 'Farbpalette',
  Cols: 'Spalten',
  Column: 'Spalte',
  'Column clipboard actions': 'Spalten-Zwischenablage-Aktionen',
  'Column group': 'Spaltengruppe',
  'Column header': 'Spaltenkopf',
  'Constrain proportions': 'Seitenverh\xe4ltnis beibehalten',
  Copy: 'Kopieren',
  'Copy column': 'Spalte kopieren',
  'Copy row': 'Zeile kopieren',
  'Could not find the specified string.':
    'Die angegebene Zeichenfolge wurde nicht gefunden.',
  'Could not load emojis': 'Emojis konnten nicht geladen werden',
  Count: 'Anzahl',
  Currency: 'W\xe4hrung',
  'Current window': 'Aktuelles Fenster',
  'Custom color': 'Benutzerdefinierte Farbe',
  'Custom...': 'Benutzerdefiniert...',
  Cut: 'Ausschneiden',
  'Cut column': 'Spalte ausschneiden',
  'Cut row': 'Zeile ausschneiden',
  'Dark Blue': 'Dunkelblau',
  'Dark Gray': 'Dunkelgrau',
  'Dark Green': 'Dunkelgr\xfcn',
  'Dark Orange': 'Dunkelorange',
  'Dark Purple': 'Dunkellila',
  'Dark Red': 'Dunkelrot',
  'Dark Turquoise': 'Dunkelt\xfcrkis',
  'Dark Yellow': 'Dunkelgelb',
  Dashed: 'Gestrichelt',
  'Date/time': 'Datum/Uhrzeit',
  'Decrease indent': 'Einzug verkleinern',
  Default: 'Standard',
  'Delete accordion': '',
  'Delete column': 'Spalte l\xf6schen',
  'Delete row': 'Zeile l\xf6schen',
  'Delete table': 'Tabelle l\xf6schen',
  Dimensions: 'Abmessungen',
  Disc: 'Scheibe',
  Div: 'Div',
  Document: 'Dokument',
  Dotted: 'Gepunktet',
  Double: 'Doppelt',
  'Drop an image here': 'Bild hier ablegen',
  'Dropped file type is not supported':
    'Hereingezogener Dateityp wird nicht unterst\xfctzt',
  Edit: 'Bearbeiten',
  Embed: 'Einbettung',
  Emojis: 'Emojis',
  'Emojis...': 'Emojis...',
  Error: 'Fehler',
  'Error: Form submit field collision.':
    'Fehler: Kollision der Formularbest\xe4tigungsfelder.',
  'Error: No form element found.': 'Fehler: Kein Formularelement gefunden.',
  'Extended Latin': 'Erweitertes Latein',
  'Failed to initialize plugin: {0}':
    'Plugin konnte nicht initialisiert werden: {0}',
  'Failed to load plugin url: {0}':
    'Plugin-URL konnte nicht geladen werden: {0}',
  'Failed to load plugin: {0} from url {1}':
    'Plugin konnte nicht geladen werden: {0} von URL {1}',
  'Failed to upload image: {0}': 'Bild konnte nicht hochgeladen werden: {0}',
  File: 'Datei',
  Find: 'Suchen',
  'Find (if searchreplace plugin activated)':
    'Suchen (wenn Suchen/Ersetzen-Plugin aktiviert ist)',
  'Find and Replace': 'Suchen und Ersetzen',
  'Find and replace...': 'Suchen und ersetzen...',
  'Find in selection': 'In Auswahl suchen',
  'Find whole words only': 'Nur ganze W\xf6rter suchen',
  Flags: 'Flaggen',
  'Focus to contextual toolbar': 'Fokus auf kontextbezogene Symbolleiste',
  'Focus to element path': 'Fokus auf Elementpfad',
  'Focus to menubar': 'Fokus auf Men\xfcleiste',
  'Focus to toolbar': 'Fokus auf Symbolleiste',
  Font: 'Schriftart',
  'Font size {0}': '',
  'Font sizes': 'Schriftgr\xf6\xdfen',
  'Font {0}': '',
  Fonts: 'Schriftarten',
  'Food and Drink': 'Essen und Trinken',
  Footer: 'Fu\xdfzeile',
  Format: 'Format',
  'Format {0}': '',
  Formats: 'Formate',
  Fullscreen: 'Vollbild',
  G: 'G',
  General: 'Allgemein',
  Gray: 'Grau',
  Green: 'Gr\xfcn',
  'Green component': 'Gr\xfcnanteil',
  Groove: 'Gekantet',
  'Handy Shortcuts': 'Praktische Tastenkombinationen',
  Header: 'Kopfzeile',
  'Header cell': 'Kopfzelle',
  'Heading 1': '\xdcberschrift 1',
  'Heading 2': '\xdcberschrift 2',
  'Heading 3': '\xdcberschrift 3',
  'Heading 4': '\xdcberschrift 4',
  'Heading 5': '\xdcberschrift 5',
  'Heading 6': '\xdcberschrift 6',
  Headings: '\xdcberschriften',
  Height: 'H\xf6he',
  Help: 'Hilfe',
  'Hex color code': 'Hexadezimal-Farbwert',
  Hidden: 'Unsichtbar',
  'Horizontal align': 'Horizontal ausrichten',
  'Horizontal line': 'Horizontale Linie',
  'Horizontal space': 'Horizontaler Raum',
  ID: 'ID',
  'ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
    'Die ID muss mit einem Buchstaben beginnen gefolgt von Buchstaben, Zahlen, Bindestrichen, Punkten, Doppelpunkten oder Unterstrichen.',
  'Image is decorative': 'Bild ist dekorativ',
  'Image list': 'Bildliste',
  'Image title': 'Bildtitel',
  'Image...': 'Bild...',
  'ImageProxy HTTP error: Could not find Image Proxy':
    'Image Proxy HTTP Fehler: Kann Image Proxy nicht finden',
  'ImageProxy HTTP error: Incorrect Image Proxy URL':
    'Image Proxy HTTP Fehler: Falsche Image Proxy URL',
  'ImageProxy HTTP error: Rejected request':
    'Image Proxy HTTP Fehler: Abgewiesene Anfrage',
  'ImageProxy HTTP error: Unknown ImageProxy error':
    'Image Proxy HTTP Fehler: Unbekannter Image Proxy Fehler',
  'Increase indent': 'Einzug vergr\xf6\xdfern',
  Inline: 'Zeichenformate',
  Insert: 'Einf\xfcgen',
  'Insert Template': 'Vorlage einf\xfcgen',
  'Insert accordion': '',
  'Insert column after': 'Neue Spalte danach einf\xfcgen',
  'Insert column before': 'Neue Spalte davor einf\xfcgen',
  'Insert date/time': 'Datum/Uhrzeit einf\xfcgen',
  'Insert image': 'Bild einf\xfcgen',
  'Insert link (if link plugin activated)':
    'Link einf\xfcgen (wenn Link-Plugin aktiviert ist)',
  'Insert row after': 'Neue Zeile danach einf\xfcgen',
  'Insert row before': 'Neue Zeile davor einf\xfcgen',
  'Insert table': 'Tabelle einf\xfcgen',
  'Insert template...': 'Vorlage einf\xfcgen...',
  'Insert video': 'Video einf\xfcgen',
  'Insert/Edit code sample': 'Codebeispiel einf\xfcgen/bearbeiten',
  'Insert/edit image': 'Bild einf\xfcgen/bearbeiten',
  'Insert/edit link': 'Link einf\xfcgen/bearbeiten',
  'Insert/edit media': 'Medien einf\xfcgen/bearbeiten',
  'Insert/edit video': 'Video einf\xfcgen/bearbeiten',
  Inset: 'Eingelassen',
  'Invalid hex color code: {0}': 'Ung\xfcltiger Hexadezimal-Farbwert: {0}',
  'Invalid input': 'Ung\xfcltige Eingabe',
  Italic: 'Kursiv',
  Justify: 'Blocksatz',
  'Keyboard Navigation': 'Tastaturnavigation',
  Language: 'Sprache',
  'Learn more...': 'Erfahren Sie mehr dazu...',
  Left: 'Links',
  'Left to right': 'Von links nach rechts',
  'Light Blue': 'Hellblau',
  'Light Gray': 'Hellgrau',
  'Light Green': 'Hellgr\xfcn',
  'Light Purple': 'Helllila',
  'Light Red': 'Hellrot',
  'Light Yellow': 'Hellgelb',
  'Line height': 'Liniendicke',
  'Link list': 'Linkliste',
  'Link...': 'Link...',
  'List Properties': 'Liste Eigenschaften',
  'List properties...': 'Liste Eigenschaften',
  'Loading emojis...': 'Lade Emojis...',
  'Loading...': 'Wird geladen...',
  'Lower Alpha': 'Lateinisches Alphabet in Kleinbuchstaben',
  'Lower Greek': 'Griechische Kleinbuchstaben',
  'Lower Roman': 'Kleiner r\xf6mischer Buchstabe',
  'Match case': 'Gro\xdf-/Kleinschreibung beachten',
  Mathematical: 'Mathematisch',
  'Media poster (Image URL)': 'Medienposter (Bild-URL)',
  'Media...': 'Medien...',
  'Medium Blue': 'Mittleres Blau',
  'Medium Gray': 'Mittelgrau',
  'Medium Purple': 'Mittelviolett',
  'Merge cells': 'Zellen verbinden',
  Middle: 'Mitte',
  'Midnight Blue': 'Mitternachtsblau',
  'More...': 'Mehr...',
  Name: 'Name',
  'Navy Blue': 'Marineblau',
  'New document': 'Neues Dokument',
  'New window': 'Neues Fenster',
  Next: 'N\xe4chste',
  No: 'Nein',
  'No alignment': 'Keine Ausrichtung',
  'No color': 'Keine Farbe',
  'Nonbreaking space': 'Gesch\xfctztes Leerzeichen',
  None: 'Keine',
  'Numbered list': 'Nummerierte Liste',
  OR: 'ODER',
  Objects: 'Objekte',
  Ok: 'Ok',
  'Open help dialog': 'Hilfe-Dialog \xf6ffnen',
  'Open link': 'Link \xf6ffnen',
  'Open link in...': 'Link \xf6ffnen in...',
  'Open popup menu for split buttons':
    '\xd6ffne Popup Menge um Buttons zu trennen',
  Orange: 'Orange',
  Outset: 'Hervorstehend',
  'Page break': 'Seitenumbruch',
  Paragraph: 'Absatz',
  Paste: 'Einf\xfcgen',
  'Paste as text': 'Als Text einf\xfcgen',
  'Paste column after': 'Spalte danach einf\xfcgen',
  'Paste column before': 'Spalte davor einf\xfcgen',
  'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
    'Einf\xfcgen ist nun im unformatierten Textmodus. Inhalte werden ab jetzt als unformatierter Text eingef\xfcgt, bis Sie diese Einstellung wieder deaktivieren.',
  'Paste or type a link': 'Link einf\xfcgen oder eingeben',
  'Paste row after': 'Zeile danach einf\xfcgen',
  'Paste row before': 'Zeile davor einf\xfcgen',
  'Paste your embed code below:':
    'F\xfcgen Sie Ihren Einbettungscode unten ein:',
  People: 'Menschen',
  Plugins: 'Plugins',
  'Plugins installed ({0}):': 'Installierte Plugins ({0}):',
  'Powered by {0}': 'Betrieben von {0}',
  Pre: 'Pre',
  Preferences: 'Einstellungen',
  Preformatted: 'Vorformatiert',
  'Premium plugins:': 'Premium-Plugins:',
  'Press the Up and Down arrow keys to resize the editor.': '',
  'Press the arrow keys to resize the editor.': '',
  'Press {0} for help': '',
  Preview: 'Vorschau',
  Previous: 'Vorherige',
  Print: 'Drucken',
  'Print...': 'Drucken...',
  Purple: 'Violett',
  Quotations: 'Anf\xfchrungszeichen',
  R: 'R',
  'Range 0 to 255': 'Spanne 0 bis 255',
  Red: 'Rot',
  'Red component': 'Rotanteil',
  Redo: 'Wiederholen',
  Remove: 'Entfernen',
  'Remove color': 'Farbauswahl aufheben',
  'Remove link': 'Link entfernen',
  Replace: 'Ersetzen',
  'Replace all': 'Alle ersetzen',
  'Replace with': 'Ersetzen durch',
  Resize: 'Skalieren',
  'Restore last draft': 'Letzten Entwurf wiederherstellen',
  'Reveal or hide additional toolbar items': '',
  'Rich Text Area': 'Rich-Text-Area',
  'Rich Text Area. Press ALT-0 for help.':
    'Rich-Text-Bereich. Dr\xfccken Sie Alt+0 f\xfcr Hilfe.',
  'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
    'Rich-Text-Bereich. Dr\xfccken Sie Alt+F9 f\xfcr das Men\xfc. Dr\xfccken Sie Alt+F10 f\xfcr die Symbolleiste. Dr\xfccken Sie Alt+0 f\xfcr Hilfe.',
  Ridge: 'Eingeritzt',
  Right: 'Rechts',
  'Right to left': 'Von rechts nach links',
  Row: 'Zeile',
  'Row clipboard actions': 'Zeilen-Zwischenablage-Aktionen',
  'Row group': 'Zeilengruppe',
  'Row header': 'Zeilenkopf',
  'Row properties': 'Zeileneigenschaften',
  'Row type': 'Zeilentyp',
  Rows: 'Zeilen',
  Save: 'Speichern',
  'Save (if save plugin activated)':
    'Speichern (wenn Save-Plugin aktiviert ist)',
  Scope: 'Bereich',
  Search: 'Suchen',
  'Select all': 'Alles ausw\xe4hlen',
  'Select...': 'Auswahl...',
  Selection: 'Auswahl',
  Shortcut: 'Tastenkombination',
  'Show blocks': 'Bl\xf6cke anzeigen',
  'Show caption': 'Beschriftung anzeigen',
  'Show invisible characters': 'Unsichtbare Zeichen anzeigen',
  Size: 'Schriftgr\xf6\xdfe',
  Solid: 'Durchgezogen',
  Source: 'Quelle',
  'Source code': 'Quellcode',
  'Special Character': 'Sonderzeichen',
  'Special character...': 'Sonderzeichen...',
  'Split cell': 'Zelle aufteilen',
  Square: 'Rechteck',
  'Start list at number': 'Beginne Liste mit Nummer',
  Strikethrough: 'Durchgestrichen',
  Style: 'Formatvorlage',
  Subscript: 'Tiefgestellt',
  Superscript: 'Hochgestellt',
  'Switch to or from fullscreen mode': 'Vollbildmodus umschalten',
  Symbols: 'Symbole',
  'System Font': 'Betriebssystemschriftart',
  Table: 'Tabelle',
  'Table caption': 'Tabellenbeschriftung',
  'Table properties': 'Tabelleneigenschaften',
  'Table styles': 'Tabellenstil',
  Template: 'Vorlage',
  Templates: 'Vorlagen',
  Text: 'Text',
  'Text color': 'Textfarbe',
  'Text color {0}': '',
  'Text to display': 'Anzuzeigender Text',
  'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
    'Diese URL scheint eine E-Mail-Adresse zu sein. M\xf6chten Sie das dazu ben\xf6tigte mailto: voranstellen?',
  'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
    'Diese URL scheint ein externer Link zu sein. M\xf6chten Sie das dazu ben\xf6tigte http:// voranstellen?',
  'The URL you entered seems to be an external link. Do you want to add the required https:// prefix?':
    'Die eingegebene URL scheint ein externer Link zu sein. Soll das fehlende https:// davor erg\xe4nzt werden?',
  Title: 'Titel',
  'To open the popup, press Shift+Enter':
    'Dr\xfccken Sie Umschalt+Eingabe, um das Popup-Fenster zu \xf6ffnen.',
  'Toggle accordion': '',
  Tools: 'Werkzeuge',
  Top: 'Oben',
  'Travel and Places': 'Reisen und Orte',
  Turquoise: 'T\xfcrkis',
  Underline: 'Unterstrichen',
  Undo: 'R\xfcckg\xe4ngig machen',
  Upload: 'Hochladen',
  'Uploading image': 'Bild wird hochgeladen',
  'Upper Alpha': 'Lateinisches Alphabet in Gro\xdfbuchstaben',
  'Upper Roman': 'Gro\xdfer r\xf6mischer Buchstabe',
  Url: 'URL',
  'User Defined': 'Benutzerdefiniert',
  Valid: 'G\xfcltig',
  Version: 'Version',
  'Vertical align': 'Vertikal ausrichten',
  'Vertical space': 'Vertikaler Raum',
  View: 'Ansicht',
  'Visual aids': 'Visuelle Hilfen',
  Warn: 'Warnung',
  White: 'Wei\xdf',
  Width: 'Breite',
  'Word count': 'Anzahl der W\xf6rter',
  Words: 'W\xf6rter',
  'Words: {0}': 'Wortzahl: {0}',
  Yellow: 'Gelb',
  Yes: 'Ja',
  'You are using {0}': 'Sie verwenden {0}',
  'You have unsaved changes are you sure you want to navigate away?':
    'Die \xc4nderungen wurden noch nicht gespeichert. Sind Sie sicher, dass Sie diese Seite verlassen wollen?',
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
    'Ihr Browser unterst\xfctzt leider keinen direkten Zugriff auf die Zwischenablage. Bitte benutzen Sie die Tastenkombinationen Strg+X/C/V.',
  alignment: 'Ausrichtung',
  'austral sign': 'Australzeichen',
  'cedi sign': 'Cedizeichen',
  'colon sign': 'Doppelpunkt',
  'cruzeiro sign': 'Cruzeirozeichen',
  'currency sign': 'W\xe4hrungssymbol',
  'dollar sign': 'Dollarzeichen',
  'dong sign': 'Dongzeichen',
  'drachma sign': 'Drachmezeichen',
  'euro-currency sign': 'Eurozeichen',
  example: 'Beispiel',
  formatting: 'Formatierung',
  'french franc sign': 'Franczeichen',
  'german penny symbol': 'Pfennigzeichen',
  'guarani sign': 'Guaranizeichen',
  history: 'Historie',
  'hryvnia sign': 'Hrywnjazeichen',
  indentation: 'Einr\xfcckungen',
  'indian rupee sign': 'Indisches Rupiezeichen',
  'kip sign': 'Kipzeichen',
  'lira sign': 'Lirezeichen',
  'livre tournois sign': 'Livrezeichen',
  'manat sign': 'Manatzeichen',
  'mill sign': 'Millzeichen',
  'naira sign': 'Nairazeichen',
  'new sheqel sign': 'Schekelzeichen',
  'nordic mark sign': 'Zeichen nordische Mark',
  'peseta sign': 'Pesetazeichen',
  'peso sign': 'Pesozeichen',
  'ruble sign': 'Rubelzeichen',
  'rupee sign': 'Rupiezeichen',
  'spesmilo sign': 'Spesmilozeichen',
  styles: 'Stile',
  'tenge sign': 'Tengezeichen',
  'tugrik sign': 'Tugrikzeichen',
  'turkish lira sign': 'T\xfcrkisches Lirazeichen',
  'won sign': 'Wonzeichen',
  'yen character': 'Yenzeichen',
  'yen/yuan character variant one': 'Yen-/Yuanzeichen Variante 1',
  'yuan character': 'Yuanzeichen',
  'yuan character, in hong kong and taiwan':
    'Yuanzeichen in Hongkong und Taiwan',
  '{0} characters': '{0}\xa0Zeichen',
  '{0} columns, {1} rows': '',
  '{0} words': '{0} W\xf6rter',
});
