import React from 'react';
import ReactDOM from 'react-dom/client';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import isToday from 'dayjs/plugin/isToday';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/es';
import 'dayjs/locale/pt';
import TagManager from 'react-gtm-module';
import 'nprogress/nprogress.css';

import App from 'src/App';
import * as serviceWorker from 'src/serviceWorker';
import { getLocaleByCountry } from 'src/shared/utils/manageLanguages';

const isProduction = process.env.NODE_ENV === 'production';
const locale = getLocaleByCountry();

if (isProduction) {
  TagManager.initialize({
    gtmId: 'GTM-WPK4RRS',
  });
}

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.locale(locale);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const root = ReactDOM.createRoot(document.getElementById('root'));
/*
TODO: hay que ver si pasar a modo estricto no rompe todo...
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/
root.render(<App />);

serviceWorker.unregister();
