import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import {
  Button,
  DialogActions as DialogActionsMUI,
  Typography,
} from '@mui/material';
import { Mail as MessageIcon } from 'react-feather';

import useIsNewCycle from 'src/features/institution/hooks/useIsNewCycle';

const DialogActions = ({
  haveViewProfileAccess,
  hasMessagesAccess,
  openProfile,
  sendMessage,
  isSamePerson,
}) => {
  const isNewCycle = useIsNewCycle();

  if (isNewCycle) return null;

  return (
    <DialogActionsMUI>
      {haveViewProfileAccess && (
        <Button onClick={openProfile}>
          <Typography>
            {isSamePerson ? (
              <Trans>Ir a mi perfil</Trans>
            ) : (
              <Trans>Ver perfil completo</Trans>
            )}
          </Typography>
        </Button>
      )}

      {hasMessagesAccess && (
        <Button
          variant="contained"
          onClick={sendMessage}
          startIcon={<MessageIcon size={'16px'} />}
        >
          <Typography>
            <Trans>Enviar mensaje</Trans>
          </Typography>
        </Button>
      )}
    </DialogActionsMUI>
  );
};
DialogActions.propTypes = {
  haveViewProfileAccess: PropTypes.bool,
  hasMessagesAccess: PropTypes.bool,
  openProfile: PropTypes.func,
  sendMessage: PropTypes.func,
  isSamePerson: PropTypes.bool,
};

export default DialogActions;
