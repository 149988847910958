// handle state changes with pure functions
import update from 'immutability-helper';

const reducers = {
  setSelectedLink(state, link) {
    if (!link) {
      return update(state, {
        selectedLink: {
          $set: null,
        },
      });
    }

    return update(state, {
      links: {
        $apply: (links) => {
          return links.map((storedLink) => {
            if (storedLink.id === link.id) {
              return link;
            }

            return {
              ...storedLink,
              is_favorite: false,
            };
          });
        },
      },
      selectedLink: {
        $set: link,
      },
    });
  },

  setLinks(state, links) {
    return update(state, {
      links: {
        $set: links,
      },
    });
  },

  updateLink(state, link) {
    return update(state, {
      links: {
        $apply: (links) => {
          return links.map((storedLink) => {
            if (storedLink.id === link.id) {
              return link;
            }

            return storedLink;
          });
        },
      },
    });
  },

  detachLink(state, linkId) {
    return update(state, {
      links: {
        $apply: (links) => {
          return links.filter((storedLink) => storedLink.id !== linkId);
        },
      },
    });
  },

  attachLink(state, link) {
    return update(state, {
      links: {
        $push: [link],
      },
    });
  },

  toggleLinkLoadingStatus(state) {
    return update(state, {
      loading: {
        $set: !state.loading,
      },
    });
  },

  setActiveDivision(state, activeDivision) {
    return update(state, {
      activeDivision: {
        $set: activeDivision,
      },
    });
  },
};

export default reducers;
