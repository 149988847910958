import PaymentGenerateBillsSchemaMx from './schema';

const harcCodeValuesReceiverMx = {
  documentFamily: 'XAXX010101000',
  nameFamily: 'Público General',
  taxRegimeFamily: 11,
  useCFDIFamily: 'S01',
  addressFamily: '',
};

const hardCodeInitValuesMx = {
  typeCFDI: {
    id: 1,
    name: 'Factura 1',
  },
  paymentMethod: {
    id: 1,
    name: 'PUE',
  },

  productCode: '86121500 - Escuelas preescolares, primarias y secunda..',
  productDescription: 'Cuota escolar - Aviso de pago - Saldo deudor',
  productUnit: {
    id: 1,
    name: 'E48',
  },
  productUnitAmount: '1',
  taxable: 'IVA Exento',
  rate: '0.0',
  wayToPay: null,
  hasResponsibleBiller: false,
  switchReceiver: false,
  autRvoe: null,
  forceEiduComplement: false,
};

export {
  harcCodeValuesReceiverMx,
  hardCodeInitValuesMx,
  PaymentGenerateBillsSchemaMx,
};
