import { t } from '@lingui/macro';
import { getDefaultStore, useSetAtom } from 'jotai';

import { paymentStatusMessageAtom } from 'src/features/payment-management/atoms';
import useCreatePaymentOrder from '../dLocal/useCreatePaymentOrder';
import { useOpenDialog } from 'src/shared/hooks';
import { dialogs } from 'src/shared/constants';
import { isJSON } from 'src/shared/helpers';

const ERROR_MESSAGE = t`Ha ocurrido un error al intentar crear el pago.`;
const SUCCESS_MESSAGE = t`En breve será redireccionado al portal de pagos...`;

const usePaycodePayment = () => {
  const defaultStore = getDefaultStore();
  const openDialog = useOpenDialog(dialogs.CREATE_PAYMENT_ORDERS_DIALOG);
  const setPaymentStatus = useSetAtom(paymentStatusMessageAtom, {
    store: defaultStore,
  });
  const { mutate: createPaymentOrder } = useCreatePaymentOrder();

  const onPaymentSuccess = ({ data }) => {
    const redirectUrl = data?.external_order?.link;
    setPaymentStatus({
      message: SUCCESS_MESSAGE,
      isOpen: true,
      isSuccess: true,
    });

    setTimeout(() => {
      window.location.href = redirectUrl;
    }, 3000);
  };

  const onPaymentError = ({ message: error }) => {
    const message = isJSON(error) ? JSON.parse(error)?.message : error;
    setPaymentStatus({
      message: message ?? ERROR_MESSAGE,
      isOpen: true,
      isSuccess: false,
    });
  };

  const handlePaymentOrder = (body) => {
    return createPaymentOrder(body, {
      onSuccess: onPaymentSuccess,
      onError: onPaymentError,
      onSettled: () => {
        openDialog(false);
      },
    });
  };

  return { handlePaymentOrder };
};

export default usePaycodePayment;
