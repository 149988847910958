// initial state
const state = {
  subjects: [],
  lectures: [],
  lecturesBySubjectId: [],
  taskDetails: {},
  tasks: {
    data: [],
    pagination: {
      total: 0,
    },
  },
  tasksPage: {
    data: [],
    pagination: {
      total: 0,
    },
  },
  members: [],
  studentsBySubjectId: [],
  teachersBySubjectId: [],
  userHomeworks: {
    pagination: {
      total: 0,
    },
    data: [],
  },
  questions: {
    data: [],
    pagination: {
      total: 0,
    },
  },
  answers: {
    data: [],
    pagination: {
      total: 0,
    },
  },
  questionsSidebarOpen: false,
};

export default state;
