import createRouter from './createRouter';
import mergeRouters from './mergeRouters';

const newsfeedRouter = createRouter('newsfeed', {
  root: `/comunicacion/publicaciones`,
  myPosts: `/comunicacion/publicaciones/mis-publicaciones`,
  scheduledPosts: `/comunicacion/publicaciones/programadas`,
  savedPosts: `/comunicacion/publicaciones/guardados`,
  post: (id) => `/comunicacion/publicaciones/${id}`,
  readConfirmation: `/comunicacion/publicaciones/confirmar-lectura`,
});

const calendarRouter = createRouter('calendar', {
  root: `/comunicacion/calendario`,
});

const divisionsRouter = createRouter('divisions', {
  root: `/comunicacion/divisiones`,
  division: (id) => `/comunicacion/divisiones/${id}`,
  posts: (id) => `/comunicacion/divisiones/${id}/publicaciones`,
  scheduledPosts: (id) =>
    `/comunicacion/divisiones/${id}/publicaciones/programadas`,
  savedPosts: (id) => `/comunicacion/divisiones/${id}/publicaciones/guardados`,
});

const groupsRouter = createRouter('groups', {
  root: `/comunicacion/grupos`,
  members: (id) => `/comunicacion/grupos/${id}/miembros`,
  posts: (id) => `/comunicacion/grupos/${id}/publicaciones`,
  scheduledPosts: (id) =>
    `/comunicacion/grupos/${id}/publicaciones/programadas`,
  savedPosts: (id) => `/comunicacion/grupos/${id}/publicaciones/guardados`,
});

const inboxRouter = createRouter('inbox', {
  root: `/comunicacion/mensajes`,
  contacts: `/comunicacion/mensajes/contactos`,
  newMessage: `/comunicacion/mensajes/nuevo`,
  newBroadcastMessage: `/comunicacion/mensajes/nueva-difusion`,
  conversation: (id) => `/comunicacion/mensajes/conversacion/${id}`,
});

const draftRouter = createRouter('draft', {
  root: `/comunicacion/mensajes/borradores`,
  conversation: (id) => `/comunicacion/mensajes/borradores/${id}`,
});

const scheduledRouter = createRouter('scheduled', {
  root: `/comunicacion/mensajes/programados`,
  conversation: (id) => `/comunicacion/mensajes/programados/${id}`,
});

const behaviorRouter = createRouter('behavior', {
  root: `/comunicacion/seguimiento`,
  module: (id) => `/comunicacion/seguimiento/${id}`,
});

const subjectsRouter = createRouter('subjects', {
  root: `/comunicacion/materias`,
  subject: (id) => `/comunicacion/materias/${id}`,
  extracurricular: `/comunicacion/materias/especiales`,
  links: (id) => `/comunicacion/materias/${id}/links`,
  members: (id) => `/comunicacion/materias/${id}/members`,
  posts: (id) => `/comunicacion/materias/${id}/publicaciones`,
  scheduledPosts: (id) =>
    `/comunicacion/materias/${id}/publicaciones/programadas`,
  savedPosts: (id) => `/comunicacion/materias/${id}/publicaciones/guardados`,
  tasks: (id) => `/comunicacion/materias/${id}/tareas`,
});

const pendingRouter = createRouter('pending', {
  root: `/comunicacion/pendientes`,
  pending: (id) => `/comunicacion/pendientes/${id}`,
  all: (id) => `/comunicacion/pendientes/${id}/todos`,
  unconfirmed: (id) => `/comunicacion/pendientes/${id}/a-confirmar`,
});

const pickUpsRouter = createRouter('pickUps', {
  root: `/comunicacion/autorizaciones`,
  exceptional: `/comunicacion/autorizaciones/excepcionales`,
  authorized: `/comunicacion/autorizaciones/autorizados`,
  regular: `/comunicacion/autorizaciones/habituales`,
  extraordinary: `/comunicacion/autorizaciones/excepcionales`,
});

const activityRouter = createRouter('activity', {
  root: `/comunicacion/extracurriculares`,
  activityDetail: (id) => `/comunicacion/extracurriculares/${id}`,
});

const reportsRouter = createRouter('reports', {
  root: `/informes`,
});

const dashboardRouter = createRouter('dashboard', {
  root: `/gestion-escolar`,
});

const routers = mergeRouters([
  newsfeedRouter,
  calendarRouter,
  divisionsRouter,
  groupsRouter,
  inboxRouter,
  draftRouter,
  scheduledRouter,
  behaviorRouter,
  subjectsRouter,
  pendingRouter,
  pickUpsRouter,
  activityRouter,
  dashboardRouter,
  reportsRouter,
]);

export default routers;
