import React from 'react';
import {
  ListWeekHeader,
  TimeGridDayHeader,
  TimeGridWeekHeader,
} from '../components/CalendarHeader';

const getViewOptions = () => {
  return {
    timeGridWeek: {
      dayHeaderContent: (header) => <TimeGridWeekHeader header={header} />,
    },
    timeGridDay: {
      dayHeaderContent: (header) => <TimeGridDayHeader header={header} />,
    },
    listWeek: {
      dayHeaderContent: (header) => <ListWeekHeader header={header} />,
    },
  };
};

export default getViewOptions;
