import JWT from 'jwt-decode';

import useUserInfo from './useUserInfo';

const useInstitutionCountryAccess = () => {
  const { apiKey } = useUserInfo();
  const { country_code: institutionCountry } = JWT(apiKey);

  const hasInstitutionCountryAccess = (countries = []) => {
    if (!countries.length) return true;

    return countries?.includes(institutionCountry.toUpperCase());
  };

  return {
    institutionCountry,
    hasInstitutionCountryAccess,
  };
};

export default useInstitutionCountryAccess;
