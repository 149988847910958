import { useGetList } from 'src/shared/services/api/query';
import { getConversationMessages } from 'src/shared/services/url/UrlCommunication';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';

const useGetConversationMessages = ({
  conversationId,
  handleSeenMessage,
  ...params
}) => {
  return useGetList(
    [QUERY_KEY.MESSAGES_LIST, { conversationId, ...params }],
    getConversationMessages(conversationId),
    { sort: 'creation_date', ...params },
    true,
    false,
    {
      enabled: Boolean(conversationId),
      onSuccess: (data) => {
        if (handleSeenMessage) handleSeenMessage(data);
      },
    },
  );
};

export default useGetConversationMessages;
