const CLOUD_STORAGE_TYPES = {
  GOOGLE_DRIVE: {
    id: 'google-drive',
    prefix: 'https://drive.google.com/u/1/uc',
  },
  ONE_DRIVE: {
    id: 'one_drive',
    prefix: '',
  },
};

export { CLOUD_STORAGE_TYPES };
