import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Autocomplete, TextField, CircularProgress } from '@mui/material';
import { useAttendanceContext } from 'src/features/attendance/contexts/AttendanceContext';
import clsx from 'clsx';
import { t } from '@lingui/macro';
import ScaleOption from '../ScaleOption';
import AttendanceTypeMenu from '../AttendanceTypeMenu';
import useStyles from './styles';

const AttendanceScaleCell = memo(({ elementId, isSubject, student }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState([]);
  const [shiftScales, setShiftScales] = useState([]);
  const [selectedAttendance, setSelectedAttendance] = useState({});
  const elementKey = useMemo(
    () => (isSubject ? 'subject_id' : 'attendance_shift_id'),
    [isSubject],
  );
  const {
    selectedDay,
    activeScales,
    activeAttendances,
    setActiveAttendances,
    selectedShifts,
    setSelectedShifts,
  } = useAttendanceContext();

  const handleAddOrUpdateAttendance = (option) => {
    const { id: attendance_scale_id } = option;
    setValue([option]);
    setActiveAttendances((oldValue) => {
      const clonedValues = [...oldValue];
      const foundIndex = clonedValues.findIndex(
        (attendance) =>
          attendance[elementKey] === elementId &&
          attendance.user_id === student.id,
      );
      const isEditing = foundIndex > -1;

      if (isEditing) {
        delete clonedValues[foundIndex].remove;
        clonedValues[foundIndex] = {
          ...clonedValues[foundIndex],
          [elementKey]: elementId,
          user_id: student.id,
          attendance_scale_id,
        };
      } else {
        const newOption = {
          [elementKey]: elementId,
          user_id: student.id,
          attendance_scale_id,
          computable: true,
          justified: false,
        };
        setSelectedAttendance((prevState) => ({
          ...prevState,
          ...newOption,
        }));
        clonedValues.push(newOption);
      }
      return clonedValues;
    });
  };

  const handleRemoveAttendance = () => {
    setValue([]);
    setActiveAttendances((oldValue) => {
      const clonedValues = [...oldValue];
      const foundIndex = clonedValues.findIndex(
        (attendance) =>
          attendance[elementKey] === elementId &&
          attendance.user_id === student.id,
      );
      const hasId = Boolean(clonedValues?.[foundIndex]?.id);
      if (foundIndex > -1 && hasId) {
        setSelectedAttendance((prevState) => ({
          ...prevState,
          justified: false,
          computable: true,
        }));
        clonedValues[foundIndex] = {
          ...clonedValues[foundIndex],
          remove: true,
        };
      } else if (!hasId) {
        clonedValues.splice(foundIndex, 1);
      }

      return clonedValues;
    });
  };

  const handleChange = (_, newValue, action, { option: latestOption }) => {
    if (action === 'removeOption') {
      return handleRemoveAttendance();
    }
    return handleAddOrUpdateAttendance(latestOption);
  };

  const handleLoadShiftScales = useCallback(() => {
    // NOTA: EL "SHIFT" PUEDE SER LA MATERIA O EL ATTENDANCE_SHIFT QUE ES POR DIVISION
    setShiftScales(
      activeScales.filter(
        (activeScale) => activeScale[elementKey] === elementId,
      ),
    );
  }, [activeScales, elementId, elementKey]);

  const handleLoadedAttendances = useCallback(() => {
    const foundAttendance = activeAttendances.find(
      (el) =>
        el[elementKey] === elementId &&
        el.user_id === student.id &&
        el.date === selectedDay.day &&
        el.remove !== true,
    );
    if (foundAttendance) {
      const foundScale = shiftScales.find(
        (el) => el.id === foundAttendance.attendance_scale_id,
      );
      if (foundScale) {
        setValue([foundScale]);
      }
    }
    setSelectedAttendance(foundAttendance);
    setIsLoading(false);
  }, [
    activeAttendances,
    elementId,
    elementKey,
    selectedDay,
    shiftScales,
    student.id,
  ]);

  const handleUpdateAll = useCallback(
    ({ id: attendance_scale_id }) => {
      setActiveAttendances((oldValue) => {
        const clonedItems = [...oldValue];
        const foundIndex = oldValue.findIndex(
          (el) => el.user_id === student.id && el[elementKey] === elementId,
        );
        const isEditing = foundIndex > -1;
        if (isEditing) {
          clonedItems[foundIndex] = {
            ...selectedAttendance,
            ...clonedItems[foundIndex],
            user_id: student.id,
            [elementKey]: elementId,
            attendance_scale_id,
          };
        } else {
          const newOption = {
            user_id: student.id,
            [elementKey]: elementId,
            attendance_scale_id,
            justified: false,
            computable: true,
          };
          clonedItems.push(newOption);
          setSelectedAttendance((prevState) => ({
            ...prevState,
            ...newOption,
          }));
        }
        return clonedItems;
      });
    },
    [
      elementId,
      elementKey,
      selectedAttendance,
      setActiveAttendances,
      student.id,
    ],
  );

  const handleMarkAll = useCallback(async () => {
    const foundShift = selectedShifts?.find(
      (el) => el.attendance_shift_id === elementId,
    );

    if (foundShift) {
      const foundScale = activeScales?.find(
        (el) => el.id === foundShift?.attendance_scale_id,
      );
      setValue([foundScale]);
      setSelectedShifts([]);
      await handleUpdateAll(foundScale);
    }
  }, [
    activeScales,
    elementId,
    handleUpdateAll,
    selectedShifts,
    setSelectedShifts,
  ]);

  useEffect(() => {
    if (activeScales.length) {
      handleLoadShiftScales();
    }
  }, [activeScales.length, handleLoadShiftScales]);

  useEffect(() => {
    handleLoadedAttendances();
    setIsLoading(false);
  }, [handleLoadedAttendances]);

  useEffect(() => {
    if (selectedShifts.length) {
      (async () => {
        await handleMarkAll();
      })();
    }
  }, [handleMarkAll, selectedShifts]);

  useEffect(() => {
    if (activeAttendances.length) {
      const foundAttendance = activeAttendances.find(
        (el) =>
          el[elementKey] === elementId &&
          el.user_id === student.id &&
          el.remove !== true,
      );
      setSelectedAttendance(foundAttendance);
    } else {
      setSelectedAttendance(null);
    }
  }, [activeAttendances, activeScales, elementId, elementKey, student.id]);

  return (
    <Box
      key={`${student.id}-${elementId}`}
      display="flex"
      width={190}
      justifyContent="center"
    >
      {isLoading ? (
        <CircularProgress size={16} />
      ) : (
        <>
          <Autocomplete
            multiple
            disableClearable
            limitTags={1}
            className={classes.scaleSelector}
            classes={{
              root: classes.root,
            }}
            options={shiftScales ?? []}
            getOptionLabel={(option) => option?.name}
            isOptionEqualToValue={(option, val) => option?.value === val?.value}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                classes={{
                  root: clsx({
                    [classes.scaleSelectorInputRoot]: value.length > 0,
                    [classes.scaleSelectorInputRootWithChips]: !value.length,
                  }),
                }}
              />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map(({ deleted, ...option }, index) => (
                <ScaleOption
                  solo
                  displayValue
                  key={option?.value ?? index}
                  {...getTagProps({ index })}
                  {...option}
                  disabled={selectedAttendance?.computable === false}
                />
              ))
            }
            renderOption={(props, { deleted, ...option }) => (
              <ScaleOption displayValue {...props} {...option} />
            )}
            onChange={(_, newValue, action, oldValue) =>
              handleChange(_, newValue, action, oldValue)
            }
            value={value}
            noOptionsText={t`No hay escalas cargadas`}
          />
          <AttendanceTypeMenu
            className={clsx({
              [classes.hiddenMenu]: !value.length,
            })}
            studentId={student.id}
            shiftId={elementId}
            justified={selectedAttendance?.justified}
            computable={selectedAttendance?.computable}
            onChange={setSelectedAttendance}
          />
        </>
      )}
    </Box>
  );
});

AttendanceScaleCell.displayName = 'AttendanceScaleCell';

AttendanceScaleCell.propTypes = {
  elementId: PropTypes.number,
  isSubject: PropTypes.bool,
  student: PropTypes.object,
};

export default AttendanceScaleCell;
