import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  head: {
    padding: theme.spacing(4),
    display: 'flex',
    minHeight: 50,
    flexDirection: 'column',
  },
  description: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  goBackWrapper: {
    '& svg': {
      width: 16,
      height: 16,
    },
  },
}));

export default useStyles;
