import abstractSerializer from 'src/shared/services/api/query/serializer/abstractSerializer';

const serialize = ({
  values,
  isBatch,
  institutionTaxInformation,
  selectedInvoice,
}) => {
  let body = {
    ...(values?.educational_complement !== undefined && {
      educational_complement: values.educational_complement,
    }),
    ...(values?.point_of_sale !== undefined && {
      point_of_sale: values.point_of_sale,
      service_type: values.service_type,
      receipt_from: values.receipt_from,
      receipt_to: values.receipt_to,
      receipt_type: null,
    }),
  };

  if (isBatch && selectedInvoice.length === 0) {
    body = {
      invoice_all: true,
      institution_tax_information_id: [institutionTaxInformation.id],
      ...body,
    };
  } else {
    const tax = values?.family_tax_information?.id
      ? { family_tax_information_id: values?.family_tax_information.id }
      : { is_general_public: true };

    body = {
      ...tax,
      payment_notification_id: selectedInvoice?.map((invoice) => invoice.id),
      ...body,
    };
  }

  return {
    ...body,
  };
};

const taxBillGenerateSerializer = abstractSerializer(serialize, null);

export default taxBillGenerateSerializer;
