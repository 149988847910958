import { useParams } from 'react-router-dom';

import useGetConversationMessages from '../data/useGetConversationMessages';
import useReplyMessage from '../data/useReplyMessage';
import useMarkMessageAsSeen from '../data/useMarkMessageAsSeen';

const useInboxMessages = () => {
  const { conversationId } = useParams();

  const { mutateAsync: replyMessage } = useReplyMessage(conversationId);

  const { mutateAsync: markMessageAsSeen } = useMarkMessageAsSeen();

  const handleSeenMessage = (data) => {
    const lastMessage = data.pop();
    if (lastMessage) {
      markMessageAsSeen({
        message_id: lastMessage.id,
      });
    }
  };

  const {
    data: messages,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useGetConversationMessages({ conversationId, handleSeenMessage });

  return {
    messages,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    replyMessage,
    markMessageAsSeen,
  };
};

export default useInboxMessages;
