import { useGetPaginatedList } from 'src/shared/services/api/query';
import { paymentManagementFamilies } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const useFamilies = (params, queryParams) =>
  useGetPaginatedList(
    [QUERY_KEY.FAMILY, params],
    paymentManagementFamilies(),
    { ...params },
    false,
    queryParams,
    null,
    0,
    10,
  );

export default useFamilies;
