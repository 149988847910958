import { useMutation } from '@tanstack/react-query';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { useSetAtom } from 'jotai';

import { rateReportUser } from 'src/shared/services/url/UrlPaymentManagement';
import { Api as api } from 'src/shared/services/api';
import { paymentBatchTransactionAtom } from 'src/features/payment-management/atoms';

const ERROR_MESSAGE = t`Ha ocurrido un error al descargar el archivo.`;

const useDownloadRateReportUser = (rateId) => {
  const { enqueueSnackbar } = useSnackbar();
  const setBatch = useSetAtom(paymentBatchTransactionAtom);

  const fetchReportMutation = () =>
    api.get({
      url: rateReportUser(rateId),
      data: {
        'per-page': 5000,
        paginate: false,
      },
    });

  const onSuccess = ({ data }) => {
    setBatch(data);
  };

  return useMutation({
    mutationFn: fetchReportMutation,
    onSuccess,
    onError: ({ message }) =>
      enqueueSnackbar(message ?? ERROR_MESSAGE, { variant: 'error' }),
  });
};

export default useDownloadRateReportUser;
