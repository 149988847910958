import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Card from '@mui/material/Card';
import { CircularProgress } from '@mui/material';

import useStyles from './styles';

const PostCard = React.forwardRef(
  ({ children, disabled, loading, pinned, ...props }, ref) => {
    const classes = useStyles();
    return (
      <Card
        {...props}
        ref={ref}
        variant="outlined"
        classes={{ root: clsx(classes.root, { [classes.pinned]: pinned }) }}
      >
        {(disabled || loading) && (
          <div className={classes.foreground}>
            {loading && <CircularProgress size={15} color="primary" />}
          </div>
        )}

        {children}
      </Card>
    );
  },
);

PostCard.displayName = 'PostCard';

PostCard.propTypes = {
  pinned: PropTypes.bool,
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default PostCard;
