import { QUERY_KEY } from 'src/features/my-account/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import { updateUser } from 'src/shared/services/url/UrlUser';
import { USER_INFO_EXPAND } from 'src/features/my-account/constants/queryExpands';

const useUserInformation = ({ userId, expand, queryParams }) => {
  return useGetOne(
    [QUERY_KEY.USER_INFO, userId],
    updateUser(userId),
    { expand: expand ?? USER_INFO_EXPAND },
    false,
    false,
    queryParams,
  );
};

export default useUserInformation;
