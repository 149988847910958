import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';
import { useCallback } from 'react';

const useTryCatch = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleTryCatch = useCallback(
    async (
      handledFunction,
      errorMessage = t`Ha ocurrido un error`,
      onError,
      onFinally,
    ) => {
      try {
        await handledFunction();
      } catch (apiError) {
        const { message } = apiError;
        if (onError) onError(apiError);
        enqueueSnackbar(message ?? errorMessage, {
          variant: 'error',
        });
      } finally {
        if (onFinally) onFinally();
      }
    },
    [enqueueSnackbar],
  );

  return {
    handleTryCatch,
  };
};

export default useTryCatch;
