import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  userRow: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  userRowDense: {
    padding: `0 ${theme.spacing(2)}`,
  },
  checkbox: {
    marginRight: theme.spacing(2),
  },
  listItemAvatarRoot: {
    minWidth: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
