import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& th': {
      border: 'none',
    },
  },
  stickyColumn: {
    left: 0,
    zIndex: 3,
    position: 'sticky !important',
    backgroundColor: theme.palette.common.white,
  },
  tableRow: {
    position: 'relative',
  },
}));

export default useStyles;
