import React from 'react';
import { Trans } from '@lingui/macro';
import { TextsmsOutlined as TextsmsOutlinedIcon } from '@mui/icons-material';

import { COMMUNICATION_ITEMS } from './constants';
import useRoutes from '../useRoutes';
import { ROLE_LIST } from 'src/config/general';
import { BLENDED_MODULES } from 'src/config/constants';
import router from 'src/shared/utils/router';
import { checkRole } from 'src/shared/helpers';
import usePickupAccessRoles from 'src/features/pickups/hooks/usePickupAccessRoles';
import {
  FINANCIAL_ROLES,
  IS_AUTHORITARY_ROLES,
} from 'src/features/school-dashboard/constants/helpers';

const useCommunicationConfig = () => {
  const { createRoutes } = useRoutes();
  const pickupRolePermission = usePickupAccessRoles();

  const items = [
    {
      id: 'school-manager',
      title: <Trans>School Manager</Trans>,
      href: router.communication.dashboard.root,
      enabled: true,
      roles: [
        ROLE_LIST.ADMIN,
        ROLE_LIST.EXECUTIVE,
        ROLE_LIST.FINANCIAL_ADMIN,
        ROLE_LIST.FINANCIAL_EXECUTIVE,
        ROLE_LIST.PRECEPTOR,
      ],
      divider: true,
    },
    {
      id: 'communication',
      title: <Trans>Comunicación</Trans>,
      enabled: true,
      items: COMMUNICATION_ITEMS,
      divider: true,
    },
    {
      id: BLENDED_MODULES.CALENDAR.id,
      title: <Trans>Calendario</Trans>,
      href: router.communication.calendar.root,
      enabled: true,
      divider: true,
    },
    {
      id: BLENDED_MODULES.BEHAVIOUR.id,
      title: <Trans>Seguimiento</Trans>,
      enabled: true,
      href: router.communication.behavior.root,
      roles: [ROLE_LIST.ADMIN, ROLE_LIST.PRECEPTOR, ROLE_LIST.EXECUTIVE],
    },
    {
      id: BLENDED_MODULES.PICKUPS.id,
      title: <Trans>Autorizaciones</Trans>,
      href: router.communication.pickUps.root,
      enabled: true,
      roles: [...pickupRolePermission],
    },
    {
      id: BLENDED_MODULES.ACTIVITY.id,
      title: <Trans>Extracurriculares</Trans>,
      href: router.communication.activity.root,
      roles: [ROLE_LIST.ADMIN, ROLE_LIST.PRECEPTOR, ROLE_LIST.EXECUTIVE],
    },
    {
      id: BLENDED_MODULES.REPORTS.id,
      title: <Trans>Informes</Trans>,
      href: router.communication.reports.root,
      enabled: true,
      roles: [ROLE_LIST.ADMIN, ROLE_LIST.PRECEPTOR, ROLE_LIST.EXECUTIVE],
    },
    {
      id: BLENDED_MODULES.DOCUMENTS.id,
      title: <Trans>Documentos</Trans>,
      href: router.integrations.documents.root,
      roles: [ROLE_LIST.ADMIN, ROLE_LIST.PRECEPTOR, ROLE_LIST.EXECUTIVE],
    },
    {
      id: BLENDED_MODULES.PENDING.id,
      title: <Trans>Pendientes</Trans>,
      href: router.communication.pending.root,
      roles: [ROLE_LIST.PARENT],
    },
  ];

  const isTeacher = checkRole([ROLE_LIST.TEACHER]);
  const isFinancial = checkRole(FINANCIAL_ROLES);
  const isAuthority = checkRole(IS_AUTHORITARY_ROLES);

  if (isTeacher && (isFinancial || !isAuthority)) {
    const index = items.map((item) => item.id).indexOf('school-manager');
    if (index > -1) {
      items.splice(index, 1);
    }
  }

  const config = {
    id: 'community-hub',
    subheader: <Trans>Community Hub</Trans>,
    icon: <TextsmsOutlinedIcon size={18} />,
    items: createRoutes(items),
    prefix: 'comunicacion',
  };

  return config;
};

export default useCommunicationConfig;
