import { BLENDED_MODULES } from 'src/config/constants';

export const createServerRoute = ({ currentModule, route }) => {
  return { ...route, enabled: currentModule.enabled };
};

export const createClientRoute = ({ route, modules, config }) => {
  switch (route.id) {
    // Si calificaciones y informes esta desactivados, no se muestra el modulo de documentos
    case BLENDED_MODULES.PENDING.id:
    case BLENDED_MODULES.DOCUMENTS.id: {
      const isActive = modules.some((module) => {
        if (
          module.id !== BLENDED_MODULES.GRADING.id &&
          module.id !== BLENDED_MODULES.PRIMARY_REPORTS.id
        )
          return false;
        return module.enabled;
      });
      return { ...route, enabled: isActive };
    }

    case 'online-collection':
      return { ...route, enabled: config?.dlocal_account_activated };

    case 'billing':
      return { ...route, enabled: config?.enable_tax_billing };

    // Las rutas locales siempre estan habilitadas por defecto
    default:
      return { ...route, enabled: true };
  }
};

export const designateRoutesByCountry = (routes, user) => {
  const { code } = user.country ?? {};

  return routes.filter((route) => {
    // Si la ruta no tiene paises asignados, se habilita para todos
    if (!route.countries?.length) return true;

    // Si la ruta tiene paises asignados, se habilita solo para los que coincidan con el del usuario
    return route.countries.some(
      (country) => country.toLowerCase() === code?.toLowerCase(),
    );
  });
};

export const isRouteChildrenContentful = (route) => {
  if (!route.children) {
    return true;
  }

  return route.children.type() !== null;
};

export const normalizeRoutes = (routes) =>
  routes.reduce((acc, route) => {
    if (route.items && !route.items.length) return acc;

    // Como el front filtra por enabled, al final solo llega siempre enabled = true
    // Por lo tanto, se elimina para no enviarlo al front
    const { enabled, ...rest } = route;

    return [...acc, rest];
  }, []);
