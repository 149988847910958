import { atom } from 'jotai';
import dialogStateFamily from './dialogStateFamily';

const disableDialogAtom = atom(null, (get, set, payload) => {
  const dialogStateAtom = dialogStateFamily(payload);

  const dialogState = get(dialogStateAtom);

  set(dialogStateAtom, {
    ...dialogState,
    isDisabled: payload.isDisabled,
  });
});
export default disableDialogAtom;
