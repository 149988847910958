import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { useCreate } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';
import { getConversation } from 'src/shared/services/url/UrlCommunication';

const useCreateMessage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useCreate(
    getConversation(),
    {
      onSuccess: () => {
        enqueueSnackbar(t`Su mensaje ha sido enviado`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t`Hubo un error al enviar su mensaje.`, {
          variant: 'error',
        });
      },
      onSettled: () => {
        queryClient.removeQueries([QUERY_KEY.CONVERSATIONS_LIST]);
        queryClient.invalidateQueries([QUERY_KEY.MESSAGES_LIST]);
        queryClient.invalidateQueries([QUERY_KEY.DRAFT_CONVERSATIONS_LIST]);
      },
    },
    false,
  );
};

export default useCreateMessage;
