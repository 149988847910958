import { useMemo } from 'react';
import { getDefaultStore, useAtomValue } from 'jotai';

import { selectedInvoicesListAtom } from 'src/features/payment-management/atoms';

const useParentBalance = () => {
  const defaultStore = getDefaultStore();
  const selectedInvoices = useAtomValue(selectedInvoicesListAtom, {
    store: defaultStore,
  });

  const positive_balance =
    selectedInvoices.find((invoice) => !!invoice.family)?.family
      ?.positive_balance ?? 0;

  const totalAmountSelected = useMemo(() => {
    const total = selectedInvoices.reduce(
      (partialSum, invoice) => partialSum + invoice.actual_balance,
      0,
    );

    return Number(total.toFixed(2));
  }, [selectedInvoices]);

  const transactions = useMemo(
    () => selectedInvoices.map((invoice) => invoice.id),
    [selectedInvoices],
  );

  const voucherValues = {
    paymentMethod: null,
    invoiceId: selectedInvoices.map((invoice) => invoice.id),
    value: Math.max(0, totalAmountSelected - positive_balance || 0),
    value_to_pay: totalAmountSelected,
    positive_balance: positive_balance,
  };

  return {
    voucherValues,
    selectedInvoices,
    totalAmountSelected,
    transactions,
    positive_balance,
  };
};

export default useParentBalance;
