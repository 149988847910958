import { createStore } from 'jotai/vanilla';

export const PaymentNoticeImportStore = createStore();
export const CampaignConfigStore = createStore();
export const CampaignAdvancedConfigStore = createStore();
export const CreatePaymentOrdersStore = createStore();
export const ActivityConfigStore = createStore();
export const SelectorMembersGroupStore = createStore();
export const PaymentRateStore = createStore();
export const SelectorMembersRateStore = createStore();
export const PaymentDiscountStore = createStore();
export const GenerateBillStore = createStore();
export const LoginStepsStore = createStore();
export const RecoveryPasswordStepsStore = createStore();
export const ReportScaleStore = createStore();
export const ReportTemplateStore = createStore();
export const ReportStore = createStore();
