import { useCallback } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { useHistory } from 'react-router';

import {
  contactFiltersAtom,
  contactSearchQueryAtom,
  selectedContactsAtom,
  sendSubjectAtom,
} from '../../atoms';
import { useGetContacts } from '../../hooks';

const useInboxContacts = () => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useAtom(contactSearchQueryAtom);
  const [selectedContacts, setSelectedContacts] = useAtom(selectedContactsAtom);
  const [contactFilters, setContactFilters] = useAtom(contactFiltersAtom);
  const setSendSubject = useSetAtom(sendSubjectAtom);
  const {
    data: contacts,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useGetContacts({ searchTerm: searchQuery, filters: contactFilters });

  const handleChangeFilters = useCallback(
    (filters) => {
      setContactFilters(filters);
    },
    [setContactFilters],
  );

  const handleSelectContact = useCallback(
    (contact, checked) => {
      if (checked) {
        setSelectedContacts((oldValue) => [...oldValue, contact]);
      } else {
        const contacts = selectedContacts.filter(({ id }) => contact.id !== id);
        setSelectedContacts(contacts);
      }
    },
    [selectedContacts, setSelectedContacts],
  );

  const handleGoToInbox = useCallback(() => {
    setSelectedContacts([]);
    setSendSubject('');
    history.goBack();
  }, [history, setSelectedContacts, setSendSubject]);

  const handleIsContactSelected = useCallback(
    (contactId) => selectedContacts.some(({ id }) => id === contactId),
    [selectedContacts],
  );

  const resetFilters = () => {
    setSearchQuery('');
    setContactFilters('');
  };

  return {
    contacts,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    searchQuery,
    setSearchQuery,
    selectedContacts,
    setSelectedContacts,
    contactFilters,
    setSendSubject,
    handleChangeFilters,
    handleSelectContact,
    handleGoToInbox,
    handleIsContactSelected,
    resetFilters,
  };
};

export default useInboxContacts;
