import React from 'react';
import { Box, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';

import features from 'src/assets/images/changelog/02-23/features.png';
import format from 'src/assets/images/changelog/02-23/format-text2.png';
import pin from 'src/assets/images/changelog/02-23/pin2.png';
import confirm from 'src/assets/images/changelog/02-23/confirm2.png';
import { useBreakpoints } from 'src/shared/hooks';

const bulletList = [
  {
    icon: format,
    title: (
      <Trans>
        Las publicaciones llevarán <b>título.</b>
      </Trans>
    ),
  },
  {
    icon: pin,
    title: (
      <Trans>
        Habrá <b>publicaciones fijadas</b> para que nadie se las pierda y sirvan
        de recordatorio.
      </Trans>
    ),
  },
  {
    icon: confirm,
    title: (
      <Trans>
        Ciertos comunicados requerirán de <b>confirmar lectura</b> para asegurar
        que las informaciones importantes hayan sido leídas.
      </Trans>
    ),
  },
];

const AppStep = () => {
  const { isMobile } = useBreakpoints();

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 0,
        px: isMobile ? 2 : 10,
        ...(isMobile && { pt: 2, pb: 7 }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          maxWidth: 330,
          width: '100%',
        }}
      >
        {isMobile && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <Box
              component="img"
              src={features}
              alt="Logo rebrand gif"
              width={310}
            />
          </Box>
        )}

        <Typography
          variant="h1"
          fontSize={isMobile ? 16 : 20}
          fontFamily="Sora"
          textAlign={isMobile ? 'center' : 'left'}
          {...(isMobile && {
            mb: -2,
          })}
        >
          <Trans>Incluímos nuevas funcionalidades</Trans>
        </Typography>

        <Box
          component="ul"
          sx={{ flexDirection: 'column', display: 'flex', gap: 2, ml: 1 }}
        >
          {bulletList.map((item, index) => (
            <Box
              component="li"
              key={index}
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                ...(isMobile && {
                  textAlign: 'center',
                  px: 2,
                  ml: 0,
                }),
              }}
            >
              {!isMobile && (
                <Box
                  component="img"
                  src={item.icon}
                  alt="Feature icon"
                  sx={{
                    width: 23,
                    height: 23,
                  }}
                />
              )}

              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <Typography variant="h3">{item.title}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      {!isMobile && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            flex: 1,
          }}
        >
          <Box component="img" src={features} alt="Logo rebrand gif" />
        </Box>
      )}
    </Box>
  );
};

export default AppStep;
