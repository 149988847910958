import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Trash } from 'react-feather';

import UserAvatar from 'src/components/UserAvatar';
import LinkChildrenForm from 'src/features/sidebar/components/LinkChildrenForm';
import useUserSons from 'src/shared/hooks/useUserSons';
import { formatUserRolesName } from 'src/shared/helpers';
import useRemoveUserChildren from 'src/shared/hooks/useRemoveUserChildren';
import useProfileUserId from 'src/features/my-account/hooks/useProfileUserId';
import useUserSearchByDocument from 'src/shared/hooks/data/useUserSearchByDocument';
import useRefreshToken from 'src/shared/hooks/useRefreshToken';

import useAddUserChildren from '../../../../shared/hooks/useAddUserChildren';

const LinkChildrenDialog = ({
  title = t`Agregar/editar hijo/a`,
  open,
  handleClose,
  ...props
}) => {
  const {
    user: { refreshToken },
  } = useSelector((state) => state);
  const { profileUserId } = useProfileUserId();

  const { data: children, isLoading: isLoadingChildren } = useUserSons({
    userId: profileUserId,
    enabled: Boolean(profileUserId && open),
  });

  const { mutateAsync: handleSearchUser, isLoading: isSearching } =
    useUserSearchByDocument();
  const { mutateAsync: addChildren, isLoadingAddChildren } = useAddUserChildren(
    {
      userId: profileUserId,
    },
  );

  const { mutateAsync: updateRefreshToken } = useRefreshToken();
  const { mutateAsync: removeSon, isLoading: isRemovingSon } =
    useRemoveUserChildren();

  const isLoading = isLoadingAddChildren || isSearching || isLoadingChildren;

  const handleRemoveSon = (sonId) => {
    removeSon({ userId: profileUserId, children: [sonId] });
  };

  const handleSearch = async (values, formik) => {
    await handleSearchUser(
      { document_number: values.students },
      {
        onSuccess: async ({ data }) => {
          const [children] = data ?? [];
          if (children) {
            await addChildren({ children: [children.id] });
            await updateRefreshToken({ refresh_token: refreshToken });
            formik.resetForm();
          }
        },
      },
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="sm"
      fullWidth
      {...props}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent dividers>
        <LinkChildrenForm onSubmit={handleSearch} onCancel={handleClose} />

        <Stack direction="column" gap={2} mt={4}>
          <Typography variant="overline" color="textSecondary">
            <Trans>ESTUDIANTES VINCULADOS/AS</Trans>
          </Typography>

          {children.map((son) => {
            const { name, last_name } = son;
            const fullName = `${last_name} ${name}`;
            const roles = son.roles?.map((role) => role.item_name);
            const rolesName = formatUserRolesName(roles);

            return (
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                key={son.id}
              >
                <UserAvatar
                  sx={{
                    flex: 1,
                  }}
                  size="large"
                  user={son}
                  display="flex"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="column" ml={1}>
                    <Typography variant="body" lineHeight={'16px'}>
                      {fullName}
                    </Typography>
                    <Typography
                      variant="body"
                      lineHeight={'14px'}
                      fontSize={'12px'}
                      color={'text.secondary'}
                      mt={0.5}
                    >
                      {rolesName}
                    </Typography>
                  </Box>
                </UserAvatar>

                <Tooltip title={t`Eliminar vínculo`}>
                  <IconButton
                    disabled={isLoading || isRemovingSon}
                    color="warning"
                    size="small"
                    onClick={() => handleRemoveSon(son.id)}
                  >
                    <Trash size={18} />
                  </IconButton>
                </Tooltip>
              </Stack>
            );
          })}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
          disabled={isLoading || isRemovingSon}
        >
          <Trans>Cancelar</Trans>
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleClose}
          disabled={isLoading || isRemovingSon}
        >
          <Trans>Guardar</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LinkChildrenDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default LinkChildrenDialog;
