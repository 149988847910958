import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Skeleton, Typography } from '@mui/material';

import CustomRole from './CustomRole';

const NameInfo = ({
  userInfo,
  name,
  loading,
  gridProps,
  isCustomRoleEditable,
}) => (
  <Grid container direction={'column'} {...gridProps}>
    <Grid item>
      {loading ? (
        <Skeleton width={100} />
      ) : (
        <Typography variant="h1" component="h2" sx={{ display: 'flex' }}>
          {name}
        </Typography>
      )}
    </Grid>
    <CustomRole user={userInfo} isEditable={isCustomRoleEditable} />
  </Grid>
);

NameInfo.propTypes = {
  userInfo: PropTypes.object,
  gridProps: PropTypes.object,
  name: PropTypes.string,
  loading: PropTypes.bool,
  isCustomRoleEditable: PropTypes.bool,
};

export default NameInfo;
