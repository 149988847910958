import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { singleFamilyTaxInformationUrl } from 'src/shared/services/url/UrlPaymentManagement';
import Api from 'src/shared/services/api/api';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const SUCCESS_MESSAGE = t`Datos de facturación actualizados con éxito.`;
const ERROR_MESSAGE = t`Hubo un error al intentar eliminar los datos de facturación`;

const useUpdateFamilyTaxInformation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const apiUpdate = (params) => {
    return Api.put({
      url: singleFamilyTaxInformationUrl(params.id),
      data: params,
    });
  };
  return useMutation(apiUpdate, {
    onSuccess: () => {
      enqueueSnackbar(SUCCESS_MESSAGE, {
        variant: 'success',
      });
    },
    onError: ({ message: errorMessage }) => {
      enqueueSnackbar(errorMessage ?? ERROR_MESSAGE, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEY.FAMILY_TAX_INFORMATION], {
        refetchType: 'all',
      });
    },
  });
};

export default useUpdateFamilyTaxInformation;
