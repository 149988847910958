import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { formatUserRole } from 'src/shared/helpers';
import { ROLE_LIST } from 'src/config/general';
import { ROLES_ORDER } from 'src/config/constants';

import clsx from 'clsx';
import { sortBySequence } from 'src/shared/helpers/sortBySequence';
import Select from './PublicationSelect';
import useStyles from './styles';
import usePublicationSelector from 'src/components/Filters/StructureFilters/usePublicationSelector';

const StructureFilters = memo(
  ({
    variant = 'roles',
    onChange,
    onClose,
    disabled,
    multiple = true,
    overrides = {},
    initialValues = {
      levels: [],
      divisions: [],
      subjects: [],
      roles: [],
    },
    selectStyles,
    type,
    hiddenElements = ['none'],
    resetElements = [],
    className,
    showExtracurriculars,
    isPortalView,
    forceReset = false,
  }) => {
    const {
      levels: initialLevels,
      divisions: initialDivisions,
      subjects: initialSubjects,
      roles: initialRoles,
    } = initialValues;

    const classes = useStyles();

    const [selectedLevels, setSelectedLevels] = useState(
      initialValues.levels ?? [],
    );
    const [selectedDivisions, setSelectedDivisions] = useState(
      initialValues.divisions ?? [],
    );
    const [selectedSubjects, setSelectedSubjects] = useState(
      initialValues.subjects ?? [],
    );
    const [selectedRoles, setSelectedRoles] = useState(
      initialValues.roles ?? [],
    );

    const [divisions, setDivisions] = useState([]);
    const [subjects, setSubjects] = useState([]);

    const { user, configuration } = useSelector((store) => ({
      user: store.user,
      configuration: store.configuration,
    }));

    const { roleTypes } = configuration;
    const { selectedInstitution, selectedInstitutionPeriodId } = user;

    const { levels: rawLevels } =
      (overrides.levels
        ? overrides
        : selectedInstitution?.periods.find(
            (period) => period.id === selectedInstitutionPeriodId,
          )) ?? {};

    const levels = useMemo(() => sortBySequence(rawLevels), [rawLevels]);
    const divisionsMenu = useMemo(() => {
      return (overrides.divisions ? overrides.divisions : divisions) ?? {};
    }, [divisions, overrides.divisions]);

    const roles = Object.keys(roleTypes)
      .map((roleId) => ({
        id: parseInt(roleId, 10),
        value: roleTypes[roleId],
        name: formatUserRole(roleTypes[roleId], true),
      }))
      .filter((role) => role.value !== ROLE_LIST.ADMIN)
      .sort((a, b) => {
        return ROLES_ORDER.indexOf(a.value) - ROLES_ORDER.indexOf(b.value);
      });
    const isElementHidden = (element) => hiddenElements.includes(element);

    const {
      handleChangeLevel,
      handleChangeDivision,
      handleChangeSubject,
      handleRoleChange,
      handleReset,
      handleResetElements,
    } = usePublicationSelector({
      levels,
      divisions,
      setDivisions,
      setSubjects,
      subjects,
      selectedLevels,
      selectedDivisions,
      selectedSubjects,
      selectedRoles,
      setSelectedLevels,
      setSelectedDivisions,
      setSelectedSubjects,
      setSelectedRoles,
      multiple,
      showExtracurriculars,
      isPortalView,
    });

    useEffect(() => {
      const filteredDivisions = divisions.filter(
        (division) => typeof division.id === 'number',
      );

      onChange({
        levels: selectedLevels,
        divisions: selectedDivisions,
        subjects: selectedSubjects,
        roles: selectedRoles,

        isAllLevels: selectedLevels.length === levels.length,
        isAllDivisions: selectedDivisions.length === filteredDivisions.length,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      divisions,
      levels.length,
      selectedDivisions,
      selectedLevels,
      selectedRoles,
      selectedSubjects,
    ]);

    useEffect(() => {
      const isResetting =
        !initialLevels?.length &&
        !initialRoles?.length &&
        !initialDivisions?.length &&
        !initialSubjects?.length;

      const hasSelectedItems =
        selectedLevels.length ||
        (selectedDivisions.length && selectedRoles.length);
      // disabled = isPosting
      if ((isResetting && hasSelectedItems && disabled) || forceReset) {
        handleReset();
      }

      if (hasSelectedItems && disabled && resetElements.length) {
        handleResetElements(resetElements);
      }
    }, [
      disabled,
      handleReset,
      handleResetElements,
      resetElements,
      initialLevels,
      initialDivisions,
      initialRoles,
      initialSubjects,
      selectedDivisions,
      selectedLevels,
      selectedRoles,
      forceReset,
    ]);

    return (
      <Box className={clsx(classes.root, className)}>
        {!isElementHidden('levels') && (
          <Select
            menuData={overrides.levels ?? levels}
            label={t`Nivel`}
            selectedItems={selectedLevels}
            initialValue={initialValues.levels}
            onSelectItem={handleChangeLevel}
            onClose={onClose}
            disabled={disabled}
            multiple={multiple}
            selectAll={multiple}
            style={{ ...selectStyles }}
            type={type}
          />
        )}

        {!isElementHidden('divisions') && (
          <Select
            menuData={divisionsMenu}
            label={t`División`}
            selectedItems={selectedDivisions}
            initialValue={initialValues.divisions}
            onSelectItem={handleChangeDivision}
            onClose={onClose}
            disabled={!selectedLevels.length || disabled}
            multiple={multiple}
            selectAll={multiple}
            style={{ ...selectStyles }}
            type={type}
          />
        )}

        {!isElementHidden('subjects') && variant === 'subjects' && (
          <Select
            menuData={overrides.subjects ?? subjects}
            label={t`Materias`}
            selectedItems={selectedSubjects}
            initialValue={initialValues.subjects}
            selectAll={multiple}
            onSelectItem={handleChangeSubject}
            onClose={onClose}
            disabled={
              disabled ||
              !selectedLevels.length ||
              !selectedDivisions.length ||
              (!overrides.subjects && !subjects)
            }
            multiple={multiple}
            style={{ ...selectStyles }}
            type={type}
          />
        )}

        {!isElementHidden('roles') && variant === 'roles' && (
          <Select
            menuData={overrides.roles ?? roles}
            label={t`Rol`}
            selectedItems={selectedRoles}
            initialValue={initialValues.roles}
            onSelectItem={handleRoleChange}
            onClose={onClose}
            disabled={disabled}
            multiple={multiple}
            selectAll={multiple}
            style={{ ...selectStyles }}
            type={type}
          />
        )}
      </Box>
    );
  },
);

StructureFilters.propTypes = {
  variant: PropTypes.oneOf(['none', 'roles', 'subjects']).isRequired,
  hiddenElements: PropTypes.array,
  resetElements: PropTypes.array,
  multiple: PropTypes.bool,
  type: PropTypes.oneOf(['none']),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selectStyles: PropTypes.object,
  className: PropTypes.string,
  showExtracurriculars: PropTypes.bool,
  overrides: PropTypes.shape({
    levels: PropTypes.array,
    divisions: PropTypes.array,
    subjects: PropTypes.array,
    roles: PropTypes.array,
  }),
  initialValues: PropTypes.shape({
    levels: PropTypes.array,
    divisions: PropTypes.array,
    subjects: PropTypes.array,
    roles: PropTypes.array,
  }),
  isPortalView: PropTypes.bool,
};

export default StructureFilters;
