import { API_COMMUNICATION_BASE, BILLING_BASE_URL } from 'src/config/general';

// TO-DO: Refactor in another issue
export const getNotification = () =>
  `${API_COMMUNICATION_BASE}/api/notification`;

export const markNotificationViewAll = () =>
  `${API_COMMUNICATION_BASE}/api/notification/view-all`;

export const hasNotifications = () =>
  `${API_COMMUNICATION_BASE}/api/notification/has-notification`;

export const getAssist = (limit) =>
  `${API_COMMUNICATION_BASE}/api/score/api/attendance/institution-period/${limit}`;

export const search = () => `${API_COMMUNICATION_BASE}/api/search`;

export const getEventsUrl = () => `${API_COMMUNICATION_BASE}/api/event`;

export const getInvitedUsersUrl = (eventId) =>
  `${API_COMMUNICATION_BASE}/api/event/${eventId}/users`;

export const putRsvpUrl = (eventId) =>
  `${API_COMMUNICATION_BASE}/api/event/rvsp/${eventId}`;

export const editEventUrl = (eventId) =>
  `${API_COMMUNICATION_BASE}/api/event/${eventId}`;

export const eventReminder = (eventId) =>
  `${API_COMMUNICATION_BASE}/api/event/${eventId}/reminder`;

export const eventReport = (eventId) =>
  `${API_COMMUNICATION_BASE}/api/event/${eventId}/report`;

export const getSonLastScores = (userId, institutionId) =>
  `${API_COMMUNICATION_BASE}/api/score/user/${userId}/institution-period/${institutionId}/last/3`;

export const getSonScore = (userId, institutionId) =>
  `${API_COMMUNICATION_BASE}/api/score/user/${userId}/institution-period/${institutionId}`;

export const getSonSubjectScore = (userId, subjectId) =>
  `${API_COMMUNICATION_BASE}/api/score/user/${userId}/subject/${subjectId}`;

export const getSonAbsences = () =>
  `${API_COMMUNICATION_BASE}/api/attendance/institution-period/2`;

export const getConfig = () => `${API_COMMUNICATION_BASE}/api/config/app`;

export const eventByMonth = (from, to) =>
  `${API_COMMUNICATION_BASE}/api/event?date_from=${from}&date_to=${to}`;

export const getPostById = (postId) =>
  `${API_COMMUNICATION_BASE}/api/post?id=${postId}`;

export const getAllPosts = () => `${API_COMMUNICATION_BASE}/api/post`;

export const getCommentsByPostId = (postId) =>
  `${API_COMMUNICATION_BASE}/api/post-comment/post/${postId}`;

export const postComment = () => `${API_COMMUNICATION_BASE}/api/post-comment`;

export const deletePostComment = (id) =>
  `${API_COMMUNICATION_BASE}/api/post-comment/${id}`;

export const likePost = (postId) =>
  `${API_COMMUNICATION_BASE}/api/like/post/${postId}`;

export const postById = (postId) =>
  `${API_COMMUNICATION_BASE}/api/post/${postId}`;

export const postStudentsList = (postId) =>
  `${API_COMMUNICATION_BASE}/api/post/${postId}/students`;

export const getEventById = (eventId) =>
  `${API_COMMUNICATION_BASE}/api/event?id=${eventId}`;

export const notificationUserConfig = () =>
  `${API_COMMUNICATION_BASE}/api/notification/user-config`;

export const markNotificationViewed = (notificationId) =>
  `${API_COMMUNICATION_BASE}/api/notification/viewed/${notificationId}`;

export const favoritePost = (postId) =>
  `${API_COMMUNICATION_BASE}/api/favorite/post/${postId}`;

export const markPostViewed = () => `${API_COMMUNICATION_BASE}/api/post-view`;

export const postViews = (postId) =>
  `${API_COMMUNICATION_BASE}/api/post-view/post/${postId}`;

export const postListUsers = (postId) =>
  `${API_COMMUNICATION_BASE}/api/post/${postId}/users-list`;

export const postListViews = (postId) =>
  `${API_COMMUNICATION_BASE}/api/post/${postId}/users-view`;

export const postConfirmRead = (postId) =>
  `${API_COMMUNICATION_BASE}/api/post/${postId}/confirm`;

export const postReminder = (postId) =>
  `${API_COMMUNICATION_BASE}/api/post/${postId}/reminder`;

export const downloadPostReport = (postId) =>
  `${API_COMMUNICATION_BASE}/api/post/${postId}/users-report`;

export const postReadConfirmation = (postId) =>
  `${API_COMMUNICATION_BASE}/api/post/${postId}/user-confirmation`;

export const postViewsByDivision = (postId, divisionId) =>
  `${API_COMMUNICATION_BASE}/api/post-view/post/${postId}`;

export const getFavoritesPosts = () => `${API_COMMUNICATION_BASE}/api/favorite`;

export const postPublication = () => `${API_COMMUNICATION_BASE}/api/post`;

export const editPostPublication = (id) =>
  `${API_COMMUNICATION_BASE}/api/post/${id}`;

export const getUploadToken = () =>
  `${API_COMMUNICATION_BASE}/api/storage/token`;

export const getOpenGraphUrl = () =>
  `${API_COMMUNICATION_BASE}/api/config/ogdata`;

export const groupsUrl = (groupId) =>
  `${API_COMMUNICATION_BASE}/api/group${groupId ? `?id=${groupId}` : ''}`;

export const editGroupUrl = (groupId) =>
  `${API_COMMUNICATION_BASE}/api/group/${groupId}`;

export const groupsMembersUrl = (groupId) =>
  `${API_COMMUNICATION_BASE}/api/group/${groupId}/users`;

export const groupSearchAddUsers = () =>
  `${API_COMMUNICATION_BASE}/api/group/users`;

export const joinGroupUrl = (groupId) =>
  `${API_COMMUNICATION_BASE}/api/group/${groupId}/join`;

export const addBulkUsersGroup = (groupId) =>
  `${API_COMMUNICATION_BASE}/api/group/${groupId}/bulk-user`;

export const leaveGroupUrl = (groupId) =>
  `${API_COMMUNICATION_BASE}/api/group/${groupId}/leave`;

export const activityGroups = () =>
  `${API_COMMUNICATION_BASE}/api/group?available_for_activities=1&per-page=1000&paginate=false&fields=id,name`;

export const studentNoteBookTypes = (institutionPeriodId) =>
  `${API_COMMUNICATION_BASE}/api/student-notebook/types/institution-period/${institutionPeriodId}`;

export const studentNoteBook = () =>
  `${API_COMMUNICATION_BASE}/api/student-notebook`;

export const studentNoteBookSearch = () =>
  `${API_COMMUNICATION_BASE}/api/student-notebook/search`;

export const studentNoteBookType = () =>
  `${API_COMMUNICATION_BASE}/api/student-notebook-type`;

export const studentNoteBookTypeById = ({ notebookTypeId }) =>
  `${API_COMMUNICATION_BASE}/api/student-notebook-type/${notebookTypeId}`;

export const signStudentNoteBook = (noteBookId) =>
  `${API_COMMUNICATION_BASE}/api/student-notebook/${noteBookId}/signed`;

export const viewStudentNoteBook = (noteBookId) =>
  `${API_COMMUNICATION_BASE}/api/student-notebook/${noteBookId}/viewed`;

export const getAllInvoice = () => `${BILLING_BASE_URL}/invoice/all-invoices`;

export const getInvoicePDF = (invoiceId) =>
  `${BILLING_BASE_URL}/invoice/${invoiceId}/pdf`;

export const permissionSendMessage = (userId) =>
  `${API_COMMUNICATION_BASE}/api/conversation-whitelist/message/${userId}`;

export const getConversationWhiteList = (userId, periodId) =>
  `${API_COMMUNICATION_BASE}/api/conversation-whitelist/institution-period/${periodId}/users/${userId}`;

export const updateConversationWhiteList = (periodId) =>
  `${API_COMMUNICATION_BASE}/api/conversation-whitelist/institution-period/${periodId}/users`;

export const postConversationWhiteListBulkUsers = (periodId) =>
  `${API_COMMUNICATION_BASE}/api/conversation-whitelist/institution-period/${periodId}/bulk-users`;

export const getConversationSchedule = () =>
  `${API_COMMUNICATION_BASE}/api/conversation-schedule`;

export const createConversationSchedule = () =>
  `${API_COMMUNICATION_BASE}/api/conversation-schedule`;

export const updateConversationSchedule = (id) =>
  `${API_COMMUNICATION_BASE}/api/conversation-schedule/${id}`;

export const uploadGoogleDriveImageUrl = () =>
  `${API_COMMUNICATION_BASE}/api/storage/drive`;

export const getConversation = () =>
  `${API_COMMUNICATION_BASE}/api/conversation`;

export const getDraftConversation = () =>
  `${API_COMMUNICATION_BASE}/api/conversation-draft`;

export const getScheduledConversation = () =>
  `${API_COMMUNICATION_BASE}/api/conversation-draft/scheduled`;

export const getScheduledConversationDetail = (conversationId) =>
  `${API_COMMUNICATION_BASE}/api/conversation-draft/${conversationId}/view`;

export const postDraftConversation = () =>
  `${API_COMMUNICATION_BASE}/api/conversation-draft`;

export const getScheduledConfig = () =>
  `${API_COMMUNICATION_BASE}/api/conversation-schedule`;

export const postScheduledConversation = () =>
  `${API_COMMUNICATION_BASE}/api/conversation-draft/scheduled`;

export const deleteScheduledConversation = (conversationId) =>
  `${API_COMMUNICATION_BASE}/api/conversation-draft/${conversationId}`;

export const updateScheduledConversation = (conversationId) =>
  `${API_COMMUNICATION_BASE}/api/conversation-draft/${conversationId}/scheduled`;

export const updateDraftConversation = (conversationId) =>
  `${API_COMMUNICATION_BASE}/api/conversation-draft/${conversationId}`;

export const getUnreadMessages = (periodId) =>
  `${API_COMMUNICATION_BASE}/api/conversation/institution-period/${periodId}/conversations-with-news`;

export const getConversationMessages = (conversationId) =>
  `${API_COMMUNICATION_BASE}/api/conversation-message/conversation/${conversationId}`;

export const getMessagesUserSearch = (periodId) =>
  `${API_COMMUNICATION_BASE}/api/conversation/institution-period/${periodId}/users-search`;

export const getMessagesUserWhitelist = (periodId) =>
  `${API_COMMUNICATION_BASE}/api/conversation-whitelist/institution-period/${periodId}/users`;

export const updateConversation = (conversationId) =>
  `${API_COMMUNICATION_BASE}/api/conversation/${conversationId}`;

export const updateUsersConversation = (conversationId) =>
  `${API_COMMUNICATION_BASE}/api/conversation/${conversationId}/user`;

export const deleteConversation = (conversationId, userId) =>
  `${API_COMMUNICATION_BASE}/api/conversation/${conversationId}/user/${userId}`;

export const printConversation = (conversationId) =>
  `${API_COMMUNICATION_BASE}/api/conversation/${conversationId}/print`;

export const setMessagesConversationSeen = () =>
  `${API_COMMUNICATION_BASE}/api/conversation-message/seen`;

export const setMessagesConversationNotSeen = (conversationId) =>
  `${API_COMMUNICATION_BASE}/api/conversation-message/${conversationId}/not-seen`;

export const getConversationById = (periodId) =>
  `${API_COMMUNICATION_BASE}/api/conversation/institution-period/${periodId}`;

export const getBehaviorCategory = () =>
  `${API_COMMUNICATION_BASE}/api/behavior-category`;

export const behaviorCategoryById = (categoryId) =>
  `${API_COMMUNICATION_BASE}/api/behavior-category/${categoryId}`;

export const getBehaviors = () => `${API_COMMUNICATION_BASE}/api/behavior`;

export const behaviorById = (behaviorId) =>
  `${API_COMMUNICATION_BASE}/api/behavior/${behaviorId}`;

export const readBehaviorById = (behaviorId) =>
  `${API_COMMUNICATION_BASE}/api/behavior/${behaviorId}/read`;

export const behaviourReport = () =>
  `${API_COMMUNICATION_BASE}/api/behavior/report`;

export const reportBatch = (id) =>
  `${API_COMMUNICATION_BASE}/api/report/batch/${id}`;

export const batchTransactions = (id) =>
  `${API_COMMUNICATION_BASE}/api/batch-transaction/${id}`;

export const activity = () => `${API_COMMUNICATION_BASE}/api/activity`;

export const activityById = (activityId) =>
  `${API_COMMUNICATION_BASE}/api/activity/${activityId}`;

export const activityEnrolledByActivityId = (activityId) =>
  `${API_COMMUNICATION_BASE}/api/activity/${activityId}/enrolled`;

export const removeEnrolled = (activityId, userId) =>
  `${API_COMMUNICATION_BASE}/api/activity/${activityId}/remove/${userId}`;

export const activityEnrolledReport = (activityId) =>
  `${API_COMMUNICATION_BASE}/api/activity/${activityId}/enrolled/report`;
