import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import FilterButton from './FilterButton';

const SearchSuggestions = ({ filters, onSelectFilter }) => (
  <Box display="flex" flexDirection="row" mt={2}>
    {filters.map((filter) => (
      <FilterButton
        key={filter.id}
        icon={filter.icon}
        text={filter.name}
        onClick={() => onSelectFilter(filter)}
      />
    ))}
  </Box>
);

SearchSuggestions.propTypes = {
  filters: PropTypes.array,
  onSelectFilter: PropTypes.func,
};

export default SearchSuggestions;
