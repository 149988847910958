import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const Image = ({ failureImage, ...props }) => {
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  const handleError = () => {
    if (!imageLoadFailed) setImageLoadFailed(true);
  };

  if (imageLoadFailed) {
    return failureImage;
  }

  return <Box component={'img'} {...props} onError={handleError} alt="fidu" />;
};

Image.propTypes = {
  failureImage: PropTypes.node,
};

export default Image;
