import { toLocaleCurrency } from 'src/shared/utils/currencies';
import {
  harcCodeValuesReceiverMx,
  hardCodeInitValuesMx,
} from 'src/features/payment-management/forms/PaymentGenerateBillsForm/MX/helpers';

export const TAXS_OPTIONS = [
  {
    name: 'IVA Exento',
    value: 0,
  },
  {
    name: 'IVA 16%',
    value: 16,
  },
];

const formattedDataMX = (
  familyTaxInformation,
  institutionTaxInformation,
  invoices,
  switchForm,
) => {
  const initialValues = hardCodeInitValuesMx;
  let valuesReceiver = harcCodeValuesReceiverMx;
  let valuesInstitution = {};
  let valuesTaxes = {};

  if (familyTaxInformation) {
    initialValues.hasResponsibleBiller = true;
    initialValues.switchReceiver = switchForm;

    if (!switchForm) {
      valuesReceiver = {
        documentFamily: familyTaxInformation.document,
        zipCodeFamily: familyTaxInformation.zip_code,
        nameFamily: familyTaxInformation.name,
        taxRegimeFamily: familyTaxInformation.tax_regime_id,
        useCFDIFamily: familyTaxInformation.cfdi_use_id,
        addressFamily: familyTaxInformation.address,
      };
    }
  } else {
    initialValues.switchReceiver = true;
    valuesReceiver.zipCodeFamily = institutionTaxInformation?.zip_code;
  }

  if (institutionTaxInformation) {
    valuesInstitution = {
      document: institutionTaxInformation.document,
      zipCode: institutionTaxInformation.zip_code,
      name: institutionTaxInformation.name,
      taxRegime: institutionTaxInformation.tax_regime_id,
      cfdiUse: institutionTaxInformation.cfdi_use_id,
      expeditionPlace: institutionTaxInformation?.zip_code,
      autRvoe: institutionTaxInformation.aut_rvoe,
    };
  }

  if (invoices.length) {
    const { currency, total_invoiced, campaign } = invoices[0];
    const base = toLocaleCurrency(total_invoiced, { currency });
    let total = total_invoiced;
    let productCode = initialValues.productCode;
    let rate = initialValues.rate;
    let productDescription = initialValues.productDescription;
    let taxable = initialValues.taxable;

    if (campaign?.product_code) {
      const increase = (total * campaign.iva) / 100;
      total = toLocaleCurrency(total + increase, { currency });

      productCode = campaign.product_code;
      rate = campaign.iva;
      productDescription = '';
      taxable = TAXS_OPTIONS.find((tax) => rate === tax.value).name;
    }

    valuesTaxes = {
      base: base,
      taxTotal: total,
      productUnitPrice: base,
      productTotal: base,
      productCode,
      rate,
      productDescription,
      taxable,
    };
  }

  const values = {
    ...initialValues,
    ...valuesInstitution,
    ...valuesReceiver,
    ...valuesTaxes,
  };

  return values;
};

export default formattedDataMX;
