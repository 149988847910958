import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  IconButton,
  SwipeableDrawer,
  useMediaQuery,
} from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { AnimatePresence } from 'framer-motion';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { ArrowForward } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import selectedNavbarModuleAtom from 'src/shared/atoms/navbar/selectedNavbarModuleAtom';
import useNavBar from 'src/features/sidebar/hooks/useNavBar';
import isMobileNavOpenAtom from 'src/shared/atoms/navbar/isMobileNavOpenAtom';

import {
  createRailTheme,
  createNavbarTheme,
} from 'src/shared/utils/navigation';
import NavigationRail from 'src/features/sidebar/components/RailBar';
import NavigationList from 'src/features/sidebar/components/NavigationList';

const NavBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const options = useNavBar();

  const selectedInstitutionId = useSelector(
    ({ user }) => user.selectedInstitution.id,
  );

  const [isMobileNavOpen, seIsMobileNavOpen] = useAtom(isMobileNavOpenAtom);
  const [selectedNavbarModule, setSelectedNavbarModule] = useAtom(
    selectedNavbarModuleAtom,
  );

  if (isMobile) {
    return (
      <ThemeProvider theme={(theme) => createNavbarTheme(theme)}>
        <SwipeableDrawer
          variant="temporary"
          anchor="left"
          open={isMobileNavOpen}
          elevation={1}
          disablePortal
          onClose={() => {
            setSelectedNavbarModule(null);
            seIsMobileNavOpen(false);
          }}
          onOpen={() => {}}
        >
          <AnimatePresence>
            {!selectedNavbarModule && (
              <NavigationList
                sx={{ width: 280 }}
                options={options}
                onClickItem={(mod) =>
                  !mod?.href && setSelectedNavbarModule(mod)
                }
                renderItemAction={(mod) => {
                  // Si es el item de la institucion, no mostrar action
                  if (mod.id === selectedInstitutionId) return null;

                  return (
                    <IconButton size="small">
                      <ArrowForward fontSize="inherit" />
                    </IconButton>
                  );
                }}
              />
            )}

            {selectedNavbarModule && (
              <NavigationList
                sx={{ width: 280 }}
                onClickItem={(item) => {
                  if (item.id !== 'back') return;
                  setSelectedNavbarModule(null);
                }}
                options={[
                  {
                    id: 'back',
                    title: selectedNavbarModule?.subheader,
                    icon: <ArrowBack />,
                  },
                  ...selectedNavbarModule?.items,
                ]}
              />
            )}
          </AnimatePresence>
        </SwipeableDrawer>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={(theme) => createNavbarTheme(theme)}>
      <Box onMouseLeave={() => setSelectedNavbarModule(null)}>
        <Drawer
          variant="temporary"
          anchor="left"
          hideBackdrop
          open={Boolean(selectedNavbarModule)}
          elevation={1}
          disablePortal
        >
          <AnimatePresence>
            {selectedNavbarModule && (
              <NavigationList
                sx={{ width: 280 }}
                subheader={selectedNavbarModule.subheader}
                options={selectedNavbarModule.items}
                renderItemAction={selectedNavbarModule.renderItemAction}
              />
            )}
          </AnimatePresence>
        </Drawer>

        <ThemeProvider theme={(theme) => createRailTheme(theme)}>
          <NavigationRail
            options={options}
            onClickOption={setSelectedNavbarModule}
            selected={selectedNavbarModule}
          />
        </ThemeProvider>
      </Box>
    </ThemeProvider>
  );
};

NavBar.propTypes = {
  onToggle: PropTypes.func,
};

export default NavBar;
