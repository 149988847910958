/**
 * Merges all types of routers (merged & single) into a single object.
 *
 * @template T
 * @param {T} config - The routers to merge.
 * @returns {{ [K in keyof T]: T[K] }} A merged router object.
 */
const createRouterSchema = (config) => {
  const schema = {};

  Object.keys(config).forEach((routerKey) => {
    const router = config[routerKey];
    const routerSchema = {};

    Object.keys(router).forEach((key) => {
      routerSchema[key] = router[key];
    });

    schema[routerKey] = routerSchema;
  });

  return schema;
};

export default createRouterSchema;
