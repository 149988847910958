import { Typography } from '@mui/material';

const columns = [
  {
    title: '',
    key: 'name',
    render: ({ name }) => (
      <Typography variant="subtitle" fontWeight={500}>
        {name}
      </Typography>
    ),
  },
];

export { columns };
