import { useRef, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';
import { t } from '@lingui/macro';

import { Api as api } from 'src/shared/services/api';
import {
  paymentManagementUsers,
  paymentManagementVariation,
  paymentManagementVariationUser,
  paymentManagementSingleVariation,
  paymentManagementVariationUsersRemove,
} from 'src/shared/services/url/UrlPaymentManagement';

import { toDateFormat } from 'src/shared/utils/dates';

const defaultErrorMessage = t`Ha ocurrido un error. Intente mas tarde.`;
const defaultParams = { page: 0, limit: 20, search: '', byRecurrent: false };

const usePaymentManagementDiscounts = () => {
  const [discounts, setDiscounts] = useState([]);
  const [usersByConcept, setUsersByConcept] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [pagination, setPagination] = useState({
    total: 0,
    page: 0,
    limit: 20,
  });
  const [isLoadingDiscounts, setIsLoadingDiscounts] = useState(false);
  const [isCreatingDiscount, setIsCreatingDiscount] = useState(false);
  const [isDeletingDiscount, setIsDeletingDiscount] = useState(false);
  const [isLoadingSelectedMembers, setIsLoadingSelectedMembers] =
    useState(false);
  const [isLoadingUsersByConcept, setIsLoadingUsersByConcept] = useState(false);
  const [filteredSelectedMembers, setFilteredSelectedMembers] = useState(null);

  const [selectedMembers, setSelectedMembers] = useState([]);

  const initialSelectedMembers = useRef([]);

  const { enqueueSnackbar } = useSnackbar();

  const resetStepsFields = useCallback(() => {
    setCurrentStep(0);
    setSelectedMembers([]);
  }, []);

  const fetchPaymentManagementDiscounts = useCallback(
    async ({
      page = 0,
      limit = 20,
      search,
      byRecurrent,
      ...params
    } = defaultParams) => {
      setIsLoadingDiscounts(true);
      try {
        const { data: raw, headers } = await api.get({
          url: paymentManagementVariation(),
          getRaw: true,
          data: {
            name: search,
            page: !page ? 1 : page + 1,
            perPage: limit,
            recurrent: byRecurrent,
            expand: 'rate,userRate',
            ...params,
          },
        });

        setDiscounts(raw.data);
        setPagination({
          page,
          limit,
          total: Number(headers['x-pagination-total-count']),
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingDiscounts(false);
      }
    },
    [enqueueSnackbar],
  );

  const fetchPaymentManagementUsersByConcept = useCallback(
    async (concept) => {
      setIsLoadingUsersByConcept(true);
      try {
        const { data: raw } = await api.get({
          url: paymentManagementUsers(concept.id),
          getRaw: true,
          data: {
            perPage: 2000,
          },
        });

        setUsersByConcept(raw.data);
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingUsersByConcept(false);
      }
    },
    [enqueueSnackbar],
  );

  const debouncedFetchAllPaymentDiscounts = useDebouncedCallback((event) => {
    fetchPaymentManagementDiscounts(event);
  }, 300);

  const fetchPaymentManagementSelectedMembers = useCallback(
    async (discount) => {
      setIsLoadingSelectedMembers(true);
      try {
        const { data: raw } = await api.get({
          url: paymentManagementVariationUser(discount.id),
          getRaw: true,
          data: {
            'per-page': 2000,
          },
        });

        setSelectedMembers(raw.data);
        initialSelectedMembers.current = raw.data;
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsLoadingSelectedMembers(false);
      }
    },
    [enqueueSnackbar],
  );

  const handlePageChange = useCallback(
    async (newPage) => {
      await fetchPaymentManagementDiscounts({
        ...pagination,
        page: newPage,
      });
    },
    [fetchPaymentManagementDiscounts, pagination],
  );

  const handleLimitChange = useCallback(
    async (limit) => {
      await fetchPaymentManagementDiscounts({
        ...pagination,
        limit,
      });
    },
    [fetchPaymentManagementDiscounts, pagination],
  );

  const handleSearch = useCallback(
    async (event, params) => {
      const { value: query } = event.target;

      setSearchValue(query);

      await debouncedFetchAllPaymentDiscounts({
        ...pagination,
        ...params,
        search: query,
      });
    },
    [debouncedFetchAllPaymentDiscounts, pagination],
  );

  const handleClearSearch = useCallback(async () => {
    setSearchValue('');

    await fetchPaymentManagementDiscounts({
      ...pagination,
      search: '',
    });
  }, [fetchPaymentManagementDiscounts, pagination]);

  const handleSelectMember = useCallback((member) => {
    setSelectedMembers((oldSelectedMembers) => {
      const isMemberExistent = oldSelectedMembers.find(
        (oldMember) => oldMember.id === member.id,
      );

      if (isMemberExistent) {
        return oldSelectedMembers.filter(
          (oldMember) => oldMember.id !== member.id,
        );
      }

      return [...oldSelectedMembers, member];
    });
  }, []);

  const handleSelectAllMembers = useCallback(
    (allMembers) => {
      if (allMembers.length === selectedMembers.length) {
        setSelectedMembers([]);
      } else {
        setSelectedMembers(allMembers);
      }
    },
    [selectedMembers],
  );

  const handleFilterSelectedMemberByName = useCallback(
    (searchText = '') => {
      setFilteredSelectedMembers(() => {
        if (searchText) {
          return selectedMembers.filter((member) =>
            `${member.name} ${member.last_name}`
              .toLowerCase()
              .includes(searchText.toLowerCase()),
          );
        }

        return null;
      });
    },
    [selectedMembers],
  );

  const handleCreateDiscount = useCallback(
    async ({ isMonthly, discount, members }) => {
      setIsCreatingDiscount(true);

      try {
        const { data: newDiscount } = await api.post({
          url: paymentManagementVariation(),
          data: {
            entity_id: discount.rate.id,
            entity_type: 'rate',
            name: discount.name,
            value: discount.amount,
            type: 'discount',
            apply_type: 'percent',

            recurrent: isMonthly,
            ...(!isMonthly
              ? {
                  start_at: toDateFormat(discount.startAt, 'YYYY-MM-DD'),
                  finish_at: toDateFormat(discount.endAt, 'YYYY-MM-DD'),
                }
              : undefined),
          },
        });

        await api.post({
          url: paymentManagementVariationUser(newDiscount.id),
          data: members.map((member) => ({
            user_id: member.id,
          })),
        });

        setDiscounts((oldDiscounts) => [
          { ...newDiscount, total_student: members.length },
          ...oldDiscounts,
        ]);

        setPagination((oldPagination) => ({
          ...oldPagination,
          total: oldPagination.total + 1,
        }));

        enqueueSnackbar(t`Descuento creado con éxito.`, { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsCreatingDiscount(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleDeleteDiscount = useCallback(
    async (discount) => {
      setIsDeletingDiscount(true);
      try {
        await api.delete({
          url: paymentManagementSingleVariation(discount.id),
        });

        setDiscounts((oldRates) =>
          oldRates.filter((oldRate) => oldRate.id !== discount.id),
        );

        setPagination((oldPagination) => ({
          ...oldPagination,
          total: oldPagination.total - 1,
        }));

        enqueueSnackbar(t`Descuento eliminado con éxito.`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsDeletingDiscount(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleEditDiscount = useCallback(
    async ({ isMonthly, discount, members }) => {
      setIsDeletingDiscount(true);

      try {
        // Carlito's fault
        const newMembers = members.filter(
          (member) =>
            !initialSelectedMembers.current.find(
              (selected) => member.id === selected.id,
            ),
        );

        const deletedMembers = initialSelectedMembers.current.filter(
          (selected) => !members.find((member) => selected.id === member.id),
        );

        const finalMembers = [
          ...initialSelectedMembers.current,
          ...newMembers,
        ].filter(
          (filteredMember) =>
            !deletedMembers.find(
              (deletedMember) => deletedMember.id === filteredMember.id,
            ),
        );

        if (newMembers.length > 0) {
          await api.post({
            url: paymentManagementVariationUser(discount.id),
            data: newMembers.map((member) => ({
              user_id: member.id,
            })),
          });
        }

        if (deletedMembers.length > 0) {
          await api.patch({
            url: paymentManagementVariationUsersRemove(discount.id),
            data: {
              user_id: deletedMembers.map((member) => member.id),
            },
          });
        }

        const { data: newDiscount } = await api.put({
          url: paymentManagementSingleVariation(discount.id),
          data: {
            entity_id: discount.rate.id,
            entity_type: 'rate',
            name: discount.name,
            value: discount.amount,
            type: 'discount',
            apply_type: 'percent',

            recurrent: isMonthly,
            ...(!isMonthly
              ? {
                  start_at: toDateFormat(discount.startAt, 'YYYY-MM-DD'),
                  finish_at: toDateFormat(discount.endAt, 'YYYY-MM-DD'),
                }
              : undefined),
          },
        });

        setSelectedMembers(finalMembers);

        setDiscounts((oldRates) =>
          oldRates.map((oldRate) => {
            if (oldRate.id === discount.id) {
              return { ...newDiscount, rate: discount.rate };
            }

            return oldRate;
          }),
        );

        enqueueSnackbar(t`Descuento editado con éxito.`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message ?? defaultErrorMessage, {
          variant: 'error',
        });
      } finally {
        setIsDeletingDiscount(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleRemoveAllSelected = () => {
    setSelectedMembers([]);
  };

  return {
    fetchPaymentManagementDiscounts, // -
    fetchPaymentManagementSelectedMembers, // -
    fetchPaymentManagementUsersByConcept,

    handleClearSearch,
    handleSearch,
    handleLimitChange,
    handlePageChange,
    handleSelectMember,
    handleSelectAllMembers,
    handleRemoveAllSelected,
    handleFilterSelectedMemberByName,
    handleCreateDiscount,
    handleDeleteDiscount,
    handleEditDiscount,

    discounts,
    pagination,
    searchValue,
    currentStep,
    selectedMembers,
    usersByConcept,
    filteredSelectedMembers,

    setCurrentStep,
    setSelectedMembers,
    resetStepsFields,

    isLoadingDiscounts,
    isCreatingDiscount,
    isDeletingDiscount,
    isLoadingSelectedMembers,
    isLoadingUsersByConcept,
  };
};

export default usePaymentManagementDiscounts;
