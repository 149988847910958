import { useGetPaginatedList } from 'src/shared/services/api/query';
import { paymentManagementUsers } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import useFilterValue from 'src/shared/hooks/filter/useFilterValue';
import sortByLastName from 'src/shared/utils/sortByLastName';

const useSelectedMembers = (rateId, params) => {
  const {
    initialPage,
    initialLimit,
    enabled = true,
    ...restParams
  } = params ?? {};

  const isEnabled = enabled && Boolean(rateId);

  const query = useGetPaginatedList(
    [QUERY_KEY.CONCEPT_SELECTED_MEMBERS, rateId, restParams],
    paymentManagementUsers(rateId),
    { paginate: 0, ...restParams },
    true,
    {
      enabled: isEnabled,
      ...(!isEnabled && { cacheTime: 0 }),
    },
    null,
    initialPage,
    initialLimit,
  );

  const filterValue = useFilterValue(QUERY_KEY.CONCEPT_SELECTED_MEMBERS);

  const filter = (data) =>
    data.filter((member) =>
      `${member.name} ${member.last_name}`
        .toLowerCase()
        .includes(filterValue.toLowerCase()),
    );

  if (query.isSuccess) {
    const sortedData = sortByLastName(query.data);
    const filteredData = filter(sortedData);
    return {
      ...query,
      data: sortedData,
      filteredData,
    };
  }

  return query;
};

export default useSelectedMembers;
