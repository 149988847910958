import makeStyles from '@mui/styles/makeStyles';
import { mobile } from 'src/shared/utils/breakpoints';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 2, 3, 2),

    [mobile]: {
      margin: theme.spacing(10, 1, 1, 1),
    },
  },
  MuiCardHeader: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export default useStyles;
