import React from 'react';
import { Trans } from '@lingui/macro';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';

import { BLENDED_MODULES } from 'src/config/constants';
import router from 'src/shared/utils/router';
import { ROLE_LIST } from 'src/config/general';

const useAiAssistantConfig = () => {
  return {
    id: BLENDED_MODULES.ASSISTANT.id,
    name: BLENDED_MODULES.ASSISTANT.name,
    subheader: <Trans>Asistente</Trans>,
    icon: <AutoAwesomeOutlinedIcon size={18} />,
    prefix: 'mi-asistente',
    items: [{}],
    href: router.assistant.root,
    roles: [ROLE_LIST.ADMIN],
  };
};

export default useAiAssistantConfig;
