import { atom } from 'jotai';
import stepFamily from './stepFamily';

const decStepAtom = atom(null, (get, set, payload) => {
  const stepAtom = stepFamily(payload);

  const step = get(stepAtom);
  const { current } = step;

  set(stepAtom, {
    ...step,
    current: current > 0 ? current - 1 : current,
  });
});

export default decStepAtom;
