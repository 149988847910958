import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { paymentManagementVariationFamily } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { Api as api } from 'src/shared/services/api';

const useAddFamilyMember = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const mutationFn = async ({ discountId, data }) =>
    api.post({
      url: `${paymentManagementVariationFamily(discountId)}?per-page=2000`,
      data,
    });

  return useMutation({
    mutationFn,
    onSuccess: () => {},
    onError: ({ message }) => {
      enqueueSnackbar(t`No se pudo vincular al estudiante. ${message}`, {
        variant: 'error',
      });
    },
    onSettled: (_data, _error, { discountId }) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.DISCOUNT_SELECTED_FAMILY_MEMBERS, discountId],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.DISCOUNTS],
      });
    },
  });
};

export default useAddFamilyMember;
