import makeStyles from '@mui/styles/makeStyles';
import { mobile } from 'src/shared/utils/breakpoints';
import { DEFAULT_COLOR } from 'src/theme/palette';

const useStyles = makeStyles((theme) => ({
  changesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    [mobile]: {
      width: '100%',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    fontSize: 24,
  },
  sparkle: {
    marginRight: theme.spacing(),
  },
  lastSparkle: {
    marginLeft: theme.spacing(),
  },
  newBadge: {
    color: DEFAULT_COLOR,
    fontWeight: 500,
    fontSize: 12,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    textTransform: 'uppercase',
  },
  bulletPoint: {
    color: '#414141',
    marginBottom: theme.spacing(2),
    // width: 340,
  },
  webinarDate: {
    fontWeight: 600,
    color: '#000',
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
  },
  webinarDateIcon: {
    marginRight: theme.spacing(),
  },
  linkButton: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  closeDialog: {
    marginLeft: theme.spacing(2),
    marginRight: -theme.spacing(),
    marginTop: -theme.spacing(),
  },
  webinarDisclaimer: {
    marginLeft: theme.spacing(2),
    fontSize: 12,
    color: theme.palette.grey['500'],
  },
}));

export default useStyles;
