import React from 'react';
import { Badge, Box, IconButton, Tooltip } from '@mui/material';
import { t } from '@lingui/macro';
import { useHistory } from 'react-router';
import Icon from '@mdi/react';
import { mdiEmailMultipleOutline } from '@mdi/js';
import { useGetUnreadConversations } from 'src/features/messages/hooks';
import router from 'src/shared/utils/router';

import useStyles from './styles';

const Messages = () => {
  const classes = useStyles();
  const history = useHistory();
  const { total, isLoading } = useGetUnreadConversations();
  const hasMessages = total > 0;

  const handleClick = () => history.push(router.communication.inbox.root);

  return (
    <Box mr={2}>
      <Tooltip title={t`Mensajes`}>
        <IconButton onClick={handleClick} size="large">
          <Badge
            classes={{ badge: classes.badge }}
            color="primary"
            variant="dot"
            invisible={!hasMessages || isLoading}
          >
            <Icon path={mdiEmailMultipleOutline} size="18px" />
          </Badge>
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default Messages;
