import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';
import { useHistory } from 'react-router';

import { usePut } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';
import { updateDraftConversation } from 'src/shared/services/url/UrlCommunication';
import useScheduledConversationDetail from 'src/features/messages/hooks/useScheduledConversationDetail';

const useUpdateDraft = (conversationId) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { resetScheduled } = useScheduledConversationDetail();

  return usePut(
    updateDraftConversation(conversationId),
    {
      onSuccess: () => {
        enqueueSnackbar(t`Mensaje actualizado con éxito.`, {
          variant: 'success',
        });
        queryClient.refetchQueries({
          queryKey: [QUERY_KEY.SCHEDULED_DETAIL, `${conversationId}`],
        });
        queryClient.refetchQueries({
          queryKey: [QUERY_KEY.DRAFT_CONVERSATIONS_LIST],
        });
        resetScheduled();
        history.goBack();
      },
      onError: () => {
        enqueueSnackbar(t`Hubo un error al intentar actualizar el mensaje`, {
          variant: 'error',
        });
      },
    },
    false,
  );
};

export default useUpdateDraft;
