import { init } from '@rematch/core';
import createRematchPersist from '@rematch/persist';
import localStorage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { logger } from 'redux-logger';
import * as Sentry from '@sentry/react';

import models from 'src/shared/models';

const persistPlugin = createRematchPersist({
  version: 1,
  key: 'blended',
  storage: localStorage,
  timeout: 10000,
  stateReconciler: autoMergeLevel2,
  whitelist: ['user', 'configuration'],
  // debug: true // to get useful logging
});

const middlewares = [];
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const enhancers = [];
enhancers.push(Sentry.createReduxEnhancer());

const store = init({
  models,
  plugins: [persistPlugin],
  redux: {
    middlewares,
    enhancers,
  },
});

export default store;
