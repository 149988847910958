import { getDefaultStore, useAtomValue, useSetAtom } from 'jotai';
import { useRouteMatch, useHistory } from 'react-router-dom';

import useUserInfo from './useUserInfo';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import {
  paymentOrderAtom,
  selectedInvoicesListAtom,
} from 'src/features/payment-management/atoms';
import { useQueryClient } from '@tanstack/react-query';
import { useOpenDialog } from 'src/shared/hooks';
import { dialogs } from 'src/shared/constants';
import { useResetAtom } from 'jotai/utils';
import stepWizardAtom from 'src/shared/atoms/stepWizard/stepWizardAtom';
import { CreatePaymentOrdersStore } from 'src/shared/atoms/stepWizard/stepWizardStore';
import router from 'src/shared/utils/router';

const usePaymentOnlineReset = () => {
  const { isWebView } = useUserInfo();
  const defaultStore = getDefaultStore();
  const history = useHistory();
  const queryClient = useQueryClient();
  const isPaymentOnlinePath = useRouteMatch(
    router.collections.paymentOrderOnline(':orderId'),
  );

  const paymentOrder = useAtomValue(paymentOrderAtom, {
    store: defaultStore,
  });

  const openDialog = useOpenDialog(dialogs.CREATE_PAYMENT_ORDERS_DIALOG);
  const setSelectedInvoicesList = useSetAtom(selectedInvoicesListAtom, {
    store: defaultStore,
  });

  const resetStepWizard = useResetAtom(stepWizardAtom, {
    store: CreatePaymentOrdersStore,
  });

  const invaldateQueries = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.INVOICE_DOWN_PAYMENTS],
    });

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.INVOICES],
    });

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.FAMILY_DETAILS],
    });
  };

  const paymentReset = () => {
    if (isWebView) {
      history.replace({
        search: 'closed',
      });
    }

    openDialog(false);
    setSelectedInvoicesList([]);
    resetStepWizard();
    invaldateQueries();

    if (isPaymentOnlinePath) {
      history.push(router.collections.familyBalance(paymentOrder?.family_id));
    }
  };

  return paymentReset;
};

export default usePaymentOnlineReset;
