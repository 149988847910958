import abstractSerializer from 'src/shared/services/api/query/serializer/abstractSerializer';

const optionsArraySerializer = (keyName = 'description', keyId) => {
  const deserialize = (data) => {
    if (Array.isArray(data)) {
      return data.map((item) => {
        return {
          ...item,
          name: item[keyName],
          ...(keyId && { id: item[keyId] }),
        };
      });
    }
  };

  return abstractSerializer(null, deserialize);
};
export default optionsArraySerializer;
