import createRouter from './createRouter';

export default createRouter('configuration', {
  root: `/configuraciones`,

  // Perfil
  taxInformation: `/configuraciones/perfil/datos-de-facturacion`,

  // Asistencias
  attendance: `/configuraciones/asistencias`,
  attendanceScales: `/configuraciones/asistencias/valores`,
  attendanceScaleSubjects: `/configuraciones/asistencias/valores/materias`,
  attendanceScaleExtracurricular: `/configuraciones/asistencias/valores/materias-especiales`,
  attendanceAlerts: `/configuraciones/asistencias/alertas`,

  // Calificaciones
  grading: `/configuraciones/calificaciones`,
  gradingLevels: `/configuraciones/calificaciones/niveles`,
  gradingSubjects: `/configuraciones/calificaciones/materias`,
  gradingLevel: (levelId) =>
    `/configuraciones/calificaciones/niveles/${levelId}`,
  gradingLevelScales: (levelId) =>
    `/configuraciones/calificaciones/niveles/${levelId}/escalas`,
  gradingLevelGrading: (levelId) =>
    `/configuraciones/calificaciones/niveles/${levelId}/calificaciones`,
  gradingLevelContest: (levelId) =>
    `/configuraciones/calificaciones/niveles/${levelId}/competencias`,
  gradingSubject: (subjectId) =>
    `/configuraciones/calificaciones/materias/${subjectId}`,
  gradingSubjectScales: (subjectId) =>
    `/configuraciones/calificaciones/materias/${subjectId}/escalas`,
  gradingSubjectGrading: (subjectId) =>
    `/configuraciones/calificaciones/materias/${subjectId}/calificaciones`,
  gradingSubjectContest: (id) =>
    `/configuraciones/calificaciones/materias/${id}/competencias`,

  // Behavior
  behavior: `/configuraciones/seguimiento`,

  // Collections (before payment-management)
  collection: `/configuraciones/pagos`,
  invoices: `/configuraciones/pagos/avisos-de-pago`,
  paymentMethods: `/configuraciones/pagos/medios-de-pago`,
  paymentMethodExternalEntities: `/configuraciones/pagos/medios-de-pago/entidades-externas`,
  billing: `/configuraciones/pagos/datos-de-facturacion`,
  billingConfig: (taxInsitutionId) =>
    `/configuraciones/pagos/datos-de-facturacion/${taxInsitutionId}`,
  billingCertificates: (taxInsitutionId) =>
    `/configuraciones/pagos/datos-de-facturacion/${taxInsitutionId}/certificados`,
  complementIEDU: (taxInsitutionId) =>
    `/configuraciones/pagos/datos-de-facturacion/${taxInsitutionId}/complemento`,
  customData: (taxInsitutionId) =>
    `/configuraciones/pagos/datos-de-facturacion/${taxInsitutionId}/personalizar`,
  billingAdvanceConfig: `/configuraciones/pagos/datos-de-facturacion/avanzada`,
  billingForm: (taxInsitutionId) =>
    `/configuraciones/pagos/datos-de-facturacion/${taxInsitutionId}/formulario`,
  paymentButton: `/configuraciones/pagos/boton-de-pagos`,
  paymentButtonDocuments: `/configuraciones/pagos/boton-de-pagos/documentos`,
  paymentButtonAccountDetails: `/configuraciones/pagos/boton-de-pagos/datos-de-cuenta`,
  permissions: `/configuraciones/pagos/permisos`,
  adminPermissions: `/configuraciones/pagos/permisos/administradores`,
  executivePermissions: `/configuraciones/pagos/permisos/ejecutivos`,
  debtReminders: `/configuraciones/pagos/recordatorios-de-deuda`,
  discounts: `/configuraciones/pagos/descuentos`,
  recurrentDiscounts: `/configuraciones/pagos/descuentos/recurrentes`,
  nonRecurrentDiscounts: `/configuraciones/pagos/descuentos/no-recurrentes`,
  familyDiscounts: `/configuraciones/pagos/descuentos/avisos-de-pago`,
  concepts: `/configuraciones/pagos/conceptos`,
  recurrentConcepts: `/configuraciones/pagos/conceptos/recurrentes`,
  nonRecurrentConcepts: `/configuraciones/pagos/conceptos/no-recurrentes`,
  extraordinaryConcepts: `/configuraciones/pagos/conceptos/extraordinarios`,
  template: `/configuraciones/pagos/plantilla`,

  // Institution config
  institutionProfile: `/configuraciones/institucion/datos`,
  blockedYearPass: `/configuraciones/pase-de-ano`,

  // Legacy routes
  userProfile: `/configuraciones/ficha-de-usuario`,
  newYear: `/configuracion/index/pase-de-ano`,
  profile: `/configuracion/index/datos`,
  actionsHistory: `/configuracion/index/control`,
  userManagement: `/configuracion/index/usuarios`,
  notifications: `/configuracion/index/notificaciones`,
  primaryReports: `/configuracion/index/informes/configuracion`,

  // Network routes
  schoolManagement: `/configuraciones/institucion`,
  schoolManagementCourse: (courseId, divisionId) =>
    `/configuraciones/institucion/curso/${courseId}/${divisionId}`,
  schoolManagementFamilies: `/configuraciones/institucion/familias`,
  schoolManagementFamilyDetails: (familyId) =>
    `/configuraciones/institucion/familias/${familyId}`,
  schoolManagementCommunity: `/configuraciones/comunidad-escolar`,
  periods: `/configuraciones/periodos`,
  schoolManagementSpecialSubjects: `/configuraciones/materias-especiales`,
  schoolManagementSpecialSubjectDetail: (subjectId) =>
    `/configuraciones/materias-especiales/${subjectId}`,

  // Pickups
  pickups: `/configuraciones/salidas`,
  authorizedPickups: `/configuraciones/salidas/autorizados`,
  regularPickups: `/configuraciones/salidas/habituales`,
  extraordinaryPickups: `/configuraciones/salidas/excepcionales`,

  //Messages
  messages: `/configuraciones/mensajes`,

  //Reports
  reports: `/configuraciones/informes`,
});
