import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { paymentManagementVariationFamilyRemove } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { Api as api } from 'src/shared/services/api';

const useRemoveFamilyMember = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const mutationFn = async ({ discountId, data }) =>
    api.patch({
      url: paymentManagementVariationFamilyRemove(discountId),
      data,
    });

  return useMutation({
    mutationFn,
    onSuccess: () => {},
    onError: ({ message }) => {
      enqueueSnackbar(
        message ?? t`Hubo un error intentando desvincular al estudiante`,
        {
          variant: 'error',
        },
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.DISCOUNT_SELECTED_FAMILY_MEMBERS],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.DISCOUNTS],
      });
    },
  });
};

export default useRemoveFamilyMember;
