import { atom } from 'jotai';
import {
  DEFAULT_DATE_RANGE,
  INVOICE_STATUS_OPTIONS,
} from 'src/features/payment-management/views/management/PaymentManagementHighRoleInvoicesView/helpers';

const managementInvoicesFiltersAtom = atom({
  dateRange: DEFAULT_DATE_RANGE,
  status: INVOICE_STATUS_OPTIONS(),
});
managementInvoicesFiltersAtom.debugLabel = 'managementInvoicesFiltersAtom';

export default managementInvoicesFiltersAtom;
