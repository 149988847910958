const addOrUpdate = (originArray = [], newArray = [], idKey = 'id') => {
  const originArrayCopy = [...originArray];
  newArray.map((el) => {
    const foundIndex = originArrayCopy.findIndex(
      (originElement) => el[idKey] === originElement[idKey],
    );
    if (foundIndex > -1) {
      originArrayCopy[foundIndex] = { ...originArrayCopy[foundIndex], ...el };
    } else {
      originArrayCopy.push(el);
    }
    return el;
  });
  return originArrayCopy;
};

export default addOrUpdate;
