import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  baseOption: {
    fontSize: 10,
    fontWeight: 400,
    color: theme.palette.common.black,
    borderRadius: '4px !important',
    padding: `${theme.spacing(0.5)} 0`,
    cursor: 'pointer',
    height: 24,
    maxWidth: `calc(100% + ${theme.spacing()})`,
  },
  optionContainer: {
    cursor: 'pointer',
    padding: `${theme.spacing()} ${theme.spacing(2)}`,
  },
  closeIcon: {
    color: `${theme.palette.common.black} !important`,
  },
  disabled: {
    textDecoration: 'line-through',
    backgroundColor: `${theme.palette.grey['100']} !important`,
  },
}));

export default useStyles;
