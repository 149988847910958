import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { useCreate } from 'src/shared/services/api/query';
import { dLocalAttachPaymentMethod } from 'src/shared/services/url/UrlPaymentManagement';

const ERROR_MESSAGE = t`Hubo un error al enviar el medio de pago.`;

const useDLocalAttachPaymentMethod = (paymentOrderId) => {
  const { enqueueSnackbar } = useSnackbar();

  return useCreate(
    dLocalAttachPaymentMethod(paymentOrderId),
    {
      onError: ({ message }) => {
        enqueueSnackbar(message ?? ERROR_MESSAGE, { variant: 'error' });
      },
    },
    false,
  );
};

export default useDLocalAttachPaymentMethod;
