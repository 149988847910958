import { useCallback, useMemo } from 'react';
import { useAtom } from 'jotai';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';
import { useParams } from 'react-router';
import { useIsMutating } from '@tanstack/react-query';

import { useMembersSearch } from 'src/shared/hooks';
import {
  useFamilyInformation,
  useUnparentStudent,
  useUpdateFamilyInformation,
} from 'src/features/payment-management/hooks';
import { selectedNewFamilyUsersAtom } from 'src/features/payment-management/atoms';
import { ROLE_LIST } from 'src/config/general';
import useUserInfo from '../useUserInfo';

const useFamilyGroup = (enabledMemberSearch = true) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userIs } = useUserInfo();
  const isMutating = useIsMutating() > 0;
  const isAllowedUnparentActions = userIs.admin || userIs.financialAdmin;

  const { familyId } = useParams();

  const updateFamilyInformation = useUpdateFamilyInformation(familyId);

  const [selectedNewFamilyUsers, setSelectedNewFamilyUsers] = useAtom(
    selectedNewFamilyUsersAtom,
  );
  const isNewFamily = useMemo(
    () => selectedNewFamilyUsers.length,
    [selectedNewFamilyUsers],
  );

  const {
    isLoading: isLoadingMembers,
    search,
    members,
    handleSearch,
    handleChangeParam,
  } = useMembersSearch({ enabled: enabledMemberSearch });

  const { data: familyInfo, isLoading: isLoadingFamilyInfo } =
    useFamilyInformation({
      familyId,
    });

  const { data: unparentStudents } = useUnparentStudent({
    enabled: isAllowedUnparentActions,
  });

  const unparentStudentIds = useMemo(
    () => unparentStudents?.map(({ id }) => id) ?? [],
    [unparentStudents],
  );

  const selectedMembers = isNewFamily
    ? selectedNewFamilyUsers
    : familyInfo?.users ?? [];

  const isLoadingFamily = !isNewFamily && isLoadingFamilyInfo;

  const { name, address, phone, email, institution_id, users } =
    familyInfo ?? {};

  const filteredMembers = members.filter(({ authAssignments }) =>
    authAssignments.some(
      (role) =>
        unparentStudentIds.includes(Number(role.user_id)) ||
        role.item_name === ROLE_LIST.PARENT,
    ),
  );

  const handleSelectMember = (member) => {
    const isParent = member.authAssignments.some(
      (role) => role.item_name === ROLE_LIST.PARENT,
    );

    if (isNewFamily) {
      setSelectedNewFamilyUsers([
        {
          user_id: member.id,
          role: isParent ? ROLE_LIST.PARENT : 'child',
          institution_id,
          ...member,
        },
        ...selectedNewFamilyUsers,
      ]);
    } else {
      const familyMembers = [
        {
          user_id: member.id,
          role: isParent ? ROLE_LIST.PARENT : 'child',
          institution_id,
        },
        ...users,
      ];
      updateFamilyInformation.mutate({
        name,
        address,
        phone,
        email,
        institution_id,
        users: familyMembers,
      });
    }
  };

  const handleRemoveMember = (member) => {
    const familyMembers = isNewFamily
      ? selectedNewFamilyUsers.filter(({ id }) => id !== member.id)
      : users.filter(({ user_id }) => user_id !== member.user_id);

    const hasOneParent = familyMembers.some(
      (item) => item.role === ROLE_LIST.PARENT,
    );

    const hasOneChild = familyMembers.some((item) => item.role === 'child');

    if (
      (!isNewFamily && (!hasOneParent || !hasOneChild)) ||
      (isNewFamily && !hasOneParent)
    ) {
      enqueueSnackbar(t`Debe haber un estudiante y un padre en la familia`, {
        variant: 'error',
      });
      return;
    }

    if (isNewFamily) {
      setSelectedNewFamilyUsers(familyMembers);
    } else {
      updateFamilyInformation.mutate({
        name,
        address,
        phone,
        email,
        institution_id,
        users: familyMembers,
      });
    }
  };

  const handleRemoveAllMember = () => {
    if (isNewFamily) {
      const unparented = selectedNewFamilyUsers.find(
        ({ isUnparent }) => isUnparent,
      );

      const [parent] = selectedNewFamilyUsers.filter(
        ({ role }) => role === ROLE_LIST.PARENT,
      );

      if (parent) {
        setSelectedNewFamilyUsers([unparented, parent]);
      }
    }
  };

  const handleChangeFilters = useCallback(
    ({ divisions, roles }) => {
      handleChangeParam({
        divisions,
        roles,
      });
    },
    [handleChangeParam],
  );
  return {
    isMutating,
    members,
    isLoadingMembers,
    search,
    familyInfo,
    isLoadingFamily,
    selectedMembers,
    filteredMembers,
    isNewFamily,
    handleSelectMember,
    handleRemoveMember,
    handleRemoveAllMember,
    handleSearch,
    handleChangeFilters,
  };
};

export default useFamilyGroup;
