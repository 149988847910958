import { useCallback, useMemo } from 'react';
import QUERY_KEY from 'src/shared/constants/queryKey';
import { useQueryClient } from '@tanstack/react-query';
import useUserLevels from 'src/shared/hooks/useUserLevels';
import { checkRole, formatUserRolesName } from 'src/shared/helpers';
import { ROLE_LIST } from 'src/config/general';
import useUserInfo from 'src/features/payment-management/hooks/useUserInfo';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import useUserSons from 'src/shared/hooks/useUserSons';

import useUserInformation from '../data/useUserInformation';
import useUserParent from '../data/useUserParent';
import { USER_PROFILE_VIEW_ROLES } from '../../helpers';
import usePermissionSendMessage from '../data/usePermissionSendMessage';
import checkRoleRank from '../../helpers/checkRoleRank';

const ERROR_MESSAGE = t`Este usuario no se encuentra en la red`;

const useUserInfoView = ({ userId, onErrorUserInformation }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    userIs: userLoggedIs,
    data: userLoggedInfo,
    role: userLoggedInfoRoles,
  } = useUserInfo();

  const params = {
    userId,
    queryParams: {
      enabled: Boolean(userId),
      onError: ({ message }) => {
        enqueueSnackbar(message ?? ERROR_MESSAGE, {
          variant: 'error',
        });
        if (onErrorUserInformation) onErrorUserInformation();
        queryClient.removeQueries([QUERY_KEY.USER_INFO, userId]);
      },
    },
  };

  const {
    data: havePermissionSendMessage = false,
    isLoading: isLoadingPermissionSendMessage,
    isFetching: isFetchingPermissionSendMessage,
  } = usePermissionSendMessage({
    userId,
  });

  const {
    data: userInfo,
    isFetching: isFetchingUserInfo,
    isLoading: isLoadingUserInfo,
  } = useUserInformation(params);

  const userHasRole = useCallback(
    (searchingRole) =>
      userInfo?.roles?.some((user) => user.item_name === searchingRole),
    [userInfo],
  );

  const isParent = userHasRole(ROLE_LIST.PARENT);

  const isStudent = userHasRole(ROLE_LIST.STUDENT);

  const isAdmin = userHasRole(ROLE_LIST.ADMIN);

  const isExecutive = userHasRole(ROLE_LIST.EXECUTIVE);

  const isSamePerson = useMemo(
    () => userLoggedInfo?.id === userInfo?.id,
    [userInfo, userLoggedInfo],
  );

  const { data: userParent, isFetching: isFetchingParent } = useUserParent({
    userId: userInfo?.id,
    isStudent,
  });

  const { data: userChildren, isFetching: isFetchingChildren } = useUserSons({
    userId: userInfo?.id,
    enabled: Boolean(userInfo?.id && isParent),
  });

  const userInfoDivisionIds = [
    ...new Set(userInfo?.roles?.map((role) => role.division?.id)),
  ].filter(Boolean);

  const userInfoLevelsIds = [
    ...new Set(userInfo?.roles?.map((role) => role.division?.course?.level_id)),
  ].filter(Boolean);

  const userInfoRoles = [
    ...new Set(userInfo?.roles?.map((role) => role.item_name)),
  ].filter(Boolean);

  const userLoggedRoles = [
    ...new Set(userLoggedInfoRoles?.map((role) => role.role_name)),
  ].filter(Boolean);

  const isLoading = useMemo(
    () =>
      isFetchingUserInfo ||
      isLoadingUserInfo ||
      isFetchingChildren ||
      isFetchingParent ||
      isLoadingPermissionSendMessage ||
      isFetchingPermissionSendMessage,
    [
      isFetchingUserInfo,
      isLoadingUserInfo,
      isFetchingParent,
      isFetchingChildren,
      isLoadingPermissionSendMessage,
      isFetchingPermissionSendMessage,
    ],
  );

  const { userLevels: userLoggedLevel } = useUserLevels();

  const fullName = `${userInfo?.name} ${userInfo?.last_name}`;

  const roles = userInfo?.roles?.map((role) => role.item_name);
  const rolesName = formatUserRolesName(roles, userInfo?.genre_id);

  const getLoggedUserDivisionByRole = useCallback(
    (role) =>
      userLoggedLevel?.flatMap((item) =>
        item.courses.flatMap((course) =>
          course.divisions.filter((division) => division.roles.includes(role)),
        ),
      ),
    [userLoggedLevel],
  );

  const getLoggedUserLevelsByRole = useCallback(
    (role) =>
      userLoggedLevel?.flatMap((level) =>
        level.courses.flatMap((course) =>
          course.divisions
            .filter((division) => division.roles.includes(role))
            .map(() => level),
        ),
      ),

    [userLoggedLevel],
  );

  const isTheUserParent = useMemo(() => {
    if (userLoggedIs.parent) {
      const isMatchParent = userParent?.some(
        (parent) => parent.id === userLoggedInfo.id,
      );

      if (isMatchParent) {
        return true;
      }
    }

    return false;
  }, [userParent, userLoggedInfo, userLoggedIs]);

  const haveActionsProfileAccess = useMemo(() => {
    if (isAdmin) {
      return false;
    }

    // admin puede editar todos, todos menos estudiantes pueden editar su propio perfil
    if (userLoggedIs.admin || (isSamePerson && !userLoggedIs.student)) {
      return true;
    }

    // se descarta si el el user logeado es menor al user que ve. Directivo > preceptor
    const haveViewProfileWithRoleRank = checkRoleRank(
      userLoggedRoles,
      userInfoRoles,
    );
    if (!haveViewProfileWithRoleRank) {
      return false;
    }

    if (userLoggedIs.executive || userLoggedIs.preceptor) {
      const userLoggedLevelIds = userLoggedLevel?.map((levels) => levels.id);
      const isMatch = userInfoLevelsIds.some((level) =>
        userLoggedLevelIds.includes(level),
      );

      if (isMatch) {
        return true;
      }
    }

    if (isTheUserParent) {
      return true;
    }

    return false;
  }, [
    isAdmin,
    userLoggedIs,
    userLoggedLevel,
    userInfoLevelsIds,
    userInfoRoles,
    userLoggedRoles,
    isSamePerson,
    isTheUserParent,
  ]);

  const haveViewProfileAccess = useMemo(() => {
    const userLoggedIsAdmin = userLoggedIs.admin;

    const haveViewProfileWithRole = checkRole(USER_PROFILE_VIEW_ROLES);

    if (!haveViewProfileWithRole) {
      return false;
    }

    if (userLoggedIsAdmin || isSamePerson) {
      return true;
    }

    const haveViewProfileWithRoleRank = checkRoleRank(
      userLoggedRoles,
      userInfoRoles,
      true,
    );

    if (!haveViewProfileWithRoleRank) {
      return false;
    }

    if (isTheUserParent) {
      return true;
    }

    if (userLoggedIs.preceptor) {
      const isPreceptor = userHasRole(ROLE_LIST.EXECUTIVE);

      if (isPreceptor) {
        return false;
      }

      const userPreceptorDivisions = getLoggedUserDivisionByRole(
        ROLE_LIST.PRECEPTOR,
      );
      const userPreceptorDivisionsIds = userPreceptorDivisions.map(
        (div) => div.id,
      );

      const isMatch = userInfoDivisionIds.some((div) =>
        userPreceptorDivisionsIds.includes(div),
      );

      if (isMatch) {
        return true;
      }
    }

    if (userLoggedIs.executive) {
      const userExcutiveLevel = getLoggedUserLevelsByRole(ROLE_LIST.EXECUTIVE);

      const userExcutiveLevelIds = userExcutiveLevel.map((level) => level.id);

      const isMatch = userInfoLevelsIds.some((level) =>
        userExcutiveLevelIds.includes(level),
      );

      if (isMatch) {
        return true;
      }
    }

    const userLoggedIsTeacher = userLoggedIs.teacher;

    if (userLoggedIsTeacher) {
      const divisions = getLoggedUserDivisionByRole(ROLE_LIST.TEACHER);
      const divisionIds = divisions?.map((division) => division.id);

      const isMatch = userInfoDivisionIds.some((level) =>
        divisionIds.includes(level),
      );

      if (isMatch) {
        return true;
      }
    }

    return false;
  }, [
    userLoggedIs.admin,
    userLoggedIs.preceptor,
    userLoggedIs.executive,
    userLoggedIs.teacher,
    isSamePerson,
    userLoggedRoles,
    userInfoRoles,
    getLoggedUserLevelsByRole,
    userInfoLevelsIds,
    getLoggedUserDivisionByRole,
    userInfoDivisionIds,
    userHasRole,
    isTheUserParent,
  ]);

  const hasMessagesAccess = useMemo(() => {
    return havePermissionSendMessage;
  }, [havePermissionSendMessage]);

  return {
    userInfo,
    isLoading,
    isLoadingUserInfo,
    fullName,
    rolesName,
    haveViewProfileAccess,
    userParent,
    userChildren,
    isStudent,
    isParent,
    isExecutive,
    isAdmin,
    isSamePerson,
    hasMessagesAccess,
    haveActionsProfileAccess,
    userInfoDivisionIds,
    isTheUserParent,
  };
};
export default useUserInfoView;
