import { useGetOne } from 'src/shared/services/api/query';
import { paymentManagementRateBatch } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';

const useGetRateBatchById = ({
  enabled = true,
  batchId,
  queryParams = {},
  ...params
} = {}) => {
  return useGetOne(
    [QUERY_KEY.RATES_USER_BATCH, batchId, params],
    paymentManagementRateBatch(batchId),
    { ...params },
    true,
    false,
    {
      enabled,
      ...(!enabled && { cacheTime: 0 }),
      ...queryParams,
    },
  );
};

export default useGetRateBatchById;
