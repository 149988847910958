import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const TimeGridWeekHeader = ({ header }) => {
  const { date } = header;
  const day = dayjs(date);

  return (
    <>
      <div>{day.format('ddd').replace('.', '')}</div>
      <div>{day.format('DD')}</div>
    </>
  );
};

TimeGridWeekHeader.propTypes = {
  header: PropTypes.object,
};

export default TimeGridWeekHeader;
