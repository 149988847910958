import { useQueryClient } from '@tanstack/react-query';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { useUpdate } from 'src/shared/services/api/query';
import { paymentManagementVariationUsersRemove } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from '../../../constants/queryKey';

const useRemoveMember = (discountId) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useUpdate(
    paymentManagementVariationUsersRemove(discountId),
    {
      onSuccess: () => {},
      onError: ({ message }, data, context) => {
        enqueueSnackbar(
          message ?? t`Hubo un error intentando desvincular al estudiante`,
          {
            variant: 'error',
          },
        );
      },
      onSettled: () => {
        // Always refetch after error or success:
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.DISCOUNT_SELECTED_MEMBERS],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.DISCOUNTS],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.RATES],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.CONCEPT_SELECTED_MEMBERS],
          refetchType: 'all',
        });
      },
    },
    false,
  );
};

export default useRemoveMember;
