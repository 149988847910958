import { ROLE_LIST } from 'src/config/general';
import usePickupConfig from 'src/features/pickups/hooks/data/usePickupConfig';
import { checkRole } from 'src/shared/helpers';
import { useAvailableStructure } from 'src/shared/hooks';

const findPickupConfigByLevel = (pickupConfigs, levelsId) =>
  pickupConfigs
    ?.filter(({ level_id }) => levelsId.includes(level_id))
    .find(({ view_roles }) => checkRole(view_roles)) ?? {};

const usePickupAccessRoles = () => {
  const { data: pickupConfigs } = usePickupConfig();
  const { levelsByRoles } = useAvailableStructure();
  const authorizedRoles = [
    ...levelsByRoles.executive,
    ...levelsByRoles.preceptor,
    ...(levelsByRoles.teacher ?? []),
  ];

  const levelsId = [...new Set(authorizedRoles.map(({ id }) => id))];
  const { view_roles } = findPickupConfigByLevel(pickupConfigs, levelsId);

  return [ROLE_LIST.ADMIN, ...(view_roles ?? [])];
};

export default usePickupAccessRoles;
