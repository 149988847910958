import { atomWithReset, RESET } from 'jotai/utils';
import { atom } from 'jotai';

const initialValue = {
  id: null,
  name: '',
  init_at: null,
  due_at: null,
  status: null,
  notify_payment_notification: true,
  concept_id: null,
  billable: false,
  online_payment_account_id: null,
  institution_tax_information_id: null,
  educational_complement: false,
  iva: null,
  product_code: '',
};

const CampaignConfigDraftAtom = atomWithReset(initialValue);

const RemoteCampaignConfigAtom = atomWithReset(initialValue);

export const CampaignConfigAtom = atom(
  (get) => get(RemoteCampaignConfigAtom),
  (get, set, newValue) => {
    if (newValue === RESET) {
      set(CampaignConfigDraftAtom, RESET);
      set(RemoteCampaignConfigAtom, RESET);
    } else {
      set(CampaignConfigDraftAtom, {
        ...newValue,
      });
      set(RemoteCampaignConfigAtom, {
        ...newValue,
      });
    }
  },
);

export default CampaignConfigDraftAtom;
