import { useMemo } from 'react';
import useRoles from 'src/shared/hooks/useRoles';
import { ROLE_LIST } from 'src/config/general';
import { useAvailableStructure } from 'src/shared/hooks';

const useExternalLinkForm = (selectedExternalLink) => {
  const roles = useRoles({
    excludeRoles: [ROLE_LIST.ADMIN],
  });
  const { levelsByRoles, divisions } = useAvailableStructure();
  const levels = [...levelsByRoles.admin, ...levelsByRoles.executive];

  const selectedRoles = useMemo(
    () =>
      selectedExternalLink?.roles
        ? roles
            .filter((rol) => selectedExternalLink?.roles?.includes(rol.value))
            .map((rol) => rol.id)
        : [],
    [roles, selectedExternalLink?.roles],
  );

  const selectedDivisions = useMemo(
    () =>
      selectedExternalLink
        ? selectedExternalLink?.divisions ??
          divisions.map(({ levelId }) => levelId)
        : [],
    [divisions, selectedExternalLink],
  );

  const selectedLevels = useMemo(() => {
    const levels = divisions
      .filter(({ divisionId }) => selectedDivisions.includes(divisionId))
      .map(({ levelId }) => levelId);

    return [...new Set(levels)];
  }, [divisions, selectedDivisions]);

  const selectedStructure = useMemo(
    () => ({
      roles: selectedRoles,
      divisions: selectedDivisions,
      levels: selectedLevels,
    }),
    [selectedDivisions, selectedLevels, selectedRoles],
  );

  return { divisions, roles, levels, selectedStructure };
};

export default useExternalLinkForm;
