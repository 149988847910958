import { t } from '@lingui/macro';

const LANGUAGES_PREFIXES = {
  SPANISH: 'es',
  PORTUGUESE: 'pt',
  ENGLISH: 'en',
};

const COUNTRY_LOCALES = {
  ARGENTINA: {
    name: t`Argentina`,
    code: 'ar',
    lang: LANGUAGES_PREFIXES.SPANISH,
  },
  BRAZIL: { name: t`Brasil`, code: 'br', lang: LANGUAGES_PREFIXES.PORTUGUESE },
  URUGUAY: { name: t`Uruguay`, code: 'uy', lang: LANGUAGES_PREFIXES.SPANISH },
  PERU: { name: t`Perú`, code: 'pe', lang: LANGUAGES_PREFIXES.SPANISH },
  PARAGUAY: { name: t`Paraguay`, code: 'py', lang: LANGUAGES_PREFIXES.SPANISH },
  MEXICO: { name: t`México`, code: 'mx', lang: LANGUAGES_PREFIXES.SPANISH },
  COLOMBIA: { name: t`Colombia`, code: 'co', lang: LANGUAGES_PREFIXES.SPANISH },
  US: { name: t`Estados Unidos`, code: 'en', lang: LANGUAGES_PREFIXES.ENGLISH },
};

const COUNTRY_LOCALES_LIST = Object.values(COUNTRY_LOCALES).map(
  (country) => country.code,
);

export const getAdapterLocale = (locale) => {
  if (locale.includes('en')) return LANGUAGES_PREFIXES.ENGLISH;
  if (locale.includes('pt')) return LANGUAGES_PREFIXES.PORTUGUESE;

  return LANGUAGES_PREFIXES.SPANISH;
};

export { COUNTRY_LOCALES, COUNTRY_LOCALES_LIST, LANGUAGES_PREFIXES };
