import React from 'react';
import { Button, Box, Typography, darken } from '@mui/material';
import { Trans } from '@lingui/macro';

import children from 'src/assets/images/changelog/02-23/childrens.png';
import { useBreakpoints } from 'src/shared/hooks';

const BrandingStep = () => {
  const { isMobile } = useBreakpoints();

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        px: isMobile ? 2 : 10,
        ...(isMobile && {
          height: '100%',
          alignItems: 'center',
        }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxWidth: 330,
          justifyContent: 'center',

          ...(isMobile && {
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }),
        }}
      >
        {isMobile && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <Box
              component="img"
              src={children}
              alt="Children running happy"
              {...(isMobile && {
                width: 220,
              })}
            />
          </Box>
        )}

        <Typography
          fontSize={isMobile ? 14 : 17}
          sx={{ color: '#FF6D3B', fontWeight: 'bold' }}
          variant="h4"
          gutterBottom
        >
          <Trans>NUESTRO BLOG</Trans>
        </Typography>

        <Typography fontSize={isMobile ? 13 : 16} variant="h3">
          <Trans>
            Si deseas conocer más acerca de la nueva app, sus cambios y
            actualizaciones te invitamos a leer la nota relacionada.
          </Trans>
        </Typography>

        <Button
          size={isMobile ? 'medium' : 'large'}
          onClick={() =>
            window.open(
              'https://www.blog.fidu.la/sobre-fidu/nueva-app-de-fidu/',
              '_blank',
            )
          }
          sx={{
            backgroundColor: '#0071F1',
            color: 'white',
            maxWidth: 125,

            '&:hover': {
              backgroundColor: darken('#0071F1', 0.2),
            },

            ...(isMobile && {
              mx: 'auto',
            }),
          }}
        >
          <Trans>Leer nota</Trans>
        </Button>
      </Box>

      {!isMobile && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            flex: 1,
          }}
        >
          <Box component="img" src={children} alt="Children running happy" />
        </Box>
      )}
    </Box>
  );
};

export default BrandingStep;
