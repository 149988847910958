import React, { memo, useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import PropTypes from 'prop-types';
import { Grid, Popper, ClickAwayListener } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import TextField from '../TextField';
import useStyles from './styles';
import PreviewButton from './PreviewButton';

const ColorPickerField = ({
  gridProps = { xs: 12 },
  name,
  disabled,
  label,
  ...rest
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState('#93bf85');
  const [field, meta, form] = useField(name);
  const popperId = isOpen ? 'color-popper' : undefined;
  const { values, setFieldValue } = useFormikContext();

  const handleToggleOpen = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
    setIsOpen((oldValue) => !oldValue);
  };

  const handleChangeColor = (newColor) => {
    setColor(newColor);
    setFieldValue(name, newColor);
  };

  useEffect(() => {
    if (values.color && values.color !== color) {
      setColor(values.color);
    }
  }, [color, values.color]);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Grid item {...gridProps} className={classes.grid}>
        <Popper
          open={isOpen}
          id={popperId}
          anchorEl={anchorEl}
          className={classes.popper}
          placement={'top-start'}
        >
          <HexColorPicker onChange={handleChangeColor} color={color} />
        </Popper>
        <PreviewButton color={color} onClick={handleToggleOpen} />
        <TextField
          label={label}
          name={name}
          fullWidth
          classes={{ root: classes.MuiInputRoot }}
          error={Boolean(meta.touched && meta.error)}
          helperText={meta.touched && meta.error}
          variant="outlined"
          disabled={form.isSubmitting || form.isValidating || disabled}
          value={values.color}
          {...field}
          {...rest}
        />
      </Grid>
    </ClickAwayListener>
  );
};

ColorPickerField.displayName = 'ColorPicker';

ColorPickerField.propTypes = {
  gridProps: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

export default memo(ColorPickerField);
