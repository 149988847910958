// initial state
import { DEFAULT_LOCALE } from 'src/shared/utils/locales/manageLocalization';

const state = {
  isLogin: false,
  selectedRole: null,
  userIs: {},
  isNewYearManager: false,
  apiKey: '',
  refreshToken: '',
  isTokenExpired: false,
  data: {},
  role: [],
  relations: [],
  sonsData: [],
  institutions: [],
  selectedInstitution: {},
  institutionPeriods: [],
  activeInstitutionPeriodId: null,
  selectedInstitutionPeriodId: 0,
  notifications: {
    data: [],
    hasUnreadNotifications: false,
    pagination: {
      total: 0,
    },
  },
  preferences: {
    calendarView: '',
  },
  isWebView: false,
  changelog: {
    isSeen: false,
    isClosed: false,
    counter: 1,
    changelogUserId: 0, // TO MULTI USERS - SINGLE DEVICES
  },
  isFirstLogin: false,
  webinar: {
    isSeen: false,
    date: null,
  },
  hasTrainingUser: false,
  tours: {
    settings: true,
    training: true,
    grading: {
      stepInit: true,
      stepWithNotes: true,
      stepWithoutNotes: true,
      stepAddNotes: true,
    },
  },
  yearPassDialog: false,
  userLocale: { isLoaded: false, locale: DEFAULT_LOCALE },
  newInstitutionPeriod: {},
};

export default state;
