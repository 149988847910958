import makeStyles from '@mui/styles/makeStyles';

import { tablet } from 'src/shared/utils/breakpoints';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'auto',
  },

  wrapperWebView: {
    [tablet]: {
      margin: '0 0 0 216px',
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
  },
}));

export default useStyles;
