import { ROLE_LIST } from 'src/config/general';

export const HIGH_ROLES = [
  ROLE_LIST.ADMIN,
  ROLE_LIST.FINANCIAL_ADMIN,
  ROLE_LIST.FINANCIAL_EXECUTIVE,
];

export const FINANCIAL_ROLES = [
  ROLE_LIST.FINANCIAL_ADMIN,
  ROLE_LIST.FINANCIAL_EXECUTIVE,
];

export const IS_AUTHORITARY_ROLES = [ROLE_LIST.EXECUTIVE, ROLE_LIST.PRECEPTOR];
