import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useAtomValue } from 'jotai';

import { calendarFiltersAtom } from 'src/features/calendar/atoms';
import useSearch from '../data/useSearch';

const useBlendedSearch = ({ filterSearch = {} } = {}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { filters } = useAtomValue(calendarFiltersAtom) ?? {};
  const { data, isLoading, isFetching, refetch } = useSearch(searchTerm, {
    ...filters,
    ...filterSearch,
  });

  const handleSearch = useDebouncedCallback((query) => {
    setSearchTerm(query);
  }, 300);

  useEffect(() => {
    if (refetch && searchTerm) refetch();
  }, [searchTerm, refetch, filters]);

  return {
    search: {
      ...(data ?? { events: [], posts: [], users: [] }),
    },
    isLoading,
    isFetching,
    handleSearch,
  };
};

export default useBlendedSearch;
