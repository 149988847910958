import { lazy } from 'react';

import {
  setLocalStorageKey,
  getLocalStorageKey,
} from 'src/shared/utils/manageLocalStorage';

import BLENDED_STORAGE_KEYS from './storageKeys';

const lazyWithErrorHandler = (componentImport) => {
  return lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed =
      Boolean(getLocalStorageKey(BLENDED_STORAGE_KEYS.REFRESH_FORCED)) || false;

    try {
      const component = await componentImport();

      setLocalStorageKey(BLENDED_STORAGE_KEYS.REFRESH_FORCED, 0);

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        setLocalStorageKey(BLENDED_STORAGE_KEYS.REFRESH_FORCED, 1);
        return window.location.reload();
      }

      throw error;
    }
  });
};

export default lazyWithErrorHandler;
