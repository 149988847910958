import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DragIndicator } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';

import FileItem from '../FileItem';

const ContainerFiles = ({
  postFiles,
  setPostFiles,
  isDroppable = false,
  ...rest
}) => {
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedFiles = Array.from(postFiles);
    const [movedItem] = reorderedFiles.splice(result.source.index, 1);
    reorderedFiles.splice(result.destination.index, 0, movedItem);

    setPostFiles(reorderedFiles);
  };

  if (!isDroppable) {
    return (
      <>
        {postFiles.map((file) => (
          <FileItem file={file} {...rest} />
        ))}
      </>
    );
  }

  return (
    <Grid container>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="dragFilesEditor">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {postFiles.map((file, index) => (
                <Draggable
                  key={file.id}
                  draggableId={file.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={snapshot.isDragging}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <DragIndicator />

                      <FileItem file={file} {...rest} />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
};

ContainerFiles.propTypes = {
  postFiles: PropTypes.array,
  setPostFiles: PropTypes.func,
  isDroppable: PropTypes.bool,
};

export default ContainerFiles;
