import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import { t } from '@lingui/macro';

import { SubmitButton, TextField } from 'src/components/Form';

import AssociateChildrenSchema from './schema';

const initialValues = {
  students: '',
};

const LinkChildrenForm = ({
  onSubmit,
  isLoading,
  submitButtonText = t`Agregar`,
  ...props
}) => (
  <Formik
    {...props}
    initialValues={initialValues}
    validationSchema={AssociateChildrenSchema}
    onSubmit={onSubmit}
  >
    <Form>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={10}>
          <TextField name="students" label={t`Ingrese el DNI del estudiante`} />
        </Grid>
        <Grid item xs={2} mt="28px">
          <SubmitButton isLoading={isLoading} disabled={isLoading}>
            {submitButtonText}
          </SubmitButton>
        </Grid>
      </Grid>
    </Form>
  </Formik>
);

LinkChildrenForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  submitButtonText: PropTypes.string,
};

export default memo(LinkChildrenForm);
