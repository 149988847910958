import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { t } from '@lingui/macro';

import { toDateFormat } from 'src/shared/utils/dates';

dayjs.extend(dayOfYear);

export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm [hs]';
export const TIME_FORMAT = 'HH:mm [hs]';
const disableUTC = false;

const renderEventDates = (event) => {
  // check empty object
  if (Object.entries(event ?? {}).length === 0) {
    return '';
  }

  const { all_day, start_datetime, end_datetime } = event;

  const start = dayjs(start_datetime);
  const end = dayjs(end_datetime);

  const isSameDay = start.isSame(end, 'day');
  const formatedStartDate = toDateFormat(start, DATE_TIME_FORMAT, disableUTC);

  if (all_day) {
    return toDateFormat(start);
  }

  if (isSameDay) {
    return `${toDateFormat(start)} ${toDateFormat(
      start,
      'HH:mm',
      disableUTC,
    )} - ${toDateFormat(end, 'HH:mm a', disableUTC)}`;
  }

  return t`${formatedStartDate} - ${toDateFormat(
    end,
    DATE_TIME_FORMAT,
    disableUTC,
  )}`;
};

export default renderEventDates;
