import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { X as CloseIcon } from 'react-feather';

import FeatherIconButton from 'src/components/FeatherIconButton';
import DialogHeaderBase from 'src/components/DialogHeaderBase';

const DialogTitle = ({ title, onClose, ...props }) => (
  <DialogHeaderBase
    right={
      <FeatherIconButton
        onClick={() => onClose()}
        Icon={CloseIcon}
        sx={{ color: 'text.secondary' }}
      />
    }
    left={<Typography variant={'h2'}>{title}</Typography>}
    {...props}
  />
);

DialogTitle.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default memo(DialogTitle);
