import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Chip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useField, useFormikContext } from 'formik';

const ChipField = ({
  gridProps = {},
  item,
  onChange,
  name,
  selectedAll,
  isCheckedAll,
  disableSelectMultiple,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const updateCurrentValue = () => {
    const currentValue = field.value;

    const isSelected = currentValue?.some((current) => current.id === item.id);

    if (isSelected) {
      return currentValue?.filter((current) => current.id !== item.id);
    }

    return !disableSelectMultiple ? [...currentValue, item] : [item];
  };

  const handleChange = () => {
    const updatedValue = updateCurrentValue();
    setFieldValue(name, updatedValue);

    if (onChange) onChange(updatedValue);
  };
  const isChecked =
    item.id === 'all'
      ? isCheckedAll
      : field.value?.some((selectedLevel) => selectedLevel.id === item.id);

  return (
    <Grid item {...gridProps}>
      <Chip
        data-testid={`chip-field-${name}-${item.id}`}
        onClick={item.id === 'all' ? selectedAll : handleChange}
        variant={!isChecked ? 'outlined' : ''}
        sx={{
          paddingX: 1,
          paddingY: 3,
          background: !isChecked ? 'transparent' : 'gray.300',
          borderRadius: 2,
          '.MuiChip-avatar': {
            width: '14px',
            height: '14px',
          },
        }}
        avatar={isChecked ? <DoneIcon /> : null}
        {...props}
      />
    </Grid>
  );
};

ChipField.propTypes = {
  gridProps: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  selectedAll: PropTypes.func,
  isCheckedAll: PropTypes.bool,
};

export default ChipField;
