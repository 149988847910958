import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router';

import {
  noInboxActiveConversationAtom,
  inboxSearchQueryAtom,
} from '../../atoms';
import { useGetScheduledConversations } from '..';
import router from 'src/shared/utils/router';

const useScheduledConversations = () => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useAtom(inboxSearchQueryAtom);
  const [activeConversation, setActiveConversation] = useAtom(
    noInboxActiveConversationAtom,
  );

  const {
    data: scheduledConversations,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useGetScheduledConversations({
    subject: searchQuery,
    users: searchQuery,
  });

  const handleClickConversation = useCallback(
    (conversation) => {
      if (conversation) {
        setActiveConversation(conversation);
        history.push(
          router.communication.scheduled.conversation(conversation?.id),
        );
      }
    },
    [history, setActiveConversation],
  );

  return {
    searchQuery,
    setSearchQuery,
    scheduledConversations,
    isLoading,
    isFetching,
    fetchNextPage,
    handleClickConversation,
    hasNextPage,
    activeConversation,
    setActiveConversation,
  };
};

export default useScheduledConversations;
