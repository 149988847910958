import useUserInfo from 'src/features/payment-management/hooks/useUserInfo';
import { getLocale } from 'src/shared/utils/locales/manageLocalization';

const useLocale = ({ partIndex } = {}) => {
  const { data: userInfo } = useUserInfo();
  const locale = getLocale(userInfo.locale, partIndex).toLowerCase();

  return {
    locale,
  };
};

export default useLocale;
