import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { MonetizationOnOutlined } from '@mui/icons-material';
import {
  mdiCalendarTextOutline,
  mdiMessageOutline,
  mdiStar,
  mdiToggleSwitchOutline,
  mdiCogOutline,
  mdiCommentTextMultipleOutline,
  mdiEmailOutline,
  mdiSchoolOutline,
  mdiNotebookOutline,
  mdiFileDocumentArrowRightOutline,
} from '@mdi/js';

export const getEditIconPath = (IconComponent) => {
  const iconString = ReactDOMServer.renderToString(<IconComponent />);
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(iconString, 'image/svg+xml');
  return svgDoc.querySelector('path')?.getAttribute('d');
};

export const notificationsIcons = {
  1: mdiCalendarTextOutline,
  2: mdiMessageOutline,
  3: mdiStar,
  4: mdiToggleSwitchOutline,
  5: mdiCogOutline,
  6: mdiCommentTextMultipleOutline,
  7: mdiEmailOutline,
  8: mdiSchoolOutline,
  9: mdiNotebookOutline,
  10: getEditIconPath(MonetizationOnOutlined),
  11: mdiFileDocumentArrowRightOutline,
};
