import { atomWithReset } from 'jotai/utils';

const discountBatchTransactionAtom = atomWithReset({
  status: '',
  institution_id: undefined,
  created_at: undefined,
  id: undefined,
});

export default discountBatchTransactionAtom;
