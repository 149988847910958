import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },
}));

export default useStyles;
