import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  FormControlLabel,
  Radio as MuiRadioField,
} from '@mui/material';
import { useFormikContext } from 'formik';

const RadioField = ({
  gridProps = { xs: 12 },
  value,
  disabled,
  label,
  labelPlacement = 'end',
  helperText,
  children,
  size,
  radioProps = {},
  helperTextProps,
  ...rest
}) => {
  const { isSubmitting, isValidating } = useFormikContext();

  return (
    <Grid item container {...gridProps}>
      <Grid item xs={12} display={'flex'}>
        <FormControlLabel
          value={value}
          labelPlacement={labelPlacement}
          label={label}
          disabled={isSubmitting || isValidating || disabled}
          control={<MuiRadioField size={size} {...radioProps} />}
          {...rest}
        />
        {children}
      </Grid>

      {helperText && (
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mt: -0.5, ml: 2 }}
            {...helperTextProps}
          >
            {helperText}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

RadioField.propTypes = {
  gridProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  labelPlacement: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  helperText: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
};

export default RadioField;
