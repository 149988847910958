// convert calendar event to event model

const calendarEventToEvent = (calendarEvent) => {
  const { id, title, text } = calendarEvent;

  const event = {
    id,
    title,
    description: text,
    ...calendarEvent.extendedProps,
  };

  return event;
};

export default calendarEventToEvent;
