import { useGetOne } from 'src/shared/services/api/query';
import { paymentManagementConfig } from 'src/shared/services/url/UrlPaymentManagement';
import oneElementArraySerializer from 'src/shared/services/api/query/serializer/oneElementArraySerializer';

import { QUERY_KEY } from '../../constants/queryKey';

const usePaymentConfig = (options) => {
  return useGetOne(
    [QUERY_KEY.PAYMENTS_MODULE_CONFIG],
    paymentManagementConfig(),
    {},
    true,
    false,
    options,
    oneElementArraySerializer(),
  );
};

export default usePaymentConfig;
