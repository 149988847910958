import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { search } from 'src/shared/services/url/UrlCommunication';
import { useGetList } from 'src/shared/services/api/query';

const KEY = 'Search';

const useSearch = (
  searchTerm,
  {
    level_id,
    division_id,
    children_id,
    subject_id,
    expand,
    filter,
    enabled,
    ...options
  } = {},
) => {
  const { enqueueSnackbar } = useSnackbar();

  const filters = {
    level_id,
    division_id,
    children_id,
    subject_id,
    expand,
    filter,
    searchTerm,
  };

  const query = useGetList(
    [KEY, filters],
    search(),
    {
      query: searchTerm,
      level_id: filters.level_id,
      division_id: filters.division_id,
      children_id: filters.children_id,
      subject_id: filters.subject_id,
      expand: filters.expand,
      filter: filters.filter,
    },
    true,
    false,
    {
      enabled: enabled ?? Boolean(searchTerm),
      onError: () => {
        enqueueSnackbar(t`Hubo un problema intentando hacer su búsqueda`, {
          variant: 'error',
        });
      },
      ...options,
    },
  );

  return {
    ...query,
    data: query.data?.[0] ?? { events: [], users: [], posts: [] },
  };
};

export default useSearch;
