import { paymentTaxServiceStatusUrl } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { useGetPaginatedList } from 'src/shared/services/api/query';

const usePaymentTaxServiceStatus = ({
  refetchOnMount,
  queryParams,
  ...params
}) => {
  return useGetPaginatedList(
    [QUERY_KEY.PAYMENT_TAX_SERVICE_STATUS, params],
    paymentTaxServiceStatusUrl(),
    params,
    true,
    {
      refetchOnMount,
      ...queryParams,
    },
  );
};

export default usePaymentTaxServiceStatus;
