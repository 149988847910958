import {
  COUNTRY_LOCALES,
  LANGUAGES_PREFIXES,
} from 'src/shared/utils/locales/localizationConfig';

const getLocale = (userLocale) => {
  let locale;

  switch (userLocale) {
    case COUNTRY_LOCALES.US.code:
      locale = LANGUAGES_PREFIXES.ENGLISH;
      break;
    case COUNTRY_LOCALES.BRAZIL.code:
      locale = LANGUAGES_PREFIXES.PORTUGUESE;
      break;
    default:
      locale = LANGUAGES_PREFIXES.SPANISH;
  }
  return locale;
};

export default getLocale;
