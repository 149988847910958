import { Buffer } from 'buffer';

const handleCustomLink = (
  link = '',
  userDocumentNumber = '',
  appHashes = {},
) => {
  const hasQueryParam = Boolean(link.includes('?'));
  const hashedDocumentNumber = Buffer.from(
    userDocumentNumber.toString(),
  ).toString('base64');
  const { app_id, auth } = appHashes;
  const separator = hasQueryParam ? '&' : '?';
  return `${link}${separator}username=${hashedDocumentNumber}&app_id=${app_id}&auth=${auth}`;
};

export default handleCustomLink;
