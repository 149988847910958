import React from 'react';
import PropTypes from 'prop-types';
import { Field as FormikField } from 'formik';

import {
  Alert,
  RadioGroup as MuiRadioGroup,
  FormControl,
  Grid,
  Collapse,
} from '@mui/material';

import FieldGroup from '../FieldGroup';

const RadioGroup = ({
  name,
  title,
  subtitle,
  children,
  gridProps = { xs: 12 },
  fullWidth,
  ...rest
}) => {
  return (
    <FieldGroup title={title} subtitle={subtitle}>
      <FormControl component="fieldset" fullWidth={fullWidth}>
        <FormikField name={name}>
          {({ field, meta }) => (
            <MuiRadioGroup
              value={field.value}
              name={field.name}
              onChange={field.onChange}
              {...rest}
            >
              <Grid item container spacing={2} {...gridProps}>
                {children}
              </Grid>

              <Collapse in={Boolean(meta.error)}>
                <Alert severity="info">{meta.error}</Alert>
              </Collapse>
            </MuiRadioGroup>
          )}
        </FormikField>
      </FormControl>
    </FieldGroup>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.string,
  children: PropTypes.any.isRequired,
  gridProps: PropTypes.object,
  fullWidth: PropTypes.bool,
};

export default RadioGroup;
