import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  externalLink: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  fullWidth: {
    width: '100%',
  },
  inline: {
    display: 'inline-block',
  },
}));

export default useStyles;
