import { t } from '@lingui/macro';

const calendarViews = [
  { value: 'dayGridMonth', label: t`Mes` },
  { value: 'timeGridWeek', label: t`Semana` },
  { value: 'timeGridDay', label: t`Día` },
  { value: 'listWeek', label: t`Agenda` },
];

const GUEST_TYPE = {
  GENERAL: 'generalGuests',
  INDIVIDUAL: 'individualGuests',
  GROUP: 'groupGuests',
};

export { calendarViews, GUEST_TYPE };
