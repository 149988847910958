import React, { memo } from 'react';
import NotificationsView from './views/NotificationsView';
import NotificationsButton from './components/NotificationsButton';
import NotificationsList from './components/NotificationsList';

const Notifications = () => {
  return <NotificationsView />;
};

export { NotificationsButton, NotificationsList, NotificationsView };

export default memo(Notifications);
