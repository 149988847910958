import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Badge,
  Checkbox,
  FormControl,
  Grid,
  Box,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import useStyles from './styles';

const MultipleSelectField = ({
  gridProps = { xs: 12 },
  name,
  disabled,
  label,
  placeholder,
  multiple,
  loading,
  withSelectAll,
  renderOption,
  options: rawOptions,
  maxItemsPlaceholder = 1,
  displayEmpty,
  handleInputChange,
  ...rest
}) => {
  const classes = useStyles();
  const [field, meta, form] = useField(name);
  const { setFieldValue } = useFormikContext();
  const [selectedCount, setSelectedCount] = useState(0);
  const options = [...rawOptions];

  const setValue = (name, values) => {
    setFieldValue(name, values);
    if (handleInputChange) handleInputChange(values);
  };

  if (withSelectAll && multiple) {
    options.unshift({ id: 'all', name: t`Todos` });
  }

  const isSelected = (option) => {
    const allOptions = rawOptions.filter((el) => !el.disabled && !el.isLabel);
    const isAllSelected = field.value.length === allOptions.length;
    return field.value.some((el) => el.value === option.value) || isAllSelected;
  };

  const renderSelectOptions = ({ isLabel, ...option }, index) => {
    if (renderOption) return renderOption(option, index);
    return (
      <MenuItem
        disableGutters
        value={option}
        key={option.id}
        disabled={option.disabled}
        classes={{ root: classes.rootInput }}
        className={clsx({ [classes.label]: isLabel })}
      >
        {!isLabel && <Checkbox checked={isSelected(option)} />}
        <ListItemText primary={option.name} />
        {option.badge && (
          <Badge variant="dot" classes={{ badge: option.badgeClassName }} />
        )}
      </MenuItem>
    );
  };

  const renderValue = (selectedOptions) => {
    const count = selectedOptions.length;
    const joinedValues = selectedOptions.map((el) => el.name).join(', ');

    if (!count && displayEmpty) return <Box>{label ?? placeholder}</Box>;

    return count > maxItemsPlaceholder ? (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {label ?? placeholder}
        <Avatar variant="square" className={classes.menuCount}>
          {selectedCount}
        </Avatar>
      </Box>
    ) : (
      joinedValues
    );
  };

  const handleSelectAll = () => {
    const allOptions = rawOptions.filter((el) => !el.disabled && !el.isLabel);
    if (field.value.length === allOptions.length) {
      return setValue(field.name, []);
    }
    return setValue(field.name, allOptions);
  };

  const handleChange = ({ target }) => {
    const { value } = target;
    const selectedOption = value.pop();
    const allSelectedOptions = field.value;
    const exists = allSelectedOptions.some(
      (option) => option.value === selectedOption.value,
    );
    if (selectedOption.id === 'all') return handleSelectAll();

    if (exists) {
      return setValue(
        field.name,
        allSelectedOptions.filter(
          (option) => option.value !== selectedOption.value,
        ),
      );
    }
    return setValue(field.name, [...allSelectedOptions, selectedOption]);
  };

  useEffect(() => {
    setSelectedCount(field.value.length);
  }, [field.value.length]);

  return (
    <Grid item {...gridProps}>
      <FormControl className={classes.formControl}>
        {label && (
          <InputLabel
            classes={{ formControl: classes.MuiInputLabelFormControl }}
          >
            {label}
          </InputLabel>
        )}
        <MuiSelect
          fullWidth
          multiple
          displayEmpty
          renderValue={renderValue}
          error={Boolean(meta.touched && meta.error)}
          disabled={
            form.isSubmitting || form.isValidating || loading || disabled
          }
          className={clsx({
            [classes.selectedInputActive]:
              field.value.length > maxItemsPlaceholder,
          })}
          placeholder={placeholder}
          {...rest}
          {...field}
          onChange={handleChange}
        >
          {options.map((option, index) => renderSelectOptions(option, index))}
        </MuiSelect>
      </FormControl>
    </Grid>
  );
};

MultipleSelectField.propTypes = {
  gridProps: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  withSelectAll: PropTypes.bool,
  renderOption: PropTypes.func,
  options: PropTypes.array.isRequired,
  maxItemsPlaceholder: PropTypes.number,
  displayEmpty: PropTypes.bool,
  handleInputChange: PropTypes.func,
};

export default memo(MultipleSelectField);
