import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  MuiLabelPlacementStart: {
    margin: 0,
  },
  MuiLabel: {
    fontWeight: 500,
  },
});

export default useStyles;
