import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 390,
  },

  list: {
    overflow: 'auto',
  },

  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
  badge: {
    top: 3,
    right: 6,
    border: `1px solid ${theme.palette.background.paper}`,
  },

  MuiButtonText: {
    textTransform: 'none',
  },
}));

export default useStyles;
