import { getLocalStorageKey } from 'src/shared/utils/manageLocalStorage';
import BLENDED_STORAGE_KEYS from 'src/shared/utils/storageKeys';

export const DEFAULT_COLOR = '#0071F1';
export const DEFAULT_ACCENT = '#EFB22C';

export const getCustomColor = () => {
  //IN LOGIN URL WITH WHITELABEL CASE MUST USE THE COLOR SHARED BY WHITELABEL
  const queryParams = new URLSearchParams(window.location.search);

  const whitelabel = queryParams.get('whitelabel'); //IT MUST BE IN BASE 64 FORMAT(NO EXCEPTIONS)

  const whitelabelParsed = Boolean(whitelabel) && JSON.parse(atob(whitelabel));

  const cachedColor = getLocalStorageKey(
    BLENDED_STORAGE_KEYS.INSTITUTION_COLOR,
  );

  return (
    (Boolean(whitelabelParsed.wlcolor) && `#${whitelabelParsed.wlcolor}`) ||
    (cachedColor ?? DEFAULT_COLOR)
  );
};

export default {
  tonalOffset: 0.5,
  action: {
    hover: '#FAFAFA',
    disabled: '#8E9099',
    disabledBackground: '#EFF0FA',
    disabledOpacity: 0.38,
  },
  default: {
    light: '#AEC6FF',
    main: DEFAULT_COLOR,
    dark: '#001A42',
  },
  primary: {
    main: getCustomColor(),
  },
  secondary: {
    light: '#C5C6D0',
    main: '#2E3038',
    dark: '#000000',
  },
  info: {
    light: '#AEC6FF',
    main: '#4E8EFF',
    dark: '#256BE8',
  },
  success: {
    light: '#B2E4C3',
    main: '#62B975',
    dark: '#409E55',
  },
  error: {
    light: '#FFC6C0',
    main: '#D32F2F',
    dark: '#A01B1B',
  },
  danger: {
    light: '#FFB59C',
    main: '#FF6D3B',
    dark: '#D63500',
  },
  warning: {
    light: '#F9DEA5',
    main: '#FFBF27',
    dark: '#DF9D00',
  },
  common: {
    black: '#000',
    white: '#FFF',
  },
  text: {
    primary: '#1D1D1D',
    secondary: '#5C5E66',
    disabled: '#8E9099',
  },
  divider: '#E1E2EC',
  background: {
    paper: '#FFFFFF',
    default: '#FFFFFF',
  },
  container: {
    light: '#D8E2FF',
  },
  grey: {
    900: '#000000',
    800: '#2E3038',
    700: '#5C5E66',
    600: '#8E9099',
    500: '#A9ABB4',
    400: '#C5C6D0',
    300: '#E1E2EC',
    200: '#EFF0FA',
    100: '#F5F6FB',
    50: '#FAFAFA',
  },
  green: {
    main: '#D8F4CB',
  },
};
