import { useQuery } from '@tanstack/react-query';

import abstractSerializer from 'src/shared/services/api/query/serializer/abstractSerializer';
import { useErrorMessage } from '../common/useErrorMessage';
import { abstractRequest } from '../common/abstractRequest';

export const useGetOne = (
  key,
  url,
  params = {},
  defaultError = true,
  isPost = false,
  queryParams = {},
  customSerializer,
) => {
  const { isRaw, headers, ...restOfParams } = params;

  const withErrorMessage = useErrorMessage();
  const { deserialize } = customSerializer || abstractSerializer();
  const request = () =>
    abstractRequest({
      url,
      params: { ...restOfParams },
      isPost,
      isRaw,
      headers,
    });
  const queryFn = defaultError ? withErrorMessage(request) : request;
  const query = useQuery(key, queryFn, queryParams);

  if (query.isSuccess) {
    return {
      ...query,
      data: deserialize(query?.data ?? {}),
    };
  }

  return query;
};
