import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  TextField as MuiTextField,
  InputLabel,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { useField } from 'formik';

import useStyles from './styles';

const FileField = ({
  gridProps = { xs: 12 },
  name,
  disabled,
  label,
  helperText,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const [field, meta, form] = useField(name);

  const InputProps = loading
    ? {
        endAdornment: (
          <InputAdornment position="start">
            <CircularProgress size={12} color="primary" />
          </InputAdornment>
        ),
      }
    : undefined;

  return (
    <Grid item {...gridProps}>
      <FormControl className={classes.formControl}>
        {label && (
          <InputLabel
            classes={{ formControl: classes.MuiInputLabelFormControl }}
          >
            {label}
          </InputLabel>
        )}
        <MuiTextField
          fullWidth
          classes={{ root: classes.MuiInput }}
          error={Boolean(meta.error)}
          helperText={meta.error || helperText}
          variant="outlined"
          disabled={
            form.isSubmitting || form.isValidating || disabled || loading
          }
          type="file"
          InputProps={InputProps}
          onBlur={field.onBlur}
          onChange={(event) => {
            form.setValue(event.target.files[0]);
          }}
          {...rest}
        />
      </FormControl>
    </Grid>
  );
};

FileField.propTypes = {
  gridProps: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  loading: PropTypes.bool,
  inputProps: PropTypes.object,
};

export default FileField;
