import React from 'react';
import { Box, Typography } from '@mui/material';
import { Trans, t } from '@lingui/macro';
import { Home, GridView, Create } from '@mui/icons-material';

import app1 from 'src/assets/images/changelog/02-23/app-1.png';
import app2 from 'src/assets/images/changelog/02-23/app-2.png';
import { useBreakpoints } from 'src/shared/hooks';

const bulletList = [
  {
    title: t`Portal`,
    description: t`Rediseñamos la estructura de la aplicación con el objetivo de optimizar la comunicación en la institución.`,
    color: '#F5D7D9',
    icon: Home,
  },
  {
    title: t`Launcher`,
    description: t`En esta nueva sección encontrarás toda la información que buscas de manera rápida y directa.`,
    color: '#FF6D3B',
    icon: GridView,
  },
  {
    title: t`Personalización`,
    description: t`Creamos una paleta tonal que acompaña el color de la institución, garantizando un contraste óptimo.`,
    color: '#362676',
    icon: Create,
  },
];

const AppStep = () => {
  const { isMobile } = useBreakpoints();

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        px: isMobile ? 2 : 10,
        ...(isMobile && {
          position: 'relative',
          alignItems: 'center !important',
          justifyContent: 'center !important',
          height: '100%',
        }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxWidth: 330,
        }}
      >
        <Typography
          variant="h1"
          fontSize={isMobile ? 16 : 20}
          fontFamily="Sora"
          textAlign={isMobile ? 'center' : 'left'}
          {...(isMobile && {
            mb: 2,
            mt: -6,
          })}
        >
          <Trans>Renovamos la app</Trans>
        </Typography>

        <Box
          component="ul"
          sx={{ flexDirection: 'column', display: 'flex', gap: 2, ml: 1 }}
        >
          {bulletList.map((item, index) => (
            <Box
              component="li"
              key={index}
              sx={{
                display: 'flex',
                gap: 2,
                ...(isMobile && {
                  flexDirection: 'column',
                  textAlign: 'center',
                }),
              }}
            >
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  borderRadius: '50%',
                  backgroundColor: item.color,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  ...(isMobile && {
                    margin: '0 auto',
                  }),
                }}
              >
                <item.icon sx={{ color: '#fff', fontSize: 18 }} />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <Typography variant={isMobile ? 'h3' : 'h2'} fontWeight="bold">
                  {item.title}
                </Typography>
                <Typography variant={isMobile ? 'h4' : 'h3'}>
                  {item.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          flex: 1,
        }}
      >
        {!isMobile && (
          <>
            <Box
              component="img"
              height={340}
              width={160}
              src={app1}
              alt="Logo rebrand gif"
            />

            <Box
              component="img"
              height={340}
              width={160}
              src={app2}
              alt="Logo rebrand gif"
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default AppStep;
