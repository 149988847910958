import PropTypes from 'prop-types';

const ConditionalWrapper = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children;
};

ConditionalWrapper.displayName = 'ConditionalWrapper';

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool.isRequired,
  wrapper: PropTypes.elementType.isRequired,
  children: PropTypes.any.isRequired,
};

export default ConditionalWrapper;
