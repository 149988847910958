/* eslint-disable import/prefer-default-export */
import React from 'react';
import { nanoid } from 'nanoid';
import { Trans, t } from '@lingui/macro';
import { ROLE_LIST } from './general';

export const THEMES = {
  BLENDED: 'BLENDED',
};
export const DEFAULT_ERROR_MESSAGE = t`Ha ocurrido un error.`;

// TODO: Use `defaultHomeworkStatus` instead of this const for filters
export const deliveryStatusFilters = [
  { id: 'all', name: <Trans>Todos</Trans>, slug: 'all' },
  { id: 1, name: <Trans>Pendiente</Trans>, slug: 'pending' },
  { id: 2, name: <Trans>Entregado</Trans>, slug: 'send' },
  {
    id: 3,
    name: <Trans>Entregado fuera de término</Trans>,
    slug: 'done_out_range',
  },
  { id: 4, name: <Trans>No entregado</Trans>, slug: 'not_done' },
  { id: 5, name: <Trans>Corregida</Trans>, slug: 'done' },
  { id: 6, name: <Trans>Rehacer</Trans>, slug: 'redo' },
];

export const defaultHomeworkStatus = {
  PENDING: {
    id: 1,
    label: t`Pendiente`,
    slug: 'pending',
  },
  DELIVERED: {
    id: 2,
    label: t`Entregado`,
    slug: 'delivered',
  },
  OUT_DUE: {
    id: 3,
    label: t`Entregado fuera de término`,
    slug: 'delivered_late',
  },
  NOT_DONE: {
    id: 4,
    label: t`No entregado`,
    slug: 'not_delivered',
  },
  DONE: {
    id: 5,
    label: t`Corregida`,
    slug: 'finished',
  },
  REDO: {
    id: 6,
    label: t`Rehacer`,
    slug: 'redo',
  },
};

export const taskStatusFilters = [
  { id: 'all', name: <Trans>Todas</Trans>, slug: 'all' },
  { id: 1, name: <Trans>En curso</Trans>, slug: 'active' },
  { id: 0, name: <Trans>Cerradas</Trans>, slug: 'unactive' },
];

export const whatsappLikeColors = {
  READ: '#2ab5eb',
};

export const DEFAULT_PAGINATION_CONFIG = {
  PER_PAGE_OPTIONS: [5, 10, 25],
  ROWS_PER_PAGE: 10,
};

export const allowedHomeworkState = [defaultHomeworkStatus.DELIVERED];

export const changeableHomeworkStatus = [defaultHomeworkStatus.PENDING];

export const NOTIFICATION_TYPE_EVENT = 1;
export const NOTIFICATION_TYPE_POST = 2;
export const NOTIFICATION_TYPE_QUALIFICATION = 3;
export const NOTIFICATION_TYPE_ATTENDANCE = 4;
export const NOTIFICATION_TYPE_GENERAL = 5;
export const NOTIFICATION_TYPE_COMMENT = 6;
export const NOTIFICATION_TYPE_MESSAGE = 7;
export const NOTIFICATION_TYPE_VIRTUAL_CLASSROOM = 8;
export const NOTIFICATION_TYPE_BEHAVIOUR = 9;
export const NOTIFICATION_TYPE_PAYMENT_SYSTEM = 10;

export const NOTIFICATION_TEXT_TYPE_EVENT = 'Eventos';
export const NOTIFICATION_TEXT_TYPE_POST = 'Publicaciones';
export const NOTIFICATION_TEXT_TYPE_QUALIFICATION = 'Notas';
export const NOTIFICATION_TEXT_TYPE_ATTENDANCE = 'Asistencias';
export const NOTIFICATION_TEXT_GENERAL = 'General';
export const NOTIFICATION_TEXT_COMMENT = 'Comentario';
export const NOTIFICATION_TEXT_TYPE_MESSAGE = 'Mensajes';
export const NOTIFICATION_TEXT_TYPE_GENERIC = 'Notificación';
export const NOTIFICATION_TEXT_VIRTUAL_CLASSROOM = 'Classroom';

export const MAX_DIVISIONS_PER_POST = 3;

// FILE UPLOAD TYPES
export const FILE_UPLOAD_TYPE_DEFAULT = 1;
export const FILE_UPLOAD_TYPE_VIDEO = 2;
export const FILE_UPLOAD_TYPE_GOOGLE_DRIVE = 3;

// ROLES ID
export const ADMIN_ROLE_ID = 1;
export const EXECUTIVE_ROLE_ID = 2;
export const PRECEPTOR_ROLE_ID = 3;
export const TEACHER_ROLE_ID = 4;
export const PARENT_ROLE_ID = 5;
export const STUDENT_ROLE_ID = 6;

export const qualificationStatus = [
  defaultHomeworkStatus.DELIVERED.id,
  defaultHomeworkStatus.OUT_DUE.id,
];

export const BLENDED_MODULES = {
  ASSISTANT: {
    id: 16,
    name: 'assistant',
  },
  ATTENDANCE: {
    id: 1,
    name: 'attendance',
  },
  PAYMENT_MANAGEMENT: {
    id: 2,
    name: 'payments',
  },
  GRADING: {
    id: 3,
    name: 'scores',
  },
  VIRTUAL_CLASSROOM: {
    id: 4,
    name: 'virtual_classroom"',
  },

  OFFICIAL_REPORTS: {
    id: 5,
    name: 'official_reports',
  },

  BEHAVIOUR: {
    id: 6,
    name: 'behavior_track',
  },

  PRIMARY_REPORTS: {
    id: 7,
    name: 'assessment_score_card',
  },

  REPORT_CARDS: {
    id: 8,
    name: 'score_card',
  },
  CALENDAR: {
    id: 9,
    name: 'events',
  },
  CONFIG: {
    id: 10,
    name: 'configuration',
  },
  PICKUPS: {
    id: 13,
    name: 'authorization',
  },
  ACTIVITY: {
    id: 14,
    name: 'activity',
  },
  NEWSFEED: {
    id: 'newsfeed',
    name: 'newsfeed',
  },
  GROUPS: {
    id: 'groups',
    name: 'groups',
  },
  INBOX: {
    id: 'inbox',
    name: 'inbox',
  },
  SUBJECTS: {
    id: 'subjects',
    name: 'subjects',
  },
  DOCUMENTS: {
    id: 'documents',
    name: 'documents',
  },
  REPORTS: {
    id: 'reports',
    name: 'reports',
  },
  PENDING: {
    id: 'pending',
    name: 'pending',
  },
  DIVISIONS: {
    id: 'divisions',
    name: 'divisions',
  },

  PAYMENT_CONTROL: {
    id: nanoid(),
    name: 'payment-control',
  },
  FAMILIY_PAYMENTS: {
    id: nanoid(),
    name: 'family-payment',
  },
  PAYMENT_NOTIFICATION: {
    id: nanoid(),
    name: 'payment-notification',
  },
  BILLING: {
    id: nanoid(),
    name: 'billing',
  },
  PAYMENT_MANAGEMENT_FAMILY_BALANCE: {
    id: nanoid(),
    name: 'payment-management-family-balance',
  },
  PAYMENT_MANAGEMENT_FAMILY_CONCEPTS: {
    id: nanoid(),
    name: 'payment-management-family-concepts',
  },
  PAYMENT_MANAGEMENT_FAMILY_GROUP: {
    id: nanoid(),
    name: 'payment-management-family-group',
  },
  PAYMENT_MANAGEMENT_FAMILY_TAX_INFO: {
    id: nanoid(),
    name: 'payment-management-family-tax-info',
  },
  PAYMENT_MANAGEMENT_ONLINE_COLLECTION: {
    id: nanoid(),
    name: 'payment-management-online-collection',
  },
};

export const PAPER_CARD_MAX_WIDTH = 750;
export const PAPER_CARD_MAX_HEIGHT = 370;
export const TABLE_MAX_WIDTH = 1200;
export const TABLE_MIN_WIDTH = 950;

// Hotjar
// export const HOTJAR_ID = 2325050;
// export const HOTJAR_SV = 6;

export const USER_DEFAULT_IMG = 'alumno-img.jpg';
// Enviroment
export const environment = process.env.NODE_ENV;
export const isProduction = environment === 'production';
export const isDevelop = environment === 'development';

// Sentry
export const release = process.env.REACT_APP_RELEASE_VERSION;
export const dsn = process.env.REACT_APP_SENTRY_URL;

export const RSVP = {
  notAttending: 0,
  attending: 1,
  maybe: 2,
};

export const RSVP_LABEL = {
  [RSVP.notAttending]: t`No`,
  [RSVP.attending]: t`Sí`,
  [RSVP.maybe]: t`Quizás`,
};

export const ROLES_ORDER = [
  ROLE_LIST.EXECUTIVE,
  ROLE_LIST.PRECEPTOR,
  ROLE_LIST.TEACHER,
  ROLE_LIST.STUDENT,
  ROLE_LIST.PARENT,
];

export const VIMEO_BLACKLIST_FOLDERS = ['classroom', 'message'];

export const HOMEWORK_STATUS = {
  CLOSED: 0,
  ACTIVE: 1,
  SCHEDULED: 2, // 'programmed' para el backend
};

export const INVOICE_STATUS = {
  DRAFT: 'draft',
  CREATED: 'created',
  SENT: 'sent',
  PENDING: 'pending',
  TO_REVIEW: 'to_review',
  PAID: 'paid',
  DUE: 'due',
  EXPIRED: 'expired',
  APPROVED: 'approved',
  PAYMENT_ORDER: 'payment_order',
  REJECT: 'reject',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
};

export const INVOICE_TYPES = {
  INVOICE: 'invoice',
  INITIAL_TRANSACTION: 'initial_transaction',
};

export const D_LOCAL_DOCUMENTS_STATUS = {
  PENDING: 'pending',
  APPROVED: 'completed',
};

export const D_LOCAL_TIER_CLASSIFICATION = {
  PUBLICO: 'publico',
  PERFIL_PRIVADO: 'privado_oficial',
  PRIVADO_NO_OFICIAL: 'privado_no_oficial',
};

export const D_LOCAL_DOCUMENT_TYPES = {
  TAX_ID: 'TAX_ID',
  CERTIFICATE_OF_INCORPORATION: 'CERTIFICATE_OF_INCORPORATION',
  CERTIFICATE_OF_GOOD_STANDING: 'CERTIFICATE_OF_GOOD_STANDING',
  SHAREHOLDERS_REGISTRY: 'SHAREHOLDERS_REGISTRY',
  DIRECTORS_REGISTRY: 'DIRECTORS_REGISTRY',
  ARTICLES_OF_ASSOCIATION_BYLAWS_MEMORANDUM_OF_INCORPORATION:
    'ARTICLES_OF_ASSOCIATION_BYLAWS_MEMORANDUM_OF_INCORPORATION',
  UBO_COPY_OF_ID_FRONT: 'UBO_COPY_OF_ID_FRONT',
  UBO_COPY_OF_ID_BACK: 'UBO_COPY_OF_ID_BACK',
  UBO_PROOF_OF_ADDRESS: 'UBO_PROOF_OF_ADDRESS',
  DIRECTOR_COPY_OF_ID_FRONT: 'DIRECTOR_COPY_OF_ID_FRONT',
  DIRECTOR_COPY_OF_ID_BACK: 'DIRECTOR_COPY_OF_ID_BACK',
  DIRECTOR_PROOF_OF_ADDRESS: 'DIRECTOR_PROOF_OF_ADDRESS',
  COPY_OF_ID_FRONT: 'COPY_OF_ID_FRONT',
  COPY_OF_ID_BACK: 'COPY_OF_ID_BACK',
  EXEMPTION_IVA: 'EXEMPTION_IVA',
  EXEMPTION_GANANCIA: 'EXEMPTION_GANANCIA',
};

export const CURRENCIES = {
  ARS: {
    id: 'ARS',
    name: t`Peso argentino`,
  },
  BRL: {
    id: 'BRL',
    name: t`Real brasileño`,
  },
};

export const DEFAULT_SCORE_SCALE = {
  id: null,
  name: t`Numérica`,
};

export const BILLING_STATUS = {
  NO_INVOICED: 'no-invoiced',
  INVOICED: 'invoiced',
  FAIL: 'fail',
  CANCELLED: 'invoiced-cancelled',
  NOT_BILLING: 'not-billing',
  CONFLICTED: 'conflicted',
  INVOICED_CONFLICT: 'invoiced-conflict',
};

export const GENDER_TYPES_ID = {
  MALE: 1,
  FEMALE: 2,
};

export const GENDER_TYPES = () => [
  { id: GENDER_TYPES_ID.MALE, name: t`Masculino` },
  { id: GENDER_TYPES_ID.FEMALE, name: t`Femenino` },
];

export const INVOICE_ADJUSTMENT_TYPES = () => [
  { id: 'invoice_adjustment_increment', name: t`Recargo` },
  { id: 'invoice_adjustment_discount', name: t`Descuento` },
];

export const VOUCHER_ACCEPTED_FILES = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/bmp',
  'image/x-png',
  'image/jpg',
  'image/gif',
  'image/pjpeg',
];

export const D_LOCAL_STATUS_TYPES = {
  RECEIVED: {
    name: t`Recibido`,
    id: 'RECEIVED',
  },
  COMPLETED: {
    name: t`Completado`,
    id: 'COMPLETED',
  },
  CANCELLED: {
    name: t`Cancelado`,
    id: 'CANCELLED',
  },
  REJECTED: {
    name: t`Rechazado`,
    id: 'REJECTED',
  },
  DELIVERED: {
    name: t`Entregado`,
    id: 'DELIVERED',
  },
  ON_HOLD: {
    name: t`En espera`,
    id: 'ON_HOLD',
  },
  EXTERNAL_ERROR: {
    name: t`Error externo`,
    id: 'EXTERNAL_ERROR',
  },
};

export const PAYMENT_TYPES = {
  POSITIVE_BALANCE: 'positive_balance',
};

export const RAIL_NAV_WIDTH = 80;

export const MAX_NAMES_PER_CELL = 2;
