import React from 'react';
import PropTypes from 'prop-types';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Trans } from '@lingui/macro';
import { Alert } from '@mui/material';
import ScrollBar from '../ScrollBar';
import useStyles from './styles';

const InfiniteScroll = ({
  loading,
  onLoadMore,
  hasNextPage,
  disabled,
  loadingComponent,
  endComponent,
  children,
  disableEndComponent,
  ...props
}) => {
  const classes = useStyles();
  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    disabled,
  });
  const showEndReachedComponent =
    !hasNextPage && !loading && !disableEndComponent;
  const endReachedComponent = endComponent ?? (
    <Alert severity="info" className={classes.alert}>
      <Trans>No hay más resultados</Trans>
    </Alert>
  );

  return (
    <ScrollBar {...props}>
      {children}
      {(loading || hasNextPage) && (
        <div ref={infiniteRef}>{loadingComponent}</div>
      )}
      {showEndReachedComponent ? endReachedComponent : null}
    </ScrollBar>
  );
};

InfiniteScroll.propTypes = {
  loading: PropTypes.bool,
  onLoadMore: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  loadingComponent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  endComponent: PropTypes.node,
  disableEndComponent: PropTypes.bool,
};

export default InfiniteScroll;
