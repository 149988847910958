import { atom } from 'jotai';
import filterFamily from './filterFamily';

const setFilterAtom = atom(null, (get, set, payload) => {
  const filterAtom = filterFamily(payload);

  const filter = get(filterAtom);

  set(filterAtom, {
    ...filter,
    searchTerm: payload.searchTerm,
  });
});

export default setFilterAtom;
