import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { WebinarDialog, BackToClassDialog } from 'src/components/Dialogs';
import { tablet } from 'src/shared/utils/breakpoints';
import {
  useBreakpoints,
  useDialogIsOpen,
  useOpenDialog,
} from 'src/shared/hooks';
import useUserInfo from 'src/features/payment-management/hooks/useUserInfo';
import { RAIL_NAV_WIDTH } from 'src/config/constants';
import ExternalLinksDialog from 'src/features/sidebar/components/ExternalLinksDialog';
import LinkChildrenDialog from 'src/features/sidebar/components/LinkChildrenDialog';
import DeleteExternalLinkDialog from 'src/features/sidebar/components/DeleteExternalLinkDialog';
import { dialogs } from 'src/shared/constants';

import NewYearManagerTrigger from '../components/NewYearManagerTrigger';
import Main from '../components/Main';
import NavBar from './NavBar';
import TopBar from './TopBar';

const MainLayout = ({ children }) => {
  const { isMobile } = useBreakpoints();
  const { isWebView: userIsInWebView, userIs, apiKey } = useUserInfo();
  const canSeeWebinar = userIs.highRole || userIs.teacher;
  const isWebView = userIsInWebView || !apiKey;
  const fallbackMargin = isWebView || isMobile ? 0 : RAIL_NAV_WIDTH;
  const setOpenLinkedSons = useOpenDialog(dialogs.LINKED_SONS_DIALOG);
  const isOpenLinkedSons = useDialogIsOpen(dialogs.LINKED_SONS_DIALOG);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      {!isWebView && (
        <>
          <BackToClassDialog />
          <NewYearManagerTrigger />

          <TopBar
            sx={{
              [tablet]: {
                width: ` calc(100% - ${fallbackMargin}px)`,
                marginLeft: 255,
              },
            }}
          />

          <NavBar />

          {canSeeWebinar && <WebinarDialog />}

          <ExternalLinksDialog />
          <DeleteExternalLinkDialog />
          <LinkChildrenDialog
            open={isOpenLinkedSons}
            handleClose={() => setOpenLinkedSons(false)}
          />
        </>
      )}

      <Main marginWrapper={fallbackMargin} isWebView={isWebView}>
        {children}
      </Main>
    </Box>
  );
};

MainLayout.propTypes = {
  children: PropTypes.any,
};

export default MainLayout;
