import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { useCreate } from 'src/shared/services/api/query';
import { dLocalPaymentOrderProcess } from 'src/shared/services/url/UrlPaymentManagement';
import { isJSON } from 'src/shared/helpers';

const ERROR_MESSAGE = t`Hubo un error al realizar el pago.`;

const useDLocalPaymentOrderProcess = (paymentOrderId) => {
  const { enqueueSnackbar } = useSnackbar();

  return useCreate(
    dLocalPaymentOrderProcess(paymentOrderId),
    {
      onError: ({ message: error }) => {
        const message = isJSON(error) ? JSON.parse(error)?.message : error;
        enqueueSnackbar(message ?? ERROR_MESSAGE, { variant: 'error' });
      },
    },
    false,
  );
};

export default useDLocalPaymentOrderProcess;
