import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import theme from 'src/theme';
import { getImageByType } from './helper';
import useStyles from './styles';

const EmptyState = memo(
  ({
    type = 'none',
    title = '',
    subtitle = '',
    boxContainerStyle,
    Image,
    ...rest
  }) => {
    const classes = useStyles();
    const ImageComponent = getImageByType(type);
    const boxMargin = type !== 'none' ? 5 : 0;

    return (
      <Box pt={boxMargin} display="flex" justifyContent="center" {...rest}>
        <Box className={classes.container} {...boxContainerStyle}>
          {Image ? (
            <Image
              fill={theme.palette.primary.main}
              className={classes.emptyImage}
            ></Image>
          ) : (
            type !== 'none' && (
              <ImageComponent
                fill={theme.palette.primary.main}
                className={classes.emptyImage}
              />
            )
          )}

          {title && <Typography variant="h2">{title}</Typography>}

          {subtitle && (
            <Typography variant="h4" className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
    );
  },
);

EmptyState.displayName = 'EmptyState';

EmptyState.propTypes = {
  type: PropTypes.oneOf([
    'attendance',
    'newsfeed',
    'savedPosts',
    'scheduledPosts',
    'myPosts',
    'search',
    'groups',
    'success',
    'questions',
    'default',
    'none',
    'illustration',
  ]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default EmptyState;
