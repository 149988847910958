import { Trans } from '@lingui/macro';

import { ROLE_LIST } from 'src/config/general';
import { BLENDED_MODULES } from 'src/config/constants';
import router from 'src/shared/utils/router';

export const MANAGEMENT_CONFIG_ITEMS = [
  {
    id: BLENDED_MODULES.ATTENDANCE.id,
    title: <Trans>Asistencias</Trans>,
    href: router.configuration.attendance,
    roles: [
      ROLE_LIST.ADMIN,
      ROLE_LIST.EXECUTIVE,
      ROLE_LIST.PRECEPTOR,
      ROLE_LIST.TEACHER,
    ],
  },
  {
    id: BLENDED_MODULES.GRADING.id,
    title: <Trans>Calificaciones</Trans>,
    href: router.configuration.grading,
    roles: [ROLE_LIST.ADMIN, ROLE_LIST.EXECUTIVE, ROLE_LIST.PRECEPTOR],
  },
];

export const INSTITUTION_CONFIG_ITEMS = [
  {
    id: 'config',
    title: <Trans>Gestionar mi red</Trans>,
    href: router.configuration.institutionProfile,
    roles: [ROLE_LIST.ADMIN],
  },
  {
    id: 'config-users',
    title: <Trans>Gestionar usuarios</Trans>,
    href: router.configuration.schoolManagementCommunity,
    roles: [ROLE_LIST.ADMIN, ROLE_LIST.EXECUTIVE, ROLE_LIST.PRECEPTOR],
  },
  {
    id: 'config-periods',
    title: <Trans>Períodos</Trans>,
    href: router.configuration.periods,
    roles: [ROLE_LIST.ADMIN],
  },
  {
    id: 'user-card',
    title: <Trans>Ficha de usuario</Trans>,
    href: router.configuration.userProfile,
    roles: [ROLE_LIST.ADMIN],
    enabled: true,
  },
];
