import createRouter from './createRouter';

// Payment management => Collections
const collectionsRouter = createRouter('collections', {
  root: `/pagos`,
  control: `/pagos/control`,
  families: `/pagos/familias`,
  generateInvoices: `/pagos/generar-avisos-pagos`,
  generateMassiveInvoices: `/pagos/generar-avisos-pagos/masivos`,
  generateMassiveInvoicesSent: `/pagos/generar-avisos-pagos/masivos/enviados`,
  generateMassiveInvoicesConcepts: `/pagos/generar-avisos-pagos/masivos/conceptos`,
  generateMassiveInvoicesDiscounts: `/pagos/generar-avisos-pagos/masivos/descuentos`,
  generateExtraordinaryInvoices: `/pagos/generar-avisos-pagos/extraordinario`,
  generateSimpleInvoices: `/pagos/generar-avisos-pagos/simples`,
  generateExternalInfo: `/pagos/generar-avisos-pagos/info-externa`,
  generateBill: `/pagos/facturacion/generar`,
  emptyBilling: `/pagos/facturacion/empty`,
  onlineCollection: `/pagos/recaudacion-online`,
  billingStatement: `/pagos/estado-de-cuenta`,
  billingStatementAccount: `/pagos/estado-de-cuenta/cuenta`,
  billingStatementInvoices: `/pagos/estado-de-cuenta/facturas`,
  billingStatementTaxInformation: `/pagos/estado-de-cuenta/datos-de-facturacion`,
  billing: `/pagos/facturacion`,
  billed: `/pagos/facturados`,
  onboarding: `/pagos/onboarding`,
  onboardingRecurrentDiscounts: `/pagos/onboarding/recurrentes`,
  onboardingNonRecurrentDiscounts: `/pagos/onboarding/no-recurrentes`,
  family: (familyId) => `/pagos/familias/${familyId}`,
  familyBalance: (familyId) => `/pagos/familias/${familyId}/balance`,
  familyTransactions: (familyId) => `/pagos/familias/${familyId}/movimientos`,
  familyConceptsAndDiscounts: (familyId) =>
    `/pagos/familias/${familyId}/conceptos-y-descuentos`,
  familyOtherDiscounts: (familyId) =>
    `/pagos/familias/${familyId}/conceptos-y-descuentos/otros-descuentos`,
  familyDiscounts: (familyId) => `/pagos/familias/${familyId}/descuentos`,
  familyGroup: (familyId) => `/pagos/familias/${familyId}/grupo-familiar`,
  familyExternalEntities: (familyId) =>
    `/pagos/familias/${familyId}/entidades-externas`,
  createFamilyGroupResponsible: (familyId) =>
    `/pagos/familias/${familyId}/grupo-familiar/informacion-de-referente/crear`,
  updateFamilyGroupResponsible: ({ familyId, responsibleId }) =>
    `/pagos/familias/${familyId}/grupo-familiar/informacion-de-referente/${responsibleId}/editar`,
  familyGroupResponsible: ({ familyId, responsibleId }) =>
    `/pagos/familias/${familyId}/grupo-familiar/informacion-de-referente/${responsibleId}`,
  familyTaxInformation: (familyId) =>
    `/pagos/familias/${familyId}/datos-de-facturacion`,
  createFamilyTaxInformation: (familyId) =>
    `/pagos/familias/${familyId}/datos-de-facturacion/crear`,
  updateFamilyTaxInformation: ({ familyId, taxInformationId }) =>
    `/pagos/familias/${familyId}/datos-de-facturacion/${taxInformationId}/editar`,
  familyTaxInformationDetails: ({ familyId, taxInformationId }) =>
    `/pagos/familias/${familyId}/datos-de-facturacion/${taxInformationId}`,
  generatingBilling: (batchId) => `/pagos/facturacion/generando/${batchId}`,
  paymentOrder: (familyId) => `/pagos/orden-de-pago/${familyId}`,
  paymentOrderOnline: (orderId) => `/pagos/pago-online/${orderId}`,
  cashFlow: `/flujo-de-caja`,
});

export default collectionsRouter;
