import React, { memo, useEffect, useState } from 'react';
import { Box, Dialog, IconButton, dialogClasses } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Close as CloseIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { useBreakpoints } from 'src/shared/hooks';

import { steps } from './helpers';
import ChangelogNavigation from './ChangelogNavigation';
import ChangelogContent from './ChangelogContent';
import ChangelogBackground from './ChangelogBackground';

const ChangelogDialog = memo(({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const { isMobile } = useBreakpoints();

  const {
    isChangelogSeen,
    isChangelogClosed,
    changelogCounter,
    setChangelogSeen,
  } = useSelector(({ user }) => ({
    isChangelogSeen: user.changelog.isSeen,
    isChangelogClosed: user.changelog.isClosed,
    changelogCounter: user.changelog.counter,
    setChangelogSeen: dispatch.user.setChangelogSeen,
  }));

  const handleClose = () => {
    setIsOpen(false);
    setChangelogSeen();
  };

  const handlePrevStep = () => {
    if (currentStep === 0) return;
    setCurrentStep((oldState) => oldState - 1);
  };

  const handleNextStep = () => {
    if (currentStep === steps.length - 1) return;
    setCurrentStep((oldState) => oldState + 1);
  };

  const handleFinish = () => {
    setChangelogSeen(true);
    setIsOpen(false);
  };

  useEffect(() => {
    if (
      !isOpen &&
      !isChangelogSeen &&
      // changelogCounter <= MAX_CHANGELOG_COUNTER &&
      !isChangelogClosed &&
      dayjs().isBetween(dayjs(startDate), dayjs(endDate))
    ) {
      setIsOpen(true);
    }
  }, [
    startDate,
    endDate,
    changelogCounter,
    isChangelogSeen,
    isOpen,
    isChangelogClosed,
  ]);

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={isMobile ? 'xl' : 'md'}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          position: 'relative',
          overflow: 'hidden',
          minHeight: '580px',
          transition: 'height 0.3s ease-in-out',
          display: 'flex',
        },
      }}
    >
      <ChangelogBackground currentStep={currentStep} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          flex: 1,
        }}
      >
        <IconButton
          onClick={() => handleClose()}
          size="medium"
          sx={{
            position: 'absolute',
            top: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
            zIndex: 10,
          }}
        >
          <CloseIcon size={16} />
        </IconButton>

        <ChangelogContent steps={steps} currentStep={currentStep} />

        <ChangelogNavigation
          currentStep={currentStep}
          onPrevStep={handlePrevStep}
          onNextStep={handleNextStep}
          onFinish={handleFinish}
          stepCount={steps.length}
        />
      </Box>
    </Dialog>
  );
});

ChangelogDialog.displayName = 'ChangelogDialog';

ChangelogDialog.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default ChangelogDialog;
