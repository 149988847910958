import { useEffect } from 'react';

const useBeforeRender = () => {
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message.includes('ResizeObserver')) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.remove();
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.remove();
        }
      }
    });
  }, []);
};

export default useBeforeRender;
