import { SIS_URL } from 'src/config/general';

export const getLegacyObservationsUrl = () =>
  `${SIS_URL}/ajax/nota/get_observaciones`;

export const saveLegacyObservationsUrl = () =>
  `${SIS_URL}/ajax/nota/save_observaciones`;

export const getLegacyReportCardCacheData = () =>
  `${SIS_URL}/ajax/base/get_cache_data`;

export const getLegacyReportCardPreview = () =>
  `${SIS_URL}/ajax/base/get_reportcard_preview`;

export const sendLegacyReportCards = () =>
  `${SIS_URL}/ajax/base/generar_boletines`;

export const setLegacyReportCardCacheData = () =>
  `${SIS_URL}/ajax/base/set_cache_data`;

export const getReportCards = () => `${SIS_URL}/ajax/perfil/get_reportcards`;

export const getSanctions = () => `${SIS_URL}/ajax/perfil/get_sanciones_user`;

export const getPrimaryReports = () =>
  `${SIS_URL}/ajax/perfil/get_primaryreports`;

export const resetPassword = () =>
  `${SIS_URL}/ajax/configuracion/set_reset_password`;

export const profileMenuOptions = () => `
  ${SIS_URL}/ajax/perfil/get_profile_menu
`;

export const setSuperUser = () => `
  ${SIS_URL}/ajax/perfil/set_super_user
`;

export const getRoles = () => `
  ${SIS_URL}/ajax/configuracion/get_roles
`;

export const getMyRoles = () => `
  ${SIS_URL}/ajax/configuracion/get_my_roles
`;

export const getTiers = () => `
  ${SIS_URL}/ajax/cicloLectivo/get_tiers
`;

export const getFullStructure = () => `
  ${SIS_URL}/ajax/perfil/get_full_tier_structure
`;

export const updateUserPermits = () => `
  ${SIS_URL}/ajax/perfil/update_user_permits
`;

export const getProfilePermits = () => `
  ${SIS_URL}/ajax/perfil/get_permits
`;
