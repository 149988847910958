import { Typography } from '@mui/material';

const columns = [
  {
    title: '',
    key: 'name',
    render: ({ name }) => <Typography variant="subtitle1">{name}</Typography>,
  },
];

export { columns };
