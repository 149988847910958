import { useSelector } from 'react-redux';

import router from 'src/shared/utils/router';

const useInstitutionSelector = () => {
  const institutions = useSelector(({ user }) =>
    user.institutionPeriods.map((institution) => ({
      ...institution,
      title: institution.name,
      href: router.institution.changeInstitution(institution.id),
      disabled: institution.id === user.selectedInstitution.id,
    })),
  );

  institutions[institutions.length - 1] = {
    ...institutions[institutions.length - 1],
    divider: true,
  };

  return institutions;
};

export default useInstitutionSelector;
