import update from 'immutability-helper';
import addOrUpdate from 'src/shared/utils/addOrUpdate';

const reducers = {
  setLevelPeriods(state, levelPeriods) {
    return update(state, {
      levelPeriods: { $set: levelPeriods },
      assessmentsFetched: { $set: false },
    });
  },
  setStudents(state, students) {
    return update(state, {
      students: { $set: students },
    });
  },
  setStudentAlert(state, newStudentAlert) {
    return update(state, {
      studentAlert: {
        $apply: (prevState) => ({
          ...prevState,
          ...newStudentAlert,
        }),
      },
    });
  },
  setScoreConfig(state, { config, subjectId }) {
    return update(state, {
      config: { $set: config },
      activeSubjectId: { $set: subjectId },
    });
  },
  setAssessmentScales(state, scales) {
    return update(state, {
      assessmentScales: { $set: scales },
    });
  },
  setAssessmentTypes(state, types) {
    return update(state, {
      assessmentTypes: { $set: types },
    });
  },
  setAssessments(state, assessments, wasFetched = true) {
    return update(state, {
      assessments: { $set: assessments },
      assessmentsFetched: { $set: wasFetched },
    });
  },
  setScores(state, scores) {
    return update(state, {
      scores: { $set: scores },
    });
  },
  setScoresQueue(state, scores) {
    return update(state, {
      scoresQueue: { $set: scores },
    });
  },
  setPendingApproval(state, assessments) {
    return update(state, {
      pendingApproval: { $set: assessments },
    });
  },
  setActiveFilters(state, filters) {
    const { activeFilters } = state;
    return update(state, {
      activeFilters: { $set: { ...activeFilters, ...filters } },
    });
  },
  setAverageCalculator(state, averageCalculatorData) {
    return update(state, {
      averageCalculator: { $set: averageCalculatorData },
    });
  },

  addOrUpdateFinalScores(state, newFinalScores) {
    const { finalScores } = state;
    return update(state, {
      finalScoresFetched: { $set: true },
      finalScores: { $set: addOrUpdate(finalScores ?? [], newFinalScores) },
    });
  },

  updateSingleFinalScore(state, newFinalScore) {
    return update(state, {
      finalScores: {
        $apply: (finalScores) => {
          return finalScores.map((finalScore) => {
            if (finalScore.id === newFinalScore.id) {
              return newFinalScore;
            }

            return finalScore;
          });
        },
      },
    });
  },

  updateAssessmentTypesFinalScore(state, newScores) {
    return update(state, {
      assessmentTypeFinalScores: {
        $apply: (assessmentTypeFinalScores) => {
          return assessmentTypeFinalScores.map((assessmentTypeFinalScore) => {
            const newFinalScore = newScores.find(
              ({ id }) => id === assessmentTypeFinalScore.id,
            );

            if (assessmentTypeFinalScore.id === newFinalScore?.id) {
              return {
                ...assessmentTypeFinalScore,
                numerical_value: newFinalScore.numerical_value,
                score_scale_value_id: newFinalScore.score_scale_value_id,
                scoreValue: {
                  ...newFinalScore.scoreValue,
                  numerical_value: newFinalScore.numerical_value,
                  score_scale_value_id: newFinalScore.score_scale_value_id,
                  ...(newFinalScore.score_scale_value_id == null && {
                    short_name: null,
                  }),
                },
              };
            }

            return assessmentTypeFinalScore;
          });
        },
      },
    });
  },

  setActiveAssessmentTypeByLevelPeriod(state, newPeriod) {
    return update(state, {
      activeAssessmentTypeByLevelPeriod: {
        $set: newPeriod,
      },
    });
  },

  setAssessmentTypeFinalScores(state, finalScores) {
    return update(state, {
      assessmentTypeFinalScores: { $set: finalScores },
    });
  },

  setFinalScores(state, newFinalScores, wasFetched = true) {
    return update(state, {
      finalScoresFetched: { $set: wasFetched },
      finalScores: { $set: newFinalScores },
    });
  },

  setFinalScoresQueue(state, newFinalScores) {
    return update(state, {
      finalScoresQueue: { $set: newFinalScores },
    });
  },

  setReportCard(state, reportCardData) {
    const { reportCard } = state;
    return update(state, {
      reportCard: { $set: { ...reportCard, ...reportCardData } },
    });
  },

  updateAssessmentTypeFinalScores(state, newFinalScores) {
    const { assessmentTypeFinalScores } = state;

    return update(state, {
      assessmentTypeFinalScores: {
        $set: addOrUpdate(assessmentTypeFinalScores, newFinalScores),
      },
    });
  },

  setAssessmentTypesColumns(state, assessmentTypesColumns) {
    return update(state, {
      assessmentTypesColumns: { $set: assessmentTypesColumns },
    });
  },

  resetAssessmentTypes(state) {
    return update(state, {
      activeAssessmentTypeByLevelPeriod: {
        $set: [],
      },
      assessmentTypesColumns: {
        $set: [],
      },
      assessmentTypeFinalScores: {
        $set: [],
      },
    });
  },
};

export default reducers;
