import { useGetOne } from 'src/shared/services/api/query';
import { getScheduledConfig } from 'src/shared/services/url/UrlCommunication';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';
import { useIsOnlySchoolStaff } from 'src/features/messages/hooks';

const useGetScheduleConfig = () => {
  return useGetOne(
    [QUERY_KEY.SCHEDULED_CONFIG],
    getScheduledConfig(),
    {},
    true,
    false,
    { enabled: !useIsOnlySchoolStaff() },
  );
};

export default useGetScheduleConfig;
