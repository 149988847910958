import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  MuiInputLabelFormControl: {
    position: 'relative',
    transform: 'none',
    fontWeight: 500,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
  },
  MuiInputRoot: {
    paddingLeft: 0,
    '& .MuiInputBase-input': {
      paddingLeft: theme.spacing(5),
    },
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  grid: {
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
