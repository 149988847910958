import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { INTERCOM_API_BASE, INTERCOM_KEY } from 'src/config/general';
import { isProduction } from 'src/config/constants';
import parseExternalId from 'src/shared/utils/parseExternalId';
import useUserInfo from '../../features/payment-management/hooks/useUserInfo';

const IntercomContainer = ({ children }) => {
  const {
    data: userData,
    role: roles,
    selectedInstitution,
    selectedInstitutionPeriodId,
    isWebView,
  } = useUserInfo();

  useEffect(() => {
    if (isProduction && !isWebView) {
      window.IntercomInit();

      window.Intercom('boot', {
        api_base: INTERCOM_API_BASE,
        app_id: INTERCOM_KEY,
        ...(userData
          ? {
              name: userData.name,
              last_name: userData.last_name,
              document_number: userData.document_number,
              roles: roles
                .reduce((accRoles, role) => {
                  if (!accRoles.includes(role.role_name))
                    accRoles.push(role.role_name);
                  return accRoles;
                }, [])
                .join(', '),
              user_id: userData.id,
              institution_id: selectedInstitution.id,
              institution_name: selectedInstitution.name,
              period_id: selectedInstitutionPeriodId,
              user_external_id: parseExternalId(userData.external_id),
              background_color: selectedInstitution.color,
              action_color: selectedInstitution.color,
            }
          : {}),
      });
    }
  }, [
    isWebView,
    roles,
    selectedInstitution,
    selectedInstitutionPeriodId,
    userData,
  ]);

  return <Fragment>{children}</Fragment>;
};

IntercomContainer.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
};

export default IntercomContainer;
