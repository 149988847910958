import { useGetOne } from 'src/shared/services/api/query';
import { paymentInvoiceBatchById } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from '../../constants/queryKey';

const useCheckInvoiceBatchProgress = (processId, queryParams) =>
  useGetOne(
    [QUERY_KEY.INVOICE_BATCH_PROGRESS, processId],
    paymentInvoiceBatchById(processId),
    true,
    false,
    false,
    queryParams,
  );

export default useCheckInvoiceBatchProgress;
