import { useMutation } from '@tanstack/react-query';
import { useErrorMessage } from '../common/useErrorMessage';
import Api from '../../api';
import abstractSerializer from '../serializer/abstractSerializer';

export const usePut = (
  url,
  queryParams = {},
  defaultError = true,
  customSerializer,
) => {
  const withErrorMessage = useErrorMessage();
  const { serialize } = customSerializer || abstractSerializer();

  const apiPut = (params = {}) =>
    Api.put({
      url,
      data: serialize(params),
    });

  const mutationFunction = defaultError ? withErrorMessage(apiPut) : apiPut;

  return useMutation(mutationFunction, queryParams);
};
