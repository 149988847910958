import { toLocaleCurrency } from 'src/shared/utils/currencies';
import { hardCodeInitValuesArg } from 'src/features/payment-management/forms/PaymentGenerateBillsForm/ARG/helpers';
import { AFIP_PAYMENT_AMOUNT_LIMIT } from 'src/features/payment-management/constants/paymentLimits';

const formattedDataAR = (
  familyTaxInformation,
  institutionTaxInformation,
  invoices,
  isBatch,
) => {
  const initialValues = { ...hardCodeInitValuesArg };
  let valuesFamily = {};
  let valuesInvoice = {};
  let valuesInstitution = {};
  let valuesTaxes = {};

  if (familyTaxInformation) {
    initialValues.hasResponsibleBiller = true;
    initialValues.switchReceiver = false;
    valuesFamily = {
      personTypeFamily: familyTaxInformation.person_type_id,
      documentFamily: familyTaxInformation.document,
      zipCodeFamily: familyTaxInformation.zip_code,
      nameFamily: familyTaxInformation.name,
      taxRegimeFamily: familyTaxInformation.tax_regime_id,
      addressFamily: familyTaxInformation.address,
    };
  }

  if (institutionTaxInformation && invoices.length) {
    valuesInstitution = {
      document: institutionTaxInformation.document,
      zipCode: institutionTaxInformation.zip_code,
      name: institutionTaxInformation.name,
      taxRegime: institutionTaxInformation.tax_regime_id,
      expeditionPlace: institutionTaxInformation?.zip_code,
    };

    if (!isBatch) {
      const { total_invoiced, invoiced_at } = invoices[0];
      const totalAmount = toLocaleCurrency(total_invoiced);
      const exceededLimit = total_invoiced > AFIP_PAYMENT_AMOUNT_LIMIT;

      initialValues.limitedOfMaxAmount = exceededLimit;
      if (exceededLimit) {
        initialValues.switchReceiver = false;
      }

      const dateInvoice = new Date(invoiced_at);

      valuesInvoice = {
        receiptFrom: new Date(
          dateInvoice.getFullYear(),
          dateInvoice.getMonth(),
          1,
        ),
        receiptTo: new Date(
          dateInvoice.getFullYear(),
          dateInvoice.getMonth() + 1,
          0,
        ),
        productUnitAmount: 1,
        productUnitPrice: totalAmount,
        productTotal: totalAmount,
      };

      valuesTaxes = {
        total: totalAmount,
      };
    }
  }

  if (familyTaxInformation && institutionTaxInformation && invoices.length) {
    const { tax_regime: taxRegimeIntitution } = institutionTaxInformation;
    const { tax_regime: taxRegimeFamily } = familyTaxInformation;
    if (
      taxRegimeIntitution?.name === 'Responsable Inscripto' &&
      taxRegimeFamily?.name === 'Responsable Inscripto'
    ) {
      const valueTotal = Number(invoices[0].total);
      const valueNeto = valueTotal / 1.21;
      const valueIva = valueTotal - valueNeto;

      valuesTaxes.neto = toLocaleCurrency(valueNeto);
      valuesTaxes.iva = toLocaleCurrency(valueIva);
    }
  }

  const values = {
    ...initialValues,
    ...valuesFamily,
    ...valuesInvoice,
    ...valuesInstitution,
    ...valuesTaxes,
  };

  return values;
};

export default formattedDataAR;
