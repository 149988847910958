import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';
import { usePut } from 'src/shared/services/api/query';
import { setMessagesConversationSeen } from 'src/shared/services/url/UrlCommunication';
import { unreadFilterAtom } from 'src/features/messages/atoms';

const useMarkMessageAsSeen = () => {
  const queryClient = useQueryClient();
  const unreadFilter = useAtomValue(unreadFilterAtom);

  const KEY = unreadFilter
    ? QUERY_KEY.UNREAD_CONVERSATIONS_LIST
    : QUERY_KEY.CONVERSATIONS_LIST;

  const queryKey = {
    predicate: (query) => {
      return query.queryKey[0] === KEY;
    },
  };

  const prevValue = queryClient.getQueryData(queryKey);

  const updatedConversations = (pages, message_id) => {
    return pages.map((page) => {
      return {
        ...page,
        data: page.data.map((item) => {
          const { last_message } = item;
          return last_message?.id === message_id
            ? { ...item, last_message: { ...last_message, is_new: false } }
            : item;
        }),
      };
    });
  };

  return usePut(setMessagesConversationSeen(), {
    onMutate: async ({ message_id }) => {
      // optimistic update to conversations list
      const pages = updatedConversations(prevValue.pages, message_id);
      queryClient.setQueriesData(queryKey, (oldValue) => {
        return {
          ...oldValue,
          pages,
        };
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEY.UNREAD_CONVERSATIONS]);
    },
  });
};

export default useMarkMessageAsSeen;
