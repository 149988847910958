import React from 'react';
import PropTypes from 'prop-types';
import FilesDropzone from 'src/components/FilesDropzone';

const PublicationFiles = ({ ...rest }) => {
  return <FilesDropzone hideButton multiple style={{ margin: 0 }} {...rest} />;
};

PublicationFiles.propTypes = {
  buttonId: PropTypes.string,
  setProgress: PropTypes.func,
  subFolder: PropTypes.string,
};

export default PublicationFiles;
