import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { familyImportPayment } from 'src/shared/services/url/UrlPaymentManagement';
import { useCreate } from 'src/shared/services/api/query';

const useFamilyImportPayment = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useCreate(
    familyImportPayment(),
    {
      onError: ({ message: error }) => {
        const message = error ?? '';
        enqueueSnackbar(
          t`Hubo un error al intentar enviar el archivo .csv. ${message}`,
          {
            variant: 'error',
          },
        );
      },
    },
    false,
  );
};

export default useFamilyImportPayment;
