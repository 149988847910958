import { QUERY_KEY } from 'src/features/my-account/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import { pickupByIdUrl } from 'src/shared/services/url/UrlUser';

const useUserPickUpSelectedDetail = (user) => {
  return useGetOne(
    [QUERY_KEY.USER_PICKUP, user?.id],
    pickupByIdUrl(user?.id),
    {},
    false,
    false,
    { enabled: false },
  );
};

export default useUserPickUpSelectedDetail;
