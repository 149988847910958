import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import NProgress from 'nprogress';
import { Box, CircularProgress } from '@mui/material';
import clsx from 'clsx';
import useStyles from './styles';

const LoadingScreen = ({ boxProps = {}, fullScreen }) => {
  const classes = useStyles();

  useEffect(() => {
    NProgress.configure({ showSpinner: false });
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      className={clsx({
        [classes.root]: true,
        [classes.fullScreen]: fullScreen,
      })}
      {...boxProps}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

LoadingScreen.displayName = 'LoadingScreen';

LoadingScreen.propTypes = {
  boxProps: PropTypes.object,
  fullScreen: PropTypes.bool,
};

export default memo(LoadingScreen);
