import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  IconButton,
  Link,
  Typography,
  // Grid,
  // Paper,
} from '@mui/material';
import { Trans } from '@lingui/macro';
import {
  // Calendar as CalendarIcon,
  // Eye as EyeIcon,
  // Heart as HeartIcon,
  // Monitor as MonitorIcon,
  // Send as SendIcon,
  X as CloseIcon,
} from 'react-feather';
// import { styled } from '@mui/material/styles';
// import dayjs from 'dayjs';

// import SparkleIcon from '../SparkleIcon';
import useStyles from './styles';

const WebinarContent = memo(({ onClose /* date */, link }) => {
  const classes = useStyles();
  // const Item = styled(Paper)(({ theme }) => ({
  //   display: 'flex',
  //   padding: theme.spacing(1.5),
  //   textAlign: 'center',
  //   color: theme.palette.primary.main,
  //   marginBottom: theme.spacing(2),
  // }));
  // const IconBox = styled(Box)(() => ({
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  // }));
  return (
    <Box className={classes.changesContainer} p={3} pb={4}>
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h1" className={classes.title}>
            {/* <SparkleIcon className={classes.sparkle} /> */}
            <Trans>¡Novedades!</Trans>
            {/* <SparkleIcon className={classes.lastSparkle} /> */}
          </Typography>
          <IconButton className={classes.closeDialog} onClick={onClose}>
            <CloseIcon size={16} />
          </IconButton>
        </Box>
        {/* <Typography className={classes.newBadge}>
          <Trans>¡Vuelvete experto y potencia la enseñanza online!</Trans>
        </Typography> */}
        <Box mt={3}>
          {/* <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={4}>
              <IconBox>
                <Item elevation={16}>
                  <MonitorIcon size={48} />
                </Item>
                <Box component="p" sx={{ textAlign: 'center' }}>
                  Completa el formulario de contacto
                </Box>
              </IconBox>
            </Grid>
            <Grid item xs={4}>
              <IconBox>
                <Item elevation={16}>
                  <SendIcon size={48} />
                </Item>
                <Box component="p" sx={{ textAlign: 'center' }}>
                  La institución referida <b>adquiere fidu</b>
                </Box>
              </IconBox>
            </Grid>
            <Grid item xs={4}>
              <IconBox>
                <Item elevation={16}>
                  <HeartIcon size={48} />
                </Item>
                <Box component="p" sx={{ textAlign: 'center' }}>
                  ¡Ambos disfrutan de un <b>increible beneficio</b>!
                </Box>
              </IconBox>
            </Grid>
          </Grid> */}
          <Typography variant="body" component="p" mb={2}>
            <Trans>
              Próximamente podrás disfrutar del{' '}
              <b>nuevo Módulo de Calificaciones</b> de fidu co-creado con
              educadores de nuestra comunidad.
            </Trans>
          </Typography>
          <Typography variant="body" component="p" mb={2}>
            <Trans>
              Haz clic en el siguiente botón para obtener más información.
            </Trans>
          </Typography>
          {/* <Typography
            variant="body2"
            component="p"
            className={classes.bulletPoint}
          >
            <Trans>
              Participa del encuentro virtual donde aprenderás cómo utilizar la
              nueva sección.
            </Trans>
          </Typography> */}
          {/* <Box mt={4} mb={4}>
            <Typography className={classes.webinarDate}>
              <CalendarIcon className={classes.webinarDateIcon} />
              {dayjs(date).format('dddd DD/MM')}
            </Typography>
          </Box> */}
          <Box mt={3}>
            <Link href={link} target="_blank" className={classes.linkButton}>
              <Button variant="contained" color="primary" onClick={onClose}>
                <Trans>¡Conoce más!</Trans>
              </Button>
            </Link>
          </Box>
          {/* <Box mb={-2} mt={2} display="flex" alignItems="center">
            <EyeIcon size={16} />
            <Typography className={classes.webinarDisclaimer}>
              <Trans>
                Esta invitación solo es visible para perfiles de colegio
              </Trans>
              <Trans>
                Esta información solo es visible para perfiles de colegio
              </Trans>
            </Typography>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
});

WebinarContent.displayName = 'WebinarContent';

WebinarContent.propTypes = {
  onClose: PropTypes.func,
  date: PropTypes.object,
  link: PropTypes.string,
};

export default WebinarContent;
