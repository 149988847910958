import { atom, getDefaultStore } from 'jotai';
import { atomWithDefault } from 'jotai/utils';

const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

export const stepWizardDefaultAtom = atom({
  current: 0,
  limit: 0,
  locked: false,
});

export const stepWizardInfoAtom = atom({
  decStep: () => {},
  locked: false,
  hasPrev: false,
  hide: false,
});

const stepWizardAtom = atomWithDefault((get) => get(stepWizardDefaultAtom));

// read only atoms

export const stepWizardCurrentStep = atom((get) => {
  const { current } = get(stepWizardAtom);
  return current + 1;
});

export const stepWizardHasPrev = atom((get) => {
  const { current } = get(stepWizardAtom);
  return Boolean(current > 0);
});

export const stepWizardHasNext = atom((get) => {
  const { current, limit } = get(stepWizardAtom);
  return Boolean(current < limit);
});

export const stepWizardIsLocked = atom((get) => {
  const { locked } = get(stepWizardAtom);
  return Boolean(locked);
});

// write only atoms

export const stepWizardInfoUpdateAtom = atom(null, (get, set) => {
  getDefaultStore().set(stepWizardInfoAtom, {
    ...getDefaultStore().get(stepWizardInfoAtom),
    decStep: () => set(stepWizardDecAtom),
    hasPrev: get(stepWizardHasPrev),
  });
});

export const stepWizardSetAtom = atom(
  null,
  (get, set, currentStep, reset = false) => {
    const { current, limit, locked } = get(stepWizardAtom);

    if (!locked && current !== currentStep) {
      set(stepWizardAtom, {
        limit,
        current: clamp(currentStep, 0, limit),
        prev: reset ? undefined : current,
      });
    }
    set(stepWizardInfoUpdateAtom);
  },
);

export const stepWizardIncAtom = atom(null, (get, set) => {
  const { current } = get(stepWizardAtom);
  set(stepWizardSetAtom, current + 1);
});

export const stepWizardDecAtom = atom(null, (get, set) => {
  const { current } = get(stepWizardAtom);
  set(stepWizardSetAtom, current - 1);
});

export const stepWizardLockAtom = atom(null, (get, set) => {
  set(stepWizardAtom, (prev) => ({
    ...prev,
    locked: true,
  }));
  getDefaultStore().set(stepWizardInfoAtom, {
    ...getDefaultStore().get(stepWizardInfoAtom),
    locked: true,
  });
});

export const stepWizardUnlockAtom = atom(null, (get, set) => {
  set(stepWizardAtom, (prev) => ({
    ...prev,
    locked: false,
  }));
  getDefaultStore().set(stepWizardInfoAtom, {
    ...getDefaultStore().get(stepWizardInfoAtom),
    locked: false,
  });
});

export const stepWizardHideAtom = atom(null, (get, set) => {
  getDefaultStore().set(stepWizardInfoAtom, {
    ...getDefaultStore().get(stepWizardInfoAtom),
    hide: true,
  });
});

export const stepWizardShowAtom = atom(null, (get, set) => {
  getDefaultStore().set(stepWizardInfoAtom, {
    ...getDefaultStore().get(stepWizardInfoAtom),
    hide: false,
  });
});
export default stepWizardAtom;
