import makeStyles from '@mui/styles/makeStyles';
import { mobile } from 'src/shared/utils/breakpoints';

const useStyles = makeStyles((theme) => ({
  filterGroup: {
    ...theme.typography.body2,
    fontWeight: 500,
  },
  normalItem: {
    paddingLeft: theme.spacing(3),
  },
  menuItem: {
    whiteSpace: 'normal',
    padding: '0 !important',
    paddingRight: `${theme.spacing(2)} !important`,

    '& .Mui-checked': {
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
  publicationSelectActive: {
    maxWidth: 170,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '& svg': {
      color: theme.palette.common.white,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    [mobile]: {
      maxWidth: 95,
    },
  },
  publicationSelectRoot: {
    padding: `${theme.spacing(1)} 10px !important`,
  },
  menuCount: {
    ...theme.typography.body2,
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    fontWeight: 500,
  },
  hiddenInput: {
    visibility: 'hidden',
    width: 0,
  },
  subItem: {
    paddingLeft: `${theme.spacing(2)} !important`,
  },
}));

export default useStyles;
