import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from 'src/components/UserAvatar';
import { Box, Typography } from '@mui/material';
import useStyles from './styles';

const UserCell = ({ user }) => {
  const classes = useStyles();
  const { name, last_name } = user;
  const fullName = `${last_name}, ${name}`;
  return (
    <Box ml={-1}>
      <UserAvatar
        size="medium"
        user={user}
        display="flex"
        alignItems="center"
        isClickable
      >
        <Typography className={classes.userName}>{fullName}</Typography>
      </UserAvatar>
    </Box>
  );
};

UserCell.propTypes = {
  user: PropTypes.object,
};

export default UserCell;
