const COUNTRY_CODE = {
  ARGENTINA: 'ar',
  MEXICO: 'mx',
  COLOMBIA: 'co',
};

const COUNTRY_OPTIONS = [
  { id: 346, name: 'Argentina', code: COUNTRY_CODE.ARGENTINA },
  { id: 365, name: 'Colombia', code: COUNTRY_CODE.COLOMBIA },
  { id: 286, name: 'Mexico', code: COUNTRY_CODE.MEXICO },
];

export { COUNTRY_CODE, COUNTRY_OPTIONS };
