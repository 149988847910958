import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  groupLabel: {
    lineHeight: '18px',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
}));

export default useStyles;
