import { useDispatch, useSelector } from 'react-redux';
import { useCreate } from 'src/shared/services/api/query';
import { refreshToken } from 'src/shared/services/url/UrlUser';

const useRefreshToken = () => {
  const dispatch = useDispatch();
  const { setApiKey, setRefreshToken } = useSelector(() => ({
    setApiKey: dispatch.user.setApiKey,
    setRefreshToken: dispatch.user.setRefreshToken,
  }));

  return useCreate(refreshToken(), {
    onSuccess: async ({ data: { token, refresh_token } }) => {
      await setApiKey(token);
      await setRefreshToken(refresh_token);
    },
  });
};

export default useRefreshToken;
