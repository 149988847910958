import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    fontSize: theme.spacing(5),
  },
  trashIcon: {
    color: theme.palette.danger.main,
  },
}));

export default useStyles;
