const PICKUPS_DIALOGS = {
  NEW_PICKUP_DIALOG: 'NewPickupDialog',
  PICKUP_DETAILS_DIALOG: 'PickupDetailsDialog',
  PICKUP_CONFIRM_DELETE_DIALOG: 'PickupConfirmDeleteDialog',
  PICKUP_STUDENTS_DIALOG: 'PickupStudentsDialog',
  PICKUP_AUTHORIZED_DIALOG: 'PickupAuthorizedDialog',
  PICKUP_VIEW_AUTHORIZED_DIALOG: 'PickupViewAuthorizedDialog',
  PICKUP_EDIT_CATEGORY_DIALOG: 'PickupEditCategoryDialog',
  PICKUP_DELETE_CATEGORY_DIALOG: 'PickupDeleteCategoryDialog',
  PICKUP_EDIT_SUBCATEGORY_DIALOG: 'PickupEditSubCategoryDialog',
  PICKUP_DELETE_SUBCATEGORY_DIALOG: 'PickupDeleteSubCategoryDialog',
  PICKUP_EDIT_OUTPUT_RANGE_DIALOG: 'PickupEditOutputRangeDialog',
  PICKUP_DELETE_OUTPUT_RANGE_DIALOG: 'PickupDeleteOutputRangeDialog',
  PICKUP_EDIT_REASON_DIALOG: 'PickupEditReasonDialog',
  PICKUP_DELETE_REASON_DIALOG: 'PickupDeleteReasonDialog',
};

export default PICKUPS_DIALOGS;
