import { debtReminder } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import { useGetList } from 'src/shared/services/api/query';
import useUserInfo from '../../useUserInfo';

const useDebtReminders = (params = {}) => {
  const { selectedInstitution } = useUserInfo();

  return useGetList([QUERY_KEY.PAYMENT_DEBT_REMINDER, params], debtReminder(), {
    sort: 'id',
    institution_id: selectedInstitution.id,
    ...params,
  });
};

export default useDebtReminders;
