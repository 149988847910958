import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  badge: {
    top: 3,
    right: 3,
    border: `1px solid ${theme.palette.background.paper}`,
  },
}));

export default useStyles;
