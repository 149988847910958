import { lighten } from '@mui/material';

import palette from './palette';
import baseTypography from './typography';
import shadows from '@mui/material/styles/shadows';

// TODO: Cada override/component deberia estar separado por archivo
// Pero por temas de tiempo lo dejamos todo en un solo archivo
export default {
  // CSS BASELINE -----------------
  MuiCssBaseline: {
    styleOverrides: {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        webkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        height: '100%',
        width: '100%',
      },
      svg: {
        color: 'inherit',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },

      // 3rd parties -----------
      // Zendesk
      '#launcher': {
        zIndex: '1 !important',
      },

      // ReactModal
      '.ReactModal__Overlay': {
        zIndex: '1300 !important',
      },

      // TinyMCE
      '.tox .tox-dialog': {
        fontFamily: 'Manrope !important',
        borderRadius: '4px !important',
        border: 'none !important',
      },

      '.tox .tox-dialog-wrap__backdrop': {
        backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
      },

      '.tox .tox-button': {
        fontFamily: 'Manrope !important',
        transition: '.3s',
      },

      '.tox-dialog__footer .tox-button': {
        backgroundColor: `${palette.primary.main} !important`,
        borderColor: `${palette.primary.main} !important`,
        fontWeight: '400 !important',
        fontSize: 14,
      },

      '.tox-dialog__body-nav-item--active': {
        color: `${palette.primary.main} !important`,
        borderColor: `${palette.primary.main} !important`,
      },

      '.tox-button.tox-button--secondary': {
        backgroundColor: 'transparent !important',
        borderColor: 'transparent !important',
        color: `${palette.primary.main} !important`,
        fontWeight: '400 !important',
        fontSize: 14,
        '&:hover': {
          backgroundColor: 'rgba(67, 160, 71, 0.04) !important',
        },
      },

      '.tox .tox-button--secondary:active:not(:disabled)': {
        backgroundColor: '#f1f2f1 !important',
      },

      '.tox .tox-listboxfield .tox-listbox--select:focus, .tox .tox-textarea:focus, .tox .tox-textfield:focus':
        {
          border: `2px solid ${palette.primary.main} !important`,
        },
      '.tox-form__group.error input:focus, .tox-form__group.error input': {
        borderColor: 'red !important',
      },

      '.tox-form__group': {
        marginBottom: '16px !important',
        '&:last-child': {
          marginBottom: '0 !important',
        },
      },
      '.tox .tox-button--naked:hover:not(:disabled)': {
        backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
        border: '1px solid transparent !important',
      },
      '.tox .tox-tbtn:focus:not(.tox-tbtn--disabled) svg': {
        fill: 'transparent !important',
      },
      // REMOVE FLAGS TAB
      '.tox-dialog__body-nav .tox-tab:last-child': {
        display: 'none',
      },

      // Notistack
      '#client-snackbar': {
        '& svg': {
          color: palette.common.white,
        },
      },

      // Fullcalendar
      // TODO: Move overrides to calendar view
      '.fc-scrollgrid': {
        borderTop: '0 !important',
        borderLeft: '0 !important',

        '& .fc-col-header-cell': {
          ...baseTypography.subheading,
          color: palette.grey[500],
          fontFamily: baseTypography.fontFamily,
          borderRight: '1px solid transparent',
        },

        '& .fc-col-header-cell-cushion': {
          paddingBottom: '8px !important',
        },

        '& .fc-scrollgrid-section-header': {
          border: '1px solid transparent !important',
          '& td': {
            border: '1px solid transparent !important',
          },
        },

        '& .fc-scrollgrid-sync-table': {
          borderLeft: '1px solid !important',
        },

        '& .fc-day-today': {
          backgroundColor: `${palette.grey[50]} !important`,
        },

        '& .fc-daygrid-event': {
          cursor: 'pointer !important',
        },
      },

      // LIGHTBOX
      '.ril__toolbar': {
        backgroundColor: 'rgba(0, 0, 0, 0.75) !important',
      },
      '.ril__toolbarRightSide': {
        '& button': {
          backgroundSize: 14,
          opacity: 1,
          '&:hover': {
            '& svg': {
              color: '#fff !important',
            },
          },
        },
        '& svg': {
          color: '#fff',
        },
      },

      '.fc-popover': {
        // default theme dialog zIndex - 1
        zIndex: '1299 !important',
      },
      '.Mui-disabled': {
        pointerEvents: 'none',
      },
      // Aplica estilos de scrollbar a todos los componentes que usan overflowY: 'auto'
      '*::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '8px',
      },
      '*::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      },
      '*::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
    },
  },
  // BUTTONS ------------------
  MuiButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        lineHeight: 'unset',
        borderRadius: theme.shape.borderRadius * 5,
      }),
      text: ({ theme }) => ({
        padding: theme.spacing(1),
      }),
      label: ({ theme }) => ({
        ...baseTypography.h4,
        lineHeight: theme.spacing(2),
        '& svg': {
          color: 'inherit',
        },
      }),
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: lighten(palette.primary.main, 0.4),
        },
      },
      outlinedPrimary: {
        '&:hover': {
          borderColor: lighten(palette.primary.main, 0.4),
        },
        '&.Mui-disabled': {
          borderColor: palette.grey[100],
          color: palette.grey[500],
        },
      },
    },
  },

  MuiIconButton: {
    styleOverrides: {
      root: {
        borderRadius: 4,

        '&:hover': {
          '& svg': {
            color: `${palette.primary.main}`,
          },
        },
      },
    },
  },

  // SURFACES ---------------
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: shadows[0],
        backgroundColor: palette.background.paper,
        borderBottom: `1px solid ${palette.divider}`,
      },
    },
  },

  // INPUTS -----------------
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: baseTypography.fontSize,

        '&.Mui-disabled': {
          '& svg': {
            color: 'inherit',
          },
        },
      },

      input: {
        padding: '8px 12px',

        '&::placeholder': {
          opacity: 1,
          color: palette.grey[500],
        },
      },

      fieldset: {
        borderColor: palette.divider,
      },
    },
  },

  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        padding: '8px 12px',
      },

      fieldset: {
        '&.Mui-disabled': {
          notchedOutline: {
            borderColor: palette.action.disabled,
          },
        },

        notchedOutline: {
          borderColor: palette.divider,
        },
      },
    },
  },

  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...baseTypography.h4,
        lineHeight: '20px',
        color: palette.common.black,
        marginBottom: theme.spacing(1),
      }),
    },
  },

  MuiSwitch: {
    styleOverrides: {
      colorSecondary: {
        color: `${palette.white} !important`,
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: '#000',
          opacity: '0.12 !important',
        },
      },
      colorPrimary: {
        color: `${palette.white} !important`,
      },
      track: {
        backgroundColor: palette.grey[600],
      },
    },
  },

  MuiFormHelperText: {
    styleOverrides: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
      },
    },
  },

  // FEEDBACK ---------------
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
  },

  MuiAlert: {
    styleOverrides: {
      icon: {
        color: 'inherit',
      },
    },
    variants: [
      {
        props: { variant: 'tip' },
        style: {
          borderRadius: 8,
          color: palette.text.secondary,
          border: `1px solid ${palette.divider}`,
          marginTop: 15,
          marginBottom: 10,
        },
      },
    ],
  },

  MuiCircularProgress: {
    styleOverrides: {
      svg: {
        color: 'inherit',
      },
    },
  },

  MuiSkeleton: {
    styleOverrides: {
      root: {
        backgroundColor: palette.grey[100],
      },
    },
  },

  // LISTS ------------------
  MuiListItem: {
    styleOverrides: {
      root: {
        padding: '12px 16px !important',
        '& svg': {
          width: 16,
          height: 16,
        },
      },
    },
  },

  MuiListItemIcon: {
    styleOverrides: {
      root: ({ theme }) => ({
        minWidth: theme.spacing(4),
        '& svg': {
          width: 16,
          height: 16,
        },
      }),
    },
  },

  // DATA DISPLAY -----------
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },

  MuiCardHeader: {
    styleOverrides: {
      title: {
        fontWeight: baseTypography.h2.fontWeight,
        fontSize: baseTypography.h2.fontSize,
      },

      action: {
        margin: 0,
      },
    },
  },

  MuiTableRow: {
    styleOverrides: {
      root: {
        '&.MuiTableRow-hover': {
          '&:hover': {
            backgroundColor: `${palette.grey[50]} !important`,
          },
        },
      },
    },
  },

  MuiTablePagination: {
    styleOverrides: {
      root: {
        fontSize: baseTypography.fontSize,
      },
      caption: {
        fontSize: baseTypography.fontSize,
      },
    },
  },

  MuiTableCell: {
    styleOverrides: {
      root: {
        borderColor: palette.divider,
      },
      head: {
        backgroundColor: palette.grey[100],
        fontSize: baseTypography.body1.fontSize,
        fontWeight: baseTypography.body1.fontWeight,
      },
    },
  },

  MuiDataGrid: {
    styleOverrides: {
      withBorderColor: {
        borderColor: palette.divider,
      },

      columnHeader: {
        backgroundColor: palette.grey[100],
      },

      columnHeaderTitle: {
        fontSize: baseTypography.fontSize,
        fontWeight: baseTypography.fontWeightBold,
      },
    },
  },

  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontSize: baseTypography.fontSize,
      }),
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontSize: baseTypography.fontSize,
      },
    },
  },

  // TABS -----------
  MuiTab: {
    styleOverrides: {
      wrapper: {
        '& svg': {
          color: palette.white,
        },
      },
      root: {
        padding: '11px 21px',
        '@media (min-width: 600px)': {
          minWidth: 95,
        },
      },
      textColorPrimary: {
        color: palette.text.primary,
      },
    },
  },

  // DIALOG ---------
  MuiDialog: {
    styleOverrides: {
      root: {
        '& .MuiDialog-paper:not(.MuiDialog-paperFullScreen)': {
          borderRadius: 24,
        },
      },
    },
  },

  MuiDialogTitle: {
    styleOverrides: {
      root: {
        '& .MuiTypography-root': {
          ...baseTypography.h2,
        },
        '& button:not(#step-back-button)': {
          borderRadius: 50,
          border: '1px solid',
        },
      },
    },
  },

  // STEPPER --------
  MuiStepIcon: {
    styleOverrides: {
      root: {
        width: 32,
        height: 32,
        color: palette.text.disabled,

        '&.Mui-disabled': {
          color: palette.text.disabled,
        },
      },
    },
  },

  MuiStepLabel: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: '#787878',
        },
      },

      labelContainer: {
        fontWeight: 500,
      },
    },
  },

  MuiStepConnector: {
    styleOverrides: {
      root: {
        opacity: 0,
      },
    },
  },

  //CARD
  MuiCard: {
    variants: [
      {
        props: { variant: 'rounded' },
        style: ({ theme }) => ({
          borderRadius: theme.spacing(1.5),
          boxShadow: '0px 0.98px 2.93px 0.98px rgba(0, 0, 0, 0.15)',
        }),
      },
      {
        props: { variant: 'rounded-without-shadow' },
        style: ({ theme }) => ({
          borderRadius: theme.spacing(1.5),
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: palette.grey[300],
        }),
      },
    ],
  },
};
