import dayjs from 'dayjs';
import { ROLE_LIST } from 'src/config/general';
import { parseEventToFormValues } from 'src/features/calendar/helpers';

const PUBLISH_ALLOWED_ROLES = [
  ROLE_LIST.ADMIN,
  ROLE_LIST.EXECUTIVE,
  ROLE_LIST.PRECEPTOR,
];

const DEFAULT_CONFIG_VALUES = {
  isAllDay: false,
  startDate: dayjs(),
  endDate: dayjs(),
  withAssistance: false,
  withNotifications: false,
  requireConfirmation: false,
  enableEvent: false,
  closeReplies: false,
  postComments: false,
  disableNotification: false,
  hidePostComments: false,
  closePostComments: false,
  enableRequireConfirmation: true,
  touched: false,
  guestType: 'generalGuests',
};

const getInitialPostConfigValues = (post) => ({
  ...DEFAULT_CONFIG_VALUES,
  ...parseEventToFormValues(post.event),
  startDate: dayjs(post.event?.start_datetime),
  startDateHour: dayjs(post.event?.start_datetime),
  endDate: dayjs(post.event?.start_datetime),
  endDateHour: dayjs(post.event?.start_datetime),
  enableEvent: Boolean(post.event),
  closeReplies: post?.close_replies,
  postComments: post?.enable_replies,
  disableNotification: !post?.enable_notification,
  hidePostComments: !post?.enable_replies && post?.close_replies,
  closePostComments: post?.enable_replies && post?.close_replies,
  enableRequireConfirmation: !post?.subject_id && !post?.group_id,
});

export {
  PUBLISH_ALLOWED_ROLES,
  DEFAULT_CONFIG_VALUES,
  getInitialPostConfigValues,
};
