import { useAtomValue } from 'jotai';

import { filterFamily } from 'src/shared/atoms';

const useFilterValue = (name) => {
  const filterAtom = filterFamily({ name });

  const { searchTerm } = useAtomValue(filterAtom);

  return searchTerm;
};

export default useFilterValue;
