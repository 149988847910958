import { useCallback, useContext } from 'react';
import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import api from 'src/shared/services/api/api';
import { uploadGoogleDriveImageUrl } from 'src/shared/services/url/UrlCommunication';
import { FILE_TYPES, getFileType } from 'src/shared/utils/fileHelper';
import { FilesDropzoneContext } from 'src/shared/context/FileDropzoneContext';
import useTinyMCEEditor from 'src/shared/hooks/useTinyMCEEditor';
import { CLOUD_STORAGE_TYPES } from './helpers';

const useCloudStorage = (dropzoneId, type) => {
  const { closeSnackbar } = useSnackbar();
  const { setPostFiles } = useContext(FilesDropzoneContext);
  const { handleUpdateEditorFiles } = useTinyMCEEditor(dropzoneId);

  const uploadImageToInternalStorage = useCallback(
    (fileId, extension, url) =>
      api.post({
        url: uploadGoogleDriveImageUrl(),
        data: {
          id: fileId,
          extension,
          type,
          url,
        },
      }),
    [type],
  );

  const handleImageUpload = useCallback(
    async (files) => {
      if (!files.length) return [];

      try {
        const driveFiles = files.map((file) => {
          const extension = file.name.split('.').pop() || 'jpg';
          const isOneDrive = type === CLOUD_STORAGE_TYPES.ONE_DRIVE.id;
          const fileUrl = isOneDrive ? file.preview : undefined;

          return uploadImageToInternalStorage(
            file.id,
            `.${extension}`,
            fileUrl,
          );
        });

        const result = await Promise.all(driveFiles);

        if (result.status === 'rejected') {
          console.error(t`Hubo un error al intentar cargar los archivos.`);
        }

        return result.map(({ data }) => data);
      } catch (error) {
        throw new Error(error.message);
      }
    },
    [type, uploadImageToInternalStorage],
  );

  const handleParseFiles = (files) =>
    files.map(
      ({
        id,
        name: original_file_name,
        mimeType: mime_type,
        url: storage_file_name,
        preview,
      }) => {
        const parsedFile = {
          id,
          storage_file_name,
          original_file_name,
          mime_type,
          link: storage_file_name,
          preview,
          type_id: FILE_TYPES.drive,
          completed: true,
        };

        if (getFileType(mime_type) === 'picture') {
          const extension = original_file_name.split('.').pop() || 'jpg'; // JPG DEFAULT POR LAS DUDAS
          parsedFile.type_id = FILE_TYPES.default;
          parsedFile.storage_file_name = `${id}.${extension}`;
        }

        return parsedFile;
      },
    );

  const handleExtractFile = useCallback(
    (files) => {
      switch (type) {
        case CLOUD_STORAGE_TYPES.ONE_DRIVE.id:
          return files.value.map((file) => ({
            id: file.id,
            name: file.name,
            mimeType: file.file.mimeType,
            preview: file['@microsoft.graph.downloadUrl'],
            url: file['permissions'][0]['link']['webUrl'],
          }));
        default:
          return files;
      }
    },
    [type],
  );

  const handleSelectedCloudFiles = useCallback(
    async (files) => {
      const parsedCloudFiles = handleExtractFile(files);
      if (parsedCloudFiles.length) {
        const cloudImages = parsedCloudFiles.filter((file) => {
          return getFileType(file.mimeType) === 'picture';
        });

        const restFiles = parsedCloudFiles.filter(
          (file) => getFileType(file.mimeType) !== 'picture',
        );

        const storedImages = await handleImageUpload(cloudImages);

        const images = storedImages.map((image) => {
          const rawImage = cloudImages.find(
            (localImage) => localImage.id === image.drive_id,
          );

          if (rawImage) {
            const googleDrivePreview = `${CLOUD_STORAGE_TYPES.GOOGLE_DRIVE.prefix}?id=${image.drive_id}`;
            const oneDrivePreview = rawImage.preview;
            const preview =
              type === CLOUD_STORAGE_TYPES.GOOGLE_DRIVE.id
                ? googleDrivePreview
                : oneDrivePreview;

            return {
              id: image.drive_id,
              mimeType: rawImage.mimeType,
              name: rawImage.name,
              preview,
              url: image.storage_file_name,
            };
          }

          return image;
        });
        const parsedFiles = handleParseFiles([...images, ...restFiles]);
        setPostFiles((oldState) => [...oldState, ...parsedFiles]);
        handleUpdateEditorFiles(parsedFiles.length, false);
        closeSnackbar();
      }
    },
    [
      closeSnackbar,
      handleExtractFile,
      handleImageUpload,
      handleUpdateEditorFiles,
      setPostFiles,
      type,
    ],
  );

  return { handleSelectedCloudFiles };
};

export default useCloudStorage;
