import makeStyles from '@mui/styles/makeStyles';
import { PAPER_CARD_MAX_WIDTH } from 'src/config/constants';
import { cardHeaderClasses } from '@mui/material/CardHeader';
import { cardContentClasses } from '@mui/material/CardContent';
import { gridClasses } from '@mui/material/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: PAPER_CARD_MAX_WIDTH,
    position: 'relative',

    [`& .${cardHeaderClasses.root}`]: {
      transition: `background-color ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`,
    },

    [`& .${cardContentClasses.root}`]: {
      transition: `background-color ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`,
    },

    [`& .${cardContentClasses.root} + * > .${gridClasses.container}`]: {
      transition: `background-color ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`,
    },
  },

  foreground: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, .75)',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 1,

    '& > *': {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

export default useStyles;
