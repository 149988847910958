import { QUERY_KEY } from 'src/features/my-account/constants/queryKey';
import { useGetOne } from 'src/shared/services/api/query';
import { pickupAuthorizedUrl } from 'src/shared/services/url/UrlUser';

const useUserPickUp = ({ enabled = true, userId }) => {
  return useGetOne(
    [QUERY_KEY.USER_PICKUP, userId],
    pickupAuthorizedUrl(),
    {
      recurrent: 1,
      user_id: userId,
    },
    true,
    false,
    { enabled },
  );
};

export default useUserPickUp;
