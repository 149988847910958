import { dateIsBetween } from 'src/shared/utils/dates';
import { t } from '@lingui/macro';

const DEFAULT_LEGACY_CACHED_DATA = {
  fetched: false,
  asignaturas: {
    pendientes: [],
    titulo: t`Asignaturas Pendientes`,
    mostrar: true,
  },
  asistencias: {
    inasistencias: {},
    mostrar: true,
  },
  average: false,
  comentarios: {},
  conceptos: {
    aptitudes: [
      {
        title: t`Esfuerzo`,
        style: { width: '60px', height: '20px' },
        notas: {},
      },
      {
        title: t`Respeto por las reglas`,
        style: { width: '80px', height: '40px' },
        notas: {},
      },
      {
        title: t`Relación con los pares`,
        style: { width: '85px', height: '40px' },
        notas: {},
      },
    ],
    titulo: t`Conceptos`,
    mostrar: true,
  },
  firmas: [],
  materias: [],
  nombres: {},
  observaciones: [],
  vaAboletin: [],
};

const DEFAULT_REPORT_CARD_STATE = {
  legacyCachedData: DEFAULT_LEGACY_CACHED_DATA,
  hasAssessmentTypes: false,
  extracurricularsDialogOpen: false,
  conceptsDialogOpen: false,
  levelScoreConfig: null,
  levelScoreScales: null,
  subjectsScoreConfig: null,
  subjectsScoreScales: null,
  threshold: 7,
  activeFilters: {
    division: null,
    levelPeriod: null,
  },
  activeEditingColumn: null,
  isEditingColumn: false,
  showOnReportCard: {
    average: false,
    attendances: false,
    comments: false,
    concepts: false,
    pendingSubjects: false,
    addAssessmentTypes: false,
  },
  previewOptions: {
    isOpen: false,
    addAssessmentTypes: false,
    sendProfiles: false,
    multiPage: false,
  },
  previewDialog: {
    open: false,
    preview: null,
    generated: false,
  },
  gridSubjects: [],
  subjects: [],
  extracurriculars: [],
  students: [],
  extracurricularsStudents: [],
  grades: [],
  attendances: [],
  comments: [],
  signatures: [],
  concepts: {
    title: '',
    list: [],
  },
  pendingSubjects: [],
};

const findLevelPeriodByDate = (date, periods) =>
  periods.find((levelPeriod) =>
    dateIsBetween(
      date,
      levelPeriod.end_date,
      levelPeriod.start_date,
      'day',
      '[]',
    ),
  );

export {
  findLevelPeriodByDate,
  DEFAULT_LEGACY_CACHED_DATA,
  DEFAULT_REPORT_CARD_STATE,
};
