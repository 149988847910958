import { useCallback, useMemo, useState } from 'react';
import { useAtom } from 'jotai';

import {
  useStudentAttendance,
  useStudentAttendanceTotal,
} from 'src/features/my-account/hooks';
import { divisionSelectorAtom } from 'src/shared/atoms';

const useStudentAttendanceInfo = (studentInfo) => {
  const [selectedPeriod, setSelectedPeriod] = useState({});
  const [selectedDivision, setSelectedDivision] = useAtom(divisionSelectorAtom);

  const { data: attendances, isLoading: isLoadingAttendances } =
    useStudentAttendance({
      user_id: studentInfo?.id,
      institution_period_id: selectedPeriod?.id,
      division_id: selectedDivision?.divisionId,
    });

  const { data: attendanceTotal, isLoading: isLoadingAttendancesTotal } =
    useStudentAttendanceTotal({
      user_id: studentInfo?.id,
      institution_period_id: selectedPeriod?.id,
      division_id: selectedDivision?.divisionId,
    });

  const handleChangePeriod = useCallback(
    (period) => {
      setSelectedPeriod(period);
    },
    [setSelectedPeriod],
  );

  const handleChangeDivision = useCallback(
    (division) => {
      setSelectedDivision(division);
    },
    [setSelectedDivision],
  );

  const attendanceTotalCount = useMemo(
    () =>
      attendanceTotal?.reduce(
        (previusValue, currentValue) => {
          return {
            total: previusValue.total + currentValue.total,
            justified: previusValue.justified + currentValue.total_justified,
          };
        },
        { total: 0, justified: 0 },
      ),
    [attendanceTotal],
  );

  const studentDivisions = useMemo(
    () =>
      studentInfo.course.map(({ id, name, division, level_id }) => ({
        courseId: id,
        courseName: name,
        divisionId: division.id,
        divisionName: `${name} ${division.name}`,
        levelId: level_id,
      })),

    [studentInfo.course],
  );

  return {
    attendances,
    isLoadingAttendances,
    attendanceTotal,
    isLoadingAttendancesTotal,
    attendanceTotalCount,
    studentDivisions,
    handleChangePeriod,
    handleChangeDivision,
  };
};

export default useStudentAttendanceInfo;
