import { en as pluralEn, es as pluralEs, pt as pluralPt } from 'make-plural';

import { COUNTRY_LOCALES, LANGUAGES_PREFIXES } from './localizationConfig';

import messagesLoader from 'src/shared/utils/locales/messagesLoader';

export const DEFAULT_LOCALE = 'es-ar';

const loadMessages = async (locale) => {
  const messagesLoaderData = messagesLoader();
  const { messages } = Object.keys(messagesLoaderData).includes(locale)
    ? await messagesLoaderData[locale]
    : await messagesLoaderData[DEFAULT_LOCALE];
  return messages;
};

export const getLocale = (userLocale, partIndex = 1) => {
  const locale = userLocale || DEFAULT_LOCALE;
  return locale?.includes('-') ? locale.split('-')[partIndex] : userLocale;
};

export const getCountryLocale = async (userLocale = DEFAULT_LOCALE) => {
  const lowerCaseLocale = userLocale?.toLowerCase();
  const locale = getLocale(lowerCaseLocale);
  const localeConfig = {
    name: LANGUAGES_PREFIXES.SPANISH,
    time: LANGUAGES_PREFIXES.SPANISH,
    texts: await loadMessages(lowerCaseLocale),
    plurals: pluralEs,
  };
  if (locale === COUNTRY_LOCALES.BRAZIL.code) {
    localeConfig.name = COUNTRY_LOCALES.BRAZIL.code;
    localeConfig.time = `${COUNTRY_LOCALES.BRAZIL.lang}-${COUNTRY_LOCALES.BRAZIL.code}`; // 'pt-br';
    localeConfig.plurals = pluralPt;
  } else if (locale === COUNTRY_LOCALES.US.code) {
    localeConfig.name = COUNTRY_LOCALES.US.code;
    localeConfig.time = COUNTRY_LOCALES.US.code;
    localeConfig.plurals = pluralEn;
  }

  return localeConfig;
};
