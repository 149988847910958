import React from 'react';
import { Box, Drawer, List, ListItemButton, ListItemIcon } from '@mui/material';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

import { RAIL_NAV_WIDTH } from 'src/config/constants';
import Link from 'src/components/Link';
import { staggerListContainer } from '../../animations';
import { isItemSelected } from '../../utils';
import measureUserAccess from 'src/shared/utils/measureUserAccess';

const NavigationRail = ({ options, onClickOption, selected }) => {
  const location = useLocation();
  const userIs = useSelector(({ user }) => user.userIs);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      PaperProps={{
        sx: {
          width: RAIL_NAV_WIDTH,
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          overflowX: 'hidden',

          '& .MuiButtonBase-root': {
            px: 3,
            display: 'flex',
            justifyContent: 'center',
          },

          '& .MuiListItemIcon-root': {
            minWidth: 'auto',

            '& svg': {
              width: 20,
              height: 20,
            },
          },
        }}
      >
        <List
          component={motion.nav}
          variants={staggerListContainer}
          initial="hidden"
          animate="visible"
          exit="exit"
          sx={{
            flexDirection: 'column',
            display: 'flex',
            width: 'auto',
          }}
        >
          {options.map((option) => {
            if (!measureUserAccess(option.roles, userIs)) return null;

            return (
              <ListItemButton
                key={option.id}
                selected={isItemSelected(option, location.pathname, selected)}
                onClick={() => !option?.href && onClickOption(option)}
                {...(option?.href && {
                  component: Link,
                  to: option.href,
                })}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
              </ListItemButton>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default NavigationRail;
