import dayjs from 'dayjs';
import { GUEST_TYPE } from '../constants';

const getEventGuests = (values) => {
  if (values.guestType === GUEST_TYPE.GENERAL) return values[values.guestType];

  if (values.guestType === GUEST_TYPE.GROUP)
    return { group_id: values.group_id ?? values[values.guestType].id };

  return {
    users: values[values.guestType].map((guest) => guest.id),
  };
};

const parseFormValuesToEvent = (values) => {
  const guests = getEventGuests(values);

  let startDateTime = dayjs(values.startDate);
  let endDateTime = dayjs(values.endDate);

  if (values.isAllDay) {
    startDateTime = startDateTime.startOf('day');
    endDateTime = startDateTime.endOf('day');
  }

  startDateTime = startDateTime.utc(true).format('YYYY-MM-DD HH:mm:ss');
  endDateTime = endDateTime.utc(true).format('YYYY-MM-DD HH:mm:ss');

  return {
    ...guests,
    id: values.id,
    start_datetime: startDateTime,
    end_datetime: endDateTime,
    enable_notification: Number(values.withNotifications),
    rvsp: Number(values.withAssistance),
    title: values.title,
    text: values.description,
    event_type_id: 3,
    all_day: values.isAllDay,
  };
};

export default parseFormValuesToEvent;
