import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { useCreate } from 'src/shared/services/api/query';
import { paymentManagementRates } from 'src/shared/services/url/UrlPaymentManagement';
import { QUERY_KEY } from 'src/features/payment-management/constants/queryKey';
import ratesSerializer from 'src/features/payment-management/helpers/ratesSerializer';

const SUCCESS_MESSAGE = t`Concepto creado con éxito.`;
const ERROR_MESSAGE = t`Hubo un error al intentar crear el concepto.`;

const useCreateRate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useCreate(
    paymentManagementRates(),
    {
      onSuccess: () => enqueueSnackbar(SUCCESS_MESSAGE, { variant: 'success' }),
      onError: ({ message }) =>
        enqueueSnackbar(message ?? ERROR_MESSAGE, { variant: 'error' }),
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.RATES],
          refetchType: 'all',
        });
        queryClient.removeQueries({
          queryKey: [QUERY_KEY.CONCEPTS],
          refetchType: 'all',
        });
      },
    },
    false,
    ratesSerializer,
  );
};

export default useCreateRate;
