import { CURRENCIES } from 'src/config/constants';
import abstractSerializer from 'src/shared/services/api/query/serializer/abstractSerializer';
import { getMonths, toDateFormat } from 'src/shared/utils/dates';
import { CONCEPT_TYPES } from '../views/configuration/PaymentManagementConceptsView/helpers';
import { getConceptType } from '.';
import { MX_TAXS_OPTIONS } from 'src/features/payment-management/constants/payments';

const deserializeRate = (rate) => ({
  ...rate,
  id: rate.id,
  concept: rate.concept,
  name: rate.name,
  amount: rate.value,
  discounts: rate.variations,
  isMonthly: Boolean(rate.recurrent),
  totalStudents: rate.total_student,
  startAt: rate.start_at,
  endAt: rate.finish_at,
  currency: rate.currency ?? CURRENCIES.ARS.id,
  isExtraordinary:
    rate.conceptType === CONCEPT_TYPES.EXTRAORDINARY || rate.is_extraordinary,
  conceptType: getConceptType(rate),
  total: rate.total,
  members:
    rate.users?.map((user) => ({
      ...user,
      id: user.user_id,
    })) ?? [],
  monthsActivated: getMonths().filter((month) =>
    rate.months_activated?.includes(month.id),
  ),
  billable: rate.billable,
  institutionTaxInformation: rate.institution_tax_information_id,
  active: rate.active,
  onlinePaymentAccount: rate.online_payment_account_id,
  taxRegime: rate.tax_regimen_id,
  accountingAccount: rate.accounting_account,
  educationalComplement: rate.educational_complement,
  satKey: rate.sat_key,
  productCode: rate.product_code,
  iva: MX_TAXS_OPTIONS.find((tax) => tax.value === rate?.iva) ?? '',
});

const serializeRate = (rate) => {
  const isRecurrent = rate.conceptType === CONCEPT_TYPES.RECURRENT;
  const isExtraordinary =
    rate.conceptType === CONCEPT_TYPES.EXTRAORDINARY || rate.isExtraordinary;

  return {
    id: rate.id,
    concept_id: rate.concept?.id,
    name: rate.name,
    value: rate.amount,
    variations: rate.discounts,

    ...(isExtraordinary
      ? {
          is_extraordinary: true,
        }
      : {
          recurrent: isRecurrent,
          ...(!isRecurrent && {
            start_at: toDateFormat(rate.startAt, 'YYYY-MM-DD'),
            finish_at: toDateFormat(rate.endAt, 'YYYY-MM-DD'),
          }),
        }),

    total_student: rate.totalStudents,
    currency: rate.currency ?? CURRENCIES.ARS.id,
    total: rate.total,
    users:
      rate.members?.map((user) => ({
        ...user,
        user_id: user.id,
      })) ?? [],
    months_activated: rate.monthsActivated.map((month) => month.id),
    billable: rate.billable,
    institution_tax_information_id:
      rate.institutionTaxInformation?.id ?? rate.institutionTaxInformationId,
    active: rate.active,
    online_payment_account_id:
      rate.onlinePaymentAccount?.id ?? rate.onlinePaymentAccountId,
    tax_regimen_id: rate.taxRegime?.id ?? rate.taxRegimeId,
    accounting_account: rate.accountingAccount,
    educational_complement: rate.educationalComplement,
    sat_key: rate.satKey,
    product_code: rate.productCode?.trim(),
    iva: rate.iva?.value === '' ? null : rate.iva?.value,
  };
};

const deserialize = (data) => {
  if (Array.isArray(data)) {
    return data.map((rate) => deserializeRate(rate));
  }

  return deserializeRate(data);
};

const serialize = (data) => {
  if (Array.isArray(data)) {
    return data.map((rate) => serializeRate(rate));
  }

  return serializeRate(data);
};

const ratesSerializer = abstractSerializer(serialize, deserialize);

export default ratesSerializer;
