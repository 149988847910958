import { useAtomValue } from 'jotai';

import { dialogStateFamily } from 'src/shared/atoms';

const useDialogIsDisabled = (dialogName) => {
  const dialogStateAtom = dialogStateFamily({
    dialogName,
  });

  const { isDisabled } = useAtomValue(dialogStateAtom);

  return isDisabled;
};

export default useDialogIsDisabled;
