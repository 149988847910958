import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControlLabel,
  Checkbox as MuiCheckboxField,
  FormHelperText,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

const CheckField = ({
  gridProps = { xs: 12 },
  name,
  disabled,
  label,
  labelPlacement = 'end',
  helperText,
  onChange,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();

  const [field, , form] = useField(name);

  const handleChange = (event) => {
    setFieldValue(field.name, event.target.checked);
    if (onChange) onChange(event);
  };

  return (
    <Grid item {...gridProps}>
      <FormControlLabel
        checked={field.value}
        onChange={handleChange}
        labelPlacement={labelPlacement}
        label={label}
        disabled={form.isSubmitting || form.isValidating || disabled}
        control={<MuiCheckboxField {...props} />}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Grid>
  );
};

CheckField.propTypes = {
  gridProps: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  labelPlacement: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
};

export default CheckField;
