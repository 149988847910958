import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, InputLabel } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers';

import ConditionalWrapper from 'src/components/ConditionalWrapper';

import RenderDateDigitalTimeView from './RenderDateDigitalTimeView';
import useStyles from './styles';

const DateTimeField = ({
  gridProps,
  name,
  disabled,
  label,
  format = 'DD-MM-YYYY HH:mm',
  disableFormik = {
    error: false,
    helperText: false,
  },
  placeholder,
  autoCompleteTextField = 'on',
  onChange,
  ampm = true,
  timeStep = 15,
  ...rest
}) => {
  const classes = useStyles();
  const { isSubmitting } = useFormikContext();
  const [field, meta, helpers] = useField(name);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleValueChange = (date, keyboard, e) => {
    if (onChange) onChange(date);
    if (dayjs(date).isValid()) {
      const prevDate = field?.value ? dayjs(field?.value) : null;
      const newDate = date ? dayjs(date) : null;

      if (
        prevDate &&
        (prevDate.hour() !== newDate.hour() ||
          prevDate.minute() !== newDate.minute())
      ) {
        setIsModalOpen(false);
      }

      helpers.setValue(date);
    } else helpers.setValue(dayjs(keyboard, format));
  };

  const fieldValue = dayjs(field.value);

  return (
    <ConditionalWrapper
      condition={Boolean(gridProps)}
      wrapper={(children) => (
        <Grid item {...gridProps}>
          {children}
        </Grid>
      )}
    >
      <FormControl className={classes.formControl}>
        {label && (
          <InputLabel
            classes={{ formControl: classes.MuiInputLabelFormControl }}
          >
            {label}
          </InputLabel>
        )}

        <DateTimePicker
          fullWidth
          disabled={isSubmitting || disabled}
          variant="inline"
          value={fieldValue}
          open={isModalOpen}
          onOpen={() => setIsModalOpen(true)}
          onClose={() => setIsModalOpen(false)}
          inputFormat={format}
          onChange={handleValueChange}
          slotProps={{
            textField: {
              onBlur: field.onBlur,
              autoComplete: autoCompleteTextField,
              className: classes.input,
              name: field.name,
              error: Boolean(!disableFormik?.error && meta.error),
              helperText: !disableFormik?.helperText && meta.error,
              variant: 'outlined',
              placeholder: placeholder,
            },
          }}
          ampm={ampm}
          timeStep={timeStep}
          viewRenderers={{
            day: RenderDateDigitalTimeView,
            month: RenderDateDigitalTimeView,
            year: RenderDateDigitalTimeView,
            hours: RenderDateDigitalTimeView,
            minutes: RenderDateDigitalTimeView,
            seconds: RenderDateDigitalTimeView,
          }}
          {...rest}
        />
      </FormControl>
    </ConditionalWrapper>
  );
};

DateTimeField.propTypes = {
  gridProps: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autoCompleteTextField: PropTypes.string,
  onChange: PropTypes.func,
  disableFormik: PropTypes.shape({
    helperText: PropTypes.bool,
    error: PropTypes.bool,
  }),
  ampm: PropTypes.bool,
};

export default memo(DateTimeField);
