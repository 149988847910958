import abstractSerializer from 'src/shared/services/api/query/serializer/abstractSerializer';
import { toDateFormat } from 'src/shared/utils/dates';

const deserializeMyAccount = (personalInfo) => ({
  id: personalInfo.id,
  name: personalInfo.name,
  last_name: personalInfo.last_name,
  document_number: personalInfo.document_number,
  email: personalInfo.email,
  phone: personalInfo.phone,
  cellphone: personalInfo.cellphone,
  address: personalInfo.address,
  birthdate: toDateFormat(personalInfo.birthdate, 'YYYY-MM-DD', true),
  photo: personalInfo.photo,
  genre_id: personalInfo.genre_id,
});

const serializeMyAccount = (personalInfo) => ({
  id: personalInfo.id,
  name: personalInfo.name,
  last_name: personalInfo.last_name,
  document_number: personalInfo.document_number,
  email: personalInfo.email,
  phone: personalInfo.phone,
  cellphone: personalInfo.cellphone,
  address: personalInfo.address,
  birthdate: toDateFormat(personalInfo.birthdate, 'YYYY-MM-DD', true),
  photo: personalInfo.photo,
  genre_id: personalInfo.genre_id,
});

const deserialize = ({ data }) => {
  if (Array.isArray(data)) {
    return data.map((rate) => deserializeMyAccount(rate));
  }

  return deserializeMyAccount(data);
};

const serialize = (data) => {
  if (Array.isArray(data)) {
    return data.map((rate) => serializeMyAccount(rate));
  }

  return serializeMyAccount(data);
};

const myAccountUpdateSerializer = abstractSerializer(serialize, deserialize);

export default myAccountUpdateSerializer;
