import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import PostCard from 'src/components/PostCard';
import PostCardContent from 'src/components/PostCard/PostCardContent';
import PostCardHeader from 'src/components/PostCard/PostCardHeader';
import UserAvatar from 'src/components/UserAvatar';
import getInitials from 'src/shared/utils/getInitials';
import { formatDivisionSubjectLink, intersperse } from 'src/shared/helpers';
import { MAX_DIVISIONS_PER_POST } from 'src/config/constants';

import useStyles from './styles';
import router from 'src/shared/utils/router';

const PostResultRow = ({ post }) => {
  const classes = useStyles();
  const postAuthor = Array.isArray(post.author) ? post.author[0] : post.author;
  const authorInitials = getInitials(
    `${postAuthor.name} ${postAuthor.last_name}`,
  );

  const handleRedirect = () => {
    window.location.href = router.communication.newsfeed.post(post.id);
  };

  const renderDivisionsPostLink = (divisions, postId) => {
    const cappedDivisions = divisions.slice(0, MAX_DIVISIONS_PER_POST);
    const DivisionsComps = cappedDivisions.map((division) => (
      <Link
        key={division.id}
        to={router.communication.divisions.posts(division.id)}
      >
        {`${division.course.name} ${division.name}`}
      </Link>
    ));

    if (divisions.length > MAX_DIVISIONS_PER_POST) {
      const restOfDivisions = divisions.length - MAX_DIVISIONS_PER_POST;

      const MoreLink = (
        <Link
          key={`divisions-posts-${postId}`}
          className={classes.andMoreButton}
          disableFocusRipple
          disableTouchRipple
        >
          {`y ${restOfDivisions} más`}
        </Link>
      );

      return intersperse([...DivisionsComps, MoreLink], ', ');
    }

    return intersperse(DivisionsComps, ', ');
  };

  return (
    <PostCard className={classes.root} onClick={handleRedirect}>
      <PostCardHeader
        title={authorInitials}
        avatar={<UserAvatar user={post.author[0]} />}
        subheader={
          <Typography>
            <Trans>Publicó en</Trans>{' '}
            {post.roles_divisions.subject && (
              <Link
                to={`${router.communication.subjects.posts(
                  post.roles_divisions.subject.id,
                )}`}
              >
                {formatDivisionSubjectLink(post.roles_divisions.subject)}
              </Link>
            )}
            {post.roles_divisions.group && (
              <Link
                to={`${router.communication.groups.posts(
                  post.roles_divisions.group.id,
                )}`}
              >
                {post.roles_divisions.group.name}
              </Link>
            )}
            {post.roles_divisions.divisions.length > 0 &&
              renderDivisionsPostLink(post.roles_divisions.divisions, post.id)}
          </Typography>
        }
      />

      <PostCardContent sanitize>{post.sanitize_text}</PostCardContent>
    </PostCard>
  );
};

PostResultRow.propTypes = {
  post: PropTypes.object,
};

export default PostResultRow;
