import { Api } from 'src/shared/services/api';
import {
  getAllLectures,
  getLecturesBySubjectId,
  getAllTasks,
  getTasksPaginated,
  getTasksBySubjectId,
  getTasksByStatusId,
  getMembersBySubjectId,
  getStudentsBySubjectId,
  getTeachersBySubjectId,
  getUserHomeworks,
  getUserHomeworksByTaskId,
  getQuestions,
  getAnswersByQuestionId,
  getTaskDetails,
  taskUser,
  postQuestion,
  turnInHomework,
  putAnswersByQuestionId,
  putQuestionById,
  postUserHomeworkReview,
  markQuestionAsViewed,
  markAnswersAsViewed,
  checkHomework,
  redoHomework,
  changeHomeworkStatus,
  finishTask,
} from 'src/shared/services/url/UrlVirtualClassroom';
import { flatten } from 'src/shared/utils/models';
import { defaultHomeworkStatus, HOMEWORK_STATUS } from 'src/config/constants';
import { v4 as uuidv4 } from 'uuid';
import { HIGH_ROLE, ROLE_STUDENT, ROLE_TEACHER } from 'src/config/general';
import router from 'src/shared/utils/router';

const effects = (dispatch) => ({
  async initializeUserBasicData(
    defaults = {
      subjects: true,
      lectures: true,
      notifications: {
        page: 1,
        per_page: 5,
      },
    },
  ) {
    if (defaults.subjects) this.getAllSubjects();
    if (defaults.lectures) this.getAllLectures();
    await dispatch.user.updateUserRoles();
    await dispatch.user.hasNotifications();
    return true;
  },

  async getAllSubjects(payload, state) {
    const { user } = state;
    const { isMultiRol, selectedRole } = user;
    let selectedRolePermissions = [];
    let selectedRoleSubjectsList = [];
    if (selectedRole === ROLE_TEACHER) {
      selectedRolePermissions = user?.role?.filter((role) =>
        HIGH_ROLE.includes(role.role_name),
      );
    } else {
      selectedRolePermissions = user?.role?.filter(
        (role) => role.role_name === selectedRole,
      );
    }

    if (selectedRolePermissions?.length) {
      selectedRolePermissions.map((rolPermission) => {
        const subjectsIds = rolPermission.subject_ids?.filter(
          (div) => div !== null,
        );
        selectedRoleSubjectsList = selectedRoleSubjectsList.concat(subjectsIds);
        return selectedRoleSubjectsList;
      });
    }

    const selectedPeriod = user?.selectedInstitution?.periods?.find(
      (p) => p.id === user.selectedInstitutionPeriodId,
    );
    const levels = selectedPeriod?.levels ?? [];
    const extracurriculars = selectedPeriod?.extracurriculars ?? [];
    let extraCurriculars = [];
    let subjects = [];

    if (levels.length) {
      // Set subjects
      subjects = levels.reduce((acu, level) => {
        const allSubjectsSelect = [];
        level.courses.map((course) =>
          course.divisions.map((division) => {
            const filteredSubjects = division?.subjects?.filter((sub) =>
              selectedRoleSubjectsList?.includes(sub.id),
            );
            const selectedDivitions = isMultiRol
              ? filteredSubjects
              : division?.subjects;

            if (division.subjects.length && selectedDivitions.length) {
              allSubjectsSelect.push({
                id: `c-${course.id}`,
                name: `${course.name} ${division.name}`,
                optionGroup: true,
              });
            }

            return selectedDivitions.map((subject) =>
              allSubjectsSelect.push({
                id: subject.id,
                name: subject.name,
                optionGroup: false,
              }),
            );
          }),
        );
        return [acu, ...allSubjectsSelect];
      }, []);
    }

    if (extracurriculars.length) {
      extraCurriculars = [
        { id: 'm-1', name: 'Materias Especiales', optionGroup: true },
        ...extracurriculars,
      ];
    }

    await dispatch.vc.setAllSubjects(
      flatten([...subjects, ...extraCurriculars]),
    );
  },

  async getAllLectures() {
    const { data } = await Api.get({ url: getAllLectures() });
    await dispatch.vc.setAllLectures(data);
    return true;
  },

  async getLecturesBySubjectId(subjectId) {
    const { data } = await Api.get({ url: getLecturesBySubjectId(subjectId) });
    await dispatch.vc.setLecturesBySubjectId(data);
    return true;
  },

  async getAllTasks({ cancelToken }) {
    const { data: response, headers } = await Api.get({
      url: getAllTasks(),
      getRaw: true,
      cancelToken,
    });
    await dispatch.vc.setTasks({
      data: response.data,
      pagination: {
        total: Number(headers['x-pagination-total-count']),
      },
    });
    return true;
  },

  async getTaskDetails(taskId, state, params) {
    try {
      const { data } = await Api.get({
        url: getTaskDetails(taskId),
        data: params,
      });
      this.setTaskDetails(data);
    } catch (error) {
      this.setTaskDetails({});
    }
    return true;
  },

  async getTasksPaginated({ isQuestions, cancelToken, ...params }) {
    const { data: response, headers } = await Api.get({
      url: getTasksPaginated(),
      getRaw: true,
      data: params,
    });

    if (isQuestions) {
      await dispatch.vc.setQuestions({
        data: response.data,
        pagination: {
          total: Number(headers['x-pagination-total-count']),
        },
      });

      return true;
    }

    await dispatch.vc.setTasksPage({
      data: response.data,
      pagination: {
        total: Number(headers['x-pagination-total-count']),
      },
    });
    return true;
  },

  async getTasksBySubjectId(subjectId) {
    const { data: response, headers } = await Api.get({
      url: getTasksBySubjectId(subjectId),
      getRaw: true,
    });

    await dispatch.vc.setTasksPage({
      data: response.data,
      pagination: {
        total: Number(headers['x-pagination-total-count']),
      },
    });
    return true;
  },

  async getTasksByStatusId(statusId) {
    const { data: response, headers } = await Api.get({
      url: getTasksByStatusId(statusId),
      getRaw: true,
    });

    await dispatch.vc.setTasksPage({
      data: response.data,
      pagination: {
        total: Number(headers['x-pagination-total-count']),
      },
    });
    return true;
  },

  async getMembersBySubjectId(subjectId) {
    const { data } = await Api.get({
      url: getMembersBySubjectId(subjectId),
      data: {
        role: ROLE_TEACHER,
      },
    });
    await dispatch.vc.setMembers(data);
    return true;
  },

  async getStudentsBySubjectId(subjectId) {
    const { data } = await Api.get({
      url: getStudentsBySubjectId(subjectId),
      data: {
        role: ROLE_STUDENT,
        'per-page': 1000,
      },
    });

    await dispatch.vc.setStudentsBySubjectId(data);
    return true;
  },

  async getTeachersBySubjectId(subjectId) {
    const { data } = await Api.get({ url: getTeachersBySubjectId(subjectId) });
    await dispatch.vc.setTeachersBySubjectId(data);
    return true;
  },

  async getUserHomeworks({ isTaskUserBase, ...params }) {
    const { data: response, headers } = await Api.get({
      // TODO: Find a better way to split homeworks with task-user based endpoints
      url: isTaskUserBase ? taskUser() : getUserHomeworks(),
      getRaw: true,
      data: params,
    });

    // If expands from questions it will set the questions by task
    if (params.expand?.includes('questions')) {
      await dispatch.vc.setQuestions({
        data: response.data,
        pagination: {
          total: Number(headers['x-pagination-total-count']),
        },
      });

      return true;
    }

    await dispatch.vc.setUserHomeworks({
      data: response.data,
      pagination: {
        total: Number(headers['x-pagination-total-count']),
      },
    });

    return true;
  },

  // TaskUser is for /task-user/ based endpoints. Use any another getTask for /task/ based endpoints
  async getTaskUser({ cancelToken, ...params }) {
    const { data: response, headers } = await Api.get({
      url: taskUser(),
      getRaw: true,
      data: params,
    });

    // If expands from questions it will set the questions by task
    if (params.expand?.includes('questions')) {
      await dispatch.vc.setQuestions({
        data: response.data,
        pagination: {
          total: Number(headers['x-pagination-total-count']),
        },
      });

      return true;
    }

    await dispatch.vc.setUserHomeworks({
      data: response.data,
      pagination: {
        total: Number(headers['x-pagination-total-count']),
      },
    });

    return true;
  },

  async getUserHomeworksByTaskId({ taskId }) {
    const { data } = await Api.get({ url: getUserHomeworksByTaskId(taskId) });
    await dispatch.vc.setUserHomeworksByTaskId(data);
    return true;
  },

  async getQuestions({ cancelToken, ...params }) {
    const { data: response, headers } = await Api.get({
      url: getQuestions(),
      getRaw: true,
      cancelToken,
      data: params,
    });

    // setAnswers instead of setQuestions because it will re-write the current questions array[]
    // Been answers an extension of the questions array[]
    if (params.expand?.includes('answers')) {
      await dispatch.vc.setAnswers({
        data: response.data,
        pagination: {
          total: Number(headers['x-pagination-total-count']),
        },
      });

      return true;
    }

    await dispatch.vc.setQuestions({
      data: response.data,
      pagination: {
        total: Number(headers['x-pagination-total-count']),
      },
    });
    return true;
  },

  async postHomeworkReview({
    userId,
    statusId,
    teacherComment,
    institutionPeriod,
  }) {
    await Api.post({
      url: postUserHomeworkReview({ user_homework_id: userId }),
      data: {
        status_id: statusId,
        teacher_comment: teacherComment,
        institution_period_id: institutionPeriod,
      },
    });

    return true;
  },

  async checkHomework({
    userHomeworkId,
    teacherComment,
    institutionPeriod,
    files,
  }) {
    const { data } = await Api.patch({
      url: checkHomework(userHomeworkId),
      data: {
        teacher_comment: teacherComment,
        institution_period_id: institutionPeriod,
        files,
      },
    });

    return data;
  },

  async redoHomework({
    userHomeworkId,
    teacherComment,
    institutionPeriod,
    files,
  }) {
    const { data } = await Api.patch({
      url: redoHomework(userHomeworkId),
      data: {
        teacher_comment: teacherComment,
        institution_period_id: institutionPeriod,
        files,
      },
    });

    return data;
  },

  async changeHomeworkStatus(data, state) {
    await Api.patch({
      url: changeHomeworkStatus(data.taskUserId),
      data: {
        status_id: data.status,
      },
    });

    const payload = {
      ...state.vc.userHomeworks,
      data: state.vc.userHomeworks.data.map((homework) => {
        if (homework.id === data.taskUserId) {
          return {
            ...homework,
            status_id: data.status,
          };
        }

        return homework;
      }),
    };

    this.setTaskDetails({
      ...state.vc.taskDetails,
      total_users_delivered: state.vc.taskDetails.total_users_delivered + 1,
    });
    await this.setUserHomeworks(payload);
  },

  async declineMyHomework(homework, state) {
    await Api.delete({
      url: postUserHomeworkReview({ user_homework_id: homework.id }),
    });

    const payload = {
      ...state.vc.userHomeworks,
      data: state.vc.userHomeworks.data.map((h) => {
        if (h.id === homework.task_user_id) {
          const currentHistoryItem = {
            id: parseInt(uuidv4(), 10),
            created_at: new Date().toJSON(),
            status: defaultHomeworkStatus.PENDING.slug,
            status_id: defaultHomeworkStatus.PENDING.id,
            task_user_id: h.task_user_id,
            user_homework: null,
            user_homework_id: null,
          };

          return {
            ...h,
            status_id: defaultHomeworkStatus.PENDING.id,
            user_homework: [],
            task_user_history: [currentHistoryItem, ...h.task_user_history],
          };
        }

        return h;
      }),
    };

    await this.setUserHomeworks(payload);
  },

  async updateHomeworkHistory(updatedHomework, { vc: { userHomeworks } }) {
    const payload = {
      ...userHomeworks,
      data: userHomeworks.data.map((userHomework) => {
        const homework = userHomework;

        if (userHomework.id === updatedHomework.task_user_id) {
          homework.status_id = updatedHomework.status_id;
          homework.user_homework = [updatedHomework];
        }

        return homework;
      }),
    };

    await this.setUserHomeworks(payload);
  },

  async postQuestion({ questionText, homeworkId, inAnswers, user }) {
    const { data } = await Api.post({
      url: postQuestion(),
      data: {
        task_id: homeworkId,
        text: questionText,
      },
    });

    if (inAnswers) {
      return this.setAnswer({
        ...data,
        user,
      });
    }

    return this.setQuestion(data);
  },

  async putQuestionById({ questionText, questionId, user }) {
    const { data } = await Api.put({
      url: putQuestionById({ questionId }),
      data: {
        text: questionText,
      },
    });

    await this.putQuestion({ ...data, user });

    return true;
  },

  async deleteQuestionById({ questionId }) {
    await Api.delete({
      url: putQuestionById({ questionId }),
    });

    await this.deleteQuestion(questionId);

    return true;
  },

  async deleteTaskById(taskId) {
    await Api.delete({
      url: getTaskDetails(taskId),
    });
    await this.deleteTask(taskId);
  },

  async finishTaskById(taskId) {
    await Api.post({
      url: finishTask(taskId),
    });
    await this.deleteTask(taskId);
  },

  async putAnswersByQuestionId({ answerText, answerId, questionId, user }) {
    const { data } = await Api.put({
      url: putAnswersByQuestionId({
        answerId,
        questionId,
      }),
      data: {
        text: answerText,
      },
    });

    await dispatch.vc.putQuestionAnswer({ ...data, user });

    return true;
  },

  async deleteAnswersByQuestionId({ answerId, questionId }) {
    await Api.delete({
      // Is not a put method but the URL is exact the same
      url: putAnswersByQuestionId({
        answerId,
        questionId,
      }),
    });

    await this.deleteQuestionAnswer({ answerId, questionId });

    return true;
  },

  async postAnswersByQuestionId({ answerText, questionId, cancelToken, user }) {
    const { data } = await Api.post({
      cancelToken,
      url: getAnswersByQuestionId({ questionId }),
      data: {
        text: answerText,
      },
    });
    const date = new Date();
    await dispatch.vc.setAnswersByQuestionId({
      ...data,
      created_at: date.toJSON(),
      updated_at: date.toJSON(),
      user,
    });

    return true;
  },

  async postMarkAnswerAsViewed(params) {
    const readAnswersIds = params.answer_id;

    await Api.patch({
      url: markAnswersAsViewed(),
      data: {
        ...params,
      },
    });

    await this.markAnswerAsViewed(readAnswersIds);

    return true;
  },

  async postMaskQuestionsAsViewed(params) {
    const readQuestions = params.question_id;

    await Api.patch({
      url: markQuestionAsViewed(),
      data: {
        ...params,
      },
    });

    await this.markQuestionsAsViewed(readQuestions);

    return true;
  },

  async saveLecture(lectureData) {
    const { subject_id } = lectureData;
    const { data } = await Api.post({
      url: getAllLectures(),
      data: lectureData,
    });
    await dispatch.vc.getLecturesBySubjectId(subject_id);
    return data;
  },

  async saveTask(taskData, state) {
    const { data } = await Api.post({ url: getAllTasks(), data: taskData });
    const isScheduled = taskData.status_id === HOMEWORK_STATUS.SCHEDULED;
    const isScheduledRoute =
      router.integrations.virtualClassroom.scheduled ===
      window.location.pathname;
    if (
      (!isScheduled && !isScheduledRoute) ||
      (isScheduled && isScheduledRoute)
    ) {
      const newState = {
        ...state.vc.tasksPage,
        data: [data, ...state.vc.userHomeworks.data],
        pagination: { total: state.vc.userHomeworks.pagination.total + 1 },
      };

      await this.setUserHomeworks(newState);
    }
  },

  async updateHomework(
    {
      id,
      active,
      description,
      files,
      name,
      users,
      lecture_id,
      submit_after_the_due_date,
      sent_event,
      sent_post,
      due_at,
      start_datetime,
    },
    state,
  ) {
    const body = {
      active,
      description,
      files,
      name,
      users,
      lecture_id,
      due_at,
      submit_after_the_due_date,
      sent_event,
      sent_post,
      start_datetime,
      // status_id: status_id ? 1 : 0,
    };

    const { data } = await Api.put({ url: getTaskDetails(id), data: body });

    const homeworks = state.vc.userHomeworks.data.map((homework) => {
      if (homework.id === id) {
        return {
          ...homework,
          ...data,
        };
      }

      return homework;
    });

    await this.setTasksPage({
      ...state.vc.userHomeworks,
      data: homeworks,
    });
  },

  async updateHomeworks(homeworkData, state) {
    const { data: createdHomework } = await Api.post({
      url: turnInHomework(),
      data: homeworkData,
    });

    const payload = {
      ...state.vc.userHomeworks,
      data: state.vc.userHomeworks.data.map((h) => {
        if (h.id === createdHomework.task_user_id) {
          return {
            ...h,
            status_id: createdHomework.status_id,
            user_homework: [...h.user_homework, createdHomework],
            task_user_history: [
              {
                id: Date.now(),
                status_id: createdHomework.status_id,
                user_homework: createdHomework,
              },
              ...h.task_user_history,
            ],
          };
        }

        return h;
      }),
    };

    await dispatch.vc.setUserHomeworks(payload);
  },

  // Questions effects
  async toggleQuestionsSidebar(force = undefined) {
    await this.setToggleQuestionsSidebar(force);
  },
});

export default effects;
