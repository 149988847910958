import { atomWithReset } from 'jotai/utils';

const initialValues = {
  isOpen: false,
  allSelected: false,
  count: 0,
};

const selectedAllUsersDiscountAtom = atomWithReset(initialValues);

export default selectedAllUsersDiscountAtom;
