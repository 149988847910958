import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro';

import { useDelete } from 'src/shared/services/api/query';
import { QUERY_KEY } from 'src/features/messages/constants/queryKeys';
import { deleteScheduledConversation } from 'src/shared/services/url/UrlCommunication';

const useDeleteScheduledConversation = (conversationId) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useDelete(
    deleteScheduledConversation(conversationId),
    {
      onSuccess: () => {
        enqueueSnackbar(t`El mensaje se borró con éxito.`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t`No pudimos borrar el mensaje.`, {
          variant: 'error',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.SCHEDULED_CONVERSATIONS_LIST],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.DRAFT_CONVERSATIONS_LIST],
        });
      },
    },
    false,
  );
};

export default useDeleteScheduledConversation;
