import { useInfiniteQuery } from '@tanstack/react-query';
import abstractSerializer from 'src/shared/services/api/query/serializer/abstractSerializer';

import { useErrorMessage } from '../common/useErrorMessage';
import { abstractRequest } from '../common/abstractRequest';

export const useGetList = (
  key,
  url,
  params = {},
  defaultError = true,
  isPost = false,
  queryParams = {},
  serializer = abstractSerializer(),
) => {
  const { deserialize } = serializer;
  const withErrorMessage = useErrorMessage();

  const request = ({ pageParam = 1 }) =>
    abstractRequest({
      url,
      params: {
        ...params,
      },
      isPost,
      pageParam,
      isRaw: true,
    });

  const queryFn = defaultError ? withErrorMessage(request) : request;

  const query = useInfiniteQuery(key, queryFn, {
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage?.page < lastPage?.totalPages) {
        return allPages.length + 1;
      }

      return undefined;
    },
    select: ({ pages }) => pages?.map(({ data }) => data)?.flat(),
    ...queryParams,
  });

  if (query.isSuccess) {
    return {
      ...query,
      data: deserialize(query.data),
    };
  }

  return query;
};
