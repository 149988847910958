import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useAttendance from '../hooks/useAttendance';

const defaultContextValues = {
  selectedDay: {},
  setSelectedDay: () => {},
  isDialogOpen: false,
  setIsDialogOpen: () => {},
  activeScales: [],
  setActiveScales: () => {},
  activeAttendances: [],
  setActiveAttendances: () => {},
  activeShifts: [],
  setActiveShifts: () => {},
  selectedShifts: [],
  setSelectedShifts: () => {},
  isLoading: false,
  error: null,
  generalAttendance: null,
  setGeneralAttendance: () => {},
  totalAttendances: null,
  attendanceByDivision: null,
  attendanceShifts: null,
  setAttendanceShifts: () => {},
  attendanceScales: null,
  params: null,
  fetchAttendanceByDivision: () => {},
  fetchUsersByDivisions: () => {},
  fetchAttendanceShiftByLevelId: () => {},
  fetchAttendancesStatus: () => {},
  fetchAttendanceScale: () => {},
  setParams: () => {},
  handleApproveAttendances: () => {},
  handleSaveAttendances: () => {},
  fetchTotalAttendances: () => {},
  fetchAttendance: () => {},
  savedActiveAttendances: [],
  setSavedActiveAttendances: () => {},
  fetchUsersBySubjectId: () => {},
};

const AttendanceContext = createContext(defaultContextValues);

const AttendanceContextProvider = ({ children }) => {
  const attendance = useAttendance();

  return (
    <AttendanceContext.Provider value={attendance}>
      {children}
    </AttendanceContext.Provider>
  );
};

const useAttendanceContext = () => useContext(AttendanceContext);

AttendanceContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { AttendanceContextProvider, useAttendanceContext };

export default AttendanceContext;
