import React from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { Trans, t } from '@lingui/macro';
import { Trash as TrashIcon } from 'react-feather';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';

import FeatherIconButton from 'src/components/FeatherIconButton';
import useCompressImage from 'src/shared/hooks/uploads/useCompressImage';
import useUploadProfilePhoto from 'src/features/my-account/hooks/data/useUploadProfilePhoto';
import useUpdateProfileInfo from 'src/features/my-account/hooks/data/useUpdateProfileInfo';
import { useDialogIsOpen, useOpenDialog } from 'src/shared/hooks';
import { GenericConfirmDialog } from 'src/components/Dialogs';
import { dialogs } from 'src/shared/constants';

const INPUT_ID = 'profile-photo-input';

const ProfilePhotoActions = ({ user }) => {
  const loggedUser = useSelector((store) => store.user);
  const hasPhoto = Boolean(user.photo);
  const { handleCompressImage } = useCompressImage();
  const isConfirmDeleteUserPhotoOpen = useDialogIsOpen(
    dialogs.CONFIRM_DELETE_USER_PHOTO,
  );
  const openConfirmDeleteUserPhoto = useOpenDialog(
    dialogs.CONFIRM_DELETE_USER_PHOTO,
  );
  const { mutateAsync: updatePersonalInfo } = useUpdateProfileInfo();
  const { mutateAsync: uploadProfilePhoto, isLoading } =
    useUploadProfilePhoto();

  const handleUpload = () => {
    const uploadInput = document.getElementById(INPUT_ID);
    uploadInput.click();
  };

  const handleChangeInput = async ({ target }) => {
    const [file] = target.files;
    const compressedFile = await handleCompressImage(file);
    const { finalUrl } = await uploadProfilePhoto({
      userId: user.id,
      file: compressedFile,
    });
    await updatePersonalInfo({
      userId: user.id,
      data: { photo: finalUrl },
    });
  };

  const handleRemovePhoto = async () => {
    openConfirmDeleteUserPhoto(false);
    await updatePersonalInfo({
      userId: user.id,
      data: { photo: null },
    });
  };

  return (
    <Grid item mt={2} xs={12} display="flex">
      <LoadingButton
        fullWidth
        variant="contained"
        onClick={handleUpload}
        loading={isLoading}
      >
        {hasPhoto ? <Trans>Cambiar foto</Trans> : <Trans>Subir foto</Trans>}
      </LoadingButton>
      <FeatherIconButton
        Icon={TrashIcon}
        onClick={() => openConfirmDeleteUserPhoto(true)}
        disabled={isLoading || !hasPhoto}
        sx={{ ml: 1 }}
      />
      <input type="file" id={INPUT_ID} onChange={handleChangeInput} hidden />
      <GenericConfirmDialog
        confirmText={t`Si, eliminar`}
        onConfirm={handleRemovePhoto}
        title={t`Eliminar foto de perfil`}
        open={isConfirmDeleteUserPhotoOpen}
        content={t`¿Estás seguro que quieres eliminar ${
          loggedUser.id === user.id ? 'tu' : 'la'
        } foto de perfil? Las fotos ayudan a una mejor experiencia en la plataforma.`}
        status={{ loading: isLoading }}
        onClose={() => openConfirmDeleteUserPhoto(false)}
      />
    </Grid>
  );
};

ProfilePhotoActions.propTypes = {
  user: PropTypes.object,
};

export default ProfilePhotoActions;
