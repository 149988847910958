import { useState, useEffect, useCallback } from 'react';
import { Api as api } from 'src/shared/services/api';

const initialPagination = {
  page: 0,
  total: 0,
  limit: 10,
};

const filters = {
  ...initialPagination,
  search: '',
};

const useMembers = (url, params = {}, showRoles) => {
  const [members, setMembers] = useState(null);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState(initialPagination);
  const { expand, only_users } = params;

  const fetchMembers = useCallback(
    async ({ page, limit, search } = filters) => {
      const paginate = (rows) => {
        return rows.slice(page * limit, page * limit + limit);
      };

      const filterByQuery = (rows, query) => {
        const queryLowerCase = query.toLowerCase().split(' ');

        return rows.filter((row) => {
          const fullName = `${row.name} ${row.last_name}`.toLowerCase();
          return queryLowerCase.every((q) => fullName.includes(q));
        });
      };

      setMembers(null);
      setError(null);
      try {
        const { data } = await api.get({
          url,
          data: { expand, only_users },
        });

        const formattedMembers = data?.map((member) => ({
          ...member,
          showRoles,
        }));

        let settledMembers = paginate(formattedMembers);

        if (search) settledMembers = filterByQuery(data, search);

        setMembers(settledMembers);
        setError(null);
        setPagination({
          page,
          limit,
          total: data.length,
        });
      } catch (apiError) {
        setMembers(null);
        setError(apiError);
      }
    },
    [url, expand, only_users, showRoles],
  );

  const handlePageChange = async (newPage) => {
    await fetchMembers({
      ...pagination,
      page: newPage,
    });
  };

  const handleLimitChange = async (limit) => {
    await fetchMembers({
      ...pagination,
      limit,
    });
  };

  const handleSearch = async (query) => {
    await fetchMembers({
      ...pagination,
      search: query,
    });
  };

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  return {
    members,
    error,
    pagination,
    handlePageChange,
    handleLimitChange,
    handleSearch,
    fetchMembers,
  };
};

export default useMembers;
